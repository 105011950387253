import TransactionActionButton from '../TransactionActionButton'
import AcceptProposalWrapper from './AcceptProposalWrapper'

export const AcceptProposalButton = ({
  myeStyles,
  isLoading,
  label,
  disabled,
  onAction,
}: {
  isLoading: boolean
  label: string
  disabled: boolean
  onAction(): void
  myeStyles: React.CSSProperties & { textColor: string }
}) => {
  return (
    <AcceptProposalWrapper textColor={myeStyles.textColor}>
      <TransactionActionButton isLoading={isLoading} label={label} disabled={disabled} onClick={onAction} />
    </AcceptProposalWrapper>
  )
}
