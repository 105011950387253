import { flip, size } from '@floating-ui/react-dom'
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup'
import { MenuItem } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import { Button, ButtonGroup, ButtonProps } from 'opensolar-ui'
import * as React from 'react'
import { ReactNode, useEffect } from 'react'
import { ArrowDropDownIcon } from '../icons'

// taken from https://github.com/mui/material-ui/blob/v5.15.18/docs/data/material/components/button-group/SplitButton.tsx

export type SplitButtonProps<ValueType, ItemType extends SplitButtonOption<ValueType>> = {
  // Can contain data objects, or random react components
  options: (ItemType | ReactNode)[]

  // Determines which option is shown in the Button
  selectedIndex?: number

  // Callback gets called when an item is clicked
  // Only gets called for data items, not react components
  onClick?: (value: ItemType, index: number) => void

  // the prop to use for the option's key, if not the index
  optionKey?: string

  // the prop to use for the option's disabled state
  optionDisabled?: string

  // whether the selected item should be shown in the menu
  showSelected?: boolean

  // whether the selection should be updated internally when items are clicked
  updateSelected?: boolean

  // whether the avoid closing the menu when an item is clicked
  dontClose?: boolean

  disabled?: ButtonProps['disabled']
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
}

export type SplitButtonOption<ValueType> = {
  label: ReactNode
  value: ValueType
}

export const SplitButton = <ValueType, ItemType extends SplitButtonOption<ValueType>>({
  options,
  selectedIndex = 0,
  optionKey,
  optionDisabled,
  onClick,
  showSelected,
  updateSelected,
  dontClose,
  size,
  variant,
  color,
  disabled,
}: SplitButtonProps<ValueType, ItemType>) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const [realSelected, setRealSelected] = React.useState(selectedIndex)

  const buttonProps = {
    disabled,
    size,
    variant: !variant ? 'contained' : variant,
    color: color === 'default' ? 'secondary' : color,
  }

  const handleClickMain = () => {
    if (onClick) onClick(options[selectedIndex] as ItemType, selectedIndex)
  }

  useEffect(() => setRealSelected(selectedIndex), [selectedIndex])

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    if (updateSelected) setRealSelected(index)
    if (onClick) onClick(options[index] as ItemType, index)
    if (!dontClose) setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const renderOption = (index: number, isButton: boolean) => {
    const item = options[index]
    if (item && typeof item === 'object' && item['label'] && item['value'] !== undefined) {
      if (isButton)
        return (
          <Button {...buttonProps} onClick={handleClickMain}>
            {item['label']}
          </Button>
        )
      else {
        const key = optionKey ? item[optionKey] : index
        return (
          <MenuItem
            key={key}
            disabled={optionDisabled ? item[optionDisabled] : false}
            selected={index === realSelected}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {item['label']}
          </MenuItem>
        )
      }
    } else {
    }
  }

  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <ButtonGroup {...buttonProps} ref={anchorRef} aria-label="Button group with a nested menu">
        {renderOption(realSelected, true)}
        <Button
          {...buttonProps}
          aria-describedby={id}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popup id={id} anchor={anchorRef.current} open={open} middleware={popupMiddleware}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {options.map((option, index) => {
                if (!showSelected && index === realSelected) return null
                return renderOption(index, false)
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popup>
    </>
  )
}

const popupMiddleware = [
  // Sets the width of the dropdown to the width of the button
  size({
    apply({ rects, elements }) {
      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`,
      })
    },
  }),

  // Flips the dropdown from the bottom to the top if there is not enough space
  flip(),
]
