export default function getFormInitialValues(initialValues, defaultValue, record) {
  let finalInitialValues = {
    ...record,
    ...initialValues,
  }

  if (typeof defaultValue !== 'undefined') {
    console.warn('"defaultValue" is deprecated, please use "initialValues" instead')
  }

  if (typeof defaultValue === 'object') {
    finalInitialValues = {
      ...defaultValue,
      ...finalInitialValues,
    }
  } else if (typeof defaultValue === 'function') {
    finalInitialValues = {
      ...finalInitialValues,
      ...defaultValue(record),
    }
  }
  return finalInitialValues
}
