import DOMPurify from 'dompurify'
import { http } from '../services/http'
import { COMPONENT_CONFIG } from './expoStand'

export const sanitizeHtml = (htmlStr: string): string => DOMPurify.sanitize(htmlStr)

export const generateEmbedUrl = (url?: string): null | string => {
  if (!url) return null
  const vimeoReg = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  const youtubeReg = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  if (vimeoReg.test(url)) {
    const match = url.match(vimeoReg)
    if (match && match?.[5]) return `http://player.vimeo.com/video/${match?.[5]}`
  }
  if (youtubeReg.test(url)) {
    const match = url.match(youtubeReg)
    if (match && match?.[2].length === 11) return `https://www.youtube.com/embed/${match[2]}`
  }
  return null
}

export const getVideoData = (url: string): Promise<any> => {
  return http.os
    .get(`https://youtube.com/oembed?url=${url}&format=json`)
    .then((res) => {
      return { ...res.data, video_url: generateEmbedUrl(url) }
    })
    .catch((err) => {
      console.error('Failed to retrieve video data: ', err)
      return null
    })
}

export const getFile = (file?: string | any[] | null) =>
  file ? (typeof file === 'string' ? file : file?.[0]?.src) : undefined

const EXPO_NAVBAR_EXCLUDE = ['navbar', 'cover', 'chat']
export const parseNavLinks = (contents, itemEls) =>
  contents
    ?.filter((block) => !EXPO_NAVBAR_EXCLUDE.includes(block.contentType))
    .map((block) => {
      const displayData = COMPONENT_CONFIG[block.contentType]
      return {
        label: block.data?.navHeading || displayData?.navLabel || displayData?.label,
        onClick: () => {
          const itemEl: HTMLDivElement = itemEls.current[block.id]
          if (itemEl) itemEl.scrollIntoView()
        },
      }
    })

const getReorderableBlocks = (reorderable = true) =>
  Object.keys(COMPONENT_CONFIG).filter((x) => {
    const value = COMPONENT_CONFIG[x]
    return value.reorderable === reorderable
  })

export const reorderableBlocks = getReorderableBlocks()
export const nonReorderableBlocks = getReorderableBlocks(false)

const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatDate = (dateStr: string) => {
  if (!dateStr) return undefined
  const dateObj = new Date(dateStr)
  return `${fullMonthNames[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}
