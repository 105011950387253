import { Action } from '../core/action'
import { DeepState, Value, ValueArray } from '../core/state'
import { DesignData } from '../types'
import { SaveState } from '../types/form'

export const project_form = {
  // State
  active: new Value<boolean>(false),
  dirty_fields: new ValueArray<string[]>([]),
  values: new DeepState<Partial<Project>>({}),
  system_count: new Value<number>(0),
  system_summaries: new DeepState<SystemSummary[]>([]),
  save_state: new Value<SaveState>('none'),

  // Actions
  setValues: new Action<[Record<string, any>]>(),
  updateFields: new Action<[Record<string, any>]>(),
  getDesignData: new Action<[], DesignData>(),
  save: new Action(),
  discard: new Action(),
} as const

export type SystemSummary = {
  name: string
  module_count: number
  payment_options: PaymentOptionSummary[]
}

export type PaymentOptionSummary = {
  title: string
  net_system_cost: number
  payback_year: number | null
  utility_bill_savings_total: number
}

//TODO: fill in
export type Project = Record<string, any>
