import { LinearProgress } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import SendIcon from '@material-ui/icons/SendOutlined'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, Dialog, TextField, Tooltip, Typography } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Field, Form, useFormState } from 'react-final-form'
import appStorage from 'storage/appStorage'
import { OpenSolarTheme } from 'Themes'
import { ActionType, EventType } from 'types/tracking'
import { EditorField } from '../../../../../../../../../elements/wysiwyg/EditorComponentWrapper'
import { FilesContext, FilesContextProps } from '../FilesContextWrapper'
import EmailRecipientsInput, { Recipient } from '../formFields/EmailRecipientsInput'
import RecipientsCheckboxes from '../formFields/RecipientsCheckboxes'
import {
  AddContactsNote,
  AttachmentItem,
  AttachmentsContainer,
  CustomCheckbox,
  ErrorBox,
  ErrorIcon,
  FileIcon,
  FileSize,
  FileTitle,
  Section,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledPaper,
  TextContainer,
} from '../styles/emailDocumentationStyles'
import { LoadingOverlay } from '../styles/styles'
import { previewInfo } from '../utils'

interface EmailDocumentationDialogProps {
  isOpen: boolean
}

const EmailDocumentationDialog: React.FC<EmailDocumentationDialogProps> = ({ isOpen }) => {
  const translate = useTranslate()
  const [loading, setIsLoading] = useState(false)
  const { onDismiss, recordsArraySelected, setRecordsArraySelected, projectId, orgId } = useContext(
    FilesContext
  ) as FilesContextProps

  const [recipients, setRecipients] = useState<Recipient[]>([])
  const notify = useNotify()
  const { trackEvent } = useTrackComponent({
    componentKey: 'email_documentation_dialog',
    eventName: 'User Opened Email Documentation Dialog',
  })

  const project = useFormState().values

  const totalAttachmentSizeMB = recordsArraySelected.reduce((acc, file) => acc + file.filesize / 1_000_000, 0)
  const exceedsLimit = totalAttachmentSizeMB > 20

  useEffect(() => {
    project.contacts_data.forEach((contact) => {
      addRecipient(contact)
    })
  }, [project.contacts_data])

  const addRecipient = (recipient: Recipient) => {
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.CLICK },
      {
        eventName: 'User Added New Recipient To The Email List',
        recipient,
      }
    )
    setRecipients((prev) =>
      prev.some((r) => r.url === recipient.url) ? prev : [...prev, { ...recipient, checked: true }]
    )
  }

  const toggleRecipient = (recipient: Recipient) => {
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.TOGGLE },
      {
        eventName: `User ${recipient.checked ? 'Unchecked' : 'Checked'} Recipient In The Email List`,
      }
    )
    setRecipients((prev) => prev.map((r) => (r.url === recipient.url ? { ...r, checked: !r.checked } : r)))
  }

  const unCheck = useCallback(
    (fileId: number) => {
      trackEvent(
        EventType.USER_INTERACTION,
        { type: ActionType.CLICK },
        {
          eventName: 'User UnChecked Document File',
          fileId,
        }
      )
      setRecordsArraySelected(recordsArraySelected.filter((record) => record.id !== fileId))
    },
    [recordsArraySelected]
  )

  const sendDocuments = useCallback(
    async (values: any) => {
      const selectedFileIds = recordsArraySelected.map((file) => file.id)
      const payload = {
        recipient_emails: recipients
          .filter((r) => r.checked && (r.email || r.user_email))
          .map((r) => r.email || r.user_email),
        custom_subject: values.email_header,
        custom_message: values.email_content,
        file_ids: selectedFileIds,
      }

      const res = await fetch(
        `${window.API_ROOT}/api/orgs/${orgId}/projects/${projectId}/send_documents_to_customer/`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + appStorage.getToken(),
          },
        }
      )
      if (!res.ok) {
        const data = await res.json()
        throw new Error(`${res.status} ${data.message}`)
      }
      return await res.json()
    },
    [recordsArraySelected, recipients]
  )

  const handleSubmit = useCallback(
    async (values) => {
      setIsLoading(true)
      try {
        await sendDocuments(values)
        notify(translate('Email sent successfuly'), 'success')
        trackEvent(
          EventType.SYSTEM_EVENT,
          { type: ActionType.SUBMIT },
          {
            eventName: 'User Sent Email Successfuly',
          }
        )
      } catch (e) {
        notify(`${e}`, 'error')
      } finally {
        setIsLoading(false)
        onDismiss()
      }
    },
    [recordsArraySelected, onDismiss, recipients]
  )

  return (
    <Dialog open={isOpen} onClose={onDismiss} aria-labelledby="email-documentation-dialog" PaperComponent={StyledPaper}>
      {loading && (
        <LoadingOverlay>
          <LinearProgress variant="indeterminate" />
        </LoadingOverlay>
      )}

      <StyledDialogTitle>Email Documentation</StyledDialogTitle>

      <Form onSubmit={handleSubmit} initialValues={{}}>
        {({ handleSubmit, values }) => {
          const validRecipients = recipients.filter((r) => r.checked && r.email)
          const disableSend = validRecipients.length === 0 || exceedsLimit

          const validContent = values.email_header && values.email_content

          return (
            <form onSubmit={handleSubmit}>
              <StyledDialogContent>
                <Section>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {translate('Send to')}
                  </Typography>
                  <RecipientsCheckboxes recipients={recipients} toggleRecipient={toggleRecipient} />
                  <AddContactsNote>{translate('To add more contacts, go to: Manage > Add Contacts')}</AddContactsNote>
                </Section>

                <Section>
                  <Typography>{translate('Add Team Recipient')}</Typography>
                  <EmailRecipientsInput addRecipient={addRecipient} />
                </Section>

                <Section>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {translate('Email header')}
                  </Typography>
                  <Field name="email_header">
                    {({ input }) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...input}
                        placeholder={translate('Add email header here')}
                      />
                    )}
                  </Field>
                </Section>

                <Section>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {translate('Attached documents')}
                  </Typography>
                  <AttachmentsContainer>
                    {recordsArraySelected.length > 0 ? (
                      recordsArraySelected.map((file) => (
                        <AttachmentItem key={file.id}>
                          <CustomCheckbox
                            color="secondary"
                            onChange={() => unCheck(file.id)}
                            checked
                            checkedIcon={<CheckBoxIcon style={{ color: OpenSolarTheme.blue4 }} />}
                          />
                          <FileIcon
                            src={`${window.PUBLIC_URL}/images/doc_icons/${previewInfo(file).fileType}.svg`}
                            alt={`${previewInfo(file).fileType} icon`}
                          />
                          <TextContainer>
                            <FileTitle>{file.title}</FileTitle>
                            <FileSize>{(file.filesize / 1_000_000).toFixed(2)} MB</FileSize>
                          </TextContainer>
                        </AttachmentItem>
                      ))
                    ) : (
                      <ErrorBox>
                        <ErrorIcon />
                        <div>
                          {translate(
                            'No documents are selected. Please return to the documents container to select documents if you want to send them via email.'
                          )}
                        </div>
                      </ErrorBox>
                    )}
                  </AttachmentsContainer>
                  {exceedsLimit && (
                    <ErrorBox>
                      <ErrorIcon />
                      <div>
                        <strong>{translate('Exceeds Maximum File Size Limit')}</strong>
                        <br />
                        {translate(
                          'Your combined attached sizes are too large to send via email. All documents combined must be lower than 20 MB.'
                        )}
                      </div>
                    </ErrorBox>
                  )}
                </Section>

                <Section>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {translate('Email content')}
                  </Typography>
                  <Field name="email_content">
                    {({ input }) => (
                      <EditorField
                        {...(input as any)}
                        handleChange={input.onChange}
                        allowUploadImage={false}
                        mentionContext={'org_and_project'}
                        options={{
                          placeholderText: translate('Write your accompanying email here'),
                        }}
                      />
                    )}
                  </Field>
                </Section>
              </StyledDialogContent>

              <StyledDialogActions>
                <Button
                  onClick={() => {
                    trackEvent(
                      EventType.USER_INTERACTION,
                      { type: ActionType.CLICK },
                      {
                        eventName: 'User Discarded Send Mail Dialog',
                      }
                    )
                    onDismiss()
                  }}
                  color="default"
                  variant="contained"
                >
                  {translate('Discard')}
                </Button>
                {recordsArraySelected.length > 0 &&
                  (disableSend || !validContent ? (
                    <Tooltip
                      title={
                        validRecipients.length === 0
                          ? translate('Please add or select recipients with valid emails.')
                          : exceedsLimit
                          ? translate('Total attachments must be under 20 MB.')
                          : ''
                      }
                    >
                      <span>
                        <Button startIcon={<SendIcon />} variant="contained" color="default" disabled>
                          <span>{translate('Send')}</span>
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      startIcon={<SendIcon />}
                      onClick={() => {
                        trackEvent(
                          EventType.USER_INTERACTION,
                          { type: ActionType.CLICK },
                          {
                            eventName: 'User Clicked On Send CTA',
                          }
                        )
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      <span>{translate('Send')}</span>
                    </Button>
                  ))}
              </StyledDialogActions>
            </form>
          )
        }}
      </Form>
    </Dialog>
  )
}

export default EmailDocumentationDialog
