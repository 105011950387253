import { styled, TextField } from 'opensolar-ui'
import { FC, useEffect, useState } from 'react'

interface PropTypes {
  handleSearch: (value: string) => void
}

const StyledInput = styled(TextField, { name: 'StyledInput' })({
  marginLeft: 20,
})

const Search: FC<PropTypes> = ({ handleSearch }) => {
  const [search, setValue] = useState('')
  useEffect(() => {
    handleSearch(search)
  }, [search])
  return (
    <StyledInput
      onChange={(e) => {
        setValue(e.target.value)
      }}
      placeholder="Search"
    />
  )
}
export default Search
