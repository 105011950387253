import { useIdentifierValidator } from 'projectSections/sections/info/siteDetails/util'
import { useTranslate } from 'ra-core'
import React from 'react'
import { NumberInput, TextInput } from 'react-admin'
import { useBusinessIdentifierLabel } from 'resources/wallet/useBusinessIdentifierLabel'
import { maxDigits } from 'validations'
import { InputRow } from '../styles'

interface PropTypes {
  countryCode: string
}

const CommercialSiteFields: React.FC<PropTypes> = ({ countryCode }) => {
  const translate = useTranslate()
  const { businessIdLabelName, businessIdHelperText } = useBusinessIdentifierLabel(countryCode)

  const identifierValidator = useIdentifierValidator(countryCode, translate, true)

  return (
    <>
      <InputRow>
        <TextInput source={'business_name'} variant="outlined" label={'Business Name'} fullWidth />
        {['NZ', 'GB', 'AU'].includes(countryCode) && (
          <TextInput
            source={'business_identifier'}
            variant="outlined"
            label={translate(businessIdLabelName)}
            helperText={businessIdHelperText}
            fullWidth
            validate={identifierValidator}
          />
        )}
      </InputRow>
      {countryCode === 'US' && (
        <InputRow>
          <NumberInput
            source={'federal_income_tax_rate_percentage'}
            variant="outlined"
            label={'Federal Income Tax Rate Percentage'}
            fullWidth
            InputProps={{
              endAdornment: '%',
            }}
            validate={maxDigits(5, 2)}
          />
          <NumberInput
            source={'state_income_tax_rate_percentage'}
            variant="outlined"
            label={'State Income Tax Rate Percentage'}
            fullWidth
            InputProps={{
              endAdornment: '%',
            }}
            validate={maxDigits(5, 2)}
          />
        </InputRow>
      )}
    </>
  )
}

export default CommercialSiteFields
