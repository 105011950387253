import { Action } from './core/action'
import { Peer, Peers } from './core/peers'
import { Signal } from './core/signal'
import { State, Value } from './core/state'
import { auth } from './state/auth'
import { current_org } from './state/current_org'
import { flows } from './state/flows'
import { preferences } from './state/preferences'
import { project_form } from './state/project_form'
import { proposal } from './state/proposal'
import { projects } from './state/resources/projects'
import { route } from './state/route'
import { studio } from './state/studio'
import { LoginOverride, SdkConfig, SdkInnerConfig } from './types/config'
import { genid } from './util/genid'
import { Logger } from './util/logger'
import { OsView } from './view/view'

const scope_const = {
  current_org,
  project_form,
  route,
  studio,
  auth,
  flows,
  preferences,
  proposal,

  resources: {
    projects,
  },
} as const

const OBJECT_TYPES_TO_PROPAGATE = [
  'OsSystem',
  'OsInverter',
  'OsBattery',
  'OsOther',
  'OsModuleGrid',
  'OsMppt',
  'OsString',
  'OsTree',
  'OsObstruction',
  'OsFacet',
  'OsClipper',
]

const filterSignalForPrimaryObjectTypes = (path: string, args: any[]) => {
  // This assumes the object is always the first argument. This holds for object* signals
  // but not if we extend this into other types of signals.
  const objectType = args[0]?.type
  if (!OBJECT_TYPES_TO_PROPAGATE.includes(objectType)) {
    // console.info('skip signal ' + path + ' for object type ' + objectType)
    return false
  } else {
    return true
  }
}

const SIGNAL_PROPAGATION_FILTERS_BY_OBJECT_TYPE: Record<string, Function | undefined> = {
  'scope.studio.objectSelected': filterSignalForPrimaryObjectTypes,
  'scope.studio.objectAdded': filterSignalForPrimaryObjectTypes,
  'scope.studio.objectChanged': filterSignalForPrimaryObjectTypes,
  'scope.studio.objectRemoved': filterSignalForPrimaryObjectTypes,
  'scope.studio.sceneLoaded': undefined,
  'scope.studio.queueProcessed': undefined,
}

const checkIfSignalShouldBePropagated = (path: string, args: any[]) => {
  // Avoid spamming unnecessary signals which require significant processing.
  const filterFunc = SIGNAL_PROPAGATION_FILTERS_BY_OBJECT_TYPE[path]

  if (filterFunc && filterFunc(path, args) === false) {
    return false
  } else {
    return true
  }
}

type ScopeType = typeof scope_const
export type OSSDK = OSSDKBootstrap & ScopeType

export class OSSDKBootstrap {
  readonly logger = new Logger('OSSDK', { color: '#FFCC26' })

  get isMaster(): boolean {
    return this.peers.id === 'master'
  }
  get isReady(): Value<boolean> {
    return this.ready
  }
  get onConfigResolved(): Signal {
    return this.configResolved
  }
  get resolvedConfig(): SdkInnerConfig {
    return this.config as SdkInnerConfig
  }

  private ready = new Value<boolean>(false)
  private origConfig: SdkConfig | undefined
  private config: SdkInnerConfig | undefined
  private stateMap: Record<string, State<any>> = {}
  private actionMap: Record<string, Action<any[], any>> = {}
  private signalMap: Record<string, Signal<any>> = {}
  private settingStateNow: string | undefined
  private signalDispatchingNow: string | undefined
  private configResolved: Signal = new Signal()

  readonly scopeMixin: OSSDK

  readonly peers = new Peers()

  readonly views: OsView[] = []

  private scope: ScopeType = {
    ...scope_const,
  }

  constructor() {
    // scopeMixin allows us to remove the distinction between the scope and the SDK for SDK users
    this.scopeMixin = Object.assign(this, this.scope)
  }

  isInitialized() {
    return !!this.config
  }

  setConfig(origConfig: SdkConfig, config: SdkInnerConfig) {
    if (this.isInitialized()) {
      throw new Error('SDK already initialized')
    }
    this.origConfig = origConfig
    this.config = config

    if (config.loglevel) window.os_loglevel = config.loglevel
    this.weave(this.scope, 'scope')

    this.peers.onSyncState = this.onSyncState.bind(this)
    this.peers.onStateChange = this.onStateChange.bind(this)
    this.peers.onSignalDispatched = this.onSignalDispatched.bind(this)
    this.peers.onAction = this.onAction.bind(this)
    this.peers.join(this.config.master ? 'master' : 'slave_' + genid())

    if (window.parent !== window) {
      this.peers.addPeer(window.parent, true, true)
    }

    this.configResolved.dispatch()
    if (!this.config.explicit_ready) this.markReady()
  }

  markReady() {
    if (this.ready.value) return
    if (!this.isInitialized()) throw new Error('SDK not initialized')

    this.ready.value = true

    if (this.pendingActions.length) {
      // Run any pending actions
      for (const pending of this.pendingActions) {
        this.actionMap[pending.path](...pending.args)
          .then(pending.resolve)
          .catch(pending.reject)
      }
      this.pendingActions = []
    }
    this.peers.markReady()

    if (this.config?.onReady) this.config.onReady(this.scopeMixin)
  }

  createView({
    parent,
    iframeHostName,
    syncState,
  }: { parent?: HTMLElement; iframeHostName?: string; syncState?: boolean } = {}): OsView {
    if (!this.isInitialized() || !this.origConfig) {
      throw new Error('SDK not ready yet')
    }
    const ret = new OsView(this.scopeMixin, this.config as SdkInnerConfig, this.origConfig, iframeHostName)
    this.views.push(ret)
    if (parent) {
      const options = { syncState }
      ret.spawn(parent, options)
    }
    return ret
  }

  removeView(view: OsView) {
    const idx = this.views.indexOf(view)
    if (idx >= 0) {
      this.views.splice(idx, 1)
      view.destroy()
    } else {
      this.logger.error('View not found')
    }
  }

  private login_configs_resolved: LoginOverride[] | undefined
  getLoginOverrides(): LoginOverride[] | undefined {
    if (this.login_configs_resolved) return this.login_configs_resolved

    const config = this.config
    if (!config) return undefined

    const overrides = config.login_configs_override
    if (!overrides) return undefined

    this.login_configs_resolved = []
    for (const override of overrides) {
      let resolved = { ...override }
      for (const key in override) {
        let value = override[key]
        if (typeof value === 'string') {
          value = value.replace(/\{hostname_spa\}/g, config.hostname_spa)
          value = value.replace(/\{hostname_api\}/g, config.hostname_api)
          resolved[key] = value
        }
      }
      this.login_configs_resolved.push(resolved)
    }

    return this.login_configs_resolved
  }

  /*
  weave recursively traverses the scope and:
  - Stores all state objects in a map for easy lookup
  - Stores all actions in a map for easy lookup
  - Registers action handlers
  */
  private weave(scope: Scope, path: string) {
    for (var i in scope) {
      var value = scope[i]
      const valuePath: string = `${path}.${i}`
      if (value instanceof State) {
        // Is state, send updates to peers
        this.stateMap[valuePath] = value
        value.path = valuePath
        value.isAllowed = (state: State<any>, value: any, old: any) =>
          this.checkStateChangeAllowed(valuePath, state, value, old)
        value.onOwn = (state: State<any>, priority: number) => {
          // Due to the react update cycle, values can be 'owned' multiple times.
          // We should process this still, as priority can change, but should avoid sending state update
          const alreadyOwned = this.peers.isOwner(valuePath)
          // Below the check in value.add(...) for `this.peers.isOwner(valuePath)` relies on setting
          // peers.ownershipMap[valuePath] so be sure that this.peers.setOwnership sets it.
          this.peers.setOwnership(valuePath, priority)

          if (!alreadyOwned && state.value !== undefined) {
            // TODO: This is a bit of a hack where we're assuming that the defaultValue is the value that peers will have
            this.peers.sendStateChange(valuePath, state.value, state.defaultValue)
          }

          // Clear pendingMessages for certain states that relate to an old master.
          // We should consider in detail which states should be cleared, of perhaps all of them?
          // For now we will only clear states that we know are problematic
        }
        value.add((val, old) => {
          // This is tricky, previously it prevented all variables using useBindSelector from propagating to
          // other peers from the master until this.peers.setOwnership() was fixed to also set
          // peers.ownershipMap[valuePath] but can all these ownership fields be simplified?
          if (!this.peers.isOwner(valuePath)) {
            // This is important in scenarios where the state gets updated before `own()` gets called.
            // The value will get sent once `own()` is called.

            // Do not send state change because we are not the owner
            return
          }
          this.settingStateNow !== valuePath && this.peers.sendStateChange(valuePath, val, old)
        })
      } else if (value instanceof Action) {
        // Is action, send unhandled actions to owner peer
        // Register ownership with peers
        this.actionMap[valuePath] = value
        value.onOwn = (action: Action, priority: number) => this.peers.setOwnership(valuePath, priority)
        value.unownedHandler = (...args: any[]) => this.sendUnownedAction(valuePath, args)
      } else if (value instanceof Signal) {
        // Is signal, forward on to peers
        this.signalMap[valuePath] = value
        value.add((...args: any[]) => {
          if (valuePath === this.signalDispatchingNow) return
          this.peers.sendSignal(valuePath, args)
        })
      } else if (typeof value !== 'function') {
        // Is scope, weave recursively
        this.weave(value, valuePath)
      } else {
        // Is function, ignore
      }
    }
  }

  private pendingActions: { path: string; args: any[]; resolve: Function; reject: Function }[] = []

  private async sendUnownedAction(path: string, args: any[]) {
    if (!this.ready.value) {
      return new Promise((resolve, reject) => {
        // Still initing, store action for later
        this.pendingActions.push({ path, args, resolve, reject })
      })
    } else {
      return this.peers.sendUnownedAction(path, args)
    }
  }

  private checkStateChangeAllowed(valuePath: string, state: State<any>, value: any, old: any): boolean {
    if (!this.peers.isOwnerOrNotOwned(valuePath)) {
      const msg = `State change not allowed: ${valuePath}`
      this.logger.error(msg)
      //TODO: throw error in some cases
      return false
    }
    return true
  }

  private onSyncState(peer: Peer) {
    for (const path in this.stateMap) {
      const state = this.stateMap[path]
      /* This is a loose check and allow syncing state not owned by the current peer.
       *  This is because peer can be nested under another peer. With today's network system, no individual peer understands the full network, only it's direct neighbours.
       *  Example case like:
       *   - AppContext A loads AppContext B
       *   - AppContext A manages some state "scope.area.var"
       *   - AppContext B loads AppContext C
       *   - AppContext B needs to also send through the state "scope.area.var", which it doesn't own
       */
      if (!this.peers.isOwner(path) && !state.isChanged()) {
        continue
      }
      this.peers.syncStateToPeer(state.path, peer, state.value, state.defaultValue)
    }
  }

  private onStateChange(path: string, value: any, old: any, isOwnerChange: boolean) {
    if (this.settingStateNow === path) return
    const state = this.stateMap[path]
    if (state) {
      if (!state.matchValue(old)) {
        if (isOwnerChange === true) {
          this.logger.log(
            `Failed to match old state but isOwnerChange===true. Accept state change and clear isOwnerChange flag: ${path}`
          )
        } else if (old === undefined) {
          this.logger.warn(
            `Failed to match old state but old value is undefined, accept state change. Owner has probably changed: ${path}`
          )
        } else {
          this.logger.error(`Failed to match old state, ignoring update: ${path}`)
          return
        }
      }
      this.settingStateNow = path
      state.innerSet(value)
      this.settingStateNow = undefined
    } else {
      this.logger.error(`Received state change for unknown state: ${path}`)
    }
  }

  private onSignalDispatched(path: string, args: any[]) {
    if (this.signalDispatchingNow === path) return
    const signal = this.signalMap[path]
    if (signal) {
      if (checkIfSignalShouldBePropagated(path, args) === false) {
        return
      }
      this.signalDispatchingNow = path
      signal.dispatch(...args)
      this.signalDispatchingNow = undefined
    } else {
      this.logger.error(`Received signal dispatch for unknown signal: ${path}`)
    }
  }

  private onAction(path: string, args: any[]): Promise<any> {
    const action = this.actionMap[path]
    if (action && action.handler) {
      return action.handler.apply(null, args)
    } else {
      this.logger.error(`Received action for unknown action: ${path}`)
      return Promise.reject()
    }
  }
}

type Scope = {
  [key: string]: Signal<any> | Action | State<any> | Scope | Function
}
