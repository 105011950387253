import { ContentsConfig } from 'opensolar-pageeditor'
import Highlights from 'opensolar-pageeditor/src/renderer/components/Highlights'
import { getFile } from 'opensolar-pageeditor/src/renderer/utils'
import { FC, useMemo } from 'react'
interface PropTypes {
  pageData: ContentsConfig
}
const FeaturedContent: FC<PropTypes> = ({ pageData }) => {
  const highlights = useMemo(() => pageData?.elements?.find((x) => x.contentType === 'highlights')?.data, [pageData])

  if (!highlights) return null
  return (
    <Highlights
      highlights={
        highlights?.map((x, i) => ({
          ...x,
          backgroundUrl: getFile(pageData?.assets?.highlights[i]),
          cta: { ...x.cta, file: getFile(x.cta?.file) },
        })) || []
      }
    />
  )
}

export default FeaturedContent
