import { FieldTitle as OsFieldTitle, OsFieldTitleVersion, useComponentVersion } from 'opensolar-ui'
import { FunctionComponent, useMemo } from 'react'
import pure from 'recompose/pure'
import useTranslate from '../i18n/useTranslate'
import getFieldLabelTranslationArgs from './getFieldLabelTranslationArgs'

interface Props {
  isRequired?: boolean
  resource?: string
  source?: string
  label?: string
  translate?: boolean
}

export const FieldTitle: FunctionComponent<Props> = ({ resource, source, label, isRequired, ...rest }) => {
  const translate = useTranslate()
  const version = useComponentVersion('field_title')
  const autoTranslate = useMemo(() => translate(...getFieldLabelTranslationArgs({ label, resource, source })), [
    label,
    resource,
    source,
  ])
  switch (version) {
    case OsFieldTitleVersion.V2:
      return <OsFieldTitle label={rest.translate === false ? label || '' : autoTranslate} required={isRequired} />
    default:
      return (
        <span>
          {autoTranslate}
          {isRequired && ' *'}
        </span>
      )
  }
}

// wat? TypeScript looses the displayName if we don't set it explicitly
FieldTitle.displayName = 'FieldTitle'

export default pure(FieldTitle)
