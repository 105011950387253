import { Action } from '../core/action'
import { DeepState, Value } from '../core/state'

export type TemplateSettings = {
  version: number
  name: string
  designConfig: any
}

type onCheckoutElementActionArgs = {
  elementData: any
  proposalData: any
  actionData: any
}

type AlteredDesignType = {
  [systemUuid: string]: {
    code: string
    delta: number
  }[]
}

export const proposal = {
  // State
  data: new DeepState<Partial<Record<string, any>>>({}),
  templateSettings: new DeepState<Partial<TemplateSettings>>({}),
  checkoutElements: new DeepState<Partial<Record<string, any>>>({}),
  transaction: new DeepState<Partial<Record<string, any>>>({}),
  isViewAsCustomer: new Value<boolean>(false),
  sendButtonOptions: new DeepState<Partial<Record<string, any>>>({}),
  lenderLogoPaths: new DeepState<Partial<Record<string, any>>>({}),

  // Actions
  setIsViewAsCustomer: new Action<[value: boolean]>(),
  onCheckoutElementAction: new Action<[arg: onCheckoutElementActionArgs], unknown>(),
  onSystemChange: new Action<[uuid: string]>(),
  onPaymentMethodChange: new Action<[id: number]>(),
  onSendProposal: new Action(),
  onSaveTemplateSettings: new Action<[rawSettings: string], any>(),
  onComponentQuantityChange: new Action<[alteredDesign: AlteredDesignType]>(),
} as const
