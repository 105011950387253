import { Location } from 'history'
import lodashDebounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import { parse, stringify } from 'query-string'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changeListParams, clearListParams, ListParams } from '../actions/listActions'
import queryReducer, {
  SET_FILTER,
  SET_PAGE,
  SET_PER_PAGE,
  SET_SORT,
  SORT_DESC,
} from '../reducer/admin/resource/list/queryReducer'
import { ReduxState, Sort } from '../types'
import removeEmpty from '../util/removeEmpty'
import removeKey from '../util/removeKey'

interface ListParamsOptions {
  resource: string
  location: Location
  perPage?: number
  sort?: Sort
  filterDefaultValues?: object
  debounce?: number
}

interface Parameters extends ListParams {
  filterValues: { [key: string]: unknown }
  displayedFilters: {
    [key: string]: boolean
  }
  requestSignature: any[]
}

interface Modifiers {
  clearParams: () => void
  changeParams: (action: any) => void
  setPage: (page: number) => void
  setPerPage: (pageSize: number) => void
  setSort: (sort: string) => void
  setFilters: (filters: any, displayedFilters?: any, immediate?: boolean) => void
  hideFilter: (filterName: string) => void
  showFilter: (filterName: string, defaultValue: any) => void
}
export type ListParamsModifiers = Modifiers

const emptyObject = {}

const defaultSort = {
  field: 'id',
  order: SORT_DESC,
}

const defaultParams = {}

/**
 * Get the list parameters (page, sort, filters) and modifiers.
 *
 * These parameters are merged from 3 sources:
 *   - the query string from the URL
 *   - the params stored in the state (from previous navigation)
 *   - the options passed to the hook (including the filter defaultValues)
 *
 * @returns {Array} A tuple [parameters, modifiers].
 * Destructure as [
 *    { page, perPage, sort, order, filter, filterValues, displayedFilters, requestSignature },
 *    { setFilters, hideFilter, showFilter, setPage, setPerPage, setSort }
 * ]
 *
 * @example
 *
 * const [listParams, listParamsActions] = useListParams({
 *      resource: 'posts',
 *      location: location // From react-router. Injected to your component by react-admin inside a List
 *      filterDefaultValues: {
 *          published: true
 *      },
 *      sort: {
 *          field: 'published_at',
 *          order: 'DESC'
 *      },
 *      perPage: 25
 * });
 *
 * const {
 *      page,
 *      perPage,
 *      sort,
 *      order,
 *      filter,
 *      filterValues,
 *      displayedFilters,
 *      requestSignature
 * } = listParams;
 *
 * const {
 *      setFilters,
 *      hideFilter,
 *      showFilter,
 *      setPage,
 *      setPerPage,
 *      setSort,
 * } = listParamsActions;
 */
const useListParams = ({
  resource,
  location,
  filterDefaultValues,
  sort = defaultSort,
  perPage = 10,
  debounce = 500,
}: ListParamsOptions): [Parameters, Modifiers] => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useSelector(
    (reduxState: ReduxState) =>
      reduxState.admin.resources[resource] ? reduxState.admin.resources[resource].list.params : defaultParams,
    shallowEqual
  )

  const requestSignature = [location.search, resource, params, filterDefaultValues, JSON.stringify(sort), perPage]

  const query = useMemo(
    () =>
      getQuery({
        location,
        params,
        filterDefaultValues,
        sort,
        perPage,
      }),
    requestSignature
  )

  const changeParams = useCallback((action) => {
    const newParams = queryReducer(query, action)
    history.push({
      search: `?${stringify({
        ...newParams,
        filter: JSON.stringify(newParams.filter),
        displayedFilters: JSON.stringify(newParams.displayedFilters),
      })}`,
    })
    dispatch(changeListParams(resource, newParams))
  }, requestSignature)

  const clearParams = useCallback(() => {
    dispatch(clearListParams(resource))
  }, [resource])

  const setSort = useCallback(
    (newSort: string) => changeParams({ type: SET_SORT, payload: { sort: newSort } }),
    requestSignature
  )

  const setPage = useCallback((newPage: number) => changeParams({ type: SET_PAGE, payload: newPage }), requestSignature)

  const setPerPage = useCallback(
    (newPerPage: number) => changeParams({ type: SET_PER_PAGE, payload: newPerPage }),
    requestSignature
  )

  const filterValues = query.filter || emptyObject
  const displayedFilterValues = query.displayedFilters || emptyObject

  const setFiltersNow = (newFilters, newDisplayedFilters) => {
    let payload: { filter: any; displayedFilters: any } = {
      filter: removeEmpty(newFilters),
      displayedFilters: undefined,
    }
    if (newDisplayedFilters) {
      payload.displayedFilters = Object.keys(newDisplayedFilters).reduce((filters, filter) => {
        return newDisplayedFilters[filter] ? { ...filters, [filter]: true } : filters
      }, {})
    }
    changeParams({
      type: SET_FILTER,
      payload,
    })
  }

  const debouncedSetFilters = lodashDebounce(setFiltersNow, debounce)

  const setFilters = useCallback((filters, displayedFilters, immediate = false) => {
    if (immediate) setFiltersNow(filters, displayedFilters)
    else debouncedSetFilters(filters, displayedFilters)
  }, requestSignature)

  const hideFilter = useCallback((filterName: string) => {
    const newFilters = removeKey(filterValues, filterName)
    const newDisplayedFilters = {
      ...displayedFilterValues,
      [filterName]: undefined,
    }

    setFilters(newFilters, newDisplayedFilters)
  }, requestSignature)

  const showFilter = useCallback((filterName: string, defaultValue: any) => {
    const newFilters = set(filterValues, filterName, defaultValue)
    const newDisplayedFilters = {
      ...displayedFilterValues,
      [filterName]: true,
    }
    setFilters(newFilters, newDisplayedFilters)
  }, requestSignature)

  return [
    {
      displayedFilters: displayedFilterValues,
      filterValues,
      requestSignature,
      ...query,
    },
    {
      clearParams,
      changeParams,
      setPage,
      setPerPage,
      setSort,
      setFilters,
      hideFilter,
      showFilter,
    },
  ]
}

export const validQueryParams = ['page', 'perPage', 'sort', 'order', 'filter', 'displayedFilters']

const parseObject = (query, field) => {
  if (query[field] && typeof query[field] === 'string') {
    try {
      query[field] = JSON.parse(query[field])
    } catch (err) {
      delete query[field]
    }
  }
}

export const parseQueryFromLocation = ({ search }) => {
  const query = pickBy(parse(search), (v, k) => validQueryParams.indexOf(k) !== -1)
  parseObject(query, 'filter')
  parseObject(query, 'displayedFilters')
  return query
}

/**
 * Check if user has already set custom sort, page, or filters for this list
 *
 * User params come from the Redux store as the params props. By default,
 * this object is:
 *
 * { filter: {}, order: null, page: 1, perPage: null, sort: null }
 *
 * To check if the user has custom params, we must compare the params
 * to these initial values.
 *
 * @param {Object} params
 */
export const hasCustomParams = (params: ListParams) => {
  return (
    params &&
    params.filter &&
    (Object.keys(params.filter).length > 0 ||
      params.order != null ||
      params.page !== 1 ||
      params.perPage != null ||
      params.sort != null)
  )
}

/**
 * Merge list params from 3 different sources:
 *   - the query string
 *   - the params stored in the state (from previous navigation)
 *   - the props passed to the List component (including the filter defaultValues)
 */
export const getQuery = ({ location, params, filterDefaultValues, sort, perPage }) => {
  const queryFromLocation = parseQueryFromLocation(location)
  const query: Partial<ListParams> =
    Object.keys(queryFromLocation).length > 0
      ? queryFromLocation
      : hasCustomParams(params)
      ? { ...params }
      : { filter: filterDefaultValues || {} }

  if (!query.sort) {
    query.sort = sort.field
    query.order = sort.order
  }
  if (!query.perPage) {
    query.perPage = perPage
  }
  if (!query.page) {
    query.page = 1
  }
  return {
    ...query,
    page: getNumberOrDefault(query.page, 1),
    perPage: getNumberOrDefault(query.perPage, 10),
  } as ListParams
}

export const getNumberOrDefault = (possibleNumber: string | number | undefined, defaultValue: number) =>
  (typeof possibleNumber === 'string' ? parseInt(possibleNumber, 10) : possibleNumber) || defaultValue

export default useListParams
