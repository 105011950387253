import type { CheckoutActionType, CheckoutElementDataType, CreateActionElementArgsLike } from '../types'
import AcceptProposal from './acceptProposal/AcceptProposal'
import CheckoutAlertCta from './alert/CheckoutAlertCta'
import MakeCashflowDeposit from './cashflowDeposit/MakeCashflowDeposit'
import CheckoutFinancingCard from './checkoutFinancing/CheckoutFinancingCard'
import CheckoutActionDivider from './divider/CheckoutActionDivider'
import LoanAgreementAction from './loanAgreement/LoanAgreementAction'
import LoanApplication from './loanApplication/LoanApplication'
import AlertMessage from './message/Alert'
import Message from './message/Message'
import PhoenixApplication from './phoenix/PhoenixApplication'
import StipulationUpload from './stipulation/StipulationUpload'
import SunlightPrequalify from './sunlightPrequalify/SunlightPrequalify'
interface CreateActionElementArgs extends CreateActionElementArgsLike {
  elementData: CheckoutElementDataType
}

// TODO: clean up and support all CheckoutActionType before rolling out
const ActionTypeAndElementMapper: { [key in CheckoutActionType]: React.FC<CreateActionElementArgsLike> | undefined } = {
  AcceptProposal: AcceptProposal,
  CashFlowDeposit: MakeCashflowDeposit,
  Prequal: SunlightPrequalify,
  Divider: CheckoutActionDivider,
  AlertCTA: CheckoutAlertCta,
  LoanApplication: LoanApplication,
  FinanceStipulations: StipulationUpload,
  LoanAgreement: LoanAgreementAction,
  PhoenixApplication: PhoenixApplication,
  Message: Message,
  Alert: AlertMessage,
  CheckoutFinance: CheckoutFinancingCard,
}

export type CreateActionElementType = (args: CreateActionElementArgs) => JSX.Element

export const createActionElement: CreateActionElementType = ({
  config,
  elementData,
  proposalData,
  onAction,
  translate,
  options,
}) => {
  const { type } = elementData
  const ActionElement = ActionTypeAndElementMapper[type]

  if (ActionElement === undefined) {
    throw new Error('Invalid checkout action type')
  }

  return (
    <ActionElement
      config={config}
      options={options}
      elementData={elementData}
      proposalData={proposalData}
      translate={translate}
      onAction={onAction}
    />
  )
}

export const SUPPORTED_ELEMENT_TYPES = [
  'AcceptProposal',
  'CashFlowDeposit',
  'Prequal',
  'Divider',
  'LoanApplication',
  'PhoenixApplication',
  'AlertCTA',
  'FinanceStipulations',
  'LoanAgreement',
  'Message',
  'Alert',
  'CheckoutFinance',
] as const
