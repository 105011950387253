import { authSelectors } from 'ducks/auth'
import { orgsActions } from 'ducks/orgs'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BlueSnapOnboardingForm from './BluesnapOnboardingForm'
import NonAdminOnboardingForm from './NonAdminOnboardingForm'
import { useGetPspOnboardingData } from './utils'

type PropTypes = {
  closeDialog: () => void
  setShowNonAdminDialog: (boolean) => void
  setShowMarketingDialog: (boolean) => void
}

const PaymentsOnboardingWrapper: React.FC<PropTypes> = (props) => {
  const bluesnapOnboardingData = useGetPspOnboardingData('bluesnap')
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const dispatch = useDispatch()

  useEffect(() => {
    // whenever the onboarding flow dismounts, reload the org in case the decision has changed
    return () => {
      dispatch(orgsActions.reloadOrg())
    }
  }, [])

  return isAdmin ? (
    <div>
      {bluesnapOnboardingData?.onboarding_fields && Object.keys(bluesnapOnboardingData?.onboarding_fields)?.length && (
        <BlueSnapOnboardingForm
          initialPageNumber={bluesnapOnboardingData?.initialPage}
          fieldMap={bluesnapOnboardingData?.onboarding_fields}
          initialValues={bluesnapOnboardingData?.application_data || {}}
          closeDialog={props.closeDialog}
        />
      )}
    </div>
  ) : (
    <div>
      <NonAdminOnboardingForm
        closeDialog={props.closeDialog}
        showNonAdminDialog={true}
        setShowNonAdminDialog={props.setShowNonAdminDialog}
        setShowMarketingDialog={props.setShowMarketingDialog}
      />
    </div>
  )
}
export default PaymentsOnboardingWrapper
