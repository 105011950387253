import Delete from '@material-ui/icons/Delete'
import ImageDisplayField from 'elements/field/ImageDisplayField'
import DateTimeInput from 'elements/input/DateTimeInput'
import { FieldValidationErrors } from 'elements/react-admin/FieldValidationErrors'
import { HighlightConfig } from 'opensolar-pageeditor'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { BooleanInput, FileField, FileInput, SelectInput, TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import MultipleCardField, { BtnWrapper } from '../common/MultipleCardField'
import { useBlockSource } from '../useBlockSource'

const CTA_TYPE_OPTIONS = [
  { id: 'url', name: 'URL' },
  { id: 'file', name: 'File Download' },
]

interface FieldPropTypes {
  disabled: boolean
  assetSource: string
  record: any
}

const HighlightsFields: FC<FieldPropTypes> = ({ disabled, record, assetSource }) => {
  const translate = useTranslate()
  const source = useBlockSource('highlights')

  if (!source) return null
  return (
    <InputAccordion title={translate('Highlights')} source={source}>
      <MultipleCardField
        source={source}
        render={(item: HighlightConfig, { index, deleteCard }) => (
          <HighlightInputs
            key={'highlight-' + index}
            source={`${source}.${index}`}
            disabled={disabled}
            removeCard={() => {
              deleteCard(index, 'highlights')
            }}
            i={index}
            highlight={item}
            record={record}
            assetSource={assetSource}
          />
        )}
      />
    </InputAccordion>
  )
}
interface PropTypes {
  source: string
  disabled: boolean
  removeCard: () => void
  highlight: HighlightConfig
  i: number
  record: any
  assetSource: string
}

const HighlightInputs: FC<PropTypes> = ({ source, disabled, removeCard, highlight, i, record, assetSource }) => {
  const translate = useTranslate()
  return (
    <InputAccordion title={highlight.title || `${translate('Card')} ${i + 1}`} source={source}>
      <TextInput source={`${source}.title`} label="Title" disabled={disabled} />
      <TextInput source={`${source}.description`} label="Description" disabled={disabled} multiline />
      <ImageDisplayField
        name={`${assetSource}.highlights.${i}`}
        source={`${assetSource}.highlights.${i}`}
        label="Background Image"
        defaultValue={null}
        accept="image/*"
        placeholder={<p>{translate('Drop your image here')}</p>}
        record={record}
        maxWidth={200}
      />
      <TextInput source={`${source}.cta.label`} label="CTA Label" disabled={disabled} />
      <SelectInput
        defaultValue={'url'}
        source={`${source}.cta.type`}
        label="CTA Type"
        disabled={disabled}
        choices={CTA_TYPE_OPTIONS}
      />
      {highlight.cta?.type && (
        <>
          {highlight.cta?.type === 'url' ? (
            <TextInput source={`${source}.cta.url`} label="CTA URL" disabled={disabled} />
          ) : (
            <FileInput
              source={`${source}.cta.file`}
              label={'CTA File Upload'}
              placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
            >
              <FileField source="src" title="title" />
            </FileInput>
          )}
        </>
      )}
      <BooleanInput label={'Enable Countdown'} source={`${source}.enableCountdown`} disabled={disabled} />
      {highlight.enableCountdown && (
        <>
          {
            //@ts-ignore
            <DateTimeInput
              source={`${source}.countdownDate`}
              label={'Countdown Date'}
              options={{ minDate: new Date() }}
            />
          }
          <FieldValidationErrors source={`${source}.countdownDate`} />
        </>
      )}
      <BooleanInput label={'Full width?'} source={`${source}.fullWidth`} disabled={disabled} />
      <BooleanInput label={'White text?'} source={`${source}.whiteText`} disabled={disabled} />
      <BtnWrapper>
        <Button onClick={removeCard} startIcon={<Delete />} variant="contained" color="error" style={{ marginTop: 20 }}>
          {translate('Delete Card')}
        </Button>
      </BtnWrapper>
    </InputAccordion>
  )
}

export default HighlightsFields
