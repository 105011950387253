import { updateAccountingIntegrations } from 'ducks/orgs'
import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { useFeatureFlag } from 'util/split'
import { AccountingIntegration, AccountingTaxRateType, LedgerAccount, TaxRateSelection } from './types'

export const getAccountingIntegrations = async (
  orgId: number | undefined,
  roleId: number | undefined,
  dispatch: any,
  notify: any
): Promise<{ integrations: AccountingIntegration[]; isConnected: boolean; connectedIntegrationName: string }> => {
  if (!orgId || !roleId) return { integrations: [], isConnected: false, connectedIntegrationName: '' }

  try {
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    const response = await restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/accounting/connections/',
    })
    const accResponse = response.data

    if (!accResponse || !Array.isArray(accResponse)) {
      console.error('Unexpected API response structure:', response)
      return { integrations: [], isConnected: false, connectedIntegrationName: '' }
    }

    const mappedData: AccountingIntegration[] = accResponse.map((acc) => {
      return {
        name: acc.name,
        value: acc.name,
        enabled: acc.status === 'connected',
        id: acc.integration_id,
        connection_url: acc.connection_url,
        product_ledger_account_id: acc.product_ledger_account_id,
        payment_ledger_account_id: acc.payment_ledger_account_id,
        accounting_contact_email: acc.accounting_contact_email,
        provider_account_name: acc.provider_account_name,
        tax_rate_json: acc.tax_rate_json,
      }
    })

    const isConnected = mappedData.some((accInt) => accInt.enabled)
    const connectedIntegrationName = mappedData.find((acc) => acc.enabled)?.name || ''

    dispatch(updateAccountingIntegrations(mappedData))

    return { integrations: mappedData, isConnected, connectedIntegrationName }
  } catch (error) {
    console.error('Error fetching accounting integrations: ', error)
    notify('Error fetching accounting integrations', 'error')
    return { integrations: [], isConnected: false, connectedIntegrationName: '' }
  }
}

export const useVisibleAccountingIntegrations = () => {
  const quickBooksIsEnabled = useFeatureFlag('quickbooks_integration', 'on')
  const xeroIsEnabled = useFeatureFlag('xero_integration', 'on')
  let visibleIntegrations: string[] = []
  if (quickBooksIsEnabled) {
    visibleIntegrations.push('quickbooks')
  }
  if (xeroIsEnabled) {
    visibleIntegrations.push('xero')
  }
  return visibleIntegrations
}

export const useAccountingIntegrationsAreVisible = () => {
  return useVisibleAccountingIntegrations().length > 0
}

export const useIsLedgerAccountsConfigured = (integration: AccountingIntegration | undefined): boolean => {
  if (!integration) {
    return false
  }

  switch (integration.name) {
    case 'quickbooks':
      return Boolean(integration.product_ledger_account_id && integration.product_ledger_account_id !== '')
    case 'xero':
      return Boolean(
        integration.product_ledger_account_id &&
          integration.product_ledger_account_id !== '' &&
          integration.payment_ledger_account_id &&
          integration.payment_ledger_account_id !== ''
      )
    default:
      return false
  }
}

export const useGetLedgerAccounts = (orgId: number | undefined, roleId: number | undefined) => {
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fetchAttempts, setFecthAttempts] = useState(0)

  const MAX_ATTEMPTS = 1
  const RETRY_DELAY = 2000

  const fetchLedgers = async () => {
    setIsLoading(true)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/accounting/ledger_accounts/',
    })
      .then((response: any) => {
        const ledgerAccs = response.data.data

        if (ledgerAccs.length === 0 && fetchAttempts < MAX_ATTEMPTS) {
          setTimeout(() => setFecthAttempts((prev) => prev + 1), RETRY_DELAY)
        } else {
          const ALData: LedgerAccount[] = ledgerAccs.map((ledAcc) => {
            return {
              id: ledAcc.id,
              currency: ledAcc.currency,
              current_balance: ledAcc.current_balance,
              name: ledAcc.name,
              description: ledAcc.description,
              is_bank_account: ledAcc.is_bank_account,
              nominal_code: ledAcc.nominal_code,
              status: ledAcc.status,
              subtype: ledAcc.subtype,
              type: ledAcc.type,
            }
          })
          setLedgerAccounts(ALData)
        }
      })
      .catch((err) => {
        console.error('Error fetching ledger accounts:', err)
        setLedgerAccounts([])
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (orgId && roleId) {
      fetchLedgers()
    }
  }, [orgId, roleId, fetchAttempts])

  return { ledgerAccounts, isLoading }
}

export const saveAccountingSettings = (
  productLedgerId: string | null,
  paymentLedgerAccountId: string | null,
  accountingContactEmail: string | null,
  taxRateSelection: TaxRateSelection,
  integrationId: string,
  connectionId: string,
  orgId: number
) => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')

    // ignore any rows with non-numeric keys
    const sanitizedTaxRateSelection: TaxRateSelection = {}
    Object.keys(taxRateSelection)?.forEach((key) => {
      try {
        parseFloat(key)
        if (typeof taxRateSelection[key] !== 'string') {
          sanitizedTaxRateSelection[key] = taxRateSelection[key]
        }
      } catch {}
    })

    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/accounting/settings/',
      data: {
        accounting_integration: integrationId,
        connection_id: connectionId,
        product_ledger_account_id: productLedgerId,
        payment_ledger_account_id: paymentLedgerAccountId,
        accounting_contact_email: accountingContactEmail,
        tax_rate_selection: sanitizedTaxRateSelection,
      },
    })
      .then((response: any) => {
        resolve(true)
      })
      .catch((error) => {
        reject(error?.body?.message || 'Error saving ledger account')
      })
  })
}

export const syncConnection = async (
  orgId: number | null,
  roleId: number | null,
  integration: AccountingIntegration
): Promise<string> => {
  const API_URL = window.API_ROOT + '/api'
  const restClientInstance = restClient(API_URL)
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/accounting/sync_connection/',
      data: {
        accounting_integration: integration.name,
        connection_id: integration.id,
      },
    })
      .then((response: any) => {
        resolve('The account sync has started. This may take a few minutes')
      })
      .catch((err) => {
        reject(err?.body?.message || 'Something went wrong')
      })
  })
}

export const getIsLedgerSelectionComplete = (integration: AccountingIntegration | null): boolean => {
  if (!integration) {
    return false
  }

  if (integration.name === 'xero') {
    // if the top-level payment ledger isn't selected, then their config isn't complete
    if (!integration.payment_ledger_account_id || integration.payment_ledger_account_id === '') {
      return false
    }
    // if they haven't done any tax rate config then their config isn't complete
    if (!integration.tax_rate_json) {
      return false
    }

    let hasFullyConfiguredTaxRate = false
    Object.keys(integration?.tax_rate_json)?.forEach((taxRate) => {
      // if they've fully configured at least one tax rate, then their config is complete
      if (
        integration.tax_rate_json?.[taxRate]?.product_tax_rate_id &&
        integration.tax_rate_json?.[taxRate]?.product_ledger_account_id
      ) {
        hasFullyConfiguredTaxRate = true
      }
    })

    return hasFullyConfiguredTaxRate
  } else if (integration.name === 'quickbooks') {
    return Boolean(integration.product_ledger_account_id && integration.product_ledger_account_id !== '')
  }
  return false
}

export const getDefaultInvoiceTaxRates = (orgCountryIso2: string | undefined): Promise<number[]> => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')

    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'feature_config/public/default_invoice_tax_rates/',
    })
      .then((response) => {
        const configData = JSON.parse(response.data.config_data)
        if (!orgCountryIso2) {
          resolve(configData?.default)
        } else {
          resolve(configData?.[orgCountryIso2])
        }
      })
      .catch((error) => {
        reject(error?.body?.message || 'Error fetching default tax rates')
      })
  })
}

export const getAccountingTaxRates = (orgId: number): Promise<AccountingTaxRateType[]> => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(window.API_ROOT + '/api')

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/accounting/tax_rates/`,
    })
      .then((response) => {
        resolve(response?.data?.data || [])
      })
      .catch((error) => {
        reject(error?.body?.message || 'Error fetching available tax rates')
      })
  })
}
