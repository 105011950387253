import { Chip as Mui4Chip, ChipProps as Mui4ChipProps } from '@material-ui/core'
import { ChipProps as Mui5ChipProps, default as Mui5Chip } from '@mui/material/Chip'
import React, { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks/useComponentVersion'

export const Chip: FC<ChipProps> = React.forwardRef((props, ref) => {
  return (
    <Inspector name="Chip" propDefs={ChipPropDefs} showExample={true} component={OsChip} props={{ ...props, ref }} />
  )
})

export enum OsChipVersion {
  V1 = 1, // MUI4
  V2 = 2, // MUI5 + Stratis UI
}
const V2_CHIP_COLORS = ['info', 'error', 'warning', 'success']
export type ChipProps = Omit<Mui5ChipProps, 'variant' | 'color'> & {
  variant?: Mui5ChipProps['variant'] | Mui4ChipProps['variant']
  color?: Mui5ChipProps['color'] | Mui4ChipProps['color']
}

const OsChip: FC<ChipProps> = forwardRef((props, ref) => {
  let version = useComponentVersion('chip')

  switch (version) {
    case OsChipVersion.V2:
      const mui5Props: Mui5ChipProps = {
        ...props,
        variant: props.variant === 'default' ? 'filled' : props.variant,
        color: !props.color || props.color === 'default' ? 'secondary' : props.color, // Change default to 'secondary'
        size: props.size || 'small', // Change default to 'small'
      }
      return <Mui5Chip ref={ref} {...mui5Props}></Mui5Chip>

    default:
      const mui4Props: Mui4ChipProps = {
        ...props,
        variant: props.variant === 'rounded' ? 'default' : (props.variant as Mui4ChipProps['variant']),
        color:
          props.color && V2_CHIP_COLORS.includes(props.color) ? 'default' : (props.color as Mui4ChipProps['color']),
      }
      return <Mui4Chip ref={ref} {...mui4Props}></Mui4Chip>
  }
})

const ChipPropDefs: PropsDefs = {
  variant: { default: 'text' },
  size: { default: 'medium' },
  color: { default: 'default' },
  label: { hidden: true },
  icon: { hidden: true },
  onDelete: { hidden: true }, // shows delete icon if set
}

// extend the spec
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    rounded: true
  }
}
