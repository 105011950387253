import { FC } from 'react'

/**
 * * Icon Component for: star-02-filled
 */
type StarIconProps = {
  size?: number
  color?: string
}
export const StarIcon: FC<StarIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M8.9455 1.10335C9.36235 0.165419 10.6384 0.165419 11.0553 1.10335L13.023 5.53088C13.1925 5.91221 13.5386 6.17492 13.938 6.2255L18.5758 6.81286C19.5583 6.93728 19.9527 8.20544 19.2278 8.90954L15.8061 12.2333C15.5114 12.5195 15.3792 12.9446 15.4566 13.3572L16.3552 18.1477C16.5456 19.1625 15.5132 19.9463 14.6483 19.4435L10.5659 17.0702C10.2143 16.8658 9.78652 16.8658 9.43492 17.0702L5.35244 19.4435C4.4876 19.9463 3.45523 19.1625 3.64558 18.1477L4.54417 13.3572C4.62156 12.9446 4.48938 12.5195 4.19469 12.2333L0.77299 8.90954C0.0481338 8.20544 0.442466 6.93728 1.42495 6.81286L6.06279 6.2255C6.46222 6.17492 6.80827 5.91221 6.97774 5.53088L8.9455 1.10335Z"
        fill={color}
      />
    </svg>
  )
}
