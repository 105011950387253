import { useState } from 'react'
import { styled } from '../../styles/styled'
import { PrequalActionLink } from './PrequalActionLink'
import { SunlightApplicationLoadingDialog } from './SunlightApplicationLoadingDialog'

const Wrapper = styled('div')(({ theme }) => {
  return {
    margin: 0,
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'center',
      margin: '15px 0px 15px 0px',
    },
  }
})

export const SunlightPrequalLinkAction = ({
  label,
  disabled,
  logoUrl,
  loadingMessage,
  onAction,
  textColor,
  description,
}: {
  label: string
  textColor: string
  disabled: boolean
  logoUrl: string
  description?: string
  loadingMessage: React.ReactNode
  onAction(): Promise<void>
}) => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false)
  const handleClick = async () => {
    setShowLoadingDialog(true)
    await onAction()
    setShowLoadingDialog(false)
  }

  return (
    <Wrapper>
      <PrequalActionLink label={label} textColor={textColor} onClick={handleClick}></PrequalActionLink>
      {!!description && <div>{description}</div>}
      <SunlightApplicationLoadingDialog show={showLoadingDialog} logoUrl={logoUrl} message={loadingMessage} />
    </Wrapper>
  )
}
