import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  wrapper: { width: '100%' },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginLeft: '5px',
    margin: '2rem',
  },
  marginleft: {
    marginLeft: '20px',
  },
  disclaimerTooltipWrapper: { display: 'flex', alignItems: 'center', color: '#757575' },
  disclaimerTooltip: {
    padding: '0 7px',
  },
})
