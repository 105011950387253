import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'

const ToolbarWrapper = styled('div')(({ theme }) => ({
  padding: 20,
  display: 'flex',
  marginTop: 'auto',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  borderTop: '1px solid ' + theme.palette.secondary.light,
}))

const DiscardBtn = styled(Button)({
  marginRight: 10,
  marginLeft: 'auto',
})

const CustomToolbar = (props) => {
  const form = useForm()
  const formValues = useFormState().values
  const translate = useTranslate()
  const handleCancel = useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.reset()
    form.setConfig('keepDirtyOnReinitialize', true)
    props.onClose()
  }, [form])

  const invalidFilters = useMemo(() => {
    return formValues?.filters?.filter(
      (x) => !x.value || (typeof x.value === 'object' && Object.keys(x.value).length === 0)
    ) || []
  }, [formValues])

  return (
    <ToolbarWrapper>
      <DiscardBtn variant="contained" color="default" onClick={handleCancel}>
        {translate('Cancel')}
      </DiscardBtn>
      <Button
        disabled={props.pristine || invalidFilters.length > 0}
        variant="contained"
        color="primary"
        onClick={props.handleSubmit}
      >
        {translate('Confirm')}
      </Button>
    </ToolbarWrapper>
  )
}
export default CustomToolbar
