import { Dialog, DialogContent, DialogTitle, Grid, Theme, useMediaQuery } from '@material-ui/core'
import {
  AccountBalanceOutlined,
  BusinessCenterOutlined,
  Check,
  ChevronRight,
  PersonOutlined,
  VerifiedUser,
} from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button, Typography, styled } from 'opensolar-ui'
import { useNotify, useRedirect, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useTranslateParse from 'util/useTranslateParse'
import PaymentChannels from './components/PaymentChannels'
import { useQuickSubmit } from './utils'

interface StyledProps {
  isMobile?: boolean
}

const StyledDialog = styled(Dialog)({
  zIndex: '10 !important',
})

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
})

const SideContainer = styled('div')({
  background: '#EDF3FF',
  borderRadius: '8px',
  padding: '1rem',
  minHeight: '70%',
  paddingBottom: '2px',
})

const HeaderContainer = styled('div')({
  textAlign: 'center',
})

const StyledCheckIcon = styled(Check)({
  fontSize: 18,
  color: '#2C499D',
  marginTop: '2.5px',
})

const StyledShieldIcon = styled(VerifiedUser)({
  height: '1.25rem',
  color: '#019939',
  padding: '10px',
  background: '#DAE5FF',
  borderRadius: 7,
  marginBottom: '10px',
})

const ListContainer = styled('div')({
  display: 'flex',
  gap: 12,
  margin: 12,
  lineHeight: 1.5,
})

const BtnRow = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  margin: '10px 0',
  gap: 10,
})

const VideoWrapper = styled('div')<StyledProps>(({ isMobile }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 840,
  maxHeight: 472,
  '& iframe': {
    position: isMobile ? 'relative !important' : 'absolute',
  },
}))

const ImageWrapper = styled('div')({
  margin: '1rem',
})

const StyledSubmitBtn = styled(Button)({
  width: 'fit-content',
  fontSize: 13,
})

const GreyContainer = styled('div')(({ theme }) => ({
  background: theme.palette.grey[50],
  borderRadius: '8px',
  padding: '1rem',
  marginTop: '12px',
  textAlign: 'center',
  paddingBottom: '6px',
}))

const ContentWrapper = styled('div')({
  width: 'fit-content',
  margin: 'auto',
})

const DescriptionWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0',
  textAlign: 'left',
})

const ActivateContainer = styled('div')({
  textAlign: 'center',
  margin: '16px 0',
})

const LinkWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
})

const LinkText = styled('span')({
  color: '#1815D0',
  cursor: 'pointer',
  textDecoration: 'underline',
})

const IconWrapper = styled('div')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey[700],
    height: '1.25rem',
    marginRight: '.5rem',
  },
}))

const StyledDialogContent = styled(DialogContent)({
  width: '87%',
  margin: 'auto',
})

type PropTypes = {
  showMarketingDialog: boolean
  setShowDialog: (boolean) => void
  setShowMarketingDialog: (boolean) => void
  setShowNonAdminDialog: (boolean) => void
}

const MarketingPage: React.FC<PropTypes> = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const quickSubmit = useQuickSubmit()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const [isloading, setIsloading] = useState<boolean>(false)

  const onNext = () => {
    props.setShowMarketingDialog(false)
    props.setShowDialog(true)
  }

  const onQuickSubmit = async () => {
    setIsloading(true)
    const submitted = await quickSubmit()
    setIsloading(false)

    if (!submitted) {
      notify('Error while sumitting quick onboarding', 'error')
      return
    }

    notify('Org successfully onboarded. Please sync PSP Webhooks from dev!', 'warning')
    redirect('/configuration')
  }

  const onClickNonAdmin = () => {
    props.setShowNonAdminDialog(true)
    props.setShowMarketingDialog(false)
  }

  return (
    <StyledDialog open={props.showMarketingDialog} maxWidth="lg">
      <DialogTitle>
        <Row>{translate('Welcome to CashFlow, the only payments system built for solar professionals')}</Row>
      </DialogTitle>
      <StyledDialogContent>
        <Grid container spacing={isMobile ? 2 : 0}>
          <Grid item xs={12} sm={8}>
            <VideoWrapper isMobile={isMobile}>
              <ResponsiveVimeoPlayer
                videoUrl="https://player.vimeo.com/video/986846106?h=a7b7fca7f0"
                trackingSource={'cashflow_onboarding'}
              />
            </VideoWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SideContainer>
              <HeaderContainer>
                <StyledShieldIcon />
                <Typography textVariant="subtitle1" colorHex="#2C499D">
                  {translate('Effortless cash collection and accounting')}
                </Typography>
              </HeaderContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('<strong> Save money <strong> on credit card fees', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('<strong> Save time <strong> and hassle processing all payments', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('Present a <strong> secure and easy-to-use <strong> payment interface', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>

              <ImageWrapper>
                <PaymentChannels />
              </ImageWrapper>
            </SideContainer>
          </Grid>
        </Grid>
        <GreyContainer>
          <Typography textVariant="h5SemiBold">{translate('Information to have on hand before you begin')}</Typography>

          <ContentWrapper>
            <DescriptionWrapper>
              <IconWrapper>
                <BusinessCenterOutlined />
              </IconWrapper>
              <Typography textVariant="body1">{translate('Legal business information')}</Typography>
            </DescriptionWrapper>

            <DescriptionWrapper>
              <IconWrapper>
                <PersonOutlined />
              </IconWrapper>
              <Typography textVariant="body1">
                {translate("Owner and/or Company Representative's personal information")}
              </Typography>
            </DescriptionWrapper>

            <DescriptionWrapper>
              <IconWrapper>
                <AccountBalanceOutlined />
              </IconWrapper>
              <Typography textVariant="body1">{translate('Business bank account information')}</Typography>
            </DescriptionWrapper>
          </ContentWrapper>
        </GreyContainer>

        <ActivateContainer>
          <Typography textVariant="body1">{translate('Administrators can activate CashFlow now')}</Typography>

          {isloading ? (
            <LoadingDots text="Please wait while we’re quickly submitting the Org onboarding..." />
          ) : (
            <BtnRow>
              <StyledSubmitBtn onClick={onNext} variant={'contained'} color={'primary'}>
                {translate('Activate CashFlow')}
              </StyledSubmitBtn>

              {!['production', 'staging4'].includes(window.ENV) && (isStaff || isSuperUser) && (
                <StyledSubmitBtn onClick={onQuickSubmit} variant={'contained'} color={'primary'}>
                  {'Onboarding Quick Submit'} <ChevronRight />
                </StyledSubmitBtn>
              )}
            </BtnRow>
          )}

          {!isAdmin && (
            <LinkWrapper onClick={onClickNonAdmin}>
              <Typography textVariant="body1">{translate("Not an Admin? We'll help you get setup.")}</Typography>
              <LinkText>{translate('I want CashFlow')}</LinkText>
            </LinkWrapper>
          )}
        </ActivateContainer>
      </StyledDialogContent>
    </StyledDialog>
  )
}
export default MarketingPage
