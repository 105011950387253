import { satisfies } from 'compare-versions'
import { FilterStruct } from 'conditions/types'
import { checkOp } from 'conditions/utils/utils'
import { UserAction } from 'contexts/userActions/types'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { SPLIT_NAMES } from 'ducks/split'
import { useAcceptedTermsDate } from 'hooks/useAcceptedTermsDate'
import lodash from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'types/state'
import { useFeatureFlagDynamic } from 'util/split'

/**
 * Returns a checker function that consumes an array of FilterStructs
 * and returns true if any of the conditions are satisfied.
 */

export const useStandardProOrgConditionChecker = () => {
  const userActions = useUserActionsContext()
  const org = useSelector(orgSelectors.getOrg)
  const auth = useSelector((state: RootState) => state.auth)
  const role = useSelector(authSelectors.getCurrentRole)
  const acceptedTermsDate = useAcceptedTermsDate()
  const location = useLocation()

  const featureChecker = useFeatureFlagDynamic()
  const inProject = !!useSelector((state: RootState) => state.project.section)

  return useCallback(
    (conditions: FilterStruct[]) => {
      const accountAge = acceptedTermsDate ? Date.now() - acceptedTermsDate.getTime() : 0
      const accountAgeDays = accountAge / (24 * 60 * 60 * 1000)

      const context = {
        org,
        auth: {
          ...auth,
          is_pro: !!role,
          is_customer: !role,
          account_age_days: accountAgeDays,
          terms_accepted_date: acceptedTermsDate?.getTime(),
        },
        role,
        user: auth?.user,
        runtime: {
          env: window.ENV,
          is_mobile_app: window.RUNNING_AS_APP,
          spa_version: window.SPA_VERSION,
          navigator_platform: navigator.platform.toLowerCase(),
        },
        project: {},
        timing: {
          now: Date.now() / 1000,
        },
        location: {
          pathname: location.pathname,
          search: location.search,
          hash: location.hash,
          state: location.state, // undocumented
        },
        feature_flags: proxyGet((target, name) => {
          return featureChecker(name.toString() as SPLIT_NAMES)
        }),
        user_actions: {
          has: proxyGet((target, name) => {
            return userActions.checkAction(name.toString())
          }),
          days_since_first: proxyGet((target, name) => {
            return daysSince(userActions.findFirstAction(name.toString()))
          }),
          days_since_last: proxyGet((target, name) => {
            return daysSince(userActions.findLastAction(name.toString()))
          }),
        },
      }

      if (inProject) {
        const paymentOptions = window.editor?.selectedSystem?.payment_options
        context.project = {
          has_cash_payment_option: !!paymentOptions?.filter((pmtOption) => pmtOption.payment_type === 'cash')?.length,
        }
      }

      return conditions.some((f) =>
        f.conditions.every((c) => {
          const checker = CUSTOM_CHECKERS[c.path] || checkOp
          return checker(context, c.path, c.op, c.value)
        })
      )
    },
    [org, auth, role, inProject, acceptedTermsDate, location, userActions.checkAction]
  )
}

const checkVersion = (context, path, op, match) => {
  const value = lodash.get(context, path)
  switch (op) {
    case 'eq':
      return satisfies(value, match)
    case 'neq':
      return !satisfies(value, match)
    case 'lt':
      return satisfies(value, '<' + match)
    case 'lte':
      return satisfies(value, '<=' + match)
    case 'gt':
      return satisfies(value, '>' + match)
    case 'gte':
      return satisfies(value, '>=' + match)
  }
}

const CUSTOM_CHECKERS = {
  'runtime.spa_version': checkVersion,
}

const daysSince = (action: UserAction | undefined) => {
  if (!action) return undefined
  return (Date.now() - new Date(action.created_date).getTime()) / (24 * 60 * 60 * 1000)
}

// util for quickly creating a getter proxy
const proxyGet = (f: (target, name) => any) =>
  new Proxy(
    {},
    {
      get: f,
    }
  )
