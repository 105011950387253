import { RootState } from 'types/state'

export const SET_STUDIO_HAS_HIGHLIGHTS = 'SET_STUDIO_HAS_HIGHLIGHTS'
export const SET_STUDIO_PROFILE_STATE = 'SET_STUDIO_PROFILE_STATE'

export const setStudioHasHighlights = (value: boolean) => ({
  type: SET_STUDIO_HAS_HIGHLIGHTS,
  payload: value,
})
export const setStudioProfileState = (value: StudioProfileState) => ({
  type: SET_STUDIO_PROFILE_STATE,
  payload: value,
})

const defaultState: StudioState = {
  hasHighlightsRow: false,
  studioProfileState: 'not-loaded',
}

export default function reducer(previousState = defaultState, { type, payload }: { type: string; payload: any }) {
  if (type === SET_STUDIO_HAS_HIGHLIGHTS) {
    return {
      ...previousState,
      hasHighlightsRow: !!payload,
    }
  } else if (type === SET_STUDIO_PROFILE_STATE) {
    return {
      ...previousState,
      studioProfileState: payload,
    }
  }
  return previousState
}

export const studioSelectors = {
  getStudioProfileState: (state: RootState) => {
    return state.studio.studioProfileState
  },
}

export type StudioState = {
  hasHighlightsRow: boolean
  studioProfileState: StudioProfileState
}

export type StudioProfileState = 'not-loaded' | 'loading' | 'loaded'
