// TODO: replace existing use case in main app
export const getIsPhoenixV2 = (integationExternalReference?: string): boolean => {
  if (!integationExternalReference) return false
  else return integationExternalReference.includes('finance_id')
}

// TODO: replace existing use case in main app
export const getAcceptProposalText = (actionTitle, quoteAcceptanceContent, paymentAmount) => {
  if (actionTitle) return actionTitle
  if ((!quoteAcceptanceContent || quoteAcceptanceContent.length === 0) && paymentAmount > 0) return 'Make Payment'
  return 'Accept Proposal'
}
