import { makeStyles, TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { Grid } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { ContactInfoType, DeliveryAddressInfoType } from '../types'

type Props = {
  contactInfo?: ContactInfoType
  deliveryAddressInfo?: DeliveryAddressInfoType
  editable?: boolean
  handleUpdateDeliveryAddress?: (input: DeliveryAddressInfoType) => void
  handleUpdateContactDetails?: (field: string, value: string) => void
  showDeliveryDetailTitle?: boolean
}

type InputProps = {
  label: string
  field: string
  value: string
  onChange: (field: string, value: string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    alignItems: 'center',
    columnGap: '12px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  sectionTitle: {
    fontSize: '14px',
    fontWeight: 550,
    color: COLOR_PALETTE.darkGrey,
  },
  sectionContent: {
    fontSize: '14px',
    fontWeight: 475,
    color: COLOR_PALETTE.darkGrey,
  },
  borderRight: {
    borderRight: `1px solid ${COLOR_PALETTE.lightGrey}`,
  },
  paddingRight: {
    paddingRight: '24px',
  },
  paddingLeft: {
    paddingLeft: '24px',
  },
}))

const Input = ({ label, field, value, onChange }: InputProps) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(field, event.target.value)
  }

  return (
    <div className={sectionStyles.column} style={{ padding: 0 }}>
      <span className={sectionStyles.inputLabel}>{label}</span>
      <TextField
        variant="outlined"
        size="small"
        style={{ width: '100%', padding: 0 }}
        onChange={handleInput}
        value={value}
        error={isEmpty(value)}
        helperText={isEmpty(value) && translate('This field is required.')}
      />
    </div>
  )
}

const DeliveryDetail: React.FC<Props> = ({
  contactInfo,
  deliveryAddressInfo,
  editable,
  handleUpdateDeliveryAddress,
  handleUpdateContactDetails,
  showDeliveryDetailTitle = true,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const address = deliveryAddressInfo
    ? `${deliveryAddressInfo.address}, ${deliveryAddressInfo.locality}, ${deliveryAddressInfo.state}, ${deliveryAddressInfo.zip}, ${deliveryAddressInfo.countryName}`
    : ''

  const handlePlaceSelected = (placeAsFields) => {
    if (handleUpdateDeliveryAddress) {
      handleUpdateDeliveryAddress({
        address: placeAsFields?.address,
        locality: placeAsFields?.locality,
        state: placeAsFields?.state,
        zip: placeAsFields?.zip,
        countryName: deliveryAddressInfo?.countryName || '',
        countryIso2: deliveryAddressInfo?.countryIso2 || '',
      })
    }
  }

  const handleInput = (field: string, value: string) => {
    if (handleUpdateContactDetails) {
      handleUpdateContactDetails(field, value)
    }
  }

  return (
    <>
      {editable && (
        <Grid container direction="column" style={{ gap: '24px' }}>
          <Grid item xs={12} className={`${classes.section} ${classes.paddingRight}`}>
            {showDeliveryDetailTitle && (
              <span className={sectionStyles.headerTitle}>{translate('Delivery Details')}</span>
            )}
            <span className={sectionStyles.inputLabel} style={{ marginBottom: '-6px' }}>
              {translate('Delivery Address')}
            </span>
            <AddressAutocomplete
              onPlaceSelected={handlePlaceSelected}
              api_key_google={apiKeyGoogle}
              types={['address']}
            />
          </Grid>
          <Grid item xs={12} className={`${classes.section} ${classes.paddingRight}`}>
            <Grid container direction="column" style={{ gap: '16px' }}>
              <Grid item xs={12}>
                <span className={sectionStyles.headerTitle}>{translate('Contact Details')}</span>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={5}>
                  <Grid item xs={5}>
                    <Input
                      label={translate('First Name')}
                      field="firstName"
                      value={contactInfo?.firstName || ''}
                      onChange={handleInput}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      label={translate('Last Name')}
                      field="lastName"
                      value={contactInfo?.lastName || ''}
                      onChange={handleInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={5}>
                  <Grid item xs={5}>
                    <Input
                      label={translate('Business Name')}
                      field="businessName"
                      value={contactInfo?.businessName || ''}
                      onChange={handleInput}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      label={translate('Email Address')}
                      field="email"
                      value={contactInfo?.email || ''}
                      onChange={handleInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={5}>
                  <Grid item xs={5}>
                    <Input
                      label={translate('Mobile number')}
                      field="phoneNumber"
                      value={contactInfo?.phoneNumber || ''}
                      onChange={handleInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!editable && (
        <Grid container>
          <Grid item xs={12} md={6} className={`${classes.section} ${classes.borderRight} ${classes.paddingRight}`}>
            <span className={classes.sectionTitle}>{translate('Deliver to')}</span>
            <span className={classes.sectionContent}>{address}</span>
          </Grid>
          <Grid item xs={12} md={6} className={`${classes.section} ${classes.paddingLeft}`}>
            <span className={classes.sectionTitle}>{translate('Contact Details')}</span>
            <span className={classes.sectionContent}>{`${contactInfo?.name} ${contactInfo?.phoneNumber}`}</span>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default memo(DeliveryDetail)
