import type { Translate } from 'ra-core'
import type {
  CheckoutActionCallback,
  CheckoutActionConfig,
  CheckoutActionElementOptions,
  MessageType,
  ProposalDataType,
} from '../../types'

const Message = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: {
  config: CheckoutActionConfig
  onAction: CheckoutActionCallback
  elementData: MessageType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}) => {
  const { actionData } = elementData

  return <p className="small">{actionData.message}</p>
}

export default Message
