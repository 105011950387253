import { Paper } from '@material-ui/core'
import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'constants/locales'
import { authSelectors } from 'ducks/auth'
import DependentInput from 'elements/input/DependentInput'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { BooleanInput, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { getLanguages } from 'resources/documentTemplates/util'
import HighlightsFields from 'resources/expoStands/blockInputs/HighlightsFields'
import { useResourceStyles } from 'resources/styles'
import { required } from 'validations'

const InputWrapper = styled(Paper, { name: 'InputWrapper' })({
  display: 'flex',
  flexDirection: 'column',
})

const ExpoHomepageForm = (props) => {
  const resourceStyles = useResourceStyles()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const languages = getLanguages(LOCALES_SUPPORTED_CUSTOMER_FACING)
  const disabled = useMemo(() => !isStaff || props.disabled, [props.disabled, isStaff])
  if (!isStaff) return null
  return (
    <SimpleForm
      {...props}
      defaultValue={{
        contents: {
          elements: [{ contentType: 'highlights', enabled: true }],
        },
      }}
    >
      <InputWrapper elevation={1} className={resourceStyles.formSection}>
        <TextInput label={'Title'} disabled={disabled} name="title" source="title" validate={required} />
        <SelectInput
          choices={languages}
          label={'Language'}
          disabled={disabled}
          fullWidth={true}
          name="locale"
          source="locale"
        />
        <BooleanInput
          label={'Is Global Homepage'}
          disabled={disabled}
          source="is_global_homepage"
          name="is_global_homepage"
        />
        <DependentInput dependsOn="is_global_homepage" value={false}>
          <TextInput
            label={'Countries Included'}
            disabled={disabled}
            fullWidth={true}
            name="countries_include"
            source="countries_include"
          />
        </DependentInput>

        <BooleanInput label={'Is Published'} disabled={disabled} source="is_published" name="is_published" />
      </InputWrapper>
      <HighlightsFields disabled={false} assetSource={'contents.assets'} {...props} />
    </SimpleForm>
  )
}

export default ExpoHomepageForm
