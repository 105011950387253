import { DATA_PROVIDER_ERROR } from 'actions/restActions'
import { useActionsListener } from 'ducks/action_listener'
import { useErrorHandling } from 'react-admin'

export const useAdditionalErrorHandling = () => {
  const errorHandling = useErrorHandling()

  // Hook into redux
  useActionsListener((payload) => {
    if (payload.type === DATA_PROVIDER_ERROR) {
      // As this is part of a reducer, this muct be delayed or it won't actually show the notification
      setTimeout(() => errorHandling.data_provider(payload.error, payload.opts), 0)
    }
  })
}
