import { default as Mui5Button } from '@mui/material/Button'
import { LoadingDots } from '../core/LoadingDots'
import { styled } from '../styles/styled'

const styles = {
  acceptButton: {
    border: '1px solid #30862C',
    fontWeight: 'normal',
    margin: 0,
    fontSize: 15,
    lineHeight: 'normal',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: '#FFFFFF',
    minWidth: 155,
    background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
  },
  acceptButtonDisabled: {
    border: '1px solid #b7b7b7',
    fontWeight: 'normal',
    margin: 0,
    fontSize: 15,
    lineHeight: 'normal',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: '#FFFFFF',
    minWidth: 155,
    background: '#dcdcdc',
    pointerEvents: 'none',
  },
}

const TransactionLabel = styled('div')(({ theme }) => {
  return {
    wordBreak: 'break-word',
    flexGrow: 1,
    padding: 0,
  }
})

const TransactionLabelWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
  }
})

const SequenceNumberWrapper = styled('div')(({ theme }) => {
  return {
    width: 44,
    height: 0,
    minWidth: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

const TransactionButton = styled(Mui5Button)(({ theme, disabled }) => {
  return {
    height: 'unset',
    minHeight: 40,
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    padding: 5,
    wordWrap: 'break-word',
    width: 'calc(100% - 2px)',
    letterSpacing: 'normal',
    ...styles.acceptButton,
    '&.Mui-disabled': {
      ...styles.acceptButtonDisabled,
    },
  }
})

export const TransactionActionButton = ({
  isLoading,
  label,
  disabled,
  onClick,
}: {
  isLoading: boolean
  label: React.ReactNode
  disabled: boolean
  onClick?(): void
}) => {
  return (
    <TransactionButton
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        if (isLoading || disabled) return
        onClick?.()
      }}
    >
      {isLoading ? (
        <LoadingDots color="#fff" />
      ) : (
        <TransactionLabelWrapper>
          <TransactionLabel>{label}</TransactionLabel>
        </TransactionLabelWrapper>
      )}
    </TransactionButton>
  )
}

export default TransactionActionButton
