import Typography from '@material-ui/core/Typography'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useSelectedSystem } from 'Designer/hooks/useSelectedSystem'
import { addOrderLineItems } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import PricingRefreshAlert from 'pages/ordering/elements/PricingRefreshAlert'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import LineItemType, { OrderLineItem } from 'pages/ordering/OrderLineItem'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { getOrderCost } from 'pages/ordering/utils'
import { useCallback, useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { currencySymbolForCountry } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 10,
    textAlign: 'right',
  },
  textWrapper: {},
}))

const BomDrawerFooter = ({ lineItems: orderableLineItems }: { lineItems: LineItemType[] }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier) as HardwareSupplierEnum
  const system = useSelectedSystem() as StudioSystemType
  const projectId = useSelector((state: any) => state.projectId)

  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)

  useEffect(() => {
    if (selectedSupplier) {
      getCartDiscount(
        orderableLineItems,
        selectedSupplier,
        (cartDiscount) => {
          setCartDiscount(cartDiscount)
        },
        'project'
      )
    }
  }, [orderableLineItems, selectedSupplier])

  const handleAddToCart = useCallback(() => {
    const lineItemsWithProjectOrder = orderableLineItems.map((lineItem) => {
      return new OrderLineItem({
        ...lineItem,
        projectOrder: [
          {
            projectId: projectId,
            // systemId is omitted as it may not be defined yet if the system hasnt been saved and it is not required downstream.
            requiredQuantity: lineItem.quantity,
          },
        ],
      })
    })
    dispatch(addOrderLineItems(lineItemsWithProjectOrder))
    notify('Hardware added to cart', 'success')

    logAmplitudeEvent('hardware_add_to_cart_button_clicked', {
      source: 'bom_drawer',
      codes: orderableLineItems.map((lineItem: LineItemType) => lineItem.code),
    })
  }, [orderableLineItems])

  const totalQuantity = orderableLineItems.reduce((sum, lineItem) => {
    return sum + (lineItem.quantity || 0)
  }, 0)

  const hasCartDiscount = cartDiscount !== null
  const subtotal = getOrderCost(orderableLineItems)
  const cartDiscountValue = getCartDiscountValue(subtotal, cartDiscount)
  const discountedTotal = subtotal - cartDiscountValue

  return (
    <div className={classes.container}>
      {hasCartDiscount && (
        <>
          <Typography variant="body2" gutterBottom className={classes.textWrapper}>
            <span>{translate('shopping_cart_action_subtotal', { smart_count: totalQuantity })}:</span>
            <span>{` ${currencySymbol}${subtotal.toLocaleString()}`}</span>
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.textWrapper}>
            <span>{translate('Design Discount (0.5%)')}:</span>
            <span>{` -${currencySymbol}${cartDiscountValue.toLocaleString()}`}</span>
          </Typography>
        </>
      )}
      <Typography variant="subtitle2" gutterBottom className={classes.textWrapper}>
        <span>{translate('Total BOM Cost')}:</span>
        <span>{` ${currencySymbol}${discountedTotal.toLocaleString()}`}</span>
      </Typography>

      <PricingRefreshAlert lineItems={orderableLineItems} />

      <Button color="primary" disabled={orderableLineItems.length === 0} onClick={handleAddToCart}>
        Add To Cart
      </Button>
    </div>
  )
}

export default BomDrawerFooter
