import { Divider } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type { CheckoutActionCallback, CheckoutActionElementOptions, ProposalDataType } from '../../types'
import type { DividerType } from '../../types/CheckoutElementsDataTypes'

const CheckoutActionDivider = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: DividerType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}) => {
  return (
    <Divider
      label={elementData.showOr !== false ? translate('or') : undefined}
      textColor={proposalData.myeStyles.textColor}
    />
  )
}

export default CheckoutActionDivider
