import { useTranslate } from 'ra-core'
import { ExpoStandCreate, ExpoStandEdit, ExpoStandList } from './ExpoStands'

const ExpoStandTitle = ({ record }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate('Expo Stands')} - {record && record.title ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: ExpoStandList,
  create: ExpoStandCreate,
  edit: ExpoStandEdit,
  options: {
    create: {
      title: 'Expo Stands',
      subtitle: null,
      breadCrumb: 'Create Expo Stand',
    },
    list: {
      title: 'Expo Stands',
      subtitle: null,
      breadCrumb: 'Expo Stands',
    },
    edit: {
      title: <ExpoStandTitle />,
      subtitle: null,
      breadCrumb: 'Edit Expo Stand',
    },
  },
}
