import React, { useRef } from 'react'
import { useMenu } from '../hooks/useMenu'
import { Menu, OsMenuProps } from './Menu'
import { OsMenuItemProps } from './MenuItem'

interface PopoutMenuProps {
  anchorEl: React.ReactElement<any>
  children: (React.ReactElement<OsMenuItemProps> | false)[]
  menuId: string
  menuProps?: Omit<OsMenuProps, 'id' | 'onClose' | 'open'>
}

export const PopoutMenu: React.FC<PopoutMenuProps> = ({
  anchorEl,
  children: menuItems,
  menuId,
  menuProps: restMenuProps,
}) => {
  const { anchorElementAriaProps, menuController, menuProps } = useMenu(menuId)
  const anchorElementRef = useRef(null)

  const anchorElementWithRef = React.cloneElement(anchorEl, {
    onClick: () => {
      menuController.handleToggleMenu()
      anchorEl.props.onClick?.()
    },
    ref: anchorElementRef,
    ...anchorElementAriaProps,
  })

  return (
    <>
      {anchorElementWithRef}
      <Menu anchorEl={anchorElementRef.current} {...restMenuProps} {...menuProps}>
        {React.Children.map(menuItems, (menuItem: React.ReactElement<OsMenuItemProps> | false) =>
          menuItem
            ? React.cloneElement(menuItem, {
                onClick: (e) => {
                  menuController.handleToggleMenu()
                  menuItem.props.onClick?.(e)
                },
              })
            : null
        )}
      </Menu>
    </>
  )
}
