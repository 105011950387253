import { FC } from 'react'
import type { IconProps } from './type'

interface ExternalLinkIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const ExternalLinkIcon: FC<ExternalLinkIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.0625 1.5H4.3125C2.7592 1.5 1.5 2.75919 1.5 4.31248V13.6875C1.5 15.2408 2.7592 16.5 4.3125 16.5H13.6875C15.2408 16.5 16.5 15.2408 16.5 13.6875V9.93745M11.812 1.50023L16.5 1.5M16.5 1.5V5.71884M16.5 1.5L8.53056 9.46848"
      />
    </svg>
  )
}
