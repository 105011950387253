import { withStyles } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { push as pushAction } from 'connected-react-router'
import { setStudioSavePrompt as setStudioSavePromptAction } from 'ducks/studioPrompt'
import { StyledDialog, StyledDialogContent } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import React from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import LeaveProjectWarningBox from '../sections/design/systems/warning/LeaveProjectWarningBox'

const defaultHeading = 'Continue?'

const StyledSaveButton = withStyles((theme: any) => ({
  root: {
    margin: '0 5px',
    // '&:hover': {
    //   background: theme.themeColorDark,
    // },

    position: 'relative',
    // [theme.breakpoints.down('xs')]: {
    //   padding: 6,
    // },
    // padding: '4px 10px',
    // background: theme.themeColor,
    // color: theme.headerFontColor,
  },
}))(Button)

const StyledButton = withStyles({
  root: {
    // color: '#1976d2',
    margin: '0 5px',
  },
})(Button)

type StateTypes = {}

type PropTypes = {
  isProjectSubmitting: boolean
}

type StatePropTypes = {
  isOpen: boolean
  promptHeading: string
  promptMessage: string
  redirect: string | Function
}

type DispatchPropTypes = {
  setStudioSavePrompt: Function
  push: Function
}

type MergedPropTypes = StatePropTypes &
  PropTypes &
  DispatchPropTypes & {
    translate: Function
  }

class _ProjectPromptDialog extends React.Component<MergedPropTypes, StateTypes> {
  componentDidUpdate(prevProps: MergedPropTypes, prevState: StateTypes) {
    if (Boolean(prevProps.isOpen) === false && Boolean(this.props.isOpen) === true) {
      // If the projectForm is already saving, immediately hide
      if (window.projectForm.getState().submitting && !window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
        console.log('Auto-closing LeaveProjectPrompt, as projectForm is already saving')
        this.props.setStudioSavePrompt()
        this.props.push(this.props.redirect)
        return
      }

      // Clear any state which can prevent dialog from being used properly
      if (window.Designer.controlMode !== 'both') {
        window.editor.signals.cycleNextMapSceneControl.dispatch('both')
        console.warn(
          'Disabling align-map mode to prevent interference with confirmation dialog. Ideally fix UI so this is not necessary.'
        )
      }
    }

    const redirectAfterSaving = !this.props.isOpen && this.props.redirect && !this.props.isProjectSubmitting
    if (redirectAfterSaving && window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
      this.props.setStudioSavePrompt()
      this.props.push(this.props.redirect)
    }
  }

  componentWillUnmount() {
    this.props.setStudioSavePrompt()
  }

  handleCancel(event: React.MouseEvent) {
    event.preventDefault()
    const { setStudioSavePrompt } = this.props
    setStudioSavePrompt()
  }

  handleContinueWithoutSave(event: React.MouseEvent) {
    event.preventDefault()
    const { push, redirect, setStudioSavePrompt } = this.props
    setStudioSavePrompt()
    if (window.isFeatureEnabled?.('hide_project_save_loader', 'on')) {
      window.WorkspaceHelper.abortSaveProject(false)
    }
    clearUnsavedData()
    if (redirect) {
      if (typeof redirect === 'function') {
        redirect()
      } else {
        push(redirect)
      }
    }
  }

  handleContinueWithSave(event: React.MouseEvent) {
    event.preventDefault()
    const { push, redirect, setStudioSavePrompt } = this.props
    if (
      window.isFeatureEnabled?.('hide_project_save_loader', 'on') &&
      (this.props.isProjectSubmitting || window.projectForm.mutators.getFormDirtyFields().length === 0)
    ) {
      setStudioSavePrompt({ redirect, isOpen: false })
      console.log('Save is either ongoing or has finished! Queueing redirect.')
      return
    }
    setStudioSavePrompt()
    const promise = window.projectForm?.submit()

    if (!promise) {
      const err = !window.projectForm
        ? "Couldn't save the project, the projectForm couldn't be found."
        : "Couldn't save the project, the project might have validation errors."
      console.error(err)
      Sentry.captureException(new Error(err))
      return
    }

    promise
      .then(() => {
        push(redirect)
      })
      .catch(() => {
        const err = "Couldn't save the project, possibly a network error."
        console.error(err)
        Sentry.captureException(new Error(err))
      })
  }

  render() {
    const { translate, isOpen, promptHeading, promptMessage } = this.props
    if (!isOpen) return null
    const heading = promptHeading || defaultHeading

    return (
      <div>
        <StyledDialog id="leave-project-prompt" open={isOpen}>
          <StyledDialogContent>
            <div>
              <div>
                <h2 style={{ fontSize: 20, fontWeight: 400 }}>{translate(heading)}</h2>
                <p style={{ color: 'rgba(0,0,0,0.54)', lineHeight: '150%' }}>
                  {translate(
                    'If you leave the project without saving, you will lose all your unapplied changes. Do you wish to continue?'
                  )}
                </p>
                <div style={{ marginBottom: 14, fontSize: 14 }}>
                  <LeaveProjectWarningBox />
                </div>

                <div>
                  <div style={{ textAlign: 'right' }}>
                    <StyledButton variant="contained" color="default" onClick={this.handleCancel.bind(this)}>
                      <span>{translate('Cancel')}</span>
                    </StyledButton>
                    <StyledButton
                      id="discard-changes"
                      variant="contained"
                      color="default"
                      onClick={this.handleContinueWithoutSave.bind(this)}
                    >
                      <span>{translate('Discard Changes')}</span>
                    </StyledButton>
                    <StyledSaveButton
                      id="save-and-continue"
                      variant="contained"
                      color="primary"
                      onClick={this.handleContinueWithSave.bind(this)}
                    >
                      <span>{translate('Save & Continue')}</span>
                    </StyledSaveButton>
                  </div>
                </div>
              </div>
            </div>
          </StyledDialogContent>
        </StyledDialog>
      </div>
    )
  }
}

const mapStateToProps = (state: any): StatePropTypes => {
  return {
    isOpen: state.studioSavePrompt?.show_prompt,
    promptHeading: state.studioSavePrompt?.prompt_heading,
    promptMessage: state.studioSavePrompt?.prompt_message,
    redirect: state.studioSavePrompt?.redirect,
  }
}

const ProjectPromptDialog = connect<StatePropTypes, DispatchPropTypes, PropTypes>(mapStateToProps, {
  setStudioSavePrompt: setStudioSavePromptAction,
  push: pushAction,
})(withTranslate(_ProjectPromptDialog))

export default ProjectPromptDialog
