import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { AddOutlined, CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { Button, LoadingDots, styled } from 'opensolar-ui'
import { GetExpectedMilestonesResponseType } from 'pages/cashFlow/types'
import { getExpectedMilestones } from 'pages/cashFlow/utils'
import { useGetPaymentRequests } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { PricingDataType } from 'types/pricing'
import { PaymentOptionSettingsOverridesType } from 'types/systems'
import { useDebouncedCallback } from 'util/Debounce'
import { generateUUID, isPricingLockedForStage } from 'util/misc'
import { createNewMilestone, updatPriceOverrideAndRecalc, useIsCalculating } from '../utils'
import MilestoneOverridePriceSummary from './MilestoneOverridePriceSummary'
import MilestoneOverrideRow from './MilestoneOverrideRow'

interface BasePropTypes {
  onClose: () => void
  milestones: ExpectedMilestonePaymentType[]
  projectId: number
  systemUuid: string
  orgId: number
  paymentOptionId: number
  pricePayable: number
  projectForm?: any // TODO
}

type FormValues = {
  overrides: ExpectedMilestonePaymentType[]
  system_price_including_tax: number | undefined
}

interface ChildPropTypes extends BasePropTypes {
  initialValues: FormValues
  projectForm: any
  projectCountryIso2?: string
  project: any
}

interface StyledProps {
  textAlign?: string | 'left' | 'center' | 'right'
}

const CloseBtn = styled(CloseOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
}))

const TitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.grey[100],
}))

const LoadingDotsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.5,
  backgroundColor: theme.palette.grey[100],
}))

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justfiyContent: 'center',
  alignItems: 'center',
})

const DescriptionContainer = styled('div')({
  lineHeight: 1.5,
  margin: '0 1rem',
})

const IndentDescription = styled('p')({
  margin: '0 0 1rem 1rem',
})

const StyledTable = styled('table')({
  borderSpacing: '0px',
  width: '100%',
  marginTop: 16,
})

const StyledTableHeader = styled('thead')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: '0px',
}))

const TableLeftColumn = styled('th')(({ theme }) => ({
  borderTopLeftRadius: '10px',
  backgroundColor: theme.palette.grey[100],
  padding: '15px 10px 15px 0px',
}))

const TableRightColumn = styled('th')(({ theme }) => ({
  borderTopRightRadius: '10px',
  width: '60px',
  backgroundColor: theme.palette.grey[100],
  padding: '15px 10px 15px 0px',
}))

const TableHeaderCell = styled('th')<StyledProps>(({ theme, textAlign }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: '15px 10px 15px 0px',
  fontWeight: 500,
  fontSize: '14px',
  textAlign: textAlign === 'right' ? 'right' : textAlign === 'center' ? 'center' : 'left',
}))

const AddMilestoneRow = styled('div')({
  borderBottom: '1px solid #F6F6F6',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '10px 0',
})

const ButtonsRow = styled('div')({
  display: 'flex',
  padding: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const RightButtonsRow = styled('div')({
  display: 'flex',
  gap: 10,
})

export const MilestoneOverrideDialogContent: React.FC<ChildPropTypes> = (props) => {
  const [priceFromLastCalc, setPriceFromLastCalc] = useState<number>(props.pricePayable)
  const [lockedFields, setLockedFields] = useState<string[]>([])
  const [editMode, setEditMode] = useState<'milestones' | 'price'>('milestones')
  const [pricingData, setPricingData] = useState<PricingDataType | undefined>(undefined)
  const [awaitingPricingCalc, setAwaitingPricingCalc] = useState<boolean>(false)
  const [calcsFinishedTimeStamp, setCalcsFinishedTimeStamp] = useState<undefined | string>(undefined)
  const [milestonesAreRefreshing, setMilestonesAreRefreshing] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(props.initialValues)
  const [hasDebouncedActionQueued, setHasDebouncedActionQueued] = useState(false)
  const [pricingIsLocked, setPricingIsLocked] = useState(false)
  const [milestoneRows, setMilestoneRows] = useState(props.initialValues.overrides)

  const formState = useFormState()
  const form = useForm()
  const translate = useTranslate()
  const notify = useNotify()
  const isCalculating = useIsCalculating(props.systemUuid)
  const milestoneRefreshTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)
  const isLoading = milestonesAreRefreshing || isCalculating || hasDebouncedActionQueued
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)

  const handleSystemCalculationChanged = useCallback(() => {
    setCalcsFinishedTimeStamp(`${new Date()}`)
  }, [pricingData, formState.values])

  // refresh the milestones on mount - this catches the scenario where the status of a milestone has changed since page load (eg user sent or cancelled one)
  useEffect(() => {
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)

    if (paymentOption) {
      setPricingData(paymentOption.pricing)
      refreshMilestones(
        formState.values.overrides,
        formState.values.system_price_including_tax,
        paymentOption?.pricing?.system_price_payable,
        true
      )
    }
  }, [])

  // refresh milestones when calcs finish or another component has requested a milestone refresh
  useEffect(() => {
    if (calcsFinishedTimeStamp || milestoneRefreshTrigger) {
      const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
      const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
      if (paymentOption) {
        setPricingData(paymentOption.pricing)
        refreshMilestones(
          formState.values.overrides,
          formState.values.system_price_including_tax,
          paymentOption?.pricing?.system_price_payable,
          true
        )
      }
    }
  }, [calcsFinishedTimeStamp, pricingData?.system_price_payable, milestoneRefreshTrigger])

  // listen for updates when calcs finish
  useEffect(() => {
    window.editor.signals.systemCalculationsUpdated.add(handleSystemCalculationChanged)
    return () => {
      window.editor.signals.systemCalculationsUpdated.remove(handleSystemCalculationChanged)
    }
  }, [])

  const system = useMemo(() => {
    if (props.systemUuid) {
      return window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    }
  }, [props.systemUuid])

  useEffect(() => {
    setPricingIsLocked(isPricingLockedForStage(props.project, orgWorkflows) && system?.override_price_locking !== true)
  }, [system?.override_price_locking])

  const paymentOption = useMemo(() => {
    return system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
  }, [system, props.paymentOptionId])

  // fetch the payment request records to make sure we have updated status values
  const { paymentRequests } = useGetPaymentRequests(props.projectId, system, paymentOption)

  const doDebouncedOnFieldChange = useDebouncedCallback(
    (overrides: object[], systemPrice: number, pricePayable: number) => {
      refreshMilestones([...overrides], systemPrice, pricePayable)
    },
    1500
  )

  const runCalcs = useCallback(() => {
    if (formState.dirty && pricingData) {
      let overrides = formState.values.overrides
      const systemPrice = formState.values.system_price_including_tax
      setHasDebouncedActionQueued(true)
      doDebouncedOnFieldChange(overrides, systemPrice, pricingData.system_price_payable)
    }
  }, [formState.values, pricingData])

  useEffect(() => {
    runCalcs()
  }, [formState.values])

  // system and payment option shouldn't be changing so this will only run on mount. But it saves this payment option's pricing data into state
  useEffect(() => {
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
    if (paymentOption) {
      setPricingData(paymentOption.pricing)
      if (awaitingPricingCalc) {
        setAwaitingPricingCalc(false)
        refreshMilestones(
          formState.values.overrides,
          formState.values.system_price_including_tax,
          paymentOption.pricing?.system_price_payable,
          true
        )
      }
    }
  }, [props.systemUuid, props.paymentOptionId])

  const refreshMilestones = (overrides, systemPrice, pricePayable, ignorePrice = false) => {
    const priceHasChanged = parseFloat(systemPrice) !== pricingData?.system_price_including_tax
    if (!ignorePrice && priceHasChanged) {
      // take the new price that the user entered and put it in this system's price override. This triggers a recalculation
      updatPriceOverrideAndRecalc(props.systemUuid, props.paymentOptionId, systemPrice)
      // since we upated the price override mark the project form as dirty to make sure it's clear that there is now an unsaved change on the system
      props.projectForm.mutators.markFieldAsDirty('design')
      props.projectForm.change('design', 'has unsaved change')
      // update state so that we know we're waiting for calcs to finish. This lets us refresh the milestones after calcs are compelte
      setAwaitingPricingCalc(true)
    } else {
      // don't bother running milestone refresh if there is an incomplete milestone
      let incompleteMilestone = overrides?.find((override) => {
        return !override?.title?.trim() || !override.fixed_override_amount
      })
      if (incompleteMilestone) {
        setHasDebouncedActionQueued(false)
        return
      }
      setMilestonesAreRefreshing(true)
      overrides?.forEach((override, i) => {
        const fieldKey = `overrides.${i}.fixed_override_amount`
        const isDirty = lockedFields?.includes(fieldKey) || formState?.dirtyFields?.[fieldKey] === true
        if (!override?.fixed_override_amount) {
          override.fixed_override_amount = parseFloat(override?.payment_amount)
        } else {
          override.fixed_override_amount = parseFloat(override?.fixed_override_amount)
        }

        // if the user edits a field then we should honor the fixed amount they entered even if they change the system price
        // if the field is untouched then that payment amount should change when systemPrice changes
        if (!isDirty) {
          // when we populate override_percentage the back-end will use that percentage to figure out what the proper
          // payment amount should be for the updated price payable. When percentage is left unpopulated then the fixed_override_amount will be used
          // regardless of whether the price changes
          override.override_percentage = (override.fixed_override_amount / priceFromLastCalc) * 100
        } else {
          override.override_percentage = null
          if (!lockedFields.includes(fieldKey)) {
            // track that this field was edited at one point because we reset form state when we get a response from the API
            setLockedFields([...lockedFields, fieldKey])
          }
          // this one is a little weird, basically we are tracking that we ran calcs with this field having changed
          // at some point in the session. But it doens't necesssarily mean this field was JUST changed. It could be that the
          // user changed milestone 2, then calculated, then milestone 1, then calculated, and milestone 2 again. But we'd make 3 events
          // for milestone 2
          logAmplitudeEvent('cashflow_adjust_payment_update_individual_payment', {
            payment_number: i + 1,
            project_id: props.projectId,
            org_id: props.orgId,
          })
        }
        if (!override.override_uuid) override.override_uuid = generateUUID()
      })
      getExpectedMilestones(
        props.orgId,
        props.projectId,
        props.systemUuid,
        props.paymentOptionId,
        overrides,
        pricePayable ? parseFloat(pricePayable) : undefined
      )
        .then((res: GetExpectedMilestonesResponseType) => {
          setPriceFromLastCalc(res.data.total_amount_payable)
          let newFormVals = res.data.expected_milestone_payments
          newFormVals.forEach((override) => {
            let matchingMilestonePayment = res.data?.expected_milestone_payments?.find(
              (milestone) => milestone.payment_number === override.payment_number
            )
            if (matchingMilestonePayment) override.fixed_override_amount = matchingMilestonePayment?.payment_amount
          })
          // reset the form so we can listen for future edits to fields
          setMilestoneRows(newFormVals)
          form.reset({ overrides: newFormVals, system_price_including_tax: systemPrice })
        })
        .catch((err) => {
          if (typeof err === 'string') notify(err, 'warning')
          else if (err?.body?.message) notify(err?.body?.message, 'warning')
          else notify(translate('Something went wrong'), 'warning')
        })
        .finally(() => {
          setMilestonesAreRefreshing(false)
          setHasDebouncedActionQueued(false)
        })
    }
  }

  const getMilestonesAreValid = () => {
    if (!formState.values?.system_price_including_tax) {
      notify('the Total Amount Invoiced must be greater than 0', 'warning')
      return false
    } else {
      if (!formState.values.overrides?.length) {
        notify('You must have at least one milestone', 'warning')
        return false
      }
      let milestoneSum = 0
      let areMilestonesFieldsValid = true
      formState.values.overrides?.forEach((override) => {
        if (!override.title.trim()) {
          notify('All Milestones must have a title', 'warning')
          areMilestonesFieldsValid = false
        } else if (
          (!override.payment_amount && !override.fixed_override_amount) ||
          override.fixed_override_amount < 0
        ) {
          notify('All Milestones must have an Amount Invoiced greater than 0', 'warning')
          areMilestonesFieldsValid = false
        }
        milestoneSum += override.fixed_override_amount
      })
      if (!areMilestonesFieldsValid) return false

      const areAllPaid = formState.values.overrides?.every((override) => override?.is_paid)
      if (areAllPaid && formState.values.system_price_including_tax > milestoneSum) {
        notify('Please add a new milestone before increasing the System Price', 'warning')
        return false
      }

      milestoneSum = parseFloat(milestoneSum.toFixed(2))
      if (milestoneSum > formState.values.system_price_including_tax) {
        notify('The sum of all milestones may not be higher than the Total Amount Invoiced', 'warning')
        return false
      } else if (pricingData?.system_price_payable && milestoneSum < pricingData?.system_price_payable) {
        notify('The sum of all milestones may not be lower than the Total Amount Invoiced', 'warning')
        return false
      }
    }
    return true
  }

  const saveToSystem = async () => {
    if (getMilestonesAreValid()) {
      const system = window.editor.objectByUuid(props.systemUuid)
      const overrideMap = system.milestone_payment_overrides || {}
      system.payment_options?.forEach((pmt) => {
        if (pmt.id === props.paymentOptionId) {
          const overridesWithPercent = formState.values.overrides?.map((override) => {
            return {
              ...override,
              override_percentage: (override.fixed_override_amount / priceFromLastCalc) * 100,
            }
          })
          overrideMap[pmt.id] = overridesWithPercent
        }
      })

      // update the milestone_payment_overrides map on the system level
      window.editor.execute(new window.SetValueCommand(system, 'milestone_payment_overrides', overrideMap))
      // We also need to update the down payment override for this payment option to make sure it matches the override they just submitted
      const currentPricingOverride: PaymentOptionSettingsOverridesType = {
        ...system?.payment_options_settings_overrides,
      }
      const newDownPayment = overrideMap[props.paymentOptionId][0].fixed_override_amount
      if (currentPricingOverride[props.paymentOptionId]) {
        currentPricingOverride[props.paymentOptionId].down_payment = newDownPayment
      } else {
        currentPricingOverride[props.paymentOptionId] = {
          down_payment: newDownPayment,
          price: undefined,
        }
      }
      window.editor.execute(
        new window.SetValueCommand(system, 'payment_options_settings_overrides', currentPricingOverride)
      )
      props.projectForm.mutators.markFieldAsDirty('design')
      props.projectForm.change('design', 'has unsaved change')
      props.onClose()
      logAmplitudeEvent('cashflow_adjust_payments_changes_saved', {
        project_id: props.projectId,
        org_id: props.orgId,
      })
    }
  }

  // injects an empty milestone into the second to last slot since the last slot cannot be edited
  const addMilestone = () => {
    let newMilestoneIndex = formState?.values?.overrides?.length - 1
    const EMPTY_MILESTONE: ExpectedMilestonePaymentType = createNewMilestone(formState?.values?.overrides)
    let newMilestones: ExpectedMilestonePaymentType[] = []
    formState?.values?.overrides?.forEach((existingMilestone, i) => {
      if (i === newMilestoneIndex) {
        newMilestones.push(EMPTY_MILESTONE)
        existingMilestone.payment_number += 1
      }
      existingMilestone.fixed_override_amount =
        existingMilestone.fixed_override_amount || existingMilestone.payment_amount
      newMilestones.push(existingMilestone)
    })
    setMilestoneRows(newMilestones)
    form.reset({ overrides: newMilestones, system_price_including_tax: pricingData?.system_price_including_tax })
    logAmplitudeEvent('cashflow_adjust_payment_milestone_added', {
      payment_number: newMilestoneIndex,
      project_id: props.projectId,
      org_id: props.orgId,
    })
  }

  const deleteMilestone = (paymentNumberToRemove: number) => {
    let newMilestones = formState?.values?.overrides
      ?.filter((milestone) => milestone.payment_number !== paymentNumberToRemove)
      ?.map((milestone, i) => {
        milestone.payment_number = i + 1
        return milestone
      })
    form.change('overrides', newMilestones)
    logAmplitudeEvent('cashflow_adjust_payment_milestone_removed', {
      payment_number: paymentNumberToRemove,
      project_id: props.projectId,
      org_id: props.orgId,
    })
  }

  const doReset = () => {
    let reorderedInitialValues: FormValues = initialValues
      ? { ...initialValues }
      : { overrides: [], system_price_including_tax: 0 }
    reorderedInitialValues.overrides = reorderedInitialValues?.overrides?.map((override, i) => {
      override.payment_number = i + 1
      return override
    })
    refreshMilestones(
      reorderedInitialValues.overrides,
      reorderedInitialValues.system_price_including_tax,
      paymentOption?.pricing?.system_price_payable,
      true
    )
    form.reset(reorderedInitialValues)
    logAmplitudeEvent('cashflow_adjust_payments_reset_clicked', {
      project_id: props.projectId,
      org_id: props.orgId,
    })
  }

  const moveMilestoneUp = (paymentNumber: number) => {
    const milestoneToReplace = formState.values?.overrides[paymentNumber - 2]
    const milestoneToMoveUp = formState.values?.overrides[paymentNumber - 1]
    if (milestoneToReplace && milestoneToMoveUp) {
      milestoneToMoveUp.payment_number = milestoneToMoveUp.payment_number - 1
      milestoneToReplace.payment_number = milestoneToReplace.payment_number + 1
      let newOverrides = [...formState.values.overrides]
      newOverrides[paymentNumber - 2] = milestoneToMoveUp
      newOverrides[paymentNumber - 1] = milestoneToReplace
      setMilestoneRows(newOverrides)
      form.reset({ overrides: newOverrides, system_price_including_tax: pricingData?.system_price_including_tax })
    }
    logAmplitudeEvent('cashflow_adjust_payment_milestone_moved', {
      direction: 'up',
      new_payment_number: paymentNumber,
      original_payment_number: paymentNumber - 1,
      project_id: props.projectId,
      org_id: props.orgId,
    })
  }

  const moveMilestoneDown = (paymentNumber: number) => {
    const milestoneToReplace = formState.values?.overrides[paymentNumber]
    const milestoneToMoveDown = formState.values?.overrides[paymentNumber - 1]
    if (milestoneToReplace && milestoneToMoveDown) {
      milestoneToMoveDown.payment_number = milestoneToMoveDown.payment_number + 1
      milestoneToReplace.payment_number = milestoneToReplace.payment_number - 1
      let newOverrides = [...formState.values.overrides]
      newOverrides[paymentNumber] = milestoneToMoveDown
      newOverrides[paymentNumber - 1] = milestoneToReplace
      setMilestoneRows(newOverrides)
      form.reset({ overrides: newOverrides, system_price_including_tax: pricingData?.system_price_including_tax })
    }
    logAmplitudeEvent('cashflow_adjust_payment_milestone_moved', {
      direction: 'down',
      new_payment_number: paymentNumber + 1,
      original_payment_number: paymentNumber,
      project_id: props.projectId,
      org_id: props.orgId,
    })
  }

  // To prevent closing before calcs are finished.
  const doNothing = useCallback(() => {}, [])
  return (
    <Dialog open={true} onClose={isLoading ? doNothing : props.onClose} maxWidth={'lg'}>
      <DialogTitle>
        <TitleWrapper>
          <div>{translate('Adjust Payments')}</div>
          <div>
            <IconButton onClick={props.onClose}>
              <CloseBtn />
            </IconButton>
          </div>
        </TitleWrapper>
      </DialogTitle>
      <DialogContent>
        <Content>
          <div>
            <div>
              <div>
                <p>
                  {translate(
                    'The table below allows you to make two different types of changes to the payment workflow:'
                  )}
                </p>
              </div>
              <DescriptionContainer>
                <span>{translate('1. Update milestone amounts or add milestones')}</span>
                <IndentDescription>
                  {translate(
                    'Changing milestone amounts or adding new milestone amounts will update the invoices for the current amount due. Adding a milestone does not increase the total amount due. All changes will impact the final milestone.'
                  )}
                </IndentDescription>
                <span>{translate('2. Update system price')}</span>
                <IndentDescription>
                  {translate(
                    'Click the system price at the bottom to change total amount due for this project. The new payment amount will impact unsent invoice amounts proportionally.'
                  )}
                </IndentDescription>
              </DescriptionContainer>
              <p>
                {translate(
                  "If you'd like to edit the payment amount for an invoice you've already sent please cancel it first."
                )}
              </p>
            </div>
          </div>
          <StyledTable data-testid="milestone-override-table">
            <StyledTableHeader>
              <TableLeftColumn></TableLeftColumn>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>{translate('Milestone')}</TableHeaderCell>
              <TableHeaderCell>{translate('Description')}</TableHeaderCell>
              <TableHeaderCell>{translate('Status')}</TableHeaderCell>
              <TableHeaderCell textAlign="center">{translate('Milestone %')}</TableHeaderCell>
              <TableHeaderCell textAlign="right">{translate('Amount Invoiced')}</TableHeaderCell>
              <TableRightColumn></TableRightColumn>
            </StyledTableHeader>
            <tbody>
              {milestoneRows.map((milestone, i) => (
                <MilestoneOverrideRow
                  milestone={milestone}
                  key={milestone?.payment_amount + milestone.payment_number}
                  isLast={milestone.payment_number === formState?.values?.overrides.length}
                  pricePayable={props.pricePayable}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  deleteMilestone={deleteMilestone}
                  projectCountryIso2={props.projectCountryIso2}
                  moveMilestoneUp={moveMilestoneUp}
                  moveMilestoneDown={moveMilestoneDown}
                  allPaymentRequests={paymentRequests}
                />
              ))}
            </tbody>
          </StyledTable>
          <AddMilestoneRow>
            <Button
              onClick={addMilestone}
              size="small"
              disabled={formState?.values?.overrides?.length > 4}
              data-testid="add-milestone-btn"
            >
              <AddOutlined /> {translate('Add a milestone')}
            </Button>
          </AddMilestoneRow>

          {pricingData && props.projectCountryIso2 && (
            <MilestoneOverridePriceSummary
              projectCountryIso2={props.projectCountryIso2}
              pricingData={pricingData}
              editMode={editMode}
              setEditMode={setEditMode}
              pricingIsLocked={pricingIsLocked}
            />
          )}
        </Content>
        {(milestonesAreRefreshing || isCalculating) && (
          <LoadingDotsWrapper>
            <LoadingDots />
          </LoadingDotsWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonsRow>
          <div>
            <Button onClick={props.onClose} disabled={isLoading} color="primary" variant="outlined">
              {translate('Cancel')}
            </Button>
          </div>
          <RightButtonsRow>
            <Button onClick={doReset} variant="outlined" color="primary">
              {translate('Reset')}
            </Button>
            <Button onClick={saveToSystem} disabled={isLoading} color="primary" variant="contained">
              {translate('Save')}
            </Button>
          </RightButtonsRow>
        </ButtonsRow>
      </DialogActions>
    </Dialog>
  )
}

const MilestoneOverrideForm: React.FC<BasePropTypes> = (props) => {
  const projectForm = useForm()
  const project = useFormState().values

  const [initialValues, setInitialValues] = useState<FormValues | undefined>(undefined)

  // populate initial values just once when we frist pen the form. Pass that as a prop so the form can be reliably reset to its original state
  useEffect(() => {
    let initialMilestones = props.milestones?.map((milestone) => {
      milestone.fixed_override_amount = milestone.payment_amount
      return milestone
    })
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
    setInitialValues({
      overrides: initialMilestones,
      system_price_including_tax: paymentOption?.pricing?.system_price_including_tax,
    })
  }, [])

  // onSubmit is a required prop for the Form component, but we do not need its behavior so just pass an empty function
  const doNothing = useCallback(() => {}, [])

  if (!initialValues) return null

  return (
    <Form
      onSubmit={doNothing}
      initialValues={initialValues}
      render={() => (
        <MilestoneOverrideDialogContent
          {...props}
          project={project}
          projectForm={projectForm}
          projectCountryIso2={project?.country_iso2}
          initialValues={initialValues}
        />
      )}
    />
  )
}
export default MilestoneOverrideForm
