import lodash from 'lodash'
import { SdkConfig, SdkInnerConfig } from '../types/config'
import { isNestedWindow } from './browser'
import { client_check } from './client_check'
import { LoggerAPI } from './logger'
import { getOsUrl } from './network'
import { getQueryVariable } from './query'

const isNestedWithinOs = (hostname_spa?: string) => {
  const isNested = isNestedWindow()
  return isNested && window.document?.referrer === `${hostname_spa}/`
}

export async function buildConfig(config: SdkConfig, logger: LoggerAPI = console): Promise<SdkInnerConfig> {
  let nested = isNestedWindow()

  let master = config.master === undefined ? nested : config.master

  //@ts-ignore
  const envApiRoot: string | undefined = window.API_ROOT_ABSOLUTE

  if (nested) {
    // Attempt to pull config from query string
    const configStr = getQueryVariable('config')
    if (configStr) {
      try {
        const queryOpts = JSON.parse(decodeURIComponent(configStr))
        config = { ...queryOpts, ...config }
      } catch (e) {
        throw new Error('Failed to parse config from query string, SDK will not load: ' + configStr)
      }
    } else if (master) {
      config = {
        hostname_spa: window.location.origin,
        hostname_api: envApiRoot,
        ...(config || {}),
      }
    }

    // Attempt to pull SDK key from query string
    const sdkKey = getQueryVariable('sdk_key')
    if (sdkKey) config.key = sdkKey
  }

  let env = config.env
  if (!env) {
    // Fallback to 'production', this is only used to assume the right hostnames
    env = 'production'
  }

  let hostname_spa = config.hostname_spa
  if (!hostname_spa && env) {
    hostname_spa = getOsUrl('spa', env)
  }
  if (!hostname_spa) {
    throw new Error(`Couldn't resolve 'hostname_spa', specify an 'env' or explicitly define 'hostname_spa'`)
  }

  let hostname_api = config.hostname_api
  if (!hostname_api && env) {
    hostname_api = getOsUrl('api', env)
  }
  if (!hostname_api) {
    throw new Error(`Couldn't resolve 'hostname_api', specify an 'env' or explicitly define 'hostname_api'`)
  }

  // Note: is_internal cannot be relied upon for security checks, as it is determined by the config sent in by the SDK user
  // It is useful to generate configuration warnings/errors only
  const is_internal = window.location.origin === hostname_spa && master
  if (!config.key && (!is_internal || nested)) {
    // This check needs to remain quite stict to avoid things like 'localhost.badactor.com'
    const onLocalhost = window.location.hostname === 'localhost'

    // Note: this is just to help 3rd party devs, not a security check
    if (onLocalhost) {
      logger.warn("Running in localhost without a key, this won't work in other environments")
    } else if (isNestedWithinOs(hostname_spa)) {
      // Note: this is a temporary solution to bypass the key check for internal OS Apps
      logger.warn('Running inside internal OS App without a key, skipping remote config fetch')
    } else {
      throw new Error('Must provide a key when running in a 3rd party context')
    }
  }

  if (is_internal && envApiRoot) {
    // This checks that the API and SPA hostnames match and is only executed internally to the iframe
    // Note, this is not a security check, this is purely to help developers
    if (envApiRoot !== hostname_api)
      throw new Error(
        "hostname_api doesn't match configuration in loaded app (via hostname_spa), please check configuration."
      )
    // Always use the API_ROOT_ABSOLUTE if it's available
    config.hostname_api = envApiRoot
  }

  let remoteConfigs
  try {
    // The real security check happens here, and this is execute on both side of the iframe
    remoteConfigs = config.key ? await getRemoteConfigs(config.key, hostname_api) : { low: {}, high: {} }
  } catch (e) {
    throw new Error(`Invalid client key: ${config.key}`)
  }

  // Remove any forbidden keys from the config sent in
  let strippedConfig = lodash.omit(config, ['domain_whitelist'])

  const ret: SdkInnerConfig = lodash.defaultsDeep(
    {}, // here because the first arg is mutated
    // defaultsDeep preserves the first encountered value, so we want to start with the highest priority
    remoteConfigs.high,
    {
      is_internal,
      nested,
      master,
      hostname_spa,
      hostname_api,
    },
    strippedConfig,
    remoteConfigs.low
  )

  if (ret.domain_whitelist && !client_check(ret.domain_whitelist, nested, document.referrer)) {
    throw new Error('Domain not whitelisted: ' + document.referrer)
  }

  logger.info('SDK Config resolved: ', ret)
  return ret
}
async function getRemoteConfigs(
  key: string,
  hostname_api: string
): Promise<{ low: Partial<SdkInnerConfig>; high: Partial<SdkInnerConfig> }> {
  const url = `${hostname_api}/api/sdk_client_config/${key}/`
  const response = await fetch(url, {
    method: 'GET',
  })
  return await response.json()
}
