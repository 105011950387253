import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CheckoutFinancingCard as CheckoutFinancingCardUi } from 'opensolar-ui'
import { CheckoutFinanceAction } from 'opensolar-ui/src/checkout/checkoutFinancing/types'
import { useTranslate } from 'ra-core'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import useCheckoutFinancingDialog from './useCheckoutFinancingDialog'

type PropTypes = {
  actionData: CheckoutFinanceAction
  countryIso2: string
  expectedMilestonePayments?: ExpectedMilestonePaymentType[]
  isProposal?: boolean
}

const CheckoutFinancingCard = (props: PropTypes) => {
  const { actionData, expectedMilestonePayments, isProposal, countryIso2 } = props
  const { dialogNode, openDialog } = useCheckoutFinancingDialog()
  const translate = useTranslate()

  if (actionData?.hidden === true) return null

  const handleClick = () => {
    logAmplitudeEvent('checkout_finance_dialog_learn_more_clicked', {
      org_id: actionData?.org_id,
      project_id: actionData?.project_id,
    })
    openDialog({
      orgId: actionData?.org_id,
      projectId: actionData?.project_id,
      countryIso2: countryIso2,
      systemUuid: actionData?.system_uuid,
      paymentOptionId: actionData?.payment_option_id,
      expectedMilestonePayments: expectedMilestonePayments,
      amountDue: actionData?.pricing?.system_price_including_tax,
    })
  }

  return (
    <>
      <CheckoutFinancingCardUi isProposal={isProposal} onClick={handleClick} translate={translate} />
      {dialogNode}
    </>
  )
}

export default CheckoutFinancingCard
