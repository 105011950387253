import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { BOOLEAN_CHOICES } from '../fields'

interface PropTypes {
  source: string
}

const ProjectType: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const filterField = useMemo(() => lodash.get(formValues, source), [formValues, source])
  const selected = useMemo(() => filterField?.fields?.filters?.split(',') || [], [filterField.fields?.filters])
  const conditional = useMemo(() => filterField?.fields?.conditional, [filterField?.fields?.conditional])
  const isResidential = useMemo(() => selected.find((x) => x.includes('is_residential')), [selected])
  const PROJECT_TYPE_CHOICES = useMemo(
    () => [
      { id: 'is_lite', name: 'OpenSolar Lite' },
      { id: 'is_residential', name: translate('Residential') },
      { id: '-is_residential', name: translate('Commercial') },
    ],
    []
  )
  const handleChange = (choice) => {
    let newVal = choice.id
    let newValues = [...selected]
    if (newVal !== 'is_lite' && isResidential && isResidential !== newVal) {
      const delIndex = newValues.indexOf(isResidential)
      newValues.splice(delIndex, 1)
      newValues.push(newVal)
    } else {
      newValues = newValues?.includes(newVal) ? newValues.filter((x) => x !== newVal) : [...newValues, newVal]
    }
    form.change(`${source}.fields.filters`, newValues.toString())
  }

  useEffect(() => {
    if (conditional !== undefined && !!selected.length) {
      let newValue = {}
      if (selected.includes('is_lite')) newValue['is_lite'] = conditional
      if (isResidential) newValue['is_residential'] = isResidential.includes('-') ? !conditional : conditional
      form.change(`${source}.value`, newValue)
    }
  }, [selected, conditional])

  return (
    <>
      <SelectInput
        choices={BOOLEAN_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
        style={{ minWidth: '100px' }}
        defaultValue={null}
      />
      <CheckboxMultiSelectInput
        source={`${source}.fields.filters`}
        choices={PROJECT_TYPE_CHOICES}
        variant={'outlined'}
        label={false}
        style={{ width: '100%' }}
        color={'secondary'}
        onChange={handleChange}
      />
    </>
  )
}

export default ProjectType
