import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { PropsDefs } from './types'

const useStyles = makeStyles(
  {
    root: {
      marginLeft: 10,
    },
    row: {},
    label: {
      display: 'inline-block',
    },
    value: {
      display: 'inline-block',
      color: 'lightblue',
    },
  },
  { name: 'PropsTable' }
)

type PropsTableProps = {
  propDefs: PropsDefs
  props: any
}

export const PropsTable: FC<PropsTableProps> = ({ propDefs, props }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {Object.entries(propDefs).map(([propName, propDef]) => {
        return propDef.hidden ? null : (
          <div key={propName} className={classes.row}>
            <div className={classes.label}>{propName + '='}</div>
            <div className={classes.value}>
              {props?.hasOwnProperty(propName)
                ? JSON.stringify(props[propName])
                : propDef.default !== undefined
                ? JSON.stringify(propDef.default) + ' (using default)'
                : 'undefined'}
            </div>
          </div>
        )
      })}
    </div>
  )
}
