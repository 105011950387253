import { makeStyles } from '@material-ui/core'
import { AlertProps as Mui5AlertProps, default as Mui5Alert } from '@mui/material/Alert'
import classnames from 'classnames'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { AlertIcon } from './AlertIcon'

// * MUI Alert API Spec  https://mui.com/material-ui/react-alert/
export const Alert: FC<OsAlertProps> = (props) => {
  return <Inspector name="Alert" propDefs={AlertProps} showExample={true} component={OsAlert} props={props} />
}

export enum OsAlertVersion {
  V1 = 1, // OS UI 2.0
  V2 = 2, // MUI5 + Stratis
}
export type Severities = Mui5AlertProps['severity']
export type OsAlertProps = Omit<Mui5AlertProps, 'icon'>

const OsAlert: FC<OsAlertProps> = forwardRef((props, ref) => {
  let { severity = 'info', variant = 'standard', ...rest } = props
  let version = useComponentVersion('alert')

  switch (version) {
    case OsAlertVersion.V2:
      return (
        <Mui5Alert ref={ref} variant={variant} icon={<AlertIcon severity={severity} />} severity={severity} {...rest} />
      )

    default:
      return <V1Alert ref={ref} icon={<AlertIcon severity={severity} />} severity={severity} {...rest} />
  }
})

const AlertProps: PropsDefs = {
  severity: { default: 'info' },
  variant: { default: 'standard' },
  color: {},
  icon: { hidden: true },
  actions: { hidden: true },
  children: { hidden: true },
}

const v1Styles = makeStyles<any, { severity: Severities }>((theme) => ({
  wrapper: ({ severity }) => ({
    display: 'flex',
    background: theme['alertBg_' + severity],
    color: theme['alert_' + severity],
    borderRadius: 4,
    padding: 16,
    alignItems: 'center',
  }),
  content: {
    paddingTop: 3,
    width: '100%',
  },
}))

const V1Alert: FC<Mui5AlertProps> = forwardRef((props, ref) => {
  const { severity, children, icon, className, ...rest } = props
  const classes = v1Styles({ severity })

  return (
    <div className={classnames(classes.wrapper, className)} ref={ref} {...rest}>
      {icon}
      <div className={classes.content}>{children}</div>
    </div>
  )
})
