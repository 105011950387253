import { PanelConfiguration } from "types/global"

/* NOCT is defined as the temperature reached by open circuited cells in a module under the conditions as listed below:
 1. Irradiance on cell surface = 800 W/m2
 2. Air Temperature = 20°C
 3. Wind Velocity = 1 m/s
 4. Mounting = open back side.
 https://www.pveducation.org/pvcdrom/modules-and-arrays/nominal-operating-cell-temperature
*/

export const NOCT_IRRADIANCE = 800
export const NOCT_AIR_TEMP = 20
export const AVERAGE_MAX_TEMP_IRRADIANCE = 1000

// Most panels are 45+/-2oC NOCT. Since it assumes open backside we assume tilt and dual tilt racks are 45oC and default/standard are 47oC
export const DEFAULT_NOCT = 47      
const STANDARD_NOCT = 47            
const TILT_RACK_NOCT = 45          
const DUAL_TILT_RACK_NOCT = 45


export const noctByPanelConfig : Record<PanelConfiguration, number> = {
    STANDARD: STANDARD_NOCT,
    TILT_RACK: TILT_RACK_NOCT,
    DUAL_TILT_RACK: DUAL_TILT_RACK_NOCT
}