import { makeStyles } from '@material-ui/styles'
import { orgSelectors } from 'ducks/orgs'
import { Stepper } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useContext, useEffect, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { CashFlowSetupContext } from '../CashFlowContext'
import { useAccountingIntegrationsAreVisible } from '../configuration/accounting/utils'
import { updateSetupProgress } from '../utils'

type PropTypes = {
  activeStep: number
}

const useStyles = makeStyles<OpenSolarThemeType, { completionPercentage: number }>((theme) => ({
  container: {
    background: theme.white,
    padding: '10px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },
  header: {
    fontSize: 18,
    margin: 10,
    fontWeight: 400,
  },
  label: {
    marginRight: 10,
  },
  progressBarWrapper: {
    margin: '0 10px',
  },
  progressBarContainer: {
    height: 5,
    borderRadius: 10,
    background: theme.greyLight1,
  },
  progressBar: {
    height: 5,
    width: ({ completionPercentage }) => completionPercentage + '%',
    borderRadius: 10,
    background: '#019939',
  },
  linkText: {
    textDecoration: 'none',
  },
}))

const CashFlowSetupChecklist: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)
  const cashflowSetupContext = useContext(CashFlowSetupContext)

  const blueSnapOnboardingStatus = useSelector(orgSelectors.getCashFlowOnboardingStatus)

  const steps = [
    {
      step: 1,
      label: blueSnapOnboardingStatus === 'conditionally approved' ? 'Finalize Approval' : 'CashFlow Approval',
      stepId: 'cashflow_setup', // filler, just so it has an id.
      path: blueSnapOnboardingStatus === 'conditionally approved' ? '/cashflow/configure/documents' : undefined, // there should be no path to return to cashflow setup since it's already finished
    },
    {
      step: 2,
      stepId: 'payment_milestones',
      label: 'Review Payment Milestones',
      path: '/cashflow/configure/milestones',
      complete_on_dismount: true,
    },
    {
      step: 3,
      stepId: 'financed_deposits',
      label: 'Review Financed Project Deposits',
      path: '/cashflow/configure/financed_deposits',
      complete_on_dismount: true,
    },
    {
      step: 4,
      stepId: 'customer_communications',
      label: 'Review Customer Communications',
      path: '/cashflow/configure/communications',
      complete_on_dismount: true,
    },
    {
      step: 5,
      stepId: 'internal_notifications',
      label: 'Review Internal Notifications',
      path: '/cashflow/configure/notifications',
      complete_on_dismount: true,
    },
    {
      step: 6,
      stepId: 'accounting',
      label: 'Accounting',
      path: '/cashflow/configure/accounting',
      complete_on_dismount: false,
    },
  ]

  const accountingVisible = useAccountingIntegrationsAreVisible()

  // TODO viewing the pages doesn't SAVE the progress, on dismount it's all lost
  // TODO accounting tab doesn't show as yellow when being viewed

  const stepsAdjustedForOrg = useMemo(() => {
    return steps?.filter((step) => {
      if (step.stepId === 'accounting_integrations' && !accountingVisible) {
        return false
      }
      return true
    })
  }, [steps, accountingVisible])
  const progress = stepsAdjustedForOrg.reduce((acc, cur, idx) => {
    if (idx === 0) {
      if (blueSnapOnboardingStatus === 'conditionally approved') {
        acc[idx] = false
      } else {
        acc[idx] = true
      }
    } else {
      acc[idx] = cashflowSetupContext?.setupProgress?.[cur.stepId] || false
    }
    return acc
  }, {})
  const completionPercentage = Object.values(progress).filter((s) => s).length * (100 / stepsAdjustedForOrg.length) || 0
  const classes = useStyles({ completionPercentage })
  const isLoading = cashflowSetupContext.isFetchingSetupProgress
  const hasLoaded = Boolean(
    !cashflowSetupContext.isFetchingSetupProgress &&
      cashflowSetupContext.setupProgress &&
      Object.keys(cashflowSetupContext.setupProgress).length
  )
  const failedToLoad = Boolean(
    !cashflowSetupContext.isFetchingSetupProgress &&
      cashflowSetupContext.setupProgress &&
      Object.keys(cashflowSetupContext.setupProgress).length === 0
  )
  const cashflowAccountingConnected = useSelector(orgSelectors.getActiveAccountingIntegrations)?.length

  // if the accounting integration is connected but the back-end hasn't logged that in the setup progress, update it
  useEffect(() => {
    if (org?.id && cashflowAccountingConnected) {
      // TODO
      //@ts-ignore
      if (cashflowSetupContext.setupProgress && !cashflowSetupContext.setupProgress?.accounting_integrations) {
        const newProgress = { ...cashflowSetupContext.setupProgress }
        newProgress.accounting_integrations = true
        // @ts-ignore
        updateSetupProgress(newProgress, org.id)
      }
    }
  }, [cashflowAccountingConnected, cashflowSetupContext?.setupProgress, org?.id])

  useEffect(() => {
    // Upon unmounting the page, set it to completed since it's considered reviewed
    return () => {
      if (org?.id && props.activeStep) {
        cashflowSetupContext.setSetupProgress((prev) => {
          const newProgress = { ...prev }
          const isNotCompleted = !progress[props.activeStep]
          // Check if step should be updated
          const shouldStepBeUpdated =
            !!stepsAdjustedForOrg[props.activeStep] && stepsAdjustedForOrg[props.activeStep].complete_on_dismount

          if (isNotCompleted && shouldStepBeUpdated) {
            newProgress[stepsAdjustedForOrg[props.activeStep].stepId] = true
            updateSetupProgress(newProgress, org.id) // No need to wait since we're just saving
          }
          return newProgress
        })
      }
    }
  }, [org?.id])

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>{translate('CashFlow Setup Checklist')}</h1>

      {isLoading && <Skeleton height={300} />}

      {hasLoaded && (
        <>
          <div className={classes.progressBarWrapper}>
            <p>{translate('%{percentage}% complete', { percentage: Math.round(completionPercentage) })}</p>
            <div className={classes.progressBarContainer}>
              <div className={classes.progressBar}></div>
            </div>
          </div>
          <div>
            <Stepper
              orientation="vertical"
              steps={stepsAdjustedForOrg.map((s, index) => ({
                id: s.step,
                content: s.path ? (
                  <NavLink className={classes.linkText} to={s.path}>
                    <span className={classes.label}> {translate(s.label)} </span>
                  </NavLink>
                ) : (
                  <span className={classes.label}> {translate(s.label)} </span>
                ),
                isComplete: progress[index],
              }))}
              activeStep={props.activeStep}
            />
          </div>
        </>
      )}

      {failedToLoad && <div className={classes.progressBarWrapper}>Unable to get progress at the moment</div>}
    </div>
  )
}
export default CashFlowSetupChecklist
