import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const LeafIconSvg: FC<IconProps> = (props) => {
  const { fill, height, width } = props

  return (
    <svg viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2314_5119)">
        <path
          d="M8.06643 10.7335C4.42643 14.3735 4.42643 20.2935 8.06643 23.9335C9.89309 25.7602 12.2798 26.6668 14.6664 26.6668C17.0531 26.6668 19.4398 25.7602 21.2664 23.9335C25.9064 19.2935 26.6664 5.3335 26.6664 5.3335C26.6664 5.3335 12.7064 6.0935 8.06643 10.7335ZM19.3864 22.0535C18.1198 23.3068 16.4531 24.0002 14.6664 24.0002C13.4798 24.0002 12.3598 23.6668 11.3598 23.0935C12.5864 19.2535 14.8531 15.8802 17.8664 13.3335C14.3598 15.1468 11.4131 17.9468 9.37309 21.3335C7.39976 18.7202 7.57309 15.0002 9.95976 12.6135C12.2798 10.2935 18.7198 8.86683 23.7331 8.26683C23.1331 13.2802 21.7064 19.7202 19.3864 22.0535Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_5119">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LeafIcon = createStandardIcon(LeafIconSvg)
