import { makeStyles, TextField } from '@material-ui/core'
import { AccountTree, SwapHorizOutlined } from '@material-ui/icons'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'
import { setElectricalDialog } from 'ducks/electrical'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import Button from 'elements/button/Button'
import { FilterValuesType } from 'elements/hardwareFilter/type'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { IconButton } from 'opensolar-ui'
import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { AnyComponentType } from 'types/components'
import { StudioSystemType } from 'types/global'
import { OtherComponentType } from 'types/otherComponent'
import { FilterOverridesType } from 'types/selectComponent'
import { ActionType, EventType } from 'types/tracking'
import { inputValueToInt } from 'util/misc'
import { handleObjectChangedAsCommand } from '../PanelSystem'
import { filterOverridesForSlot, getDefaultFilterValuesBySlot, getSlotKey } from '../Slots'
import { ComponentSelectorDropdown } from './common/ComponentSelectorDropdown'
import { RemoveComponentButton } from './common/RemoveComponentButton'

const useComponentRowStyles = makeOpenSolarStyles<{ showQuantity: boolean }>(
  {
    root: { display: 'flex', alignItems: 'center' },
    selectorCont: { flexGrow: 1, height: 44 },
    quantityInput: {
      margin: '0 !important',
      width: 50,
    },
    quantityInputInput: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -2,
      textAlign: 'center',
    },
    quantityInputInputInput: {
      height: 13,
      paddingLeft: '10px !important',
    },
  },
  { name: 'ComponentRow' }
)

export const RowButtons = ({
  title,
  componentSelector,
  component,
  quantity,
  handleSelect,
  handleChoose,
  handleDelete,
  showQuantity,
  allowZeroQuantity = false,
}) => {
  const [quantityRaw, setQuantityRaw] = useState(String(quantity))
  const inputRef = useRef()
  const classes = useComponentRowStyles({ showQuantity })

  useEffect(() => {
    if (quantity !== quantityRaw) {
      setQuantityRaw(String(quantity))
    }
  }, [quantity])

  return (
    <div className={classes.root} data-testid="product-added-to-collection">
      <div className={classes.selectorCont} data-testid="product-info">
        {!componentSelector && <span>{title}</span>}
        {!!componentSelector && componentSelector}
        {!componentSelector && handleChoose ? (
          <IconButton
            style={{ padding: 0, width: 24, height: 24 }}
            onClick={(e) => {
              handleChoose()
              e.stopPropagation()
            }}
          >
            {<SwapHorizOutlined style={{ width: 18, height: 18 }} />}
          </IconButton>
        ) : null}
      </div>

      {showQuantity && (
        <TextField
          className={classes.quantityInput}
          InputProps={{
            className: classes.quantityInputInput,
            classes: {
              input: classes.quantityInputInputInput,
            },
          }}
          type="number"
          variant="outlined"
          label={null}
          name="quantity"
          inputRef={inputRef}
          value={quantityRaw}
          // @ts-ignore
          onClick={() => inputRef.current.focus()}
          onFocus={() => {
            // markFieldActive.call(_this, 'otherQuantityRaw' + component.uuid, state['otherQuantityRaw' + component.uuid])
          }}
          onBlur={() => {
            // markFieldInactive.call(_this)
          }}
          onKeyDown={(event) => {
            if (event.key === 'e' || event.key === 'E') {
              event.preventDefault()
            }
          }}
          onChange={(event) => {
            const value = event.target.value
            setQuantityRaw(event.target.value)

            var valueAsInt = inputValueToInt(value)
            if ((valueAsInt < 1 && !allowZeroQuantity) || valueAsInt < 0 || isNaN(valueAsInt)) {
              valueAsInt = 1
            }
            handleObjectChangedAsCommand(component.uuid, 'quantity', valueAsInt)
          }}
          style={{ margin: '0px 5px 10px 0px' }}
          inputProps={{
            style: { padding: '15px 5px', fontSize: 12, width: '40px' },
            classes: {},
            min: !allowZeroQuantity ? 1 : 0,
          }}
          disabled={!!component.isMerged || component?.other_component_type === 'dc_optimizer'} // dc optimiser quantities are updated automatically
        />
      )}
      <div style={{ flex: '0 0 24' }}>
        {handleSelect ? (
          <IconButton
            style={{ padding: 0, width: 24, height: 24 }}
            onClick={(e) => {
              handleSelect()
              e.stopPropagation()
            }}
          >
            {<LocationSearchingIcon style={{ color: '#DDD', width: 18, height: 18 }} />}
          </IconButton>
        ) : null}
      </div>
      <RemoveComponentButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleDelete()
        }}
      />
    </div>
  )
}

export type ComponentMerged = AnyComponentType & {
  code?: string
  uuid: string
  slotKey?: string
  quantity?: number
  mergedUuids: string[]
  isMerged?: boolean
  selectable?: boolean
  manufacturer_name?: string
}

type ComponentsMerged = ComponentMerged[]
const OMIT_OTHER_TYPES = COMPONENT_TYPE_OPTIONS.filter((x) => x.id !== 'general').map((x) => x.id)
const useInverterStyles = makeStyles({
  logicButtonContainer: {
    display: 'flex',
  },
  logicIcon: {
    margin: '-4px 10px !important',
    width: '15px !important',
  },
  logicLabel: {
    margin: 'auto',
  },
})

type ButtonProps = {
  disabled: boolean
  system: StudioSystemType
}

const InverterLogicButton: FC<ButtonProps> = ({ disabled, system }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const inverters = system.inverters()
  const classes = useInverterStyles()

  return (
    <Button
      style={{
        marginTop: -4,
        marginBottom: 20,
      }}
      fullWidth={true}
      variant="contained"
      size="small"
      disabled={disabled}
      onClick={() => {
        dispatch(setElectricalDialog(true, inverters[0]))
      }}
    >
      <div className={classes.logicButtonContainer}>
        <AccountTree className={classes.logicIcon} />
        <span className={classes.logicLabel}>{translate('View Inverter Logic')}</span>
      </div>
    </Button>
  )
}

type TitleSizes = 'large' | 'medium'

interface ComponentCollectionProps extends WithHardwareSelectorV2Props {
  title: string
  dialogSelectorTitle?: string
  titleSize?: TitleSizes
  style?: CSSProperties
  addButtonLabel?: string
  systemUuid: string
  others: any[]
  filterParams: any
  disabled?: boolean
  hideDivider?: boolean
}

const useComponentStyles = makeOpenSolarStyles<{ titleSize: TitleSizes }>({
  root: { marginBottom: 20 },
  titleRow: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
    paddingTop: 10,
  },
  divider: ({ titleSize }) => ({
    height: 1,
    width: titleSize === 'large' ? 'calc(100% + 20px)' : '100%',
    borderTop: titleSize === 'large' ? '1px solid #CCC' : '1px solid #EEE',
    margin: titleSize === 'large' ? '20px -10px 0' : '',
  }),
  title: ({ titleSize }) => ({ flex: '1 1', marginTop: 3, fontSize: titleSize === 'large' ? 16 : 14 }),
  addButton: { flex: '0 0 24px', padding: 0, width: 24, height: 24, minWidth: 24 },
})

const ComponentCollection: FC<ComponentCollectionProps> = ({
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  title,
  dialogSelectorTitle,
  titleSize = 'medium',
  style,
  addButtonLabel,
  systemUuid,
  others,
  filterParams,
  disabled = false,
  hideDivider = false,
}) => {
  /*
        Each item includes:
          - component title/code
          - select component button
          - delete button
          - quantity button (can be enabled/disabled based on the slot)
          - place in scene button (only if it has a model)
            - components with a model are placed into the scene in one click.
            - components with a path are placed into the scene by clicking waypoints to place edges/nodes like wiring.
      */
  const system = window.editor.objectByUuid(systemUuid)
  const inverters = system.inverters()
  const classes = useComponentStyles({ titleSize })
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { trackEvent } = useTrackComponent({
    componentKey: 'component_collection',
    eventName: 'User Interacted With Component Collection Actions',
  })

  const handleOpenOtherComponentSelector = ({ defaultFilterValues, targetUuids, slotKey, defaultFilterValuesV2 }) => {
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.CLICK },
      { eventName: 'User Added Component', source: dialogSelectorTitle }
    )
    if (enableHardwareDialogV2) {
      handleOpenHardwareSelector({
        componentTypes: filterParams?.other_component_type
          ? [filterParams.other_component_type]
          : COMPONENT_TYPE_OPTIONS.map((option) => option.id), // Fall back to all other component types,
        targetUuids,
        defaultFilterValues: defaultFilterValuesV2,
        slotKey,
        title: dialogSelectorTitle,
      })
    } else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          targetUuids,
          'other',
          defaultFilterValues,
          slotKey
        )
      )
    }
  }

  var components = others.filter((other: OtherComponentType) => {
    if (filterParams.slot) {
      return other.slotKey === filterParams.slot.key
    } else if (filterParams.other_component_type) {
      return other.other_component_type === filterParams.other_component_type
    } else {
      var isAlreadyInCollection = false
      if (OMIT_OTHER_TYPES.includes(other.other_component_type)) isAlreadyInCollection = true
      return !isAlreadyInCollection
    }
  })

  var componentsMerged: ComponentsMerged = []
  components.forEach((component) => {
    var matchingComponent = componentsMerged.find((c) => c.slotKey && component.slotKey && c.code === component.code)
    if (!matchingComponent) {
      // Shallow clone is ok because we will only modify the "quantity" field which is at the top level
      let componentMerged: ComponentMerged = { ...component }
      componentMerged.isMerged = false
      componentMerged.mergedUuids = [componentMerged.uuid]
      // Quantity should be populated already but if not, set to 1
      if (!componentMerged.quantity) {
        componentMerged.quantity = 1
      }
      componentsMerged.push(componentMerged)
    } else {
      // If quantity is missing, assume 1
      let componentMerged: ComponentMerged = matchingComponent
      componentMerged.quantity += component.quantity || 1
      componentMerged.mergedUuids.push(component.uuid)
      componentMerged.isMerged = true
    }
  })

  const filterOverrides: FilterOverridesType = []
  if (filterParams.other_component_type) {
    filterOverrides.push({ key: 'other_component_type', value: filterParams.other_component_type })
    if (filterParams.other_component_type === 'meter') {
      const inverterWithPhase = system.inverters().find((inverter) => inverter.phase_type)
      if (inverterWithPhase) {
        filterOverrides.push({ key: 'phase_type', value: inverterWithPhase.phase_type })
      } else {
        let projectPhase = window.projectForm.getState().values.number_of_phases
        if (projectPhase === 1) {
          filterOverrides.push({ key: 'phase_type', value: 'single_phase' })
        } else if (projectPhase === 3) {
          filterOverrides.push({ key: 'phase_type', value: 'three_phase' })
        }
      }
    }
  }
  const filterOverridesV2: FilterValuesType = filterOverrides.reduce((filterValues, { key, value }) => {
    filterValues[key] = value
    return filterValues
  }, {})

  const supplyPhase = window.WorkspaceHelper.project?.number_of_phases === 3 ? 'three_phase' : 'single_phase'

  return (
    <div className={classes.root} style={style}>
      {!hideDivider && <div className={classes.divider} />}
      <div className={classes.titleRow}>
        {!!title && <h3 className={classes.title}>{translate(title)}</h3>}{' '}
        <Button
          translate="no"
          disabled={
            disabled ||
            (filterParams.slot && filterParams.slot?.restrictToOne ? true : false) ||
            (filterParams.other_component_type &&
              filterParams.other_component_type === 'dc_optimizer' &&
              componentsMerged.length > 0)
          }
          className={classes.addButton}
          onClick={() => {
            if (filterParams.slot) {
              const slotKey = getSlotKey(filterParams.slot)
              handleOpenOtherComponentSelector({
                defaultFilterValuesV2: getDefaultFilterValuesBySlot(filterParams.slot, supplyPhase),
                defaultFilterValues: filterOverridesForSlot(filterParams.slot, supplyPhase),
                slotKey,
                targetUuids: null,
              })
            } else {
              handleOpenOtherComponentSelector({
                targetUuids: null,
                defaultFilterValues: filterOverrides,
                defaultFilterValuesV2: filterOverridesV2,
                slotKey: undefined,
              })
            }
          }}
        >
          {addButtonLabel || '+'}
        </Button>
      </div>

      {['isolator', 'cable'].includes(filterParams.other_component_type) && !!inverters.length && (
        <InverterLogicButton disabled={disabled} system={system} />
      )}

      <div>
        {componentsMerged.map((componentMerged, index) => (
          <RowButtons
            key={`${componentMerged.code}_${index}`}
            title={componentMerged.code}
            componentSelector={
              <ComponentSelectorDropdown
                componentType="other"
                component={componentMerged}
                availableComponents={window.AccountHelper.getComponentOtherSpecsAvailable()}
                disabled={disabled}
                select={() => {
                  handleOpenOtherComponentSelector({
                    defaultFilterValuesV2: filterOverridesV2,
                    defaultFilterValues: filterOverrides,
                    slotKey: undefined,
                    targetUuids: componentMerged.mergedUuids,
                  })
                }}
                margin="dense"
                showLogo={false}
                roundLeft={componentMerged.hasOwnProperty('quantity')}
              />
            }
            showQuantity={componentMerged.hasOwnProperty('quantity')}
            component={componentMerged}
            quantity={componentMerged.quantity}
            handleChoose={() => {
              // Legacy: this was used before when we only rendered a basic title link
              // but now we use the ComponentSelectorDropdown above
              handleOpenOtherComponentSelector({
                defaultFilterValuesV2: filterOverridesV2,
                defaultFilterValues: filterOverrides,
                slotKey: undefined,
                targetUuids: componentMerged.mergedUuids,
              })
            }}
            // TODO: Allow location of a component to be selected in the editor. Eg. to connect a cable slot to a wiring annotation, and automatically get the correct length. This has not yet been implemented.
            handleSelect={
              componentMerged.selectable
                ? () => {
                    window.editor.select(system.others().filter((c) => c.code === componentMerged.code))
                  }
                : null
            }
            handleDelete={() => {
              // Cannot delete a merged component, instead delete all components within merge
              var componentsToDelete = system.others().filter((c) => componentMerged.mergedUuids.includes(c.uuid))
              componentsToDelete.forEach((component) => {
                window.editor.deleteObject(component)
              })
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default withHardwareSelectorV2(ComponentCollection)
