import MuiTooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defaultRules } from './defaultRules'
import { ExampleComponent } from './ExampleComponent'
import { getStyleDefinition } from './getStyleDefinition'
import { makeStyleNotices } from './makeStyleNotices'
import { PropsTable } from './PropsTable'
import { StyleWarnings } from './StyleWarnings'
import { PropsDefs, StyleNotices, StyleNoticesBrief, StyleWarningLevel, StyleWarningRules } from './types'

const useStyles = makeStyles(
  {
    componentHover: {
      pointerEvents: 'unset',
      outline: '2px dashed BlueViolet !important',
      outlineOffset: 1,

      '&.style-warning-warning': {
        outlineColor: 'orange !important',
      },
      '&.style-warning-error': {
        outlineColor: 'red !important',
      },
    },
    tooltip: {
      background: 'black',
      fontFamily: 'monospace',
      color: 'white',
    },
    subhead: {
      marginTop: 10,
    },
  },
  { name: 'InspectOverlay' }
)

interface InspectOverlayProps<P> {
  name: string
  component: React.FunctionComponent<P>
  rules?: StyleWarningRules
  propDefs?: PropsDefs
  showExample: boolean
  props: P
}

export function Inspector<P extends object>(props: InspectOverlayProps<P>) {
  // Force this to only be assessed once per session, requires reload, but ensures no performance hit
  const enableUiInspector =
    typeof window !== 'undefined' ? localStorage?.getItem('enableUiInspector') === 'true' : false
  if (!enableUiInspector) return <props.component {...props.props} />

  return <InspectorEnabled {...props} />
}

/*
This component only really exists to allow Inspector to exit early when not enabled.
That is, otherwise all of the following hooks would complain about conditionally being run.
*/
function InspectorEnabled<P extends object>({
  name,
  component: Component,
  rules,
  propDefs,
  showExample,
  props,
}: InspectOverlayProps<P>) {
  const classes = useStyles()
  const [highestLevel, setHighestLevel] = useState<StyleWarningLevel>(StyleWarningLevel.info)

  //Nested state here is to get around annoying react recursion
  const [styleNotices, setStyleNotices] = useState<{ notices: StyleNotices[] }>({ notices: [] })

  const calcHighestLevel = useCallback(() => {
    const highestLevelNew =
      styleNotices.notices.reduce((prev, curr) => Math.max(prev, curr.highestLevel), 0) || StyleWarningLevel.info
    setHighestLevel(highestLevelNew)
  }, [styleNotices])

  const addStyleNotices = useCallback(
    (id: string, name: string, order: number, notices: StyleNoticesBrief | undefined) => {
      const existing = styleNotices.notices.filter((n) => n.id !== id)

      if (notices) {
        existing.push({ id, name, order, ...notices })
        existing.sort((a, b) => a.order - b.order)
      }
      styleNotices.notices = existing
      setStyleNotices(styleNotices)
      calcHighestLevel()
    },
    [styleNotices]
  )

  const clearStyleNotices = (idStartsWith: string) => {
    const existing = styleNotices.notices.filter((n) => !n.id.startsWith(idStartsWith))
    styleNotices.notices = existing
    setStyleNotices(styleNotices)
    calcHighestLevel()
  }

  const realRules = rules || defaultRules

  const styleProp = props['style']
  useEffect(() => {
    addStyleNotices('style', "'style' prop", 0, makeStyleNotices(styleProp ? [{ values: styleProp }] : [], realRules))
  }, [styleProp, addStyleNotices])

  const className = props['className']
  useEffect(() => {
    clearStyleNotices('className.')
    if (!className) return

    const classNames: string[] = className.split(' ')
    let i = 0
    for (let key of classNames) {
      const id = `className: '${key}'`
      addStyleNotices(
        id,
        `'className' prop (.${key})`,
        1 + i / classNames.length,
        makeStyleNotices(getStyleDefinition('.' + key), realRules)
      )
      i++
    }
  }, [className, addStyleNotices])

  const classesProp = props['classes']

  // Extra caching step as 'classes' is often declared inline
  const classesKey = useMemo(() => JSON.stringify(classesProp), [classesProp])

  useEffect(() => {
    clearStyleNotices('classes.')
    if (!classesProp) {
      return
    }
    let i = 0
    const keys = Object.keys(classesProp)
    for (let key in classesProp) {
      const id = 'classes.' + key
      addStyleNotices(
        id,
        `'${id}' prop`,
        2 + i / keys.length,
        makeStyleNotices(getStyleDefinition('.' + classesProp[key]), realRules)
      )
      i++
    }
  }, [classesKey, addStyleNotices])

  const newClassName = classes.componentHover + ' ' + getLevelStyle(highestLevel) + ' ' + (className || '')

  return (
    <>
      <MuiTooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          <>
            <div>{name}</div>
            {propDefs && <PropsTable propDefs={propDefs} props={props} />}
            {!!styleNotices.notices.length && <div className={classes.subhead}>Style Warnings:</div>}
            {styleNotices.notices.map((n) => (
              <StyleWarnings key={n.id} name={n.name} notices={n.styles} />
            ))}
            {showExample && (
              <>
                <div className={classes.subhead}>Standardised Example:</div>
                <ExampleComponent type={Component} propDefs={propDefs} props={props} />
              </>
            )}
          </>
        }
      >
        <Component {...props} className={newClassName} />
      </MuiTooltip>
    </>
  )
}

const getLevelStyle = (level: StyleWarningLevel): string => {
  return 'style-warning-' + StyleWarningLevel[level]
}
