import React, { FC, useRef, useState, useCallback, useMemo } from 'react'
import { Form, FormProps } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import useInitializeFormWithRecord from './useInitializeFormWithRecord'
import useWarnWhenUnsavedChanges from './useWarnWhenUnsavedChanges'
import sanitizeEmptyValues from './sanitizeEmptyValues'
import getFormInitialValues from './getFormInitialValues'
import FormContext from './FormContext'
import { Record } from '../types'
import { RedirectionSideEffect } from '../sideEffect'

const defaultMutators = {
  updateField: ([field, value], state, utils) => {
    utils.changeValue(state, field, () => value)
  },
}

/**
 * Wrapper around react-final-form's Form to handle redirection on submit,
 * legacy defaultValue prop, and array inputs.
 *
 * Requires a render function, just like react-final-form
 *
 * @example
 *
 * const SimpleForm = props => (
 *    <FormWithRedirect
 *        {...props}
 *        render={formProps => <SimpleFormView {...formProps} />}
 *    />
 * );
 *
 * @typedef {Object} Props the props you can use (other props are injected by Create or Edit)
 * @prop {Object} initialValues
 * @prop {Function} validate
 * @prop {Function} save
 * @prop {boolean} submitOnEnter
 * @prop {string} redirect
 *
 * @param {Prop} props
 */
const FormWithRedirect: FC<FormWithRedirectOwnProps & FormProps> = ({
  debug,
  decorators,
  defaultValue,
  destroyOnUnregister,
  form,
  initialValues,
  initialValuesEqual,
  keepDirtyOnReinitialize = true,
  mutators = defaultMutators as any,
  //mutators = arrayMutators as any, // FIXME see https://github.com/final-form/react-final-form/issues/704 and https://github.com/microsoft/TypeScript/issues/35771
  record,
  render,
  save,
  saving,
  subscription = defaultSubscription,
  validate,
  validateOnBlur,
  version,
  warnWhenUnsavedChanges,
  formatSubmitValues,
  validationOnSubmit,
  ...props
}) => {
  let redirect = useRef(props.redirect)
  let onSave = useRef(save)
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect
  }

  /**
   * A form can have several Save buttons. In case the user clicks on
   * a Save button with a custom onSave handler, then on a second Save button
   * without custom onSave handler, the user expects the default save
   * handler (the one of the Form) to be called.
   * That's why the SaveButton onClick calls setOnSave() with no parameters
   * if it has no custom onSave, and why this function forces a default to
   * save.
   */
  const setOnSave = useCallback(
    (newOnSave) => {
      typeof newOnSave === 'function'
        ? (onSave.current = (values, redirect, form) => newOnSave(values, redirect, form, save))
        : (onSave.current = save)
    },
    [save]
  )

  const formContextValue = useMemo(() => ({ setOnSave }), [setOnSave])

  const finalInitialValues = getFormInitialValues(initialValues, defaultValue, record)

  const submit = (values, form) => {
    const formattedValue = formatSubmitValues ? formatSubmitValues(values) : values
    const finalRedirect = typeof redirect.current === undefined ? props.redirect : redirect.current
    // avoid sending unnecessary value to backend
    // const finalValues = sanitizeEmptyValues(finalInitialValues, formattedValue)
    const finalValues = formattedValue
    // if(validationOnSubmit){
    //   const errors = validationOnSubmit(formattedValue)
    //   if(errors) return errors
    // }
    return onSave.current(finalValues, finalRedirect, form)
  }

  return (
    <FormContext.Provider value={formContextValue}>
      <Form
        key={version} // support for refresh button
        debug={debug}
        decorators={decorators}
        destroyOnUnregister={destroyOnUnregister}
        form={form}
        initialValues={finalInitialValues}
        initialValuesEqual={initialValuesEqual}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        mutators={mutators} // necessary for ArrayInput
        onSubmit={submit}
        subscription={subscription} // don't redraw entire form each time one field changes
        validate={validate}
        validateOnBlur={validateOnBlur}
      >
        {(formProps) => (
          <FormView
            {...props}
            {...formProps}
            record={record}
            setRedirect={setRedirect}
            saving={formProps.submitting || saving}
            render={render}
            save={save}
            warnWhenUnsavedChanges={warnWhenUnsavedChanges}
          />
        )}
      </Form>
    </FormContext.Provider>
  )
}

export interface FormWithRedirectOwnProps {
  defaultValue?: any
  record?: Record
  save: (
    data: Partial<Record>,
    redirectTo: RedirectionSideEffect,
    options?: {
      onSuccess?: (data?: any) => void
      onFailure?: (error: any) => void
    }
  ) => void
  redirect: RedirectionSideEffect
  saving: boolean
  version: number
  warnWhenUnsavedChanges?: boolean
  formatSubmitValues?: any
  validationOnSubmit?: any
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

const FormView = ({ render, warnWhenUnsavedChanges, ...props }) => {
  // if record changes (after a getOne success or a refresh), the form must be updated
  // disable this feature as 1. record not updating itself after getOne success in our app 2. it breaks the initial values of react-final-forms (record always override customised initial values)
  false && useInitializeFormWithRecord(props.record)
  useWarnWhenUnsavedChanges(warnWhenUnsavedChanges)

  const { redirect, setRedirect, handleSubmit } = props

  /**
   * We want to let developers define the redirection target from inside the form,
   * e.g. in a <SaveButton redirect="list" />.
   * This callback does two things: handle submit, and change the redirection target.
   * The actual redirection is done in save(), passed by the main controller.
   *
   * If the redirection target doesn't depend on the button clicked, it's a
   * better option to define it directly on the Form component. In that case,
   * using handleSubmit() instead of handleSubmitWithRedirect is fine.
   *
   * @example
   *
   * <Button onClick={() => handleSubmitWithRedirect('edit')}>
   *     Save and edit
   * </Button>
   */
  const handleSubmitWithRedirect = useCallback(
    (redirectTo = redirect) => {
      setRedirect(redirectTo)
      handleSubmit()
    },
    [setRedirect, redirect, handleSubmit]
  )

  return render({
    ...props,
    handleSubmitWithRedirect,
  })
}

export default FormWithRedirect
