import { FC } from 'react'

/**
 * * Icon Component for: help circle icon
 */
type HelpCircleIconProps = {
  size?: number
  color?: string
}
export const HelpCircleIcon: FC<HelpCircleIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
  }
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <g id="help-circle-contained-filled">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00039 1.40015C3.9076 1.40015 1.40039 3.90735 1.40039 7.00015C1.40039 10.0929 3.90759 12.6001 7.00039 12.6001C10.0932 12.6001 12.6004 10.0929 12.6004 7.00015C12.6004 3.90735 10.0932 1.40015 7.00039 1.40015ZM6.40039 10.0335C6.40039 10.3649 6.66902 10.6335 7.00039 10.6335C7.33176 10.6335 7.60039 10.3649 7.60039 10.0335C7.60039 9.70216 7.33176 9.43353 7.00039 9.43353C6.66902 9.43353 6.40039 9.70216 6.40039 10.0335ZM6.10039 5.95015C6.10039 5.45309 6.50333 5.05015 7.00039 5.05015C7.49745 5.05015 7.90039 5.45309 7.90039 5.95015C7.90039 6.4472 7.49745 6.85015 7.00039 6.85015C6.66902 6.85015 6.40039 7.11878 6.40039 7.45015V8.23355C6.40039 8.56492 6.66902 8.83355 7.00039 8.83355C7.33176 8.83355 7.60039 8.56492 7.60039 8.23355V7.96319C8.46784 7.70502 9.10039 6.90146 9.10039 5.95015C9.10039 4.79035 8.16019 3.85015 7.00039 3.85015C5.84059 3.85015 4.90039 4.79035 4.90039 5.95015C4.90039 6.28152 5.16902 6.55015 5.50039 6.55015C5.83176 6.55015 6.10039 6.28152 6.10039 5.95015Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
