import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { StyleNotice, StyleWarningLevel } from './types'

const useWarningStyles = makeStyles(
  {
    badStyles: {
      marginLeft: 10,
      whiteSpace: 'pre',
    },
    badStyle: {
      '&.info': {
        color: 'lightblue',
      },
      '&.warning': {
        color: 'orange',
      },
      '&.error': {
        color: 'red',
      },

      '&::before': {
        content: '""',
        display: 'inline-block',
        width: 20,
      },
      '&.error::before': {
        content: '"⚠"',
      },
    },
  },
  { name: 'StyleWarnings' }
)
type StyleWarningsProps = {
  name: string
  notices: StyleNotice[] | undefined
}
export const StyleWarnings: FC<StyleWarningsProps> = ({ name, notices }) => {
  const classes = useWarningStyles()
  if (!notices?.length) return null

  return (
    <div className={classes.badStyles}>
      <div>{name}</div>
      {notices.map((notice) => (
        <div key={String(notice.key)} className={classes.badStyle + ' ' + getLevelStyle(notice.level)}>
          {notice.key}: {notice.value}
        </div>
      ))}
    </div>
  )
}

const getLevelStyle = (level: StyleWarningLevel): string => {
  return StyleWarningLevel[level]
}
