import { styled } from 'opensolar-ui'
import { FC } from 'react'
import { HighlightConfig } from '../expoStand'
import HighlightCard from './common/HighlightCard'

interface PropTypes {
  highlights: HighlightConfig[]
}

const HighlightsWrapper = styled('div', { name: 'HighlightsWrapper' })({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
})

const Highlights: FC<PropTypes> = ({ highlights }) => {
  if (!highlights) return null
  return (
    <HighlightsWrapper>
      {highlights.map((card, i) => (
        <HighlightCard key={'highlight-' + i} highlight={card} />
      ))}
    </HighlightsWrapper>
  )
}

export default Highlights
