import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { projectViewSettingsActions } from 'ducks/projectViewSettings'
import { updateRecentProject } from 'ducks/recentProjects'
import { FinanceCtaType } from 'opensolar-checkout'
import { hasDirtySharedValues } from 'projectSections/sections/info/orgSharing/util'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { useNotify } from 'ra-core'
import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { OrgType } from 'types/orgs'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProjectShare, ProjectType } from 'types/projects'
import { reloadEntireApp, urlToId } from 'util/misc'
import { useProjectFormFormatter } from '../form/formatter'
import { parseInitialValues } from '../form/parser'

enum SaveStatus {
  FAILED = 0,
  SUCCESS = 1,
  CANCELLED = 2,
}

const useProjectSave = () => {
  const org = useSelector(orgSelectors.getOrg)
  const notify = useNotify()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const locationRef = useRef(location)
  const projectIdRef = useRef(id)
  locationRef.current = location
  projectIdRef.current = id

  const formatSubmitValues = useProjectFormFormatter()

  const handleSave = useCallback(async (values, redirect, form, sendAll = false) => {
    const formState = form.getState()
    /*
    Ensure we generate this lazily ONLY when it is actually used. This ensures it saves the latest data.
    In particular, this ensures that initial calcs can be applied before generate the data save payload.
    Otherwise, if we generate the values now we may save stale data when the save finally triggers.
    */
    const generateSubmitValueLazy = () => {
      if (sendAll) return form.getState().values
      const dirtyFields = form.mutators.getFormDirtyFields()
      const vals = formatSubmitValues(values, dirtyFields)
      return form.mutators.fillQuietFields(vals, true)
    }
    return new Promise((resolve, reject) => {
      const projectSavedAnalyticsData = getProjectSavedAnalyticsPayload(projectIdRef.current, values, org)
      const onUpdateSuccess = (response: ProjectType) => {
        //mark as invalid to fetch new events
        dispatch(projectViewSettingsActions.setEventsValid(false))
        // notify('Success', 'success')
        const dirtyFields = form.mutators.getFormDirtyFields()
        const initialValues = parseInitialValues(values, response)
        form.restart(initialValues)
        form.mutators.resetFormDirtyFields()
        dispatch(updateRecentProject(response))
        resolve(response)

        if (dirtyFields.includes('project_sold') && response.project_sold !== null) {
          notify('Project marked as sold', 'info')
        }
        if (dirtyFields.includes('project_installed') && response.project_installed !== null) {
          notify('Project marked as installed', 'info')
        }

        //reload to refresh shared instances
        if (hasDirtySharedValues(dirtyFields)) {
          reloadEntireApp()
        }
        window.WorkspaceHelper.getUnsharedEntitiesError(response.unshared_items)
      }

      const onCreateSuccess = (response: ProjectType) => {
        const newProjectId = response.id
        window.WorkspaceHelper.params.id = newProjectId
        window.AccountHelper.params.id = newProjectId
        const pathName = locationRef.current.pathname
        const search = locationRef.current.search
        const redirectPathName = pathName.replace('new', newProjectId + '').replace('explore', redirect)
        history.push({ pathname: redirectPathName, search })
        const initialValues = parseInitialValues(response, response)
        form.mutators.resetFormDirtyFields()
        form.restart(initialValues)
        dispatch(updateRecentProject(response))
        resolve(response)
      }

      // Track changes to project sharing
      const currentShared: ProjectShare[] = formState.values['shared_with']
      if (currentShared) {
        const previousShared: ProjectShare[] = formState.initialValues['shared_with'] || []
        for (const share of previousShared) {
          if (!currentShared.find((s) => s.org_id === share.org_id)) {
            // Share removed
            logAmplitudeEvent('project_unshared', { org_id: share.org_id, project_id: formState.values.id })
          }
        }
        for (const share of currentShared) {
          if (!previousShared.find((s) => s.org_id === share.org_id)) {
            // Share added
            logAmplitudeEvent('project_shared', { org_id: share.org_id, project_id: formState.values.id })
          }
        }
      }
      const callback = (saveStatus: SaveStatus, response?: ProjectType | Error, projectId?: string) => {
        // Passing projectId as an argument here to avoid projectId mismatch
        // https://github.com/open-solar/opensolar-todo/issues/12559
        if (saveStatus === SaveStatus.SUCCESS) {
          if (projectId === 'new' && projectIdRef.current === projectId) {
            onCreateSuccess(response as ProjectType)
            clearUnsavedData()
          } else if (projectId === projectIdRef.current) {
            onUpdateSuccess(response as ProjectType)
            clearUnsavedData()
          } else {
            console.error('Project ID mismatch! Skipping project form update.')
            resolve(response)
          }

          // Track project saved event
          logAmplitudeEvent('project_saved', projectSavedAnalyticsData)
        } else if (saveStatus === SaveStatus.CANCELLED) {
          reject()
        } else {
          if (response) Sentry.captureException(response)
          reject()
          notify('Project failed to save', 'error')
        }
      }
      window.WorkspaceHelper.saveProject(generateSubmitValueLazy, callback)
    })
  }, [])
  return handleSave
}

export default useProjectSave

function getProjectSavedAnalyticsPayload(projectId: string, values: Partial<ProjectType>, org: OrgType | undefined) {
  let hasPmtOverride = 'false'
  let pmtTitlesStr = ''
  let integrationsStr = ''
  let soldPmtTitle = 'none'
  const pmtTitleSet = new Set()
  const pmtIntegrationSet = new Set()
  window.editor.getSystems()?.forEach((osSystem) => {
    if (osSystem?.payment_options_override?.length) hasPmtOverride = 'true'
    osSystem?.payment_options?.forEach((pmt: PaymentOptionDataType) => {
      pmtTitleSet.add(pmt.title)
      if (pmt.payment_type === 'cash') {
        const checkoutFinanceCTA = pmt?.finance_ctas?.find((cta) => cta.type === FinanceCtaType.CHECKOUT_FINANCE)
        const isCheckoutFinanceEnabled = pmt.finance_product_category_id && !pmt.disabled_finance_product_category_ids?.includes(pmt.finance_product_category_id)
        if (checkoutFinanceCTA && isCheckoutFinanceEnabled) {
          pmtIntegrationSet.add(checkoutFinanceCTA.type)
        }
      } else {
        pmtIntegrationSet.add(pmt.integration)
      }
      if (pmt?.id === urlToId(values.payment_option_sold)) soldPmtTitle = pmt.title
    })
  })

  pmtTitleSet?.forEach((pmtTitle, i) => {
    if (pmtTitlesStr?.length) pmtTitlesStr += ';'
    pmtTitlesStr += pmtTitle
  })

  pmtIntegrationSet?.forEach((integration) => {
    if (integration) {
      if (integrationsStr?.length) integrationsStr += ';'
      integrationsStr += integration
    }
  })

  return {
    project_id: projectId,
    payment_option_sold: soldPmtTitle,
    has_pmt_override: hasPmtOverride,
    payment_options: pmtTitlesStr,
    integrations: integrationsStr,
    org_name: org?.name,
    org_id: org?.id,
  }
}
