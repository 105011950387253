import { Create, Edit } from 'react-admin'
import { ExpoStandList } from 'resources/expoStands/ExpoStands'
import ExpoHomepageForm from './ExpoHomepageForm'

export const ExpoHomepageList = (props) => {
  return <ExpoStandList homepage={true} {...props} />
}

export const ExpoHomepageCreate = (props) => {
  return (
    <Create {...props}>
      <ExpoHomepageForm {...props} />
    </Create>
  )
}

export const ExpoHomepageEdit = (props) => {
  return (
    <Edit {...props}>
      <ExpoHomepageForm {...props} />
    </Edit>
  )
}
