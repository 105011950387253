import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import LoadingDots from 'layout/widgets/LoadingDots'
import { useCallback, useEffect, useState } from 'react'
import { NotFound } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { urlToId } from 'util/misc'
import SectionContainer from '../SectionContainer'

const useStyle = makeStyles<any, { loaded: boolean }>((theme) => ({
  dialog: {
    margin: 0,
  },
  wrapper: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 64px)',
    },
  },
  iframeSizer: ({ loaded }) => ({
    width: '100%',
    /*
    There is a bug in draw.io loading below 800px wide breaks their app.
    https://github.com/jgraph/drawio/issues/3048
    This is our workaround until fixed on their end.
    */
    minWidth: loaded ? 'unset' : 800,
    flexGrow: 1,
  }),
  iframe: {
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% / 0.8)',
      height: 'calc(100% / 0.8)',
      transform: 'scale(0.8)',
      transformOrigin: '0 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% / 0.7)',
      height: 'calc(100% / 0.7)',
      transform: 'scale(0.7)',
      transformOrigin: '0 0',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% / 0.55)',
      height: 'calc(100% / 0.55)',
      transform: 'scale(0.55)',
      transformOrigin: '0 0',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

const API_URL = window.API_ROOT + '/api'

const restClientInstance = restClient(API_URL)

const postMesageOrigin = '*'

let hasForcedLoad = false

const SldEditor = () => {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyle({ loaded })
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null)

  const org = useSelector(authSelectors.getCurrentOrg)
  const orgId = useSelector(authSelectors.getOrgId)
  const projectForm = useForm()

  const baseUrl = usePublicFeatureConfig('sld_settings', !hasForcedLoad)?.baseUrl
  hasForcedLoad = true

  // Testing when running on localhost with /drawio/main/webapp/ shared
  // const iframeUrl = 'http://localhost/drawio/index.html?plugins=1&p=sld&splash=0&pages=0&dev=1'

  // SLD App only has production and dev environments.
  // OSApp production targets SLDApp production.
  // All other OSApp environments target SLDApp dev.
  const iframeUrl = baseUrl ? `${baseUrl}/index.html?plugins=1&p=sld&splash=0&pages=0` : undefined

  const populateFileList = useCallback(
    (orgId, projectId) => {
      restClientInstance('GET_LIST', 'private_files', {
        filter: { project: projectId },
      })
        .then((response: any) => {
          var singleLineDiagramPrivateFiles = response.data.filter((item) => {
            return item.file_tags_data.some((ft) => ft.title === 'Single Line Diagram')
          })

          iframeRef?.contentWindow?.postMessage(
            {
              type: 'SLD_PRIVATE_FILES_LIST',
              data: singleLineDiagramPrivateFiles,
            },
            postMesageOrigin
          )
        })
        .catch((err: any) => {
          console.log('err', err)
        })
    },
    [iframeRef]
  )

  const setSaveSuccess = useCallback(() => {
    iframeRef?.contentWindow?.postMessage(
      {
        type: 'SLD_SAVE_SUCCESS',
      },
      postMesageOrigin
    )
  }, [iframeRef])

  useEffect(() => {
    const projectId = projectForm.getState().values.id

    function iframeListener(message: MessageEvent) {
      if (message.data?.type === 'SLD_PRIVATE_FILES_GET') {
        populateFileList(orgId, projectId)
      } else if (message.data?.type === 'SLD_PLUGIN_READY') {
        setLoaded(true)
        // drawio and SLD plugin loaded, send design data now

        iframeRef?.contentWindow?.postMessage(
          {
            type: 'SLD_DESIGN_DATA',
            data: {
              design: window.editor.sceneAsJSON(),
              project: projectForm.getState().values,
              org: org,
            },
          },
          postMesageOrigin
        )

        populateFileList(orgId, projectId)
      } else if (message.data?.type === 'SLD_PRIVATE_FILES_SAVE') {
        // Create or Save private file then if successful close the iframe

        const file = new File([message.data.data.fileContents], 'sld.svg', {
          type: message.data.data.contentType, //e.g. 'image/png',
        })

        const createNewPrivateFile = !message.data.data.url

        const requestBody: Record<string, any> = {
          project: `/api/orgs/${orgId}/projects/${projectId}/`,
          title: 'Single Line Diagram',
          file_contents: [{ rawFile: file }],

          // workaround for restClient which strips file_contents due to a bug with react-admin edit forms and uploaded files
          do_not_strip_file_contents: 1,
        }

        // Add file tags only if creating
        if (createNewPrivateFile) {
          requestBody.file_tag_titles = 'Single Line Diagram'
        }

        const apiRequestType = message.data.data.url ? 'UPDATE' : 'CREATE'
        const apiRequestOptions = message.data.data.url
          ? {
              id: urlToId(message.data.data.url),
              url: message.data.data.url.replace(API_URL, ''),
              data: requestBody,
            }
          : {
              url: `orgs/${orgId}/private_files/`,
          data: requestBody,
            }
        restClientInstance(apiRequestType, 'private_files', apiRequestOptions)
          .then((response: any) => {
            console.log('response from refresh', response)
            // re-populate file list after file has been saved
            populateFileList(orgId, projectId)
            setSaveSuccess()
          })
          .catch((err: any) => {
            console.log('err', err)
          })
      } else {
        // console.warn('unhandled message', message.data)
      }
    }
    window.addEventListener('message', iframeListener)
    return () => window.removeEventListener('message', iframeListener)
  })

  return (
    <>
      {iframeUrl ? (
        <div className={classes.iframeSizer}>
          <iframe
            ref={(iframe) => setIframeRef(iframe)}
            id="SldIframe"
            src={iframeUrl}
            frameBorder="0"
            width="100%"
            style={{ height: 'calc(100vh - 104px)' }}
            scrolling="no"
          ></iframe>
        </div>
      ) : (
        <div className={classes.container}>
          <LoadingDots text="Loading..." />
        </div>
      )}
    </>
  )
}

const Sld = () => {
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('design'))

  if (!allowView) {
    return <NotFound />
  }

  return (
    <SectionContainer section="sld" mode="none">
      <SldEditor />
    </SectionContainer>
  )
}
export default Sld
