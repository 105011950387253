import { default as Mui4Snackbar, SnackbarProps as Mui4SnackbarProps } from '@material-ui/core/Snackbar'
import { default as Mui5Snackbar, SnackbarProps as Mui5SnackbarProps } from '@mui/material/Snackbar'
import { FC, forwardRef, ReactElement } from 'react'
import { Inspector } from '../debug/Inspector'
import { useComponentVersion } from '../hooks'

export const Snackbar: FC<SnackbarProps> = (props) => (
  <Inspector name="Snackbar" showExample={false} component={OsSnackbar} props={props} />
)

export enum OsSnackbarVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type SnackbarProps = Mui4SnackbarProps

const OsSnackbar: FC<SnackbarProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('snackbar')

  switch (version) {
    case OsSnackbarVersion.V2: {
      const { ClickAwayListenerProps, ContentProps, onClose, ...rest } = props
      return (
        <Mui5Snackbar
          ref={ref}
          {...rest}
          ClickAwayListenerProps={{
            ...ClickAwayListenerProps,
            onClickAway: ClickAwayListenerProps?.onClickAway as (event) => void,
            children: ClickAwayListenerProps?.children as ReactElement<any>,
          }}
          ContentProps={ContentProps as Mui5SnackbarProps['ContentProps']}
          onClose={onClose as (event, reason) => void}
        />
      )
    }
    default:
      return <Mui4Snackbar {...props} />
  }
})
