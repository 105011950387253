import { Delete } from '@material-ui/icons'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import MultipleCardField, { BtnWrapper } from '../common/MultipleCardField'
import { validateRequiredYoutubeUrl } from '../form'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled?: boolean
}
const VideoFields: FC<PropTypes> = ({ disabled = false }) => {
  const translate = useTranslate()
  const source = useBlockSource('videos')
  if (!source) return null
  return (
    <InputAccordion title={translate('Videos')} defaultExpanded={false} source={source}>
      <MultipleCardField
        source={source}
        addLimit={10}
        cardTitle={translate('Video')}
        render={(item, { index, deleteCard }) => (
          <VideoInputs
            key={'video-' + index}
            source={`${source}.${index}`}
            disabled={disabled}
            removeCard={() => {
              deleteCard(index)
            }}
            index={index}
          />
        )}
      />
    </InputAccordion>
  )
}

interface VideoInputsProps {
  source: string
  disabled: boolean
  removeCard: () => void
  index: number
}
const VideoInputs: FC<VideoInputsProps> = ({ source, disabled, removeCard, index }) => {
  const translate = useTranslate()
  return (
    <InputAccordion title={`${translate('Video')} ${index + 1}`}>
      <TextInput
        source={`${source}.url`}
        label="Youtube URL"
        disabled={disabled}
        validate={validateRequiredYoutubeUrl}
        helperText={'example: https://www.youtube.com/watch?v=zhoLKq4oq1k'}
      />

      <BtnWrapper>
        <Button onClick={removeCard} startIcon={<Delete />} variant="contained" color="error" style={{ marginTop: 20 }}>
          {translate('Delete Video')}
        </Button>
      </BtnWrapper>
    </InputAccordion>
  )
}

export default VideoFields
