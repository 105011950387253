import { makeStyles } from '@material-ui/core/styles'
import { permissionsSelectors } from 'ducks/permissions'
import { AlertTriangleOutlineIcon, BaseTheme, Button, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ANON_SHARE_CONTACT } from 'types/contacts'
import { Theme } from 'types/themes'
import ContactInput from './ContactInput'
import ContactLinkExisting from './ContactLinkExisting'

const useStyles = makeStyles((theme: Theme) => ({
  accordionStyle: {
    backgroundColor: BaseTheme.palette.grey[60],
    padding: 0,
  },
  removeBtn: {
    marginLeft: 'auto !important',
  },
}))

interface PropTypes {
  index: number
  contact: any
  handleAssign: (contact: any, index: number) => void
  handleDeleteContact: (id: number) => void
  handleRemove: (index: number) => void
  handleAddContact: (isNew: boolean, index: number) => void
  showErrors?: boolean
}

const ContactAccordion: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { contact, index, handleAssign, handleDeleteContact, handleRemove, showErrors, handleAddContact } = props
  const expand = contact?.id !== undefined && contact?.id !== null
  const { allowDelete } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic'))
  const [isNewContact, setIsNewContact] = useState(!contact?.assignExisting)

  const isHideButton = () => {
    const allowedKeys = new Set(['assignExisting', 'custom_data'])
    return Object.keys(contact || {}).some((key) => !allowedKeys.has(key))
  }

  const getTitle = () => {
    const hideButtons = isHideButton()
    return hideButtons
      ? !contact?.first_name && !contact?.family_name
        ? !contact?.id
          ? `${translate('Contact')} #${index + 1}`
          : `${translate('Contact')} ${contact.id}`
        : `${contact.first_name || ''} ${contact.family_name || ''}`
      : ''
  }

  if (contact?.type === ANON_SHARE_CONTACT) return null
  return (
    <AccordionCard
      className={classes.accordionStyle}
      elevation={0}
      defaultExpanded={!expand}
      name={'contact-' + index}
      title={getTitle()}
      titleIcon={() => (
        <>
          {showErrors && (!contact?.email || contact?.email.trim() === '') && (
            <AlertTriangleOutlineIcon color={BaseTheme.palette.red[800]} />
          )}
          {!isHideButton() ? (
            <ToggleButtonGroup
              exclusive
              value={isNewContact}
              onChange={(e, value) => {
                e.stopPropagation()
                if (value !== null) {
                  setIsNewContact(value)
                  handleAddContact(value, index)
                }
              }}
            >
              <ToggleButton value={true}>{translate('New Contact')}</ToggleButton>
              <ToggleButton value={false}>{translate('Existing Contact')}</ToggleButton>
            </ToggleButtonGroup>
          ) : null}
        </>
      )}
      content={contact?.assignExisting ? ContactLinkExisting : ContactInput}
      contentProps={{
        index: index,
        source: `contacts_data.${index}`,
        contact: contact,
        handleAssign: contact?.assignExisting
          ? (contact) => {
              handleAssign(contact, index)
            }
          : undefined,
        handleDeleteContact: handleDeleteContact,
        showErrors: showErrors,
      }}
      summaryContent={
        <Button
          variant="text"
          color="error"
          onClick={(event) => {
            event.stopPropagation() // Prevents the accordion from collapsing
            handleRemove(index)
          }}
          className={classes.removeBtn}
          disabled={!allowDelete}
        >
          {translate('Remove from Project')}
        </Button>
      }
    />
  )
}
export default ContactAccordion
