import { DataProvider } from 'ra-core'

interface WeatherCacheSuccess {
  success: true
  status: string
  message: string
  coordinates?: {
    lon: number
    lat: number
    dataset: number
  }
}

interface WeatherCacheError {
  success: false
  error: string
  detail?: string
  coordinates?: {
    lon: number
    lat: number
    dataset: number
  }
}

type WeatherCacheResult = WeatherCacheSuccess | WeatherCacheError

export const preWarmWeatherCacheFromLonLat = (dataProvider: DataProvider) => async (
  lon: number,
  lat: number,
  weather_dataset: number
): Promise<WeatherCacheResult> => {
  try {
    const response = await dataProvider.CUSTOM_GET('pre_warm_weather_cache', {
      url: `pre_warm_weather_cache/?lon=${lon}&lat=${lat}&weather_dataset=${weather_dataset}`,
    })

    return {
      success: true,
      ...response.data,
    } as WeatherCacheSuccess
  } catch (error: any) {
    const errorData = error.response?.data as WeatherCacheError
    console.error('Weather cache error:', {
      message: errorData?.error || error.message,
      detail: errorData?.detail,
      lon: lon,
      lat: lat,
    })
    return {
      success: false,
      error: errorData?.error || error.message,
      detail: errorData?.detail,
      coordinates: {
        lon,
        lat,
        dataset: weather_dataset,
      },
    }
  }
}
