import { FilterStruct } from 'conditions/types'
import { useConditionalStructs } from 'contexts/structs/hooks/useConditionalStructs'
import { PromoDialogElement } from 'persistentContent/promoDialog/usePromoDialogStructs'

export const useToastNotificationStructs = () => {
  return useConditionalStructs<ToastNotification>('toast-notification', {
    accept_empty_conditions: true,
    recheck_secs: 10,
  })
}

export type ToastNotification = {
  elements: PromoDialogElement[]
  dismiss_label: string
  cta_label: string
  cta_link: string
  show_again_rule: 'never' | 'always' | 'once-per-session'
  conditions: FilterStruct[]
}
