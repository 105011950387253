// @ts-nocheck
import { Location } from 'history'
import inflection from 'inflection'
import { parse } from 'query-string'
import { useCallback } from 'react'
import { match as Match, useLocation } from 'react-router-dom'

import { useVersion } from '.'
import { CRUD_CREATE } from '../actions'
import { useCreate } from '../dataProvider'
import { useTranslate } from '../i18n'
import { RedirectionSideEffect, useNotify, useRedirect } from '../sideEffect'
import { Record } from '../types'
import { useCheckMinimumRequiredProps } from './checkMinimumRequiredProps'

export interface CreateControllerProps {
  loading: boolean
  loaded: boolean
  saving: boolean
  defaultTitle: string
  save: (
    record: Partial<Record>,
    redirect: RedirectionSideEffect,
    callbacks?: {
      onSuccess: () => void
      onFailure: (error: string | { message?: string }) => void
    }
  ) => void
  resource: string
  basePath: string
  record?: Partial<Record>
  redirect: RedirectionSideEffect
  version: number
}

export interface CreateProps {
  basePath: string
  hasCreate?: boolean
  hasEdit?: boolean
  hasList?: boolean
  hasShow?: boolean
  location?: Location
  match?: Match
  record?: Partial<Record>
  resource: string
  successMessage?: string
  redirect?: string
}

/**
 * Prepare data for the Create view
 *
 * @param {Object} props The props passed to the Create component.
 *
 * @return {Object} controllerProps Fetched data and callbacks for the Create view
 *
 * @example
 *
 * import { useCreateController } from 'react-admin';
 * import CreateView from './CreateView';
 *
 * const MyCreate = props => {
 *     const controllerProps = useCreateController(props);
 *     return <CreateView {...controllerProps} {...props} />;
 * }
 */
const useCreateController = (props: CreateProps): CreateControllerProps => {
  useCheckMinimumRequiredProps('Create', ['basePath', 'resource'], props)
  const { basePath, resource, record = {}, hasShow, hasEdit, successMessage, redirect: redirectOverride } = props

  const location = useLocation()
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const recordToUse = getRecord(location, record)
  const version = useVersion()

  const [create, { loading: saving }] = useCreate(resource)

  const save = useCallback(
    (data: Partial<Record>, redirectTo = redirectOverride || 'list', { onSuccess, onFailure } = {}) =>
      create(
        { payload: { data } },
        {
          action: CRUD_CREATE,
          onSuccess: onSuccess
            ? onSuccess
            : ({ data: newRecord }) => {
                notify(successMessage || 'ra.notification.created', 'info', {
                  smart_count: 1,
                })
                redirect(redirectTo, basePath, newRecord.id, newRecord)
              },
          onFailure,
        }
      ),
    [create, notify, successMessage, redirect, basePath]
  )

  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(resource)),
  })
  const defaultTitle = translate('ra.page.create', {
    name: `${resourceName}`,
  })

  return {
    loading: false,
    loaded: true,
    saving,
    defaultTitle,
    save,
    resource,
    basePath,
    record: recordToUse,
    redirect: redirectOverride || getDefaultRedirectRoute(hasShow, hasEdit),
    version,
  }
}

export default useCreateController

export const getRecord = ({ state, search }, record: any = {}) => {
  if (state && state.record) {
    return state.record
  }
  if (search) {
    try {
      const searchParams = parse(search)
      if (searchParams.source) {
        if (Array.isArray(searchParams.source)) {
          console.error(
            `Failed to parse location search parameter '${search}'. To pre-fill some fields in the Create form, pass a stringified source parameter (e.g. '?source={"title":"foo"}')`
          )
          return
        }
        return JSON.parse(searchParams.source)
      }
    } catch (e) {
      console.error(
        `Failed to parse location search parameter '${search}'. To pre-fill some fields in the Create form, pass a stringified source parameter (e.g. '?source={"title":"foo"}')`
      )
    }
  }
  return record
}

const getDefaultRedirectRoute = (hasShow, hasEdit) => {
  if (hasEdit) {
    return 'edit'
  }
  if (hasShow) {
    return 'show'
  }
  return 'list'
}
