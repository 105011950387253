import { Grid, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import OnboardingField from '../OnboardingField'
import { FieldPrefixFilterType } from '../types'

type PropTypes = {
  bankInfoFields: any
  fieldPrefixFilter: FieldPrefixFilterType
  setFieldPrefixFilter?: (boolean) => void
  setIsManualAddressEntryEnabled: (boolean) => void
  isManualAddressEntryEnabled: boolean
}

const TextWrapper = styled('div')({
  margin: '10px 0',
})

const BankInfoSection: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  return (
    <div data-testid="bank-info-section">
      <div>
        <TextWrapper>
          <Typography textVariant="body1">
            {translate(
              "This business bank account is where you'll receive payments from customer transactions. Enter the information exactly as it appears in your bank account."
            )}
          </Typography>
        </TextWrapper>

        <Grid container spacing={2}>
          {props.bankInfoFields?.map((field) => (
            <OnboardingField
              field={field}
              key={field.field_name}
              fieldPrefixFilter={props.fieldPrefixFilter}
              setIsManualAddressEntryEnabled={props.setIsManualAddressEntryEnabled}
              isManualAddressEntryEnabled={props.isManualAddressEntryEnabled}
            />
          ))}
        </Grid>
      </div>
    </div>
  )
}
export default BankInfoSection
