import React, { useState } from 'react'
import MarketingPage from './MarketingPage'
import NonAdminOnboardingForm from './NonAdminOnboardingForm'
import PaymentsOnboardingWrapper from './PaymentsOnboardingWrapper'

type PropTypes = {}

const LaunchPaymentsOnboardingDialog: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showMarketingDialog, setShowMarketingDialog] = useState<boolean>(true)
  const [showNonAdminDialog, setShowNonAdminDialog] = useState<boolean>(false)

  const closeDialog = () => {
    setShowDialog(false)
  }
  return (
    <div>
      <MarketingPage
        showMarketingDialog={showMarketingDialog}
        setShowMarketingDialog={setShowMarketingDialog}
        setShowDialog={setShowDialog}
        setShowNonAdminDialog={setShowNonAdminDialog}
      />
      {showNonAdminDialog && (
        <NonAdminOnboardingForm
          closeDialog={closeDialog}
          showNonAdminDialog={showNonAdminDialog}
          setShowNonAdminDialog={setShowNonAdminDialog}
          setShowMarketingDialog={setShowMarketingDialog}
        />
      )}
      {showDialog && (
        <PaymentsOnboardingWrapper
          closeDialog={closeDialog}
          setShowNonAdminDialog={setShowNonAdminDialog}
          setShowMarketingDialog={setShowMarketingDialog}
        />
      )}
    </div>
  )
}
export default LaunchPaymentsOnboardingDialog
