import { Search } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import ContactAutocomplete from 'projectSections/sections/info/contacts/ContactAutocomplete'
import React, { useState } from 'react'
import { ReferenceInput } from 'react-admin'
import { ContactType } from 'types/contacts'
import GroupedInput from '../../../../teams/GroupedInput'

const EmailRecipientsInputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 30,
  gap: 10,
})

export type Recipient = ContactType & {
  checked?: boolean
  user_email?: string
}

interface EmailRecipientsInputProps {
  addRecipient: (recipient: Recipient) => void
}

const EmailRecipientsInput: React.FC<EmailRecipientsInputProps> = ({ addRecipient }) => {
  const [inputKey, setInputKey] = useState(0)

  const handleRecipientSelection = (value: Recipient) => {
    if (!value.url) return
    addRecipient(value)
    setInputKey((prev) => prev + 1)
  }

  return (
    <EmailRecipientsInputWrapper>
      <GroupedInput>
        <ReferenceInput
          variant="outlined"
          source="selected_member"
          reference="roles___aliases"
          resource="roles"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <ContactAutocomplete
            key={inputKey}
            onSelection={handleRecipientSelection}
            initialValue=""
            showSpinnerWhenRefreshing={true}
            options={{
              InputProps: { startAdornment: <Search />, fullWidth: true },
              containerStyle: { width: '100%', margin: '0' },
              fullWidth: true,
              label: false,
              style: { margin: 0 },
            }}
          />
        </ReferenceInput>
      </GroupedInput>
    </EmailRecipientsInputWrapper>
  )
}

export default EmailRecipientsInput
