import { Fab, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import ContentAdd from '@material-ui/icons/Add'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'
import React, { FC, MouseEventHandler, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'

import Button, { ButtonProps, sanitizeButtonRestProps } from './Button'

const CreateButton: FC<CreateButtonProps> = (props) => {
  const {
    basePath = '',
    className,
    classes: classesOverride,
    label = 'ra.action.create',
    icon = defaultIcon,
    id,
    onClick,
    ...rest
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return isSmall ? (
    <Link id={id} style={{ textDecoration: 'none', color: '#fff' }} to={`${basePath}/create`} onClick={onClick}>
      <Fab
        id={id ? id + '-button' : undefined}
        color="primary"
        className={classnames(classes.floating, className)}
        style={{
          width: 70,
          height: 70,
          borderRadius: '50%',
        }}
        name={'raCreateButton'}
        aria-label={label && translate(label)}
        {...sanitizeButtonRestProps(rest)}
      >
        {icon}
      </Fab>
    </Link>
  ) : (
    <Link id={id} style={{ textDecoration: 'none' }} to={`${basePath}/create`} onClick={onClick}>
      <Button
        id={id ? id + '-button' : undefined}
        variant="outlined"
        color="default"
        size="default"
        name={'raCreateButton'}
        style={{ margin: 5 }}
        startIcon={icon}
        {...(rest as any)}
      >
        <span>{translate(label)}</span>
      </Button>
    </Link>
  )
}

const defaultIcon = <ContentAdd />

const useStyles = makeStyles(
  (theme) => ({
    floating: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 110,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000,
    },
    floatingLink: {
      color: 'inherit',
    },
  }),
  { name: 'RaCreateButton' }
)

interface Props {
  basePath?: string
  icon?: ReactElement
  onClick?: MouseEventHandler<any>
}

export type CreateButtonProps = Props & ButtonProps

CreateButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

const enhance = onlyUpdateForKeys(['basePath', 'label', 'translate'])
export default enhance(CreateButton)
