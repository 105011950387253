import React, { FC } from 'react'

/**
 * * Icon Component for: user-profile-add-01
 */
type UserProfileAddIconProps = {
  size?: number
  color?: string
}
export const UserProfileAddIcon: FC<UserProfileAddIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <path
        d="M0.400391 20.5999C0.400329 21.1522 0.847993 21.6 1.40028 21.6C1.95256 21.6001 2.40033 21.1524 2.40039 20.6001L0.400391 20.5999ZM1.4008 16.9996L2.4008 16.9997L1.4008 16.9996ZM11.6004 14.4C12.1527 14.4 12.6004 13.9523 12.6004 13.4C12.6004 12.8477 12.1527 12.4 11.6004 12.4V14.4ZM20.6004 15.6C21.1527 15.6 21.6004 15.1523 21.6004 14.6C21.6004 14.0477 21.1527 13.6 20.6004 13.6V15.6ZM15.2004 13.6C14.6481 13.6 14.2004 14.0477 14.2004 14.6C14.2004 15.1523 14.6481 15.6 15.2004 15.6V13.6ZM16.9004 17.2999C16.9004 17.8522 17.3481 18.2999 17.9004 18.2999C18.4527 18.2999 18.9004 17.8522 18.9004 17.2999H16.9004ZM18.9004 11.8999C18.9004 11.3476 18.4527 10.8999 17.9004 10.8999C17.3481 10.8999 16.9004 11.3476 16.9004 11.8999H18.9004ZM12.4004 5.00002C12.4004 6.43596 11.2363 7.60002 9.80039 7.60002V9.60002C12.3409 9.60002 14.4004 7.54053 14.4004 5.00002H12.4004ZM9.80039 7.60002C8.36445 7.60002 7.20039 6.43596 7.20039 5.00002H5.20039C5.20039 7.54053 7.25988 9.60002 9.80039 9.60002V7.60002ZM7.20039 5.00002C7.20039 3.56408 8.36445 2.40002 9.80039 2.40002V0.400024C7.25988 0.400024 5.20039 2.45951 5.20039 5.00002H7.20039ZM9.80039 2.40002C11.2363 2.40002 12.4004 3.56408 12.4004 5.00002H14.4004C14.4004 2.45951 12.3409 0.400024 9.80039 0.400024V2.40002ZM2.40039 20.6001L2.4008 16.9997L0.400795 16.9995L0.400391 20.5999L2.40039 20.6001ZM5.00079 12.4C2.46049 12.4 0.401081 14.4592 0.400795 16.9995L2.4008 16.9997C2.40096 15.5639 3.56497 14.4 5.00079 14.4V12.4ZM5.00079 14.4H11.6004V12.4H5.00079V14.4ZM20.6004 13.6H17.9004V15.6H20.6004V13.6ZM17.9004 13.6H15.2004V15.6H17.9004V13.6ZM18.9004 17.2999V14.6H16.9004V17.2999H18.9004ZM18.9004 14.6V11.8999H16.9004V14.6H18.9004Z"
        stroke={color}
        fill={color}
      />
    </svg>
  )
}
