import { FC } from 'react'

/**
 * * Icon Component for: x-01-filled, x-02-filled, x-03-filled
 */
export type CloseIconProps = {
  variant?: 1 | 2 | 3
  size?: number
  color?: string
}
export const CloseIcon: FC<CloseIconProps> = ({ variant = 1, size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...rest} {...iconAttributes}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.93934 3.93934C4.52513 3.35355 5.47487 3.35355 6.06066 3.93934L12 9.87868L17.9393 3.93934C18.5251 3.35356 19.4749 3.35356 20.0607 3.93934C20.6464 4.52513 20.6464 5.47488 20.0607 6.06066L14.1213 12L20.0607 17.9393C20.6464 18.5251 20.6464 19.4749 20.0607 20.0607C19.4749 20.6464 18.5251 20.6464 17.9393 20.0607L12 14.1213L6.06066 20.0607C5.47487 20.6464 4.52513 20.6464 3.93934 20.0607C3.35355 19.4749 3.35355 18.5251 3.93934 17.9393L9.87868 12L3.93934 6.06066C3.35355 5.47487 3.35355 4.52513 3.93934 3.93934Z"
            fill={color}
          />
        </svg>
      )
    case 2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...rest} {...iconAttributes}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.93934 4.93934C5.52513 4.35355 6.47487 4.35355 7.06066 4.93934L12 9.87868L16.9393 4.93934C17.5251 4.35356 18.4749 4.35356 19.0607 4.93934C19.6464 5.52513 19.6464 6.47488 19.0607 7.06066L14.1213 12L19.0607 16.9393C19.6464 17.5251 19.6464 18.4749 19.0607 19.0607C18.4749 19.6464 17.5251 19.6464 16.9393 19.0607L12 14.1213L7.06066 19.0607C6.47487 19.6464 5.52513 19.6464 4.93934 19.0607C4.35355 18.4749 4.35355 17.5251 4.93934 16.9393L9.87868 12L4.93934 7.06066C4.35355 6.47487 4.35355 5.52513 4.93934 4.93934Z"
            fill={color}
          />
        </svg>
      )
    case 3:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...rest} {...iconAttributes}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.93934 5.93934C6.52513 5.35355 7.47487 5.35355 8.06066 5.93934L12 9.87868L15.9393 5.93934C16.5251 5.35356 17.4749 5.35356 18.0607 5.93934C18.6464 6.52513 18.6464 7.47488 18.0607 8.06066L14.1213 12L18.0607 15.9393C18.6464 16.5251 18.6464 17.4749 18.0607 18.0607C17.4749 18.6464 16.5251 18.6464 15.9393 18.0607L12 14.1213L8.06066 18.0607C7.47487 18.6464 6.52513 18.6464 5.93934 18.0607C5.35355 17.4749 5.35355 16.5251 5.93934 15.9393L9.87868 12L5.93934 8.06066C5.35355 7.47487 5.35355 6.52513 5.93934 5.93934Z"
            fill={color}
          />
        </svg>
      )
  }
}
