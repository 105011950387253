import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { Theme } from 'types/themes'
import DeliveryDetail from './components/DeliveryDetail'
import { SOURCE_CONTEXT_MAPPING } from './constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from './styles'
import { ContactInfoType, DeliveryAddressInfoType, SectionPropType } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  edit: {
    fontSize: '12px',
    fontWeight: 600,
    color: COLOR_PALETTE.blue,
    cursor: 'pointer',
  },
}))

const DeliveryDetailSection: FC<SectionPropType> = ({ sortOrder, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const [editable, setEditable] = useState<boolean>(false)
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressInfoType>()
  const [contactDetails, setContactDetails] = useState<ContactInfoType | undefined>(undefined)
  const [hasCompleteContactDetails, setHasCompleteContactDetails] = useState<boolean>(false)
  const translate = useTranslate()

  useEffect(() => {
    const generalContactInfo: ContactInfoType | undefined = contactInfo ? contactInfo['general'] : undefined
    const iscompleteContactDetails =
      !isEmpty(generalContactInfo?.email) &&
      !isEmpty(generalContactInfo?.firstName) &&
      !isEmpty(generalContactInfo?.lastName) &&
      !isEmpty(generalContactInfo?.name) &&
      !isEmpty(generalContactInfo?.phoneNumber)
    setContactDetails(generalContactInfo)
    setEditable(!iscompleteContactDetails)
    setHasCompleteContactDetails(iscompleteContactDetails)
  }, [contactInfo])

  useEffect(() => {
    setDeliveryAddress(deliveryAddressInfo['general'])
  }, [deliveryAddressInfo])

  const handleEdit = () => {
    if (!editable) {
      setEditable(true)
    }
    if (editable && hasCompleteContactDetails) {
      const updatedContactInfo = {}
      const updatedDeliveryAddressInfo = {}
      Object.keys(contactInfo).forEach((key) => {
        updatedContactInfo[key] = contactDetails
        updatedDeliveryAddressInfo[key] = deliveryAddress
      })

      preOrderPresenter?.updateContactInfo(updatedContactInfo)
      preOrderPresenter?.updateDeliveryAddressInfo(updatedDeliveryAddressInfo)
      setEditable(false)
    }
  }

  const handleUpdateDeliveryAddress = (input: DeliveryAddressInfoType) => {
    setDeliveryAddress(input)
  }

  const handleUpdateContactDetails = (field: string, value: string) => {
    const updatedcontactDetails = {
      ...contactDetails,
      [field]: value,
    }

    if (field === 'firstName') {
      updatedcontactDetails['name'] = `${value} ${contactDetails?.lastName}`
    } else if (field === 'lastName') {
      updatedcontactDetails['name'] = `${contactDetails?.firstName} ${value}`
    }

    setContactDetails({
      ...updatedcontactDetails,
      [field]: value,
    })
    setHasCompleteContactDetails(
      !isEmpty(updatedcontactDetails?.email) &&
        !isEmpty(updatedcontactDetails?.firstName) &&
        !isEmpty(updatedcontactDetails?.lastName) &&
        !isEmpty(updatedcontactDetails?.name) &&
        !isEmpty(updatedcontactDetails?.phoneNumber)
    )
  }

  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={sectionStyles.sectionCardTop}>
        <div className={sectionStyles.row} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={sectionStyles.headerTitle}>{translate(`${sortOrder}. Delivery Details`)}</span>
          <span className={classes.edit} onClick={handleEdit}>
            {translate(editable ? 'Save' : 'Edit')}
          </span>
        </div>
      </div>
      <div className={sectionStyles.sectionCardBottom}>
        <DeliveryDetail
          contactInfo={contactDetails}
          deliveryAddressInfo={deliveryAddressInfo ? deliveryAddressInfo['general'] : undefined}
          editable={editable}
          handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
          handleUpdateContactDetails={handleUpdateContactDetails}
        />
      </div>
    </div>
  )
}

export default memo(DeliveryDetailSection)
