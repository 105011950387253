import { Theme, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '../../styles'
import LoanMilestoneCell from './LoanMilestoneCell'
import { ExpectedMilestonePaymentType } from './types'
import { formatCurrencyWithSymbol } from './utils'

const Container = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  minWidth: '100%',
  padding: '5px 20px',
  overflowX: 'auto',
  boxSizing: 'border-box',
  marginBottom: isMobile ? '10px' : '0px',
  marginRight: !isMobile ? '10px' : '0px',
}))

type PropTypes = {
  onChangeTotalAmount: (amount: number) => void
  milestones: ExpectedMilestonePaymentType[]
  currencySymbol: string
  milestonesChecked: { [key: number]: boolean }
  setMilestonesChecked: (value) => void
  onCheck: (key: number) => void
}

const LoanMilestoneTimeline: React.FC<PropTypes> = (props) => {
  const { onChangeTotalAmount, milestones, currencySymbol, setMilestonesChecked, milestonesChecked, onCheck } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    // On load, set all unpaid milestones as checked
    setMilestonesChecked((prev) => {
      return {
        ...milestones.reduce((acc, curr) => {
          acc[curr.payment_number] = !curr.is_paid
          return acc
        }, {}),
      }
    })
  }, [milestones])

  useEffect(() => {
    // Compute total amount of checked milestones
    if (milestones.length && Object.keys(milestonesChecked).length) {
      const sum = milestones
        .filter((m) => milestonesChecked[m.payment_number] && !m.is_paid)
        .reduce((partialSum, m) => partialSum + m.payment_amount, 0)

      onChangeTotalAmount(sum)
    }
  }, [milestonesChecked, milestones])

  const handleOnCheck = (payment_number: number) => {
    setMilestonesChecked((prev) => ({ ...prev, [payment_number]: !prev[payment_number] }))
    onCheck(payment_number)
  }

  return (
    <>
      <Container isMobile={isMobile}>
        {milestones.map((milestone, idx) => (
          <LoanMilestoneCell
            key={milestone.payment_number}
            onCheck={handleOnCheck}
            isChecked={!!milestonesChecked?.[milestone.payment_number]}
            isPaid={milestone.is_paid}
            isLast={milestones.length === idx + 1}
            title={milestone.title}
            amount={`${formatCurrencyWithSymbol(milestone.payment_amount, currencySymbol, undefined, 0)}`}
            paymentNumber={milestone.payment_number}
          />
        ))}
      </Container>
    </>
  )
}

export default LoanMilestoneTimeline
