import { PanelConfiguration } from "types/global";
import { AVERAGE_MAX_TEMP_IRRADIANCE, DEFAULT_NOCT, NOCT_AIR_TEMP, NOCT_IRRADIANCE, noctByPanelConfig } from "./Constants";

// Function to calculate the added temperature increase dynamically
export const calculateAddedTempIncrease = (noct: number, panelConfig: PanelConfiguration) => {
  const assumedNOCT = noctByPanelConfig[panelConfig] ?? DEFAULT_NOCT;
  
  return AVERAGE_MAX_TEMP_IRRADIANCE * ((noct ?? assumedNOCT) - NOCT_AIR_TEMP) / NOCT_IRRADIANCE  // Using the NOCT formula
}

export const isValueWithinRange = (range: number[], value: number) => {
  return value >= range[0] && value <= range[1]
}

export const isSolarEdgeInverter = (inverter: any) => {
  return inverter?.manufacturer_name.toLowerCase().includes('solaredge')
}

export const inverterOverSizingRatio = (inverter: any) => {
  if (isSolarEdgeInverter(inverter)) {
    return 1.35
  } else if (inverter?.microinverter) {
    return 1.5
  } else {
    // This is essentially 1.33 DC to AC ratio. However, we want to use this formula as that is how the CEC in
    // Australia calculates this oversizing ratio.
    return 1 / 0.75
  }
}

export const getMinMaxTemperature = (formValues: any) => {
  const [default_min_temp, default_max_temp] = formValues?.temperature_min_max || []
  const min_temp_override = formValues?.temperature_min_override
  const max_temp_override = formValues?.temperature_max_override
  const min_temp = min_temp_override || default_min_temp || 0
  const max_temp = max_temp_override || default_max_temp || 0
  return [min_temp, max_temp]
}

export const getInverterVoltageRange = (inverter: any) => {
  return [inverter?.voltage_minimum, inverter?.voltage_max]
}

export const getInverterMpptVoltageRange = (inverter: any) => {
  // Current we use the minimum voltage of the inverter if the minimum MPPT voltage is not provided.
  return [inverter?.mppt_voltage_min || inverter?.voltage_minimum, inverter?.mppt_voltage_max]
}

export const getInverterMaxParallelStrings = (inverter: any, maxPanelIsc: number) => {
  if (maxPanelIsc === 0) {
    console.error(`Couldn't calculate max parallel strings as maxPanelIsc is 0.
      This could be due to 'temp_coefficient_voc' missing or zero on the module.`)
    return null
  }
  return Math.floor(inverter?.current_isc_max / maxPanelIsc)
}

export const getPanelSpecAtTemp = (parameterValue: number, tempCoefficient: number, temp: number) => {
  return parameterValue * (1 + ((temp - 25) * tempCoefficient) / 100)
}

export const getMaxPanelsInSeries = (inverter: any, maxPanelVoc: number, maxPanelVmp: number) => {
  const maxPanelsInSeriesVdc = Math.floor(inverter?.voltage_max / maxPanelVoc)
  const maxPanelsInSeriesMppt = Math.floor(inverter?.mppt_voltage_max / maxPanelVmp)

  return Math.min(maxPanelsInSeriesVdc, maxPanelsInSeriesMppt)
}

export const getMinPanelsInSeries = (inverter: any, minPanelVmp: number) => {
  const minPanelsInSeriesVdc = Math.ceil(inverter?.voltage_minimum / minPanelVmp)
  const minPanelsInSeriesMppt = Math.ceil(inverter?.mppt_voltage_min / minPanelVmp)

  if (minPanelsInSeriesMppt) {
    // To handle missing data for minimum inverter MPPT voltage
    return Math.min(minPanelsInSeriesVdc, minPanelsInSeriesMppt)
  } else {
    return minPanelsInSeriesVdc
  }
}

export const getMaxPanelsForInverter = (
  inverter: any,
  maxPanelsInSeries: number,
  minPanelsInSeries: number,
  maxParallelStrings: number,
  maxPanelsForInverter: number
) => {
  if (maxParallelStrings) {
    const validPanelsToInverter: number[] = []
    for (let mppts = inverter?.mppt_quantity; mppts > 0; mppts--) {
      for (let parallelString = maxParallelStrings; parallelString > 0; parallelString--) {
        for (let stringSize = maxPanelsInSeries; stringSize >= minPanelsInSeries; stringSize--) {
          const numberPanels = mppts * parallelString * stringSize
          if (maxPanelsForInverter >= numberPanels) {
            validPanelsToInverter.push(numberPanels)
          }
        }
      }
    }
    return Math.max(...validPanelsToInverter)
  } else {
    // Handle old data whereby we don't have inverter current ratings to calculate max parallel strings
    return maxPanelsForInverter
  }
}
