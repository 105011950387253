import React from 'react'
import PropTypes from 'prop-types'
import Title, { TitlePropType } from './Title'
import { useTranslate } from 'ra-core'

const TitleForRecord = ({ defaultTitle, record, title, subtitle }) => {
  const translate = useTranslate()
  return (
    <>
      {record && <Title title={title} record={record} defaultTitle={defaultTitle} />}
      {subtitle && (
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            margin: 0,
          }}
        >
          {typeof subtitle === 'string' ? translate(subtitle) : subtitle}
        </p>
      )}
    </>
  )
}

TitleForRecord.propTypes = {
  defaultTitle: PropTypes.any,
  record: PropTypes.object,
  title: TitlePropType,
}

export default TitleForRecord
