// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import classnames from 'classnames'
import inflection from 'inflection'
import PropTypes from 'prop-types'
import {
  CRUD_DELETE,
  Record,
  RedirectionSideEffect,
  useDelete,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'ra-core'
import { FC, Fragment, ReactElement, SyntheticEvent, useCallback, useState } from 'react'

import Confirm from '../layout/Confirm'
import Button, { ButtonProps } from './Button'

const listStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0 }
const defaultStyle = {}

const DeleteWithConfirmButton: FC<DeleteWithConfirmButtonProps> = (props) => {
  const {
    basePath,
    className,
    onSuccess: onSuccessCallback,
    onFailure: onFailureCallback,
    confirmTitle = 'ra.message.delete_title',
    confirmContent = 'ra.message.delete_content',
    icon = defaultIcon,
    label = 'ra.action.delete',
    onClick,
    record,
    resource,
    useListStyle = false,
    redirect: redirectTo = 'list',
    ...rest
  } = props
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [deleteOne, { loading }] = useDelete(resource, record.id, record, {
    action: CRUD_DELETE,
    onSuccess: () => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (onSuccessCallback) {
        onSuccessCallback()
      }
      redirect(redirectTo, basePath)
      refresh()
    },
    onFailure: (error) => {
      setOpen(false)
      if (onFailureCallback) {
        onFailureCallback()
      }
    },
    undoable: false,
  })

  const handleClick = (e) => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleDialogClose = (e) => {
    setOpen(false)
    e.stopPropagation()
  }

  const handleDelete = useCallback(
    (event) => {
      deleteOne()
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    [deleteOne, onClick]
  )
  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames('ra-delete-button', 'MuiButton-textPrimary', className)}
        key="button"
        variant={props.name === 'editDeleteButton' ? 'contained' : 'text'}
        size={props.name === 'editDeleteButton' ? 'large' : 'small'}
        color={'default'}
        style={useListStyle ? listStyle : defaultStyle}
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: inflection.humanize(
            translate(`resources.${resource}.name`, {
              smart_count: 1,
              _: inflection.singularize(resource),
            }),
            true
          ),
          id: record.title || record.id,
        }}
        confirmColor="warning"
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

const defaultIcon = <DeleteOutlinedIcon />

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' }
)

interface Props {
  basePath?: string
  onSuccess?: Function
  onFailure?: Function
  classes?: object
  className?: string
  confirmTitle?: string
  confirmContent?: string
  icon?: ReactElement
  label?: string
  onClick?: (e: MouseEvent) => void
  record?: Record
  redirect?: RedirectionSideEffect
  resource?: string
  // May be injected by Toolbar - sanitized in Button
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
  invalid?: boolean
  pristine?: boolean
  saving?: boolean
  submitOnEnter?: boolean
  undoable?: boolean
  useListStyle?: boolean
}

type DeleteWithConfirmButtonProps = Props & ButtonProps

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  icon: PropTypes.element,
}

export default DeleteWithConfirmButton
