import { Grid, Typography } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DistributorChips from './DistributorsChip'
import QuantityButton from './QuantityButton'

import ExpoTooltip from 'pages/expo/ExpoTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setToggleGlobalCart } from 'reducer/designer/view'
import { orgSelectors } from '../../../ducks/orgs'
import { ComponentLikeType, DistributorDataTypeV2, StockLevelTypeV2 } from '../../../elements/hardwareSelectorV2/types'
import { useGlobalCart } from '../../../hooks/useGlobalCart'
import { currencySymbolForCountry, formatCurrencyNumber, format_percentage_value } from '../../../util/misc'
import { getSupplierConfigByFilterKey } from '../configs'
import { DISTRIBUTOR_LABEL } from '../constants'
import PercentageDiscountTag from '../elements/PercentageDiscountTag'
import OrderLineItem from '../OrderLineItem'
import { HardwareSupplierEnum } from '../type'
import { sortByPriceOrderingV2 } from '../utils'
import { AddToCartButton } from './AddToCartButton'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  container: {
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    gap: '16px',
    width: '236px',
    height: '436px',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: '#4272DD',
      cursor: 'pointer',
    },
  },
  priceTag: {
    margin: '4px 0px',
    fontSize: '18px',
  },
  discountTag: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  productImageBg: {
    background: theme.greyLight1,
    height: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productImage: {
    width: '100%',
    height: '160px',
    objectFit: 'contain',
    borderRadius: '8px',
  },
  section: {
    flex: 2,
  },
  section2: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  shortDescription: {
    width: '100%',
    '& p': {
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      margin: 0,
      wordBreak: 'break-word',
    },
  },
  expoRibbonContainer: {
    minHeight: '27px',
  },
  gridFullHeight: {
    height: '100%',
  },
}))

export const ComponentPlaceHolderImage = () => {
  const classes = useStyles()

  return (
    <div className={classes.productImageBg}>
      <img src={`${window.PUBLIC_URL}/images/component-image-placeholder.png`} />
    </div>
  )
}

interface PricingProps {
  selectedDistributor?: DistributorDataTypeV2 | null
  lowestPriceDistributor?: DistributorDataTypeV2
  hasOneDistributor: boolean
  totalCost?: string
  currencySymbol: string
}

export const PricingText = ({
  selectedDistributor,
  lowestPriceDistributor,
  hasOneDistributor,
  totalCost,
  currencySymbol,
}: PricingProps) => {
  const classes = useStyles()

  const distributorDisplayName = selectedDistributor?.distributor
    ? DISTRIBUTOR_LABEL[selectedDistributor?.distributor]
    : ''

  if (selectedDistributor || hasOneDistributor) {
    return (
      <>
        <Grid container item alignItems="center" justify="flex-start" spacing={1}>
          <Grid item>
            <h2 className={classes.priceTag}>
              {currencySymbol}
              {formatCurrencyNumber(selectedDistributor?.price, 2)}
            </h2>
          </Grid>
          <Grid item>
            <Typography variant="body2">from {distributorDisplayName}</Typography>
          </Grid>
        </Grid>
        <Grid item>{/*<Typography textVariant="body1SemiBold">{totalCost && totalCost}</Typography>*/}</Grid>
      </>
    )
  }

  return (
    <Grid alignContent="center">
      <h1 className={classes.priceTag}>
        From {currencySymbol}
        {formatCurrencyNumber(lowestPriceDistributor?.price, 2)}
      </h1>
    </Grid>
  )
}

export const getQuantity = (arr?: StockLevelTypeV2[]): StockLevelTypeV2 | null => {
  if (!arr) {
    return null
  }
  return arr.find(({ quantity }) => quantity) || null
}

const getStockLevelLabel = (stockLevel: StockLevelTypeV2 | null, distributor?: DistributorDataTypeV2) => {
  if (!distributor || !stockLevel) {
    return 'Out of stock'
  }

  const distributoConfig = getSupplierConfigByFilterKey(distributor.distributor)
  if (distributoConfig.type === HardwareSupplierEnum.SolarOutlet) {
    return stockLevel.quantity > 0 ? 'In Stock' : 'Out of stock'
  }

  return stockLevel?.quantity && Number(stockLevel.quantity) > 0 ? `${stockLevel.quantity} in stock` : 'Out of stock'
}

interface ProductCardProps<T> {
  component: T
}

const ProductTile = ({ component }: ProductCardProps<ComponentLikeType>) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { addLineItemFromV2Component, updateLineItemQtyByCode, removeLineItem, orderableLineItems } = useGlobalCart()

  const [selectedDistributor, setSelectedDistributor] = useState<DistributorDataTypeV2 | null>(null)
  const [cartItem, setCartItem] = useState<OrderLineItem | null>(null)
  const [isImageLinkBroken, setIsImageBroken] = useState(false)

  const lowestPriceDistributor = sortByPriceOrderingV2(component.ordering_v2).find((ordering) => ordering.price)

  const displayDetailsDistributor = selectedDistributor || lowestPriceDistributor
  const stockLevel: StockLevelTypeV2 | null =
    getQuantity(selectedDistributor?.stock_levels) || getQuantity(lowestPriceDistributor?.stock_levels)
  const discountPercentValue = displayDetailsDistributor?.price_detail.discount_value

  const isSegen = displayDetailsDistributor
    ? getSupplierConfigByFilterKey(displayDetailsDistributor.distributor).type === HardwareSupplierEnum.Segen
    : false

  const hasStocks = isSegen || (stockLevel?.quantity && Number(stockLevel.quantity) > 0)

  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  const handleAddToCart = (event) => {
    if (event.stopPropagation) event.stopPropagation()

    if (!displayDetailsDistributor || Object.keys(displayDetailsDistributor).length === 0) {
      return
    }
    addLineItemFromV2Component(component, displayDetailsDistributor)
    dispatch(setToggleGlobalCart(true))
  }

  const handleQuantityChange = (quantity: number) => {
    if (quantity === 0) {
      removeLineItem(cartItem?.uuid)
      setCartItem(null)
      return
    }
    updateLineItemQtyByCode(component.code, quantity)
  }

  const handleClick = () => {
    history.push('/shop/product/' + encodeURIComponent(component.code))
  }

  const handleDistributorSelect = (event, distributor) => {
    if (event.stopPropagation) event.stopPropagation()
    setSelectedDistributor(distributor)
  }

  useEffect(() => {
    const cartOrderItem = orderableLineItems.find((lineItem: OrderLineItem) => lineItem.code === component.code)
    if (!cartOrderItem) {
      setCartItem(null)
      return
    }
    setCartItem(cartOrderItem)
  }, [orderableLineItems])

  useEffect(() => {
    if (
      component?.ordering_v2?.length === 1 &&
      component.ordering_v2[0].distributor !== selectedDistributor?.distributor
    ) {
      setSelectedDistributor(component.ordering_v2[0])
    }
  }, [component.ordering_v2])

  return (
    <div className={classes.container} onClick={handleClick}>
      <Grid container direction="column" justify="space-between" alignItems="center" className={classes.gridFullHeight}>
        <Grid item container className={classes.section}>
          {!isImageLinkBroken && displayDetailsDistributor && displayDetailsDistributor?.image_url ? (
            <div style={{ position: 'relative', width: '100%' }}>
              {discountPercentValue && (
                <div className={classes.discountTag}>
                  <PercentageDiscountTag showUpTo={false} percentage={format_percentage_value(discountPercentValue)} />
                </div>
              )}
              <img
                src={displayDetailsDistributor?.image_url}
                alt="Product"
                className={classes.productImage}
                onError={() => setIsImageBroken(true)}
              />
            </div>
          ) : (
            <ComponentPlaceHolderImage />
          )}
        </Grid>

        <Grid item container justify="flex-end" direction="column" spacing={1} className={classes.section}>
          <Grid item className={classes.expoRibbonContainer}>
            {component.partner_expo_stand && <ExpoTooltip {...component.partner_expo_stand} />}
          </Grid>
          <Grid item>
            <span className={classes.shortDescription}>
              <Typography textVariant="body1">{displayDetailsDistributor?.short_description}</Typography>
            </span>
            <Typography textVariant="body1" colorHex="#757575">
              {component.code}
            </Typography>
          </Grid>
          <Grid item>
            {(selectedDistributor?.price || lowestPriceDistributor?.price) && (
              <PricingText
                hasOneDistributor={component.ordering_v2.length === 1}
                selectedDistributor={selectedDistributor}
                lowestPriceDistributor={lowestPriceDistributor}
                currencySymbol={currencySymbol}
              />
            )}
          </Grid>
        </Grid>

        <Grid item container justify="space-evenly" direction="column" spacing={1} className={classes.section}>
          <Grid item>
            <Typography textVariant="helper1" colorHex="#757575">
              SELECT AVAILABLE DISTRIBUTOR
            </Typography>
            <DistributorChips
              ordering_v2={component.ordering_v2}
              onSelect={handleDistributorSelect}
              selectedDistributor={selectedDistributor}
            />
          </Grid>
          <Grid item>
            <Typography textVariant="body2" colorHex={hasStocks ? '#008000' : '#D32F2F'}>
              {isSegen && 'In Stocks'}
              {!isSegen && getStockLevelLabel(stockLevel, displayDetailsDistributor)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={1} className={classes.section2}>
          <Grid item xs={12}>
            {!!cartItem ? (
              <QuantityButton onChangeQuantity={handleQuantityChange} initialValue={cartItem?.quantity} />
            ) : (
              <AddToCartButton onClick={handleAddToCart} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProductTile
