// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { FunctionComponent } from 'react'

import sanitizeRestProps from './sanitizeRestProps'
import { FieldProps, fieldPropTypes, InjectedFieldProps } from './types'

const useStyles = makeStyles(
  {
    root: { display: 'inline-block' },
  },
  { name: 'RaFileField' }
)

interface Props extends FieldProps {
  src?: string
  title?: string
  target?: string
  classes?: object
}

const FileField: FunctionComponent<Props & InjectedFieldProps> = (props) => {
  const { className, classes: classesOverride, emptyText, record, source, title, src, target, ...rest } = props
  const sourceValue = get(record, source)
  const classes = useStyles(props)

  if (!sourceValue) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : (
      <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)} />
    )
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
        {sourceValue.map((file, index) => {
          const fileTitleValue = get(file, title) || title
          const srcValue = get(file, src) || title

          return (
            <li key={index}>
              <a href={srcValue} title={fileTitleValue} target={target}>
                {fileTitleValue}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  const titleValue = get(record, title) || title

  return (
    <div className={classnames(classes.root, className)} {...sanitizeRestProps(rest)}>
      <a href={sourceValue} title={titleValue} target={target}>
        {titleValue}
      </a>
    </div>
  )
}

FileField.defaultProps = {
  addLabel: true,
}

FileField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
}

export default FileField
