import lodash from 'lodash'
import { OSSDK, OSSDKBootstrap } from './sdk'
import { SdkConfig } from './types/config'
import { buildConfig } from './util/config'

let singletonCreatedCallbacks: ((sdk: OSSDK) => void)[] = []

export function getSdkSingleton_v1(): OSSDK | undefined {
  return window.ossdk
}

export function onSdkSingletonCreated(callback: (sdk: OSSDK) => void): () => void {
  const sdk = getSdkSingleton_v1()
  if (sdk) {
    callback(sdk)
    return () => {}
  }
  singletonCreatedCallbacks.push(callback)
  return () => {
    const idx = singletonCreatedCallbacks.indexOf(callback)
    if (idx >= 0) singletonCreatedCallbacks.splice(idx, 1)
  }
}

// In some cases, SDK users make overlapping calls to `initSdk_v1`.
// This is normally due to poorly written react code, but we should
// still resolve all calls made, at the right time
let lastInitConfig: SdkConfig | undefined
let initPromise: Promise<OSSDK> | undefined

export function initSdk_v1(opts?: SdkConfig): Promise<OSSDK> {
  const origConfig: SdkConfig = opts || {}
  if (origConfig.singleton === undefined) origConfig.singleton = true

  const sdk: OSSDK = getOrCreateSdk(origConfig.singleton)

  if (origConfig.singleton) {
    if (lastInitConfig) {
      const mainMsg = "Secondary call to `initSdk_v1` detected, check that your code isn't mounting OsSdkView twice."
      if (lodash.isEqual(origConfig, lastInitConfig)) {
        sdk.logger.info(
          mainMsg + "\nNote that, as the configs match, this shouldn't cause any issues, but is suboptimal."
        )
      } else {
        sdk.logger.error(
          mainMsg + '\nAs the configs send through do not match, new configs properties will be ignored!'
        )
      }
      return initPromise as Promise<OSSDK> // Will not be undefined if lastInitConfig is not undefined
    }
    lastInitConfig = origConfig
  }

  const initAndConfigSdk = async (sdk: OSSDK, origConfig: SdkConfig): Promise<OSSDK> => {
    let realConfig
    if (window.setInvalidUsageReason) {
      try {
        realConfig = await buildConfig(origConfig, sdk.logger)
      } catch (e) {
        console.error(e)
        if (e instanceof Error) window.setInvalidUsageReason(e.message)
        return sdk
      }
    } else {
      realConfig = await buildConfig(origConfig, sdk.logger)
    }
    sdk.setConfig(origConfig, realConfig)
    return sdk
  }

  const ret = initAndConfigSdk(sdk, origConfig)
  if (origConfig.singleton) {
    initPromise = ret
  }
  return ret
}

function getOrCreateSdk(singleton: boolean): OSSDK {
  let ret = getSdkSingleton_v1()
  if (singleton && ret) {
    return ret
  } else {
    const bootstrap = new OSSDKBootstrap()
    ret = bootstrap.scopeMixin
    if (singleton) {
      window.ossdk = ret
      for (const callback of singletonCreatedCallbacks) {
        callback(ret)
      }
      singletonCreatedCallbacks = []
    }
    return ret
  }
}
