import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useMemo, useState } from 'react'
import { AutocompleteInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { FilterField, OS_PROJECT_FILTERS } from './fields'

interface PropTypes {
  source: string
  filter: FilterField
  activeFilters: string[]
}

const FilterRow = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 10,
  flexDirection: 'row',
  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
    '& > *': {
      width: 'max-content',
    },
  },
}))

const FilterDisplay: React.FC<PropTypes> = ({ source, filter, activeFilters }) => {
  const form = useForm()
  const formValues = useFormState().values
  const translate = useTranslate()
  const [searchedValue, setSearchedValue] = useState('')

  const unselectedFilterOptions = useMemo(() => {
    const filteredOptions = OS_PROJECT_FILTERS.filter((filterOption) => {
      const { id, props, relatedFields } = filterOption
      const isActiveFilter = activeFilters.includes(id)
      const duplicateFilterCount = activeFilters.filter((activeId) => activeId === id).length
      const totalOperators = relatedFields?.length || 1

      if (props) {
        return !isActiveFilter || filter.fieldId === id
      }

      return duplicateFilterCount < totalOperators || filter.fieldId === id
    })
    // If there is a search value
    if (searchedValue) {
      return filteredOptions
        .filter((filterOption) =>
          filterOption.name.toLowerCase().includes(searchedValue.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    }
    // If no search value, return all the filtered options
    return filteredOptions.sort((a, b) => a.name.localeCompare(b.name))
  }, [filter.fieldId, activeFilters, searchedValue])

  const [resetFieldData, setResetFieldData] = useState(false)
  const fieldData = useMemo(() => {
    return resetFieldData ? undefined : OS_PROJECT_FILTERS.find((x) => x.id === filter.fieldId)
  }, [filter.fieldId, resetFieldData])

  return (
    <FilterRow>
      <AutocompleteInput
        source={`${source}.fieldId`}
        label={false}
        variant="outlined"
        choices={unselectedFilterOptions}
        margin="none"
        allowEmpty={false}
        setFilter={(value) => setSearchedValue(value)}
        onChange={(value) => {
          if (value !== null) {
            setResetFieldData(false)
          }
        }}
        options={{
          InputProps: {
            onChange: () => {
              const resetFilters = formValues?.filters?.map((x) => {
                if (x.fieldId === filter.fieldId) {
                  return {
                    ...x,
                    fieldId: undefined,
                    fields: null,
                    value: {}
                  }
                }
                return x
              })
              setResetFieldData(true)
              form.change("filters", [...resetFilters])
            },
            placeholder: translate('Select filter'),
          },
          style: {
            textOverflow: 'ellipsis',
          },
        }}
      />
      {fieldData?.field && (
        <CustomField
          source={source}
          name={source}
          component={fieldData?.field}
          fieldId={fieldData.id}
          {...(fieldData.props || {})}
        />
      )}
    </FilterRow>
  )
}

export default FilterDisplay
