import { DepositCtaButton, DepositCtaLink } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type { CheckoutActionCallback, ProposalDataType } from '../../types'
import type { CashFlowDepositType } from '../../types/CheckoutElementsDataTypes'

const MakeCashflowDeposit = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: CashFlowDepositType
  translate: Translate
  proposalData: ProposalDataType
  options?: {
    isLoading?: boolean
  }
}) => {
  const variant = elementData.variation
  const label = translate(elementData.actionData.action_title)
  const handleAction = () => {
    onAction({ elementData, proposalData })
  }
  return variant === 'button' ? (
    <DepositCtaButton label={label} disabled={false} onClick={handleAction} />
  ) : (
    <DepositCtaLink label={label} onClick={handleAction} />
  )
}

export default MakeCashflowDeposit
