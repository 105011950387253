/**
 * This is a logger that allows call-sites to remain ignorant of whether logging is enabled, or at what level.
 * It also allows for group names to be added to the log messages.
 *
 * (Mostly) API-compatible with native `console` API.
 */

export class Logger {
  get level(): LogLevel {
    return this.explicit_level || window.os_loglevel
  }

  explicit_level: LogLevel | undefined
  color: string

  log_stack: boolean | undefined

  constructor(readonly group: string, opts?: LoggerOpts) {
    this.explicit_level = opts?.level
    this.color = opts?.color || generateColor(group)
  }

  debug(...args: any[]) {
    this.do_log('debug', args)
  }
  log(...args: any[]) {
    this.do_log('info', args)
  }
  info(...args: any[]) {
    this.do_log('info', args)
  }
  warn(...args: any[]) {
    this.do_log('warn', args)
  }
  error(...args: any[]) {
    this.do_log('error', args)
  }

  do_log(level: LogLevel, args: any[]) {
    if (this.should_log(level)) {
      console[level](...this.format_args(level, args))
    }
  }
  should_log(level: LogLevel) {
    return LogLevelPrority.indexOf(level) >= LogLevelPrority.indexOf(this.level)
  }
  format_args(level: LogLevel, args: any[]) {
    const styledGroupName = `background: ${this.color}; border-radius: 2px; color: black; font-size:0.9em; padding: 1px 4px;`
    const prefix = `%c${this.group}`.padEnd(16)
    const ret = [prefix, styledGroupName, ...args]
    if (this.log_stack || (this.log_stack === undefined && level === 'debug')) {
      ret.push(this.get_formatted_stack())
    }
    return ret
  }
  get_formatted_stack() {
    let stack = new Error().stack?.split('\n') || []
    // console.log('stack: ', stack)
    stack.shift() // remove first line 'Error:'
    stack = stack
      .map((s) => s.trim().replace(/^at /, '')) // Trim whitespace and remove leading 'at' (Chrome)
      .map((s) => {
        if (!navigator.userAgent.includes('Firefox')) return s
        // Deal with FF formatting: ../packages/opensolar-sdk/dist/core/peers.js/</Peers.prototype.onMessage/Peers</<@http://localhost:31000/static/js/main.chunk.js:129349:18
        let ff_simple = s.match(/\/<\/[^<](.*)/)
        return ff_simple ? ff_simple[1] : s
      })
      .filter((s) => !s.startsWith('Logger.')) // Filter out call stack items from within this class
    let caller = stack[0]
    let caller_simple = caller.match(/^([\w.]+)/)
    if (caller_simple) caller = caller_simple[1]
    return { caller, stack }
  }
}

// Can be used to duck-type console in for Logger
// e.g. `myFunction(logger:LoggerAPI = console)`
export type LoggerAPI = {
  debug: (...args: any[]) => void
  log: (...args: any[]) => void
  info: (...args: any[]) => void
  warn: (...args: any[]) => void
  error: (...args: any[]) => void
}

type LoggerOpts = {
  color?: string
  level?: LogLevel
}

export type LogLevel = 'debug' | 'log' | 'info' | 'warn' | 'error'
const LogLevelPrority = ['debug', 'log', 'info', 'warn', 'error']

const generateColor = (group: string) => {
  const hash = hashString(group)
  const hue = hash % 360
  return `hsl(${hue}, 100%, 50%)`
}
const hashString = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

declare global {
  interface Window {
    os_loglevel: LogLevel
  }
}

if (!window.os_loglevel) window.os_loglevel = (localStorage.getItem('os_loglevel') || 'info') as LogLevel
