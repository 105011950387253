import { Typography } from '@material-ui/core'
import { useContext } from 'react'
import { OrderContext } from '../..'
import { FullWidthWrapper, SectionCardBottom, SectionCardTop } from '../orderActivity/OrderActivitySection'
import OrderParts from './OrderParts'

const OrderPartsSection = () => {
  const { orderData } = useContext(OrderContext)

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Parts Ordered</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <OrderParts orderData={orderData} />
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default OrderPartsSection
