import {
  DETECT_IMAGERY,
  DETECT_IMAGERY_FAILURE,
  DETECT_IMAGERY_LOADING,
  DETECT_IMAGERY_SUCCESS,
  PRELOAD_3D,
  storeAvailableImagery,
} from 'actions/designer'
import lodash from 'lodash'
import { imagerySelectors } from 'reducer/designer/detectImagery'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { DefaultImageryStrategy } from 'types/imagery'
import { MapDataTypes } from 'types/map'
import { Location4326 } from 'types/studio/structs'

export interface DetectImagerySideEffect {
  payload: {
    location4326: any
    country_iso2: string
    state: string
    address: string
    premiumImgIsAvailable: boolean
    preloadTerrainProvider: any
    is_lite: boolean
    onComplete?: (res: { availableMapTypes: any; timezoneOffset: number } | undefined, e: any) => void
    enablePreload: boolean
  }
}

export const getMapTypesAtLocation = async (
  location4326: Location4326,
  country_iso2: string,
  state: string,
  address: string,
  is_lite: boolean,
  omitMapTypes: string[],
  default_imagery_strategy: DefaultImageryStrategy,
  enablePreload: boolean
) => {
  const includeParts: string[] = []
  if (default_imagery_strategy === 'optimize-for-auto-design') {
    includeParts.push('building_quality')
    includeParts.push('coverage_quality')
  }
  const includePartsStr = includeParts.join(',')

  const queryParams: Record<string, any> = {}
  if (includePartsStr) queryParams.include = includePartsStr
  if (is_lite) queryParams.enable_other_imagery = 0
  if (enablePreload) queryParams.preload = 'gsa'
  let ret
  // first check to see if PAYG premium imagery is available, the result of this call can dictate how we interact with the cached imagery results
  try {
    let premiumImgResult = window.AccountHelper.getIsPremiumImageryAvailable(location4326, country_iso2, state)
    ret = await doGetMapTypesAtLocation(
      location4326,
      country_iso2,
      state,
      address,
      premiumImgResult?.isAvailable,
      queryParams
    )
  } catch {
    ret = await doGetMapTypesAtLocation(location4326, country_iso2, state, address, false, queryParams)
  }

  if (is_lite) {
    const isNearmap = !!window.getStorage().getItem('nearmap_token')

    // Allow Google Top for NM Lite users only if not 2D NM imagery is available
    const allowGoogleTop =
      !isNearmap ||
      !ret[0].find(
        (mapType) =>
          mapType.provider === 'Nearmap' && (mapType.provider === 'Nearmap' || mapType.provider === 'NearmapSource')
      )

    ret[0] = ret[0].filter((mapType) => {
      if (mapType.provider === 'Google') {
        switch (mapType.map_type) {
          case 'GoogleTop':
            return allowGoogleTop
          case 'Google':
            return !isNearmap
          case 'GoogleRoadMap':
            return true
          default:
            return false
        }
      }
      if (mapType.provider === 'MetroMap') return false
      if (mapType.provider === 'Image') return !isNearmap
      if (mapType.design_mode === '3D') return false
      else return true
    })
  }

  if (omitMapTypes?.length > 0) {
    ret[0] = ret[0].filter((mapType) => !omitMapTypes.includes(mapType.map_type))
  }

  return ret
}

const doGetMapTypesAtLocation = async (
  location4326: Location4326,
  country_iso2: string,
  state: string,
  address: string,
  premium_img_available: boolean,
  queryParams: Record<string, any>
) => {
  // @TODO: Ensure cache is cleared at the appropriate times, including:
  //  On change of project
  //  On logout/login of OpenSolar
  //  On change of Nearmap Token (which could theoretically happen wihtout logout/login of OpenSolar, in future)
  //
  // For now, we simply avoid caching any result which includes warning matching "LOGIN_PROMPT".
  var availableMapTypes: MapDataTypes[] = []

  var identifier = 'cachedGetMapTypesAtLocationRequest'
  var cachedGetMapTypesAtLocationRequest = window.AccountHelper.terrainUrlsCache.get(
    identifier,
    location4326,
    country_iso2,
    state,
    premium_img_available
  )
  if (cachedGetMapTypesAtLocationRequest) {
    // console.log('Using cached cachedGetMapTypesAtLocation')
    return cachedGetMapTypesAtLocationRequest
  }

  var fetchPromise

  // Cached data not found, now try to check for an unresolved promise, if we find it then listen to it
  var identifierPromise = 'cachedGetMapTypesAtLocationRequestPromise'
  var cachedGetMapTypesAtLocationRequestPromise = window.AccountHelper.terrainUrlsCache.get(
    identifierPromise,
    location4326,
    country_iso2,
    state,
    premium_img_available
  )
  if (cachedGetMapTypesAtLocationRequestPromise) {
    if (window.studioDebug) {
      console.log('Using cached cachedGetMapTypesAtLocationRequestPromise')
    }
    fetchPromise = cachedGetMapTypesAtLocationRequestPromise
  } else {
    var url =
      window.API_BASE_URL +
      'orgs/' +
      window.getStorage().getItem('org_id') +
      '/maps/check_coverage/?lon=' +
      location4326[0] +
      '&lat=' +
      location4326[1] +
      '&country_iso2=' +
      country_iso2 +
      '&state=' +
      state +
      '&address=' +
      address

    url += lodash.keys(queryParams).reduce((res, key) => {
      return `${res}&${key}=${queryParams[key]}`
    }, '')

    if (window.getStorage().getItem('sample_imagery') === '1') {
      url += '&sample=1'
    }

    var nearmap_token = window.getStorage().getItem('nearmap_token')
    if (nearmap_token) {
      url += '&nearmap_token=' + nearmap_token
    }

    // url += '&sample=1'
    // url += '&sample=1_small'

    fetchPromise = fetch(url, {
      headers: window.Utils.tokenAuthHeaders(),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
    })

    // save the promise so other call can use it
    window.AccountHelper.terrainUrlsCache.save(
      identifierPromise,
      location4326,
      country_iso2,
      state,
      premium_img_available,
      fetchPromise
    )
  }

  var timezoneOffset

  await fetchPromise
    // We must clone the response if we want to be able to read the stream multiple times
    // This only happens if we make a second call while the first is in transit. Future calls after the first
    // have already returned will hit the data cache and will not required processing the stream again
    .then((response) => response.clone().json())
    .then((data) => {
      if (window.studioDebug) {
        console.log('Success:', data)
      }
      availableMapTypes = data.available_map_types
      timezoneOffset = data.timezone_offset

      // Register any mapTypes which are constructed dynamically
      availableMapTypes.forEach((availableMapType) => {
        if (!window.MAP_TYPES[availableMapType.map_type]) {
          if (
            availableMapType.variation_data?.class &&
            window.MAP_TYPES_CONSTRUCTORS[availableMapType.variation_data?.class]
          ) {
            window.MAP_TYPES_CONSTRUCTORS[availableMapType.variation_data?.class](
              availableMapType.map_type,
              availableMapType.variation_data
            )
          } else if (availableMapType.map_type === 'EagleViewInform') {
            // do not throw exception for EagleView maps... we do not have a particular mapType for EagleView
            // but do not throw an error
          } else {
            throw new Error('Map type not recognized: ' + availableMapType.map_type)
          }
        }
      })

      // API Key has different errors to NMOS.
      // If API is invalid then show a simple message.
      // If NMOS is invalid them show login prompt
      // We CANNOT just check that they have NMOS here because they may have lost their token somehow, so we do this
      // the other way - if we find a nearmap token we assume they are NOT NMOS.
      var has_nearmap_login_warning =
        data.warnings && data.warnings.some((warning) => warning.indexOf('LOGIN_PROMPT') !== -1)

      if (has_nearmap_login_warning) {
        if (window.AccountHelper.hasNearmapApiKey()) {
          // Warning is disabled for API Key because we may get these error messages in situations where we should not
          // show an error. e.g. Perhaps their API Key only offers Vertical imagery, Source photos chould show an error.
          // Designer.showNotification(window.translate('Nearmap imagery not found. Please contact support.'), 'danger')
        } else {
          window.editor.signals.requestNearmapLogin.dispatch()
          // window.Designer?.setUiState('LoginWithNearmapDialog', {
          //   isOpen: true,
          // })
          // if (window.Designer && window.Designer.uiRefs && window.Designer.uiRefs['LoginWithNearmapDialog']) {
          //   window.Designer.uiRefs['LoginWithNearmapDialog'].setState({
          //     isOpen: true,
          //   })
          // }
        }
      } else {
        // Only save if no LOGIN_PROMPT warning received
        window.AccountHelper.terrainUrlsCache.save(
          identifier,
          location4326,
          country_iso2,
          state,
          premium_img_available,
          [availableMapTypes, timezoneOffset]
        )
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    })
    .finally(() => {
      window.AccountHelper.terrainUrlsCache.delete(
        identifierPromise,
        location4326,
        country_iso2,
        state,
        premium_img_available
      )
    })

  return [availableMapTypes, timezoneOffset]
}

const getImageryToPreload = function (availableMapTypes, preloadTerrainProvider) {
  var availableMapTypes3D = availableMapTypes.filter(
    (m) =>
      m.map_type === 'Nearmap3D' ||
      m.map_type === 'Google3D' ||
      m.map_type === 'GetMapping3D' ||
      m.map_type === 'GetMappingPremium3D' ||
      m.map_type === 'Vexcel3D'
  )

  var selectedMapType3D

  if (availableMapTypes3D && availableMapTypes3D.length) {
    if (preloadTerrainProvider === 'nearmap') {
      selectedMapType3D = availableMapTypes3D.filter((m) => m.map_type === 'Nearmap3D')[0]
    } else if (preloadTerrainProvider === 'getmapping') {
      // Including both GetMapping3D and GetMappingPremium3D
      selectedMapType3D = availableMapTypes3D.filter(
        (m) => m.map_type === 'GetMapping3D' || m.map_type === 'GetMappingPremium3D'
      )[0]
    } else if (preloadTerrainProvider === 'vexcel') {
      selectedMapType3D = availableMapTypes3D.filter((m) => m.map_type === 'Vexcel3D')[0]
    } else {
      selectedMapType3D = availableMapTypes3D.filter((m) => m.map_type === 'Google3D')[0]
    }

    // if none found for preferred map type above, then just use first available 3d map type now
    if (!selectedMapType3D) {
      selectedMapType3D = availableMapTypes3D[0]
    }

    if (
      selectedMapType3D &&
      (selectedMapType3D.map_type === 'Google3D' ||
        selectedMapType3D.map_type === 'Nearmap3D' ||
        selectedMapType3D.map_type === 'GetMapping3D' ||
        selectedMapType3D.map_type === 'GetMappingPremium3D' ||
        selectedMapType3D.map_type === 'Vexcel3D')
    ) {
      // Only preload DSM & Ortho for Google, not Nearmap

      // Need to remove the querystring for google to avoid errors
      return {
        dsm: selectedMapType3D.variation_data.dsm,
        ortho: selectedMapType3D.variation_data.ortho,
      }
    }
  }
}

export function* handleDetectImagery({ payload }: DetectImagerySideEffect) {
  const {
    location4326,
    country_iso2,
    state,
    address,
    premiumImgIsAvailable,
    preloadTerrainProvider,
    is_lite,
    onComplete,
    enablePreload,
  } = payload || {}

  var identifier = 'cachedGetMapTypesAtLocationRequest'

  try {
    if (location4326[0] && location4326[1]) {
      yield put({
        type: DETECT_IMAGERY_LOADING,
        payload: {
          cacheKey: window.AccountHelper.terrainUrlsCache.key(
            identifier,
            location4326,
            country_iso2,
            state,
            premiumImgIsAvailable
          ),
          preloadTerrainProvider,
        },
      })

      const blockedMapTypes = yield select(imagerySelectors.getBlockedMapTypes)
      let default_imagery_strategy = yield select(imagerySelectors.getDefaultImageryStrategy)

      const response = yield call(
        getMapTypesAtLocation,
        location4326,
        country_iso2,
        state,
        address,
        is_lite,
        blockedMapTypes,
        default_imagery_strategy,
        enablePreload
      )

      var availableMapTypes, timezoneOffset

      if (!response) {
        throw new Error('No response from call!')
      }

      // @TODO: Why is the response format sometimes different? We handle both versions here
      // since we have not standardized them yet.
      if (response.payload) {
        availableMapTypes = response.payload.availableMapTypes
        timezoneOffset = response.payload.timezoneOffset
      } else {
        availableMapTypes = response[0]
        timezoneOffset = response[1]
      }

      if (!availableMapTypes || availableMapTypes.length === 0) {
        throw new Error('No imagery found')
      }

      storeAvailableImagery(availableMapTypes, timezoneOffset)

      yield put({
        type: DETECT_IMAGERY_SUCCESS,
        payload: {
          availableMapTypes: availableMapTypes,
          timezoneOffset: timezoneOffset,
        },
      })

      if (preloadTerrainProvider) {
        var preloadData = getImageryToPreload(availableMapTypes, preloadTerrainProvider)
        if (preloadData) {
          yield put({
            type: PRELOAD_3D,
            payload: preloadData,
          })
        }
      }
    }
    if (onComplete)
      onComplete(
        {
          availableMapTypes: availableMapTypes,
          timezoneOffset: timezoneOffset,
        },
        undefined
      )
  } catch (e) {
    console.error(e)
    yield put({ type: DETECT_IMAGERY_FAILURE, payload: { detail: String(e) } })
    if (onComplete) onComplete(undefined, e)
  }
}

export function* watchDetectImagery() {
  //@ts-ignore
  yield takeLatest(DETECT_IMAGERY, handleDetectImagery)
}
