import { useState } from 'react'
import TransactionActionButton from '../TransactionActionButton'
import { PrequaHelperMessage } from './PrequaHelperMessage'
import { SunlightApplicationLoadingDialog } from './SunlightApplicationLoadingDialog'

export const SunlightPrequalPrimaryAction = ({
  label,
  disabled,
  logoUrl,
  loadingMessage,
  onAction,
}: {
  label: string
  disabled: boolean
  logoUrl: string
  loadingMessage: React.ReactNode
  onAction(): Promise<void>
}) => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false)
  const handleClick = async () => {
    setShowLoadingDialog(true)
    await onAction()
    setShowLoadingDialog(false)
  }
  return (
    <>
      <div>
        <TransactionActionButton
          isLoading={showLoadingDialog}
          label={label}
          disabled={disabled}
          onClick={handleClick}
        />
        <PrequaHelperMessage />
      </div>
      <SunlightApplicationLoadingDialog show={showLoadingDialog} logoUrl={logoUrl} message={loadingMessage} />
    </>
  )
}
