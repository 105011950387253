export const PRODUCT_FILTERS = [
  'Association',
  'Batteries',
  'Battery Optimization',
  'Business Services',
  'Connect & Monitor',
  'Distribution',
  'EV Chargers',
  'Finance',
  'Heat Pump',
  'Imagery',
  'Inverters',
  'Lead Services',
  'Microinverters',
  'Modules',
  'Monitoring',
  'Optimization Software',
  'Other Components',
  'Permitting',
  'Power Storage',
  'Racking & Mounting Systems',
  'Retail Energy',
  'Service Plans',
  'Storage Solutions',
  'Tariffs',
]
export interface StandFilters {
  tags?: string
  search?: string
}
