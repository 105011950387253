import { Divider, Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { clearLineItems, orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import { isEmpty } from 'lodash'
import PrivacyLinks from 'pages/ordering/checkoutv3/preOrder/components/PrivacyLinks'
import { getOrderCost } from 'pages/ordering/utils'
import { useNotify } from 'ra-core'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import CheckoutPageLayout from '../../PageLayout'
import CheckoutTitle from '../CheckoutTitle'
import ReviewItems from '../ReviewItems'
import { useCheckoutStyles } from '../styles'
import CheckoutProvider, {
  CheckoutPresenterContext,
  ContactDetailContext,
  DeliveryDetailContext,
  PaymentMethodContext,
} from './CityPlumbingCheckoutProvider'
import ContactDetail from './ContactDetail'
import DeliveryMethod from './DeliveryMethod'
import { isValidAddress } from './helper'
import OrderSummary from './OrderSummary'
import PaymentMethod from './PaymentMethod'
import { ContactDetailType, DeliveryDetailType, PaymentMethodType } from './types'

type Props = {
  showChangeShippingAddress: boolean
  disablePlaceOrder: boolean
  totalCost: number
  setShowChangeShippingAddress: (value: boolean) => void
  handlePlaceOrder: () => void
}

const MainContent = memo(
  ({
    showChangeShippingAddress,
    disablePlaceOrder,
    totalCost,
    setShowChangeShippingAddress,
    handlePlaceOrder,
  }: Props) => {
    const checkoutClasses = useCheckoutStyles()
    const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
    return (
      <Grid container>
        <Grid item xs={12}>
          <Divider style={{ marginTop: '10px' }} />
        </Grid>
        <Grid item xs={12}>
          <ContactDetail />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DeliveryMethod
            showChangeShippingAddress={showChangeShippingAddress}
            setShowChangeShippingAddress={setShowChangeShippingAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <PaymentMethod />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ReviewItems itemNumber={4} />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" justify="space-between" style={{ padding: '20px 30px' }}>
            <Grid container className={checkoutClasses.placeOrderCard}>
              <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Button fullWidth color="primary" onClick={handlePlaceOrder} disabled={disablePlaceOrder}>
                  Place Order
                </Button>
              </Grid>
              <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column', paddingLeft: '40px' }}>
                <Typography variant="h6" className={checkoutClasses.fontWeightMedium}>
                  <span>Order total: </span>
                  <span className={checkoutClasses.validColor}>{`${currencySymbol} ${formatCurrency(totalCost)}`}</span>
                </Typography>
                <PrivacyLinks
                  distributor={'City Plumbing'}
                  policyLink={'https://www.highbournegroup.co.uk/privacy-policy/'}
                  conditionLink={'https://www.cityplumbing.co.uk/content/terms-and-conditions-of-sale'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)

const Checkout = () => {
  const notify = useNotify()
  const history = useHistory()
  const dispatch = useDispatch()
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const deliveryDetail: DeliveryDetailType = useContext(DeliveryDetailContext)
  const contactDetail: ContactDetailType = useContext(ContactDetailContext)
  const paymentMethod: PaymentMethodType = useContext(PaymentMethodContext)
  const [showChangeShippingAddress, setShowChangeShippingAddress] = useState<boolean>(false)
  const [disablePlaceOrder, setDisablePlaceOrder] = useState<boolean>(false)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
  const subTotalCost = getOrderCost(lineItems)
  const shippingFee: number = useMemo(
    () => (['TBC', 'FREE'].includes(deliveryDetail.fee) ? 0 : Number(deliveryDetail.fee)),
    [deliveryDetail.fee]
  )
  const tax: number = useMemo(() => subTotalCost * 0.2, [subTotalCost])
  const totalCost: number = useMemo(() => subTotalCost + shippingFee + tax, [subTotalCost, shippingFee, tax])

  useEffect(() => {
    setDisablePlaceOrder(
      !isValidAddress(deliveryDetail.contactAddress) ||
        isEmpty(contactDetail.businessName) ||
        isEmpty(contactDetail.email) ||
        isEmpty(contactDetail.mobileNumber) ||
        deliveryDetail.deliveryDate === '' ||
        (deliveryDetail.deliveryMethod === 'delivery' && isEmpty(deliveryDetail.deliveryTime)) ||
        (deliveryDetail.deliveryMethod === 'click_and_collect' && isEmpty(deliveryDetail.deliveryBranch?.name))
    )
  }, [contactDetail, deliveryDetail])

  const handlePlaceOrder = async () => {
    if (showChangeShippingAddress) {
      notify('Please save any changes to your shipping address to continue with your order.', 'warning')
      return
    }
    logAmplitudeEvent('hardware_place_order_cta_clicked', {
      distributor: 'city_plumbing',
      source: 'checkout',
      codes: lineItems.map((lineItem) => lineItem.code),
    })
    const orderId = await checkoutPresenter?.placeOrderViaEmail(
      contactDetail,
      deliveryDetail,
      paymentMethod,
      { tax, subTotalCost, totalCost, shippingFee },
      projectIds
    )

    if (orderId) {
      dispatch(clearLineItems())
      history.push(`/shop/order_confirmation/${orderId}`)
    }
  }

  return (
    <CheckoutPageLayout
      title={<CheckoutTitle />}
      mainContent={
        <MainContent
          showChangeShippingAddress={showChangeShippingAddress}
          disablePlaceOrder={disablePlaceOrder}
          totalCost={totalCost}
          setShowChangeShippingAddress={setShowChangeShippingAddress}
          handlePlaceOrder={handlePlaceOrder}
        />
      }
      sideAction={
        <OrderSummary
          cost={subTotalCost}
          totalBeforeTax={subTotalCost + shippingFee}
          tax={tax}
          totalCost={totalCost}
          handlePlaceOrder={handlePlaceOrder}
          disablePlaceOrder={disablePlaceOrder}
        />
      }
    />
  )
}

const CityPlumbingCheckoutV2 = () => (
  <CheckoutProvider>
    <Checkout />
  </CheckoutProvider>
)

export default memo(CityPlumbingCheckoutV2)
