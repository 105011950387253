import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { Button, styled } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'


const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const DivWrapper = styled('div')({
  marginLeft: '15px',
})

const ResetCashflowRegistration: React.FC<PropTypes> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const notify = useNotify()

  const resetCashflowRegistration = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/payments/reset_cashflow_registration/`,
    })
      .then((res) => {
        console.log('res', res)
        notify('Org Cashflow Registration successfully reseted!', 'success')
      })
      .catch((err) => {
        console.log('err')
        const errorMessage = err?.message || "Something went wrong. Please try again.";
        notify(errorMessage, 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff && !isSuperUser) return null

  return (
    <DivWrapper>
      <Button onClick={resetCashflowRegistration} variant="contained" size="small" disabled={isLoading}>
        {isLoading ? <CircularProgress /> : <span>Reset Cashflow Registration</span>}
      </Button>
    </DivWrapper>
  )
}

export default ResetCashflowRegistration
