import PrivacyLinks from 'pages/ordering/checkoutv3/preOrder/components/PrivacyLinks'
import { memo } from 'react'

const HdmDisclaimer = () => {
  return (
    <PrivacyLinks
      distributor={'HDM'}
      policyLink={'https://www.hdmsolar.co.uk/pages/privacy-policy'}
      conditionLink={'https://www.hdmsolar.co.uk/pages/terms-of-service'}
    />
  )
}

export default memo(HdmDisclaimer)
