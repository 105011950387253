import { Tooltip as Mui4Tooltip, TooltipProps as Mui4TooltipPropsType } from '@material-ui/core'
import { default as Mui5Tooltip, TooltipProps as Mui5TooltipPropsType } from '@mui/material/Tooltip'
import React, { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks/useComponentVersion'

export const Tooltip: FC<TooltipProps> = React.forwardRef((props, ref) => {
  return (
    <Inspector
      name="Tooltip"
      propDefs={TooltipPropDefs}
      showExample={true}
      component={OsTooltip}
      props={{ ...props, ref }}
    />
  )
})

export enum OsTooltipVersion {
  V1 = 1, // Base MUI
  V2 = 2, // MUI5 + Stratis
}

export type TooltipProps = Omit<Mui4TooltipPropsType, 'color'> & {
  color?: Mui5TooltipPropsType['color'] | Mui4TooltipPropsType['color']
}

const OsTooltip: FC<TooltipProps> = forwardRef((props, ref) => {
  let version = useComponentVersion('tooltip')

  switch (version) {
    case OsTooltipVersion.V2:
      let { onClose, PopperComponent, PopperProps, ...rest } = props
      const onCloseModified = (e) => {
        if (onClose) onClose(e)
      }
      const PopperProps5 = (PopperProps as unknown) as Mui5TooltipPropsType['PopperProps']
      return <Mui5Tooltip ref={ref} onClose={onCloseModified} PopperProps={PopperProps5} {...rest}></Mui5Tooltip>

    default:
      return <Mui4Tooltip ref={ref} {...props}></Mui4Tooltip>
  }
})

const TooltipPropDefs: PropsDefs = {}
