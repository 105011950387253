import ImageDisplayField from 'elements/field/ImageDisplayField'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { ImageInput, TextInput } from 'react-admin'
import { required } from 'validations'
import InputAccordion from '../common/InputAccordion'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled: boolean
  assetSource: string
  record: any
}

const CoverFields: FC<PropTypes> = ({ disabled, record, assetSource }) => {
  const translate = useTranslate()
  const source = useBlockSource('cover')
  if (!source) return null
  return (
    <InputAccordion title={translate('Cover Image')} source={source} assetSource={`${assetSource}.cover_background`}>
      <ImageDisplayField
        component={ImageInput}
        name={`${assetSource}.cover_background`}
        source={`${assetSource}.cover_background`}
        label="Background Image"
        defaultValue={null}
        accept="image/*"
        placeholder={<p>{translate('Drop your image here')}</p>}
        record={record}
        maxWidth={200}
        validate={required}
      />
      <TextInput
        label={'Cover Tagline'}
        name={`${source}.tagline`}
        source={`${source}.tagline`}
        disabled={disabled}
        validate={required}
      />
    </InputAccordion>
  )
}

export default CoverFields
