import { Grid } from '@material-ui/core'
import { CheckCircle, Settings } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { ComponentVersionsInherit, Link, LoadingDots, styled, Typography } from 'opensolar-ui'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import AccountingConnectionChip from './AccountingConnectionChip'
import AccountingIntegrationConnectionDialog from './AccountingIntegrationConnectionDialog'
import AccountingSettingsForm from './AccountingSettingsForm'
import DisconnectAccountingDialog from './DisconnectAccountingDialog'
import GetAccountingConnectionStatusButton from './GetAccountingConnectionStatusButton'
import GetAccountingRecordsButton from './GetAccountingRecordsButton'
import SyncAccountingButton from './SyncAccountingButton'
import { AccountingIntegration } from './types'
import {
  getAccountingIntegrations,
  useAccountingIntegrationsAreVisible,
  useVisibleAccountingIntegrations,
} from './utils'

type PropTypes = {
  onClose: () => void
}

const ContainerWrapper = styled('div')({
  overflow: 'hidden',
})

const JustifyCenter = styled(Grid)({
  justifyContent: 'center',
})

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '16px 24px',
  border: '1px solid #e7e7e7',
  borderRadius: '5px',
  margin: '1rem 0',
}))

const IntegrationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

const LoadingDotsWrapper = styled('div')({
  padding: '16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Wrapper = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  margin: '10px',
  marginLeft: '0',
  position: 'relative',
  width: '100px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  borderColor: '#ececec',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '5px',
  backgroundColor: '#ececec',
})

const HoveringIcon = styled('div')({
  position: 'absolute',
  left: '2px',
  top: '2px',
  cursor: 'pointer',
})

const Img = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

const SubHeaderWrapper = styled('div')({
  margin: '35px 0px 10px',
})

const TitleSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '20px',
})

const AccountingIntegrations: React.FC<PropTypes> = (props) => {
  const accountingVisible = useAccountingIntegrationsAreVisible()
  const visibleIntegrations = useVisibleAccountingIntegrations()

  const org = useSelector(orgSelectors.getOrg)
  const roleId = useSelector(authSelectors.getCurrentRole)?.id
  const [showIntegrationsDialog, setShowIntegrationsDialog] = useState<boolean>(false)
  const [accountingIntegrations, setAccountingIntegrations] = useState<AccountingIntegration[]>([])
  const [selectedAccountingIntegration, setSelectedAccountingIntegration] = useState<string>('')
  const [dialogContentUrl, setDialogContentUrl] = useState<string | null>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [fetchingIntegrations, setFetchingIntegrations] = useState<boolean>(false)
  const [integrationId, setIntegrationId] = useState<string | null>(null)
  const [accountingContactEmail, setAccountingContactEmail] = useState<string | null>(null)

  const dispatch = useDispatch()
  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    if (org?.id && roleId) {
      setFetchingIntegrations(true)
      getAccountingIntegrations(org?.id, roleId, dispatch, notify)
        .then(({ integrations, isConnected, connectedIntegrationName }) => {
          setAccountingIntegrations(integrations)
          setIsConnected(isConnected)

          if (connectedIntegrationName) {
            setSelectedAccountingIntegration(connectedIntegrationName)
          }
        })
        .catch((err) => {
          notify(translate('Error fetching accounting integrations'), 'error')
        })
        .finally(() => {
          setFetchingIntegrations(false)
        })
    }
  }, [org?.id, roleId])

  useEffect(() => {
    logAmplitudeEvent('cashflow_accounting_page_viewed', {})
  }, [])

  const refreshIntegrationState = async (integrationId: string, integrationName: string) => {
    try {
      const connected = await pollingIntegrationStatus(integrationId, 10, 3000)

      if (connected) {
        setAccountingIntegrations((prevIntegrations) => [
          ...prevIntegrations.map((integration) =>
            integration.id === integrationId && integration.name === integrationName
              ? { ...integration, enabled: true }
              : integration
          ),
        ])

        setIsConnected(true)
        setSelectedAccountingIntegration(integrationName)

        notify(translate('Accounting integration successfully connected'), 'success')
      } else {
        notify(translate('Connection was not established.'), 'warning')
      }
    } catch (error) {
      console.error('Error refreshing integration state: ', error)
      notify('Error refreshing integration state.', 'error')
    }
  }

  const pollingIntegrationStatus = async (
    integrationId: string,
    maxAttempts = 10,
    interval = 3000
  ): Promise<boolean> => {
    let attempts = 0

    while (attempts < maxAttempts) {
      attempts += 1

      try {
        const API_URL = window.API_ROOT + '/api'
        const restClientInstance = restClient(API_URL)
        const response = await restClientInstance('CUSTOM_GET', 'custom', {
          url: `orgs/${org?.id}/accounting/connections/${integrationId}/`,
        })

        if (response.data.status === 'connected') {
          logAmplitudeEvent('cashflow_accounting_connected', { integration: selectedAccountingIntegration })
          return true
        }
      } catch (error) {
        console.error(`Polling attempts ${attempts} failed: `, error)
      }
      await new Promise((resolve) => setTimeout(resolve, interval))
    }

    return false
  }

  const handleCloseDialog = async () => {
    setDialogContentUrl(null)

    try {
      if (integrationId) {
        await refreshIntegrationState(integrationId, selectedAccountingIntegration)
      }

      if (org?.id && roleId) {
        await getAccountingIntegrations(org.id, roleId, dispatch, notify).then((result) => {
          if (result) {
            const { integrations, isConnected, connectedIntegrationName } = result
            setAccountingIntegrations(integrations)
            setIsConnected(isConnected)

            if (connectedIntegrationName) {
              setSelectedAccountingIntegration(connectedIntegrationName)
            }
          }
        })
      }

      setShowIntegrationsDialog(false)
    } catch (error) {
      console.error('Error handling closedialog: ', error)
      notify(translate('Error closing dialog'), 'error')
    }
  }

  const connectIntegration = async (integration: string) => {
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + org?.id + '/accounting/connect_accounting/',
      data: {
        accounting_integration: integration,
      },
    })
      .then(async (response: any) => {
        setDialogContentUrl(response.data.connection_url)
        setIntegrationId(response.data.id)
        setSelectedAccountingIntegration(integration)
        logAmplitudeEvent('cashflow_accounting_connection_started', { integration })
      })
      .catch((error) => {
        console.error(`Error connecting to account integration ${integration}`, error)
        notify(error?.body?.message || translate('Error connecting to accounting integration'), 'error')
        logAmplitudeEvent('cashflow_accounting_connection_start_error', { integration })
      })
  }

  const connectedAccountName = useMemo(() => {
    if (accountingIntegrations?.length) {
      return accountingIntegrations.find((accInt) => accInt.enabled)?.provider_account_name
    }
    return null
  }, [accountingIntegrations])

  if (!accountingVisible) return null
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <ContainerWrapper>
        <JustifyCenter container spacing={2}>
          <Grid item xs={11} lg={8}>
            <Container>
              <TitleSection>
                <Typography textVariant="h3">{translate('Accounting Integrations')}</Typography>
                <AccountingConnectionChip accountingIntegrations={accountingIntegrations} />
              </TitleSection>
              <Typography textVariant="body1">
                {translate(
                  'OpenSolar allows you to integrate your accounting software so that customers and invoices are automatically synced between the two platforms.'
                )}{' '}
                <Link href="">{translate('Learn more')}</Link>
              </Typography>
              <SubHeaderWrapper>
                <Typography textVariant="h4">{translate('Integrations')}</Typography>
                {connectedAccountName && (
                  <Typography textVariant="body1">
                    {translate('You are connected to the following account')}: <strong>{connectedAccountName}</strong>
                  </Typography>
                )}
              </SubHeaderWrapper>

              <IntegrationWrapper>
                {fetchingIntegrations && (
                  <LoadingDotsWrapper>
                    <LoadingDots text={translate('Fetching integrations...')} color="primary" />
                  </LoadingDotsWrapper>
                )}
                {accountingIntegrations?.map((accInt) => {
                  if (!visibleIntegrations.includes(accInt.value)) return null
                  return (
                    <Wrapper
                      key={accInt.name}
                      onClick={() => {
                        if (!accInt.enabled && !isConnected) {
                          setShowIntegrationsDialog(true)
                          setSelectedAccountingIntegration(accInt.name)
                          connectIntegration(accInt.value)
                        }
                      }}
                      style={{
                        cursor: accInt.enabled || isConnected ? 'not-allowed' : 'pointer',
                        backgroundColor: isConnected && !accInt.enabled ? undefined : '#fff',
                      }}
                    >
                      <HoveringIcon>
                        {accInt.enabled ? (
                          <>
                            <CheckCircle htmlColor="rgba(55, 169, 46, 0.9)" fontSize="small" />
                          </>
                        ) : (
                          <Settings fontSize="small" htmlColor="rgb(117, 117, 117)" />
                        )}
                      </HoveringIcon>
                      <Img>
                        <img
                          src={
                            accInt.value === 'quickbooks'
                              ? '/images/Quickbooks-Logo.svg'
                              : '/images/Xero_software_logo.svg'
                          }
                          width={accInt.value === 'quickbooks' ? '100%' : '45%'}
                          alt={accInt.name}
                        />
                      </Img>
                    </Wrapper>
                  )
                })}
              </IntegrationWrapper>

              {isConnected && accountingIntegrations.some((accInt) => accInt.enabled) && (
                <>
                  <AccountingSettingsForm
                    accountingIntegrations={accountingIntegrations}
                    selectedAccountingIntegration={selectedAccountingIntegration}
                    accountingContactEmail={accountingContactEmail}
                    setAccountingContactEmail={setAccountingContactEmail}
                    setAccountingIntegrations={setAccountingIntegrations}
                    setIsConnected={setIsConnected}
                    setSelectedAccountingIntegration={setSelectedAccountingIntegration}
                  />
                </>
              )}

              {accountingIntegrations?.some((accInt) => accInt.enabled) && (
                <>
                  <SyncAccountingButton accountingIntegrations={accountingIntegrations} />
                  <DisconnectAccountingDialog
                    accountingIntegrations={accountingIntegrations}
                    integrationId={integrationId}
                    setIsConnected={setIsConnected}
                    setAccountingIntegrations={setAccountingIntegrations}
                    setDialogContentUrl={setDialogContentUrl}
                    getAccountingIntegrations={() => {
                      if (org?.id && roleId) {
                        getAccountingIntegrations(org.id, roleId, dispatch, notify).then((result) => {
                          if (result) {
                            const { integrations, isConnected, connectedIntegrationName } = result
                            setAccountingIntegrations(integrations)
                            setIsConnected(isConnected)

                            if (connectedIntegrationName) {
                              setSelectedAccountingIntegration(connectedIntegrationName)
                            }
                          }
                        })
                      }
                    }}
                  />
                  <GetAccountingConnectionStatusButton />
                  <GetAccountingRecordsButton />
                </>
              )}

              <AccountingIntegrationConnectionDialog
                selectedAccountingIntegration={selectedAccountingIntegration}
                isOpen={showIntegrationsDialog}
                dialogContentUrl={dialogContentUrl}
                handleCloseDialog={handleCloseDialog}
              />
            </Container>
          </Grid>
          <Grid item xs={11} lg={3}>
            <CashFlowSetupChecklist activeStep={5} />
          </Grid>
        </JustifyCenter>
      </ContainerWrapper>
    </ComponentVersionsInherit>
  )
}

export default AccountingIntegrations
