import { MenuItem } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { Struct } from 'contexts/structs/types/types'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { UiSwitch } from 'elements/UiSwitch'
import { useTranslatableText } from 'hooks/translation/useTranslatableText'
import { useTranslateOrTranslatableText } from 'hooks/translation/useTranslateOrTranslatableText'
import {
  ComponentVersionsInherit,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
  styled,
  Switch,
  Typography,
} from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useMemo, useState } from 'react'
import {
  FeatureFlagOptState,
  FeatureFlagStateMap,
  FeatureFlagType,
  getFeatureFlagUserActionKey,
  useFeatureFlagStructsWithState,
} from 'util/featureFlags'

type Props = {
  className?: string
  uiKey: string
  onClick?: () => void
}

export const FeatureSettingsButtonAndDialog = ({ className, uiKey, onClick }: Props) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const { states } = useFeatureFlagStructsWithState()
  const translatableText = useTranslatableText()

  const sortedOptional = useMemo(() => {
    const optional = (states.enabled_user_optout || []).concat(states.disabled_user_optin || [])
    return (
      optional.sort((a, b) =>
        translatableText(a.data.description).localeCompare(translatableText(b.data.description))
      ) || []
    )
  }, [states])

  const handleClick = () => {
    setOpen(true)
    if (onClick) onClick()
  }

  return (
    <>
      {!!sortedOptional.length && (
        <UiSwitch uiKey={uiKey}>
          <MenuItem className={className} onClick={handleClick}>
            {translate('Beta Features')}
          </MenuItem>
          <FeaturesDialog open={open} onClose={() => setOpen(false)} states={states} sortedOptional={sortedOptional} />
        </UiSwitch>
      )}
    </>
  )
}

const FeaturesDialog = ({
  open,
  onClose,
  states,
  sortedOptional,
}: {
  open: boolean
  onClose: () => void
  states: FeatureFlagStateMap
  sortedOptional: Struct<FeatureFlagType>[]
}) => {
  const translate = useTranslate()
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <span>{translate('Beta Features')}</span>
          <DialogCloseButton onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <table>
            {sortedOptional.map((struct) => {
              return <FeatureFlagRow key={struct.key} struct={struct} state={getState(states, struct)} />
            })}
          </table>
        </DialogContent>
      </Dialog>
    </ComponentVersionsInherit>
  )
}
const getState = (states: FeatureFlagStateMap, struct: Struct<FeatureFlagType>): FeatureFlagOptState => {
  if (states.enabled_user_optout?.includes(struct)) return 'enabled_user_optout'
  if (states.disabled_user_optin?.includes(struct)) return 'disabled_user_optin'
  throw Error('Something went wrong, unknown feature flag state')
}

const TableCell = styled('td')({
  padding: 20,
})

const FeatureFlagRow = ({ struct, state }: { struct: Struct<FeatureFlagType>; state: FeatureFlagOptState }) => {
  const userActions = useUserActionsContext()
  const actionName = getFeatureFlagUserActionKey(state, struct.key)
  const translate = useTranslateOrTranslatableText()

  const didOptInOut = useMemo(() => {
    return userActions.checkAction(actionName)
  }, [state, userActions.actions])

  const checked = useMemo(() => {
    const default_value = state === 'enabled_user_optout'
    return didOptInOut ? !default_value : default_value
  }, [state, didOptInOut])

  const onChange = () => {
    if (didOptInOut) userActions.deleteAction(actionName)
    else userActions.recordAction(actionName)
  }

  return (
    <tr>
      <TableCell>
        <Typography variant="h3">{translate(struct.data.description)}</Typography>
      </TableCell>
      <TableCell>
        <Switch disabled={!userActions.loaded} onChange={onChange} checked={checked} />
      </TableCell>
    </tr>
  )
}
