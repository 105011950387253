import { FC } from 'react'
import type { IconProps } from './type'

interface ContractIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const ContractIcon: FC<ContractIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M3 20C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V17C18 17.8333 17.7083 18.5417 17.125 19.125C16.5417 19.7083 15.8333 20 15 20H3ZM15 18C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17V2H5V14H14V17C14 17.2833 14.0958 17.5208 14.2875 17.7125C14.4792 17.9042 14.7167 18 15 18ZM6 7V5H15V7H6ZM6 10V8H15V10H6ZM3 18H12V16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18ZM3 18H2H12H3Z"
      />
    </svg>
  )
}
