import { styled } from 'opensolar-ui'
import { FC, useEffect, useMemo, useState } from 'react'

interface PropTypes {
  endDateTime: string
}

const CountdownWrapper = styled('div', { name: 'CountdownWrapper' })({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  gap: 50,
  flex: 1,
})

const Section = styled('div', { name: 'Section' })({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',

  '& p': {
    fontSize: 60,
    margin: 0,
    marginBottom: 25,
  },
})

const Countdown: FC<PropTypes> = ({ endDateTime }) => {
  const [timeNow, setTimeNow] = useState(new Date().getTime())
  const { hours, minutes, seconds } = useMemo(() => {
    const countDownDate = new Date(endDateTime).getTime()
    let timespan = countDownDate - timeNow

    let retValues = {
      hours: Math.floor((timespan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timespan % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timespan % (1000 * 60)) / 1000),
    }
    Object.keys(retValues).forEach((x) => {
      if (retValues[x] <= 0) retValues[x] = 0
    })
    return retValues
  }, [endDateTime, timeNow])

  useEffect(() => {
    const interval = setInterval(() => setTimeNow(new Date().getTime()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])
  if (!endDateTime) return null
  return (
    <CountdownWrapper>
      <Section>
        <p>{hours}</p>
        <span>HOURS</span>
      </Section>
      <Section>
        <p>{minutes}</p>
        <span>MINUTES</span>
      </Section>
      <Section>
        <p>{seconds}</p>
        <span>SECONDS</span>
      </Section>
    </CountdownWrapper>
  )
}

export default Countdown
