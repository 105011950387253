import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ProposalTemplateTypeEnum } from 'resources/proposalTemplates/types'
import type { ProposalTemplateType } from 'types/proposalTemplate'
import ClassicTemplateCard from './ClassicTemplateCard'
import DefaultTemplateCard from './DefaultTemplateCard'

export enum TemplateLibraryTypeEnum {
  ProposalV2 = 'proposalV2',
  Classic = 'classic',
}

const useTemplateLibraryOptions = ({ proposalTemplates }: { proposalTemplates: ProposalTemplateType[] }) => {
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)

  const defaultProposalV2 = proposalTemplates.find((template) => template.type === ProposalTemplateTypeEnum.proposalV2)
  const defaultClassic = proposalTemplates.find((template) => template.is_default)

  const TEMPLATE_SECTIONS = useMemo(
    () => [
      {
        key: TemplateLibraryTypeEnum.ProposalV2,
        label: translate('%{orgName} Template', { orgName: org?.name }),
        templateCard: DefaultTemplateCard,
        value: defaultProposalV2,
      },
      {
        key: TemplateLibraryTypeEnum.Classic,
        label: translate('Classic Template'),
        templateCard: ClassicTemplateCard,
        value: defaultClassic,
      },
    ],
    [defaultProposalV2, defaultClassic]
  )
  return TEMPLATE_SECTIONS
}

export default useTemplateLibraryOptions
