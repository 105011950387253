import { TextField as Mui4TextField, TextFieldProps as Mui4TextFieldPropsType } from '@material-ui/core'
import { default as Mui5TextField, TextFieldProps as Mui5TextFieldPropsType } from '@mui/material/TextField'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { ChevronDownOutlineIcon } from '../icons'
import FieldTitle from './FieldTitle'

// * MUI TextField API Spec  https://mui.com/material-ui/api/text-field/
export const TextField: FC<TextFieldProps> = (props) => (
  <Inspector name="TextField" propDefs={TextFieldPropsDefs} showExample={false} component={OsTextField} props={props} />
)

export enum OsTextFieldVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type TextFieldProps = Omit<Mui4TextFieldPropsType, 'variant' | 'color'> & {
  variant?: Mui5TextFieldPropsType['variant'] | Mui4TextFieldPropsType['variant']
  color?: Mui5TextFieldPropsType['color'] | Mui4TextFieldPropsType['color']
}
const V2_TEXT_COLORS = ['primary', 'secondary']
const OsTextField: FC<TextFieldProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('text_field')

  switch (version) {
    case OsTextFieldVersion.V2:
      const { InputProps, SelectProps, label, color, ...rest } = props
      const InputProps5: Mui5TextFieldPropsType['InputProps'] = InputProps as Mui5TextFieldPropsType['InputProps']
      const SelectProps5: Mui5TextFieldPropsType['SelectProps'] = {
        IconComponent: ChevronDownOutlineIcon,
        ...SelectProps,
      } as Mui5TextFieldPropsType['SelectProps']
      return (
        <Mui5TextField
          ref={ref}
          InputProps={InputProps5}
          SelectProps={SelectProps5}
          color={color || 'secondary'}
          InputLabelProps={{
            //set by default to apply OS Stratis UI styling
            shrink: true,
          }}
          label={
            label === false ? undefined : typeof label === 'string' ? (
              <FieldTitle label={label} required={rest.required} />
            ) : (
              label
            )
          }
          {...rest}
        />
      )

    default:
      const mui4Props: Mui4TextFieldPropsType = {
        ...props,
        variant: props.variant as Mui4TextFieldPropsType['variant'],
        color: color && V2_TEXT_COLORS.includes(color) ? (color as Mui4TextFieldPropsType['color']) : 'secondary',
      }
      return <Mui4TextField ref={ref} {...mui4Props} />
  }
})

const TextFieldPropsDefs: PropsDefs = {}
