import { TableHead as Mui4TableHead, TableHeadProps as Mui4TableHeadProps } from '@material-ui/core'
import { default as Mui5TableHead } from '@mui/material/TableHead'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsTableVersion } from './Table'

export const TableHead: FC<OsTableHeadProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="TableHead"
      propDefs={TableHeadProps}
      showExample={true}
      component={OsTableHead}
      props={{ ...props, ref }}
    />
  )
})

export type OsTableHeadProps = Mui4TableHeadProps

const OsTableHead: FC<OsTableHeadProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('table')
  switch (version) {
    case OsTableVersion.V2:
      return <Mui5TableHead ref={ref} {...props} />

    default:
      return <Mui4TableHead ref={ref} {...props} />
  }
})

const TableHeadProps: PropsDefs = {}
