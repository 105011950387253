import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { Box } from 'opensolar-ui'
import { getSupplierConfig, getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { ComponentCost } from 'projectSections/hooks/useComponentCost'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ComponentTypesV2 } from 'types/selectComponent'
import { currencySymbolForCountry, formatDecimalPlaces, mapComponentTypesV2ToV1 } from 'util/misc'
import { DistributorTradePricingContext, DistributorTradePricingContextType } from '../HardwareSelectorCore'
import { DistributorDataTypeV2, HardwareComponentDataType } from '../types'
import EmptyColumnItem from './EmptyColumnItem'
import useCommonStyles from './useCommonStyles'
import { getComponentRating } from './utility'

interface PriceBlockInterface {
  code: string
  combinedRating?: number
  componentData?: HardwareComponentDataType
  componentType: ComponentTypesV2
  distributors: DistributorDataTypeV2[]
  costSource?: ComponentCost | null
}

const PriceBlock: React.FC<PriceBlockInterface> = ({
  code,
  combinedRating,
  componentData,
  componentType,
  distributors,
  costSource,
}) => {
  const commonClasses = useCommonStyles()

  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const orgId = useSelector(authSelectors.getOrgId)
  const distributorTradePricing = useContext<DistributorTradePricingContextType>(DistributorTradePricingContext)
    .distributorTradePricing
  const activationData = window.AccountHelper.getComponentActivationFromCode(
    code,
    mapComponentTypesV2ToV1(componentType)
  )

  const prices = useMemo(() => {
    if (costSource) return [costSource.price || 0]

    return distributors.reduce((lineItems, { distributor, price }) => {
      const supplierEnum = getSupplierEnumByFilterKey(distributor)
      const supplierConfig = getSupplierConfig(supplierEnum)
      if (!supplierEnum || !enabledDistributors.includes(supplierEnum) || !supplierConfig) return lineItems

      // Only include a Segen price if the user has connected their Segen account
      if (distributor === 'segen' && !distributorTradePricing['segen']) return lineItems

      return !lineItems.includes(price) ? [...lineItems, price].sort((a, b) => a - b) : lineItems
    }, [] as number[])
  }, [distributors, enabledDistributors, orgId, costSource])

  const pricePerWatt = useMemo(() => {
    if ((componentType !== 'module' && !combinedRating) || prices.length === 0) return null

    const componentRating = combinedRating
      ? { suffix: 'kW', value: combinedRating }
      : getComponentRating(componentType, componentData)
    if (componentRating === '-' || typeof componentRating.value !== 'number') return null

    const { value: rating, suffix } = componentRating
    const pricePerWatts = prices.map((price) => formatDecimalPlaces(price / rating, 2))
    let unit = countryIso2 === 'AU' ? `/${suffix}` : `p/${suffix}p`
    if (combinedRating) unit.replaceAll('W', 'kW')

    return (
      <span className={commonClasses.lightText}>
        {`${currencySymbol}${pricePerWatts[0]}${
          pricePerWatts.length > 1 ? `-${pricePerWatts[pricePerWatts.length - 1]}` : ''
        }${unit}`}
      </span>
    )
  }, [componentData, componentType, prices])

  if (prices.length === 0) return <EmptyColumnItem />

  return (
    <Box className={commonClasses.textsContainer} display="flex" flexDirection="column">
      {prices.length > 0 && (
        <span className={commonClasses.mainText}>
          {`${currencySymbol}${formatDecimalPlaces(prices[0], 2)}${
            prices.length > 1 ? `-${prices[prices.length - 1]}` : ''
          }`}
        </span>
      )}
      {pricePerWatt}
    </Box>
  )
}

export default PriceBlock
