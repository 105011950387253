import { useForm } from 'react-final-form'

const getProjectIdSpecial = () => (window.location.hash.includes('/replay/') ? 'replay' : 'new')

export type ProjectId = number | 'new' | 'replay'

export const useSpecialProjectId = () => {
  const formState = useForm().getState()
  return formState.values.id || getProjectIdSpecial()
}
