import { Action } from '../core/action'
import { CreateOrUpdateProjectFlow, GenerateDocumentFlow } from '../types'

/**
 * 'Flows' are sequences of actions designed to cover
 * common SDK use cases that would otherwise require
 * multiple actions to be dispatched in sequence.
 */
export const flows = {
  // Actions
  createOrUpdateProject: new Action<[CreateOrUpdateProjectFlow], boolean>(),
  generateDocument: new Action<[GenerateDocumentFlow], boolean>(),
} as const
