// @ts-nocheck
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { FunctionComponent } from 'react'
import pure from 'recompose/pure'

import sanitizeRestProps from './sanitizeRestProps'
import { FieldProps, fieldPropTypes, InjectedFieldProps } from './types'

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString('i')
  } catch (error) {
    return error instanceof RangeError
  }
  return false
})()

interface Props extends FieldProps {
  locales?: string | string[]
  options?: object
  showTime?: boolean
}

/**
 * Display a date value as a locale string.
 *
 * Uses Intl.DateTimeFormat() if available, passing the locales and options props as arguments.
 * If Intl is not available, it outputs date as is (and ignores the locales and options props).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * @example
 * <DateField source="published_at" />
 * // renders the record { id: 1234, published_at: new Date('2012-11-07') } as
 * <span>07/11/2012</span>
 *
 * <DateField source="published_at" className="red" />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span class="red">07/11/2012</span>
 *
 * <DateField source="share" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>Wednesday, November 7, 2012</span>
 *
 * <DateField source="price" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>mercredi 7 novembre 2012</span>
 */

export const DateField: FunctionComponent<Props & InjectedFieldProps & TypographyProps> = ({
  className,
  emptyText,
  locales,
  options,
  record,
  showTime = false,
  source,
  ...rest
}) => {
  if (!record) {
    return null
  }
  const value = get(record, source)
  if (value == null) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : null
  }

  const date = value instanceof Date ? value : new Date(value)
  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
    ? date.toLocaleDateString(locales, options)
    : date.toLocaleDateString()

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
      {dateString}
    </Typography>
  )
}

const EnhancedDateField = pure<Props>(DateField)

EnhancedDateField.defaultProps = {
  addLabel: true,
}

EnhancedDateField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
  locales: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.object,
  showTime: PropTypes.bool,
}

EnhancedDateField.displayName = 'EnhancedDateField'

export default EnhancedDateField
