import React from 'react'
import LoadingDots from './LoadingDots'

type PropTypes = {
  text?: string
  styles?: React.CSSProperties
}
const FullScreenLoading: React.FC<PropTypes> = ({ text, styles = {} }) => {
  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...styles,
      }}
    >
      <LoadingDots text={text} />
    </div>
  )
}
export default FullScreenLoading
