import { defaultValueFunc } from 'resources/projects/UsageComponents'
import { parseNaturalGasValues } from './NaturalGas/utils'
import { EnergyTypeEnum, OtherEnergyItemType } from './types'

// re-use proux1 defaultValue Func as much as possible.

/**
 * Extracts and parses the natural gas entry from other_energy_types.
 * Filters out any null values and returns a valid parsed natural gas object.
 */
const getParsedNaturalGasEntry = (otherEnergyTypes: OtherEnergyItemType[] | undefined) => {
  const energyArray = Array.isArray(otherEnergyTypes)
    ? (otherEnergyTypes.filter((item) => item !== null) as OtherEnergyItemType[])
    : []

  const naturalGasData = energyArray.find((energy) => energy.energy_type === EnergyTypeEnum.NATURAL_GAS)

  // Ensure parseNaturalGasValues always receives a valid object.
  const naturalGasEntry = naturalGasData || { energy_type: EnergyTypeEnum.NATURAL_GAS }
  return parseNaturalGasValues(naturalGasEntry)
}

/**
 * Merges and updates usage form values based on parsed usage data.
 */
const parseUsageInitialValues = (formValsOnSave: any, returnedVals: any = {}) => {
  let parsedUsageData =
    returnedVals?.usage?.length > 0 ? defaultValueFunc(returnedVals) : defaultValueFunc(formValsOnSave)

  if (parsedUsageData) {
    for (const [key, value] of Object.entries(parsedUsageData)) {
      //ignore is_residential and country_iso2 that parsed from defaultValueFunc
      //To Do: refactor defaultValueFunc after completely transfer to UX2.0
      //untangle is_residential and country_iso2
      if (key !== 'is_residential' && key !== 'country_iso2') {
        formValsOnSave[key] = value
      }
      if (key === 'other_energy_types') {
        formValsOnSave['natural_gas'] = getParsedNaturalGasEntry(value)
      }
    }
  }
  return formValsOnSave
}

export default parseUsageInitialValues
