import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { lazy, Suspense } from 'react'

const UILibrary = lazy(() => import('./UILibrary'))

const LazyUILibrary = () => (
  <Suspense fallback={<FullScreenLoading />}>
    <UILibrary />
  </Suspense>
)

export default LazyUILibrary
