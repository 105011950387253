// @ts-nocheck
import { Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDataProvider, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { ContactButtonsHome } from 'resources/projects/projectsList/ContactButtonsHome'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {},
  tableWrapper: {
    maxHeight: 300,
    overflowY: 'scroll',
    marginTop: 20,
  },
  table: {
    tableLayout: 'fixed',
    marginTop: 10,
    '& th': {
      padding: 4,
      borderWidth: 2,
      borderColor: '#e7e7e7',
    },
    '& td': {
      padding: 4,
      borderWidth: 2,
      borderColor: '#e7e7e7',
    },
    '& thead': {
      borderTop: theme.border.default,
    },
    '& thead tr': {
      background: '#F8F8F8',
    },
    '& thead th': {
      padding: '16px 4px',
    },
    '& th:nth-child(4)': {
      width: 80,
      // [theme.breakpoints.down('xs')]: { width: '30%' },
    },
    '& th:nth-child(5)': {
      width: 100,
      // [theme.breakpoints.down('xs')]: { width: '30%' },
    },
  },
  list: {},
}))

const getDateTime = (record: any) => {
  let date = null
  if (record.completion_date) {
    // date_label = 'Completed'
    date = new Date(record.completion_date.replace(/ /gi, 'T'))
  } else if (record.start) {
    // date_label = 'Scheduled'
    date = new Date(record.start.replace(/ /gi, 'T'))
  } else {
    // date_label = null
    date = null
  }
  return date ? date.toLocaleString() : null
}

const DashboardClientActivities = () => {
  const translate = useTranslate()
  const [activities, setActivities] = useState()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const dataProvider = useDataProvider()
  const classes = useStyles()
  useEffect(() => {
    const onSuccess = ({ data }: any) => {
      return setActivities(data.customer_activity)
    }
    const onFailed = (error) => {
      setActivities([])
    }
    dataProvider
      .CUSTOM_GET(
        'events',
        {
          url: 'home/activity/',
        },
        { onSuccess }
      )
      .catch(onFailed)
  }, [])
  return (
    <div>
      <h1>{translate('Client Activities')}</h1>
      <div className={classes.tableWrapper}>
        {!activities ? (
          <Skeleton height="300px" />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translate('Project')}</TableCell>
                <TableCell align="left">{translate('Activity')}</TableCell>
                <TableCell align="left">{translate('Time')}</TableCell>
                {!isMobile && <TableCell align="left">{translate('Client')}</TableCell>}
                {!isMobile && <TableCell align="left"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map((record: any) => (
                <TableRow key={record.id}>
                  <TableCell component="th" scope="row">
                    {record.project_id && <Link to={`/projects/${record.project_id}/info`}>{record.project_name}</Link>}
                  </TableCell>
                  <TableCell align="left">
                    {record.event_type_id !== 0 && record.notes && record.notes.length ? record.notes : record.title}
                  </TableCell>
                  <TableCell align="left">{getDateTime(record)}</TableCell>
                  {!isMobile && <TableCell align="left">{record?.who?.display}</TableCell>}
                  {!isMobile && <TableCell align="left">{<ContactButtonsHome contact={record.who} />}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default DashboardClientActivities
