import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import lodash from 'lodash'
import { BaseTheme, Button, CloseOutlineIcon, IconButton, PlusIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ActionType, EventType } from 'types/tracking'
import { FilterField } from './fields'
import FilterDisplay from './FilterDisplay'

const Filters = styled('div', { name: 'Filters' })(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.grey[50],
  padding: 12,
  '& div:first-child': {
    marginTop: 0,
  },
}))

const FilterWrapper = styled('div', { name: 'FilterWrapper' })({
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
})

const StyledIconBtn = styled(IconButton, { name: 'StyledIconBtn' })({
  marginLeft: 'auto',
})

const AddFilterBtn = styled(Button, { name: 'AddFilterBtn' })({
  marginTop: 10,
})

const ColumnWrapper = styled('div', { name: 'ColumnWrapper' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid ' + theme.palette.secondary.light,
  padding: '15px 0',
  margin: '15px 0',
}))

const filterInitialState = {
  fieldId: undefined,
  value: {},
}

interface FilterSectionProps {
  isTableView?: boolean;
  [key: string]: any;
}

const FilterSection: React.FC<FilterSectionProps> = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const filtersList = useMemo(() => [...formValues.filters], [formValues.filters]);
  const activeFilters = useMemo(() => filtersList.map((filter) => filter.fieldId), [filtersList])
  const { trackEvent } = useTrackComponent({
    componentKey: 'filter_section',
    eventName: 'User Sorted and Filtered Projects',
  })

  return (
    <ColumnWrapper>
      {!!filtersList?.length && (
        <Filters>
          {filtersList.map((filter, i) => (
            <FilterWrapper key={`filter-${filter.fieldId}`}>
              <FilterDisplay source={`${props.source}.${i}`} filter={filter} activeFilters={activeFilters} />
              {!(!props.isTableView && filter.fieldId === 'workflow_id') && ( //hide delete button for workflow filter when on kanban view
                <StyledIconBtn
                  onClick={() => {
                    const updatedList = filtersList.filter((x) => x.fieldId !== filter.fieldId)
                    trackEvent(
                      EventType.USER_INTERACTION,
                      { type: ActionType.CLICK },
                      { selectedOption: filter, filterSource: 'manual' }
                    )
                    form.change('filters', updatedList);
                  }}
                >
                  <CloseOutlineIcon size={12} color={BaseTheme.palette.red[800]} />
                </StyledIconBtn>
              )}
            </FilterWrapper>
          ))}
        </Filters>
      )}
      <div>
        <AddFilterBtn
          startIcon={<PlusIcon width="12" height="12" />}
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            form.change('filters', [...filtersList, lodash.cloneDeep(filterInitialState)])
          }}
        >
          {translate('Add Filter')}
        </AddFilterBtn>
      </div>
    </ColumnWrapper>
  )
}

export default FilterSection
