import { Select as Mui4Select, SelectProps as Mui4SelectProps } from '@material-ui/core'
import { default as Mui5Select, SelectProps as Mui5SelectProps } from '@mui/material/Select'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { ChevronDownOutlineIcon } from '../icons'

export const Select: FC<SelectProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="Select"
      propDefs={SelectPropDefs}
      showExample={false}
      component={OsSelect}
      props={{ ...props, ref }}
    />
  )
})

export enum OsSelectVersion {
  V1 = 1, // MUI4
  V2 = 2, // MUI5 + Stratis UI
}
export type SelectProps = Mui4SelectProps

const OsSelect: FC<SelectProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('select')
  switch (version) {
    case OsSelectVersion.V2:
      const { onInvalid, onChange, MenuProps, ...rest } = props
      const onInvalid5: Mui5SelectProps['onInvalid'] = onInvalid as Mui5SelectProps['onInvalid']
      const onChange5: Mui5SelectProps['onChange'] = onChange as Mui5SelectProps['onChange']
      const MenuProps5: Mui5SelectProps['MenuProps'] = MenuProps as Mui5SelectProps['MenuProps']
      return (
        <Mui5Select
          ref={ref}
          IconComponent={ChevronDownOutlineIcon}
          onInvalid={onInvalid5}
          onChange={onChange5}
          MenuProps={MenuProps5}
          {...rest}
        />
      )

    default:
      return <Mui4Select ref={ref} {...props} />
  }
})

const SelectPropDefs: PropsDefs = {}
