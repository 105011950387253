// @ts-nocheck
import { makeStyles, useMediaQuery } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitationOutlined'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import _ from 'lodash'
import { CRUD_GET_LIST, useGetList, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getRoleId } from 'util/misc'
import Button from '../../elements/button/Button'
import AssignedActivitiesTable from './AssignedActivitiesTable'
import AssignedActivitiesTableSmall from './AssignedActivitiesTableSmall'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  sizeSmall: {
    marginRight: 10,
    marginBottom: 10,
  },
  last: {
    marginBottom: 10,
  },

  // startIcon: {
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none',
  //   },
  // },
  // textButton: {
  //   textTransform: 'none',
  //   color: theme.blue,
  //   margin: 2,
  //   '&:hover': {
  //     color: 'white',
  //     background: theme.blue,
  //   },
  // },
})

const ListTitle = () => {
  const history = useHistory()
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div>
        <Button
          // classes={{ root: classes.textButton, startIcon: classes.startIcon }}
          classes={{ sizeSmall: classes.sizeSmall }}
          variant="outlined"
          size="small"
          startIcon={<VisibilityIcon />}
          onClick={() =>
            history.push(
              '/events?filter=%7B%22team_members%22%3A' +
                getRoleId() +
                '%2C%22task_status%22%3A1%7D&order=DESC&page=1&perPage=20&sort=modified_date'
            )
          }
        >
          <span>{translate('View all')}</span>
        </Button>
        <Button
          // classes={{ root: classes.textButton, startIcon: classes.startIcon }}
          classes={{ sizeSmall: classes.sizeSmall }}
          variant="outlined"
          size="small"
          startIcon={<InsertInvitationIcon />}
          onClick={() => history.push('/calendar')}
        >
          <span>{translate('Calendar')}</span>
        </Button>
      </div>
      <div>
        <Button
          // classes={{ startIcon: classes.startIcon }}
          classes={{ sizeSmall: classes.last }}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => history.push('/events/create')}
        >
          <span>{translate('Create Activity')}</span>
        </Button>
      </div>
    </div>
  )
}

const DashboardAssignedActivities = () => {
  const translate = useTranslate()
  const { ids, total, loading, loaded } = useGetList(
    'events',
    {
      //only show 5 items
      page: 1,
      perPage: 5,
    },
    { field: 'modified_date', order: 'DESC' },
    { team_members: getRoleId() },
    {
      action: CRUD_GET_LIST,
    }
  )
  const data = useSelector((state: any) => _.get(state.admin.resources, ['events', 'data']))
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const records = _.pick(data, ids)
  return (
    <div>
      <h1>{translate('Your Assigned Activities')}</h1>
      <ListTitle />
      {isMobile ? (
        <AssignedActivitiesTableSmall records={records} loading={loading} />
      ) : (
        <AssignedActivitiesTable records={records} loading={loading} />
      )}
    </div>
  )
}

export default DashboardAssignedActivities
