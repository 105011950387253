import { makeStyles } from '@material-ui/core'
import Alert, { AlertClasses } from 'elements/Alert'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { CRUD_GET_LIST, useGetList, useVersion } from 'react-admin'
import { useHistory } from 'react-router-dom'
import useTranslateParse from 'util/useTranslateParse'

const useStyles = makeStyles((theme: any) => ({
  wrapper: { flexGrow: 1, color: theme.blue },
  message: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('xl')]: {
      textAlign: 'center',
    },
  },
  link: { color: theme.blue, textDecoration: 'underline', cursor: 'pointer' },
}))

type PropTypes = {
  address: string
  variant?: 'blue' | 'alert'
  classes?: AlertClasses
}

export const ExploreAlreadyExistsMessage: FC<PropTypes> = ({ address, variant, classes }) => {
  const translate = useTranslate()
  const history = useHistory()
  const version = useVersion()
  const translateParse = useTranslateParse()
  const { data } = useGetList(
    'projects',
    {
      page: 1,
      perPage: 1,
    },
    { field: 'id', order: 'DESC' },
    { address: address },
    {
      action: CRUD_GET_LIST,
      version,
    }
  )
  const innerClasses = useStyles()
  const existingProject = data && Object.keys(data).length && data[Object.keys(data)[0]]
  if (!existingProject) return null

  const message = translateParse(
    'You already have a project with this address. <a>Click here<a> to view, or click "Create Project" to create a new project.',
    {
      a: () => (
        <span className={innerClasses.link} onClick={() => history.push(`/projects/${existingProject.id}`)}>
          {translate('Click here')}
        </span>
      ),
    }
  )

  switch (variant) {
    case 'alert':
      return (
        <Alert severity="info" classes={classes}>
          {message}
        </Alert>
      )

    default:
      return (
        <div className={innerClasses.wrapper + ' ' + classes?.wrapper}>
          <p className={innerClasses.message + ' ' + classes?.layout}>{message}</p>
        </div>
      )
  }
}
