import { makeStyles, useMediaQuery } from '@material-ui/core'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import { duplicate as duplicateAction } from 'actions/restActions'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import CreateEditToolbar from 'elements/CreateEditToolbar'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import DependentInput from 'elements/input/DependentInput'
import PaginationWithCsvActions from 'elements/PaginationWithCsvActions'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import inflection from 'inflection'
import { Button } from 'opensolar-ui'
import { useRef, useState } from 'react'
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  NotFound,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { OtherComponentField } from 'resources/components/others/otherComponents'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { currencySymbolForCountry, getRoleFromState, parseJsonSafe, showShareField } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { customOtherDataFields } from '../../components/others'
import {
  CodeFromComponentReferenceOrData,
  Cost,
  inputStyle,
  LinksToCustomFields,
  LinksToSearchDatabase,
  ManufacturerAndLogo,
} from '../common'
import CustomAutocompleteItem from '../widgets/CustomAutocompleteItem'
import ExhibitedShowcase from '../widgets/ExhibitedShowcase'
import NumberInputWithTiers from '../widgets/NumberInputWithTiers'
import { validateOtherData } from './validation'

const ADDER_PRICE_TREATMENT_CHOICES = [
  { id: 0, name: 'Solar' },
  { id: 1, name: 'Roofing' },
  { id: 2, name: 'Generic Non-Solar' },
]

const mapStateToProps = (state) => {
  var countryIso2 = getOrgCountryCodeFromState(state)

  return {
    org_id: state.auth ? state.auth.org_id : null,
    countryIso2: countryIso2,
    currencySymbol: currencySymbolForCountry(countryIso2),
    enablePurchasing: countryIso2 === 'AU',
  }
}

const mapDispatchToProps = {
  duplicate: duplicateAction,
}

export const ActiveOthersList = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getPermissionByKey('components')
  )
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowEdit)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  if (!allowView) {
    return <NotFound />
  }
  return (
    <List
      minQuantityToShowAsList={1}
      actions={<ListActions hasArchived={true} createButtonLabel="Add From Database" />}
      extraCreateButtonLabel={translate('ra.page.empty_new', { name: resourceName })}
      {...props}
      hasSearch={true}
      alwayShowActions={true}
      pagination={
        <PaginationWithCsvActions
          csvUploadDialogTitle="CSV Upload - Other Components"
          csvUploadDialogDescription="Here you can update costing and price adjustments for existing components by first downloading them and then reuploading them here, or upload new ones."
        />
      }
      hasCreate={allowCreate}
      filters={<ShareabilityFilters />}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid>
        <CodeFromComponentReferenceOrData source="code" sortable={false} />
        <ManufacturerAndLogo label="Manufacturer" />
        <BooleanField source="is_default" textAlign={'center'} />
        {isMedium && <Cost source="cost" />}
        <EditOrImportButton
          sortable={false}
          org_id={props.org_id}
          source="actions"
          label="Actions"
          resource="component_other_activations"
          duplicate={props.duplicate}
          allowDuplicate={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
          allowView={allowView}
          hideShared={true}
        />
      </Datagrid>
    </List>
  )
})

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      background: 'rgb(228, 228, 228)',
      padding: '5px 0',
      borderRadius: 2,
    },
  },
})

const customOtherDataFieldsFiltered = customOtherDataFields.filter((field) => field[0] !== 'model')

const ReferenceComponentInput = (props) => {
  const form = useForm()
  const { allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('components'))
  const translate = useTranslate()
  const classes = useStyles()

  const clearCustomFields = () => {
    customOtherDataFieldsFiltered.forEach((fieldConfig) => {
      form.mutators.updateField(`custom_data_field_${fieldConfig.source}`, '')
    })
    props.setDefaultComponentData('')
    props.setIsComponentFromDatabase(false)
  }

  const populateData = (choice) => {
    if (choice && choice.data && choice.url) {
      let choiceData = parseJsonSafe(choice.data, null)
      if (choice.hasOwnProperty('manufacturer_name')) {
        choiceData['manufacturer_name'] = choice['manufacturer_name']
      }
      let values = getDefaultValuesFromDataOthers({ data: choiceData, other: choice.url })
      delete values.lookup_in_component_database
      props.setDefaultComponentData(values)
      props.setIsComponentFromDatabase(true)
      Object.keys(values).forEach((field) => form.mutators.updateField(field, values[field]))
    } else {
      clearCustomFields()
    }
  }

  return (
    <ReferenceInput
      label={false}
      source="other"
      reference="component_others"
      filter={{
        show_exhibited_first: 'true',
        country_iso2: props.countryIso2,
      }}
      sort={{ field: 'code', order: 'ASC' }}
      optionValueField="url"
      allowEmpty={allowDelete}
    >
      <AutocompleteInput
        variant="outlined"
        options={{
          placeholder: translate('Search for Other Code or Manufacturer'),
          underlineStyle: { display: 'none' },
          style: { width: '100%' },
          containerStyle: { width: 550 },
          label: null,
          classes: classes,
        }}
        onSelect={populateData}
        autocompleteInputRef={props.autocompleteInputRef}
        optionText="code"
        optionValue="url"
        customItem={<CustomAutocompleteItem formatValue={(data) => data.title && `${data.title}`} />}
      />
    </ReferenceInput>
  )
}

const formatSubmitValues = (values) => {
  const data = {}
  customOtherDataFieldsFiltered.forEach((fieldConfig) => {
    const key = fieldConfig.source
    data[key] = values[fieldConfig.custom_field_prefix_source]
    if (fieldConfig.component === 'Number') {
      data[key] = parseFloat(data[key])
    }
    if (fieldConfig.component === 'Boolean') {
      data[key] = !!data[key]
    }
  })
  const dataString = JSON.stringify(data)
  values.data = dataString

  //stringify tiers data
  if (values['tiers_data']) {
    values['tiers_data'] = JSON.stringify(values['tiers_data'])
  }
  return values
}

const getDefaultValuesFromDataOthers = (record) => {
  let data =
    typeof record.data === 'string'
      ? parseJsonSafe(record.data, null)
      : typeof record.data === 'object'
      ? record.data
      : null

  // Need this to handle old other component data.
  // Unfortunately this is quite hacky since we did not perform a data migration to update all existing "Other Component" data to set
  // the new boolean fields show_in_your_solution and show_in_quotation_table to true when show_customer is set to true previously.
  // We can safely remove this condition ONLY after we have done a database migration.
  if (data?.show_customer === true) {
    if (data.show_in_your_solution === undefined) {
      data.show_in_your_solution = true
    }
    if (data.show_in_quotation_table === undefined) {
      data.show_in_quotation_table = true
    }
  }

  let customComponentDataFields = customOtherDataFields
  let values = Object.assign(
    {
      lookup_in_component_database: isNaN(record.id) || Boolean(record.other),
      other: record.other,
    },
    record
  )

  if (data) {
    //some values copy directly from matching fieldname
    customComponentDataFields.forEach(
      (fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = data[fieldConfig.source])
    )
  } else {
    customComponentDataFields.forEach((fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = ''))
  }

  //parse tiers data
  if (record['tiers_data']) {
    values['tiers_data'] = parseJsonSafe(record['tiers_data'], null)
  }
  return values
}

const CustomDataInputs = (props) => {
  const formState = useFormState()
  const isSearchFromDataBase = formState.values['lookup_in_component_database']
  const hasSelectedFromDropDown = formState.values['other']
  const showInputFields = isSearchFromDataBase ? hasSelectedFromDropDown : true
  const isStaff = useSelector(authSelectors.getIsStaff)
  return showInputFields ? (
    <>
      {customOtherDataFieldsFiltered.map((fieldConfig, index) => (
        <OtherComponentField
          key={index}
          props={props}
          fieldConfig={fieldConfig}
          isStaff={isStaff}
          disabled={isSearchFromDataBase}
        />
      ))}
      {isSearchFromDataBase ? (
        <LinksToCustomFields componentType="other" />
      ) : (
        <LinksToSearchDatabase
          defaultComponentData={props.defaultComponentData}
          isComponentFromDatabase={props.isComponentFromDatabase}
          getDefaultValues={getDefaultValuesFromDataOthers}
        />
      )}
    </>
  ) : null
}

const ExtraFields = (props) => {
  const isUs = useSelector(orgSelectors.getOrgIso2) === 'US'
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('component_pricing_costing'))

  const formState = useFormState()
  const showMoreFields = formState.values['lookup_in_component_database']
    ? Boolean(formState.values['other'])
    : Boolean(formState.values['custom_data_field_code'])
  return showMoreFields ? (
    <div>
      {allowView && (
        <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          <NumberInputWithTiers
            label="COGS per unit"
            numberInputSource="cost"
            tiersInputSource={'tiers_data.cost'}
            resource={props.resource}
            numberInputOptions={{
              defaultValue: '0',
              startAdornment: props.currencySymbol,
              endAdornment: 'ex tax',
            }}
            disabled={!allowEdit}
          />
          <NumberInputWithTiers
            label="Labour per unit"
            numberInputSource="labor_adjustment"
            tiersInputSource={'tiers_data.labor_adjustment'}
            resource={props.resource}
            numberInputOptions={{
              defaultValue: '0',
              startAdornment: props.currencySymbol,
              endAdornment: 'ex tax',
            }}
            disabled={!allowEdit}
          />
          <NumberInputWithTiers
            label="Price Adjustment per unit"
            numberInputSource="price_adjustment"
            tiersInputSource={'tiers_data.price_adjustment'}
            resource={props.resource}
            numberInputOptions={{
              defaultValue: '0',
              startAdornment: props.currencySymbol,
              endAdornment: 'inc tax',
            }}
            disabled={!allowEdit}
          />
          <NumberInputWithTiers
            label="Price Adjustment per watt"
            numberInputSource="price_adjustment_per_watt"
            tiersInputSource={'tiers_data.price_adjustment_per_watt'}
            resource={props.resource}
            numberInputOptions={{
              defaultValue: '0',
              startAdornment: props.currencySymbol,
              endAdornment: 'inc tax',
            }}
            disabled={!allowEdit}
          />
          <NumberInputWithTiers
            label="Price Adjustment per panel"
            numberInputSource="price_adjustment_per_panel"
            tiersInputSource={'tiers_data.price_adjustment_per_panel'}
            resource={props.resource}
            numberInputOptions={{
              defaultValue: '0',
              startAdornment: props.currencySymbol,
              endAdornment: 'inc tax',
            }}
            disabled={!allowEdit}
          />
        </div>
      )}

      {props.enablePurchasing && (
        <CustomField
          component={TextInput}
          InputProps={{ fullWidth: true }}
          name="_sku"
          source="_sku"
          label={'Extra SKU'}
          style={{ display: 'block' }}
          helperText="Input SKU only if the Other Component Code is different than the SKU used by your distributor."
          disabled={!allowEdit}
        />
      )}
      {isUs && (
        <CustomField
          name={'price_treatment'}
          source="price_treatment"
          label="Price Treatment (optional)"
          component={SelectInput}
          choices={ADDER_PRICE_TREATMENT_CHOICES}
          required={false}
        />
      )}
      <CustomField
        component={BooleanInput}
        name="is_default"
        label="resources.component_module_activations.fields.is_default"
        defaultValue={false}
        style={{ display: 'block', marginTop: 30 }}
        disabled={!allowEdit}
      />

      <CustomField
        component={BooleanInput}
        name="is_archived"
        source="is_archived"
        {...props}
        defaultValue={false}
        disabled={!allowEdit}
      />

      <CustomField
        component={TextInput}
        name="data"
        style={{ width: '100%', opacity: '0.5', display: 'none' }}
        label="Hidden Data"
      />
    </div>
  ) : null
}

const SearchOtherActivation = (props) => {
  const formState = useFormState()
  const autocompleteInputRef = useRef()
  const isSearchFromDataBase = formState.values['lookup_in_component_database']
  const hasSelectedFromDropDown = formState.values['other']
  const handleClick = (searchName = '') => {
    if (autocompleteInputRef && autocompleteInputRef.current) {
      autocompleteInputRef.current.value = searchName
      autocompleteInputRef.current.click()
      autocompleteInputRef.current.focus()
    }
  }
  return isSearchFromDataBase ? (
    <>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <ReferenceComponentInput
          countryIso2={props.countryIso2}
          autocompleteInputRef={autocompleteInputRef}
          setDefaultComponentData={props.setDefaultComponentData}
          setIsComponentFromDatabase={props.setIsComponentFromDatabase}
        />
        {!hasSelectedFromDropDown && <LinksToCustomFields componentType="other" />}
      </div>
      {!hasSelectedFromDropDown && <ExhibitedShowcase handleClick={handleClick} componentType="other" />}
    </>
  ) : null
}

const _ActiveOthersEdit = (props) => {
  const [defaultComponentData, setDefaultComponentData] = useState('')
  const [isComponentFromDatabase, setIsComponentFromDatabase] = useState(undefined)
  const translate = useTranslate()
  const accessRights = useSelector(permissionsSelectors.getPermissionByKey('components'))

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        defaultValue={getDefaultValuesFromDataOthers}
        formatSubmitValues={formatSubmitValues}
        toolbar={
          <CreateEditToolbar resource="component_other_activations" org_id={props.org_id} duplicate={props.duplicate} />
        }
        disabled={!access.allowEdit}
        validate={(values) => validateOtherData(values, translate)}
      >
        <BooleanInput
          style={{ display: 'none' }}
          source="lookup_in_component_database"
          label="Lookup in Component Database"
        />

        <SearchOtherActivation
          countryIso2={props.countryIso2}
          setDefaultComponentData={setDefaultComponentData}
          setIsComponentFromDatabase={setIsComponentFromDatabase}
        />
        <CustomDataInputs
          defaultComponentData={defaultComponentData}
          isComponentFromDatabase={isComponentFromDatabase}
          setDefaultComponentData={setDefaultComponentData}
          setIsComponentFromDatabase={setIsComponentFromDatabase}
        />

        <DependentInput
          dependsOn="product_warranty"
          resolve={(value) => Boolean(value) || value === 0 || window.debug_show_warranty_fields === true}
        >
          <CustomField
            component={TextInput}
            name="product_warranty"
            source="product_warranty"
            label="Product Warranty Override"
            defaultValue={''}
            options={{
              helperText: (
                <Alert severity="warning">
                  {translate(
                    'This override field is scheduled for removal. Please move data into custom component specifications if necessary'
                  )}
                </Alert>
              ),
            }}
            InputProps={{ fullWidth: true }}
            endAdornment={'years'}
            style={{ ...inputStyle, display: 'block' }}
          />
        </DependentInput>

        <ExtraFields enablePurchasing={props.enablePurchasing} currencySymbol={props.currencySymbol} />
        <ShareabilitySelector dependsOn={(form) => showShareField(form, 'other')} noBorder={true} />
      </SimpleForm>
    ),
  }).editPage
}

export const ActiveOthersEdit = connect(mapStateToProps, mapDispatchToProps)(_ActiveOthersEdit)
// const CreateFormTitle = (props) => {
//   const translate = useTranslate()
//   return <h2>{translate('Create Other Component')}</h2>
// }
const _ActiveOthersCreate = (props) => {
  const [defaultComponentData, setDefaultComponentData] = useState('')
  const [isComponentFromDatabase, setIsComponentFromDatabase] = useState(false)
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm
        validate={(values) => validateOtherData(values, translate)}
        formatSubmitValues={formatSubmitValues}
        toolbar={
          <Toolbar style={{ padding: 0 }} {...props}>
            <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
              <Button
                startIcon={<AlertError />}
                style={{ margin: 10, position: 'relative' }}
                // backgroundColor="#D8D8D8"
                variant="contained"
                color="default"
              >
                <span>{translate('Cancel')}</span>
              </Button>
            </Link>
            <SaveButton
              // disabled={!showMoreFields}
              redirect="list"
              submitOnEnter={true}
            />
          </Toolbar>
        }
        defaultValue={getDefaultValuesFromDataOthers}
      >
        <BooleanInput
          style={{ display: 'none' }}
          source="lookup_in_component_database"
          label="Lookup in Component Database"
        />
        <SearchOtherActivation
          countryIso2={props.countryIso2}
          setDefaultComponentData={setDefaultComponentData}
          setIsComponentFromDatabase={setIsComponentFromDatabase}
        />
        <CustomDataInputs
          defaultComponentData={defaultComponentData}
          isComponentFromDatabase={isComponentFromDatabase}
          setDefaultComponentData={setDefaultComponentData}
          setIsComponentFromDatabase={setIsComponentFromDatabase}
        />
        <ExtraFields enablePurchasing={props.enablePurchasing} currencySymbol={props.currencySymbol} />
        <ShareabilitySelector dependsOn={(form) => showShareField(form, 'other')} noBorder={true} />
      </SimpleForm>
    </Create>
  )
}

export const ActiveOthersCreate = connect(mapStateToProps, mapDispatchToProps)(_ActiveOthersCreate)
