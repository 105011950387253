import { Button, Chip, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useMemo } from 'react'

export interface Partner {
  id: number
  name: string
  description: string
  logo_public_url: string
  stand: string
  tags: string[]
  is_premium: boolean
}

const CardWrapper = styled('div', { name: 'CardWrapper' })(({ theme }) => ({
  borderRadius: 8,
  padding: 20,
  outline: theme.mixins.borderStandard.border,
  backgroundColor: 'white',
  maxWidth: 300,
}))

const TopWrapper = styled('div', { name: 'TopWrapper' })({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
})

const CardImg = styled('img', { name: 'CardImg' })({
  height: 56,
  width: 56,
})

const Placeholder = styled('div', { name: 'Placeholder' })({
  height: 56,
  width: 56,
})

const StyledBtn = styled(Button, { name: 'StyledBtn' })(({ theme }) => ({
  // TODO: add this as UI 3 variant
  backgroundColor: '#4272DD',
  color: 'white !important',
}))

const TagWrapper = styled('div', { name: 'TagWrapper' })({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 5,
  marginTop: 10,
})

const DescText = styled('p', { name: 'DescText' })(({ theme }) => ({
  color: theme.palette.grey[700],
  lineHeight: '20px',
  marginBottom: 0,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
}))
const PartnerCard: FC<Partner> = ({ stand, name, description, logo_public_url, tags, is_premium }) => {
  const translate = useTranslate()
  const parsedDesc = useMemo(() => description.replace('<p>', '').replace('</p>', ''), [description])
  return (
    <CardWrapper>
      <TopWrapper>
        {logo_public_url && is_premium ? <CardImg src={logo_public_url} /> : <Placeholder />}
        <div>
          <StyledBtn variant="contained" href={'#/expo/' + stand}>
            {translate('Visit Stand')}
          </StyledBtn>
        </div>
      </TopWrapper>
      <h1>{name}</h1>
      <DescText>{parsedDesc}</DescText>
      {!!tags?.length && (
        <TagWrapper>
          {tags.slice(0, 3).map((x) => (
            <Chip label={x} />
          ))}
        </TagWrapper>
      )}
    </CardWrapper>
  )
}

export default PartnerCard
