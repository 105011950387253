import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import PropTypes from 'prop-types'
import { linkToRecord, Record } from 'ra-core'
import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Button, { ButtonProps } from './Button'

const listStyle = { color: '#4d4d4d', justifyContent: 'flex-start', margin: 0 }
const defaultStyle = {}
const EditButton: FC<EditButtonProps> = ({
  basePath = '',
  label = 'ra.action.edit',
  record,
  icon = defaultIcon,
  useListStyle = false,
  ...rest
}) => (
  <Link style={{ textDecoration: 'none' }} to={linkToRecord(basePath, record && record.id)}>
    <Button
      label={label}
      onClick={stopPropagation}
      name={'raEditButton'}
      style={useListStyle ? listStyle : defaultStyle}
      {...(rest as any)}
    >
      {icon}
    </Button>
  </Link>
)

const defaultIcon = <ContentCreate />

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation()

interface Props {
  basePath?: string
  record?: Record
  icon?: ReactElement
  useListStyle?: boolean
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps

EditButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
}

export default EditButton
