import { RootState } from 'types/state'

export const SET_HEAT_PUMP_DATA_AND_PROJECT_ID = 'SET_HEAT_PUMP_DATA_AND_PROJECT_ID'
export const SET_SHOW_HEAT_PUMP_ALERT = 'SET_SHOW_HEAT_PUMP_ALERT'

export const setHeatPumpDataAndProjectId = (heatPumpData: any, projectId: number | undefined) => ({
  type: SET_HEAT_PUMP_DATA_AND_PROJECT_ID,
  payload: { heatPumpData, projectId },
})

export const setShowHeatPumpAlert = (showAlert: boolean) => ({
  type: SET_SHOW_HEAT_PUMP_ALERT,
  payload: showAlert,
})

export type ReduxHeatPumpStateType = {
  heatPumpData: any
  projectId: number | undefined
  showHeatPumpAlert?: boolean
}

const defaultState: ReduxHeatPumpStateType = {
  heatPumpData: null,
  projectId: undefined,
  showHeatPumpAlert: false,
}

type ReduxHeatPumpArgs = {
  type: string
  payload: any
}

export default function heatPumpReducer(
  state: ReduxHeatPumpStateType = defaultState,
  { type, payload }: ReduxHeatPumpArgs
): ReduxHeatPumpStateType {
  switch (type) {
    case SET_HEAT_PUMP_DATA_AND_PROJECT_ID:
      return {
        ...state,
        heatPumpData: payload.heatPumpData,
        projectId: payload.projectId,
      }
    case SET_SHOW_HEAT_PUMP_ALERT:
      return {
        ...state,
        showHeatPumpAlert: payload,
      }
    default:
      return state
  }
}

export const heatPumpSelectors = {
  getHeatPumpData(state: RootState): any {
    return state.heatPump.heatPumpData ?? null
  },
  getProjectId(state: RootState): number | undefined {
    return state.heatPump.projectId
  },
  getShowHeatPumpAlert(state: RootState): boolean {
    return state.heatPump.showHeatPumpAlert ?? false
  },
}
