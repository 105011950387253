import CalendarIcon from '@material-ui/icons/DateRangeOutlined'
import { authSelectors } from 'ducks/auth'
import { projectListViewSelectors, ProjectView, ViewType } from 'ducks/projectListViews'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { showNotification } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ActionType, EventType } from 'types/tracking'
import { useFeatureFlag } from 'util/split'
import { handleCsvDownload } from '../util'
import ExportReportsDialog from './elements/ExportReportsDialog'
import { ListColumnBase } from './views/utils'

const SecondaryButton = styled(Button)({
  marginRight: 10,
})

const MainHeader = styled('h1', { name: 'MainHeader' })(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  marginTop: 0,
  marginBottom: 10,
  color: theme.palette.primary.contrastText,
}))

const Subheader = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[900],
}))

const LeftDiv = styled('div')({
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
})
const RightDiv = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 10,
})

const FullRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  rowGap: 10,
})

interface Props {
  filterValues: object
  savedColData?: ListColumnBase[]
}

const ProjectsHeader = (props: Props) => {
  const history = useHistory()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const [showExportReportsDialog, setShowExportReportsDialog] = useState(false)
  const { id } = useParams<{ id: string }>()
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const isAsyncExportEnabled = useFeatureFlag('projects_export_csv', 'on')

  const { trackEvent, trackUserInteraction } = useTrackComponent({
    componentKey: 'project_header',
    eventName: 'Used Header Buttons',
  })

  const currentView = views.find((view) => String(view.id) === id)
  const isKanbanView = currentView?.view_type === ViewType.Kanban

  const { filterValues, savedColData } = props

  const handleCSVDownload = () => {
    handleCsvDownload(
      `${window.API_BASE_URL}orgs/${orgId}/projects/?fieldset=csv&format=csv&ordering=-id`,
      showNotification,
      props.filterValues,
      'OpenSolarProjects'
    )
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.CLICK },
      { eventName: 'User Exported Projects Data to CSV' }
    )
  }

  const openExportReportsDialog = () => {
    setShowExportReportsDialog(true)
  }

  const renderExportButton = () => {
    if (isKanbanView) {
      return null
    }

    if (isAsyncExportEnabled) {
      return (
        <WithPermissionsCheck permissionToCheck="files" permissions={['allowView']} projectMode={false}>
          <SecondaryButton variant="outlined" color="secondary" onClick={openExportReportsDialog}>
            {translate('Export Report')}
          </SecondaryButton>
        </WithPermissionsCheck>
      )
    }

    return (
      <SecondaryButton variant="outlined" color="secondary" onClick={handleCSVDownload}>
        {translate('Export to CSV')}
      </SecondaryButton>
    )
  }

  return (
    <>
      <FullRow>
        <LeftDiv>
          <MainHeader>{translate('Projects')}</MainHeader>
          <Subheader>{translate('View and manage your projects')}</Subheader>
        </LeftDiv>
        <RightDiv>
          <SecondaryButton
            href="#/calendar"
            variant="outlined"
            color="secondary"
            onClick={() =>
              trackEvent(
                EventType.USER_INTERACTION,
                { type: ActionType.CLICK },
                { eventName: 'User Opened Calendar Page' }
              )
            }
            startIcon={<CalendarIcon />}
          >
            {translate('Calendar')}
          </SecondaryButton>
          {renderExportButton()}

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              trackUserInteraction({ type: ActionType.CLICK }, { eventName: 'User Clicked Create Project Button' })
              history.push('/home')
            }}
          >
            {translate('Create Project')}
          </Button>
        </RightDiv>
      </FullRow>
      <ExportReportsDialog
        savedColData={savedColData}
        showDialog={showExportReportsDialog}
        orgId={orgId}
        setShowDialog={setShowExportReportsDialog}
        filterValues={filterValues}
      />
    </>
  )
}

export default ProjectsHeader
