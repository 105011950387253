import { Alert } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import React from 'react'
import type {
  CheckoutActionCallback,
  CheckoutActionConfig,
  CheckoutActionElementOptions,
  ProposalDataType,
} from '../../types'
import type { AlertCTAType } from '../../types/CheckoutElementsDataTypes'
import { FinanceCtaType } from '../genericFinance/constants'

export type PropTypes = {
  config: CheckoutActionConfig
  onAction: CheckoutActionCallback
  elementData: AlertCTAType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}

const SEVERITY_MAP = {
  [FinanceCtaType.ALERT_ERROR]: 'error',
  [FinanceCtaType.ALERT_INFO]: 'info',
  [FinanceCtaType.ALERT_WARNING]: 'warning',
}

const CheckoutAlertCta: React.FC<PropTypes> = (props) => {
  const { actionData } = props.elementData
  if (!actionData.status_title || !actionData.cta_type || !SEVERITY_MAP[actionData.cta_type]) return null
  return (
    <div>
      <Alert severity={SEVERITY_MAP[actionData?.cta_type]}>
        <p>{actionData.status_title}</p>
        {actionData.status_title_secondary && <p>{actionData.status_title_secondary}</p>}
      </Alert>
    </div>
  )
}
export default CheckoutAlertCta
