import { styled } from 'opensolar-ui'
import { FC } from 'react'
import StatisticCard from './common/StatisticCard'
import { SectionWrapper } from './common/styles'

export interface Statistic {
  value: string
  description: string
}

interface PropTypes {
  statistics: Statistic[]
  partnerName: string
}

const Divider = styled('div', { name: 'Divider' })(({ theme }) => ({
  borderBottom: theme.mixins.borderStandard.border,
  marginTop: 10,
  marginBottom: 20,
}))

const Header = styled('h1', { name: 'Header' })({
  fontSize: 24,
  margin: 0,
})

const StatsContainer = styled('div', { name: 'StatsContainer' })({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 40,
})

const Statistics: FC<PropTypes> = ({ partnerName, statistics }) => {
  if (!statistics) return null
  return (
    <SectionWrapper>
      {<Header>Why choose {partnerName}?</Header>}
      <Divider />
      {!!statistics.length && (
        <StatsContainer>
          {statistics.map((stat, i) => (
            <StatisticCard key={'stat-' + i} statistic={stat} />
          ))}
        </StatsContainer>
      )}
    </SectionWrapper>
  )
}

export default Statistics
