import { CableRecommendation, InverterLimits, SystemParams } from '../EnphaseTypes'
import { DEFAULT_INVERTER_LIMITS, getMatchingEnphaseInverterConfig, INVERTER_CONFIGS } from './EnphaseInverterConfigs'
import { CableOption, getCableOptions } from './enphase_bom_rules/EnphaseCableConfigs'
import { OperationExecutor } from './enphase_bom_rules/types/OperationExecutor'
import { EnphaseRule } from './enphase_bom_rules/types/RuleTypes'

/**
 * Generates form sections from COMPONENT_RULES.
 * @returns An array of form sections.
 */
export const getFormSections = (rules: Record<string, EnphaseRule>) => {
  const sections = new Map<string, Set<string>>()

  // Iterate over aggregated rules
  Object.values(rules).forEach((rule) => {
    if (rule.section !== 'Microinverters') {
      if (!sections.has(rule.section)) {
        sections.set(rule.section, new Set())
      }
      sections.get(rule.section)?.add(rule.id)
    }
  })

  return Array.from(sections.entries()).map(([heading, codesSet]) => ({
    heading,
    codes: Array.from(codesSet),
  }))
}

/**
 * Gets the display name of a component.
 * @param code - The component code.
 * @returns The display name of the component.
 */
export const getComponentTitle = (code: string, rules: Record<string, EnphaseRule>): string => {
  if (rules[code]) {
    return rules[code].displayName
  }
  // For unknown codes, create a generic display name
  if (code.includes('IQ')) {
    return `Enphase ${code}`
  }
  return code
}

/**
 * Calculates the inverter limits based on the inverter code, number of phases, and country code.
 * @param inverterCode - The inverter code.
 * @param numberOfPhases - The number of electrical phases.
 * @param countryCode - The country code.
 * @returns The inverter limits.
 */
export const calculateInverterLimits = (
  inverterCode: string,
  numberOfPhases: number,
  countryCode: string
): InverterLimits => {
  // Try to find exact match first
  let inverterType = inverterCode
  let inverterConfig = INVERTER_CONFIGS[inverterType]

  // If no exact match, try normalized code
  if (!inverterConfig) {
    inverterType = getMatchingEnphaseInverterConfig(inverterCode)
    inverterConfig = INVERTER_CONFIGS[inverterType]
  }

  const phaseKey = numberOfPhases === 1 ? '1P' : '3P'
  const countryKey = countryCode === 'AU' ? 'AU' : countryCode === 'IT' ? 'IT' : 'OTHER'

  return (
    inverterConfig?.countrySpecificLimits?.[countryCode]?.[phaseKey] ||
    inverterConfig?.defaultLimits?.[phaseKey] ||
    DEFAULT_INVERTER_LIMITS[`${phaseKey}_${countryKey}`] ||
    DEFAULT_INVERTER_LIMITS[`${phaseKey}_OTHER`]
  )
}

/**
 * Gets the best cable types based on the system parameters.
 * @param countryCode - The country code.
 * @param isThreePhase - Whether the system is three-phase.
 * @param moduleWidth - The width of the module.
 * @param moduleHeight - The height of the module.
 * @param portraitCount - The number of portrait panels.
 * @param landscapeCount - The number of landscape panels.
 * @returns An array of cable recommendations.
 */
export const getBestCableTypes = (
  countryCode: string,
  isThreePhase: boolean,
  moduleWidth: number,
  moduleHeight: number,
  portraitCount: number,
  landscapeCount: number
): CableRecommendation[] => {
  const portraitSpacing = moduleWidth + 0.3
  const landscapeSpacing = moduleHeight + 0.3

  const options = getCableOptions(countryCode, isThreePhase)

  const recommendations: CableRecommendation[] = []

  const getBestOption = (
    options: CableOption[],
    spacing: number,
    orientation: 'portrait' | 'landscape',
    count: number
  ) => {
    const filteredOptions = options.filter(
      (option) =>
        option.applicableOrientations === orientation ||
        option.applicableOrientations === 'both' ||
        !option.applicableOrientations
    )

    if (filteredOptions.length === 0) {
      console.warn(`No available cables for ${orientation} orientation.`)
      return
    }

    const bestOption = filteredOptions.reduce((best, current) => {
      const currentWaste = current.spacing - spacing
      const bestWaste = best.spacing - spacing
      return currentWaste >= 0 && (bestWaste < 0 || currentWaste < bestWaste) ? current : best
    }, filteredOptions[0])

    recommendations.push({
      code: bestOption.code,
      spacing: bestOption.spacing,
      orientation,
      quantity: count,
    })
  }

  if (portraitCount > 0) {
    getBestOption(options, portraitSpacing, 'portrait', portraitCount)
  }

  if (landscapeCount > 0) {
    getBestOption(options, landscapeSpacing, 'landscape', landscapeCount)
  }

  return recommendations
}

/**
 * Calculates the quantity of micro inverters required.
 * @param moduleQuantity - The number of modules.
 * @param inverter - The inverter object.
 * @returns The quantity of micro inverters required.
 */
export const getMicroInverterQuantity = (moduleQuantity, inverter) => {
  return Math.ceil(moduleQuantity / inverter.mppt_quantity)
}

/**
 * Determines if a component is relevant based on the system parameters.
 * @param id - The component ID.
 * @param params - The system parameters.
 * @returns True if the component is relevant, false otherwise.
 */
export const isComponentRelevant = (id: string, params: SystemParams, rules: Record<string, EnphaseRule>): boolean => {
  const rule = rules[id]
  if (!rule) return false

  const getInverterCount = () => {
    const inverter = params.system.inverters()[0]
    return getMicroInverterQuantity(params.system.moduleQuantity() - params.system.getStrungModules().length, inverter)
  }

  const executor = new OperationExecutor({
    getBatteryCode: () => params.system.batteries()[0]?.code || '',
    getBatteryCount: () => params.system.batteries().length,
    getInverterCount: () => getInverterCount(),
    numberOfPhases: params.numberOfPhases,
    countryCode: params.countryCode,
  })

  // Evaluate hidden condition/s
  if (rule.hidden) {
    const isHidden = rule.hidden.conditions
      ? rule.hidden.conditions.every((condition) => executor.evaluateCondition(condition))
      : executor.evaluateCondition({
          field: rule.hidden.field!,
          operator: rule.hidden.operator!,
          value: rule.hidden.value!,
        })
    if (isHidden) return false
  }

  //If the project is residential and the comoonent is commercial, return false
  if (rule.requiresCommercial && !params.isCommercial) return false

  // Prevent component if battery is present and rule.preventWithBattery is true
  if (rule.preventWithBattery && params.system.batteries().length > 0) return false

  // Require battery if rule.requiresBattery is true
  if (rule.requiresBattery && params.system.batteries().length === 0) return false

  // Require specific phase if rule.requiresPhase is set
  if (rule.requiresPhase && rule.requiresPhase !== params.numberOfPhases) return false

  // Only available in specified countries
  if (rule.availableIn && !rule.availableIn.includes(params.countryCode)) return false

  if (rule.notAvailableIn && rule.notAvailableIn.includes(params.countryCode)) return false

  return true
}

export const shouldComponentShowWarning = (
  id: string,
  params: SystemParams,
  rules: Record<string, EnphaseRule>
): boolean => {
  const rule = rules[id]
  if (!rule) return false

  return rule.showWarning !== false
}
