import { TextareaAutosize } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import { formSharingParams } from 'projectSections/sections/info/orgSharing/util'
import { useTranslate } from 'ra-core'
import React from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getRange } from 'util/misc'
import { InputRow } from '../styles'

const GeneralSiteInfoFields: React.FC = (props) => {
  const form = useForm()
  const initialValues = form.getState().initialValues
  const translate = useTranslate()
  const project = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)

  return (
    <>
      <InputRow>
        <ReferenceInput
          resource="projects"
          reference="project_configurations"
          source="configuration_override"
          label="Setbacks & Design Settings"
          filter={orgId ? formSharingParams(orgId, project, { limit: 1000 }) : undefined}
          sort={{ field: 'title', order: 'ASC' }}
          allowEmpty
        >
          <SelectInputProjectConfiguration
            options={{ SelectProps: { displayEmpty: true }, fullWidth: true }}
            configurationOverride={project?.configuration_override}
            configurationData={project?.configuration}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            label={false}
          />
        </ReferenceInput>
        <SelectInput
          source={'number_of_storeys'}
          variant="outlined"
          label={'Number of Stories'}
          allowEmpty
          choices={getRange(100).map((i) => ({ id: i, name: i ? i : '' }))}
          translateChoice={false}
          fullWidth
        />
      </InputRow>
      {initialValues?.costing_override && (
        <ReferenceInput
          resource="projects"
          reference="costings"
          source="costing_override"
          label={'Costing Override'}
          filter={{ limit: 1000 }}
          sort={{ field: 'title', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput
            variant="outlined"
            optionValue="url"
            label={false}
            optionText={(choice: any) => choice?.title}
            source={'costing_override'}
            fullWidth
          />
        </ReferenceInput>
      )}
      <TextInput
        multiline={true}
        source={'site_notes'}
        variant="outlined"
        label={'Site Notes'}
        placeholder={translate('Enter text here...')}
        InputProps={{
          inputComponent: TextareaAutosize,
          inputProps: {
            style: {
              resize: 'vertical',
              minHeight: 72, // equivalent to 3 rows (assuming 1 row ≈ 24px)
              maxHeight: 500,
              overflow: 'auto',
            },
          },
          style: {
            alignItems: 'flex-start',
          },
        }}
        fullWidth
      />
    </>
  )
}

const SelectInputProjectConfiguration = ({ configurationOverride, configurationData, ...props }) => {
  const translate = useTranslate()
  // Only populate the default title if we are not using an override because the title will be available
  // in project.configuration, populated by the back-end.
  // Unfortunately we cannot know the default configuration if we are using an override unless we calculated this
  // and sent it along in the api response as an extra field, which would be very wasteful, so we will just show
  // "Default" when we are using an override and not include the default title.
  const defaultDesignSettingsTitle = !configurationOverride ? configurationData?.title : null
  const emptyText = defaultDesignSettingsTitle
    ? translate('Default') + ` (${defaultDesignSettingsTitle})`
    : translate('Default')
  return (
    <SharedEntitySelectInput
      allowEmpty={true}
      emptyValue=""
      emptyText={<i style={{ color: '#aaa' }}>{emptyText}</i>}
      optionText="title"
      optionValue="url"
      style={{ width: '100%' }}
      {...props}
    />
  )
}

export default GeneralSiteInfoFields
