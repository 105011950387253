import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { permissionsSelectors } from 'ducks/permissions'
import { BaseTheme, ComponentVersionsInherit, HourGlassOutlineIcon, styled } from 'opensolar-ui'
import Notice from 'projectSections/sections/manage3/common/Notice'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Datagrid, List } from 'react-admin'
import { useSelector } from 'react-redux'
import { OuterPlaceholderContainer, PlaceholderContainer } from '../common/styles/styles'
import { FileRow } from './FileRow'

const StyledList = styled(List)(({ theme }) => ({
  minHeight: 103,
  borderWidth: '0px !important',
  padding: '0px !important',
  '& .Ra-List-Toolbar-wrapper, & thead, & .MuiTablePagination-root': {
    display: 'none !important',
  },
  '& .Ra-List-Main': {
    paddingTop: 0,
  },
  '& .td, & .MuiTableCell-sizeSmall:last-child': {
    padding: 0,
    paddingRight: 0,
    borderBottom: 'none',
  },
  '& .MuiPaper-elevation1': {
    borderColor: 'transparent !important',
  },
  '& .OSUI-Button-containedSizeMedium': {
    display: 'none !important',
  },
  '& tr:hover, & td:hover': {
    backgroundColor: 'white !important',
  },
}))

interface FilesSectionProps {
  filesSearchTerm: string
}

export const FilesSection: React.FunctionComponent<FilesSectionProps> = ({ filesSearchTerm = '' }) => {
  const translate = useTranslate()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_documents'))

  const projectId = useSelector((state: any) => state.projectId)

  const Placeholder = () => {
    const subtitle = !allowView
      ? "You don't have permission to view files for this project"
      : 'No file matches found for your query'
    return (
      <PlaceholderContainer>
        <span>
          <Notice
            icon={<HourGlassOutlineIcon variant={3} size={26} color={BaseTheme.palette.grey[810]} />}
            mainText={translate('No files found')}
            subText={translate(subtitle)}
          />
        </span>
      </PlaceholderContainer>
    )
  }

  // Show placeholder if projectId is 'new' or not set
  if (projectId === 'new' || !projectId) {
    return (
      <OuterPlaceholderContainer>
        <Placeholder />
      </OuterPlaceholderContainer>
    )
  }

  return (
    <ComponentVersionsInherit versions={{ ...ComponentVersions_3_0, table: 1 }}>
      <section>
        <StyledList
          id={'private_files_section'}
          basePath={`/private_files`}
          resource="private_files"
          hasSearch={true}
          hasCreate={true}
          filter={{ project: projectId, q: filesSearchTerm, file_tags_exclude: 'Design Artifact' }}
          hideBreadCrumbs={true}
          hasBulkActions={false}
          perPage={100}
          breadcrumbs={null}
          empty={<Placeholder />}
        >
          <Datagrid>
            <FileRow source="id" />
          </Datagrid>
        </StyledList>
      </section>
    </ComponentVersionsInherit>
  )
}
