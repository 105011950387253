import { Box, styled } from 'opensolar-ui'

const TagText = styled('text', { name: 'TagText' })({
  x: '60',
  y: '20',
  dominantBaseline: 'middle',
  textAnchor: 'middle',
  fill: 'white',
  fontSize: 12,
})
const ExpoRibbonTag = ({ fillColor = '#2D2B32', text = '' }) => {
  const padding = 2
  const width = 90 + padding * 2
  const height = 20 + padding * 2
  const tagAngleWidth = 4

  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M${padding} ${padding}H${width - padding}L${width - padding - tagAngleWidth} ${height / 2}L${
            width - padding
          } ${height - padding}H${padding}V${padding}Z`}
          fill={fillColor}
        />
        <TagText x={`${width / 2 - tagAngleWidth / 2}`} y="50%">
          {text}
        </TagText>
      </svg>
    </Box>
  )
}

export default ExpoRibbonTag
