import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'

type PropTypes = {
  label: string
  fieldName: string
  initialValue: string
  hideToolbar: boolean
}

const InvoiceEmailCustomization: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  const formVals = useFormState().values
  return (
    <div data-testid="invoice-email-body">
      <EditorComponent
        allowUploadImage={false}
        mentionContext="cashflow_project"
        name={props.fieldName}
        source={props.fieldName}
        label={props.label}
        defaultValue={translate(props.initialValue)}
        resetToDefault={translate(props.initialValue)}
        hideToolbar={false}
      />
    </div>
  )
}

export default InvoiceEmailCustomization
