import { useForm } from 'react-final-form'

import { ComponentVersions_2_1, ComponentVersions_3_0 } from 'constants/uxVersions'
import { myEnergySelectors, toggleSendProposalDialog } from 'ducks/myEnergy'
import { InviteDialog } from 'elements/button/InviteButton'
import { useUxVersion } from 'hooks/useUxVersion'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { useDispatch, useSelector } from 'react-redux'
import useSendProposalButton from './useSendProposalButton'

const SendProposalDialog = () => {
  const { disableButton } = useSendProposalButton()

  const dispatch = useDispatch()
  const form = useForm()
  const formValue = form.getState().values
  const ui3Enabled = useUxVersion() === 3

  const isSendProposalDialogShown = useSelector(myEnergySelectors.getIsSendProposalDialogShown)

  if (disableButton || !isSendProposalDialogShown) return null

  return (
    <ComponentVersionsInherit versions={ui3Enabled ? ComponentVersions_3_0 : ComponentVersions_2_1}>
      <InviteDialog
        contacts={formValue.contacts_data}
        projectId={formValue.id}
        handleClose={() => dispatch(toggleSendProposalDialog(false))}
        project={formValue}
      />
    </ComponentVersionsInherit>
  )
}

export default SendProposalDialog
