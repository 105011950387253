import { CloseOutlined } from '@material-ui/icons'
import { Dialog, DialogContent, DialogTitle, IconButton, LoadingDots, styled } from 'opensolar-ui'
import React, { useRef } from 'react'

type PropTypes = {
  isOpen: boolean
  dialogContentUrl: string | null
  handleCloseDialog: () => void
  selectedAccountingIntegration: string
}

const TitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.grey[500],
}))

const CloseButton = styled(CloseOutlined)(({ theme }) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
}))

const AccountingIntegrationConnectionDialog: React.FC<PropTypes> = (props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  return (
    <Dialog open={props.isOpen} fullScreen={true} fullWidth={true} style={{ height: 'xl' }}>
      <DialogTitle>
        <TitleWrapper>
          <div>{props.selectedAccountingIntegration} Integration details</div>
          <div>
            <IconButton onClick={props.handleCloseDialog}>
              <CloseButton />
            </IconButton>
          </div>
        </TitleWrapper>
      </DialogTitle>
      <DialogContent>
        {props.dialogContentUrl ? (
          <iframe
            ref={iframeRef}
            src={props.dialogContentUrl}
            title="Integration"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        ) : (
          <LoadingDots text="Loading..." />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AccountingIntegrationConnectionDialog
