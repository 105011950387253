import { FC, useMemo } from 'react'
import { useNotify } from '../sideEffect'
import { NotifyHandler } from '../sideEffect/useNotify'
import {
  ErrorHandlerOpts,
  ErrorHandlerOverrides,
  ErrorHandlingContext,
  ErrorHandlingProvider,
  ErrorType,
  LooseError,
} from './ErrorHandlingContext'

const errorTypes: ErrorType[] = ['unexpected', 'data_provider', 'http']

export type Props = {
  errorHandlingOverrides?: ErrorHandlerOverrides
}

const repeatErrorKey = '____has_seen_error'
const isRepeatError = (err: LooseError): boolean => {
  if (err[repeatErrorKey]) return true
  err[repeatErrorKey] = true
  return false
}

export const DefaultErrorHandlingProvider: FC<Props> = ({ children, errorHandlingOverrides }) => {
  const notify = useNotify()
  const errorHandling = useMemo(
    () =>
      errorTypes.reduce((acc, type) => {
        acc[type] = (err: LooseError, opts?: ErrorHandlerOpts) => {
          if (isRepeatError(err)) {
            console.error('WARNING: error is being handled twice')
            debugger
            return
          }

          const before = errorHandlingOverrides?.before?.[type]
          const replace = errorHandlingOverrides?.replace?.[type]
          const after = errorHandlingOverrides?.after?.[type]

          // First run before handlers
          if (before) before(err, opts)

          if (replace) {
            // Run the replace handler
            replace(err, opts)
          } else {
            // Or use default implementation
            defaultErrorHandling(type, err, opts, notify)
          }

          // Last run after handlers
          if (after) after(err, opts)
        }
        return acc
      }, ({} as unknown) as ErrorHandlingProvider), // WARNING: override typescript here to let us build this gradually
    []
  )

  return <ErrorHandlingContext.Provider value={errorHandling}>{children}</ErrorHandlingContext.Provider>
}

export const defaultErrorHandling = (
  type: ErrorType,
  error: LooseError,
  opts: ErrorHandlerOpts | undefined,
  notify: NotifyHandler
) => {
  if (error) console.error(error)

  if (opts?.notify !== false) {
    const errorDetail = typeof error === 'string' ? error : error?.message
    const message = opts?.msg || errorDetail || `ra.notification.${type}_error`
    notify(message, 'error', { error, detail: errorDetail !== message ? errorDetail : undefined })
  }
}
