import { useState } from 'react'
import CheckoutFinancingDialog from './CheckoutFinancingDialog';
import { CheckoutFinancingDialogConfig } from './types'


const useCheckoutFinancingDialog = () => {
  const [state, setState] = useState<
    { open: false; config: {} } | { open: boolean; config: CheckoutFinancingDialogConfig }
  >({
    open: false,
    config: {},
  })

  const openDialog = (config: CheckoutFinancingDialogConfig) => {
    setState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    setState({
      open: false,
      config: {},
    })
  }

  const dialogNode = state.open ? <CheckoutFinancingDialog open={state.open} onClose={closeDialog} {...state.config} /> : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'CheckoutFinancingDialog' as const }
}

export default useCheckoutFinancingDialog
