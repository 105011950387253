import { getSupplierConfigByFilterKey } from 'pages/ordering/configs'
import { HardwareDetailType } from './type'

export const getSupplierName = (order) => {
  if (!order.distributor) return order.supplier
  return getSupplierConfigByFilterKey(order?.distributor).name
}

export const getHardwareDetailFromSystem = (system): HardwareDetailType[] => {
  const hardwares: HardwareDetailType[] = []
  const moduleId: number = system.moduleId
  if (moduleId !== undefined) {
    hardwares.push({
      activationId: moduleId,
      quantity: system.moduleQuantity('all'),
      type: 'module',
    })
  }

  const inverters = system.inverters()
  inverters.forEach((inverter) => {
    let quantity: number
    if (inverter.microinverter) {
      quantity = inverter.moduleQuantity()
        ? Math.ceil(inverter.moduleQuantity() / inverter.mppt_quantity)
        : Math.ceil((system.moduleQuantity() - system.getStrungModules().length) / inverter.mppt_quantity)
    } else {
      quantity = 1
    }
    hardwares.push({
      activationId: inverter.inverter_id,
      quantity: quantity,
      type: 'inverter',
    })
  })

  const batteries = system.batteries()
  batteries.forEach((battery) => {
    const existingComponent = hardwares.find((component) => component.code === battery.code)
    if (existingComponent) {
      existingComponent.quantity += battery.quantity
    } else {
      hardwares.push({
        activationId: battery.battery_id,
        quantity: battery.quantity,
        type: 'battery',
      })
    }
  })

  const others = system.others()
  others.forEach((other) => {
    hardwares.push({
      activationId: other.other_id,
      quantity: other.quantity,
      type: 'other',
    })
  })

  return hardwares
}
