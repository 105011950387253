import { TableRow as Mui4TableRow, TableRowProps as Mui4TableRowProps } from '@material-ui/core'
import { default as Mui5TableRow } from '@mui/material/TableRow'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsTableVersion } from './Table'

export const TableRow: FC<OsTableRowProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="TableRow"
      propDefs={TableRowProps}
      showExample={true}
      component={OsTableRow}
      props={{ ...props, ref }}
    />
  )
})

export type OsTableRowProps = Mui4TableRowProps

const OsTableRow: FC<OsTableRowProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('table')
  switch (version) {
    case OsTableVersion.V2:
      return <Mui5TableRow ref={ref} {...props} />

    default:
      return <Mui4TableRow ref={ref} {...props} />
  }
})

const TableRowProps: PropsDefs = {}
