import MuiToolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import AlertError from '@material-ui/icons/ErrorOutline'
import classnames from 'classnames'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React, { Children, Fragment, isValidElement } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DeleteButton, SaveButton } from '../button'

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      backgroundColor: 'rgba(224, 224, 224, 0.5)',
      justifyContent: 'flex-end',
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: 'fixed',
      bottom: 50,
      left: 0,
      right: 0,
      padding: '8px',
      width: '100%',
      boxSizing: 'border-box',
      flexShrink: 0,
      zIndex: 2,
    },
    ux2DesktopToolbar: {
      position: 'fixed',
      bottom: 0,
      width: 'calc(100% - 240px)',
      left: 240,
      zIndex: 2,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        left: 0,
        bottom: 50,
      },
    },
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    spacer: {
      [theme.breakpoints.down('xs')]: {
        height: '5em',
      },
    },
  }),
  { name: 'RaToolbar' }
)
const valueOrDefault = (value, defaultValue) => (typeof value === 'undefined' ? defaultValue : value)

const Toolbar = (props) => {
  const {
    basePath,
    children,
    className,
    disabled,
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect,
    resource,
    saving,
    hideCancelButton,
    submitOnEnter,
    undoable,
    width,
    ...rest
  } = props

  const staticClasses = useStyles(props)
  const classes = props.classes && Object.keys(props.classes)?.length > 0 ? props.classes : staticClasses
  const zone = useSelector((state) => state.zone)
  const translate = useTranslate()

  // force redirect /{resource}/list to /{resource}
  const redirectLinkOnCancel = redirect && redirect !== 'list' && redirect !== 'edit' ? redirect : `/${resource}`
  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          (zone === 'control' || zone === 'inventory') && width !== 'xs'
            ? classes.ux2DesktopToolbar
            : classes.desktopToolbar, // TODO fix me please
          width === 'xs' && classes.mobileToolbar,
          className
        )}
        role="toolbar"
        {...rest}
      >
        {Children.count(children) === 0 ? (
          <div className={classes.defaultToolbar}>
            {!hideCancelButton && (
              <Link style={{ textDecoration: 'none' }} to={redirectLinkOnCancel}>
                <Button
                  variant="contained"
                  color="default"
                  style={{ margin: '10px 0 10px 10px' }}
                  startIcon={<AlertError />}
                >
                  <span>{translate('Cancel')}</span>
                </Button>
              </Link>
            )}
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              disabled={disabled}
              submitOnEnter={submitOnEnter}
            />
            {false && record && typeof record.id !== 'undefined' && (
              <DeleteButton
                variant="contained"
                color="default"
                basePath={basePath}
                record={record}
                resource={resource}
                undoable={undoable}
              />
            )}
          </div>
        ) : (
          Children.map(children, (button) =>
            button && isValidElement(button)
              ? React.cloneElement(button, {
                  basePath,
                  handleSubmit: valueOrDefault(button.props.handleSubmit, handleSubmit),
                  handleSubmitWithRedirect: valueOrDefault(
                    button.props.handleSubmitWithRedirect,
                    handleSubmitWithRedirect
                  ),
                  onSave: button.props.onSave,
                  invalid,
                  pristine,
                  record,
                  resource,
                  saving,
                  submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
                  undoable: valueOrDefault(button.props.undoable, undoable),
                })
              : null
          )
        )}
      </MuiToolbar>
      <div className={classes.spacer} />
    </Fragment>
  )
}

Toolbar.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  width: PropTypes.string,
}

Toolbar.defaultProps = {
  submitOnEnter: true,
}

export default withWidth({ initialWidth: 'xs' })(Toolbar)
