import { styled } from '../../styles/styled'

const LinkWrapper = styled('div')(({ theme }) => {
  return {
    marginTop: '15px',
    textAlign: 'center',
  }
})

const Link = styled('div')<{ linkColor: string }>(({ theme, linkColor }) => {
  return {
    flex: 3, // Fix me
    color: linkColor,
    cursor: 'pointer',
    textDecoration: 'underline',
  }
})

export const UploadMoreStipulationLink = ({
  linkColor,
  onClick,
  stipulationName,
}: {
  linkColor: string
  onClick(): void
  stipulationName: string
}) => {
  return (
    <LinkWrapper>
      <Link linkColor={linkColor} onClick={onClick}>
        Need to upload more {stipulationName} documents?
      </Link>
    </LinkWrapper>
  )
}
