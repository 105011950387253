import { useDataProvider } from 'ra-core'
import { useEffect } from 'react'
import { WEATHER_DATASETS } from 'resources/projectConfigurations/ProjectConfigurations'
import { preWarmWeatherCacheFromLonLat } from '../utils/preWarmWeatherCache'

export const useWeatherCacheEngine = (lon: number, lat: number, weather_dataset: number) => {
  const dataProvider = useDataProvider()
  const warmCache = preWarmWeatherCacheFromLonLat(dataProvider)

  useEffect(() => {
    if (
      lon === undefined ||
      lon === null ||
      lat === undefined ||
      lat === null ||
      !WEATHER_DATASETS.some((dataset) => dataset.id === weather_dataset)
    ) {
      return
    }

    warmCache(lon, lat, weather_dataset)
  }, [lat, lon, weather_dataset])
}
