import { Alert, styled } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type {
  AlertType,
  CheckoutActionCallback,
  CheckoutActionConfig,
  CheckoutActionElementOptions,
  ProposalDataType,
} from '../../types'

export const StyledAlert = styled(Alert)(({ theme }) => ({
  margin: '5px 0px',
  whiteSpace: 'pre-line',
}))

const AlertMessage = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: {
  config: CheckoutActionConfig
  onAction: CheckoutActionCallback
  elementData: AlertType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}) => {
  const { messages } = elementData.actionData
  const defaultSeverity = 'warning'

  return (
    <>
      {messages
        ? messages.map((message, index) => (
            <StyledAlert key={message.type + message.text} severity={message.type || defaultSeverity} className="small">
              {message.text}
            </StyledAlert>
          ))
        : null}
    </>
  )
}

export default AlertMessage
