import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker as Mui4DatePicker,
  DatePickerProps as Mui4DatePickerPropsType,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { DatePicker as Mui5DatePicker } from '@mui/x-date-pickers'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
// * MUI DatePicker API Spec  https://mui.com/x/react-date-pickers/date-picker/
export const DatePicker: FC<DatePickerProps> = (props) => (
  <Inspector
    name="DatePicker"
    propDefs={DatePickerPropsDefs}
    showExample={false}
    component={OsDatePicker}
    props={props}
  />
)

export enum OsDatePickerVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type DatePickerProps = Mui4DatePickerPropsType

const OsDatePicker: FC<DatePickerProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('date_picker')

  switch (version) {
    case OsDatePickerVersion.V2:
      return (
        //@ts-ignore
        <Mui5DatePicker
          ref={ref}
          slotProps={{
            popper: {
              sx: {
                zIndex: 2000,
              },
            },
            textField: {
              InputProps: {
                color: 'secondary',
              },
            },
          }}
          {...props}
        />
      )

    default:
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Mui4DatePicker ref={ref} {...props} />
        </MuiPickersUtilsProvider>
      )
  }
})

const DatePickerPropsDefs: PropsDefs = {}
