import { InputLabel } from '@material-ui/core'
import { countries, countriesWhichRequireTermsOptIn } from 'constants/countries'
import { useAddRemoveStructs } from 'contexts/structs/hooks/useAddRemoveStructs'
import { Struct } from 'contexts/structs/types/types'
import { addDevToolShowHideToggle } from 'debug/ostools'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { viewModeSelectors } from 'ducks/viewMode'
import GDPRText from 'elements/GDPRText'
import CheckboxInput from 'elements/input/CheckboxInput'
import { TermsAndPrivacyLinks } from 'elements/legal/TermsAndPrivacyLinks'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { Button, MenuItem, Select, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { RootState } from 'types/state'
import { updateEmailPreferences } from '../../actions/authActions'
import { setTermsAccepted } from '../../ducks/auth'
import { PromoDialog, PromoDialogComponent, PromoDialogMedia } from './usePromoDialogStructs'

export const ORIENTATION_VIDEO_URLS = {
  full: 'https://player.vimeo.com/video/742363084?h=7a71a8d75d',
  lite: 'https://player.vimeo.com/video/856873380?h=cc6a2e4956',
}

const generateKey = (struct: Struct<PromoDialog>) => {
  struct.key = 'user-agreement_' + Date.now()
}

export const useUserAgreementPromo = () => {
  // Add devtools to show/hide the dialog
  const [forceShow, setForceShow] = useState<boolean | undefined>()
  addDevToolShowHideToggle('promos.{showhide}UserAgreement', '{showhide} the user agreement promo dialog', setForceShow)

  const structs = useAddRemoveStructs()

  // Mysteriously, this isn't used in the pro case
  // const show = useSelector((state: RootState) => state.welcome.show_welcome_dialog)

  const isPro = useSelector((state: RootState) => state.welcome.pro)
  const userId = useSelector(authSelectors.getCurrentUserId)
  const role = useSelector(authSelectors.getCurrentRole)
  const authStatus = useSelector(authSelectors.getAuthStatus)
  const hasAcceptedTerms = useSelector(authSelectors.getHasAcceptedTerms)
  const isLite = useSelector(viewModeSelectors.isUserLite)

  const isOpen = useMemo(() => {
    if (forceShow !== undefined) return forceShow

    // Only show for Pro, not customer
    // Customer acceptance is handled in the MyEnergy Introduction popup
    if (!role) {
      return false
    }

    // Do not show until sso finished
    if (authStatus === 'sso_logging_in') {
      return false
    }

    // Open if terms not accepted OR if welcome message is active
    if (userId && !hasAcceptedTerms) {
      return true
    } else if (isPro) {
      return true
    } else {
      return false
    }
  }, [userId, isPro, role, authStatus, hasAcceptedTerms, forceShow])

  useEffect(() => {
    WelcomeVideo.media_url = isLite ? ORIENTATION_VIDEO_URLS.lite : ORIENTATION_VIDEO_URLS.full
  }, [isLite])

  useEffect(() => {
    UserAgreementDialog.type = hasAcceptedTerms ? 'onboarding' : 'blocker'
    if (isOpen) {
      generateKey(UserAgreementStruct)
      structs.add(UserAgreementStruct, { update: true })
    }
  }, [hasAcceptedTerms])

  useEffect(() => {
    if (isOpen) {
      generateKey(UserAgreementStruct)
      structs.add(UserAgreementStruct)
    } else {
      structs.remove(UserAgreementStruct)
    }
  }, [isOpen])
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const TermsAcceptanceElement = ({ key }: { key: string }) => {
  const dispatch = useDispatch()
  const orgCountry = useSelector(orgSelectors.getOrgIso2)
  const translate = useTranslate()
  const hasAcceptedTerms = useSelector(authSelectors.getHasAcceptedTerms)
  const email_marketing_opt_in = useSelector((state: RootState) => !!state.auth?.user?.email_marketing_opt_in)

  const [selectedCountry, setSelectedCountry] = useState(orgCountry)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showGDPR, setShowGDPR] = useState(false)

  const [GDPRAnswer, setGDPRAnswer] = useState(
    email_marketing_opt_in || !countriesWhichRequireTermsOptIn.includes(orgCountry || '')
  )

  const handleCountryChange = (newCountry: string) => {
    setSelectedCountry(newCountry)
  }

  const renderCountrySelection = () => {
    return (
      <div style={{ margin: '20px 10px 0px 10px' }}>
        <InputLabel id="country">{translate('Your Country')}</InputLabel>
        <Select
          labelId="country"
          defaultValue={orgCountry}
          value={selectedCountry}
          onChange={(event) => handleCountryChange(event.target.value as string)}
          style={{ width: 300 }}
        >
          {countries.map((country) => {
            return (
              <MenuItem key={country.id} value={country.id}>
                {translate(country.name)}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }

  const renderGDPRToggle = () => {
    return (
      <div>
        <CheckboxInput
          input={{
            value: GDPRAnswer,
            onChange: (newVal) => {
              setGDPRAnswer(newVal)
            },
          }}
          label={GDPRText}
          elStyle={{ width: '100%', maxWidth: null }}
        />
      </div>
    )
  }

  const acceptTerms = () => {
    setLoading(true)
    setMessage('')
    restClientInstance('CUSTOM_POST', 'accept_terms', {
      url: 'accept_terms/',
      data: {
        email_marketing_opt_in: GDPRAnswer,
      },
    }).then(
      (response) => {
        setLoading(false)
        dispatch(updateEmailPreferences({ email_marketing_opt_in: GDPRAnswer }))
        dispatch(setTermsAccepted())
      },
      (reject) => {
        setMessage(reject.message)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    if (orgCountry) {
      setSelectedCountry((prev) => prev || orgCountry)
    }
  }, [orgCountry])

  useEffect(() => {
    if (!selectedCountry || selectedCountry === 'US') {
      if (showGDPR) setShowGDPR(false)
    } else {
      // TODO: clean up this quirky logic below
      if (!showGDPR) setShowGDPR(true)
      // if (selectedCountry !== prevState.selectedCountry) {
      if (countriesWhichRequireTermsOptIn.includes(selectedCountry)) {
        if (GDPRAnswer) setShowGDPR(false)
      } else {
        if (!GDPRAnswer) setShowGDPR(true)
      }
      // }
    }
  }, [selectedCountry])

  return (
    <>
      {!hasAcceptedTerms && (
        <TermsContainer key={key}>
          <div>
            {renderCountrySelection()}
            {showGDPR && renderGDPRToggle()}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ margin: 10 }}>
                <TermsAndPrivacyLinks />
              </div>
              {message && <p>{message}</p>}
            </div>
            <Button
              id="UserAgreementDialogContinue"
              onClick={() => acceptTerms()}
              disabled={loading}
              variant="contained"
              color="primary"
            >
              {translate('continue')}
              {loading ? <FullScreenLoading /> : null}
            </Button>
          </div>
        </TermsContainer>
      )}
    </>
  )
}

const TermsContainer = styled('div', { name: 'TermsContainer' })({
  padding: 20,
  textAlign: 'left',
})

const WelcomeVideo: PromoDialogMedia = {
  type: 'video',
  media_url: '',
  media_height: 360, //TODO: validate this
} as const

const TermsElement: PromoDialogComponent = {
  type: 'react-component',
  render: TermsAcceptanceElement,
} as const

const UserAgreementDialog: PromoDialog = {
  type: 'onboarding',
  priority: 5,
  show_again_rule: 'once-per-session',
  conditions: [],
  elements: [
    {
      type: 'h3',
      text: 'Welcome to OpenSolar',
    },
    WelcomeVideo,
    TermsElement,
  ],
}

const UserAgreementStruct: Struct<PromoDialog> = {
  key: 'user-agreement',
  type: 'promo-dialog',
  data: UserAgreementDialog,
}
