import { Hidden, InputAdornment, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import DependentInput from 'elements/input/DependentInput'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import get from 'lodash/get'
import {
  ArrowForwardIcon,
  Box,
  Button,
  ChevronDownOutlineIcon,
  DeleteOutlineIcon,
  Grid,
  IconButton,
  PlusIcon,
  styled,
  Typography,
} from 'opensolar-ui'
import React, { useMemo } from 'react'
import { NumberInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { MeasurementUnits } from 'types/orgs'
import { ProjectType } from 'types/projects'
import type { Theme } from 'types/themes'
import { currencySymbolForCountry } from 'util/misc'
import { TRANSPORTATION_CHOICES, USAGE_CHOICES } from './constants'

const StyledVehicleRowContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '40px',
    ' > div': {
      padding: '12px 0',
    },
  },
}))

const ColumnHeader = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.MuiTypography-root': {
      fontWeight: 600,
      marginBottom: '8px',
    },
  },
}))

const StyledVehicleFormContainer = styled('div')(({ theme }) => ({
  padding: '0 12px',
  border: '2px solid',
  borderColor: theme.palette.grey[200],
  borderRadius: '4px',
}))

const StyledSelectInput = styled(SelectInput)({
  width: '100%',
  margin: '16px 30px 16px 0px',
})

const StyledButton = styled(Button)({
  '.MuiButton-startIcon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const StyledCustomField = styled(CustomField)({
  width: '100%',
  margin: '5px 0px',
  padding: '0px',
})

const HiddenTextInput = styled(TextInput)({
  display: 'none',
})

type TransportationItemFormType = {
  baseField: string
  inCurrentColumn?: boolean
}

const TransportationItemForm = ({ baseField, inCurrentColumn = false }: TransportationItemFormType) => {
  const formState = useFormState<ProjectType>()
  const vehicleType = get(formState?.values, `${baseField}.vehicle_type`)
  const currencySymbol = useMemo(() => {
    const projectCountry = formState.values['country_iso2']
    return currencySymbolForCountry(projectCountry)
  }, [formState?.values?.country_iso2])

  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric
  const priceUnit = useMemo(() => {
    if (vehicleType === 'petrol') return '/Gal'
    return '/kWh'
  }, [vehicleType])
  const efficiencyUnit = useMemo(() => {
    if (vehicleType === 'petrol') return 'MPG'
    const distanceUnit = measurements === MeasurementUnits.metric ? 'km' : 'mi'
    return `${distanceUnit}/kWh`
  }, [vehicleType, measurements])

  const excludeTypes = inCurrentColumn ? [] : ['petrol']
  return (
    <StyledVehicleFormContainer>
      <Grid container spacing={3}>
        <HiddenTextInput
          name={`${baseField}.is_current`}
          source={`${baseField}.is_current`}
          resource={'projects'}
          defaultValue={inCurrentColumn}
        />
        <HiddenTextInput
          name={`${baseField}.is_proposed`}
          source={`${baseField}.is_proposed`}
          resource={'projects'}
          defaultValue={!inCurrentColumn}
        />
        <Grid item xs={12} lg={6}>
          <StyledSelectInput
            label="Transportation Type"
            name={`${baseField}.vehicle_type`}
            source={`${baseField}.vehicle_type`}
            resource={'projects'}
            choices={filter(TRANSPORTATION_CHOICES, (choice: any) => !excludeTypes.includes(choice.id))}
            translateChoice={true}
            fullWidth
            emptyText="Select Transportation"
          />
        </Grid>

        <DependentInput
          dependsOn={`${baseField}.vehicle_type`}
          resolve={(value) => value === 'electric' || (value === 'petrol' && inCurrentColumn)}
        >
          <Grid item xs={12} lg={6}>
            <StyledSelectInput
              label="Data Source"
              name={`${baseField}.data_source`}
              source={`${baseField}.data_source`}
              resource={'projects'}
              choices={USAGE_CHOICES}
              translateChoice={true}
              defaultValue="annual_mileage"
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <NumberInput
              label="Annual Distance Traveled"
              name={`${baseField}.annual_vehicle_km_traveled`}
              source={`${baseField}.annual_vehicle_km_traveled`}
              resource={'projects'}
              fullWidth
              inputProps={{ min: 0 }}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <NumberInput
              label="Single Rate - Price per unit"
              name={`${baseField}.price_per_unit`}
              source={`${baseField}.price_per_unit`}
              resource={'projects'}
              inputProps={{ min: 0 }}
              startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
              endAdornment={<InputAdornment position="end">{priceUnit}</InputAdornment>}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <NumberInput
              label="Vehicle Efficiency"
              name={`${baseField}.fuel_economy_fuel_unit_per_km`}
              source={`${baseField}.fuel_economy_fuel_unit_per_km`}
              resource={'projects'}
              inputProps={{ min: 0 }}
              endAdornment={<InputAdornment position="end">{efficiencyUnit}</InputAdornment>}
              margin="normal"
              fullWidth
            />
          </Grid>
        </DependentInput>
        <DependentInput dependsOn={`${baseField}.vehicle_type`} value="electric">
          <Grid item sm={12}>
            <StyledCustomField
              name={`${baseField}.is_ev_charger_installed`}
              source={`${baseField}.is_ev_charger_installed`}
              label="EV Charger already installed at the property"
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
            />
          </Grid>
        </DependentInput>
      </Grid>
    </StyledVehicleFormContainer>
  )
}

type VehicleRowType = {
  col1?: React.ReactNode
  col2?: React.ReactNode
  showArrowIcon?: boolean
  deleteButton?: React.ReactNode
  marginBottom?: string
}

const VehicleRow = (props: VehicleRowType) => {
  const { col1 = undefined, col2 = undefined, showArrowIcon = false, deleteButton = undefined, ...otherProps } = props
  const translate = useTranslate()
  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <StyledVehicleRowContainer container {...otherProps}>
      <Grid item xs={12} sm={6}>
        {isXsDown && (
          <ColumnHeader>
            <Typography variant="subtitle1">{translate('Current Transportation')}</Typography>
          </ColumnHeader>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="1" width="calc(100% - 48px)">
            {col1}
          </Box>
          <Box height="100%" width="48px" display="flex" justifyContent="center" alignItems="center">
            {isXsDown && deleteButton}
            {!isXsDown && showArrowIcon && <ArrowForwardIcon height="24" width="24" />}
          </Box>
        </Box>
      </Grid>
      {isXsDown && showArrowIcon && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <ChevronDownOutlineIcon />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {isXsDown && (
          <ColumnHeader>
            <Typography variant="subtitle1">{translate('Current Transportation')}</Typography>
          </ColumnHeader>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flex="1" width="calc(100% - 48px)">
            {col2}
          </Box>
          <Box height="100%" width="48px" display="flex" justifyContent="center" alignItems="center">
            {deleteButton}
          </Box>
        </Box>
      </Grid>
    </StyledVehicleRowContainer>
  )
}

// All fields on this section will be nested inside this field
const FIELD_PREFIX = 'transportation'
const DEFAULT_ROW = [
  { is_current: true, is_proposed: false },
  { is_current: false, is_proposed: true },
]

const TransporationContent = () => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const vehicleList: any[] = get(formState.values, FIELD_PREFIX, DEFAULT_ROW)

  const addVehicleRow = () => {
    const isFieldExist = form.getFieldState(FIELD_PREFIX)
    if (!isFieldExist) {
      form.registerField(FIELD_PREFIX, () => {}, {})
    }
    const updatedVehicleList = [...vehicleList, ...DEFAULT_ROW]
    let previousRowId = 0
    form.change(
      FIELD_PREFIX,
      updatedVehicleList.map((row) => {
        previousRowId = row.id || previousRowId
        return {
          ...row,
          id: previousRowId++,
        }
      })
    )
  }

  const removeVehicleRow = (rowIndex) => {
    const clonedVehicleList = cloneDeep(vehicleList)
    clonedVehicleList.splice(rowIndex * 2, 2)
    form.change(FIELD_PREFIX, clonedVehicleList)
  }

  const groupedVehicleList = useMemo(() => {
    if (!vehicleList) return []
    return vehicleList.reduce((acc, _, i) => {
      if (i % 2 === 0) {
        const groupedRow = vehicleList?.slice(i, i + 2)
        const groupedId = groupedRow.map((row) => row.id).join('-')
        acc.push({ key: groupedId, content: groupedRow })
      }
      return acc
    }, [])
  }, [vehicleList])

  return (
    <Box display="flex" flexDirection="column" width="100%" gridGap={12}>
      {groupedVehicleList.length > 0 && (
        <Hidden xsDown>
          <VehicleRow
            col1={<Typography variant="subtitle1">{translate('Current Transportation')}</Typography>}
            col2={<Typography variant="subtitle1">{translate('Proposed Transportation')}</Typography>}
            marginBottom="20px"
          />
        </Hidden>
      )}
      {groupedVehicleList.map(({ key }, rowIndex) => (
        <VehicleRow
          key={key}
          col1={<TransportationItemForm baseField={`${FIELD_PREFIX}.${rowIndex * 2}`} inCurrentColumn />}
          col2={<TransportationItemForm baseField={`${FIELD_PREFIX}.${rowIndex * 2 + 1}`} />}
          showArrowIcon
          deleteButton={
            <IconButton onClick={() => removeVehicleRow(rowIndex)}>
              <DeleteOutlineIcon size={24} color="black" />
            </IconButton>
          }
        />
      ))}

      <div>
        <StyledButton variant="contained" startIcon={<PlusIcon height="12" width="12" />} onClick={addVehicleRow}>
          {translate('Transportation')}
        </StyledButton>
      </div>
    </Box>
  )
}

export default TransporationContent
