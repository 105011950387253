import { StringType } from 'types/global'
import { OtherComponentType } from 'types/otherComponent'
import {
  BASELINE_COPPER_TEMP,
  CABLE_STC_TEMP,
  COPPER_RESISTIVITY,
  COPPER_TEMP_COEFFICIENT,
  DEFAULT_CABLE_TEMP,
  GOOD_VOLTAGE_DROP,
  MAX_ALLOWABLE_VOLTAGE_DROP,
  MAX_CABLE_SIZE,
  STANDARD_CABLE_SIZES,
} from './constants'
import { resultsType } from './types'

const CABLE_THICKNESS_CACHE = new Map<string, number>()
const MAX_CACHE_SIZE = 20

function getCacheKey(length: number, phase: compatiblePhases, current: number, qty: number): string {
  return `${Math.round(length * 10) / 10}-${phase}-${Math.round(current * 10) / 10}-${qty}`
}

export function getSupplyVoltage(supplyPhase: string) {
  const supplyVoltage = supplyPhase === 'single_phase' ? 240 : 415
  return supplyVoltage
}

export function calculateCableResistance(
  crossSectionArea: number, // in mm²
  temperature: number = DEFAULT_CABLE_TEMP // in °C
): number {
  // Convert mm² to m²
  const areaInSquareMeters = crossSectionArea * 1e-6

  // Calculate resistivity at the given temperature
  const resistivityAtTemperature =
    COPPER_RESISTIVITY * (1 + COPPER_TEMP_COEFFICIENT * (temperature - BASELINE_COPPER_TEMP))

  // Calculate resistance for 1m length
  const resistance = resistivityAtTemperature / areaInSquareMeters

  return Number(resistance.toFixed(5))
}

export function getMeterCompatibility(meter: OtherComponentType, phase: 'single_phase' | 'three_phase'): resultsType {
  const meterPhase = meter.phase_type
  const compatibility = phase === meterPhase ? 'good' : 'bad'
  return {
    compatibility,
    data: {
      meterPhase,
      phase,
    },
  }
}

export function getMaxCableCurrent(cableLocation: any) {
  // Where cableLocation is OsInverter or OsString or OsMppt
  var current
  const module = window.AccountHelper.getModuleById(window.editor.selectedSystem.moduleId)
  // Calculate the highest expected Imp, where module.imp is at STC (25 degrees C)
  // Assume the highest expected location temperature is 40, making the temperature difference 15 degrees C
  // Assume that all modules are the same. If a combination of different modules are used in the future, the code should be updated to use the panel with the lowest current in the string
  const temperature_difference = DEFAULT_CABLE_TEMP - CABLE_STC_TEMP
  const impAt40 = module.imp + (temperature_difference * module.temp_coefficient_isc * module.imp) / 100
  // if string, as below. If microinverter, just multiple current by number of modules attached to location.
  const inverter =
    cableLocation.type === 'OsString'
      ? cableLocation.parent.parent
      : cableLocation.type === 'OsMppt'
      ? cableLocation.parent
      : cableLocation
  if (cableLocation.type === 'OsInverter') {
    current = cableLocation.current_ac_max
  } else if (inverter.microinverter) {
    current = impAt40 * cableLocation.moduleQuantity() // Assumes panels conencted to multi input microinverters are connected in parallel
  } else {
    if (cableLocation.type === 'OsString') {
      current = impAt40
    } else {
      // Assume that all modules are the same. If a combination of different modules are used in the future, the code should be updated to add each string's current
      const strings = cableLocation.strings().length
      current = impAt40 * strings
    }
  }
  return current
}

export type compatiblePhases = 'single_phase' | 'three_phase'

export function getThinnestCompatibleCableThickness(
  cableLength: number,
  supplyPhase: compatiblePhases,
  current: number,
  qty: number = 1
): number {
  const cacheKey = getCacheKey(cableLength, supplyPhase, current, qty)

  if (CABLE_THICKNESS_CACHE.has(cacheKey)) {
    return CABLE_THICKNESS_CACHE.get(cacheKey)!
  }

  const phaseFactor = supplyPhase === 'three_phase' ? Math.sqrt(3) : 2
  const maxResistance = getSupplyVoltage(supplyPhase) / (100 * phaseFactor * cableLength * current * qty)

  // Find first standard size that meets resistance requirement
  const compatibleSize = STANDARD_CABLE_SIZES.find((size) => {
    const resistance = calculateCableResistance(size)
    return resistance < maxResistance
  })

  if (!compatibleSize) {
    console.warn(
      `No standard cable size meets requirements: ${current}A, ${cableLength}m, ${supplyPhase}. Using maximum ${MAX_CABLE_SIZE}.`
    )
  }

  const result = compatibleSize || MAX_CABLE_SIZE

  if (CABLE_THICKNESS_CACHE.size >= MAX_CACHE_SIZE) {
    const firstKey = CABLE_THICKNESS_CACHE.keys().next().value
    CABLE_THICKNESS_CACHE.delete(firstKey)
  }
  CABLE_THICKNESS_CACHE.set(cacheKey, result)

  return result
}

// Note cable voltage drop for microinverters DC cabling is not necessary, since the cable lengths are so short.
export function getCableVoltageDrop(
  cable: OtherComponentType,
  cableLength: number,
  supplyPhase: 'single_phase' | 'three_phase',
  current: number
): resultsType {
  if (!current) {
    return {
      compatibility: null,
      data: {
        error:
          window.translate('Cannot calculate cable voltage drop') +
          ': ' +
          window.translate('component current not defined'),
      },
    }
  }
  if (!cable.cable_thickness) {
    return {
      compatibility: null,
      data: {
        error:
          window.translate('Cannot calculate cable voltage drop') +
          ': ' +
          window.translate('component cable thickness not defined'),
      },
    }
  }
  const supplyVoltage = getSupplyVoltage(supplyPhase)
  const cableResistance = calculateCableResistance(cable.cable_thickness)
  const voltageDrop = 2 * cableLength * current * cableResistance
  const voltageDropPercentage = (voltageDrop / supplyVoltage) * 100
  const compatibility =
    voltageDropPercentage < GOOD_VOLTAGE_DROP
      ? 'good'
      : voltageDropPercentage < MAX_ALLOWABLE_VOLTAGE_DROP
      ? 'ok'
      : 'bad'
  return {
    compatibility,
    data: {
      voltageDropPercentage,
    },
  }
}

export function getACIsolatorCompatibility(isolator: OtherComponentType, isolatorLocation: any): resultsType {
  const errors: string[] = []
  var currentCompatibility, phaseCompatibility
  const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
  const inverterCurrent = inverter?.current_ac_max
  var current
  if (inverter.microinverter) {
    // Note we use 'isolatorLocation' below which can be the inverter or the branch. If we are isolating separate branches, we only combine the output currents of the microinverters within the branch. If we add an isolator on the level above that, we combine the branches.
    const microinverterQty = Math.ceil(isolatorLocation.moduleQuantity() / inverter.mppt_quantity)
    current = inverterCurrent * microinverterQty
  } else {
    current = inverterCurrent
  }
  const currentRating = isolator?.current_rating
  if (current && currentRating) {
    currentCompatibility = current < currentRating ? 'good' : 'bad'
  } else {
    if (!currentRating) errors.push(window.translate('isolator current rating not defined'))
    if (!current) errors.push(window.translate('maximum inverter output current not defined'))
  }

  const phase = inverter?.phase_type
  const isolatorPhase = isolator?.phase_type
  if (phase && isolatorPhase) {
    phaseCompatibility = phase === isolatorPhase ? 'good' : 'bad'
  } else {
    if (!isolatorPhase) errors.push(window.translate('isolator phase type not defined'))
    if (!phase) errors.push(window.translate('inverter phase type not defined'))
  }

  const compatibility = errors.length
    ? null
    : currentCompatibility === 'good' && phaseCompatibility === 'good'
    ? 'good'
    : 'bad'
  return {
    compatibility,
    data: {
      current,
      currentRating,
      currentCompatibility,
      phase,
      isolatorPhase,
      phaseCompatibility,
      error: errors.length
        ? window.translate('Cannot calculate isolator compatibility') + ': ' + errors.join(', ')
        : undefined,
    },
  }
}

export function getSystemVoltageAndCurrent(strings: StringType[]) {
  let longestStringLength = 0
  strings.forEach((string) => {
    if (string.modules.length > longestStringLength) longestStringLength = string.modules.length
  })
  //@ts-ignore
  const voltage = window.editor.selectedSystem.userData.module.voc * longestStringLength
  //@ts-ignore
  const current = window.editor.selectedSystem.userData.module.isc * strings.length
  return { voltage, current }
}

// strings = window.editor.selectedSystem.userData.inverters.mppts[x].strings or an array with containing one string [strings[x]], depending if user has chosen to isolate the MPPT strings in parallel rather than each string separately
export function getDCIsolatorCompatibility(isolator: OtherComponentType, strings: StringType[]): resultsType {
  if (!isolator) {
    throw new Error(
      window.translate('Cannot calculate DC isolator compatibility') + ': ' + window.translate('isolator not defined')
    )
  }
  const { voltage, current } = getSystemVoltageAndCurrent(strings)
  if (!isolator.voltage_to_current_rating) {
    return {
      compatibility: null,
      data: {
        current,
        voltage,
        error:
          window.translate('Cannot calculate isolator compatibility') +
          ': ' +
          window.translate('component voltage to current rating not defined'),
      },
    }
  }
  function getVoltageAndCurrentRating(voltageToCurrentRating, systemVoltage) {
    var voltageKey
    Object.keys(voltageToCurrentRating).forEach((voltage: string | number) => {
      // Find the lowest voltage in voltageToCurrentRating that's above our system voltage
      voltage = Number(voltage)
      if (!voltageKey || (voltage < voltageKey && systemVoltage < voltage)) {
        voltageKey = voltage
      }
    })
    return { voltage: voltageKey, current: voltageToCurrentRating[voltageKey] }
  }
  const rating = getVoltageAndCurrentRating(isolator.voltage_to_current_rating, voltage)
  const voltageRating = rating.voltage
  const currentRating = rating.current
  //@ts-ignore
  const voltageCompatibility = voltage < voltageRating ? 'good' : voltage * 1.25 < voltageRating ? 'ok' : 'bad'
  //@ts-ignore
  const currentCompatibility = current < currentRating ? 'good' : current * 1.25 < currentRating ? 'ok' : 'bad'
  const compatibility =
    voltageCompatibility === 'good' && currentCompatibility === 'good'
      ? 'good'
      : voltageCompatibility === 'bad' || currentCompatibility === 'bad'
      ? 'bad'
      : 'ok'
  return {
    compatibility,
    data: {
      current,
      currentRating,
      currentCompatibility,
      voltage,
      voltageRating,
      voltageCompatibility,
    },
  }
}
