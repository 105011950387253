import { FC } from 'react'

/**
 * * Icon Component for: user-profile-group-filled
 **/
type UserProfileGroupIconProps = {
  size?: number
  color?: string
}
export const UserProfileGroupIcon: FC<UserProfileGroupIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M7.33268 6.66667C9.17363 6.66667 10.666 5.17428 10.666 3.33333C10.666 1.49238 9.17363 0 7.33268 0C5.49173 0 3.99935 1.49238 3.99935 3.33333C3.99935 5.17428 5.49173 6.66667 7.33268 6.66667Z"
        fill={color}
      />
      <path
        d="M16.666 4.66667C16.666 5.77124 15.7706 6.66667 14.666 6.66667C13.5614 6.66667 12.666 5.77124 12.666 4.66667C12.666 3.5621 13.5614 2.66667 14.666 2.66667C15.7706 2.66667 16.666 3.5621 16.666 4.66667Z"
        fill={color}
      />
      <path
        d="M15.4994 13.7383C15.3663 14.1244 15.6453 14.5722 16.048 14.5046C18.3223 14.1226 19.3327 12.8295 19.3327 11.293C19.3327 9.45607 17.1989 7.96692 14.5667 7.96692C14.1798 7.96692 13.8037 7.9991 13.4435 8.05982C13.3431 8.07676 13.3206 8.20911 13.4063 8.26417C14.6567 9.06695 15.7582 10.3884 15.7582 12.153C15.7582 12.7103 15.6703 13.2426 15.4994 13.7383Z"
        fill={color}
      />
      <path
        d="M7.4539 16.303C12.1848 16.303 14.2418 14.4434 14.2418 12.1494C14.2418 9.85541 11.2027 7.99577 7.4539 7.99577C3.70505 7.99577 0.666016 9.85541 0.666016 12.1494C0.666016 14.4434 2.72295 16.303 7.4539 16.303Z"
        fill={color}
      />
    </svg>
  )
}
