import { FC } from 'react'

/**
 * * Icon Component for: receipt-lines
 */
type ReceiptLinesOutlineIconProps = {
  size?: number
  color?: string
}
export const ReceiptLinesOutlineIcon: FC<ReceiptLinesOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M4.79922 5.8H13.1992M4.79922 10.6H13.1992M2.75922 1H15.2392C16.1008 1 16.7992 1.80589 16.7992 2.8V19L14.1992 17.2L11.5992 19L8.99922 17.2L6.39922 19L3.79922 17.2L1.19922 19V2.8C1.19922 1.80589 1.89765 1 2.75922 1Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
