import { Action } from '../core/action'
import { ShallowState, Value } from '../core/state'

export const route = {
  // State
  route: new ShallowState<Route | undefined>(undefined),
  path: new Value<string | undefined>(undefined),

  // Actions
  goto: new Action<[string | Route]>(),
} as const

export type Route = {
  pathname?: string
  search?: string
  state?: unknown
  hash?: string
  key?: string
}
