import { authSelectors } from 'ducks/auth'
import { useEndpointPoller } from 'hooks/useEndpointPoller'
import { deleteGenericUserAction, recordGenericUserAction } from 'persistentContent/tours/tools/common'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserAction } from './types'

export type UserActionsContextType = {
  actions: UserAction[]
  findFirstAction: (action_name: string) => UserAction | undefined
  findLastAction: (action_name: string) => UserAction | undefined
  checkAction: (action_name: string) => boolean
  recordAction: (action_name: string) => void
  deleteAction: (action_name: string) => void
  loaded: boolean
}

export type PromosLoadState = 'not-loaded' | 'loaded' | 'reloading'

const POLL_TIME = 5 * 60 * 1000 // 5m
export const UserActionsCore = createContext<UserActionsContextType>({
  actions: [],
  findFirstAction: () => undefined,
  findLastAction: () => undefined,
  checkAction: () => false,
  recordAction: () => {},
  deleteAction: () => {},
  loaded: false,
})

export const UserActionsContext = ({ children }) => {
  const userId = useSelector(authSelectors.getCurrentUserId)
  const [actions, setActions] = useState<UserAction[]>([])
  const [loaded, setLoaded] = useState(false)

  const checkAction = useCallback((action_name: string) => actions.some((a) => a.action_name === action_name), [
    actions,
  ])

  // These assume that the actions are sorted by created_date
  const findFirstAction = useCallback((action_name: string) => actions.find((a) => a.action_name === action_name), [
    actions,
  ])

  const findLastAction = useCallback(
    (action_name: string) => actions.reverse().find((a) => a.action_name === action_name),
    [actions]
  )

  const recordAction = useCallback(
    (action_name: string) => {
      if (!userId) {
        throw new Error('UserActionsContext: recordAction called without a userId')
      }
      recordGenericUserAction(userId, action_name).then(() => {
        setActions((actions) => [...actions, { action_name, created_date: new Date().toISOString() }])
      })
    },
    [userId]
  )

  const deleteAction = useCallback(
    (action_name: string) => {
      if (!userId) {
        throw new Error('UserActionsContext: deleteAction called without a userId')
      }
      deleteGenericUserAction(userId, action_name).then(() => {
        setActions(actions.filter((a) => a.action_name !== action_name))
      })
    },
    [actions, userId]
  )

  // Clear actions when user logs out
  useEffect(() => {
    if (!userId) {
      setActions([])
      setLoaded(false)
    }
  }, [userId])

  const onActionsChange = (actions) => {
    setActions(actions)
    setLoaded(true)
  }

  const endpoint = `users/${userId}/actions/`
  useEndpointPoller(endpoint, POLL_TIME, onActionsChange, { disabled: !userId })

  const value = useMemo(
    () => ({ actions, findFirstAction, findLastAction, checkAction, recordAction, deleteAction, loaded }),
    [actions, findFirstAction, findLastAction, checkAction, recordAction, deleteAction, loaded]
  )
  return <UserActionsCore.Provider value={value}>{children}</UserActionsCore.Provider>
}
