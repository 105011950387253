import { makeStyles } from '@material-ui/core'
import { Grid } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import { memo } from 'react'
import { Theme } from 'types/themes'
import Alert from '../components/Alert'
import PrivacyLinks from '../components/PrivacyLinks'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'

const DISTRIBUTOR_KEY = 'vtac'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  radio: {
    padding: '0px 16px 0px 0px',
    '&.Mui-checked': {
      color: '#4272DD',
    },
  },
}))

const TrustSolarCartSection = () => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'distributor'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<span className={sectionStyles.accordionHeaderTitle}>{translate('3. Supplied by Trust Solar')}</span>}
      // summaryContent={<AccordionCardSummaryContent />}
      content={TrustSolarCartSectionContent}
      lazyRender={true}
    />
  )
}

const TrustSolarCartSectionContent = () => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()
  return (
    <Grid container style={{ gap: '24px', padding: '8px 8px' }}>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <Alert
          title={translate('What’s next after sending my order?')}
          subtitle={translate(
            'Once sent, your order will be transferred to the HDMs portal for payment processing and final shipping confirmation. You will then receive a HDM order reference ID for tracking purposes.'
          )}
          severity="info"
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Delivery Method')}</span>
        <span style={{ fontSize: '14px', color: COLOR_PALETTE.grey5 }}>
          {translate(
            'Your delivery options will be confirmed by Trust Solar staff members once order is confirmed and payment is received.'
          )}{' '}
        </span>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        {/* <DeliveryInstruction /> */}
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        {/* <PartsList distributorKey={DISTRIBUTOR_KEY} /> */}
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <PrivacyLinks
          distributor={'Trust Solar'}
          policyLink={
            'https://trustsolar.co.uk/privacy-policy/?_gl=1*1utq274*_up*MQ..*_ga*NDI0NjM3NjI4LjE3MzI4MzI2NjE.*_ga_7JRCWHECV1*MTczMjgzMjY1NS4xLjAuMTczMjgzMjY1NS4wLjAuNzc0MDQ2ODc.'
          }
          conditionLink={
            'https://trustsolar.co.uk/terms-conditions/?_gl=1*75ts4b*_up*MQ..*_ga*NDI0NjM3NjI4LjE3MzI4MzI2NjE.*_ga_7JRCWHECV1*MTczMjgzMjY1NS4xLjEuMTczMjgzMjY2NS4wLjAuNzc0MDQ2ODc.'
          }
        />
      </Grid>
    </Grid>
  )
}

export default memo(TrustSolarCartSection)
