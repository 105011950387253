import { FC } from 'react'
import { BaseTheme } from '../theme/BaseTheme'

// Need to use this version of ThemeProvider to support makeStyles
import { ThemeProvider as ThemeProvider2 } from '@mui/styles'

import { ThemeProvider } from '@mui/material'

// Wrapper on <ThemeProvider> for opensolar-ui
// At some point this will handle theme switching
export const ThemeContext: FC = ({ children }) => {
  const theme = BaseTheme
  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider2 theme={theme}>{children}</ThemeProvider2>
    </ThemeProvider>
  )
}
