import { default as createMuiTheme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

export default function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
  })
}

declare module '@material-ui/core/styles/createMuiTheme' {
  //Augmenting theme property in material UI
  interface Theme {
    themeColorDark: string
    themeColor: string
    headerFontColor: string
    infoIconColor: string
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    // themeColorDark: string
  }
}
