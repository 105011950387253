import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

export const useStudioStyles = makeOpenSolarStyles((theme) => ({
  fieldsContainer: {
    marginTop: -10,
    marginBottom: 10,
  },
  fieldsRow: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: 20,
    gap: '0px 15px',
    justifyContent: 'space-between',
  },
  simpleRadioButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  warningIcon: {
    width: 20,
    height: 20,
    color: theme.alertIcon_warning,
    marginRight: 5,
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: theme.alertIcon_info,
    marginRight: 5,
  },
  iconButton: {
    padding: 10,
    marginRight: 20,
    backgroundColor: theme.greyLight1,
  },
  rackingSelectorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0px 10px',
  },
  rackingSelectorOpt: {
    width: 70,
    flexGrow: 1,
    height: 70,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.greyLight2,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  rackingSelectorOptActive: {
    borderColor: theme.blue,
  },
  rackingSelectorOptLabel: {
    fontSize: 12,
    marginTop: -5,
  },
}))
