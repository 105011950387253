import { PreQualifiedMessage, SunlightPrequalLinkAction, SunlightPrequalPrimaryAction } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type { CheckoutActionCallback, CheckoutActionConfig, ProposalDataType } from '../../types'
import type { PrequalType } from '../../types/CheckoutElementsDataTypes'
import LoadingMessage from './LoadingMessage'

const SunlightPrequalify = ({
  config,
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  config: CheckoutActionConfig
  onAction: CheckoutActionCallback
  elementData: PrequalType
  translate: Translate
  proposalData: ProposalDataType
  options?: {
    isLoading?: boolean
  }
}) => {
  const handleAction = async () => {
    await onAction({ elementData, proposalData })
  }

  const { actionData, isPrimaryAction } = elementData
  if (actionData.status_code === 'complete') {
    return <PreQualifiedMessage textColor={proposalData.myeStyles.textColor} />
  } else if (actionData.status_code === 'available') {
    return isPrimaryAction ? (
      <SunlightPrequalPrimaryAction
        label={translate('Get Pre-Qualified Instantly')}
        disabled={false}
        logoUrl={`${config.hostname_api}/images/logo-sunlight-financial.png`}
        loadingMessage={<LoadingMessage translate={translate} />}
        onAction={handleAction}
      />
    ) : (
      <SunlightPrequalLinkAction
        label={translate('Get Pre-Qualified Instantly')}
        disabled={false}
        logoUrl={`${config.hostname_api}/images/logo-sunlight-financial.png`}
        loadingMessage={<LoadingMessage translate={translate} />}
        onAction={handleAction}
        textColor={proposalData.myeStyles.highlightColor}
        description={actionData.status_description}
      />
    )
  } else {
    return null
  }
}

export default SunlightPrequalify
