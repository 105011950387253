import inflection from 'inflection'

export const syncValidate = (values, translate) => {
  const errors = {}
  return errors
}

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const required = (value) => (value ? undefined : 'This field is required.')

export const positiveOrZero = (value) =>
  value === undefined || (!isNaN(value) && value >= 0) ? undefined : 'Value should be greater than or equal to 0.'

export const requiredOrZero = (value) => (value || value === '0' || value === 0 ? undefined : 'This field is required.')

export const emailFormat = (value) => {
  const emailRegex = /^[-a-z0-9A-Z~!$%^&*_=+}{'"?]+(\.[-a-z0-9A-Z~!$%^&*_=+}{'"?]+)*@([a-z0-9A-Z_][-a-z0-9A-Z_]*(\.[-a-z0-9A-Z_]+)*\.((?!web)[a-zA-Z]{1,})|(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/
  if (value && !emailRegex.test(value)) return 'Invalid email address.'
}

export const requiredPositiveOrZero = composeValidators(requiredOrZero, positiveOrZero)

export const maxWholeDigits = (maxDigits) => {
  return (value) => {
    if (value === undefined) return
    if (value.toString().replace('.', '').length > maxDigits)
      return `Value should have at most ${maxDigits} ${inflection.inflect('digit', maxDigits)}.`
  }
}

export const maxDecimalPlaces = (maxDecimalPlaces) => {
  return (value) => {
    if (value === undefined) return
    const decimalPlaces = value.toString().split('.')[1]?.length || 0
    if (decimalPlaces > maxDecimalPlaces)
      return `Value should have at most ${maxDecimalPlaces} decimal ${inflection.inflect('place', maxDecimalPlaces)}.`
  }
}

export const maxDigits = (maxDigitsValue, maxDecimalPlacesValue) => {
  if (!maxDigitsValue || !maxDecimalPlacesValue || maxDecimalPlacesValue > maxDigitsValue) {
    console.error('maxDigits validator has been configured incorrectly.')
    return
  }
  return (value) => {
    if (!value) return

    const maxWholeDigitsError = maxWholeDigits(maxDigitsValue)(value)
    if (maxWholeDigitsError) return maxWholeDigitsError

    const maxDecimalPlacesError = maxDecimalPlaces(maxDecimalPlacesValue)(value)
    if (maxDecimalPlacesError) return maxDecimalPlacesError

    const wholeDigits = value.toString().split('.')[0].length
    const decimalDigits = value.toString().split('.')[1]?.length
    const totalAllowedwholeDigits = maxDigitsValue - maxDecimalPlacesValue
    if (decimalDigits && wholeDigits > totalAllowedwholeDigits) {
      return `Ensure that there are no more than ${totalAllowedwholeDigits} ${inflection.inflect(
        'digit',
        totalAllowedwholeDigits
      )} before the decimal point.`
    }
  }
}

export const validRange = (min = 0, max = 100, required = false, opts) => {
  return (value) => {
    if (value === undefined) {
      if (required) return 'This field is required.'
      else return
    }

    if (value < min || value > max) {
      const fieldName = window.translate(opts?.fieldName || 'Value')
      return window.translate(`%{fieldName} must be between %{min} and %{max} (inclusive).`, { min, max, fieldName })
    }
  }
}
