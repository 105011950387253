export interface HighlightConfig {
  title: string
  description: string
  cta: {
    label: string
    type: 'url' | 'file'
    url?: string
    file: any
  }
  enableCountdown: boolean
  countdownDate?: string
  fullWidth: boolean
  whiteText: boolean
  backgroundUrl?: string | null
}

export interface BlogConfig {
  title: string
  url: string
  author: string
  image: string
  publish_date: string
}

export interface ContentsConfig {
  elements: ContentBlock[]
  assets: {
    cover_background: string
    highlights: (string | null)[]
  }
}

export interface ExpoStand {
  id: number
  title: string
  description: string
  locale: string
  contents: ContentsConfig
  tags: string[]
  countries_include: string
  countries_exclude: string
  brand: {
    name: string
    logo_contents: any
  }
}

export interface ContentBlock {
  contentType: string
  enabled: boolean
  data?: any
}

export interface StandData {
  org: {
    id: number
    name: string
    premium_expo: boolean
    logo_public_url: string | null
  }
  expoStand: ExpoStand
}

export type ConfigType = {
  label: string
  toggleEnabled: boolean
  reorderable: boolean
  required: boolean
  premium: boolean
  navLabel?: string
}

export const COMPONENT_CONFIG: { [id: string]: ConfigType } = {
  navbar: {
    label: 'Navbar',
    toggleEnabled: false,
    reorderable: false,
    required: true,
    premium: false,
  },
  cover: {
    label: 'Cover Image',
    toggleEnabled: false,
    reorderable: false,
    required: false,
    premium: true,
  },
  our_story: {
    label: 'Our Story',
    toggleEnabled: false,
    reorderable: false,
    required: true,
    navLabel: 'Home',
    premium: false,
  },

  highlights: {
    label: 'Highlights',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  stats: {
    label: 'Why Us',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    navLabel: 'Stats',
    premium: true,
  },
  // socials: {
  //   label: 'Socials',
  //   toggleEnabled: true,
  //   reorderable: true,
  //   required: false,
  //   premium: true,
  // },
  videos: {
    label: 'Videos',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  blogs: {
    label: 'Blogs',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  resources: {
    label: 'Resources',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  custom_content_1: {
    label: 'Custom Content 1',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  custom_content_2: {
    label: 'Custom Content 2',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  custom_content_3: {
    label: 'Custom Content 3',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    premium: true,
  },
  featured_products: {
    label: 'Featured Products (Hardware)',
    toggleEnabled: true,
    reorderable: true,
    required: false,
    navLabel: 'Shop',
    premium: true,
  },
  chat: {
    label: 'Chat',
    toggleEnabled: true,
    reorderable: false,
    required: false,
    premium: true,
  },
}
