import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import classNames from 'classnames'
import { TextField } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useCallback } from 'react'

const useStyles = makeStyles(
  {
    clearIcon: {
      height: 16,
      width: 0,
    },
    visibleClearIcon: {
      width: 16,
    },
    clearButton: {
      height: 24,
      width: 24,
      padding: 0,
    },
    selectAdornment: {
      position: 'absolute',
      right: 24,
    },
    inputAdornedEnd: {
      paddingRight: 0,
    },
  },
  { name: 'RaResettableTextField' }
)

const handleMouseDownClearButton = (event) => {
  event.preventDefault()
}

type Props = TextFieldProps & {
  classes?: object
  clearAlwaysVisible?: boolean
  disabled?: boolean
  InputProps?: TextFieldProps['InputProps']
  onBlur?: TextFieldProps['onBlur']
  onChange: (value: '') => void
  onFocus?: TextFieldProps['onFocus']
  resettable?: boolean
  value: any
  select?: boolean
}

/**
 * An override of the default Material-UI TextField which is resettable
 */
function ResettableTextField(props: Props) {
  const {
    classes: classesOverride,
    clearAlwaysVisible,
    InputProps,
    value,
    resettable,
    disabled,
    margin = 'dense',
    variant = 'standard',
    ...rest
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const { onChange, onFocus, onBlur } = props
  const handleClickClearButton = useCallback(
    (event) => {
      event.preventDefault()
      onChange('')
    },
    [onChange]
  )

  const { clearButton, clearIcon, inputAdornedEnd, selectAdornment, visibleClearIcon, ...restClasses } = classes

  const { endAdornment, ...InputPropsWithoutEndAdornment } = InputProps || {}

  if (clearAlwaysVisible && endAdornment) {
    throw new Error('ResettableTextField cannot display both an endAdornment and a clear button always visible')
  }

  const getEndAdornment = () => {
    if (!resettable) {
      return endAdornment
    } else if (!value) {
      if (clearAlwaysVisible) {
        // show clear button, inactive
        return (
          <InputAdornment
            position="end"
            classes={{
              root: props.select ? selectAdornment : undefined,
            }}
          >
            <IconButton
              className={clearButton}
              aria-label={translate('ra.action.clear_input_value')}
              title={translate('ra.action.clear_input_value')}
              disableRipple
              disabled={true}
            >
              <ClearIcon className={classNames(clearIcon, visibleClearIcon)} />
            </IconButton>
          </InputAdornment>
        )
      } else {
        if (endAdornment) {
          return endAdornment
        } else {
          // show spacer
          return (
            <InputAdornment
              position="end"
              classes={{
                root: props.select ? selectAdornment : undefined,
              }}
            >
              <span className={clearButton}>&nbsp;</span>
            </InputAdornment>
          )
        }
      }
    } else {
      // show clear
      return (
        <InputAdornment
          position="end"
          classes={{
            root: props.select ? selectAdornment : undefined,
          }}
        >
          <IconButton
            className={clearButton}
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            disableRipple
            onClick={handleClickClearButton}
            onMouseDown={handleMouseDownClearButton}
            disabled={disabled}
          >
            <ClearIcon
              className={classNames(clearIcon, {
                [visibleClearIcon]: clearAlwaysVisible || value,
              })}
            />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    //@ts-ignore
    <TextField
      classes={restClasses}
      value={value}
      InputProps={{
        classes: props.select && variant === 'filled' ? { adornedEnd: inputAdornedEnd } : {},
        endAdornment: getEndAdornment(),
        ...InputPropsWithoutEndAdornment,
      }}
      disabled={disabled}
      variant={variant}
      margin={margin}
      {...rest}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default ResettableTextField
