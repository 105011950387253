export type MfaErrorStatus =
  | 'mfa_accepted'
  | 'mfa_required'
  | 'mfa_rejected'
  | 'mfa_not_checked'
  | 'mfa_required_but_not_configured'

export type LoginErrorType = {
  body?: {
    mfa_status?: MfaErrorStatus
    has_sms_device: boolean
    has_totp_device: boolean
    has_recovery_codes: boolean
  }
}

export const getMfaStatusFromLoginError = (
  err: LoginErrorType | undefined
): 'check_required' | 'config_required' | undefined => {
  let result = undefined
  if (!err || !err.body || !err.body.mfa_status) return result
  else if (err.body.has_sms_device || err.body.has_totp_device || err.body.mfa_status === 'mfa_required')
    return 'check_required'
  else if (err.body.mfa_status === 'mfa_required_but_not_configured') return 'config_required'
  else return result
}
