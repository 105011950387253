import { orgSelectors } from 'ducks/orgs'
import { Grid, MenuItem, Select, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import AccountingTaxRateSelect from './AccountingTaxRateSelect'
import { AccountingTaxRateType, LedgerAccount, TaxRateSelection } from './types'
import { getAccountingTaxRates, getDefaultInvoiceTaxRates } from './utils'

type PropTypes = {
  onChange: (value: TaxRateSelection) => void
  taxRateSelection: TaxRateSelection
  ledgerAccounts: LedgerAccount[]
  taxRate: number
  taxRatesLoading: boolean
}

const TableCell = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const AccountingTaxRateSelectionRow: React.FC<PropTypes> = (props) => {
  const [defaultTaxRates, setDefaultTaxRates] = useState<number[]>([])
  const [availableTaxRates, setAvailableTaxRates] = useState<AccountingTaxRateType[]>([])
  const translate = useTranslate()
  const orgCountryIso2 = useSelector(orgSelectors.getOrgIso2)
  const orgId = useSelector(orgSelectors.getOrg)?.id

  useEffect(() => {
    if (orgCountryIso2 && !defaultTaxRates?.length) {
      getDefaultInvoiceTaxRates(orgCountryIso2)
        .then((taxRates) => {
          setDefaultTaxRates(taxRates)
        })
        .catch((res) => {
          setDefaultTaxRates([])
        })
    }
  }, [orgCountryIso2, defaultTaxRates])

  useEffect(() => {
    if (orgId && !availableTaxRates?.length) {
      getAccountingTaxRates(orgId)
        .then((res) => {
          setAvailableTaxRates(res)
        })
        .catch((err) => {
          setAvailableTaxRates([])
        })
    }
  }, [orgId, availableTaxRates])

  const taxRateSelectionRowValues = useMemo(() => {
    return props.taxRateSelection?.[`${parseFloat(`${props.taxRate}`)}`]
  }, [])

  const handleTaxRecordChange = (taxRate: number, taxRateId: string, ledgerType: 'product' | 'payment') => {
    const newVal = { ...props.taxRateSelection }
    if (!newVal[`${props.taxRate}`] || typeof newVal[`${props.taxRate}`] === 'string') {
      newVal[`${props.taxRate}`] = {
        product_ledger_account_id: '',
        payment_ledger_account_id: '',
        product_tax_rate_id: '',
        payment_tax_rate_id: '',
      }
    }
    if (ledgerType === 'product') {
      newVal[`${props.taxRate}`].product_tax_rate_id = taxRateId
    } else if (ledgerType === 'payment') {
      newVal[`${props.taxRate}`].payment_tax_rate_id = taxRateId
    }
    props.onChange(newVal)
  }

  const handleLedgerChange = (e, ledgerType: 'product' | 'payment') => {
    const newVal = { ...props.taxRateSelection }
    if (!newVal[`${props.taxRate}`]) {
      newVal[`${props.taxRate}`] = {
        product_ledger_account_id: '',
        payment_ledger_account_id: '',
        product_tax_rate_id: '',
        payment_tax_rate_id: '',
      }
    }
    if (ledgerType === 'product') {
      newVal[`${props.taxRate}`].product_ledger_account_id = e.target.value
    } else if (ledgerType === 'payment') {
      newVal[`${props.taxRate}`].payment_ledger_account_id = e.target.value
    }
    props.onChange(newVal)
  }

  return (
    <>
      <TableCell xs={2}>{props.taxRate}%</TableCell>
      <TableCell xs={5}>
        <Select
          value={taxRateSelectionRowValues?.product_ledger_account_id}
          onChange={(e) => handleLedgerChange(e, 'product')}
          displayEmpty
          variant="outlined"
          fullWidth
        >
          <MenuItem value="" disabled>
            {translate('Select a Payment Ledger Account')}
          </MenuItem>
          {props.ledgerAccounts.map((ledger) => (
            <MenuItem key={ledger.id} value={ledger.id}>
              {ledger.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell xs={5}>
        <AccountingTaxRateSelect
          availableTaxRates={availableTaxRates}
          taxRate={props.taxRate}
          onChange={(taxRateId: string) => handleTaxRecordChange(props.taxRate, taxRateId, 'product')}
          value={taxRateSelectionRowValues?.product_tax_rate_id}
          taxRatesLoading={props.taxRatesLoading}
        />
      </TableCell>

      {/* <TableCell xs={3}>
        <Select
          value={taxRateSelectionRowValues?.payment_ledger_account_id}
          onChange={(e) => handleLedgerChange(e, 'payment')}
          displayEmpty
          variant="outlined"
          fullWidth
        >
          <MenuItem value="" disabled>
            {translate('Select a Payment Ledger Account')}
          </MenuItem>
          {props.ledgerAccounts.map((ledger) => (
            <MenuItem key={ledger.id} value={ledger.id}>
              {ledger.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell xs={2}>
        <AccountingTaxRateSelect
          availableTaxRates={availableTaxRates}
          taxRate={props.taxRate}
          onChange={(taxRateId: string) => handleTaxRecordChange(props.taxRate, taxRateId, 'payment')}
          value={taxRateSelectionRowValues?.payment_tax_rate_id}
          taxRatesLoading={props.taxRatesLoading}
        />
      </TableCell> */}
    </>
  )
}

export default AccountingTaxRateSelectionRow
