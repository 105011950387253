import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CheckoutFinancingDialog as CheckoutFinancingDialogUi } from 'opensolar-ui'
import { LenderType } from 'opensolar-ui/src/checkout/checkoutFinancing/types'
import { getExpectedMilestones } from 'pages/cashFlow/utils'
import { useNotify, useTranslate } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import restClient from 'restClient'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { currencySymbolForCountry } from 'util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  open: boolean
  onClose: () => void
  orgId: number
  projectId: number
  countryIso2: string
  systemUuid: string
  paymentOptionId: number
  expectedMilestonePayments?: ExpectedMilestonePaymentType[] | undefined
  amountDue: number
}

const CheckoutFinancingDialog = (props: PropTypes) => {
  const {
    open,
    onClose,
    projectId,
    systemUuid,
    paymentOptionId,
    orgId,
    expectedMilestonePayments: expectedMilestonePaymentsProp,
    amountDue,
    countryIso2,
  } = props
  const notify = useNotify()
  const translate = useTranslate()
  const currencySymbol = currencySymbolForCountry(countryIso2)

  const [loanAmount, setLoanAmount] = useState(amountDue)
  const [isLoading, setIsLoading] = useState(true)
  const [lenders, setLenders] = useState<LenderType[]>([])
  const [expectedMilestonePayments, setExpectedMilestonePayments] = useState<ExpectedMilestonePaymentType[]>([])

  const fetchExpectedMilestonePayments = useCallback(() => {
    if (orgId && systemUuid && paymentOptionId) {
      if (expectedMilestonePaymentsProp) {
        setExpectedMilestonePayments(expectedMilestonePaymentsProp)
      } else {
        // Fetch expected milestones if no expected milestones are provided
        getExpectedMilestones(orgId, projectId, systemUuid, paymentOptionId).then((res) => {
          setExpectedMilestonePayments(res.data.expected_milestone_payments)
          setLoanAmount(res.data.total_amount_payable - res.data.total_amount_paid)
        })
      }
    }
  }, [orgId, projectId, systemUuid, paymentOptionId, expectedMilestonePaymentsProp])

  const fetchAvailableLenders = useCallback(() => {
    setIsLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${projectId}/checkout_financing/lenders/?payment_option_id=${paymentOptionId}`,
    })
      .then((response) => {
        setLenders(response.data?.lenders)
      })
      .catch((err) => {
        // TODO: Add error handling
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [paymentOptionId, orgId, projectId])

  useEffect(() => {
    if (orgId && projectId && systemUuid && paymentOptionId) {
      fetchAvailableLenders()
      fetchExpectedMilestonePayments()
    }
  }, [orgId, projectId, systemUuid, paymentOptionId])

  const handleApplyNowButton = async (lender: LenderType): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (loanAmount === 0) {
        notify('Please edit the loan amount or select milestones to proceed.')
        resolve()
      }

      logAmplitudeEvent('checkout_finance_dialog_apply_clicked', { org_id: orgId, project_id: projectId, lender: lender.title })
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/checkout_financing/affiliate_link/?finance_cta_id=${lender.id}`,
        data: {
          loanAmount: loanAmount,
          paymentOptionId: paymentOptionId,
        },
      })
        .then((response) => {
          const link = document.createElement('a')
          link.href = response.data.affiliateLink
          link.target = '_blank'
          link.rel = 'noopener noreferrer sponsored'
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((err) => {
          if (err?.body?.message) {
            notify(err?.body?.message, 'warning')
          } else {
            notify('Failed to launch loan application url', 'warning')
          }
        })
        .finally(() => {
          resolve()
        })
    })
  }

  const handleEditLoanAmount = (field) => {
    logAmplitudeEvent('checkout_finance_dialog_loan_amount_edited', { org_id: orgId, project_id: projectId, edited_field: field})
  }

  return (
    <CheckoutFinancingDialogUi
      open={open}
      onClose={onClose}
      actionData={{
        org_id: orgId,
        project_id: projectId,
        payment_option_id: paymentOptionId,
        system_uuid: systemUuid,
        pricing: {
          system_price_including_tax: loanAmount,
        },
      }}
      onApply={handleApplyNowButton}
      onEditLoanAmount={handleEditLoanAmount}
      currencySymbol={currencySymbol}
      expectedMilestonePayments={expectedMilestonePayments}
      isLoading={isLoading}
      lenders={lenders}
      translate={translate}
    />
  )
}

export default CheckoutFinancingDialog
