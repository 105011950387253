import { Grid, Grow } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { FC, useEffect, useState } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useBusinessIdentifierLabel } from 'resources/wallet/useBusinessIdentifierLabel'
import { useIdentifierValidator } from './util'

type Props = {
  validate?: boolean // This should be false in the main project form as we shouldn't block save ever
}

export const BusinessNameAndIdentifierFields: FC<Props> = ({ validate }) => {
  const formState = useFormState()
  const country_iso2 = formState.values.country_iso2
  const translate = useTranslate()
  const { businessIdLabelName, businessIdHelperText } = useBusinessIdentifierLabel(country_iso2)

  const [wasResidential, setWasResidential] = useState<boolean>(formState?.values?.is_residential)

  useEffect(() => {
    if (formState?.values?.is_residential && !wasResidential) setWasResidential(true)
  }, [formState?.values?.is_residential, wasResidential])

  // real validation is a bit strict (first 2 letters and the numbers can't be random)
  // not sure if you want to implement it or if it would dscourage users
  // const regex = /^((AC|ZC|FC|GE|LP|OC|SE|SA|SZ|SF|GS|SL|SO|SC|ES|NA|NZ|NF|GN|NL|NC|R0|NI|EN|\d{2}|SG|FE)\d{5}(\d|C|R))|((RS|SO)\d{3}(\d{3}|\d{2}[WSRCZF]|\d(FI|RS|SA|IP|US|EN|AS)|CUS))|((NI|SL)\d{5}[\dA])|(OC(([\dP]{5}[CWERTB])|([\dP]{4}(OC|CU))))$/
  // if (!regex.test(value.toUpperCase())) {
  //   setIsValid(businessIdHelperText
  // }
  const identifierValidator = useIdentifierValidator(country_iso2, translate, validate)

  return (
    <DependentInput dependsOn="is_residential" resolve={(value: boolean) => value === false}>
      <Grow in={true} timeout={wasResidential ? 300 : 0}>
        <Grid item xs={6}>
          <CustomField
            resource="projects"
            name="business_name"
            source="business_name"
            component={TextInput}
            fullWidth
            style={{ width: '100%' }}
          />
        </Grid>
      </Grow>
      {['NZ', 'GB', 'AU'].includes(formState.values.country_iso2) && (
        <Grow in={true} timeout={wasResidential ? 300 : 0}>
          <Grid item xs={6}>
            <CustomField
              resource="projects"
              label={businessIdLabelName}
              name="business_identifier"
              source="business_identifier"
              helperText={businessIdHelperText}
              component={TextInput}
              fullWidth
              style={{ width: '100%' }}
              validate={identifierValidator}
              inputType="number"
            />
          </Grid>
        </Grow>
      )}
    </DependentInput>
  )
}
