type AppDomain = 'spa' | 'api'

export function getOsUrl(type: AppDomain, env: string): string | undefined {
  const host = getOsHost(type, env)
  if (!host) return undefined

  if (host.includes('localhost')) {
    return `http://${host}`
  } else {
    return `https://${host}`
  }
}

const knownEnvs = ['local', 'sunrise', 'dev', 'dock', 'sdkqa', 'staging', 'production'] as const

// Used by the SDK script to detect where it is hosted
export function detectEnvFromHost(domain: AppDomain, host: string): string | undefined {
  for (let env of knownEnvs) {
    if (host === getOsHost(domain, env)) return env
  }
}

export function getOsHost(type: AppDomain, env: string): string | undefined {
  switch (type) {
    case 'spa':
      switch (env) {
        case 'local':
          return 'localhost:3000'
        case 'sunrise':
          return 'localhost:31000'
        case 'dev':
          return 'dev.sunsuite.com.au'
        case 'dock':
          return 'dock.sunsuite.com.au'
        case 'sdkqa':
          return 'sdkqa.sunsuite.com.au'
        case 'staging':
          return 'staging4.opensolar.com'
        case 'production':
          return 'app.opensolar.com'
      }
      break

    case 'api':
      switch (env) {
        case 'local':
          return 'localhost:8000'
        case 'sunrise':
          return 'localhost:30000'
        case 'dev':
          return 'apidev.sunsuite.com.au'
        case 'dock':
          // Note this is non-standard! stage is "dock2" but environment is still "dock"
          return 'apidock2.sunsuite.com.au'
        case 'sdkqa':
          return 'apisdkqa-docker.sunsuite.com.au'
        case 'staging':
          return 'apistaging4.opensolar.com'
        case 'production':
          return 'api.opensolar.com'
      }

      break
  }
}
