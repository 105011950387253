import { useTranslate } from 'ra-core'
import { BrandCreate, BrandEdit, BrandList } from './Brands'

const BrandTitle = ({ record }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate('Brand Registry')} - {record && record.name ? `"${record.name}"` : ''}
    </span>
  )
}

export default {
  list: BrandList,
  create: BrandCreate,
  edit: BrandEdit,
  options: {
    create: {
      title: 'Brand Registry',
      subtitle: null,
      breadCrumb: 'Register Brand',
    },
    list: {
      title: 'Brand Registry',
      subtitle: null,
      breadCrumb: 'Brand Registry',
    },
    edit: {
      title: <BrandTitle />,
      subtitle: null,
      breadCrumb: 'Edit Brand',
    },
  },
}
