export const SHOW_NOTIFICATION = 'RA/SHOW_NOTIFICATION'

export type NotificationType = 'info' | 'warning' | 'error' | 'success'

export interface NotificationOptions {
  // The duration in milliseconds the notification is shown
  autoHideDuration?: number
  // Arguments used to translate the message
  messageArgs?: any
  // If true, the notification shows an Undo button
  undoable?: boolean

  buttons?: ButtonInfo

  smart_count?: number

  // Enables better reporting in sentry.io
  error?: Error | unknown

  detail?: string
}

export interface ButtonInfo {
  label: string
  variant?: 'text' | 'outlined' | 'contained'
  icon?: any
  action: () => any
  avoidClose?: true // Won't auto-close notification of click if true
}

export interface Notification extends NotificationOptions {
  readonly message: string
  readonly type: NotificationType
}

export interface ShowNotificationAction {
  readonly type: typeof SHOW_NOTIFICATION
  readonly payload: Notification
}

/**
 * Shows a snackbar/toast notification on the screen
 *
 * @see {@link https://material-ui.com/api/snackbar/|Material ui snackbar component}
 * @see {@link https://material.io/guidelines/components/snackbars-toasts.html|Material ui reference document on snackbar}
 */
export const showNotification = (
  // A translatable label or text to display on notification
  message: string,
  // The type of the notification
  type: NotificationType = 'info',
  // Specify additional parameters of notification
  options: NotificationOptions = {}
): ShowNotificationAction => ({
  type: SHOW_NOTIFICATION,
  payload: {
    type,
    message,
    ...options,
  },
})

export const HIDE_NOTIFICATION = 'RA/HIDE_NOTIFICATION'

export interface HideNotificationAction {
  readonly type: typeof HIDE_NOTIFICATION
}

export const hideNotification = (): HideNotificationAction => ({
  type: HIDE_NOTIFICATION,
})
