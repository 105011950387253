import { FC } from 'react'

/**
 * * Icon Component for: layers-02
 */
type LayersIconProps = {
  size?: number
  color?: string
}
export const LayersIcon: FC<LayersIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M16.4004 9.17426L20.6004 11.3271L11.0004 16.2478L1.40039 11.3271L5.67715 9.13491M11.0004 1.75195L20.6004 6.6727L11.0004 11.5934L1.40039 6.6727L11.0004 1.75195Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
