import { Divider, makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { isEmpty } from 'lodash'
import { Button, Chip, Grid, TruckOutlineIcon } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { HardwareComponentType, POSTORDER } from 'projectSections/sections/hardware'
import { useNotify, useTranslate } from 'ra-core'
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useSolarOutletService from 'services/outlet/useSolarOutletService'
import { Theme } from 'types/themes'
import { formatCurrency } from 'util/misc'
import OrderSummaryItem from './components/OrderSummaryItem'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING, SOURCE_CONTEXT_MAPPING } from './constants'
import {
  getShippingFeeCurrencySymbol,
  validateCityPlumbingData,
  validateHdmData,
  validateOutletData,
  validateSegenData,
  validateVtacData,
} from './helper'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from './styles'
import { CheckoutSourceType, DistributorDataType, ProcessDistributorOrderResponseType } from './types'

type Props = {
  checkoutSource: CheckoutSourceType
  updateHardwareComponent?: (component: HardwareComponentType) => void
  distributorData?: DistributorDataType
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  rowWrapper: {
    alignItems: 'center',
    columnGap: '12px',
  },
  content: {
    fontSize: '16px',
    color: COLOR_PALETTE.black,
    fontWeight: 400,
  },
  contentWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  font18: {
    fontSize: '18px',
    color: COLOR_PALETTE.black,
    fontWeight: 600,
  },
  font16: {
    fontSize: '16px',
    color: COLOR_PALETTE.black,
  },
  discount: {
    fontSize: '16px',
    color: COLOR_PALETTE.green,
  },
  gap: {
    gap: '8px',
  },
  distributorDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '8px',
    width: '100%',
  },
  chipWrapper: {
    padding: '0px 40px',
  },
  chipLabel: {
    fontSize: '12px',
    fontWeight: 500,
  },
}))

const shippingInfoHasDeliveryDate = (shippingInfo) => {
  if (!shippingInfo) return false

  // If it doesn't have deliveryDate or dispatchDate, it's probably hdm or outlet which doesn't require delivery date
  if (!Object.hasOwn(shippingInfo, 'deliveryDate') && !Object.hasOwn(shippingInfo, 'dispatchDate')) return true

  return !!shippingInfo?.deliveryDate || !!shippingInfo?.dispatchDate
}

const OrderSummarySection: FC<Props> = ({ checkoutSource, updateHardwareComponent }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const history = useHistory()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const solarOutletService = useSolarOutletService()
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
  const isAU = orgCountry === 'AU'
  const [loading, setLoading] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [discounts, setDiscounts] = useState<number[]>([])
  const keys = Object.keys(distributorData)
  const firstKey = keys[0]
  const hasDeliveryDate = shippingInfoHasDeliveryDate(distributorData[firstKey]?.shippingInfo)

  const total = useMemo<number>(() => {
    let currentTotal: number = Object.values(distributorData).reduce((accumulator: number, item: any) => {
      const shippingFee = ['TBC', 'FREE'].includes(item?.shippingInfo?.fee) ? 0 : Number(item?.shippingInfo?.fee || 0)
      return accumulator + item?.afterDiscountTotal + shippingFee
    }, 0)

    discounts.forEach((item: number) => {
      if (checkoutSource === 'project_hardware') {
        return (currentTotal -= item)
      }
      return currentTotal
    })
    return currentTotal
  }, [discounts, distributorData])

  const shippingFee = useMemo(() => {
    const shippingFee = Object.keys(distributorData).map((key) => {
      return (
        getShippingFeeCurrencySymbol(distributorData[key]['shippingInfo']['fee'], currencySymbol) +
        distributorData[key]['shippingInfo']['fee']
      )
    })
    return shippingFee
  }, [distributorData, currencySymbol])

  useEffect(() => {
    for (const key in distributorData) {
      let valid = true

      if (key === 'city_plumbing') {
        valid = validateCityPlumbingData({ ...distributorData[key], ...contactInfo[key] })
      } else if (key === 'segen') {
        valid = validateSegenData({ ...distributorData[key], ...contactInfo[key] })
      } else if (key === 'hdm') {
        valid = validateHdmData({ ...contactInfo[key] })
      } else if (['vtac_uk', 'vtac_pl'].includes(key)) {
        valid = validateVtacData({ ...distributorData[key], ...contactInfo[key] })
      } else if (key === 'outlet') {
        valid = validateOutletData({ ...distributorData[key], ...contactInfo[key] })
      }

      setIsValid(valid)
      if (!valid) {
        break
      }
    }
  }, [distributorData, contactInfo])

  const handleProcessCheckout = async () => {
    setLoading(true)
    let response: ProcessDistributorOrderResponseType[] | undefined = undefined
    if (isAU) {
      const outletData = distributorData['outlet']
      if (outletData?.paymentMethod === 'pay_now') {
        const checkout = await solarOutletService?.checkout(outletData?.items, '3')
        if (checkout?.webUrl) {
          window.location.href = checkout?.webUrl
        } else {
          notify(`${DISTRBUTOR_KEY_TO_NAME_MAPPING['outlet']}: Service not available`, 'error')
        }
        setLoading(false)
        return
      }
    }
    response = await preOrderPresenter?.processCheckout(distributorData, contactInfo, deliveryAddressInfo)
    let hasSuccessfulOrder = false
    response?.forEach((item) => {
      if (!hasSuccessfulOrder && isEmpty(item?.error)) {
        hasSuccessfulOrder = true
      }
      if (!isEmpty(item?.error)) {
        notify(`${DISTRBUTOR_KEY_TO_NAME_MAPPING[item?.distributor]}: ${item?.error?.message}`, 'error')
      }
    })
    if (hasSuccessfulOrder) {
      if (updateHardwareComponent) {
        updateHardwareComponent(POSTORDER)
      } else {
        history.push('/manage_orders/incoming_transfer')
      }
    } else if (!hasSuccessfulOrder) {
      const updatedDistributorData = {}
      response?.forEach((item) => {
        updatedDistributorData[item?.distributor] = {
          ...distributorData[item?.distributor],
          orderId: item?.order_id,
        }
      })
      preOrderPresenter?.updateDistributorData(updatedDistributorData)
    }
    setLoading(false)
  }

  const discountCallback = (value: number) => {
    setDiscounts([...discounts, value])
  }

  const vat = isAU ? 10 : 20
  const tax = total * (vat / 100)
  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={sectionStyles.sectionCardTop}>
        <div className={`${sectionStyles.row} ${classes.rowWrapper}`}>
          <div style={{ marginLeft: '-4px' }}>
            <TruckOutlineIcon height="24" width="24" />
          </div>
          <span className={`${sectionStyles.headerTitle}`}>{translate('Order Summary')}</span>
        </div>
      </div>
      <div className={sectionStyles.sectionCardBottom}>
        <Grid container style={{ gap: '24px' }}>
          {Object.keys(distributorData).map((key) => (
            <OrderSummaryItem
              distributorData={distributorData[key]}
              distributor={key as HardwareSupplierFilterKeyType}
              callback={discountCallback}
              checkoutSource={checkoutSource}
            />
          ))}
          <Grid item xs={12} md={12} className={`${sectionStyles.column} ${classes.gap}`}>
            <Grid container className={classes.gap}>
              <Grid item xs={12} md={12} className={`${sectionStyles.row} ${classes.contentWrapper}`}>
                <span className={classes.font18}>{translate('Total Before VAT')}:</span>
                <span className={classes.font18}>{`${currencySymbol}${formatCurrency(total)}`}</span>
              </Grid>
              <Grid item xs={12} md={12} className={`${sectionStyles.row} ${classes.contentWrapper}`}>
                <span className={classes.font16}>{`VAT (${vat}%)`}</span>
                <span className={classes.font16}>{`${currencySymbol}${formatCurrency(tax)}`}</span>
              </Grid>
              <Grid item xs={12} md={12} className={`${sectionStyles.row} ${classes.contentWrapper}`}>
                <span className={classes.content}>{translate('Shipping & Handling')}</span>
                <span className={classes.font16}>{shippingFee}</span>
              </Grid>
              <Grid item xs={12} md={12} className={sectionStyles.row}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={`${sectionStyles.column} ${classes.gap}`}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleProcessCheckout}
              disabled={!isValid || loading || !hasDeliveryDate}
            >
              {translate('Send order')}
            </Button>
          </Grid>
          <Grid item xs={12} md={12} className={`${sectionStyles.column} ${classes.chipWrapper} ${classes.gap}`}>
            <Chip
              style={{ padding: '20px 12px', background: COLOR_PALETTE.green2, color: 'white' }}
              label={<span className={classes.chipLabel}>{translate('Order now and pay upon dispatch!')}</span>}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default memo(OrderSummarySection)
