import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { default as Mui5Button } from '@mui/material/Button'
import { useState } from 'react'
import { styled } from '../styles/styled'

const StyledLink = styled('div')<{ highlightColor: string }>(({ theme, highlightColor }) => {
  return {
    marginTop: '10px',
    color: highlightColor,
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
  }
})

const Wrapper = styled('div')(({ theme }) => {
  return {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }
})

const ActionWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
  }
})

const DismissButtonWrapper = styled('div')(({ theme }) => {
  return {
    margin: '10px',
  }
})

const StartButtonWrapper = styled('div')(({ theme }) => {
  return {
    margin: '10px',
    position: 'relative',
  }
})

const CircularProgressWrapper = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    left: 'calc(50% - 12px)',
    top: 'calc(50% - 12px)',
  }
})

export const ReapplicationConfirm = ({
  highlightColor,
  onConfirm,
  loading,
  extraMessage,
}: {
  highlightColor: string
  onConfirm(): void
  loading: boolean
  extraMessage?: string
}) => {
  const [showReapplyDialog, setShowReapplyDialog] = useState<boolean>(false)

  return (
    <Wrapper>
      {extraMessage && <div style={{ textAlign: 'center' }}>{extraMessage}</div>}
      <StyledLink highlightColor={highlightColor} onClick={() => setShowReapplyDialog(true)}>
        Want to Re-Apply?
      </StyledLink>
      <Dialog open={showReapplyDialog}>
        <DialogTitle>Re-Applying for Financing</DialogTitle>
        <DialogContent>
          Our records show that you have already applied for this financing product and were previously not approved for
          financing. Before continuing, please confirm that you understand you will be submitting a new application.
        </DialogContent>
        <DialogActions>
          <ActionWrapper>
            <DismissButtonWrapper>
              <Mui5Button onClick={() => setShowReapplyDialog(false)}>{'Dismiss'}</Mui5Button>
            </DismissButtonWrapper>
            <StartButtonWrapper>
              <Mui5Button onClick={onConfirm}>{'I want to start a new application'}</Mui5Button>
              {loading && (
                <CircularProgressWrapper>
                  <CircularProgress size={24} />
                </CircularProgressWrapper>
              )}
            </StartButtonWrapper>
          </ActionWrapper>
        </DialogActions>
      </Dialog>
    </Wrapper>
  )
}
