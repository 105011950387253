import { CircularProgress, TextField } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { Button, Dialog, DialogContent, styled } from 'opensolar-ui'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const DebugButton = styled(Button)({
  marginBottom: '10px',
  marginLeft: '10px',
})

const GetAccountingRecordsButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const orgId = useSelector(orgSelectors.getOrg)?.id

  const [showDebugDialog, setShowDebugDialog] = useState<boolean>(false)
  const [syncJSON, setSyncJSON] = useState<object | null>(null)

  const closeDebugDialog = () => {
    setShowDebugDialog(false)
    setSyncJSON(null)
  }

  const onClick = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/accounting/sync_records/`,
    })
      .then((res) => {
        console.log('res', res)
        setShowDebugDialog(true)
        setSyncJSON(res?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff) return null
  if (isLoading) return <CircularProgress />
  return (
    <>
      <DebugButton variant="contained" onClick={onClick}>
        Debug Accounting Records
      </DebugButton>
      <Dialog open={showDebugDialog} onClose={closeDebugDialog} maxWidth="xl" fullWidth>
        <DialogContent style={{ margin: '32px', position: 'relative' }}>
          <TextField
            value={JSON.stringify(syncJSON, undefined, 4)}
            label="Accounting Records"
            disabled={true}
            fullWidth
            multiline={true}
            rows={30}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default GetAccountingRecordsButton
