import { Link, LinkedInIcon, styled } from 'opensolar-ui'
import { FC } from 'react'
import { CardWrapper } from './styles'

interface PropTypes {
  copy: string
  orgName: string
  time: string
}

const Header = styled('div', { name: 'Header' })({
  display: 'flex',
})

const HeaderImg = styled('div', { name: 'HeaderImg' })({
  borderRadius: 20,
  height: 40,
  width: 40,
  outline: '1px solid black',
})

const PostInfo = styled('div', { name: 'StyledIcon' })({
  marginRight: 'auto',
  marginLeft: 10,
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 500,
})

const TimeSpan = styled('span', { name: 'TimeSpan' })(({ theme }) => ({
  color: theme.palette.grey[600],
  marginTop: 8,
  fontSize: 12,
  fontWeight: 400,
}))

const Content = styled('div', { name: 'Content' })({
  margin: '10px 0',
  lineHeight: '20px',
  maxWidth: 256,
  '& p': {
    marginBottom: 10,
  },
})

const StyledLink = styled(Link, { name: 'StyledLink' })(({ theme }) => ({
  color: theme.palette.grey[600] + '!important',
}))

const PreviewImage = styled('div', { name: 'PreviewImage' })({
  outline: '1px solid black',
  height: 210,
  width: 265,
})

const PostCard: FC<PropTypes> = ({ copy, orgName, time }) => {
  return (
    <CardWrapper>
      <Header>
        <HeaderImg />
        <PostInfo>
          <span>{orgName}</span>
          <TimeSpan>{time}</TimeSpan>
        </PostInfo>
        <LinkedInIcon size={24} />
      </Header>
      <Content>
        <p>{copy}</p>
        <StyledLink href={'https://www.google.com/'} target="_blank">
          Read more
        </StyledLink>
      </Content>
      <PreviewImage />
    </CardWrapper>
  )
}
export default PostCard
