import { InputAdornment } from '@material-ui/core'
import { TextField, TextFieldProps } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { FieldTitle, InputProps, useInput } from 'ra-core'
import { FunctionComponent } from 'react'
import InputHelperText from './InputHelperText'
import sanitizeRestProps from './sanitizeRestProps'

const convertStringToNumber = (value) => {
  const float = parseFloat(value)

  return isNaN(float) ? null : float
}

interface Props {
  step?: string | number
}

/**
 * An Input component for a number
 *
 * @example
 * <NumberInput source="nb_views" />
 *
 * You can customize the `step` props (which defaults to "any")
 * @example
 * <NumberInput source="nb_views" step={1} />
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 */
const NumberInput: FunctionComponent<
  Props & InputProps<TextFieldProps> & Omit<TextFieldProps, 'label' | 'helperText'>
> = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = convertStringToNumber,
  resource,
  source,
  step,
  validate,
  variant = 'standard',
  inputProps: overrideInputProps,
  InputProps: InputPropsOverride,
  startAdornment,
  endAdornment,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'number',
    validate,
    ...rest,
  })

  const adornment = {
    startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
    endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
  }
  const inputProps = { ...overrideInputProps, step }
  const InputProps = { ...adornment, ...InputPropsOverride }

  return (
    <TextField
      id={id}
      {...input}
      variant={variant}
      error={!!(touched && error)}
      helperText={touched && <InputHelperText touched={touched} error={error} helperText={helperText} />}
      label={
        label !== '' &&
        label !== false && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      margin={margin}
      inputProps={inputProps}
      InputProps={InputProps}
      {...options}
      {...sanitizeRestProps(rest)}
    />
  )
}

NumberInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

NumberInput.defaultProps = {
  options: {},
  step: 'any',
  textAlign: 'right',
}

export default NumberInput
