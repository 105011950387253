import { Action } from '../core/action'
import { Value } from '../core/state'

export const current_org = {
  // State
  id: new Value<number | undefined>(undefined),

  // Actions
  reload: new Action(),
} as const
