import { FormControlLabel, InputLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import dayjs from 'dayjs'
import { DateInput } from 'elements/input/DateInput'
import SegenQuotePrompt from 'elements/SegenQuotePrompt'
import { isEmpty } from 'lodash'
import { FormControl, Grid, MenuItem, Select, Typography } from 'opensolar-ui'
import AccordionCardSummaryContent from 'pages/ordering/checkoutv3/preOrder/components/AccordionCardSummaryContent'
import AccordionCardTitle from 'pages/ordering/checkoutv3/preOrder/components/AccordionCartTitle'
import Alert from 'pages/ordering/checkoutv3/preOrder/components/Alert'
import DeliveryInstruction from 'pages/ordering/checkoutv3/preOrder/components/DeliveryInstruction'
import DistributorConnectionAlert from 'pages/ordering/checkoutv3/preOrder/components/DistributorConnectionAlert'
import DistributorDeliveryDetail from 'pages/ordering/checkoutv3/preOrder/components/DistributorDeliveryDetail'
import { ErrorHelperText } from 'pages/ordering/checkoutv3/preOrder/components/ErrorHelperText'
import PartsList from 'pages/ordering/checkoutv3/preOrder/components/PartsList'
import PrivacyLinks from 'pages/ordering/checkoutv3/preOrder/components/PrivacyLinks'
import { SOURCE_CONTEXT_MAPPING } from 'pages/ordering/checkoutv3/preOrder/constants'
import { createOrderData, getSegenDeliveryCostFromQuoteItems } from 'pages/ordering/checkoutv3/preOrder/helper'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from 'pages/ordering/checkoutv3/preOrder/styles'
import { CartSectionContentPropType, CartSectionPropType } from 'pages/ordering/checkoutv3/preOrder/types'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import useGetOrderDataByProject from 'projectSections/sections/manage3/hardware/hooks/useGetOrderDataByProject'
import { useTranslate } from 'ra-core'
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { getMsalSegenSingletonInstance } from 'services/identity/getMsalSingletonInstance'
import SegenAuthenticationService from 'services/segen/SegenAuthenticationService'
import { AccountContact, SegenQuoteResult } from 'services/segen/type'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import useSegenServiceV2 from 'services/segen/useSegenServiceV2'
import { Theme } from 'types/themes'
import { formatCurrency } from 'util/misc'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  radio: {
    padding: '0px 16px 0px 0px',
    '&.Mui-checked': {
      color: '#4272DD',
    },
  },
}))

const segenAuthenticationService = new SegenAuthenticationService({
  msalInstance: getMsalSegenSingletonInstance(),
  segenConfig: window.getSegenConfig(),
})

const SegenCartSection: FC<CartSectionPropType> = ({ sortOrder, checkoutSource, distributorKey }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]

  return (
    <AccordionCard
      name={'distributor-segen'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent
          totalItems={data?.totalItems}
          subtotal={data?.subtotalPrice}
          shippingFee={data?.shippingInfo.fee}
          isLoading={data?.isLoading}
          isPriceLocked
        />
      }
      contentProps={{ distributorKey, checkoutSource }}
      content={SegenCartSectionContent}
      lazyRender={true}
    />
  )
}

const SegenCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const project = useFormState().values
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const accountManagerData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].accountManagerContext)
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const data = distributorData[distributorKey]
  const authenticationStatus = useSegenAuthenticationStatus()
  const segenService = useSegenServiceV2()
  const [deliveryOptions, setDeliveryOptions] = useState<string[]>([])
  const translate = useTranslate()
  const { loadedOrderData } = useGetOrderDataByProject()
  const [quote, setQuote] = useState<SegenQuoteResult | undefined>(undefined)
  const { distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const distributorConfig = distributorConfigs.find(
    (item: ExtendedHardwareSupplierConfig) => item.filterKey === distributorKey
  )

  useEffect(() => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        isLoading: true,
      },
    })
  }, [])

  useEffect(() => {
    const fetchAccountManager = async () => {
      if (authenticationStatus !== 'authenticated') return
      const [account, deliveryMethod, segenAccessToken] = await Promise.all([
        segenService.getAccountDetail(),
        segenService.getDeliveryMethodPreference(),
        segenAuthenticationService.maybeGetSegenAccessTokenSilently(),
      ])

      const segenManagerAccount = account?.keyAccountContacts?.find(
        (contact: AccountContact) => contact.type === 'Account Manager'
      )
      setDeliveryOptions(deliveryMethod || [])
      preOrderPresenter?.updateAccountManager({
        ...accountManagerData,
        [distributorKey]: segenManagerAccount,
      })
      preOrderPresenter?.updateDistributorData({
        ...distributorData,
        [distributorKey]: {
          ...data,
          authorization: segenAccessToken,
        },
      })
    }
    fetchAccountManager()
  }, [authenticationStatus])

  useEffect(() => {
    const init = async () => {
      if (authenticationStatus !== 'authenticated' || loadedOrderData?.length > 0) return

      const items = Object.values(data?.items)
        .flat()
        .map((item) => {
          const lineItemObj = new OrderLineItem(item as OrderLineItem)
          return {
            code: lineItemObj.code,
            productCode: lineItemObj.variantId,
            quantity: lineItemObj.quantity,
            componentType: lineItemObj.componentType,
            projectOrder: [],
          }
        })

      const original_order_data = createOrderData(
        distributorData[distributorKey],
        contactInfo[distributorKey],
        deliveryAddressInfo[distributorKey]
      )

      const [account, deliveryMethod, segenAccessToken, quoteResponse] = await Promise.all([
        segenService.getAccountDetail(),
        segenService.getDeliveryMethodPreference(),
        segenAuthenticationService.maybeGetSegenAccessTokenSilently(),
        segenService.placeQuoteFromProject({
          items,
          project_ids: [project.id],
          original_order_data: original_order_data,
        }),
      ])
      const segenManagerAccount = account?.keyAccountContacts?.find(
        (contact: AccountContact) => contact.type === 'Account Manager'
      )
      const shippingFee = quoteResponse?.items ? getSegenDeliveryCostFromQuoteItems(quoteResponse?.items) : 'TBC'

      setDeliveryOptions(deliveryMethod || [])
      setQuote(quoteResponse)

      preOrderPresenter?.updateAccountManager({
        ...accountManagerData,
        [distributorKey]: segenManagerAccount,
      })
      preOrderPresenter?.updateDistributorData({
        ...distributorData,
        [distributorKey]: {
          ...data,
          shippingInfo: {
            ...data?.shippingInfo,
            fee: shippingFee === 'TBC' ? 'TBC' : formatCurrency(shippingFee),
          },
          authorization: segenAccessToken,
          isLoading: false,
          quoteId: quoteResponse?.id ? `${quoteResponse?.id}` : data.quoteId,
          orderId: quote?.orderId,
        },
      })
    }
    init()
  }, [authenticationStatus, loadedOrderData])

  const handleUpdateDispatchDate = (date: any) => {
    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          dispatchDate: formattedDate,
        },
      },
    })
  }

  const handleUpdateDeliveryOption = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryOption: event.target.value,
        },
      },
    })
  }

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  return (
    <Grid container style={{ gap: '24px', padding: '8px 8px' }}>
      <SegenQuotePrompt orderId={data?.orderId} />
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        {distributorConfig?.isConnected ? (
          <>
            <Alert
              title={translate(
                `Congratulations! 30 days price lock is enabled for this quote and your Segen reference ID is ${quote?.reference}.`
              )}
              severity="success"
            />
            <Alert
              title={translate('What’s next after sending my order?')}
              subtitle={translate(
                'Once sent, your order will be transferred to the Segen Portal for payment processing and final shipping confirmation. You will then receive a Segen order reference ID for tracking purposes.'
              )}
              severity="info"
            />
          </>
        ) : (
          <DistributorConnectionAlert
            distributor={distributorKey}
            checkoutSource={checkoutSource}
            borderColor="#FAA4A4"
            backgroundColor="#FEEBEB"
            fontColor="#961616"
          />
        )}
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Select Delivery Method')}</span>
        <RadioGroup
          name="deliveryMethod"
          style={{ marginTop: '4px', gap: '20px' }}
          value={data?.shippingInfo?.deliveryMethod}
        >
          <FormControlLabel
            checked
            key={'delivery-method-delivery'}
            value={'delivery'}
            control={<Radio className={classes.radio} />}
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
            label={
              <div className={sectionStyles.column}>
                <Typography textVariant="body2" colorHex={COLOR_PALETTE.black as `#${string}`}>
                  {translate('Delivery')}
                </Typography>
                <Typography textVariant="body1" colorHex={COLOR_PALETTE.grey as `#${string}`}>
                  {translate('The delivery cost is calculated after the preferred date and time have been selected.')}
                </Typography>
              </div>
            }
          />
          <Grid
            container
            direction="row"
            style={{ width: '100%', alignItems: 'center', gap: '10px', marginLeft: '40px' }}
          >
            <Grid item xs={4}>
              <DateInput
                fullWidth
                size="medium"
                value={data?.shippingInfo?.dispatchDate ? dayjs(data?.shippingInfo?.dispatchDate) : null}
                onChange={handleUpdateDispatchDate}
                inputVariant="outlined"
                label={
                  <InputLabel className={sectionStyles.inputLabel}>{translate('Preferred dispatch date')}</InputLabel>
                }
              />
              <ErrorHelperText showText={isEmpty(data?.shippingInfo?.dispatchDate)} />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel className={sectionStyles.inputLabel}>{translate('Preferred Delivery Type')}</InputLabel>
                <Select value={data?.shippingInfo?.deliveryOption} onChange={handleUpdateDeliveryOption}>
                  {deliveryOptions.map((item) => (
                    <MenuItem value={item} key={`project-hardware-${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorHelperText showText={isEmpty(data?.shippingInfo?.deliveryOption)} />
              </FormControl>
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <PrivacyLinks
          distributor={'Segen'}
          policyLink={'https://portal.segen.co.uk/Home/PrivacyPolicy'}
          conditionLink={'https://portal.segen.co.uk/reseller/docs/UKPG_Terms_of_Supply.pdf'}
        />
      </Grid>
    </Grid>
  )
}

export default memo(SegenCartSection)
