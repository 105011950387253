import { Action } from '../core/action'
import { Signal } from '../core/signal'
import { ArrayState, DeepState } from '../core/state'
import { DesignView } from '../types'
import { AutoDesignRunAndLoadOptions } from '../types/autoDesign'

export const studio = {
  // State
  availableImagery: new DeepState<any | undefined>(undefined),
  views: new ArrayState<DesignView>([]),

  // Actions
  autoDesignRunAndLoad: new Action<[options: AutoDesignRunAndLoadOptions], any>(),
  autoDesignRunAndLoadFromEquipment: new Action<[any, any, any, any, any, any, any, any, any?, any?, any?], any>(),
  setComponents: new Action<[components: any[], keepExistingComponents?: Boolean, systemUuid?: string]>(),
  removeObject: new Action<[objectUuid: string]>(),
  getLoadedData: new Action<[], any>(),
  getSelectedSystemData: new Action<[], any>(),
  getSystemImageUrl: new Action<[systemUuid: string, width?: number, height?: number], string>(),
  executeCommand: new Action<[commandName: string, commandArgs: any[]]>(),
  executeCommandFromJson: new Action<[commandName: string, json: any]>(),
  callFunction: new Action<[singletonName: string, functionName: string, args: any[]]>(),

  // Signals
  windowResize: new Signal(),
  objectSelected: new Signal(),
  objectAdded: new Signal(),
  objectChanged: new Signal(),
  objectRemoved: new Signal(),
  sceneLoaded: new Signal(),
  queueProcessed: new Signal(),
} as const
