import { genid } from '../util/genid'
import { Logger } from '../util/logger'
import { deserialiseDeep, serialiseDeep } from '../util/serialise'
import { Value } from './state'

export class Peers {
  readonly logger = new Logger('OSSDK.Peers')
  readonly hasPeers = new Value<boolean>(false)
  id: string = ''

  private ready = false
  private peers: Peer[] = []
  private selfOwnerships: Record<string, number> = {}
  private ownershipHasChanged: string[] = []
  private ownershipMap: Record<string, Record<string, number>> = {}
  private pendingActions: Record<string, { resolve: (val: any) => void; reject: (err: any) => void }> = {}
  private handlers: Record<string, ((msg: any) => void)[]> = {}

  onSyncState: undefined | ((peer: Peer) => void)
  onStateChange: undefined | ((path: string, val: any, old: any, isOwnerChange: boolean) => void)
  onSignalDispatched: undefined | ((path: string, args: any[]) => void)
  onAction: undefined | ((path: string, args: any[]) => Promise<any>)

  join(id: string) {
    this.id = id
    window.addEventListener('message', (e) => {
      const from = this.peers.find((p) => p.window === e.source)

      const isSdkMessage = e.data.format === SdkPeersMessageFormat
      if (
        e.data.source === 'react-devtools-content-script' ||
        e.data.target === 'Extension' ||
        e.origin === 'https://cdn.walkme.com'
      ) {
        // Can ignore these silently to make debugging easier
        // these are known other systems that we can ignore safely
        return
      }
      if (!from || !isSdkMessage) {
        this.logger.debug('Ignoring message: ', this.id, e)
        // Some other system's comms
        return
      }

      // Forward the message to other peers
      this.forEach((peer) => {
        if (peer !== from) this.sendEnvToPeer(peer, e.data)
      })

      // Process message
      this.onMessage(e.data, from)

      //TODO: could optimise here by not forwarding action responses targeted at this peer

      // Send message to external handlers
      const handlers = this.handlers[e.data.type]
      if (handlers) handlers.forEach((h) => h(e.data))
    })
  }

  markReady() {
    if (this.ready) {
      this.logger.error('Already marked ready')
      return
    }
    this.ready = true
    this.forEach((peer) => this.sendMsgToPeer(peer, { type: 'PeerReadyMsg' }))
  }

  add(type: string, handler: (msg: unknown) => void) {
    let handlers = this.handlers[type]
    if (!handlers) {
      this.handlers[type] = handlers = []
    }
    handlers.push(handler)
  }
  remove(type: string, handler: (msg: unknown) => void) {
    let handlers = this.handlers[type]
    if (!handlers) return
    const idx = handlers.indexOf(handler)
    if (idx >= 0) handlers.splice(idx, 1)
  }

  sendToAll(msg: AllMsgs, target?: string) {
    const env: MsgEnvelope = this.envelope(msg, target)
    this.forEach((peer) => this.sendEnvToPeer(peer, env))
  }

  send(window: Window, msg: AllMsgs) {
    const peer = this.peers.find((p) => p.window === window)
    if (!peer) {
      this.logger.error('Unknown peer: ', window)
    } else {
      //TODO: should probably target this msg
      this.sendMsgToPeer(peer, msg)
    }
  }

  private forEach(callback: (peer: Peer) => void) {
    this.peers.forEach(callback)
  }

  private canHandleMsg(peer: Peer, msg: MsgEnvelope) {
    const type = msg.msg.type
    const isEarlyMsg = type === 'PeerReadyMsg' || type === 'PeerLoadedMsg' || type === 'SetOwnershipMsg'
    return (isEarlyMsg && peer.loaded) || (!isEarlyMsg && peer.ready)
  }

  private envelope(msg: AllMsgs, target?: string): MsgEnvelope {
    return {
      format: SdkPeersMessageFormat,
      from: this.id,
      msg,
      target,
    }
  }

  private sendMsgToPeer(peer: Peer, msg: AllMsgs) {
    this.sendEnvToPeer(peer, this.envelope(msg))
  }

  private sendEnvToPeer(peer: Peer, env: MsgEnvelope) {
    if (!this.canHandleMsg(peer, env)) {
      this.logger.debug('Queuing message: ', this.id, env)
      peer.pendingMsgs.push(env)
      return
    }
    this.logger.debug(`Send.${env.msg.type}(me:${this.id} to:${env.target || 'all'}):`, env.msg)

    try {
      peer.window.postMessage(env, '*')
    } catch (e) {
      this.logger.error('Error sending message to peer', e)
    }
  }

  addPeer(
    window: Window,
    loaded: boolean,
    sendLoaded: boolean,
    options?: { onReadinessChange?: (loaded: boolean, ready: boolean) => void; syncState?: boolean }
  ) {
    const { onReadinessChange, syncState } = options || {}
    const peer: Peer = { window, loaded, ready: false, pendingMsgs: [], onReadinessChange }
    this.peers.push(peer)

    // Check for unloaded peers regularly  but not so much it kills performance. An efficient choice
    // of event is when a new peer is added because it avoids needing to do something more aggressive
    // like listening on each event. Remove existing unloaded peers here.
    //
    // There is a good possibility this many not remove all closed views perfectly if the removed view
    // has not yet fully closed, it may not be marked as closed when the new view is added. But this does
    // not need to be precise, it is ok if some events get routed to windows that are closed/closing because
    // it does not cause any harm.
    //
    // Is this insufficient? We really need to remove the whole OSSDKBootstrap
    // var peersToRemove = this.peers.filter((p) => !p.window.closed === true)
    // peersToRemove.forEach((otherPeer) => this.removePeer(otherPeer), this)

    if (sendLoaded) this.sendMsgToPeer(peer, { type: 'PeerLoadedMsg' })
    if (this.ready) this.sendMsgToPeer(peer, { type: 'PeerReadyMsg' })

    // Send ownerships to new peer
    for (var path in this.selfOwnerships) {
      this.sendOwnership(path, this.selfOwnerships[path], peer)
    }

    if (syncState) {
      this.onSyncState?.(peer)
    }

    this.hasPeers.value = !!this.peers.length

    // Return cleanup method
    // This never actually gets called which is why we need to check for unloaded peers regularly.
    // An efficient choice is when a new peer is added because it avoids needing to do something more
    // aggressive like listening on each event.
    return () => this.removePeer(peer)
  }

  private removePeer(peer: Peer) {
    const index = this.peers.indexOf(peer)
    if (index < 0) {
      this.logger.error('Unknown peer: ', peer)
    } else {
      // Should we also explicitly remove handlers from the peer before simply removing from the list of peers?
      // Perhaps garbage collection can clean it up but could there be some references somewhere related to handlers?

      this.peers.splice(this.peers.indexOf(peer), 1)
    }

    this.hasPeers.value = !!this.peers.length
  }

  async onMessage(env: MsgEnvelope, from: Peer) {
    const msg = env.msg
    this.logger.debug(`Receive.${msg.type}(me:${this.id} from:${env.from}):`, env.msg)
    switch (msg.type) {
      case 'SignalDispatchedMsg':
        this.onSignalDispatched?.(msg.path, msg.args)
        // forward signal to peers (except where it's come from)
        this.forEach((peer) => {
          if (peer !== from) this.sendMsgToPeer(peer, msg)
        })
        break

      case 'StateChangeMsg':
        let isOwnerChange = this.ownershipHasChanged.includes(msg.path)
        this.onStateChange?.(msg.path, msg.value, msg.old, isOwnerChange)

        // remove msg.path from this.ownershipHasChanged since it has now loaded a new starting "old" value
        if (isOwnerChange) {
          const idx = this.ownershipHasChanged.indexOf(msg.path)
          if (idx >= 0) this.ownershipHasChanged.splice(idx, 1)
        }

        break

      case 'SetOwnershipMsg':
        let ownerMap = this.ownershipMap[msg.path]
        if (!ownerMap) {
          this.ownershipMap[msg.path] = ownerMap = {}
        }

        if (!this.ownershipHasChanged.includes(msg.path)) {
          this.ownershipHasChanged.push(msg.path)

          // Not currently implemented because we have not yet identified any cases where this is problematic
          // and clearing messages could be dangerous without solid test coverage with real scenarios.
          // Beware: env.from should only ever be master and it's actually the OLD master not the new one
          // that is sending the new SetOwnershipMsg message
          //
          // PENDING_MESSAGES_TO_CLEAR_ON_OWNERSHIP_CHANGE = []
          // if(PENDING_MESSAGES_TO_CLEAR_ON_OWNERSHIP_CHANGE.includes(msg.path)){
          //   this.clearPendingMsgs(from, msg.path)
          // }
        }

        ownerMap[env.from] = msg.priority

        break

      case 'UnownedActionMsg':
        if (env.target === this.id) {
          this.logger.debug('Process Action:', this.id, msg)
          const promise = this.onAction?.(msg.path, msg.args)
          if (!promise) {
            this.logger.error('No action handler found for msg', msg)
            return
          }
          // This app is the target for this call, execute it
          promise
            .then((ret) => {
              // success
              const response: ActionResponseMsg = {
                type: 'ActionResponseMsg',
                callId: msg.callId,
                response: serialiseDeep(ret),
                rejected: false,
              }
              this.sendToAll(response, env.from)
            })
            .catch((err) => {
              // failure
              const response: ActionResponseMsg = {
                type: 'ActionResponseMsg',
                callId: msg.callId,
                response: serialiseDeep(err),
                rejected: true,
              }
              this.sendToAll(response, env.from)
            })
        }
        break

      case 'ActionResponseMsg':
        if (env.target !== this.id) {
          // This app is not the target for this call, ignore it
          break
        }
        const ret = this.pendingActions[msg.callId]
        if (ret) {
          delete this.pendingActions[msg.callId]
          const response = deserialiseDeep(msg.response)
          if (msg.rejected) ret.reject(response)
          else ret.resolve(response)
        } else {
          this.logger.error('No pending action found for callId', msg.callId)
        }
        break

      case 'PeerLoadedMsg':
        if (from.loaded) {
          this.logger.error('Peer already loaded', from)
          return
        }
        from.loaded = true
        this.checkPendingMsgs(from)
        if (from.onReadinessChange) from.onReadinessChange(from.loaded, from.ready)
        break

      case 'PeerReadyMsg':
        if (from.ready) {
          this.logger.error('Peer already ready', from)
          return
        }
        from.ready = true
        this.checkPendingMsgs(from)
        if (from.onReadinessChange) from.onReadinessChange(from.loaded, from.ready)
        break
    }
  }

  private checkPendingMsgs(peer: Peer) {
    if (!peer.pendingMsgs.length) return
    const stillPending: MsgEnvelope[] = []
    peer.pendingMsgs.forEach((env) => {
      if (this.canHandleMsg(peer, env)) {
        this.sendEnvToPeer(peer, env)
      } else {
        stillPending.push(env)
      }
    })
    peer.pendingMsgs = stillPending
  }

  private clearPendingMsgs(peer: Peer, path: string) {
    const stillPending: MsgEnvelope[] = []
    peer.pendingMsgs.forEach((env) => {
      // Only strip messages that are known to be problematic. In future we may confirm that all
      // pending messages can be cleared from the old owner.
      if (env.msg.type === 'StateChangeMsg' && env.msg.path === path) {
        // Skip this message
      } else {
        stillPending.push(env)
      }
    })
    peer.pendingMsgs = stillPending
  }

  setOwnership(path: string, priority: number) {
    if (this.selfOwnerships[path] === priority) return
    this.selfOwnerships[path] = priority
    this.sendOwnership(path, priority)

    if (!this.ownershipMap[path]) {
      this.ownershipMap[path] = {}
    }
    this.ownershipMap[path][this.id] = priority
  }
  private sendOwnership(path: string, priority: number, peer?: Peer) {
    const msg: SetOwnershipMsg = {
      type: 'SetOwnershipMsg',
      path,
      priority,
    }
    if (peer) this.sendMsgToPeer(peer, msg)
    else this.forEach((peer) => this.sendMsgToPeer(peer, msg))
  }

  sendSignal(path: string, args: any[]) {
    const msg: SignalDispatchedMsg = {
      type: 'SignalDispatchedMsg',
      path,
      args,
    }
    this.forEach((peer) => this.sendMsgToPeer(peer, msg))
  }

  isOwner(path: string): boolean {
    const owner = this.findOwner(path)
    return owner === this.id
  }

  isOwnerOrNotOwned(path: string): boolean {
    const owner = this.findOwner(path)
    return !owner || owner === this.id
  }

  sendUnownedAction(path: string, args: any[]): Promise<any> {
    const owner = this.findOwner(path, 'master')

    if (owner === this.id) {
      return this.onAction?.(path, args)!!
    } else {
      const msg: UnownedActionMsg = {
        type: 'UnownedActionMsg',
        path: path,
        args: args,
        callId: genid(10),
      }
      const ret = new Promise<any>((resolve, reject) => {
        this.pendingActions[msg.callId] = { resolve, reject }
      })
      this.sendToAll(msg, owner)
      return ret
    }
  }

  findOwner(path: string, def?: string): string | undefined {
    const ownerMap = this.ownershipMap[path]
    let bestOwner = def

    if (ownerMap) {
      let bestPriority: undefined | number
      for (var id in ownerMap) {
        if (bestPriority === undefined || ownerMap[id] > bestPriority) {
          bestOwner = id
          bestPriority = ownerMap[id]
        }
      }
    }

    return bestOwner
  }

  sendStateChange(path: string, val: any, old: any) {
    const msg: StateChangeMsg = {
      type: 'StateChangeMsg',
      path: path,
      value: val,
      old: old,
    }
    this.sendToAll(msg)
  }

  syncStateToPeer(path: string, peer: Peer, newValue: any, oldValue: any) {
    const msg: StateChangeMsg = {
      type: 'StateChangeMsg',
      path: path,
      value: newValue,
      old: oldValue,
    }
    this.sendMsgToPeer(peer, msg)
  }
}

export type MsgEnvelope = {
  target?: string
  from: string
  msg: AllMsgs
  format: typeof SdkPeersMessageFormat
}

export type SignalDispatchedMsg = {
  type: 'SignalDispatchedMsg'
  path: string
  args: any[]
}

export type StateChangeMsg = {
  type: 'StateChangeMsg'
  path: string
  value: any
  old: any
}

export type UnownedActionMsg = {
  type: 'UnownedActionMsg'
  path: string
  args: any[]
  callId: string
}

export type ActionResponseMsg = {
  type: 'ActionResponseMsg'
  response: any
  rejected: boolean
  callId: string
}

export type SetOwnershipMsg = {
  type: 'SetOwnershipMsg'
  priority: number
  path: string
}

export type PeerLoadedMsg = {
  type: 'PeerLoadedMsg'
}

export type PeerReadyMsg = {
  type: 'PeerReadyMsg'
}

export type AllMsgs =
  | SignalDispatchedMsg
  | StateChangeMsg
  | UnownedActionMsg
  | SetOwnershipMsg
  | ActionResponseMsg
  | PeerReadyMsg
  | PeerLoadedMsg

export type Peer = {
  window: Window
  loaded: boolean // Whether the SDK is mounted in the peer (important for comms)
  ready: boolean // Whether setup is complete in the peer (important for SDK users)
  pendingMsgs: MsgEnvelope[]
  onReadinessChange?: (loaded: boolean, ready: boolean) => void
}

// Update this string to avoid interference between incompatible sdk versions
const SdkPeersMessageFormat = 'ossdk_v1'
