import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { BooleanInput, TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled?: boolean
}
const NavbarFields: FC<PropTypes> = (props) => {
  const { disabled } = props
  const translate = useTranslate()
  const source = useBlockSource('navbar')

  return (
    <InputAccordion title={translate('Navbar')} defaultExpanded={false}>
      <TextInput label={'CTA Label'} name={`${source}.cta.label`} source={`${source}.cta.label`} disabled={disabled} />
      <TextInput label={'CTA Link'} name={`${source}.cta.link`} source={`${source}.cta.link`} disabled={disabled} />
      <BooleanInput label={'Open Link in New Tab?'} source={`${source}.cta.newTab`} disabled={disabled} />
    </InputAccordion>
  )
}

export default NavbarFields
