import AcceptProposalWrapper from './AcceptProposalWrapper'

export const ProposalAcceptedLink = ({ textColor }: { textColor: string }) => {
  return (
    <AcceptProposalWrapper textColor={textColor}>
      <input type="checkbox" checked disabled={true} />
      Proposal Accepted
    </AcceptProposalWrapper>
  )
}
