import type { IconProps, IconSvgComponentType } from './type'

/**
 *
  return opensolar SVG component with standard props
 */
const createStandardIcon = (Icon: IconSvgComponentType) => {
  return ({
    width = '1rem',
    height = '1rem',
    color = 'currentColor',
    stroke = 'currentColor',
    fill = 'currentColor',
    strokeWidth = '0.01rem',
    transform = '',
    ...rest
  }: Partial<IconProps>) => {
    return (
      <Icon
        width={width}
        height={height}
        color={color}
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
        transform={transform}
        {...rest}
      />
    )
  }
}

export default createStandardIcon
