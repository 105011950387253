import { orgSelectors } from 'ducks/orgs'
import { DraggableList } from 'elements/dnd/DraggableList'
import { COMPONENT_CONFIG, ConfigType } from 'opensolar-pageeditor'
import { nonReorderableBlocks, reorderableBlocks } from 'opensolar-pageeditor/src/renderer/utils'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'
import { FC, useEffect, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { defaultExpoContents } from './util'

const source = 'contents.elements'
const PageContentField: FC = () => {
  const form = useForm()
  const formValues = useFormState().values
  const isPremiumExpo = useSelector(orgSelectors.getPremiumExpo)
  const contents = useMemo(() => formValues.contents?.elements, [formValues.contents?.elements])
  const contentBlocks = useMemo(() => defaultExpoContents(isPremiumExpo, false /* add correct condition */), [
    isPremiumExpo,
  ])
  //ensure all content blocks present in template data matches our list
  useEffect(() => {
    let contentList = contents || []
    const newBlocks = contentBlocks?.filter((x) => !contentList?.map((y) => y.contentType).includes(x.contentType))
    const removeBlocks = contentList
      ?.filter((x) => !contentBlocks?.map((y) => y.contentType).includes(x.contentType))
      ?.map((y) => y.contentType)
    if (!!newBlocks.length) contentList = contentList.concat(newBlocks || [])
    if (!!removeBlocks.length) contentList = contentList.filter((x) => !removeBlocks.includes(x.contentType))
    if (!!newBlocks.length || !!removeBlocks.length) form.change(source, contentList)
  }, [contentBlocks, contents])

  const onDragComplete = (oldOrder, newOrder) => {
    //restrict drag on required items
    let updatedContents = [...(contents.filter((x) => nonReorderableBlocks.includes(x.contentType)) || [])]
    updatedContents = updatedContents.concat(
      newOrder
        .filter((x) => reorderableBlocks.includes(x))
        .map((x) => ({ ...contents.find((y) => y.contentType === x) }))
    )
    form.change(source, updatedContents)
  }
  if (!contents || !contents?.length) return null
  return (
    <DraggableList
      handleReorder={onDragComplete}
      optionId="contentType"
      list={contents}
      render={(item, { dragHandle, index }) => (
        <ContentBlockToggleView source={`${source}.${index}`} dragHandle={dragHandle} item={item} />
      )}
      isDragDisabled={(item: any) => nonReorderableBlocks.includes(item.contentType)}
      dragMode="drag-handle"
    />
  )
}

const ToggleWrapper = styled('div', { name: 'ToggleWrapper' })({
  display: 'flex',
  alignItems: 'center',
  margin: '5px 10px',
})

const BooleanStyled = styled(BooleanInput, { name: 'BooleanStyled' })({
  marginLeft: 'auto',
})
const ContentBlockToggleView = (props) => {
  const { source, dragHandle, item } = props
  const itemConfig: ConfigType = useMemo(() => COMPONENT_CONFIG[item.contentType] || undefined, [item.contentType])
  const { label, toggleEnabled, reorderable } = itemConfig || { label: '', toggleEnabled: false, reorderable: false }
  const translate = useTranslate()
  const display = useMemo(
    () => (label.includes('Custom Content') ? `${translate('Custom Content')} ${label.slice(-1)} ` : translate(label)),
    [label]
  )
  if (!item || !itemConfig) return null
  return (
    <ToggleWrapper key={'elements-' + item.contentType}>
      {!reorderable ? <></> : dragHandle}
      <span style={{ color: !toggleEnabled ? 'grey' : undefined }}>{display}</span>
      <BooleanStyled
        disabled={!toggleEnabled}
        source={source + '.enabled'}
        label={''}
        options={{ color: 'secondary' }}
      />
    </ToggleWrapper>
  )
}

export default PageContentField
