// @ts-nocheck
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import { FieldTitle, InputProps, useInput } from 'ra-core'
import { FunctionComponent, useCallback } from 'react'

import { OsSwitchProps, Switch } from 'opensolar-ui'
import InputHelperText from './InputHelperText'
import InputPropTypes from './InputPropTypes'
import sanitizeRestProps from './sanitizeRestProps'

// const defaultFormControlLabelStyle = { margin: '24px 40px 16px 0' }

const BooleanInput: FunctionComponent<
  InputProps<OsSwitchProps> & Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>
> = ({
  format,
  label,
  fullWidth,
  helperText,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  labelPlacement = 'start',
  formControlLabelStyle,
  endAdornment,
  ...rest
}) => {
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, checked, ...inputProps },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  })

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value)
    },
    [finalFormOnChange]
  )

  //it's a little bit tricky here, if component wrapped by <Field/> the 'value' prop presenting field's actual value otherwise 'checked' contains the actual value
  const finalValue = value || checked

  return (
    <FormGroup {...sanitizeRestProps(rest)}>
      <FormControlLabel
        style={formControlLabelStyle}
        control={
          <>
            <Switch
              id={id}
              color="secondary"
              onChange={handleChange}
              {...inputProps}
              {...options}
              disabled={disabled}
              checked={Boolean(finalValue)}
            />
            {endAdornment}
          </>
        }
        labelPlacement={labelPlacement}
        label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
      />
      <FormHelperText error={!!error}>
        <InputHelperText touched={touched} error={error} helperText={helperText} />
      </FormHelperText>
    </FormGroup>
  )
}

BooleanInput.propTypes = {
  ...InputPropTypes,
  options: PropTypes.any,
  disabled: PropTypes.bool,
}

BooleanInput.defaultProps = {
  options: {},
}

export default BooleanInput
