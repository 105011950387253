import ContentAdd from '@material-ui/icons/Add'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import { useEffect, useMemo, useState } from 'react'
import { Button, Datagrid, FilterComp, Link, SelectInput, TextField } from 'react-admin'
import { doNotTranslate } from 'util/misc'
import { StructType, useStructTypes } from './hooks/useStructTypes'

const StructActions = ({ structTypes, ...props }) => {
  const { filterValues } = props
  const [currentType, setCurrentType] = useState<StructType | undefined>()

  useEffect(() => {
    if (!structTypes || !filterValues.type) return
    setCurrentType(structTypes.find((t) => t.id === filterValues.type))
  }, [filterValues.type, structTypes])

  return (
    <>
      {/*@ts-ignore*/}
      <ListActions {...props} hasCreate={false} exporter={false} hasArchived={true} />
      {props.hasCreate && currentType && <StructCreateButton structType={currentType} {...props} />}
    </>
  )
}

const StructFilter = ({ structTypes, ...props }) => (
  <FilterComp {...props} displayedFilters={{ type: true }}>
    <SelectInput
      alwaysOn={true}
      source="type"
      optionText="title"
      optionValue="id"
      choices={structTypes}
      allowEmpty={false}
    />
  </FilterComp>
)

const StructCreateButton = (props) => {
  const {
    basePath = '',
    className,
    classes: classesOverride,
    icon = <ContentAdd />,
    id,
    onClick,
    structType,
    ...rest
  } = props

  if (!structType) return null

  return (
    <Link
      id={id}
      style={{ textDecoration: 'none' }}
      to={{
        pathname: `${basePath}/create`,
        search:
          'record=' +
          encodeURIComponent(JSON.stringify({ type: structType.id, is_archived: false, is_published: false })),
      }}
      onClick={onClick}
    >
      <Button
        id={id ? id + '-button' : undefined}
        variant="outlined"
        color="default"
        size="default"
        name={'raCreateButton'}
        style={{ margin: 5 }}
        startIcon={icon}
        {...(rest as any)}
      >
        <span>{doNotTranslate('Create ' + structType.title)}</span>
      </Button>
    </Link>
  )
}

export const StructList = (props) => {
  const structTypes = useStructTypes()
  const defaultFilters = useMemo(() => ({ type: 'feature-flag' }), [])

  return (
    <List
      hasSearch={true}
      {...props}
      showNoResultsPage={false}
      filterDefaultValues={defaultFilters}
      actions={<StructActions structTypes={structTypes} />}
      filters={<StructFilter structTypes={structTypes} />}
    >
      <Datagrid>
        <TextField source="key" />
        <TextField source="description" />
        <EditOrImportButton
          source="actions"
          label="Actions"
          resource="structs"
          allowDuplicate={false}
          sortable={false}
          allowEdit={true}
          allowDelete={true}
        />
        <TextField source="is_published" label="Published" />
      </Datagrid>
    </List>
  )
}
