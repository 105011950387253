import { styled } from 'opensolar-ui'
import { FC } from 'react'
import { Statistic } from '../Statistics'

const CardWrapper = styled('div', { name: 'CardWrapper' })({
  flexGrow: 1,
  flexBasis: 290,
})

const Value = styled('h1', { name: 'Value' })({
  fontSize: 32,
  margin: 0,
})
const Description = styled('p', { name: 'Description' })(({ theme }) => ({
  color: theme.palette.grey[600],
  lineHeight: '20px',
  marginTop: 5,
  maxWidth: 300,
}))

interface PropTypes {
  statistic: Statistic
}

const StatisticCard: FC<PropTypes> = ({ statistic }) => {
  return (
    <CardWrapper>
      <Value>{statistic.value}</Value>
      <Description>{statistic.description}</Description>
    </CardWrapper>
  )
}
export default StatisticCard
