import { FC } from 'react'

/**
 * * Icon Component for: x-03
 */
type CloseOutlineIconProps = {
  size?: number
  color?: string
}
export const CloseOutlineIcon: FC<CloseOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none" {...rest} {...iconAttributes}>
      <path d="M9 1L1 9M9 9L1 0.999998" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
