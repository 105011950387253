import { CheckOutlineIcon, ReapplicationConfirm, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const SungageApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const extra_actions: React.ReactNode[] = []

  const buttonLabelText =
    actionData.status_title === 'InitiatedButIncomplete'
      ? translate('Finish Loan Application')
      : translate('Apply Now!')

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Apply Now!" will take you to Sungage's credit application, which Sungage indicates is a soft pull with
        no impact on your credit score. OpenSolar does not store any of your data except name and email address.
      </p>
    )
  }

  if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  return (
    <>
      {actionData.status_code !== 'complete' && (
        <div>
          <TransactionActionButton
            isLoading={isLoading}
            label={
              <>
                {buttonLabelText}
                <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
              </>
            }
            disabled={actionData.status_code !== 'available' || !!options?.loanDecisionRenderedAt}
            onClick={openApplicationDialog}
          />
          {extra_actions}
        </div>
      )}
      {actionData.status_code === 'complete' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(actionData.status_title?.includes('approved') || actionData.status_title?.includes('Approved')) && (
              <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />
            )}
            <h2>{actionData.status_title}</h2>
          </div>
          <div>{actionData.status_description}</div>
          <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%', justifyContent: 'center' }}>
            <ReapplicationConfirm
              highlightColor={proposalData.myeStyles.highlightColor}
              onConfirm={openApplicationDialog}
              loading={isLoading}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SungageApplication
