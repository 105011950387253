import { FC } from 'react'

/**
 * * Icon Component for: arrow-curve-right-up
 */
type ArrowCurveRightUpOutlineIconProps = {
  size?: number
  color?: string
}
export const ArrowCurveRightUpOutlineIcon: FC<ArrowCurveRightUpOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M13.0864 1.19981L18.4004 6.51382M18.4004 6.51382L13.0864 11.8278M18.4004 6.51382L5.6004 6.51381C3.39126 6.51381 1.60039 8.30468 1.60039 10.5138L1.60039 16.7998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
