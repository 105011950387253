import { useCallback } from 'react'

import { setMfaCheckRequired, setMfaConfigRequired, setMfaHasDevices } from 'app/src/ducks/auth_mfa_actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useNotify } from '../sideEffect'
import useAuthProvider from './useAuthProvider'
import useLogout from './useLogout'

let authCheckPromise

/**
 * Returns a callback used to call the authProvider.checkError() method
 * and an error from the dataProvider. If the authProvider rejects the call,
 * the hook logs the user out and shows a logged out notification.
 *
 * Used in the useDataProvider hook to check for access denied responses
 * (e.g. 401 or 403 responses) and trigger a logout.
 *
 * @see useLogout
 * @see useDataProvider
 *
 * @returns {Function} logoutIfAccessDenied callback
 *
 * @example
 *
 * import { useLogoutIfAccessDenied, useNotify, DataProviderContext } from 'react-admin';
 *
 * const FetchRestrictedResource = () => {
 *     const dataProvider = useContext(DataProviderContext);
 *     const logoutIfAccessDenied = useLogoutIfAccessDenied();
 *     const notify = useNotify()
 *     useEffect(() => {
 *         dataProvider.getOne('secret', { id: 123 })
 *             .catch(error => {
 *                  logoutIfaccessDenied(error);
 *                  notify('server error', 'warning');
 *              })
 *     }, []);
 *     // ...
 * }
 */

const useLogoutIfAccessDenied = (): LogoutIfAccessDenied => {
  const authProvider = useAuthProvider()
  const logout = useLogout()
  const notify = useNotify()
  const dispatch = useDispatch()
  const history = useHistory()
  const logoutIfAccessDenied = useCallback(
    (error?: any, doMfa = true) => {
      // If there was an mfa issue, assume that MFA is enforced on Org but not set up on user
      const mfaStatus = error.body?.mfa_status
      if (mfaStatus) {
        if (doMfa) {
          console.debug('MFA issue, redirecting to MFA setup', mfaStatus)
          dispatch(
            setMfaHasDevices(
              !!error.body?.has_sms_device,
              !!error.body?.has_totp_device,
              !!error.body?.has_recovery_codes
            )
          )
          dispatch(setMfaCheckRequired(mfaStatus == 'mfa_required' || mfaStatus == 'mfa_rejected'))
          dispatch(setMfaConfigRequired(mfaStatus == 'mfa_required_but_not_configured'))
          history.push('/login#mode')
        }
        return
      }

      //only record the path when error is 401 unauthorized
      //avoid causing infinite loop
      const recordCurrentPath = error?.status === 401

      // Sometimes, a component might trigger multiple simultaneous
      // dataProvider calls which all fail and call this function.
      // To avoid having multiple notifications, we first verify if
      // a checkError promise is already ongoing
      if (!authCheckPromise) {
        authCheckPromise = authProvider
          .checkError(error)
          .then(() => false)
          .catch(async (e) => {
            const redirectTo =
              e && e.redirectTo ? e.redirectTo : error && error.redirectTo ? error.redirectTo : undefined
            logout({}, redirectTo, recordCurrentPath)
            notify('ra.notification.logged_out', 'warning')
            return true
          })
          .finally(() => {
            authCheckPromise = undefined
          })
      }
      return authCheckPromise
    },
    [authProvider, logout, notify]
  )
  return authProvider ? logoutIfAccessDenied : logoutIfAccessDeniedWithoutProvider
}

const logoutIfAccessDeniedWithoutProvider = () => Promise.resolve(false)

/**
 * Call the authProvider.authError() method, unsing the error passed as argument.
 * If the authProvider rejects the call, logs the user out and shows a logged out notification.
 *
 * @param {Error} error An Error object (usually returned by the dataProvider)
 *
 * @return {Promise} Resolved to true if there was a logout, false otherwise
 */
type LogoutIfAccessDenied = (error?: any) => Promise<boolean>

export default useLogoutIfAccessDenied
