import { CloseIcon, CloseIconProps, IconButton } from 'opensolar-ui'
import { FC } from 'react'

interface DialogCloseButtonProps extends CloseIconProps {
  className?: string
  onClick: () => void
}

export const DialogCloseButton: FC<DialogCloseButtonProps> = ({ onClick, className, ...props }) => {
  return (
    <IconButton className={className} onClick={onClick} id="dialog-close-button">
      <CloseIcon {...props} />
    </IconButton>
  )
}
