import { FC } from 'react'

/**
 * * Icon Component for: arrow-expand-01, arrow-expand-02, arrow-expand-03, arrow-expand-04
 */
type ArrowExpandOutlineIconProps = {
  variant: 1 | 2 | 3 | 4
  size?: number
  color?: string
}
export const ArrowExpandOutlineIcon: FC<ArrowExpandOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M5 17H1M1 17V13M1 17L5.5 12.5M13 1H17M17 1V5M17 1L12.5 5.5M1 5L1 1M1 1L5 1M1 1L5.5 5.5M17 13L17 17M17 17H13M17 17L12.5 12.5"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M11.2064 1H16.9995M16.9995 1V6.79311M16.9995 1L11.2064 6.79311M6.79363 17H1.00052M1.00052 17V11.2069M1.00052 17L7.00052 11M17 11.2064V16.9995M17 16.9995H11.2069M17 16.9995L11.2069 11.2064M1 6.79363L1 1.00052M1 1.00052L6.79311 1.00052M1 1.00052L7 7.00052"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 3:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M11.2065 0C10.6542 0 10.2065 0.447715 10.2065 1C10.2065 1.55228 10.6542 2 11.2065 2V0ZM17 1H18C18 0.447715 17.5523 0 17 0V1ZM16 6.79311C16 7.34539 16.4477 7.79311 17 7.79311C17.5523 7.79311 18 7.34539 18 6.79311H16ZM10.2409 7.75863L9.53385 7.0515L9.53381 7.05154L10.2409 7.75863ZM6.79349 18C7.34577 18 7.79349 17.5523 7.79349 17C7.79349 16.4477 7.34577 16 6.79349 16V18ZM1 17H0C0 17.5523 0.447715 18 1 18L1 17ZM2 11.2069C2 10.6546 1.55228 10.2069 1 10.2069C0.447715 10.2069 0 10.6546 0 11.2069H2ZM11.2065 2H17V0H11.2065V2ZM16 1V6.79311H18V1H16ZM16.2929 0.29287L9.53385 7.0515L10.948 8.46575L17.7071 1.70713L16.2929 0.29287ZM6.79349 16H1V18H6.79349V16ZM2 17V11.2069H0V17H2ZM1.70712 17.7071L10.9481 8.46571L9.53381 7.05154L0.292876 16.2929L1.70712 17.7071Z"
            fill={color}
          />
        </svg>
      )
    case 4:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M11.2065 1H17M17 1V6.79311M17 1L10.2409 7.75863M6.79349 17H1M1 17V11.2069M1 17L7.75907 10.2414"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
