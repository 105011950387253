import { useMediaQuery } from '@material-ui/core'
import { BaseThemeType, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { AccountingIntegration } from './types'

type PropTypes = {
  accountingIntegrations: AccountingIntegration[]
}

const StatusDiv = styled('div')<{ isMobile?: boolean; isConnected: boolean }>(({ isMobile, isConnected, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: isMobile ? '10px' : '100px',
  height: '24px',
  borderRadius: '5px',
  padding: '5px',
  backgroundColor: isConnected ? '#E1FAEA' : '#FFF1DA',
}))

const StatusText = styled('div')<{ isConnected: boolean }>(({ isConnected, theme }) => ({
  display: 'flex',
  margin: 'auto',
  fontSize: '12px',
  fontWeight: 600,
  color: isConnected ? '#016626' : '#C47E0A',
}))

const AccountingConnectionChip: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('sm'))
  const isConnected = useMemo(() => {
    return props.accountingIntegrations?.some(
      (integration) =>
        integration.enabled &&
        integration.product_ledger_account_id &&
        (integration.name === 'quickbooks' || (integration.name === 'xero' && integration.payment_ledger_account_id))
    )
  }, [props.accountingIntegrations])

  const chipLabel = useMemo(() => {
    const connectedIntegration = props.accountingIntegrations?.find((integration) => integration.enabled)
    if (!connectedIntegration) return translate('NOT CONNECTED')
    if (connectedIntegration.name === 'quickbooks') {
      if (connectedIntegration.product_ledger_account_id) return translate('CONNECTED')
      else return translate('CONNECTED - LEDGER ACCOUNT SELECTION REQUIRED')
    }
    if (connectedIntegration.name === 'xero') {
      // Xero orgs need to have the main payment ledger account defined as well as at least one tax rate with a ledger and tax rate record
      if (!connectedIntegration.product_ledger_account_id)
        return translate('CONNECTED - LEDGER ACCOUNT SELECTION REQUIRED')
      if (connectedIntegration?.tax_rate_json && Object.values(connectedIntegration.tax_rate_json).length > 0) {
        let validSelections = Object.values(connectedIntegration.tax_rate_json).every(
          (taxRate) => Boolean(taxRate?.product_ledger_account_id) && Boolean(taxRate?.product_tax_rate_id)
        )
        if (validSelections) return translate('CONNECTED')
      }
      return translate('CONNECTED - LEDGER ACCOUNT SELECTION REQUIRED')
    }
    return translate('NOT CONNECTED')
  }, [props.accountingIntegrations])

  return (
    <StatusDiv isConnected={isConnected} isMobile={isMobile}>
      <StatusText isConnected={isConnected}>{chipLabel}</StatusText>
    </StatusDiv>
  )
}
export default AccountingConnectionChip
