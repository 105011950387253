import { CircularProgress } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import { Button, styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AccountingIntegration } from './types'
import { syncConnection } from './utils'

type PropTypes = {
  accountingIntegrations: AccountingIntegration[]
}

const SyncButton = styled(Button)({
  marginRight: '20px',
  marginBottom: '10px',
})

const SyncAccountingButton: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)

  const notify = useNotify()
  const translate = useTranslate()

  const onClick = () => {
    const enabledIntegration = props.accountingIntegrations.find((ai) => ai.enabled)
    if (orgId && roleId && enabledIntegration) {
      setIsLoading(true)
      syncConnection(orgId, roleId, enabledIntegration)
        .then((res) => {
          notify(translate(res), 'success')
          logAmplitudeEvent('cashflow_accounting_sync_started', {
            integration: enabledIntegration?.name,
          })
        })
        .catch((err) => {
          notify(translate(err), 'error')
          logAmplitudeEvent('cashflow_accounting_sync_error', { integration: enabledIntegration?.name })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  return (
    <SyncButton
      onClick={() => {
        onClick()
      }}
      variant="contained"
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress /> : <>{translate('Sync Records')}</>}
    </SyncButton>
  )
}

export default SyncAccountingButton
