import useDesignRulesEngine from 'Designer/designRules/useDesignRulesEngine'
import { useSnapshotExtension } from 'Designer/hooks/useSnapshotExtension'
import { useStudioExtensions } from 'Designer/hooks/useStudioExtensions'
import { projectInfoActions } from 'actions/project/projectInfoActions'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import CustomFormDialog from 'customForm/dialog/CustomFormDialog'
import { authSelectors } from 'ducks/auth'
import { resetSoldOptions } from 'ducks/myEnergy'
import { permissionsSelectors } from 'ducks/permissions'
import { projectViewSettingsActions } from 'ducks/projectViewSettings'
import { viewModeSelectors } from 'ducks/viewMode'
import { useFeatureSelectImageryEarly } from 'hooks/useFeatureSelectImageryEarly'
import ProgressOverlay from 'persistentContent/elements/ProgressOverlay'
import SocketDialog from 'persistentContent/elements/SocketDialog'
import LeaveProjectPrompt from 'projectSections/elements/LeaveProjectPrompt'
import Confirm from 'projectSections/elements/dialog/Confirm'
import { useAutoSelectDesignImagery } from 'projectSections/hooks/imagery/useAutoSelectDesignImagery'
import { useAddProjectErrors } from 'projectSections/hooks/useAddProjectErrors'
import { useAutoSaveProject } from 'projectSections/hooks/useAutoSaveProject'
import { useAutoSaveProjectEnabled } from 'projectSections/hooks/useAutoSaveProjectEnabled'
import { useFeatureLoadStudioProfileEarly } from 'projectSections/hooks/useFeatureLoadStudioProfileEarly'
import { useLoadProjectEvents } from 'projectSections/hooks/useLoadProjectEvents'
import { useLoadStudioProfile } from 'projectSections/hooks/useLoadStudioProfile'
import { useProjectDiscard } from 'projectSections/hooks/useProjectDiscard'
import { useWeatherCacheEngine } from 'projectSections/hooks/useWeatherCacheEngine'
import { useLyraDesignValidation } from 'projectSections/integrations/lyra/useLyraDesignValidation'
import { Design } from 'projectSections/sections/design/Design'
import infoFields from 'projectSections/sections/info/fields'
import { useCashflowCustomerInfoValidations } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormWithRedirect, useTranslate } from 'react-admin'
import { FormSpy } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { SectionType } from 'types/section'
import { RootState } from 'types/state'
import { CommandType } from 'types/studio/commands'
import { currencySymbolForCountry } from 'util/misc'
import { clearImagery as clearImageryAction, detectImagery as detectImageryAction } from '../../actions/designer'
import SubHeader from '../elements/header'
import useLocalAutoSave from '../hooks/useLocalAutoSave'
import Sections from '../sections'
import CalcImpactListener from './CalcImpactListener'
import { useProjectFormMutators } from './mutations'

type FormTypes = {
  content: React.ReactElement
  submitting: boolean
  form: any
  previousUnsavedData: any
}

const confirmBeforeLeave = () => {
  if (!window.disableAutoSave && window.projectForm?.mutators.getFormDirtyFields().length > 0) {
    // Probably won't be shown, see https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup
    return `If you leave this page without saving, you will lose all your unapplied changes. Do you wish to continue?`
  }
  return null
}

const FormWithListeners = (props: FormTypes) => {
  const { form, submitting, previousUnsavedData } = props
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('project'))
  const allowEditRef = useRef(allowEdit)
  allowEditRef.current = allowEdit
  const viewAsCustomer = useSelector((state: RootState) => state.viewAsCustomer)
  const refreshedAvailableActions = useSelector((state: RootState) => state.myEnergy?.availableCustomerActions)
  const sectionRef = useRef<SectionType | undefined>()
  const section = useSelector((state: RootState) => state.project?.section)
  sectionRef.current = section

  const formState = form.getState()
  const projectId = formState.values?.id
  let isLite = formState.values.is_lite
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const org_id = useSelector(authSelectors.getOrgId)
  const location = useLocation()
  const translate = useTranslate()
  const history = useHistory()

  var projectLocation = window.AccountHelper.projectLocation()
  var country_iso2 = projectLocation.country_iso2 || formState?.values?.country_iso2

  useDesignRulesEngine()

  const loadStudioProfileEarly = useFeatureLoadStudioProfileEarly()
  useLoadStudioProfile(loadStudioProfileEarly, true)

  const selectImageryEarly = useFeatureSelectImageryEarly()
  useAutoSelectDesignImagery(selectImageryEarly)
  OsTerrain.RUN_DELATIN_ASYNC = selectImageryEarly

  useEffect(() => {
    // Update redux with basic project info
    dispatch(
      projectInfoActions.setProjectInfo({
        id: projectId,
        country_iso2,
      })
    )

    return () => {
      dispatch(projectInfoActions.clearProjectInfo())
    }
  }, [country_iso2])

  const liteUserPojectConflict = useMemo(() => {
    return projectId && isUserLite !== undefined && isUserLite !== !!isLite
  }, [projectId, isUserLite, isLite])

  const [liteConflictDismissed, setLiteConflictDismissed] = useState(false)

  const autoSaveEnabled = useAutoSaveProjectEnabled()
  window.WorkspaceHelper.showSaveNotifications = !autoSaveEnabled

  useEffect(() => {
    // Register a handful of fields that to be able to modify (most will already be added by Design)
    const requiredFields = ['is_lite']
    for (var i of requiredFields) {
      form.registerField(i, () => {}, {})
    }

    // when project form unmounts reset the redux state that is used to force systems and payment options to appear sold after credit approval
    return () => {
      dispatch(resetSoldOptions())
    }
  }, [])

  useEffect(() => {
    if (isLite) {
      window.objectTypeBlacklist = {
        OsEdge: true,
        OsObstruction: true,
        OsStructure: true,
        OsClipper: true,
        OsTree: true,
        OsAnnotation: true,
        OsModuleGrid: true,
      }
    } else {
      window.objectTypeBlacklist = undefined
    }
  }, [isLite])

  // When coming from AddressSearch, the address details will come through in the location.state
  // and we should transfer these into the project form, so that the initial save captures them.
  useEffect(() => {
    if (location.state) {
      form.batch(() => {
        if (!form.getState().values.org_id) {
          form.registerField('org_id', () => {}, {})
          form.change('org_id', org_id)
        }

        const locState = location.state as Record<string, any>

        for (var i in locState) {
          if (!infoFields.includes(i)) continue
          console.debug(`Setting initial project value from location.state: ${i} = ${locState[i]}`)
          form.registerField(i, () => {}, {})
          form.change(i, locState[i])
        }
        isLite = !!form.getState().values.is_lite
      })
    }
  }, [])

  useWeatherCacheEngine(formState.values?.lon, formState.values?.lat, formState.values?.configuration?.weather_dataset)

  // Register custom_data, as it doesn't have a field in the form
  // Used by several downstream systems
  useEffect(() => {
    form.registerField('custom_data', () => {}, {})
  }, [])

  // Set up project errors/warnings into redux logic
  useAddProjectErrors()

  // set up auto-save
  useAutoSaveProject(form)

  // set up Lyra validations
  useLyraDesignValidation()

  //fetch new events if necessary
  useLoadProjectEvents()

  // set up cashflow customer info validations
  useCashflowCustomerInfoValidations()

  // set up studio extensions
  useStudioExtensions()
  useSnapshotExtension()

  const storeUnsavedData = useLocalAutoSave(form, previousUnsavedData)
  const dispatch = useDispatch()

  const handleHistoryChanged = useCallback((cmds) => {
    if (
      window.editor &&
      window.editor.sceneIsLoading === false &&
      sectionRef?.current !== 'explore' &&
      allowEditRef.current
    ) {
      if (cmds && cmds.every((cmd: CommandType) => cmd.isEphemeral)) {
        console.log('command is ephemeral, do not mark design as dirty')
      } else {
        // detect unsaved design by listening to historyChanged signal
        form.mutators.markFieldAsDirty('design')
        // The following is a hack to ensure <Header /> get re-rendered (this is only apply once)
        form.change('design', 'has unsaved change')
        storeUnsavedData()
      }
    }
  }, [])

  const handleSystemCalculationChanged = useCallback(() => {
    form.mutators.requireSystemCalcs(false)
    var dirtyFields = form.mutators.getFormDirtyFields() || []
    convertFields(dirtyFields)
    // if (client?.readyState === 1) onUnsavedFieldsChanged(dirtyFields, projectId)
    console.log('WebSocket: Notify unsaved fields changed', projectId, dirtyFields)
  }, [])

  const availableImageryTypes = useSelector(
    (state: RootState) => state.designer?.detectImagery?.availableMapTypes || []
  )
  const detectImageryStatus = useSelector((state: RootState) => state.designer?.detectImagery?.status || null)
  const detectImageryCacheKey = useSelector((state: RootState) => state.designer?.detectImagery?.cacheKey || null)

  const detectImageryStateRef = useRef({ availableImageryTypes, detectImageryStatus, detectImageryCacheKey })
  detectImageryStateRef.current = { availableImageryTypes, detectImageryStatus, detectImageryCacheKey }

  const loadAvailableImagery = () => {
    // Skip if we already have imagery loaded at this location

    const formState = form.getState()

    let sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()

    var projectLocation = window.AccountHelper.projectLocation()
    var country_iso2 = projectLocation.country_iso2 || formState?.values?.country_iso2
    var state = projectLocation.state || formState?.values?.state
    var address = projectLocation.address || formState?.values?.address

    let premiumImgResponse = window.AccountHelper.getIsPremiumImageryAvailable(sceneOrigin4326, country_iso2, state)
    let premiumImgIsAvailable = !!premiumImgResponse.isAvailable

    // TO-DO, is this call to getIsPremiumImageryAvailable necessary? Need to figure out how to just have it be called once on design page render

    var identifier = 'cachedGetMapTypesAtLocationRequest'
    var cachedGetMapTypesAtLocationRequest = window.AccountHelper.terrainUrlsCache.get(
      identifier,
      sceneOrigin4326,
      country_iso2,
      state,
      premiumImgIsAvailable
    )
    if (
      cachedGetMapTypesAtLocationRequest &&
      detectImageryStateRef.current?.availableImageryTypes &&
      detectImageryStateRef.current?.availableImageryTypes.length > 0
    ) {
      console.log('Using cached cachedGetMapTypesAtLocation')
    } else {
      // Only detect imagery if not already-loaded and not currently-loading
      if (
        !detectImageryStateRef.current?.availableImageryTypes ||
        detectImageryStateRef.current?.availableImageryTypes.length === 0
      ) {
        // Do not attempt to load imagery if imagery is already being loaded at this location
        // But if imagery detection is in progress and the location is different to what we require then we should
        // call detectImagery which will cancel the old request to ensure we get data for the correct location
        var expectedDetectImageryCacheKey = window.AccountHelper.terrainUrlsCache.key(
          identifier,
          sceneOrigin4326,
          country_iso2,
          state,
          premiumImgIsAvailable
        )

        if (
          detectImageryStateRef.current?.detectImageryStatus === 'idle' ||
          expectedDetectImageryCacheKey !== detectImageryStateRef.current?.detectImageryCacheKey
        ) {
          // Do not preload 3D when loading imagery into Design mode toolbar, we only loaded it for new projects
          // when clicking address auto-complete dropdown
          // var preferredMapType = window.getDefaultMapType()
          // var preloadTerrainProvider = window.mapTypeToProvider[preferredMapType]
          // preloadTerrainProvider = 'google'
          var preloadTerrainProvider = null
          var is_lite = window.projectForm.getState().values.is_lite
          dispatch(
            detectImageryAction(
              sceneOrigin4326,
              country_iso2,
              state,
              address,
              premiumImgIsAvailable,
              preloadTerrainProvider,
              is_lite,
              undefined,
              false
            )
          )
        }
      }
    }
  }

  const clearAvailableImagery = useCallback(() => {
    dispatch(clearImageryAction())
  }, [])

  const resetProjectViewSettings = useCallback(() => {
    dispatch(projectViewSettingsActions.clearState())
  }, [])

  // various parts of the app will fetch a new array of available actions. Listen to chagnes for that and if the project id matches, update the form value and mark as clean
  useEffect(() => {
    if (refreshedAvailableActions && refreshedAvailableActions?.length > 0) {
      const refreshedForThisProject = refreshedAvailableActions?.filter((act: any) => {
        const isThisProject = act.actions_available?.filter((act: any) => act.project_id === projectId)?.length > 0
        return isThisProject
      })
      if (refreshedForThisProject && refreshedForThisProject?.length > 0) {
        form.change('available_customer_actions', refreshedForThisProject)
      }
    }
  }, [refreshedAvailableActions])

  useEffect(() => {
    window.editor.signals.historyChanged.add(handleHistoryChanged)
    window.editor.signals.systemCalculationsUpdated.add(handleSystemCalculationChanged)
    window.projectForm = form
    window.onbeforeunload = confirmBeforeLeave

    window.loadAvailableImagery = loadAvailableImagery
    window.clearAvailableImagery = clearAvailableImagery

    console.log('WebSocket: Notify viewing project', projectId)

    return () => {
      window.editor.signals.historyChanged.remove(handleHistoryChanged)
      window.editor.signals.systemCalculationsUpdated.remove(handleSystemCalculationChanged)
      window.projectForm = null
      window.onbeforeunload = null

      window.loadAvailableImagery = null
      window.clearAvailableImagery = null

      clearAvailableImagery()
      resetProjectViewSettings()

      console.log('WebSocket: Notify leaving project', projectId)
    }
  }, [])

  const convertFields = (fields) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].includes('contacts_new.0.')) {
        fields[i] = fields[i].replace('contacts_new.0.', 'resources.contacts.fields.')
      }
    }
    return fields
  }

  const projectCountry = formState.values['country_iso2']
  const projectCurrencySymbol = currencySymbolForCountry(projectCountry)
  const orgCurrencySymbol = useContext(CurrencySymbolContext)

  return (
    <CurrencySymbolContext.Provider value={projectCurrencySymbol || orgCurrencySymbol}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <LeaveProjectPrompt isProjectSubmitting={formState.submitting} />
        <Confirm
          open={liteUserPojectConflict && !liteConflictDismissed}
          header={{
            title: translate(isLite ? 'Viewing OpenSolar Lite Project' : 'Viewing Non-OpenSolar Lite Project'),
          }}
          content={translate(
            isLite
              ? 'This project was designed using a limited range of features and options using OpenSolar Lite. By clicking continue you will view the project in OpenSolar Lite.'
              : 'This project was designed using OpenSolar, the fully-featured tool that provides the complete suite of design, sales, and management functionality.'
          )}
          buttons={[
            {
              children: 'Return to Projects',
              onClick: () => history.push('/projects'),
            },
            {
              color: 'primary',
              children: 'Continue',
              onClick: () => setLiteConflictDismissed(true),
            },
          ]}
        />
        <ProgressOverlay />
        <SocketDialog />
        <CustomFormDialog />
        {isLite ? (
          <Design />
        ) : (
          <>
            {!viewAsCustomer && (
              <FormSpy subscription={{ dirtyFields: true, errors: true, hasSubmitErrors: true, submitErrors: true }}>
                {(spyProps) => <SubHeader submitting={submitting} />}
              </FormSpy>
            )}
            <Sections submitting={submitting} />
          </>
        )}
        <FormSpy
          subscription={{ active: true, hasSubmitErrors: true, submitErrors: true }}
          onChange={(changes) => {
            const { active, submitErrors } = changes
            //To do: handle JSON stringified data like usage
            if (active && submitErrors && submitErrors[active]) {
              form.mutators.clearSubmitErrorsByFieldName(active)
            }
          }}
        />
        <FormSpy subscription={{ values: true }} onChange={storeUnsavedData} />
        <FormSpy subscription={{ values: true }}>{({ values }) => <CalcImpactListener values={values} />}</FormSpy>
      </form>
    </CurrencySymbolContext.Provider>
  )
}

const ProjectForm = (props: any) => {
  // if (client?.readyState === 1 && enableConcurrentEditingProtection) connectClient()

  const discardChanges = useProjectDiscard()
  const mutations = useProjectFormMutators()

  return (
    <>
      <FormWithRedirect
        submitOnEnter={false}
        //have to set keepDirtyOnReinitialize to true since some initial values are loaded asynchronously e.g. new_contact
        keepDirtyOnReinitialize={true}
        mutators={{ ...mutations, discardChanges }}
        render={FormWithListeners}
        {...props}
      />
    </>
  )
}

export default ProjectForm
