import { FC } from 'react'

/**
 * * Icon Component for: alert-circle
 */
type AlertCircleOutlineIconProps = {
  size?: number
  color?: string
}
export const AlertCircleOutlineIcon: FC<AlertCircleOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M10 10V5.5M10 13.3354V13.375M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
