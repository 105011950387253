import { styled } from '../../styles/styled'

const AcceptProposalWrapper = styled('div')<{ textColor: string }>(({ theme, textColor }) => {
  return {
    color: textColor,
    textAlign: 'center',
    margin: '15px 10px',
  }
})

export default AcceptProposalWrapper
