import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

/**
 * * Icon Component for: link-external
 */

const LinkExternalOutlineIconSvg: FC<IconProps> = (props) => {
  const { stroke, fill, ...otherProps } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...otherProps}>
      <path
        d="M8.875 1H4.375C2.51104 1 1 2.51103 1 4.37498V15.625C1 17.489 2.51104 19 4.375 19H15.625C17.489 19 19 17.489 19 15.625V11.1249M13.3744 1.00027L19 1M19 1V6.06261M19 1L9.43667 10.5622"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LinkExternalOutlineIcon = createStandardIcon(LinkExternalOutlineIconSvg)
