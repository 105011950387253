import { watchActiveComponentRequest } from './activeComponent'
import { watchAutoStringingRequest } from './autoStringing'
import {
  watchAsyncCalculationPartialComplete,
  watchCalculateSystem,
  watchSystemCalculationRequest,
} from './calculation'
import { watchDetectImagery } from './detectImagery'
import { watchNotifications } from './notifications'
import { watchProjectSectionClear } from './projectErrors'

export default [
  watchActiveComponentRequest,
  watchSystemCalculationRequest,
  watchCalculateSystem,
  watchAsyncCalculationPartialComplete,
  watchAutoStringingRequest,
  watchDetectImagery,
  watchProjectSectionClear,
  watchNotifications,
]
