import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon, IconButton, styled } from 'opensolar-ui'
import { ReactNode, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface PropTypes<T> {
  cards: T[]
  header: string
  render: (card: T, index: number) => ReactNode
}

const Header = styled('h1', { name: 'Header' })({
  fontSize: 24,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
})

const SliderControl = styled('div', { name: 'SliderControl' })({
  marginLeft: 'auto',
})
const CardContainer = styled(Slider, { name: 'CardContainer' })({
  '& .slick-track': {
    margin: 1,
    display: 'flex',
    gap: 20,
  },
})

const ContentSlider = <T,>({ cards, header, render }: PropTypes<T>) => {
  let sliderRef = useRef(null)
  const handlePrev = () => {
    //@ts-ignore
    sliderRef.slickPrev()
  }
  const handleNext = () => {
    //@ts-ignore
    sliderRef.slickNext()
  }
  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
  }

  if (!cards || !cards.length) return null
  return (
    <>
      <Header>
        {header && <span>{header}</span>}
        {sliderRef && (
          <SliderControl>
            <IconButton onClick={handlePrev}>
              <ChevronLeftOutlineIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ChevronRightOutlineIcon />
            </IconButton>
          </SliderControl>
        )}
      </Header>
      <CardContainer
        ref={(slider) => {
          sliderRef = slider
        }}
        {...settings}
      >
        {cards.map((card, i) => render(card, i))}
      </CardContainer>
    </>
  )
}
export default ContentSlider
