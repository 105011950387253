import { FC } from 'react'
import type { IconProps } from './type'

interface TableViewIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const TableViewIcon: FC<TableViewIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  const viewBox = `0 0 ${size} ${size}`
  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 4C10.5 3.44772 10.0523 3 9.5 3C8.94771 3 8.5 3.44772 8.5 4H10.5ZM8.5 20C8.5 20.5523 8.94771 21 9.5 21C10.0523 21 10.5 20.5523 10.5 20H8.5ZM7 5H17V3H7V5ZM19 7V17H21V7H19ZM17 19H7V21H17V19ZM5 17V7H3V17H5ZM7 19C5.89543 19 5 18.1046 5 17H3C3 19.2091 4.79086 21 7 21V19ZM19 17C19 18.1046 18.1046 19 17 19V21C19.2091 21 21 19.2091 21 17H19ZM17 5C18.1046 5 19 5.89543 19 7H21C21 4.79086 19.2091 3 17 3V5ZM7 3C4.79086 3 3 4.79086 3 7H5C5 5.89543 5.89543 5 7 5V3ZM8.5 4V20H10.5V4H8.5ZM4.5 10.5H19.5V8.5H4.5V10.5Z"
        fill={color}
      />
    </svg>
  )
}
