import { FC } from 'react'

/**
 * * Icon Component for: column-vertical-03
 */
type ColumnVerticalIconProps = {
  size?: number
  color?: string
}
export const ColumnVerticalIcon: FC<ColumnVerticalIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M7.10238 1L17.0274 1C18.1168 1 19 1.88316 19 2.9726V17.0274C19 18.1168 18.1168 19 17.0274 19H7.10238M7.10238 1L2.9726 1C1.88316 1 1 1.88316 1 2.9726L1 17.0274C1 18.1168 1.88317 19 2.9726 19H7.10238M7.10238 1V19M13.1127 1V19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
