import { makeStyles } from '@material-ui/core'
import { Box } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  auditBox: {
    backgroundColor: '#f5f5f5', // Explicit color instead of theme.palette.grey[100]
    padding: '16px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: '24px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
  },
  auditField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  auditLabel: {
    fontWeight: 500,
    marginRight: '16px',
    minWidth: '100px',
    color: '#333333',
  },
  auditValue: {
    color: '#666666',
  },
}))

export const AuditInfo = ({ record }) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (!record) return null

  const auditFields = [
    {
      label: 'Created by',
      value: record.created_by_user_email,
    },
    {
      label: 'Modified by',
      value: record.modified_by_user_email,
    },
    {
      label: 'Modified date',
      value: record.modified_date ? new Date(record.modified_date).toLocaleString() : null,
    },
  ].filter((field) => field.value)

  if (auditFields.length === 0) return null

  return (
    <Box className={classes.auditBox}>
      {auditFields.map(({ label, value }) => (
        <div key={label} className={classes.auditField}>
          <span className={classes.auditLabel}>{translate(label)}:</span>
          <span className={classes.auditValue}>{value}</span>
        </div>
      ))}
    </Box>
  )
}
