import * as Sentry from '@sentry/react'
import { addProjectError, clearProjectErrors, projectPermissionsActions, updateProjectSection } from 'actions/project'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { updateRecentProject } from 'ducks/recentProjects'
import { setStudioProfileState } from 'ducks/studio'
import { isNestedWindow } from 'opensolar-sdk'
import { showNotification, useErrorHandling, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { projectErrorSelector, ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import { PermissionSet } from 'types/roles'
import { RootState } from 'types/state'
import { LoadSceneError, LoadSceneLogs, LoadSceneWarning } from 'types/studio/editor'
import { fetchRequest } from 'util/fetch'
import { useFeatureFlag } from 'util/split'
import PageLoadingSkeleton from './elements/displayComponents/PageLoadingSkeleton'
import { ProjectLoadErrorPrompt, ProjectLoadErrorTypes } from './elements/ProjectLoadErrorPrompt'
import { parseInitialValues } from './form/parser'
import ProjectForm from './form/ProjectForm'
import { useFeatureLoadStudioProfileEarly } from './hooks/useFeatureLoadStudioProfileEarly'
import useProjectSave from './hooks/useProjectSave'
import useRefreshDesignPermissions from './hooks/useRefreshDesignPermissions'

export const AjaxSessionContext = React.createContext<number | undefined>(undefined)

type ProjectIdType = 'new' | number

const ProjectSections: React.FC<RouterProps> = (props) => {
  const { projectId, previousUnsavedData } = props
  const apiKeyGoogle = useSelector((state: RootState) => state.auth?.api_key_google)
  const apiKeyVexcel = useSelector((state: RootState) => orgSelectors.getOrg(state)?.api_key_vexcel)
  const apiKeyBing = useSelector((state: RootState) => orgSelectors.getOrg(state)?.api_key_bing)
  const apiKeyArcGisOSM = useSelector((state: RootState) => orgSelectors.getOrg(state)?.api_key_arcgis_osm)
  const [loadedProjectId, setLoadedProjectId] = useState<ProjectIdType | undefined>()
  const [initialValues, setInitialValues] = useState({})
  const authReloading = useSelector(authSelectors.getAuthReloading)
  const orgLoading = useSelector(orgSelectors.getOrgLoading)
  const authOrgId = useSelector(authSelectors.getOrgId)
  const currentRolePermissions = useSelector(permissionsSelectors.getPermissionsSetting)
  const loadedProjPermissions = useSelector(permissionsSelectors.getProjectPermissionsLoaded)
  useRefreshDesignPermissions()
  const projectErrors = useSelector(projectErrorSelector.getAllErrors) || []
  const [errorOnLoad, setErrorOnLoad] = useState<ProjectLoadErrorTypes>(ProjectLoadErrorTypes.NONE)
  const [restartDesignFunc, setRestartDesignFunc] = useState<(() => void) | null>(null)
  const DSMTerrainWallBlurringOn = useFeatureFlag('dsm_terrain_wall_blurring', 'on')
  const loadStudioProfileEarly = useFeatureLoadStudioProfileEarly()

  const handleSave = useProjectSave()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()

  useEffect(() => {
    window.editor.terrainSettings.wallBlurringActive = !!DSMTerrainWallBlurringOn
    const terrain = window.editor.getTerrain()
    if (terrain) terrain.setWallBlurringActive(!!DSMTerrainWallBlurringOn, { render: true })
  }, [DSMTerrainWallBlurringOn])

  const clearErrorOnLoad = () => {
    setErrorOnLoad(ProjectLoadErrorTypes.NONE)
  }

  //TODO: Move this to use react-admin useDataProvider
  const fetchProjectData = useCallback((projectId: ProjectIdType) => {
    // Do not use localStorage.getItem('org_id') because it can have various problems inside SDK
    // especially if other tabs are also running the App or SDK. Use authOrgId instead.

    const requestUrl = `${window.API_ROOT}/api/orgs/${authOrgId}/projects/${projectId}/`
    const headers = window.Utils.tokenAuthHeaders({
      'Content-Type': 'application/json',
      'X-CSRFToken': window.getCookie('csrftoken') as string,
    })
    const request = new Request(requestUrl, {
      method: 'GET',
      headers: new Headers(headers),
    })
    return fetchRequest(request, { format: 'json' })
  }, [])

  const updateCalcErrorsFromProjectData = useCallback((projectData) => {
    const filter = (error: ProjectErrorType) => error.source === 'lastCalc' || error.source === 'calculations'
    if (projectErrors.find(filter)) {
      dispatch(clearProjectErrors(filter))
    }

    // Handle new calculation error messages format
    if (projectData.calculation_error_messages?.length > 0) {
      // Add each calculation error message to the store
      projectData.calculation_error_messages.forEach((errorMessage) => {
        dispatch(
          addProjectError({
            ...errorMessage,
            systemId: errorMessage.systemId || undefined,
            field: errorMessage.field || undefined,
          })
        )
      })
    } else if (projectData.last_calculation_error && Object.keys(projectData.last_calculation_error).length > 0) {
      /**
       * Maintaining temporary legacy support for older calculation error format (last_calculation_error)
       * @deprecated This will only remaining to support the old error handling until the 1st of March 2025
       *             Errors should be handled as the new format
       *
       * Filter above will also be updated to only check the calculations source
       */
      const detail = window.Designer.getErrorDetail(
        { responseJSON: projectData.last_calculation_error },
        'Unspecified Error.'
      )
      dispatch(showNotification(translate(`Calculation error: ${detail}`), 'warning'))
      dispatch(
        addProjectError({
          message: projectData.last_calculation_error,
          messageType: 'fieldError',
          key: 'LAST_CALC_ERROR',
          severity: 'error',
          systemId: undefined,
          source: 'lastCalc',
          category: 'standalone',
        })
      )
    }
  }, [])

  const updateSharingErrorsFromProjectData = useCallback((projectData) => {
    if (projectData.unshared_items) {
      // this block was extracted from the stack:
      // window.WorkspaceHelper.loadDesign()
      // window.WorkspaceHelper.getUnsharedEntitiesError()
      dispatch(
        addProjectError({
          message: projectData.unshared_items,
          messageType: 'sharingError',
          key: '3PO_ERROR',
          severity: 'warning',
          systemId: undefined,
          source: 'sharing',
          category: 'standalone',
        })
      )
    }
  }, [])

  const updateProjectPermissionsFromProjectData = useCallback(
    (projectData) => {
      // this block was extracted from the stack:
      // window.WorkspaceHelper.loadDesign() ->
      // window.WorkspaceHelper.addProjectPermissionsToReduxStore()
      // also: window.WorkspaceHelper.inheritProjectPermissions
      if (projectData.permissions?.project) {
        const projectPermissions = projectData.permissions?.project
        const rolePermissions = currentRolePermissions || {}
        // changed this from the spread the spread [...] operator to Array.from()
        // as the current TypeScript compilation target (es5) will not allow it
        // unless the --downLevelIteration flag is present
        const permissionKeys = Array.from(new Set(Object.keys(rolePermissions).concat(Object.keys(projectPermissions))))
        let permissions = {}
        permissionKeys.forEach((key) => {
          const roleSetting = rolePermissions[key]
          const projectSetting = projectPermissions[key]
          if (roleSetting && projectSetting) {
            permissions[key] = {
              view: !!roleSetting.view && !!projectSetting.view,
              create: !!roleSetting.create && !!projectSetting.create,
              edit: !!roleSetting.edit && !!projectSetting.edit,
              delete: !!roleSetting.delete && !!projectSetting.delete,
            }
          } else if (roleSetting && !projectSetting) {
            permissions[key] = {
              view: !!roleSetting.view,
              create: !!roleSetting.create,
              edit: !!roleSetting.edit,
              delete: !!roleSetting.delete,
            }
          }
        })
        dispatch(projectPermissionsActions.updateProjectPermissions(permissions as PermissionSet))
      } else {
        dispatch(projectPermissionsActions.inheritProjectPermissions())
      }
    },
    [currentRolePermissions]
  )

  const captureLoadSceneLogs = useCallback((logs: LoadSceneLogs) => {
    logs.warnings.forEach((warning: LoadSceneWarning) => {
      Sentry.captureException(warning.warning, {
        level: 'warning',
        contexts: {
          object: {
            source: 'studio_load_scene',
            uuid: warning.object.uuid,
            type: warning.object.type,
            userData: JSON.stringify(warning.object.userData),
          },
        },
      })
    })

    logs.errors.forEach((error: LoadSceneError) => {
      Sentry.captureException(error.error, {
        level: 'error',
        contexts: {
          object: {
            source: 'studio_load_scene',
            uuid: error.object.uuid,
            type: error.object.type,
            userData: JSON.stringify(error.object.userData),
            removed: error.objectRemoved,
          },
        },
      })
    })
  }, [])

  const loadDesignFromProjectData = useCallback((projectData) => {
    // deserialize the project's design data then load it in studio
    projectData.design = projectData.design ? window.editor.deserializeDesign(projectData.design) : null

    // I don't have enough context to refactor this awkward block of code...
    // check to see if any payment options have validation errors on them. If so add them to redux
    if (projectData.design?.object) {
      const systems = projectData.design.object?.children?.filter((child) => child.type === 'OsSystem')
      systems?.forEach((system) => {
        if (system.userData) {
          window.WorkspaceHelper.refreshPaymentOptionWarnings(system.userData)
        }
      })
    }

    let initData = undefined
    if (projectData.design?.init) {
      initData = projectData.design.init
      projectData.design = null
    }

    if (!projectData || !projectData.design) {
      window.WorkspaceHelper.onLoaded(window.editor, false, projectData, initData)
      return
    }

    setRestartDesignFunc(() => () => {
      // because the previous attempt to load the design data resulted in an error
      // we assume the editor's scene is not anymore in pristine condition
      // we bring it back to a clean state first before restarting the design
      window.editor.loadStarterScene(window.WorkspaceHelper.params)
      // setting the isSceneReady param to false will create a new design
      // using the default design mode and imagery
      window.WorkspaceHelper.onLoaded(window.editor, false, projectData, initData)
      clearErrorOnLoad()
    })

    // attempt to load the project's design data into studio
    // if we encounter a runtime error, we handle it separately from
    // the project-level errors
    try {
      window.WorkspaceHelper.setParamsFromProjectData(projectData)
      window.editor.loadScene(projectData.design, window.WorkspaceHelper.params).then((logs) => {
        captureLoadSceneLogs(logs)
        if (logs.errors.some((e) => e.objectRemoved)) {
          // some objects were removed from the scene due to runtime error(s)
          // might be a corrupted / malformed design data or a flaw in the loading process
          setErrorOnLoad(ProjectLoadErrorTypes.DESIGN_RECOVERABLE)
        }
      })
      window.WorkspaceHelper.onLoaded(window.editor, true, projectData)
      if (window.Designer.scheduleRefreshStrings) {
        window.Designer.scheduleRefreshStrings()
      }
    } catch (error) {
      setErrorOnLoad(ProjectLoadErrorTypes.DESIGN_NON_RECOVERABLE)
      if (error instanceof Error) {
        // error should always be of type Error, but just to satisfy TS
        errorHandling.unexpected(error, { notify: false })
      }
    }
  }, [])

  useEffect(() => {
    window.Designer.AjaxSession.invalidateAllPendingRequests()
    const ajaxSessionToken = window.Designer.AjaxSession.createAjaxSessionToken({
      url: `${window.API_ROOT}/api/orgs/${authOrgId}/projects/${projectId}/`,
      nameSpace: 'fetchProjectData',
    })

    console.debug('Project begin load:', projectId)
    const initializeProjectData = async (projectId: ProjectIdType) => {
      clearErrorOnLoad()
      setRestartDesignFunc(null)

      window.setStudioMode('hidden')
      // Never clear overrideApiKeyGoogle, only overwrite with a new (not-empty) value
      // to avoid some race-condition bugs
      if (apiKeyGoogle) {
        window.AccountHelper.overrideApiKeyGoogle = apiKeyGoogle
      }
      if (apiKeyBing) {
        window.AccountHelper.overrideApiKeyBing = apiKeyBing
      }

      if (apiKeyArcGisOSM) {
        window.AccountHelper.overrideApiKeyArcGisOSM = apiKeyArcGisOSM
      }

      if (!isNaN(projectId as any)) {
        window.WorkspaceHelper.setProjectData({ id: projectId as number })
        window.WorkspaceHelper.designIsLoading = true

        await fetchProjectData(projectId)
          .then((result) => {
            if (!window.Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
              // break the promise chain if the token is invalid
              throw new Error('Ajax session token is invalid.')
            }
            if (window.WorkspaceHelper.designIsLoading === true) {
              window.studioDebug && console.warn('Loading new design but WorkspaceHelper.designIsLoading === true')
            }
            return result.json
          })
          .then((projectData) => {
            // routine redux updates
            updateCalcErrorsFromProjectData(projectData)
            updateSharingErrorsFromProjectData(projectData)
            updateProjectPermissionsFromProjectData(projectData)
            return projectData
          })
          .then((projectData) => {
            // Do not call loadStudioProfile() if we are creating a new design because it breaks default view detection.
            // Ignore this block if we are going to auto-create a new design and let this all happen inside <Design />
            // which also calls loadStudioProfile() but it does not have the same problem with default view detection.
            // @TODO: Untangle this whole aspect of loading/creating designs after awaiting necessary data.
            if (isNestedWindow() && window.editor.deserializeDesign(projectData.design)) {
              // This addresses some issues encountered in SDK when loading a project before component_specs
              // have finished loading. Non-SDK scenarios will just continue and let the component_specs load async.
              return window.WorkspaceHelper.loadStudioProfile({
                id: projectData.id,
                org_id: projectData.org_id,
                identifier: projectData.identifier,
              }).then(() => {
                return projectData
              })
            } else {
              return projectData
            }
          })
          .then((projectData) => {
            // parse and load, into studio, the project's design data
            //this will handle separately any errors caused by the design data
            loadDesignFromProjectData(projectData)
            return projectData
          })
          .then((projectData) => {
            // initialize the project form from raw project data
            const parsedData = parseInitialValues(projectData)
            dispatch(updateRecentProject(parsedData))
            setInitialValues(parsedData)
          })
          .catch((error: Error) => {
            // handle project-level errors here
            if (!window.Designer.AjaxSession.validateAjaxSessionToken(ajaxSessionToken)) {
              if (window.studioDebug) {
                console.log('******** request aborted token: ', ajaxSessionToken)
              }
              // skip sending error message if the request was aborted
              return
            }
            window.WorkspaceHelper.cancelLoading()
            setErrorOnLoad(ProjectLoadErrorTypes.PROJECT)
            const errMsg = translate(error.message || '') // will often be the standard `ra.notifications.http_error` message
            errorHandling.data_provider(error, {
              msg: translate(`Failed to load project %{projectId}`, { projectId }),
            })
          })
          .finally(() => {
            window.WorkspaceHelper.designIsLoading = false
          })
      } else {
        dispatch(projectPermissionsActions.inheritProjectPermissions())
      }

      setLoadedProjectId(projectId)
      console.debug('Project end load:', projectId)
    }

    initializeProjectData(projectId)

    return () => {
      dispatch(updateProjectSection())
      //clear helpers
      window.WorkspaceHelper.clear()
      window.WorkspaceHelper.cancelLoading()
      window.ViewBoxHelper.clear()
      window.ViewBoxHelper.clearStore()
      window.editor.clear()
      window.setStudioMode('hidden')

      // Can be removed once `load_studio_profile_early` feature is through the pipeline
      if (!loadStudioProfileEarly) {
        window.AccountHelper.reset()
        dispatch(setStudioProfileState('not-loaded'))
      }
    }
  }, [projectId])

  if (authReloading || orgLoading || loadedProjectId !== projectId || !loadedProjPermissions || errorOnLoad) {
    return (
      <>
        <ProjectLoadErrorPrompt
          errorOnLoad={errorOnLoad}
          recoverDesignFunc={clearErrorOnLoad}
          restartDesignFunc={restartDesignFunc}
        />
        <PageLoadingSkeleton showTopNav={true} />
      </>
    )
  } else {
    return (
      <div>
        <ProjectForm save={handleSave} previousUnsavedData={previousUnsavedData} initialValues={initialValues} />
      </div>
    )
  }
}

interface RouterProps {
  projectId: ProjectIdType
  previousUnsavedData: any
}

export default ProjectSections
