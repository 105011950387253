import { Paper } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import ImageDisplayField from 'elements/field/ImageDisplayField'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { SimpleForm, TextInput, useTranslate } from 'react-admin'
import { FormSpy } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useResourceStyles } from 'resources/styles'
import { required } from 'validations'

const InputWrapper = styled(Paper, { name: 'InputWrapper' })({
  display: 'flex',
  flexDirection: 'column',
})

const BrandForm = (props) => {
  const translate = useTranslate()
  const resourceStyles = useResourceStyles()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const disabled = useMemo(() => !isStaff || props.disabled, [props.disabled, isStaff])
  if (!isStaff) return null
  return (
    <SimpleForm {...props}>
      <InputWrapper elevation={1} className={resourceStyles.formSection}>
        <TextInput label={'Name'} disabled={disabled} name="name" source="name" validate={required} />
        <FormSpy subscription={{ values: true }}>
          {(props) => {
            const formValues = props.form.getState().values

            return (
              <TextInput
                label={'URL Slug'}
                disabled={disabled || !isStaff}
                fullWidth={true}
                name="slug"
                source="slug"
                helperText={'app.opensolar.com/expo/' + (formValues.slug || '')}
                validate={required}
              />
            )
          }}
        </FormSpy>
        <ImageDisplayField
          record={props.record}
          name={'logo_contents'}
          source={'logo_contents'}
          defaultValue={null}
          label="Brand Logo"
          accept="image/*"
          placeholder={<p>{translate('Drop your image here')}</p>}
          disabled={disabled}
          maxWidth={200}
        />
      </InputWrapper>
    </SimpleForm>
  )
}

export default BrandForm
