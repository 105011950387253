import { makeStyles, Paper, Popover } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { MapDataTypes } from 'types/map'
import { ViewTypes } from 'types/view'
import Tools from './PopoverTools'
import PopoverViewSelector from './PopoverViewSelector'
import ShowCustomerToggle from './ShowCustomerToggle'

const PopoverDecorator = () => {
  return (
    <div
      style={{
        width: 20,
        height: 20,
        display: 'inline-block',
        marginLeft: 50,
        background: 'rgb(255,255,255)',
        transform: 'rotate(45deg) translateX(-50%) translateY(-50%)',
      }}
    ></div>
  )
}

const useStyles = makeStyles((theme: any) => ({
  popover: {
    background: 'rgba(255,255,255,0)',
    boxShadow: 'none',
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  root: {
    opacity: 0.9,
  },
  content: {
    width: 200,
    padding: 10,
    pointerEvents: 'all',
  },
}))

type PopupPropTypes = {
  anchorEl: HTMLElement | null
  handleClose: () => void
  view: ViewTypes | any
  availableImageryTypes: MapDataTypes[] | []
}

const ToolbarViewsPopoverMobile: React.FC<PopupPropTypes> = ({
  anchorEl,
  handleClose,
  view,
  availableImageryTypes,
}) => {
  const classes = useStyles()
  const [count, setCount] = useState(0)
  const forcePopoverReposition = useCallback(() => {
    setCount(count + 1)
  }, [count])
  const mode = view.uuid === 'new' ? 'add' : 'change'
  return (
    <Popover
      open={Boolean(anchorEl)}
      id={'toolbar-view-popover' + view?.uuid}
      anchorEl={anchorEl}
      classes={{ root: classes.root, paper: classes.popover }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={handleClose}
    >
      {Boolean(anchorEl) && (
        <>
          <Paper classes={{ root: classes.content }}>
            {mode === 'change' && <Tools handleClose={handleClose} forcePopoverReposition={forcePopoverReposition} />}
            {availableImageryTypes && availableImageryTypes.length > 0 && (
              <PopoverViewSelector
                availableImageryTypes={availableImageryTypes}
                mode={mode}
                forcePopoverReposition={forcePopoverReposition}
              />
            )}
            {mode === 'change' && <ShowCustomerToggle view={view} />}
          </Paper>
          <PopoverDecorator />
        </>
      )}
    </Popover>
  )
}

export default ToolbarViewsPopoverMobile
