export interface CableOption {
  code: string
  spacing: number
  applicableOrientations?: 'portrait' | 'landscape' | 'both'
}

export const cableConfigs: { [countryCode: string]: { [phase: string]: CableOption[] } } = {
  default: {
    '3P': [
      { code: 'Q-25-10-3P', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-25-17-3P', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-25-20-3P', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
    '1P': [
      { code: 'Q-25-10', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-25-17', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-25-20', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
  },
  US: {
    '3P': [
      { code: 'Q-12-10-3P', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-12-17-3P', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-12-20-3P', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
    '1P': [
      { code: 'Q-12-10', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-12-17', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-12-20', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
  },
  CA: {
    '3P': [
      { code: 'Q-12-10-3P', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-12-17-3P', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-12-20-3P', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
    '1P': [
      { code: 'Q-12-10', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'Q-12-17', spacing: 2.0, applicableOrientations: 'landscape' },
      { code: 'Q-12-20', spacing: 2.3, applicableOrientations: 'landscape' },
    ],
  },
  MX: {
    '3P': [
      { code: 'QD-12-13-120', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'QD-12-20-120', spacing: 2.0, applicableOrientations: 'landscape' },
    ],
    '1P': [
      { code: 'QD-12-13-120', spacing: 1.3, applicableOrientations: 'portrait' },
      { code: 'QD-12-20-120', spacing: 2.0, applicableOrientations: 'landscape' },
    ],
  },
}

/**
 * Retrieves cable options based on country code and phase type.
 * @param countryCode - The country code (e.g., 'US', 'EU').
 * @param isThreePhase - Indicates if the system is three-phase.
 * @returns Array of CableOption objects.
 */
export const getCableOptions = (countryCode: string, isThreePhase: boolean): CableOption[] => {
  const phase = isThreePhase ? '3P' : '1P'
  const countryConfig = cableConfigs[countryCode]
  const defaultConfig = cableConfigs['default'][phase] || []

  let options: CableOption[] = []

  if (countryConfig && countryConfig[phase]) {
    options = countryConfig[phase]
  } else {
    //console.warn(`No cable configurations found for country code: ${countryCode}. Using default configuration.`)
    options = defaultConfig
  }

  return options
}
