import { orgSelectors } from 'ducks/orgs'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypesV2 } from 'types/selectComponent'
import { DISTRIBUTOR_FILTER_KEY_V2 } from '../distributor/DistributorFilterConfigV2Factory'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import { DISTRIBUTOR_BRAND_FILTER_KEY_V2 } from './DistributorBrandFilterNodeFactoryV2'

export const EXHIBITOR_FILTER_KEY_V2 = 'exhibitor_org_id'
export const MANUFACTURER_FILTER_KEY_V2 = 'manufacturer'
export const FEATURE_CONFIG_BRAND_FILTER_NODE_KEY_V2 = 'feature_config_brand_filter'
const defaultSelectedOptionValue = ''
const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

type ManufacturerFilterOptionsType = OptionGenericType<string>[]

const defaultLabel = 'Brand'

const getConfigByComponentType = ({
  manufacturerConfig,
  componentType,
}: {
  manufacturerConfig
  componentType: ComponentTypesV2
}) => {
  switch (componentType) {
    case 'module':
      return manufacturerConfig.modules
    case 'inverter':
      return manufacturerConfig.inverters
    case 'battery':
      return manufacturerConfig.batteries
    default:
      return manufacturerConfig.others
  }
}

const getManufacturerOptions = ({
  configs,
  orgIso2,
}: {
  configs
  orgIso2: string | undefined
}): ManufacturerFilterOptionsType | undefined => {
  if (!configs || configs.length === 0) {
    return undefined
  }

  const options: ManufacturerFilterOptionsType = configs.reduce((result, option) => {
    if (option.include_country && !option.include_country.includes(orgIso2)) {
      return result
    }

    result.push({ title: option.title, value: option.value, id: option.value })

    return result
  }, [])

  if (options.length === 0) {
    return undefined
  }

  options.sort((a, b) => (a.title > b.title ? 1 : -1))

  options.unshift(SELECT_ALL_OPTION)

  return options
}

// TODO: fix the hack to parse value by split.
const FeatureConfigManufacturerFilter = <T,>({
  label = defaultLabel,
  disabled,
  allFilterValues,
  setModifiableFilters,
  modifiableFilterValues,
  rendererComponent: RendererComponent,
}: FilterGenericPropsType & ConfigurableFilterOptionsType<string, string>) => {
  const componentTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
    filterValues: allFilterValues,
    key: 'types',
  })
  const manufacturerConfig = usePublicFeatureConfig('manufacturer_filter')
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)

  const manufacturerOptions =
    manufacturerConfig === undefined
      ? undefined
      : getManufacturerOptions({
          configs: getConfigByComponentType({ manufacturerConfig, componentType: Array.from(componentTypes)[0] }),
          orgIso2,
        })
  const onSelect = useCallback(
    (value) => {
      // Always clear first
      const newFilterValues = omit({ ...modifiableFilterValues }, [
        EXHIBITOR_FILTER_KEY_V2,
        MANUFACTURER_FILTER_KEY_V2,
        DISTRIBUTOR_BRAND_FILTER_KEY_V2,
      ])

      if (value === '') {
      } else if (value.includes('manufacturer_')) {
        const manufacturerId = value.split('manufacturer_')[1]
        newFilterValues[MANUFACTURER_FILTER_KEY_V2] = Number(manufacturerId)
      } else if (value.includes('exhibitor_')) {
        const exhibitorId = value.split('exhibitor_org_id_')[1]
        newFilterValues[EXHIBITOR_FILTER_KEY_V2] = Number(exhibitorId)
      }
      setModifiableFilters(newFilterValues)
    },
    [modifiableFilterValues]
  )

  if (manufacturerOptions === undefined) {
    return null
  }

  const selectedManufacturer =
    allFilterValues[MANUFACTURER_FILTER_KEY_V2] !== undefined
      ? `${MANUFACTURER_FILTER_KEY_V2}_${allFilterValues[MANUFACTURER_FILTER_KEY_V2]}`
      : ''
  const selectedExhibitor =
    allFilterValues[EXHIBITOR_FILTER_KEY_V2] !== undefined
      ? `${EXHIBITOR_FILTER_KEY_V2}_${allFilterValues[EXHIBITOR_FILTER_KEY_V2]}`
      : ''

  return (
    <RendererComponent
      id={'feature_config_manufacturer_filter_v2'}
      defaultValue={SELECT_ALL_OPTION.value}
      label={label}
      options={manufacturerOptions}
      disabled={!!disabled}
      onSelect={onSelect}
      value={selectedManufacturer || selectedExhibitor}
    />
  )
}

// The source of this comes from a feature config - the list containing the popular brands based on their country
// This was introduced before v3 brand filter - where brands are based on distributors
const createFeatureConfigBrandFilterNode = <T,>(
  config: ConfigurableFilterOptionsType<string, string>
): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const currentSelectedDistributor = filterProps.allFilterValues[DISTRIBUTOR_FILTER_KEY_V2] // This won't work when distributors turn to multi select checkbox
    if (!!currentSelectedDistributor) return null

    if (!currentSelectedDistributor) {
      return <FeatureConfigManufacturerFilter {...config} {...filterProps} />
    } else {
      return null
    }
  }

  return {
    key: FEATURE_CONFIG_BRAND_FILTER_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createFeatureConfigBrandFilterNode
