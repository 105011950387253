import { AxiosResponse } from 'axios'

export type VERSION_MISMATCH = 'mandatory' | 'optional'

const COMPATIBILITY_TO_MISMATCH_TYPE: Record<1 | 2 | 3 | 4, VERSION_MISMATCH | null> = {
  1: 'mandatory',
  2: 'optional',
  3: null, //patch updates do not require notification of an optional update
  4: null,
}

export const apiVersionMismatchType = (actualVersion: string | undefined, requiredVersion: string | undefined) => {
  const apiCompatibility = apiVersionCompatibility(actualVersion, requiredVersion)
  return COMPATIBILITY_TO_MISMATCH_TYPE[apiCompatibility]
}

export const apiVersionCompatibility = (actualVersion: string | undefined, requiredVersion: string | undefined) => {
  // supported: 4
  // patch: 3
  // minor: 2
  // major: 1

  if (!actualVersion || !requiredVersion) {
    console.debug('API version check failed: ', actualVersion, requiredVersion)
    return 4
  }

  if (requiredVersion === actualVersion) {
    return 4
  }

  var requiredApiVersionParts = requiredVersion.split('.').map(function (value) {
    return parseInt(value)
  })
  var actualApiVersionParts = actualVersion.split('.').map(function (value) {
    return parseInt(value)
  })
  if (actualApiVersionParts[0] < requiredApiVersionParts[0]) {
    return 1 // major
  } else if (actualApiVersionParts[1] < requiredApiVersionParts[1]) {
    return 2 // minor
  } else {
    //supported
    //patch updates do not require notification of an optional update
    return 4
  }
}

export function apiVersion(response: AxiosResponse): AxiosResponse {
  const TALKING_TO_OPENSOLAR_API_VERSION = response.headers['OpenSolar-Api-Version']

  // The built-in type of window does not include our custom types.
  if (window.setVersion) window.setVersion(TALKING_TO_OPENSOLAR_API_VERSION)

  // 'mandatory', 'optional', null
  const mismatchType = apiVersionMismatchType(window.OPENSOLAR_API_VERSION, TALKING_TO_OPENSOLAR_API_VERSION)

  if (mismatchType) {
    if (window.setVersionMismatch) {
      window.setVersionMismatch(mismatchType)
    } else {
      console.error('window.setVersionMismatch not found')
    }
  }

  return response
}
