import { Grid as Mui4Grid, GridProps as Mui4GridPropsType } from '@material-ui/core'
import { default as Mui5Grid } from '@mui/material/Grid'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

// * MUI Grid API Spec  https://mui.com/material-ui/api/Grid/
export const Grid: FC<GridProps> = (props) => (
  <Inspector name="Grid" propDefs={GridPropsDefs} showExample={true} component={OsGrid} props={props} />
)

export enum GridVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type GridProps = Mui4GridPropsType

const OsGrid: FC<GridProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('grid')

  switch (version) {
    case GridVersion.V2:
      return <Mui5Grid ref={ref} {...props} />

    default:
      return <Mui4Grid ref={ref} {...props} />
  }
})

const GridPropsDefs: PropsDefs = {}
