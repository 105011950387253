import { FC } from 'react'

type KeyIconProps = {
  size?: number
  color?: string
}

export const KeyIcon: FC<KeyIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M10.5 20.4229C9.82875 20.4229 9.25712 20.1869 8.78512 19.7149C8.31313 19.2429 8.07713 18.6713 8.07713 18C8.07713 17.3288 8.31313 16.7571 8.78512 16.2851C9.25712 15.8131 9.82875 15.5771 10.5 15.5771C11.1713 15.5771 11.7429 15.8131 12.2149 16.2851C12.6869 16.7571 12.9229 17.3288 12.9229 18C12.9229 18.6713 12.6869 19.2429 12.2149 19.7149C11.7429 20.1869 11.1713 20.4229 10.5 20.4229ZM10.5 26.25C8.2115 26.25 6.26438 25.4471 4.65863 23.8414C3.05288 22.2356 2.25 20.2885 2.25 18C2.25 15.7115 3.05288 13.7644 4.65863 12.1586C6.26438 10.5529 8.2115 9.75 10.5 9.75C12.1077 9.75 13.5616 10.1649 14.8616 10.9946C16.1616 11.8246 17.1539 12.9097 17.8384 14.25H31.3414L35.0914 18L29.322 23.7401L26.5384 21.6491L23.6828 23.769L20.7548 21.75H17.8384C17.1539 23.0808 16.1616 24.1634 14.8616 24.9979C13.5616 25.8326 12.1077 26.25 10.5 26.25ZM10.5 24C11.9385 24 13.1841 23.5654 14.2369 22.6961C15.2899 21.8269 15.977 20.7615 16.2983 19.5H21.4616L23.622 20.9944L26.553 18.8509L29.1 20.7983L31.8983 18L30.3983 16.5H16.2983C15.977 15.2385 15.2899 14.1731 14.2369 13.3039C13.1841 12.4346 11.9385 12 10.5 12C8.85 12 7.4375 12.5875 6.2625 13.7625C5.0875 14.9375 4.5 16.35 4.5 18C4.5 19.65 5.0875 21.0625 6.2625 22.2375C7.4375 23.4125 8.85 24 10.5 24Z"
        fill="black"
      />
    </svg>
  )
}
