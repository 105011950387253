import { makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { Button } from 'opensolar-ui'

import { BaseButtonPropTypes, ButtonSizeType } from './types'

const useButtonStyles = makeStyles<
  any,
  {
    fontColor?: string
    bgColor: string
    border?: string
    disabled: boolean
    hoverStyle?: object
    loading: boolean
    size?: ButtonSizeType
    labelStyle?: object
  }
>(() => ({
  root: ({ fontColor, bgColor, border, disabled, hoverStyle }) => ({
    background: bgColor,
    border: border,
    color: fontColor,
    fontWeight: 400,
    cursor: disabled ? 'not-allowed !important' : undefined,
    '&:hover': hoverStyle,
  }),
  label: ({ fontColor, loading, size, labelStyle }) => ({
    color: loading ? 'transparent' : fontColor,
    fontSize: size === 'extra-small' ? '10px' : undefined,
    ...labelStyle,
  }),
  startIcon: {
    // width: props.isWideIcon ? 'unset' : 'inherit',
    width: 'unset',
    marginLeft: 0,
  },
}))

const useStyles = makeStyles<
  any,
  {
    fontColor?: string
  }
>(() => ({
  spinner: ({ fontColor }) => ({
    color: fontColor,
  }),
}))

interface ProXButtonPropType extends BaseButtonPropTypes {
  id?: string
  submit?: boolean
}

const ProUXButton: React.FunctionComponent<ProXButtonPropType> = (props) => {
  const getFontColor = () => {
    switch (props.type) {
      case 'primary':
        return 'rgb(77, 77, 77)'
      case 'warning':
        if (props.disabled) return '#ececec'
        return '#EE0202'
      default:
        return undefined
    }
  }
  const getBackgroundColor = () => {
    if (props.backgroundColor) return props.backgroundColor
    switch (props.type) {
      case 'primary':
        return '#ffda00'
      case 'secondary':
        return 'rgba(246, 246, 246)'
      case 'text':
        return '#fff'
      case 'warning':
        return '#fff'
      default:
        return 'initial'
    }
  }
  const getHoverStyle = () => {
    switch (props.type) {
      case 'primary':
        return {
          backgroundColor: props.disabled ? '#ffeded' : '#F3B800',
        }
      default:
        return {}
    }
  }
  const getVariant = (): 'contained' | 'outlined' | 'text' => {
    switch (props.type) {
      case 'primary':
        return 'contained'
      case 'secondary':
        return 'contained'
      case 'text':
        return 'text'
      case 'warning':
        return 'text'
      default:
        return 'contained'
    }
  }
  const buttonClasses = useButtonStyles({
    fontColor: getFontColor(),
    bgColor: getBackgroundColor(),
    border: props.border,
    disabled: !!props.disabled,
    hoverStyle: getHoverStyle(),
    loading: !!props.loading,
    size: props.size,
    labelStyle: props.labelStyle,
  })
  const classes = useStyles({
    fontColor: getFontColor(),
  })

  const translate = useTranslate()
  if (props.hideLabel && (props.startIcon || props.endIcon)) {
    // let iconSize: 'small' | 'medium'  | 'large'  | string
    // if (props.size === 'extra-small') {
    //   iconSize = 'small'
    // } else if (props.size !== 'large'&&props.size !== 'small'&&props.size !== 'medium') {
    //   iconSize = string
    // } else {
    //   iconSize = props.size
    // }
    return (
      <div id={props.id} style={{ position: 'relative', width: props.fullWidth ? '100%' : undefined }}>
        <Tooltip title={(props as any).title}>
          <IconButton
            onClick={props.onClick}
            disabled={props.disabled}
            variant={getVariant()}
            classes={buttonClasses}
            //@ts-ignore
            size={props.size === 'extra-small' ? 'small' : props.size}
            style={props.style}
          >
            {props.startIcon
              ? React.cloneElement(props.startIcon, {
                  htmlColor: props.loading ? 'transparent' : props.startIcon?.props?.htmlColor,
                })
              : React.cloneElement((props as any).endIcon, {
                  htmlColor: props.loading ? 'transparent' : props.endIcon?.props?.htmlColor,
                })}
          </IconButton>
        </Tooltip>
        {props.loading && (
          <div style={{ position: 'absolute', left: 'calc(50% - 12px)', top: 'calc(50% - 12px)' }}>
            <CircularProgress classes={{ colorPrimary: classes.spinner }} size={24} />
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div
        id={props.id}
        style={{
          position: 'relative',
          width: props.fullWidth ? '100%' : undefined,
          display: props.fullWidth ? undefined : 'table-cell',
        }}
      >
        <Button
          type={props.submit ? 'submit' : undefined}
          onClick={(props as any).onClick}
          disabled={props.disabled}
          variant={getVariant()}
          classes={classes}
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          size={props.size === 'extra-small' ? 'small' : props.size}
          style={{
            width: props.fullWidth ? '100%' : undefined,
            height: props.fullHeight ? '100%' : undefined,
            ...props.style,
            justifyContent: props.justifyContent,
          }}
        >
          <span style={{ margin: props.isMarginTop ? '10px 0 0 0' : '0', textAlign: 'left' }}>
            {typeof props.label === 'string' ? translate(props.label) : props.label}
          </span>
        </Button>
        {props.loading && (
          <div style={{ position: 'absolute', left: 'calc(50% - 12px)', top: 'calc(50% - 12px)' }}>
            <CircularProgress classes={{ colorPrimary: classes.spinner }} size={24} />
          </div>
        )}
      </div>
    )
  }
}
export default ProUXButton
