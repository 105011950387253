import { Box, Card, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { Button, Typography, styled } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { restClientInstance } from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import Badge from './sharedComponents/Badge'

type PropTypes = {
  showNonAdminDialog: boolean
  setShowMarketingDialog: (boolean) => void
  setShowNonAdminDialog: (boolean) => void
  closeDialog: () => void
}

const StyledGridContainer = styled(Grid)({
  borderTop: '1px #DADBE9 solid',
  padding: '1rem 0',
})

const DialogTitleWrapper = styled('div')({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
})

const DescriptionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  margin: '10px 0',
  width: '90%',
})

const StyledCard = styled(Card)({
  backgroundColor: '#EDF3FF',
  border: 'none',
  textAlign: 'center',
  padding: '1rem',
  marginBottom: '1rem',
})

const ChartImg = styled('img')({
  height: '7rem',
  margin: '10px 0',
})

const StyledShieldIcon = styled(VerifiedUser)({
  height: '1.25rem',
  color: '#019939',
  padding: '10px',
  background: '#DAE5FF',
  borderRadius: 7,
  marginBottom: 5,
})

const PaymentDescWrapper = styled('div')({
  margin: '10px 0',
})

const SubmitBtn = styled(Button)({
  width: '100%',
  fontSize: 13,
})

const BackBtn = styled(Button)({
  color: '#757575',
  fontSize: 13,
})

const NonAdminOnboardingForm: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const [contact, setContact] = useState('')

  useEffect(() => {
    logAmplitudeEvent('psp_onboarding_non_admin_form_viewed', { org_id: orgId })
  }, [])

  const onPageSubmit = () => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/payments/non_admin_onboarding_request/`,
      data: { who_to_contact: contact },
    })
      .then((res) => {
        if (res?.data?.success) {
          notify(translate('Thank you, we will reach out to the administrators in your account'), 'success')
          logAmplitudeEvent('psp_onboarding_non_admin_form_submitted', { org_id: orgId })
        }
      })
      .catch((err) => {
        notify(
          translate('Something went wrong and we were unable to contact the administrators in your account'),
          'warning'
        )
      })
  }

  const onBack = () => {
    props.setShowNonAdminDialog(false)
    props.closeDialog()
    props.setShowMarketingDialog(true)
  }

  return (
    <Dialog open={props.showNonAdminDialog} maxWidth="md">
      <DialogTitle>
        <DialogTitleWrapper>
          {translate('Activate CashFlow')}
          <Badge text={translate('1 min')} />
        </DialogTitleWrapper>
      </DialogTitle>

      <DialogContent>
        <StyledGridContainer container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography textVariant="h5SemiBold">{translate("We'll help you get setup")}</Typography>

            <DescriptionWrapper>
              <Typography textVariant="body1">
                {translate(
                  "OpenSolar CashFlow is the first ever intelligent payments solution built specifically for Solar Pros, by Solar Pros. Let's unlock an easier way to get paid and manage your project accounting!"
                )}
              </Typography>
              <Typography textVariant="body1">
                {translate(
                  "Only users with administrator privileges can set up CashFlow. It looks like you're not an admin, but we can still help you get CashFlow set up."
                )}
              </Typography>
              <Typography textVariant="body1">
                {translate(
                  "We'll reach out to the admins on your account to start CashFlow setup. In the meantime if you have any suggestions who would be best to complete this process, please share that below:"
                )}
              </Typography>
              <TextField
                fullWidth={true}
                id="outlined-multiline-static"
                label={translate('Who at your company is best to set up CashFlow? (Optional)')}
                multiline
                variant="outlined"
                rows={4}
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value)
                }}
              />
            </DescriptionWrapper>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledCard variant="outlined">
              <Typography textVariant="body1SemiBold" colorHex="#2C499D">
                {translate('Pros save 20% or more on credit card transactions*')}
              </Typography>

              <ChartImg src={`${window.PUBLIC_URL}/images/cashflow/pie-chart.svg`} />

              <Typography textVariant="helper1" colorHex="#757575">
                {translate('* Compared to consumer point of sale transaction fees')}
              </Typography>
            </StyledCard>
            <StyledCard variant="outlined">
              <StyledShieldIcon />
              <Typography textVariant="body1SemiBold" colorHex="#2C499D">
                {translate('Safe & Secure')}
              </Typography>

              <PaymentDescWrapper>
                <Typography textVariant="helper1" colorHex="#757575">
                  {translate(
                    'Payment information is secure and encrypted. Our platform prioritizes security and data privacy'
                  )}
                </Typography>
              </PaymentDescWrapper>

              <Box display="flex" alignItems="center" justifyContent="space-evenly">
                <img src={`${window.PUBLIC_URL}/images/cashflow/visa.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/mastercard.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/apple-pay.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/google-pay.svg`} />
                <img src={`${window.PUBLIC_URL}/images/cashflow/paypal.svg`} />
              </Box>
            </StyledCard>
          </Grid>
          <Grid item xs={6}>
            <BackBtn variant="text" onClick={onBack}>
              {translate('Back')}
            </BackBtn>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <SubmitBtn variant="contained" color="primary" onClick={onPageSubmit}>
              {translate("I'm interested in using CashFlow!")}
            </SubmitBtn>
          </Grid>
        </StyledGridContainer>
      </DialogContent>
    </Dialog>
  )
}

export default NonAdminOnboardingForm
