import { Theme, useMediaQuery } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import { Tooltip, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {
  taxIdField: any
}

const TextWrapper = styled('div')({
  margin: '5px 0 20px',
})

const Container = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  marginBottom: 10,
})

const Field = styled(CustomField)({
  width: '50%',
  margin: 0,
})

const InfoIcon = styled(InfoOutlined)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[700],
  marginLeft: '6px',
}))

const BusinessTaxIdSection: React.FC<PropTypes> = ({ taxIdField }) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const orgCountry = useSelector(orgSelectors.getOrgIso2)

  const tooltipMsg = useMemo(() => {
    if (orgCountry === 'US') {
      return (
        <>
          <Typography textVariant="body1SemiBold">{translate('What Business Tax ID is required?')}</Typography>
          <Typography textVariant="body1">
            {translate(
              "Your Federal Employer Identification Number (FEIN) is a unique number assigned to your business by the IRS. If you're a new business, you may not have one yet, come back to this step when you do."
            )}
          </Typography>

          <Typography textVariant="body1SemiBold">{translate('Where can I find this information?')}</Typography>
          <Typography textVariant="body1">
            {translate('Your business legal name and FEIN are in the header of your IRS Form CP-575')}
          </Typography>
        </>
      )
    } else if (orgCountry === 'GB') {
      return (
        <>
          <Typography textVariant="body1SemiBold">{translate('What Business Tax ID is required?')}</Typography>
          <Typography textVariant="body1">
            {translate(
              "A VAT number is a unique ID that HMRC provides to businesses when they register for VAT. In the UK, VAT numbers are nine digits long and always have the prefix 'GB'"
            )}
          </Typography>
          <Typography textVariant="body1SemiBold">
            {translate("Do not include the 'GB' country code in this field when submitting")}
          </Typography>
          <br />
          <Typography textVariant="body1SemiBold">{translate('Where can I find this information?')}</Typography>
          <Typography textVariant="body1">
            {translate(
              "VAT registration certificate issued by HMRC contains your VAT number and it's included on VAT returns. If you're unable to find it, check your accounting software or you can contact HMRC for assistance."
            )}
          </Typography>
        </>
      )
    } else if (orgCountry === 'AU') {
      return (
        <>
          <Typography textVariant="body1SemiBold">{translate('Where can I find this information?')}</Typography>
          <Typography textVariant="body1">
            {translate(
              'ACN is a unique nine-digit number issued by the Australian Securities and Investments Commission (ASIC) to companies registered in Australia. ABN is a unique 11-digit number issued by the Australian Taxation Office (ATO) to all businesses in Australia.'
            )}
          </Typography>
        </>
      )
    }
    return null
  }, [orgCountry])

  return (
    <div data-testid="business-tax-id-section">
      <TextWrapper>
        <Typography textVariant="body1">
          {translate(
            'We use this information to confirm your business identity. Please enter your information exactly as it appears on your official tax documents.'
          )}
        </Typography>
      </TextWrapper>

      <Container>
        <Field
          required={taxIdField.is_required}
          name={taxIdField.field_name}
          label={taxIdField.field_label}
          component={TextInput}
          choices={taxIdField.choices}
          initialValue={taxIdField.default_value}
          variant="outlined"
          disabled={taxIdField.is_disabled}
        />
        {tooltipMsg && (
          <Tooltip
            title={tooltipMsg}
            enterTouchDelay={0}
            leaveTouchDelay={isMobile ? 3000 : 0}
            leaveDelay={isMobile ? 3000 : 0}
          >
            <InfoIcon />
          </Tooltip>
        )}
      </Container>
    </div>
  )
}
export default BusinessTaxIdSection
