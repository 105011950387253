import BlueSnapOnboardingSection from './BlueSnapOnboardingSection'
import BusinessInfoTitle from './businessInfoPage/BusinessInfoTitle'
import BusinessInfoTop from './businessInfoPage/BusinessInfoTop'
import DecisionDisplayTop from './decisionDisplayPage/DecisionDisplayTop'
import OwnerAndBankInfoTitle from './ownerAndBankInfo/OwnerAndBankInfoTitle'
import OwnerAndBankInfoTop from './ownerAndBankInfo/OwnerAndBankInfoTop'
import TermsTitle from './termsPage/TermsTitle'
import TermsTop from './termsPage/TermsTop'
import { OnboardingFormRouteInfoType } from './types'

const routes: OnboardingFormRouteInfoType = {
  businessInfo: {
    hasFormFields: true,
    sectionName: 'Business Info',
    fieldPageNumber: 1,
    nextKey: 'terms',
    prevKey: null,
    showNextButton: true,
    showSaveAndContinueLater: true,
    topLeft: {
      component: BusinessInfoTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: BusinessInfoTitle,
    },
  },
  terms: {
    hasFormFields: true,
    sectionName: 'Terms',
    fieldPageNumber: 2,
    nextKey: 'ownerAndBankInfo',
    prevKey: 'businessInfo',
    showSaveAndContinueLater: true,
    showNextButton: true,
    topLeft: {
      component: TermsTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: TermsTitle,
    },
  },
  ownerAndBankInfo: {
    hasFormFields: true,
    sectionName: 'OwnerAndBankInfo',
    nextButtonLabel: 'Submit',
    fieldPageNumber: 3,
    nextKey: 'decisionDisplay',
    prevKey: 'terms',
    showNextButton: true,
    topLeft: {
      component: OwnerAndBankInfoTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: BlueSnapOnboardingSection,
    },
    title: {
      component: OwnerAndBankInfoTitle,
    },
  },
  decisionDisplay: {
    hasFormFields: false,
    sectionName: 'decisionDisplay',
    fieldPageNumber: null,
    nextKey: null,
    prevKey: null,
    showNextButton: false,
    topLeft: {
      component: DecisionDisplayTop,
    },
    right: {
      component: null,
    },
    bottomLeft: {
      component: null,
    },
    title: {
      component: null,
    },
  },
}

export default routes
