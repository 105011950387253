import { UploadIcon } from '../../icons'
import { styled } from '../../styles/styled'

const MainView = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 5px',
    margin: '5px 0px',
    backgroundColor: '#ececec',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
      boxShadow: undefined,
    },
    background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
    border: '1px solid #30862C',
    borderRadius: '3px',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    color: '#FFFFFF',
    fontSize: 15,
  }
})

const Row = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
  }
})

const NameView = styled('div')(({ theme }) => {
  return {
    flex: 3,
  }
})

const IconView = styled('div')(({ theme }) => {
  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
})

export const UploadStipulationButton = ({
  description,
  onClick,
  stipulationName,
}: {
  description?: string
  onClick(): void
  stipulationName: string
}) => {
  return (
    <MainView onClick={onClick}>
      <Row>
        <NameView>{stipulationName}</NameView>
        <IconView>
          <UploadIcon />
        </IconView>
      </Row>
      {description && (
        <Row>
          <span>{description}</span>
        </Row>
      )}
    </MainView>
  )
}
