import { SelectInputProps } from 'react-admin'

export const TRANSPORTATION_CHOICES: SelectInputProps['choices'] = [
  { id: 'petrol', name: 'Internal Combustion Engine (ICE)' },
  { id: 'electric', name: 'Electric Vehicle (EV)' },
]

export const USAGE_CHOICES: SelectInputProps['choices'] = [
  { id: 'annual_mileage', name: 'Annual Mileage', disabled: true },
]
