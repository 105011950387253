import { FC } from 'react'

/**
 * * Icon Component for: arrow-right-sm
 */
type ArrowRightSmIconProps = {
  size?: number
  color?: string
}
export const ArrowRightSmIcon: FC<ArrowRightSmIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M6.83333 1.625L11 6M11 6L6.83333 10.375M11 6L1 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
