import { Add } from '@material-ui/icons'
import lodash from 'lodash'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { ReactNode, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'

type MultipleCardFieldProps<T> = {
  source: string
  render: (
    item: T,
    opts: {
      index: number
      deleteCard: (index, assetKey?: string) => void
    }
  ) => ReactNode
  cardTitle?: string
  addLimit?: number
}
export const BtnWrapper = styled('div', { name: 'BtnWrapper' })({
  justifyContent: 'flex-start',
})

const MultipleCardField = <T,>({ source, render, cardTitle, addLimit }: MultipleCardFieldProps<T>) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const items = useMemo(() => lodash.get(formValues, source) || [], [source, formValues])
  const addCard = () => {
    if (source) form.change(source, [...(items || []), {}])
  }
  const deleteCard = (index, assetKey: string | undefined = undefined) => {
    let updatedCards = [...(items || [])]
    updatedCards.splice(index, 1)
    if (source) form.change(source, updatedCards)
    //clear any assets

    if (assetKey && formValues.contents?.assets?.[assetKey]?.[index]) {
      let updatedAssets = [...formValues.contents?.assets?.[assetKey]]
      updatedAssets[index] = null
      form.change(`contents.assets.${assetKey}`, updatedAssets)
    }
  }
  return (
    <>
      {!!items.length &&
        items.map((item, index) =>
          render(item, {
            index,
            deleteCard,
          })
        )}
      {(addLimit === undefined || addLimit !== items.length) && (
        <BtnWrapper>
          <Button startIcon={<Add />} variant="contained" onClick={addCard}>
            {cardTitle || translate('Card')}
          </Button>
        </BtnWrapper>
      )}
    </>
  )
}

export default MultipleCardField
