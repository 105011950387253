import { useLocale } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry } from 'util/misc'
import { useGetMilestones } from '../hooks'
import { useIsCalculating } from '../projectProgress/cashFlow/utils'
import { formatAmount } from '../utils'

type PropTypes = {
  pmtId: number
  systemUuid: string | null
}
const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    minHeight: '42px',
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: theme.greyLight2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const MilestonePaymentsTable: React.FC<PropTypes> = (props) => {
  const project = useFormState().values

  const locale = useLocale()
  const currencySymbol = currencySymbolForCountry(project?.country_iso2)
  const isCalculating = useIsCalculating(props.systemUuid)
  const classes = useStyles()
  const milestones = useGetMilestones(props.pmtId, props.systemUuid, project?.id)

  if (!milestones) return <div className={classes.wrapper}></div>
  else if (isCalculating)
    return (
      <div className={classes.wrapper}>
        <Skeleton height="40px" width="100%" />
      </div>
    )
  return (
    <div className={classes.wrapper}>
      {milestones
        ?.filter((milestone) => !milestone.is_deposit)
        .map((milestone, i) => {
          return (
            <div key={milestone.payment_number} className={classes.row}>
              <div className={classes.title} data-testid={`milestone-${i + 2}-title`}>
                {milestone.title}
              </div>
              <div data-testid={`milestone-${i + 2}-value`}>
                {formatAmount(milestone.payment_amount, currencySymbol, locale)}
              </div>
            </div>
          )
        })}
    </div>
  )
}
export default MilestonePaymentsTable
