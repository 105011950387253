import { Box, Grid } from '@material-ui/core'
import { FileField, FileInput, useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'

import { setHeatPumpDataAndProjectId, setShowHeatPumpAlert } from 'ducks/heatPump'
import { useForm } from 'react-final-form'
import { CurrentAnnualEnergyUsageType } from './types'
import { formatEnergyUsageValues, isEmptyObject } from './utils'

const HeatContent = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const projectId = useSelector((state: RootState) => state.projectId)
  const notify = useNotify()
  const form = useForm()

  const updateOtherEnergyTypes = (currentUsage, currentOtherEnergyUsage) => {
    if (currentUsage.other_energy_types) {
      currentUsage.other_energy_types = currentUsage.other_energy_types.filter((type) => type !== null)
      // Merge other_energy_types from currentEnergyUsageByType into currentUsage.other_energy_types
      currentUsage.other_energy_types = currentUsage.other_energy_types.map((type) => {
        const updatedType = currentOtherEnergyUsage.find((newType) => newType.energy_type === type.energy_type)
        return updatedType ? { ...type, ...updatedType } : type
      })

      // Add any new other_energy_types from currentEnergyUsageByType that are not in currentUsage.other_energy_types
      currentOtherEnergyUsage.forEach((newType) => {
        if (!currentUsage.other_energy_types.some((type) => type.energy_type === newType.energy_type)) {
          currentUsage.other_energy_types.push(newType)
        }
      })
    } else {
      // If currentUsage.other_energy_types does not exist, initialize it with currentEnergyUsageByType.other_energy_types
      currentUsage.other_energy_types = currentOtherEnergyUsage
    }

    return currentUsage
  }

  const customOnDrop = async (acceptedFiles: File[], rejectedFiles: File[], event: any) => {
    const file = acceptedFiles[0]
    if (!file) return

    try {
      const text = await file.text()
      const parsedData = JSON.parse(text)

      if (parsedData) {
        // Save the parsed data to the store for accessing proposed energy usage in design studio
        dispatch(setHeatPumpDataAndProjectId(parsedData, projectId))
        dispatch(setShowHeatPumpAlert(true))

        // Save the current energy usage data to the project in the project.usage field
        // TODO: Handle case where there is no currentAnnualEnergyUsage data
        if (isEmptyObject(parsedData.data?.currentAnnualEnergyUsage)) {
          notify('No current energy usage data found in JSON file.', 'warning')
          return
        }
        const currentHeatPumpEnergyUsage: CurrentAnnualEnergyUsageType = parsedData.data?.currentAnnualEnergyUsage
        const currentUsage = JSON.parse(form.getState().values.usage)
        const currentOtherEnergyUsage = formatEnergyUsageValues(currentHeatPumpEnergyUsage)
        const updatedUsage = updateOtherEnergyTypes(currentUsage, currentOtherEnergyUsage)

        form.change('usage', updatedUsage)
      }
    } catch (error) {
      console.error('Error processing JSON file. Upload a file in the correct JSON format.', error)
      notify('Error processing JSON file. Upload a file in the correct JSON format.', 'error')
    }
  }

  return (
    <Box width="100%">
      <Grid item xs={12}>
        <FileInput
          source="heat_json_file"
          placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
          label="Upload JSON file"
          accept="application/JSON"
          options={{ onDrop: customOnDrop }}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </Grid>
    </Box>
  )
}

export default HeatContent
