import { FC } from 'react'

/**
 * * Icon Component for: check-contained-filled
 */
type Props = {
  color?: string
}
export const CancelCircleIcon: FC<Props> = ({ color = 'currentColor' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.182 7.87868C13.4749 7.58579 13.4749 7.11091 13.182 6.81802C12.8891 6.52513 12.4142 6.52513 12.1213 6.81802L13.182 7.87868ZM6.81802 12.1213C6.52513 12.4142 6.52513 12.8891 6.81802 13.182C7.11091 13.4749 7.58579 13.4749 7.87868 13.182L6.81802 12.1213ZM12.1213 13.182C12.4142 13.4749 12.8891 13.4749 13.182 13.182C13.4749 12.8891 13.4749 12.4142 13.182 12.1213L12.1213 13.182ZM7.87868 6.81802C7.58579 6.52513 7.11091 6.52513 6.81802 6.81802C6.52513 7.11091 6.52513 7.58579 6.81802 7.87868L7.87868 6.81802ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25ZM12.1213 6.81802L9.46967 9.46967L10.5303 10.5303L13.182 7.87868L12.1213 6.81802ZM9.46967 9.46967L6.81802 12.1213L7.87868 13.182L10.5303 10.5303L9.46967 9.46967ZM13.182 12.1213L10.5303 9.46967L9.46967 10.5303L12.1213 13.182L13.182 12.1213ZM10.5303 9.46967L7.87868 6.81802L6.81802 7.87868L9.46967 10.5303L10.5303 9.46967Z"
        strokeWidth="1.3"
        fill={color}
      />
    </svg>
  )
}
