import { Alert, CheckOutlineIcon, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const PhoenixApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  if (options?.phoenixStatus === 'Approved') {
    return (
      <div style={{ width: '100%' }}>
        <h2 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>Application Approved!</h2>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />
          <p>You've been approved for financing by Phoenix. Please consult your sales representative for next steps</p>
        </div>
      </div>
    )
  } else if (options?.phoenixStatus === 'Pending') {
    return (
      <div style={{ width: '100%' }}>
        <h2>Under Review</h2>
        <p>Your application for finance is under review. Please consult your sales representative for next steps.</p>
      </div>
    )
  } else if (options?.isPro) {
    return (
      <Alert severity="warning">
        Phoenix applications may only be started by the customer. Please send the proposal to your customer to initiate
        an application.
      </Alert>
    )
  }

  return (
    <div style={{ width: '90%', padding: '0px 5%', marginBottom: '10px' }}>
      {options?.phoenixStatus === 'Declined' && (
        <span className="small" style={{ textAlign: 'center', margin: '5px 0px 10px 0px' }}>
          Your previous application was declined. If you would like to re-apply click the button below.
        </span>
      )}
      <TransactionActionButton
        isLoading={isLoading}
        label={<div>{options?.phoenixStatus === 'Declined' ? 'Re-Apply for Finance' : 'Apply for Finance'}</div>}
        disabled={options?.isPro || actionData.status_code !== 'available'}
        onClick={openApplicationDialog}
      />
    </div>
  )
}

export default PhoenixApplication
