import { useMediaQuery } from '@material-ui/core'
import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'constants/locales'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import TrackedToolbar from 'elements/TrackedToolbar'
import inflection from 'inflection'
import { Chip } from 'opensolar-ui'
import {
  BooleanInput,
  Create,
  Datagrid,
  FileField,
  FileInput,
  FilterComp,
  FunctionField,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import SharedFromInput from 'resources/connectedOrgs/filters/SharedFromInput'
import SharedWithInput from 'resources/connectedOrgs/filters/SharedWithInput'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { DocumentFormatField } from 'resources/documentTemplates/DocumentFormatField'
import DocumentTemplateFilterFields from 'resources/documentTemplates/DocumentTemplateFilterFields'
import DocusignSettings, { IntegrationField } from 'resources/documentTemplates/DocusignSettings'
import TemplateWizard from 'resources/documentTemplates/TemplateWizard'
import { styles as standardStyles } from 'styles'
import { getRoleFromState, renderDraftEditor, renderRawHtmlEditor } from 'util/misc'
import { duplicate as duplicateAction } from '../../actions/restActions'
import { authSelectors } from '../../ducks/auth'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import DependentInput from '../../elements/input/DependentInput'
import OverviewInputs from '../../elements/input/OverviewInputs'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { useStyles } from './styles'
import ContractAutoApplyInputs from './widgets/ContractAutoApplyInputs'

export const DOCUMENT_TEMPLATE_CHOICES = [
  { id: 0, name: 'Owners Manual' },
  { id: 1, name: 'Generic Document' },
  { id: 2, name: 'Installation Instructions' },
]

export const DisclaimerText = {
  header1: 'No Legal or Business Advice',
  section1: `Nothing in the form contracts or related explanatory materials made available to you by OpenSolar (together, the
    “Forms”) is to be considered as the rendering of legal or business advice. The Forms are provided as a courtesy to
    our users for general informational purposes only. Users are responsible for obtaining legal or business advice
    from their own lawyer or other professional and should not rely on the Forms without seeking such advice.`,
  header2: 'Disclaimer of Liability',
  section2: `TO THE EXTENT PERMITTED BY LAW THE FORMS ARE PROVIDED AS-IS WITH NO REPRESENTATIONS OR WARRANTIES, EITHER
  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
  PARTICULAR PURPOSE AND NONINFRINGEMENT. TO THE EXTENT PERMITTED BY LAW YOU ASSUME COMPLETE RESPONSIBILITY AND
  RISK FOR USE OF THE FORMS.`,
  section3: `To the extent permitted by law, OpenSolar expressly disclaims all liability, loss or risk incurred as a direct
  or indirect consequence of the use of the Forms. To the extent permitted by law, by using the Forms, you waive
  any rights or claims you may have against OpenSolar in connection therewith. The information contained in the
  Forms is provided only as general information and may not reflect the most current market and legal developments
  and may not address all relevant business or legal issues; accordingly, information in the Forms is not promised
  or guaranteed to be correct or complete.`,
  section4: `In the event a client of your business makes a claim against OpenSolar based on your use of the Forms, you agree
  to indemnify and hold OpenSolar harmless from and against any and all claims, damages, losses or obligations
  arising from your use of the Forms.`,
  section5: `OpenSolar at its sole discretion may choose to change the terms and conditions of the Forms at any time.`,
}

const LoanTemplateDisclaimerTooltipText = `
  <div style="max-width: 642px">
    <p style="font-weight: bold; line-height: 22px">${DisclaimerText.header1}</p>
    <p style="font-weight: 400; line-height: 22px">
      ${DisclaimerText.section1}
    </p>

    <p style="font-weight: bold; line-height: 22px">${DisclaimerText.header2}</p>
    <p style="font-weight: 400; line-height: 22px">
      ${DisclaimerText.section2}
    </p>
    <p style="font-weight: 400; line-height: 22px">
      ${DisclaimerText.section3}
    </p>
    <p style="font-weight: 400; line-height: 22px">
      ${DisclaimerText.section4}
    </p>
    <p style="font-weight: 400; line-height: 22px">
      ${DisclaimerText.section5}
    </p>
  </div>
`

const DraftEditorWithContent = (props) => {
  const hasCountersignEmail = useFormState().values?.docusign_designated_countersigner_email

  if (!props.record || !props.record.id) {
    return null
  }
  return (
    <EditorComponent
      label={props.label}
      fieldName={props.fieldName}
      allowUploadImage={true}
      mentionContext="owners_manual"
      {...props}
      hasCountersignEmail={hasCountersignEmail}
    />
  )
}

DraftEditorWithContent.defaultProps = {
  addField: true,
}

export const ContractFilter = (props) => (
  <FilterComp {...props}>
    <TextInput label="pos.search" style={standardStyles.FILTER_FIELD_STYLE} source="q" />
    <SharedFromInput source="owned_by" label={'Shared From'} />
    <SharedWithInput source="shared_with" label={'Shared With'} />
  </FilterComp>
)

const DefaultPaymentType = ({ record }) => {
  let tags = []
  if (record.is_default) tags.push('Default')
  if (record.is_default_loan) tags.push('Loan')
  if (record.is_default_ppa) tags.push('PPA')
  if (record.is_default_cash) tags.push('Cash')
  if (record.is_default_regular_payment) tags.push('Regular Payment')
  if (record.is_default_lease) tags.push('Lease')

  const translate = useTranslate()

  return (
    <div>
      {tags.map((item) => (
        <Chip
          label={translate(item)}
          style={{
            margin: '5px',
          }}
        />
      ))}
    </div>
  )
}

export const _ContractList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const isFirstOrg = useSelector(authSelectors.getOrgId) === 1
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowEdit)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      hasSearch={true}
      alwayShowActions={true}
      extraCreateButtonLabel={isAdmin ? translate('ra.page.empty_new', { name: resourceName }) : null}
      filters={<ContractFilter />}
      actions={<ListActions hasArchived={true} />}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid
        duplicate={props.duplicate}
        cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}
        isAdmin={isAdmin}
      >
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <DefaultPaymentType label="Default for payment type" />
        {isFirstOrg ? (
          <FunctionField
            source="language"
            displayInCard={true}
            render={(record) => LOCALES_SUPPORTED_CUSTOMER_FACING[record.locale]}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}
        {isFirstOrg ? (
          <FunctionField
            source="countries_included"
            displayInCard={true}
            render={(record) => record.countries_include}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}
        {isFirstOrg ? (
          <FunctionField
            source="countries_excluded"
            displayInCard={true}
            render={(record) => record.countries_exclude}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}
        <EditOrImportButton
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource="contracts"
          duplicate={props.duplicate}
          sortable={false}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

export const ContractList = connect(
  (state) => {
    return {
      org_id: authSelectors.getOrgId(state),
      docusign_account_connected: authSelectors.getDocusignAccountConnected(state),
    }
  },
  { duplicate: duplicateAction }
)(_ContractList)

const inputStyle = {
  width: 256,
}

export const ContentFields = (props) => {
  const formValues = useFormState().values
  return props.record.id && props.record.id > 0 && (props.record.content || props.record.content === '') ? (
    <div>
      <div className="contract" style={{ marginBottom: 40 }}>
        <DependentInput dependsOn={'content'} resolve={renderDraftEditor}>
          <CustomField
            key={`content_fields_${formValues.integration}`}
            component={DraftEditorWithContent}
            //label={props.translate('Content')}
            fieldName="content"
            source="content"
            name="content"
            {...props}
            docusign_selected={formValues.integration === 1}
          />
        </DependentInput>
        <DependentInput dependsOn={'content'} resolve={renderRawHtmlEditor}>
          <CustomField
            component={TextInput}
            inputStyle={{ minHeight: 260 }}
            fullWidth={true}
            multiline={true}
            //label={props.translate('Content')}
            fieldName="content"
            source="content"
            name="content"
            {...props}
          />
        </DependentInput>
      </div>
    </div>
  ) : null
}

const LoanTemplateDisclaimerTooltip = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.disclaimerTooltipWrapper}>
      {translate('Important Note')}
      <InfoTooltip
        className={classes.disclaimerTooltip}
        fullWidth
        title={<div dangerouslySetInnerHTML={{ __html: LoanTemplateDisclaimerTooltipText }} />}
        placement="left-start"
      />
    </div>
  )
}

//TODO: Add this tooltip when pdf v2 is selected
/* const DesktopScreenTooltip = () => {
  const translate = useTranslate()
  const formState = useFormState()
  const selectedType = formState.values['format']

  return selectedType === 3 && <InfoTooltip title={translate('Requires Desktop Screen')} />
} */

const PdfEditor = ({ ...props }) => {
  // TODO: Can swap between v1 and v2
  const isV1 = true

  return isV1 ? (
    <TemplateWizard {...props} />
  ) : (
    <FileInput label={null} source="pdf_file" placeholder={<p>Upload PDF</p>}>
      <FileField source="pdf" title="Upload PDF" />
    </FileInput>
  )
}

export const ContractEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  const docusign_account_connected = useSelector(authSelectors.getDocusignAccountConnected)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        redirect={props.redirect}
        hideCancelButton={!!props.hideCancelButton}
        validate={(values) => validateDocument(values, translate)}
        toolbar={access.allowEdit ? <TrackedToolbar {...props} /> : false}
        disabled={!access.allowEdit}
      >
        <OverviewInputs>
          {/* <DefaultModeSelector startPDF={() => setMode('pdf')} startHTML={() => setMode('html')} /> */}
          <div style={isMobile ? {} : { float: 'right' }}>
            <LoanTemplateDisclaimerTooltip />
          </div>
          <CustomField
            component={TextInput}
            inputStyle={inputStyle}
            name={'title'}
            source="title"
            label="Title"
            disabled={!access.allowEdit}
          />
          <DocumentTemplateFilterFields inputStyle={inputStyle} isEditForm={false} isContractForm={true} />
          <DocumentFormatField />
          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            name={'description'}
            source="description"
            label="Description"
          />
          <CustomField
            component={BooleanInput}
            name="is_archived"
            source="is_archived"
            label={'Archive (hide component)'}
            defaultValue={false}
            disabled={!access.allowEdit}
          />
          <IntegrationField disabled={!access.allowEdit} />
          <div>
            <CustomField name={'is_default_for_matching_filters_enabled'} label="Auto-apply" component={BooleanInput} />
            <ContractAutoApplyInputs />
          </div>
        </OverviewInputs>

        <DependentInput dependsOn="integration" value={1}>
          <DocusignSettings disabled={!access.allowEdit} />
        </DependentInput>
        <DependentInput dependsOn="format" value={1}>
          <ContentFields
            translate={translate}
            docusign_account_connected={docusign_account_connected}
            label="Displayed for all payment options. Specific terms & conditions for selected payment option will also be shown."
            disabled={!access.allowEdit}
          />
        </DependentInput>
        <DependentInput dependsOn="format" value={2}>
          <PdfEditor />
        </DependentInput>
        <ShareabilitySelector />
      </SimpleForm>
    ),
  }).editPage
}

export const ContractCreate = (props) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <Create {...props} record={{ format: 1 }}>
      <SimpleForm validate={(values) => validateDocument(values, translate)} toolbar={<TrackedToolbar {...props} />}>
        <OverviewInputs>
          <div style={isMobile ? {} : { float: 'right' }}>
            <LoanTemplateDisclaimerTooltip />
          </div>
          <CustomField component={TextInput} name={'title'} source="title" label="Title" />
          <DocumentTemplateFilterFields inputStyle={inputStyle} isEditForm={false} isContractForm={true} />
          <DocumentFormatField />
          {/* <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            name={'description'}
            source="description"
            label="Description"
          /> */}
          <div>
            <CustomField name={'is_default_for_matching_filters_enabled'} label="Auto-apply" component={BooleanInput} />
            <ContractAutoApplyInputs />
          </div>
        </OverviewInputs>

        {/* <ShareabilitySelector /> */}
      </SimpleForm>
    </Create>
  )
}

export const validateDocument = (values, translate) => {
  const errors = {}
  if (!values.title) {
    errors.title = translate('Title is required.')
  }
  if (values.enable_docusign_countersignature && !values.docusign_designated_countersigner_email) {
    errors.docusign_designated_countersigner_email = translate('This field is required')
  }
  if (values.enable_docusign_countersignature && !values.docusign_designated_countersigner_first_name) {
    errors.docusign_designated_countersigner_first_name = translate('This field is required')
  }
  if (values.enable_docusign_countersignature && !values.docusign_designated_countersigner_family_name) {
    errors.docusign_designated_countersigner_family_name = translate('This field is required')
  }
  if (values.content && values.content.includes('sample_project_url')) {
    const tags = JSON.parse(values.content)?.tags
    let hasCounterSigningTag = false
    if (tags) {
      tags.forEach((tag) => {
        if (!hasCounterSigningTag && tag.field_key?.includes('/DS-') && tag.field_key?.includes('counter-'))
          hasCounterSigningTag = true
      })
    }
    if (
      hasCounterSigningTag &&
      !values.enable_docusign_countersignature &&
      !values.docusign_designated_countersigner_email
    ) {
      let errorMsg = translate(
        'Docusign countersignature tags are used in this template but countersigning is not enabled. Please either remove the countersigning tags or enable countersigning above.'
      )
      errors.enable_docusign_countersignature = errorMsg
    }
  }
  //sanitize before committing
  if (Object.keys(errors)?.length === 0) {
    // if it's disabled clear all of the related fields on save
    if (
      !Object.keys(values)?.includes('enable_docusign_countersignature') ||
      !values['enable_docusign_countersignature']
    ) {
      if (values.docusign_require_sales_rep_countersignature)
        values['docusign_require_sales_rep_countersignature'] = false
      if (values.docusign_designated_countersigner_email) values['docusign_designated_countersigner_email'] = undefined
      if (values.docusign_designated_countersigner_first_name)
        values['docusign_designated_countersigner_first_name'] = undefined
      if (values.docusign_designated_countersigner_family_name)
        values['docusign_designated_countersigner_family_name'] = undefined
    }
    // this not a real field on the model, it's just used for UI purposes. Clean it up before save
    if (Object.keys(values)?.includes('enable_docusign_countersignature'))
      delete values['enable_docusign_countersignature']
  }
  return errors
}
