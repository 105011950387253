import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined'
import { Card, CardActions, CardContent, Theme, Typography, useMediaQuery } from '@mui/material'
import { Button } from 'opensolar-ui'
import { styled } from '../../styles'

const StyledCard = styled(Card)(() => ({
  marginTop: '13px',
  border: '1px solid #D3D3D3 !important',
}))
const StyledCardContent = styled(CardContent)(() => ({
  padding: '13px',
}))
const CardHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '18px',
}))
const CardContentText = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 300,
}))

const StyledIcon = styled(AccountBalanceOutlined)(() => ({
  marginRight: '8px',
  width: '20px',
  height: '20px',
}))
const MobileSection = styled('div')(() => ({
  flexBasis: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  margin: '10px 0px',
}))
const MobileIcon = styled(AccountBalanceOutlined)(() => ({
  width: '18px',
  height: '18px',
  marginRight: '2px',
}))
const MobileButtonContainer = styled('div')(() => ({
  color: 'rgb(77, 77, 77)',
  fontSize: '14px',
  textDecoration: 'underline',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  width: '240px',
}))
const MobileLeftFiller = styled('div')(() => ({
  minWidth: '60px',
  width: '60px',
}))

export const CheckoutFinancingCard = (props: {
  isProposal?: boolean
  onClick(): void
  hidden?: boolean
  translate: (text: string) => string
}) => {
  const { isProposal, onClick, hidden, translate } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  if (hidden === true) return null

  return (
    <>
      {isProposal && isMobile ? (
        <MobileSection>
          <MobileLeftFiller></MobileLeftFiller>
          <MobileButtonContainer onClick={onClick}>
            <MobileIcon />
            <span>{translate('Financing Available')}</span>
          </MobileButtonContainer>
        </MobileSection>
      ) : (
        <StyledCard elevation={0}>
          <StyledCardContent>
            <CardHeader>
              <StyledIcon />
              <Typography variant="subtitle1">{translate('Financing Available')}</Typography>
            </CardHeader>
            <CardContentText variant="body2">
              {translate(
                'Secure the funds you need to begin your home improvement project. Financing is just a click away.'
              )}
            </CardContentText>
          </StyledCardContent>
          <CardActions>
            <Button color="secondary" variant="contained" fullWidth onClick={onClick}>
              {translate('Learn More')}
            </Button>
          </CardActions>
        </StyledCard>
      )}
    </>
  )
}
