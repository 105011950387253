import { styled } from 'opensolar-ui'
import { FC, useMemo } from 'react'
import Blogs from './components/Blogs'
import Chat from './components/Chat'
import Cover from './components/Cover'
import Description from './components/Description'
import ExhibitResources from './components/ExhibitResources'
import Highlights from './components/Highlights'
import Navbar from './components/Navbar'
import Products from './components/Products'
import Statistics from './components/Statistics'
import Videos from './components/Videos'
import { ContentBlock, StandData } from './expoStand'
import { getFile, parseNavLinks, sanitizeHtml } from './utils'

interface PropTypes {
  item: ContentBlock
  enabledContents: ContentBlock[]
  itemEls: any
}

const PaddedSection = styled('div', { name: 'PaddedSection' })({
  padding: 30,
  paddingBottom: 0,
})

const nonPaddedSections = ['navbar', 'cover', 'chat']
const dataNotRequired = ['resources', 'cover']
const ComponentBlock: FC<PropTypes & StandData> = ({ item, org, expoStand, enabledContents, itemEls }) => {
  const assets = useMemo(() => expoStand?.contents?.assets, [expoStand?.contents])
  const companyLogoUrl = useMemo(() => getFile(expoStand?.brand?.logo_contents) || org?.logo_public_url, [
    expoStand?.brand?.logo_contents,
    org?.logo_public_url,
  ])
  const componentDisplay = useMemo(() => {
    if (
      !item ||
      !item.contentType ||
      !item.enabled ||
      (dataNotRequired.includes(item.contentType) ? false : !item.data)
    )
      return null
    const data = item?.data
    switch (item.contentType) {
      case 'navbar':
        const navLinks = parseNavLinks(enabledContents, itemEls) || []
        return (
          <Navbar
            breadcrumbs={[
              { label: 'Expo', href: '#/expo' },
              { label: expoStand?.brand?.name || org?.name || 'Partner' },
            ]}
            cta={data?.cta}
            links={navLinks}
          />
        )
      case 'cover': {
        return (
          <Cover tagline={data?.tagline} logoUrl={companyLogoUrl} backgroundUrl={getFile(assets?.cover_background)} />
        )
      }
      case 'our_story': {
        return (
          <Description
            body={data?.body || ''}
            tags={expoStand?.tags || []}
            videoUrl={org?.premium_expo ? data?.video : undefined}
            media={companyLogoUrl}
          />
        )
      }
      case 'highlights': {
        return (
          <Highlights
            highlights={
              data?.map((x, i) => ({
                ...x,
                backgroundUrl: getFile(assets?.highlights[i]),
                cta: { ...x.cta, file: getFile(x.cta?.file) },
              })) || []
            }
          />
        )
      }
      case 'stats':
        return <Statistics statistics={data || []} partnerName={expoStand?.brand?.name || org?.name || 'Partner'} />
      // case 'socials':
      //   return <Socials linkedInUrl={data?.linkedInUrl} />
      case 'videos':
        return <Videos videos={data || []} />
      case 'blogs':
        return <Blogs blogs={data || []} />
      case 'resources':
        return <ExhibitResources standId={expoStand?.id} viewAllUrl={data?.url} />
      case 'featured_products':
        return <Products products={[{ test: '' }]} />
      case 'custom_content_1' || 'custom_content_2' || 'custom_content_3':
        return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.content) }} />
      case 'chat':
        return <Chat apiKey={data.apiKey} />
      default:
        return null
    }
  }, [item])

  if (!componentDisplay) return null
  if (nonPaddedSections.includes(item.contentType)) return componentDisplay
  return <PaddedSection>{componentDisplay}</PaddedSection>
}
export default ComponentBlock
