import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const PaymentOptionIconSvg: FC<IconProps> = (props) => {
  const { color, fill, width, height } = props
  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20C3.44772 20 3 19.5523 3 19V9C3 8.44772 3.44772 8 4 8H18C18.5523 8 19 8.44772 19 9V11H18V10C18 9.44772 17.5523 9 17 9H5C4.44772 9 4 9.44772 4 10V18C4 18.5523 4.44772 19 5 19H17C17.5523 19 18 18.5523 18 18V17H19V19C19 19.5523 18.5523 20 18 20H4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17C20.1046 17 21 16.1046 21 15V13C21 11.8954 20.1046 11 19 11H14V17H19ZM19 16C19.5523 16 20 15.5523 20 15V13C20 12.4477 19.5523 12 19 12H15V16H19Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 12.75C17.0596 12.75 16.5 13.3096 16.5 14C16.5 14.6904 17.0596 15.25 17.75 15.25C18.4404 15.25 19 14.6904 19 14C19 13.3096 18.4404 12.75 17.75 12.75ZM18.25 14.25C18.3881 13.9739 18.2761 13.6381 18 13.5C17.7239 13.3619 17.3881 13.4739 17.25 13.75C17.1119 14.0261 17.2239 14.3619 17.5 14.5C17.7761 14.6381 18.1119 14.5261 18.25 14.25Z"
        fill={fill}
      />
      <path d="M11.4948 8.02877L6.00532 6L5.6001 6.99789L8.49482 8.01306L11.4948 8.02877Z" fill={fill} />
      <path
        d="M4.92718 8.709L6.23831 5.46386C6.34175 5.20782 6.63317 5.08412 6.8892 5.18757L14.5 8H17L6.80022 4.07308C6.28815 3.86619 5.70532 4.11359 5.49843 4.62566L4 8.33439L4.92718 8.709Z"
        fill={fill}
      />
    </svg>
  )
}

export const PaymentOptionIcon = createStandardIcon(PaymentOptionIconSvg)
