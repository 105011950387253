import { orgSelectors } from 'ducks/orgs'
import { User, WebStorageStateStore } from 'oidc-client-ts'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo } from 'react'
import { AuthProvider } from 'react-oidc-context'
import { useSelector } from 'react-redux'

const cityPlumbingConfig = window.getCityPlumbingConfig?.()

const onSigninCallback = (_user: User | void): void => {
  // This clears the state URL params. We will continue with the actual redirect after this is called.
  window.history.replaceState({}, document.title, window.location.pathname)

  if (_user && _user?.refresh_token) {
    window.cityPlumbingTokens = { token: _user.access_token, refreshToken: _user.refresh_token }
  }
}

const oidcConfig = cityPlumbingConfig
  ? {
      authority: `${cityPlumbingConfig.url}/realms/${cityPlumbingConfig.realm}`,
      client_id: cityPlumbingConfig.clientId,
      realm: cityPlumbingConfig.realm,
      redirect_uri: window.location.origin, // TODO better value? Or rely on our own redirects?
      scope: 'offline_access',
      loadUserInfo: false,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      automaticSilentRenew: true,
    }
  : undefined

/**
 * The AuthProvider will render with this config if the org is not City Plumbing enabled
 * which should prevent any extraneous OIDC interactions with the CP server - although it
 * does seem that the provider isn't actually sending any requests until the user clicks
 * the connect button.
 */
const dummyConfig = {
  authority: undefined,
  client_id: undefined,
  realm: null,
  redirect_uri: undefined,
  scope: undefined,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  automaticSilentRenew: true,
}

/**
 * This is quite City Plumbing specific. We could genericise if we add another OAuth integration.
 */
const OidcWrapper = ({ children }) => {
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const wrapWithOidc = enabledDistributors.includes(HardwareSupplierEnum.CityPlumbing)
  const effectiveOidcConfig = wrapWithOidc ? oidcConfig : dummyConfig

  // It turns out this AuthProvider has internal state tracking whether it's been initialised.
  // So it's actually safest just to render it once like this.
  return (
    <AuthProvider {...effectiveOidcConfig} onSigninCallback={onSigninCallback}>
      {children}
    </AuthProvider>
  )
}

export default memo(OidcWrapper)
