import { Grid, Paper } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { orgSelectors } from 'ducks/orgs'
import DependentInput from 'elements/input/DependentInput'
import React, { FC } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import useStyle from '../../autoApply/styles'
import PaymentAutoApplyInputs from './PaymentAutoApplyInputs'

type ContractAutoApplyInputTypes = {
  enableByState?: boolean
  enableByZip?: boolean
  disabled?: boolean
  containerComponent?: React.ComponentType
}

const ContractAutoApplyInputs: FC<ContractAutoApplyInputTypes> = ({
  enableByState = true,
  enableByZip = true,
  disabled = false,
  containerComponent = Paper,
}) => {
  const translate = useTranslate()
  const classes = useStyle()
  const isUs = useSelector(orgSelectors.getOrgIso2) === 'US'

  const Comp = containerComponent

  return (
    <DependentInput dependsOn="is_default_for_matching_filters_enabled" resolve={(value) => !!value}>
      <Comp className={classes.paperStyle}>
        {isUs && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2>{translate('Auto apply settings')}</h2>
            </Grid>

            {enableByState && (
              <Grid item xs={12} sm={12} lg={12}>
                <TextInput
                  className={classes.defaultFieldStyle}
                  label={'Auto apply only in specified states'}
                  name={'is_default_for_states'}
                  source="is_default_for_states"
                  disabled={disabled}
                  endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
                  fullWidth={true}
                />
              </Grid>
            )}

            {enableByZip && (
              <Grid item xs={12} sm={12} lg={12}>
                <TextInput
                  className={classes.defaultFieldStyle}
                  label={'Auto apply only in specified ZIP codes'}
                  name={'is_default_for_zips'}
                  source="is_default_for_zips"
                  disabled={disabled}
                  endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
                  fullWidth={true}
                />
              </Grid>
            )}
          </Grid>
        )}
        <PaymentAutoApplyInputs />
      </Comp>
    </DependentInput>
  )
}

export default ContractAutoApplyInputs
