import { styled } from 'opensolar-ui'

//Move to BaseTheme?
export const palette = {
  blue: '#4272DD',
}

export const CardWrapper = styled('div', { name: 'CardWrapper' })(({ theme }) => ({
  borderRadius: 8,
  padding: 20,
  outline: theme.mixins.borderStandard.border,
  backgroundColor: '#FDFDFD',
}))

export const sectionBaseStyles = {
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 20,
}

export const SectionWrapper = styled('div', { name: 'SectionWrapper' })({
  ...sectionBaseStyles,
})
