import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import { ExpoStandRenderer } from 'opensolar-pageeditor'
import { Button, ComponentVersionsInherit, Dialog, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ExpoModes } from 'types/orgs'

const Wrapper = styled('div', { name: 'Wrapper' })({
  display: 'flex',
  justifyContent: 'flex-end',
})

const PreviewTemplateButton = (props) => {
  const translate = useTranslate()
  const [previewOpen, setPreviewOpen] = useState(false)
  return (
    <>
      <Wrapper>
        <Button
          variant="contained"
          onClick={() => {
            setPreviewOpen(true)
          }}
        >
          {translate('Preview Template')}
        </Button>
      </Wrapper>
      <PreviewDialog
        open={previewOpen}
        onClose={() => {
          setPreviewOpen(false)
        }}
      />
    </>
  )
}

const CloseBtn = styled(Button, { name: 'CloseBtn' })(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.secondary.contrastText,
  borderRadius: 0,
  position: 'sticky',
  top: 0,
  zIndex: 3000,
  '&:hover': {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}))
const PreviewDialog = ({ open, onClose }) => {
  const translate = useTranslate()
  const formValues = useFormState().values
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 1440,
          maxWidth: 'unset',
          maxHeight: '90%',
        },
      }}
    >
      <CloseBtn onClick={onClose} size={'small'}>
        {translate('Close Preview')}
      </CloseBtn>
      <StandPreview data={formValues} />
    </Dialog>
  )
}

const StandPreview = ({ data }) => {
  const org = useSelector(orgSelectors.getOrg)
  if (!org) return null
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      {org && (
        <ExpoStandRenderer
          org={{
            ...org,
            logo_public_url: org.logo_public_url?.toString() || null,
            premium_expo: org.expo_mode === ExpoModes.Premium,
          }}
          expoStand={data}
        />
      )}
    </ComponentVersionsInherit>
  )
}
export default PreviewTemplateButton
