// @ts-nocheck
import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { AutocompleteInput, useTranslate } from 'react-admin'
import { ContactType } from 'types/contacts'

type Options = {
  InputProps: {
    startAdornment: ReactNode
    fullWidth: boolean
  }
  containerStyle: CSSProperties
  fullWidth: boolean
  label: boolean
  style: CSSProperties
}

type PropTypes = {
  onSelection: (selected: ContactType) => void
  choices?: ContactType[]
  initialValue: string
  input?: {
    checked?: boolean
    name: string
    onBlur: Function
    onChange: Function
    onFocus: Function
    value: string
  }
  refreshingData?: boolean
  showSpinnerWhenRefreshing?: boolean
  loading?: boolean
  options?: Options
}

const ContactAutocomplete: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const handleChange = (chosenId: number) => {
    if (chosenId && props.choices) {
      let chosenContact = props.choices.find((con: ContactType) => con.id === chosenId)
      if (chosenContact) props.onSelection({ ...chosenContact, projects: undefined, org: undefined })
    }
  }
  return (
    <div
      style={{
        flexGrow: 1,
        position: 'relative',
      }}
    >
      <AutocompleteInput
        optionText={'display'}
        name="existing_contact"
        suggestionLimit={10}
        style={{ width: '100%' }}
        fullWidth
        resettable={true}
        clearAlwaysVisible={true}
        choices={props.choices}
        label={translate('Search existing contacts...')}
        {...props}
        input={{ ...props.input, onChange: handleChange }} //note, ...props spreads input.onChange so we need to overwrite it by setting it after spreading props
      />
      {props.refreshingData && props.showSpinnerWhenRefreshing && (
        <div style={{ position: 'absolute', right: '10px', top: 'calc(50%)' }}>
          <CircularProgress style={{ color: 'gray' }} size={16} />
        </div>
      )}
    </div>
  )
}
export default ContactAutocomplete
