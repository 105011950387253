import { Menu } from '@material-ui/core'
import SettingsOutlined from '@material-ui/icons/SettingsOutlined'
import { IconButton } from 'opensolar-ui'
//import { refreshView as refreshViewAction } from 'admin-on-rest
import { osLiteSupportLink } from 'constants/links'
import { authSelectors } from 'ducks/auth'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { viewModeSelectors } from 'ducks/viewMode'
import Tooltip from 'elements/tooltip/Tooltip'
import { FeatureSettingsButtonAndDialog } from 'pages/settings/FeatureSettingsButtonAndDialog'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import PropTypes from 'prop-types'
import React from 'react'
import { MenuItemLink, useLocale } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import compose from 'recompose/compose'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getHelpCenterByLocale } from 'util/misc'

const useStyles = makeOpenSolarStyles({
  menuItem: {
    height: 48,
    paddingRight: 35,
  },
  menuPopover: {
    zIndex: '2800 !important',
  },
})

const UserSettingsDropdown = ({ showOrgSelector, orgs, layout }) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const translate = useTranslateWithVariable()
  const locale = useLocale()
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const classes = useStyles()
  const isStaff = useSelector(authSelectors.getIsStaff)

  const handleClick = (event) => {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setOpen(false)
  }

  const handleRequestClose = (to, label) => {
    if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: to,
          prompt_heading: translate('Leave Project to %{label}?', { label: translate(label) }),
        })
      )
    } else {
      history.push(to)
    }
  }

  return (
    <div>
      <IconButton
        id="HeaderButtonOpen"
        color="inherit"
        onClick={handleClick}
        style={{ minWidth: layout === 2 ? 25 : 36, padding: 0, height: 36 }}
      >
        <Tooltip title={translate('Settings')} enterDelay={300}>
          <SettingsOutlined
            style={{
              fill: 'currentColor',
              height: 24,
              width: 24,
            }}
          />
        </Tooltip>
      </IconButton>

      <Menu
        open={open}
        disableScrollLock={true}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        PopoverClasses={{ root: classes.menuPopover }}
        keepMounted
      >
        {showOrgSelector && (isStaff || orgs?.length) && (
          <MenuItemLink
            id="HeaderButtonSelectOrganisation"
            to="/"
            className={classes.menuItem}
            primaryText={translate('Select Organisation')}
            onClick={(e) => {
              e.preventDefault()
              //To do: use a more elegant way to show
              if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
                const result = window.confirm('Any unsaved data will be lost. Are you sure?')
                if (!result) return
              }
              showOrgSelector()
              handleMenuClose()
              return false
            }}
          />
        )}
        <MenuItemLink
          id="HeaderButtonHelpAndFeedback"
          to="/"
          className={classes.menuItem}
          primaryText={translate('Support & Feedback')}
          onClick={(e) => {
            e.preventDefault()
            isUserLite ? window.open(osLiteSupportLink, '_blank') : window.open(getHelpCenterByLocale(locale), '_blank')
            handleMenuClose()
            return false
          }}
        />
        <MenuItemLink
          id="HeaderButtonConfiguration"
          to="/configuration"
          className={classes.menuItem}
          primaryText={translate('Settings')}
          onClick={(e) => {
            e.preventDefault()
            handleMenuClose()
            handleRequestClose('/configuration', 'Settings')
          }}
        />
        <FeatureSettingsButtonAndDialog
          className={classes.menuItem}
          onClick={() => {
            handleMenuClose()
          }}
        />
        <MenuItemLink
          id="HeaderButtonLogout"
          to={'/logout'}
          className={classes.menuItem}
          primaryText={translate('Logout')}
          onClick={(e) => {
            e.preventDefault()
            handleMenuClose()
            handleRequestClose('/logout', 'Logout')
          }}
        />
      </Menu>
    </div>
  )
}

UserSettingsDropdown.propTypes = {
  showOrgSelector: PropTypes.func,
}

UserSettingsDropdown.defaultProps = {}

const mapStateToProps = (state) => ({
  projectId: state.projectId,
  recentProjects: state.recentProjects,
  orgs: state.auth ? state.auth.orgs : [],
  zone: state.zone,
})
export default compose(connect(mapStateToProps))(UserSettingsDropdown)
