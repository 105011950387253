import { useTemplateLibraryModalControl } from 'projectSections/sections/proposal/templateLibrary'
import React from 'react'
import type { ProposalDataType } from 'types/proposals'
import { useFeatureFlag } from 'util/split'
import TemplateLibraryModal from './TemplateLibraryModal'
import { useAutoOpenIfNeverChooseBefore, useAutoOpenIfTemplateNotCompatible } from './useAutoOpenTemplateModal'

type ControlledTemplateLibraryModalPropTypes = {
  proposalData: ProposalDataType
  handleChange(): void
  setWaitingForAction(value: boolean): void
}

const TemplateLibraryModalWithAutoOpenListener: React.FC<ControlledTemplateLibraryModalPropTypes> = ({
  proposalData,
  ...props
}) => {
  const { open, disableClose, setOpen, setClose } = useTemplateLibraryModalControl()

  const autoOpen = () => {
    if (open) return
    setOpen({ disableClose: true })
  }

  useAutoOpenIfNeverChooseBefore({ proposalData, open, autoOpen })
  useAutoOpenIfTemplateNotCompatible({ proposalData, autoOpen })

  return (
    <TemplateLibraryModal
      disableClose={disableClose}
      isSystemLimitExceeded={proposalData.systems.length > 6}
      open={open}
      handleChange={props.handleChange}
      closeDialog={setClose}
      setWaitingForAction={props.setWaitingForAction}
    />
  )
}

const ControlledTemplateLibraryModal = ({
  proposalData,
  ...rest
}: {
  proposalData: ProposalDataType | undefined
  handleChange(): void
  setWaitingForAction(value: boolean): void
}) => {
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  if (!enableProposalV2) return null
  if (proposalData === undefined || proposalData === null) return null
  return <TemplateLibraryModalWithAutoOpenListener proposalData={proposalData} {...rest} />
}

export default ControlledTemplateLibraryModal
