import { projectListViewSelectors } from 'ducks/projectListViews'
import { viewModeSelectors } from 'ducks/viewMode'
import { useNewProjectsList } from 'hooks/useNewProjectsList'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ProjectsList from './ProjectsList'
import ProjectListLite from './ProjectsListLite'

const SwitchProjectsList = (props) => {
  const ui3Enabled = useNewProjectsList()
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const defaultView = useSelector(projectListViewSelectors.getDefaultViewId)
  if (isUserLite === undefined) return <FullScreenLoading />
  if (isUserLite)
    return (
      <div>
        <ProjectListLite {...props} />
      </div>
    )
  if (ui3Enabled === undefined) return <FullScreenLoading />
  if (ui3Enabled) return <Redirect to={'/projects/view/' + (defaultView || 0)} />
  return (
    <div>
      <ProjectsList {...props} />
    </div>
  )
}

//hack for project page
export default {
  list: SwitchProjectsList,
  edit: null,
  create: null,
  options: {
    show: {
      subtitle: null,
      breadCrumb: 'Edit Project',
    },
    list: {
      //hide bread crumb and use custom project selector dropdown as title
    },
    create: {
      // auto generate default title and bread crumb for create
    },
  },
}
