import BannerAd from 'elements/BannerAd'
import { ContentsConfig } from 'opensolar-pageeditor'
import { styled } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import { FC, useEffect, useMemo, useState } from 'react'
import restClient from 'restClient'
import FeaturedContent from './FeaturedContent'
import FilterSideNav from './filtering/FilterSideNav'
import { StandFilters } from './filtering/util'
import { Partner } from './PartnerCard'
import Partners from './Partners'

const Wrapper = styled('div', { name: 'Wrapper' })({
  display: 'flex',
  height: '100vh',
})

const Content = styled('div', { name: 'Content' })({
  padding: 30,
  backgroundColor: '#F6F9FC',
  flex: 1,
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
})

interface HomepageData {
  contents: ContentsConfig
  partners: Partner[]
  categories: string[]
}

const restClientInstance = restClient(window.API_ROOT + '/api')
const Homepage: FC = () => {
  const notify = useNotify()
  const [pageData, setPageData] = useState<HomepageData | null>(null)
  const [filters, setFilters] = useState<StandFilters>({})
  const filtersApplied = useMemo(() => filters.tags || filters.search, [filters])
  const handleSearch = (value: string) => {
    setFilters({ ...filters, search: value })
  }

  useEffect(() => {
    let url = `partners/homepage_data/`
    if (filters?.tags) url += `?tags=${filters?.tags}`
    if (filters?.search) url += `${url.includes('?') ? '&' : '?'}search=${filters.search}`
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((res) => {
        if (res.data) {
          setPageData(pageData === null ? res.data : { ...pageData, partners: res.data?.partners })
        }
      })
      .catch((err) => {
        notify('Failed to retrieve homepage data: ' + err, 'warning')
      })
  }, [filters])

  return (
    <Wrapper>
      <FilterSideNav filters={filters} setFilters={setFilters} categories={pageData?.categories} />
      <Content>
        {!filtersApplied && (
          <>
            <BannerAd
              formats={['leaderboard']}
              placement="expo_homepage"
              requiredWindowSize={{ width: 960 }}
              rounded={true}
            />
            {pageData?.contents && <FeaturedContent pageData={pageData?.contents} />}
          </>
        )}
        {pageData?.partners && <Partners partners={pageData?.partners} handleSearch={handleSearch} />}
      </Content>
    </Wrapper>
  )
}

export default Homepage
