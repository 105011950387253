import { FC } from 'react'

/**
 * * Icon Component for: upload icon
 */
type UploadIconProps = {
  size?: number
  color?: string
}
export const UploadIcon: FC<UploadIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <g id="upload-03">
        <path
          id="Icon"
          d="M3.3335 13.1703V16.2437C3.3335 16.7095 3.50909 17.1562 3.82165 17.4856C4.13421 17.815 4.55814 18 5.00016 18H15.0002C15.4422 18 15.8661 17.815 16.1787 17.4856C16.4912 17.1562 16.6668 16.7095 16.6668 16.2437V13.1703M10.0008 12.9521L10.0008 3M10.0008 3L6.19126 6.80265M10.0008 3L13.8103 6.80265"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
