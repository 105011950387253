import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import React from 'react'

type PropTypes = {
  message: React.ReactNode
  logoUrl: string
  show: boolean
}

export const SunlightApplicationLoadingDialog: React.FunctionComponent<PropTypes> = (props) => {
  return (
    <Dialog open={props.show} style={{ textAlign: 'center' }}>
      <DialogContent style={{ padding: '10px 40px' }}>
        <img alt="Sunlight Financial" src={props.logoUrl} style={{ maxHeight: 84, maxWidth: 220 }} />
        <div style={{ margin: '20px 0px' }}>
          <CircularProgress
            size={60}
            //@ts-ignore
            left={10}
            top={0}
            style={{
              display: 'inline-block',
              position: 'relative',
            }}
          />
        </div>
        {props.message}
      </DialogContent>
    </Dialog>
  )
}
