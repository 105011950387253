import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_FILTER_KEY_V2 } from 'elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import type { FilterValuesType } from 'elements/hardwareFilter/type'
import Pagination from 'elements/pagination/Pagination'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import { useComponentCost } from 'projectSections/hooks/useComponentCost'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDistributorTradePricing from 'services/useDistributorTradePricing'
import { ComponentTypesV2 } from 'types/selectComponent'
import { useFeatureFlag } from 'util/split'
import { HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2 } from '../hardwareFilter/constantsV2'
import HardwareSelectorFilterSidebar from './elements/HardwareSelectorFilterSidebar'
import HardwareSelectorHeader from './elements/HardwareSelectorHeader'
import HardwareSelectorLayout from './elements/HardwareSelectorLayout'
import HardwareSelectorList from './elements/HardwareSelectorList'
import HardwareSelectorToolBars, { getPlaceholderByComponentType } from './elements/HardwareSelectorToolBars'
import useHardwareSelectorFetchEngine from './fetch/useHardwareSelectorFetchEngine'
import useHandleSelect from './useHandleSelect'

export type DistributorTradePricing = {
  [key: string]: boolean
}

export type DistributorTradePricingContextType = {
  distributorTradePricing: DistributorTradePricing
  isChecking: boolean
}

export const DistributorTradePricingContext = React.createContext<DistributorTradePricingContextType>({
  distributorTradePricing: {},
  isChecking: false,
})

export type HardwareSelectorDialogConfig = {
  componentTypes: ComponentTypesV2[]
  defaultFilterValues?: FilterValuesType
  targetUuids?: string[]
  slotKey?: string
  title?: string
}

export const HardwareSelectorCore = ({
  layoutOptions: { footer, isHeaderHidden } = {
    footer: null,
    isHeaderHidden: false,
  },
  onClose,
  config,
}: {
  layoutOptions?: {
    footer?: React.ReactNode
    isHeaderHidden?: boolean
  }
  onClose(): void
  config: HardwareSelectorDialogConfig
}) => {
  const enabledMultiDistributorSelector = useFeatureFlag('design_multi_distributor', 'on')

  const hardwareDistributors = useEnabledHardwareSuppliers()

  // Check if the distributor has trade pricing available. We use this to suppress
  // public pricing for some distributors.
  const { distributorTradePricing, isChecking } = useDistributorTradePricing({
    orgId: useSelector(orgSelectors.getOrg)?.id || 0,
    distributors: hardwareDistributors,
  })

  const defaultFilterValues = useMemo(() => {
    return {
      ...config.defaultFilterValues,
      [DISTRIBUTOR_FILTER_KEY_V2]: null,
    }
  }, [])

  const { updateComponentCosts } = useComponentCost()

  const handleSelect = useHandleSelect({ onClose, config })

  const doHandleSelect = useCallback(
    (component, priceSource, price) => {
      handleSelect(component)

      if (enabledMultiDistributorSelector) {
        const componentActivation = window.AccountHelper.getComponentActivationFromCode(component.code, component.type)
        // This handles the default case for the pricing dropdown in the hardware selector
        const effectivePrice = priceSource === 'component' ? componentActivation?.cost || 0 : price
        updateComponentCosts([
          {
            code: component.code,
            source: priceSource,
            price: effectivePrice,
            activationId: componentActivation?.id || 0,
          },
        ])
      }
    },
    [handleSelect]
  )

  const {
    componentsData,
    setFilters,
    total = 0,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useHardwareSelectorFetchEngine({
    componentTypes: config.componentTypes,
    limit: 20,
    defaultFilterValues,
  })

  const handleUpdate = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  const handleSearchTextUpdate = useCallback(
    (searchText) => {
      handleUpdate({ search: searchText })
    },
    [handleUpdate]
  )

  const hiddenColumns = new Set<string>().add('price').add('stock')
  const shouldShowRating = config.componentTypes.some((type) => ['module', 'inverters', 'battery'].includes(type))
  if (!shouldShowRating) {
    hiddenColumns.add('rating')
  }

  return (
    <DistributorTradePricingContext.Provider value={{ distributorTradePricing, isChecking }}>
      <HardwareSelectorLayout
        footer={footer}
        heading={
          !isHeaderHidden ? (
            <HardwareSelectorHeader title={config.title || 'Select Hardware'} handleClose={onClose} />
          ) : null
        }
        toolBars={
          <HardwareSelectorToolBars
            componentType={config.componentTypes[0]}
            placeholder={getPlaceholderByComponentType(config.componentTypes[0])}
            onChange={handleSearchTextUpdate}
          />
        }
        listContent={
          <HardwareSelectorList
            total={total}
            loading={loading}
            data={componentsData}
            onSelect={doHandleSelect}
            Pagination={
              <Pagination total={total} page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
            }
            hiddenColumns={hiddenColumns}
          />
        }
        sidebar={
          <HardwareSelectorFilterSidebar
            hardwareFilterConfigList={HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2}
            persistentFilterValues={persistentFilterValues}
            modifiableFilterValues={modifiableFilterValues}
            allFilterValues={allFilterValues}
            setModifiableFilterValues={setFilters}
            componentTypes={config.componentTypes}
            location="design"
          />
        }
      />
    </DistributorTradePricingContext.Provider>
  )
}

export default HardwareSelectorCore
