import { FC } from 'react'

/**
 * * Icon Component for: trash-icon
 */
type TrashOutlineIconProps = {
  size?: number
  color?: string
}
export const TrashOutlineIcon: FC<TrashOutlineIconProps> = ({ size = '20', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <g id="trash-03">
        <path
          id="Icon"
          d="M3.33301 5.14706H16.6663M7.49967 2.5H12.4997M12.9163 17.5H7.08301C6.16253 17.5 5.41634 16.7099 5.41634 15.7353L5.03584 6.06614C5.01612 5.56486 5.3946 5.14706 5.86845 5.14706H14.1309C14.6047 5.14706 14.9832 5.56486 14.9635 6.06614L14.583 15.7353C14.583 16.7099 13.8368 17.5 12.9163 17.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}
