// @ts-nocheck
import { ThemeProvider } from '@material-ui/styles'
import { createMyTheme as createMuiTheme, Notification } from 'ra-ui-materialui'
import React, { ComponentType, createElement, FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import {
  AdminChildren,
  CatchAllComponent,
  CustomRoutes,
  DashboardComponent,
  LayoutComponent,
  LayoutProps,
  LoginComponent,
  TitleComponent,
} from '../types'
import CoreAdminRouter from './CoreAdminRouter'

export type ChildrenFunction = () => ComponentType[]

const DefaultLayout: FunctionComponent<LayoutProps> = ({ children }) => <>{children}</>

export interface AdminUIProps {
  catchAll?: CatchAllComponent
  children?: AdminChildren
  customRoutes?: CustomRoutes
  dashboard?: DashboardComponent
  layout?: LayoutComponent
  loading?: ComponentType
  loginPage?: LoginComponent | boolean
  logout?: ComponentType
  menu?: ComponentType
  themePackages?: object
  theme?: object
  persistentContent?: object
  title?: TitleComponent
}

// for BC
export type CoreAdminUIProps = AdminUIProps

export const CoreAdminUI: FunctionComponent<AdminUIProps> = ({
  catchAll = Noop,
  children,
  customRoutes = [],
  dashboard,
  layout = DefaultLayout,
  loading = Noop,
  loginPage = false,
  persistentContent,
  logout,
  menu, // deprecated, use a custom layout instead
  theme,
  title = 'React Admin',
}) => {
  return (
    <div>
      <Switch>
        {loginPage !== false && loginPage !== true ? (
          <Route
            exact
            path="/login"
            render={(props) =>
              createElement(loginPage, {
                ...props,
                title,
                theme,
              })
            }
          />
        ) : null}
        <Route
          path="/"
          render={(props) => (
            <CoreAdminRouter
              catchAll={catchAll}
              customRoutes={customRoutes}
              dashboard={dashboard}
              layout={layout}
              loading={loading}
              logout={logout && createElement(logout)}
              menu={menu}
              theme={theme}
              title={title}
              {...props}
            >
              {children}
            </CoreAdminRouter>
          )}
        />
      </Switch>
      {persistentContent}
      <Notification />
    </div>
  )
}

const Noop = () => null

const CoreAdminUIWithTheme = ({ theme, themePackages, ...props }) => {
  const themeProp = useRef(theme)
  const themeOverride = themePackages && themePackages[theme]
  const [MuiTheme, setTheme] = useState(createMuiTheme(themeOverride))

  useEffect(() => {
    if (themeProp.current !== theme) {
      themeProp.current = theme
      setTheme(createMuiTheme(themeOverride))
    }
  }, [themeOverride, themeProp, MuiTheme, setTheme])

  return (
    <ThemeProvider theme={MuiTheme}>
      <CoreAdminUI {...props} />
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme || 'opensolar',
  }
}

export default connect(mapStateToProps)(CoreAdminUIWithTheme)
