import { createTheme } from '@mui/material'

// Stratis UI font
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

declare module '@mui/material/styles' {
  interface Palette {
    red: { 800: string; 900: string }
    blue: { 500: string; 600: string; 700: string }
    green: { 600: string }
    orange: { 500: string }
  }
  interface PaletteOptions {
    red: { 800: string; 900: string }
    blue: { 500: string; 600: string; 700: string }
    green: { 600: string }
    orange: { 500: string }
  }
}

const palette = {
  primary: { main: '#FFD800', contrastText: '#212121', light: '#FFE900', dark: '#FFC400' },
  secondary: { main: '#F8F8F8', contrastText: '#212121', light: '#ECECED', dark: '#e6e6e6' },
  info: { main: '#ebf0fb', contrastText: '#003e97', light: '#f0f4fc', dark: '#d9e2f3' },
  warning: { main: '#fff6e7', contrastText: '#9d6508', light: '#fffaf2', dark: '#f9e8c7' },
  error: { main: '#ffe3e3', contrastText: '#961616', light: '#fff0f0', dark: '#f9cfcf' },
  success: { main: '#e1faea', contrastText: '#016626', light: '#f0fcf7', dark: '#c5f3d9' },
  grey: {
    50: '#F9F9F9',
    60: '#FBFAFC',
    100: '#F2F2F2',
    110: '#F1F1F1',
    120: '#EFF0F2',
    130: '#ECECED',
    200: '#E5E5E5',
    300: '#D9D9D9',
    350: '#C9C9CC',
    400: '#BFBFBF',
    500: '#A6A6A6',
    600: '#8C8C8C',
    700: '#737373',
    800: '#595959',
    810: '#4F4D55',
    900: '#404040',
  },
  red: {
    800: '#E12121',
    900: '#BC1C1C',
  },
  blue: {
    500: '#618DF2',
    600: '#4272DD',
    700: '#1751D0',
  },
  green: {
    600: '#019939',
  },
  orange: {
    500: '#EC980C',
  },
}

const BOX_SHADOW_CONFIGS = {
  soft: '0px 1.5px 4px -1px rgba(0, 0, 0, 0.07)',
  menu: '0px 6px 16px 0px rgba(10, 9, 11, 0.08)',
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 680,
    md: 850,
    lg: 1000,
    xl: 1280,
  },
}
const STANDARD_BORDER = '1px solid ' + palette.grey[110]
const STANDARD_OUTLINE = {
  borderRadius: 8,
  border: STANDARD_BORDER,
  boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
}

export const BaseTheme = createTheme({
  mixins: {
    borderStandard: {
      border: STANDARD_BORDER,
    },
    outlineStandard: {
      ...STANDARD_OUTLINE,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif !important', // important to override designer.css
    fontSize: 14,
  },
  palette,
  breakpoints,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },
        outlinedPrimary: {
          borderColor: palette.secondary.dark,
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main,

          '&:hover': {
            backgroundColor: palette.secondary.main,
            borderColor: '#b6b6b6',
          },
        },
        outlinedSecondary: {
          borderColor: palette.secondary.dark,
          color: palette.secondary.contrastText,
          backgroundColor: 'white',
        },
        textSecondary: {
          color: palette.secondary.contrastText,
        },
        textInfo: {
          color: palette.info.contrastText,
        },
        textError: {
          color: palette.error.contrastText,
        },
        outlinedError: {
          color: palette.error.contrastText,
          borderColor: palette.error.contrastText,
        },
        containedError: {
          backgroundColor: palette.error.contrastText,
          color: palette.error.light,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 5,
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            borderRadius: 5,
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
        outlined: {
          border: '1px solid ' + palette.secondary.dark,
          backgroundColor: 'transparent',
          color: palette.grey[900],
          '& .OSUI-Chip-deleteIcon': {
            color: palette.grey[800],
          },
        },
        colorSecondary: {
          backgroundColor: palette.secondary.light,
        },
        sizeSmall: {
          fontSize: '0.75rem',
        },
        iconSmall: {
          fontSize: '0.875rem',
          marginRight: -5,
        },
        iconMedium: {
          fontSize: '0.875rem',
          marginRight: -10,
          paddingLeft: 5,
        },
        deleteIconSmall: {
          fontSize: '1rem',
          marginLeft: -6,
        },
        deleteIconMedium: {
          fontSize: '1rem',
          marginLeft: -8,
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            color: palette.secondary.dark + ' !important',
          },
        },
        {
          props: { color: 'info' },
          style: {
            color: palette.info.contrastText + ' !important',
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: palette.success.contrastText + ' !important',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: palette.warning.contrastText + ' !important',
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: palette.error.contrastText + ' !important',
          },
        },
      ],
    },
    MuiRadio: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            color: palette.secondary.dark + ' !important',
          },
        },
        {
          props: { color: 'info' },
          style: {
            color: palette.info.contrastText + ' !important',
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: palette.success.contrastText + ' !important',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: palette.warning.contrastText + ' !important',
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: palette.error.contrastText + ' !important',
          },
        },
      ],
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 23,
          padding: '2px 0',
        },
        sizeSmall: {
          height: 16,
          width: 23,
          '.OSUI-Switch-switchBase': {
            padding: 0,
            margin: '3px 1.5px',
            '&.Mui-checked': {
              transform: 'translateX(10.5px) translateY(0px)',
            },
          },
          '.OSUI-Switch-thumb': {
            width: 10,
            height: 10,
          },
        },
        switchBase: {
          padding: 0,
          margin: '3.5px 2px',
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px) translateY(0px)',
            color: 'white',
          },
          '&.Mui-checked.Mui-disabled': {
            color: 'white',
          },
        },
        thumb: {
          boxSizing: 'border-box',
          width: 16,
          height: 16,
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: palette.secondary.dark,
          opacity: 1,
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            border: 0,
          },
          '.Mui-checked.Mui-disabled + &': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        icon: {
          display: 'flex',
          alignItems: 'center',
          marginRight: 10,
        },
        standardInfo: {
          backgroundColor: palette.info.main,
          border: '1px solid ' + palette.info.dark,
          color: palette.info.contrastText,
          fill: palette.info.contrastText,
        },
        standardWarning: {
          backgroundColor: palette.warning.main,
          border: '1px solid ' + palette.warning.dark,
          color: palette.warning.contrastText,
          fill: palette.warning.contrastText,
        },
        standardSuccess: {
          backgroundColor: palette.success.main,
          border: '1px solid ' + palette.success.dark,
          color: palette.success.contrastText,
          fill: palette.success.contrastText,
        },
        standardError: {
          backgroundColor: palette.error.main,
          border: '1px solid ' + palette.error.dark,
          color: palette.error.contrastText,
          fill: palette.error.contrastText,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'squared' },
          style: {
            borderRadius: 8,
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            backgroundColor: 'none',
            color: palette.grey[120],
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
          padding: 8,
        },
        colorPrimary: {
          border: '1px solid ' + palette.secondary.dark,
          color: palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        colorSecondary: {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        colorError: {
          backgroundColor: palette.secondary.main,
          color: palette.error.contrastText,
          padding: 8,
          height: 35,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 20px',
          borderBottom: '1px solid ' + palette.grey[130],
          fontSize: 18,
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',

          '&>span': {
            flexGrow: 1,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 20px',
          borderTop: '1px solid ' + palette.grey[130],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[120],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 13,
          padding: '10px 16px',
          color: palette.grey[810],
          borderBottom: 'none',
          '&:first-child': {
            borderTopLeftRadius: 8,
          },
          '&:last-child': {
            borderTopRightRadius: 8,
          },
        },
        body: {
          border: '1px solid ' + palette.grey[110],
          borderLeft: 'none',
          borderRight: 'none',
        },
      },
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { fullWidth: true },
          style: {
            width: '100%',
          },
        },
      ],
      styleOverrides: {
        root: {
          display: 'flex',
          padding: 4,
          backgroundColor: palette.secondary.main,
          boxSizing: 'border-box',
          gap: 4,
        },
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: palette.primary.contrastText + ' !important',
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: palette.grey[800] + ' !important',
          },
        },
        {
          props: { color: 'info' },
          style: {
            color: palette.info.contrastText + ' !important',
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: palette.success.contrastText + ' !important',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: palette.warning.contrastText + ' !important',
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: palette.error.contrastText + ' !important',
          },
        },
        {
          props: { selected: true },
          style: {
            backgroundColor: 'white !important',
            boxShadow: BOX_SHADOW_CONFIGS.soft,
            fontWeight: 'bold',
            letterSpacing: 0, // To mitigate spacing changes when selected (due to bolding)
          },
        },
        {
          props: { selected: true, color: 'primary' },
          style: {
            backgroundColor: palette.primary.main + ' !important',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          background: 'none',
          border: 'none',
          borderRadius: '4px !important',
          flexGrow: 1,
          lineHeight: 1,
          fontWeight: 400,
          letterSpacing: '0.02rem', // To mitigate spacing changes when selected (due to bolding)
        },
        sizeSmall: {
          padding: '8px 12px',
          fontSize: 14,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { color: 'info' },
          style: {
            color: palette.info.contrastText,
            backgroundColor: palette.info.main,
            '& .OSUI-OutlinedInput-notchedOutline': {
              border: 'none',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: palette.warning.contrastText,
            backgroundColor: palette.warning.main,
            '& .OSUI-OutlinedInput-notchedOutline': {
              border: 'none',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: palette.error.contrastText,
            backgroundColor: palette.error.main,
            '& .OSUI-OutlinedInput-notchedOutline': {
              border: 'none',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: palette.success.contrastText,
            backgroundColor: palette.success.main,
            '& .OSUI-OutlinedInput-notchedOutline': {
              border: 'none',
              boxShadow: 'none',
            },
          },
        },
      ],
      styleOverrides: {
        root: {},
        inputSizeSmall: {
          '&.OSUI-Select-select': {
            padding: '4px 12px',
            fontSize: 14,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          position: 'unset',
          WebkitTransform: 'unset',
          '&.Mui-focused': {
            color: palette.grey[900] + ' !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          paddingRight: '5px',
        },
        icon: {
          color: 'inherit',
          height: 18,
          width: 10,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: palette.grey[900],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-focused': {
            '& .OSUI-OutlinedInput-notchedOutline': {
              borderColor: palette.blue[700],
            },
          },
        },
        input: {
          padding: '8.5px 14px',
        },
        colorSecondary: {
          backgroundColor: 'white', //should this be a global style?
        },
        notchedOutline: {
          ...STANDARD_OUTLINE,
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          boxShadow: BOX_SHADOW_CONFIGS.menu,
          marginTop: 4,
        },
        list: {
          border: `1px solid ${palette.secondary.light}`,
          borderRadius: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          fontSize: 14,
          fontWeight: 475,
          lineHeight: '20px',
          margin: '0px 8px',
          padding: 8,
          '&.OSUI-MenuItem-divider': {
            border: 'none',
            marginBottom: 17,
            position: 'relative',
            '&::after': {
              backgroundColor: palette.secondary.light,
              bottom: -17,
              content: "''",
              height: 1,
              left: -8,
              margin: '8px 0px',
              pointerEvents: 'none',
              position: 'absolute',
              width: 'calc(100% + 16px)',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .Mui-error': {
            color: palette.error.contrastText + ' !important',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.primary.contrastText,
          color: '#fff',
          borderRadius: 8,
          padding: '8px 10px',
          fontWeight: 400,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: palette.grey[800],
          backgroundColor: 'white',
        },
      },
    },
  },
})

// Type augmentation for custom mixins
declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    borderStandard: CSSProperties
    outlineStandard: CSSProperties
  }
}

export type BaseThemeType = typeof BaseTheme
