import { CloseOutlined, EditOutlined, OpenInNewOutlined } from '@material-ui/icons'
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined'
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { Button } from '../../core'
import { LoadingDots } from '../../core/LoadingDots'
import { styled } from '../../styles'
import LoanMilestoneTimeline from './LoanMilestoneTimeline'
import { CheckoutFinanceAction, ExpectedMilestonePaymentType, LenderType } from './types'
import { formatCurrencyWithSymbol } from './utils'

const StyledDialog = styled(Dialog)<{ isMobile: boolean }>(({ isMobile }) => ({
  '&.MuiDialog-paper': {
    width: isMobile ? '100%' : 'auto',
    margin: isMobile ? '10px' : 'auto',
  },
  '&.MuiPaper-root': {
    width: isMobile ? '100%' : 'auto',
    margin: isMobile ? '10px' : 'auto',
  },
  zIndex: 2000,
}))
const StyledDialogTitle = styled(DialogTitle)(() => ({
  paddingBottom: '0px',
}))
const DialogTitleContentContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
}))
const DialogTitleText = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: isMobile ? '17px' : '18px',
  fontWeight: isMobile ? 400 : 500,
}))
const StyledCloseButton = styled(CloseOutlined)(() => ({
  color: '#757575',
}))
const TimelineContainer = styled('div')(() => ({
  margin: '16px 0px',
}))
const TimelineTitle = styled(Typography)(() => ({
  marginBottom: '25px',
}))
const LoanAmountCardContent = styled(CardContent)(() => ({
  textAlign: 'center',
  padding: '13px 14px !important',
}))
const LoanAmountTitle = styled('div')(() => ({
  marginBottom: '10px',
  whiteSpace: 'nowrap',
  color: '#212121',
}))
const StyledEditButton = styled(EditOutlined)(() => ({
  margin: '0px !important',
}))
const EditTextField = styled(TextField)(() => ({
  height: '28px',
  padding: '6px 0px',
}))
const LenderCard = styled(Card)(() => ({
  marginBottom: '19px',
}))
const LenderCardContent = styled(CardContent)(() => ({
  display: 'flex',
}))
const LenderLogo = styled('img')(() => ({
  marginBottom: '16px',
}))
const LenderDescription = styled(Typography)(() => ({
  whiteSpace: 'pre-line', // respects linebreaks
  marginBottom: '16px',
}))
const ApplyButtonWrapper = styled(Grid)(() => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  width: '100%',
  height: '100%',
}))
const ApplyButton = styled(Button)(() => ({
  marginLeft: 'auto',
}))
const LoadingDotsWrapper = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.5,
  backgroundColor: '#F6F6F6',
  zIndex: 10,
}))
const StyledDialogContent = styled(DialogContent)(() => ({
  padding: '8px 24px',
}))

export const CheckoutFinancingDialog = (props: {
  open: boolean
  onClose: () => void
  onApply: (lender: LenderType) => Promise<void>
  onEditLoanAmount: (field) => void
  actionData: CheckoutFinanceAction
  currencySymbol: string
  expectedMilestonePayments?: ExpectedMilestonePaymentType[] | undefined
  isLoading: boolean
  lenders: LenderType[]
  translate: (text: string) => string
}) => {
  const {
    open,
    onClose,
    onApply,
    onEditLoanAmount,
    actionData,
    expectedMilestonePayments,
    currencySymbol,
    isLoading,
    lenders,
    translate,
  } = props

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [loanAmount, setLoanAmount] = useState(actionData?.pricing?.system_price_including_tax)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditingAmount, setIsEditingAmount] = useState(false)
  const [milestonesChecked, setMilestonesChecked] = useState<{ [key: number]: boolean }>({})

  const handleApplyNowButton = async (lender) => {
    setIsSubmitting(true)
    await onApply(lender)
    setIsSubmitting(false)
  }

  const handleOnClose = () => {
    if (!isSubmitting) {
      onClose()
    }
  }

  return (
    <StyledDialog open={open} onClose={handleOnClose} isMobile={isMobile}>
      <StyledDialogTitle>
        <DialogTitleContentContainer>
          <DialogTitleText isMobile={isMobile}>
            <AccountBalanceOutlined />
            <span>{translate('Explore available financing')}</span>
          </DialogTitleText>
          <div>
            <IconButton onClick={handleOnClose}>
              <StyledCloseButton />
            </IconButton>
          </div>
        </DialogTitleContentContainer>
      </StyledDialogTitle>
      <StyledDialogContent>
        {!!lenders.length && (
          <TimelineContainer>
            {!!expectedMilestonePayments?.length && (
              <TimelineTitle variant="subtitle2">{translate('Select financed amount')}</TimelineTitle>
            )}
            <Grid container alignItems="center">
              {!!expectedMilestonePayments?.length && (
                <Grid item xs={12} md={9}>
                  <LoanMilestoneTimeline
                    onChangeTotalAmount={(amount) => setLoanAmount(amount)}
                    milestonesChecked={milestonesChecked}
                    setMilestonesChecked={(value) => {
                      setMilestonesChecked(value)
                    }}
                    milestones={expectedMilestonePayments}
                    currencySymbol={currencySymbol}
                    onCheck={() => onEditLoanAmount('checkbox')}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Card>
                  <LoanAmountCardContent>
                    <LoanAmountTitle>{translate('Total loan amount')}</LoanAmountTitle>
                    {!isEditingAmount ? (
                      <Button onClick={() => setIsEditingAmount(true)} variant="text">
                        <Box display="flex" alignItems="center">
                          <Typography variant="h6">
                            {`${formatCurrencyWithSymbol(loanAmount, currencySymbol, undefined, 0)}`}
                          </Typography>
                          <StyledEditButton />
                        </Box>
                      </Button>
                    ) : (
                      <EditTextField
                        type="number"
                        variant="standard"
                        value={loanAmount}
                        onChange={(e) => {
                          setMilestonesChecked({})
                          setLoanAmount(Number(e.target.value))
                        }}
                        onBlur={() => {
                          onEditLoanAmount('textfield')
                          setIsEditingAmount(false)
                        }}
                        autoFocus
                      />
                    )}
                  </LoanAmountCardContent>
                </Card>
              </Grid>
            </Grid>
          </TimelineContainer>
        )}
        {lenders.map((lender) => (
          <LenderCard key={lender.title}>
            <LenderCardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <LenderLogo
                    src={lender.logo_url}
                    height={lender.logo_height || '56px'}
                    alt={`${lender.title} logo`}
                  />
                  <LenderDescription variant="subtitle2">{lender.main_text}</LenderDescription>
                  <LenderDescription variant="body2" dangerouslySetInnerHTML={{ __html: lender.sub_text || '' }} />
                </Grid>
                <ApplyButtonWrapper container item xs={12} md={4}>
                  {lender.show_apply_button && (
                    <ApplyButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleApplyNowButton(lender)
                      }}
                      endIcon={<OpenInNewOutlined />}
                    >
                      {translate('Apply Now')}
                    </ApplyButton>
                  )}
                </ApplyButtonWrapper>
              </Grid>
            </LenderCardContent>
          </LenderCard>
        ))}

        {(isLoading || isSubmitting) && (
          <LoadingDotsWrapper>
            <LoadingDots />
          </LoadingDotsWrapper>
        )}

        {!lenders.length && !isLoading && (
          <Typography>{translate('There are no available lenders for your region at the moment.')}</Typography>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('Close')}</Button>
      </DialogActions>
    </StyledDialog>
  )
}
