import { styled } from 'opensolar-ui'
import { FC } from 'react'

interface PropTypes {
  logoUrl?: URL
  tagline?: string
  backgroundUrl?: URL
}

const CoverWrapper = styled('div', { name: 'CoverWrapper' })<{
  backgroundUrl: string
}>(({ backgroundUrl }) => ({
  maxHeight: 200,
  minHeight: 200,
  backgroundImage: backgroundUrl ? `url("${backgroundUrl}")` : '',
  paddingLeft: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: backgroundUrl ? '' : 'white',
}))

const CoverLogo = styled('img', { name: 'CoverWrapper' })({
  marginRight: 'auto',
  maxHeight: 80,
  maxWidth: 150,
})

const CoverTagline = styled('p', { name: 'CoverTagline' })({
  marginBottom: 0,
  marginTop: 20,
  fontSize: 32,
})

const Cover: FC<PropTypes> = ({ logoUrl, tagline, backgroundUrl }) => {
  return (
    <CoverWrapper backgroundUrl={backgroundUrl?.toString() || ''}>
      <CoverLogo src={logoUrl?.toString()} />
      <CoverTagline>{tagline}</CoverTagline>
    </CoverWrapper>
  )
}

export default Cover
