import { DialogContent as Mui4DialogContent, DialogContentProps as Mui4DialogContentProps } from '@material-ui/core'
import { default as Mui5DialogContent } from '@mui/material/DialogContent'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsDialogVersion } from './Dialog'

export const DialogContent: FC<OsDialogContentProps> = (props) => {
  return (
    <Inspector
      name="DialogContent"
      propDefs={DialogContentProps}
      showExample={true}
      component={OsDialogContent}
      props={props}
    />
  )
}

export type OsDialogContentProps = Mui4DialogContentProps

const OsDialogContent: FC<OsDialogContentProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('dialog')
  switch (version) {
    case OsDialogVersion.V2:
      return <Mui5DialogContent ref={ref} {...props} />

    default:
      return <Mui4DialogContent ref={ref} {...props} />
  }
})

const DialogContentProps: PropsDefs = {}
