export type AlteredDesignType = {
  [systemUuid: string]: { code; delta }[]
}

const useMutateDesignComponentQuantity = (fetchProjectData) => {
  return ({
    projectId,
    orgId,
    alteredDesign,
  }: {
    projectId: number
    orgId: number
    alteredDesign: AlteredDesignType
  }) => {
    const design = window.editor.sceneAsJSON()
    for (const systemUuid in alteredDesign) {
      const currentSystemDesign = design.object.children.find((child) => child.uuid === systemUuid)
      if (!currentSystemDesign) {
        // log error
        continue
      }
      const alters = alteredDesign[systemUuid]
      alters.forEach(function ({ code, delta }) {
        const targetComponents = currentSystemDesign.children.filter((child) => child.userData.code === code)
        if (!targetComponents || targetComponents.length === 0) {
          // log error
          return
        }
        const componentType = targetComponents[0].type

        targetComponents.every((component) => {
          const currentQuantity = component.userData?.quantity || 1
          const updatedQuantity = currentQuantity + delta
          if (updatedQuantity >= 0) {
            component.userData.quantity = updatedQuantity
            return false
          }
          // updatedQuantity is more than what this component can remove
          // zero out this component and update the next
          component.userData.quantity = 0
          delta = updatedQuantity
          return true
        })

        if (componentType === 'OsBattery') {
          currentSystemDesign.userData.battery_total_kwh = currentSystemDesign.userData.batteries
            .map(function (battery) {
              const batteryQuantity = battery.quantity ?? 1
              return parseFloat(battery.kwh_optimal) * parseFloat(batteryQuantity)
            })
            .reduce(function (a, b) {
              return a + b
            }, 0)
        }
      })
    }

    const unsaveDataOverride = {
      org_id: orgId,
      design: window.CompressionHelper.compress(JSON.stringify(design)),
    }
    fetchProjectData(projectId, undefined, { unsaveDataOverride })
  }
}

export default useMutateDesignComponentQuantity
