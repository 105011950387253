import { myEnergySelectors, updateShowTemplateLibraryModal } from 'ducks/myEnergy'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useTemplateLibraryModalControl = () => {
  const dispatch = useDispatch()
  const { open, disableClose } = useSelector(myEnergySelectors.getShowTemplateLibraryModal)

  return useMemo(
    () => ({
      open: open,
      disableClose: disableClose,
      setOpen: ({ disableClose }) =>
        dispatch(
          updateShowTemplateLibraryModal({
            open: true,
            disableClose,
          })
        ),
      setClose: () =>
        dispatch(
          updateShowTemplateLibraryModal({
            open: false,
            disableClose: false,
          })
        ),
    }),
    [open, disableClose]
  )
}

export default useTemplateLibraryModalControl
