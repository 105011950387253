import { Close } from '@material-ui/icons'
import CustomField from 'elements/field/CustomField'
import { IconButton, styled } from 'opensolar-ui'
import { InputProps } from 'ra-core'
import { FileInputOptions, FileInputProps } from 'ra-ui-materialui/src/input/FileInput'
import { FC } from 'react'
import { ImageField, ImageInput } from 'react-admin'
import { useField, useForm } from 'react-final-form'
const Wrapper = styled('div')({
  display: 'flex',
})
const ImageDisplay = styled('div', { name: 'ImageDisplay' })({
  position: 'relative',
})

const ClearButton = styled(IconButton, { name: 'ClearButton' })({
  position: 'absolute',
  right: -10,
  top: -10,
})

const ClearIcon = styled(Close, { name: 'ClearIcon' })(({ theme }) => ({
  backgroundColor: theme.palette.error.contrastText,
  borderRadius: 20,
  color: theme.palette.grey[100],
}))

interface PropTypes {
  source: string
  name: string
  record: any
  maxWidth?: string | number
}
const ImageDisplayField: FC<PropTypes & FileInputProps & InputProps<FileInputOptions>> = (props) => {
  const { source } = props
  const form = useForm()
  const fieldValue = useField(source, { subscription: { value: true } })?.input?.value
  const handleClear = () => {
    form.change(source, null)
  }
  return (
    <>
      {fieldValue && typeof fieldValue === 'string' && (
        <Wrapper>
          <ImageDisplay>
            <ClearButton size="small" onClick={handleClear}>
              <ClearIcon />
            </ClearButton>
            <ImageField
              record={props.record}
              source={source}
              title="title"
              imageStyle={{ maxWidth: props.maxWidth, margin: 0 }}
            />
          </ImageDisplay>
        </Wrapper>
      )}
      <CustomField component={ImageInput} {...props} name={props.name} source={source}>
        <ImageField source="src" title="title" />
      </CustomField>
    </>
  )
}

export default ImageDisplayField
