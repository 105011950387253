import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button, ExternalLinkIcon, HelpCircleIcon, styled } from 'opensolar-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import type { ProjectType } from 'types/projects'
import { ActionType } from 'types/tracking'
import { doNotTranslate } from 'util/misc'
import { useRemoveSelectedProject } from '../hooks'
import { SolarAppAHJIntegrationModes, SolarAppPermitStates, SolarAppProjectStatus } from '../types'
import { PROJECT_STATUS_TO_CTA_STATE } from './constants'

export const ActionButtonWrapperUI3 = styled('div')({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
})

const ActionButtonWrapper = styled('div')({
  marginTop: '24px',
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
})

const ActionButtonWithRightIcon = styled(Button)<{ ui3Enabled: boolean }>(
  ({ theme, ui3Enabled }) =>
    ui3Enabled && {
      backgroundColor: `${theme.palette.blue[700]} !important`,
      color: '#fff !important',
      fontSize: '12px !important',
      padding: '5px 13px 5px 9px !important',
    }
)

interface PropTypes {
  project: ProjectType
  solarAppProjectStatus: SolarAppProjectStatus
  updateSolarAppProjectDetails?: (newId?: string, newUrl?: string) => void
}

export const StepActionsWrapper = ({ children }) => {
  const ui3Enabled = useUxVersion() === 3
  return ui3Enabled ? (
    <ActionButtonWrapperUI3>{children}</ActionButtonWrapperUI3>
  ) : (
    <ActionButtonWrapper>{children}</ActionButtonWrapper>
  )
}

export const StepActions: React.FC<PropTypes> = ({ project, solarAppProjectStatus, updateSolarAppProjectDetails }) => {
  const { isLoading: removeProjectLoading, handleRemoveProject } = useRemoveSelectedProject(
    project,
    updateSolarAppProjectDetails
  )
  const userId = useSelector(authSelectors.getCurrentUserId)
  const { trackUserInteraction } = useTrackComponent({
    componentKey: 'step_actions',
    eventName: 'User Interacted With SolarApp Step Actions',
  })

  const ui3Enabled = useUxVersion() === 3

  return (
    <StepActionsWrapper>
      <>
        <ActionButtonWithRightIcon
          variant="contained"
          color={ui3Enabled ? 'secondary' : 'default'}
          onClick={() => {
            trackUserInteraction(
              { type: ActionType.CLICK },
              {
                eventName: 'SolarAPP Project Link Clicked',
                metadata: {
                  uuid: solarAppProjectStatus.uuid,
                  userId,
                },
              }
            )
            window.open(project?.custom_data?._solarapp_permit?.url || solarAppProjectStatus.url, '_blank')
          }}
          endIcon={<ExternalLinkIcon size={18} color={ui3Enabled ? 'white' : undefined} />}
          ui3Enabled={ui3Enabled}
        >
          {PROJECT_STATUS_TO_CTA_STATE[solarAppProjectStatus.current_state]}
        </ActionButtonWithRightIcon>

        {/* 
          If the SolarAPP+ permit is qualified and the AHJ is integrated with SolarAPP+ with
          an avaliable payment url then flag their may be fees to pay
        */}
        {solarAppProjectStatus.current_state === SolarAppPermitStates.APPROVED &&
          solarAppProjectStatus.ahj.mode === SolarAppAHJIntegrationModes.INTEGRATED &&
          solarAppProjectStatus.ahj.payment_url && (
            <Tooltip
              title={doNotTranslate(`
                The associated AHJ may require paying jurisdiction fees. We cannot track fee payments.\n\n
                Please ensure all fees to "${solarAppProjectStatus.ahj.name}" are paid to finalize the permit.
            `)}
              placement="bottom"
            >
              <Button
                variant="outlined"
                color={ui3Enabled ? 'secondary' : 'info'}
                onClick={() => {
                  window.open(solarAppProjectStatus.ahj.payment_url, '_blank')
                }}
                endIcon={<HelpCircleIcon size={18} />}
              >
                {ui3Enabled ? 'Jurisdiction Fees Portal' : 'Jurisdiction Fees'}
              </Button>
            </Tooltip>
          )}

        {solarAppProjectStatus && solarAppProjectStatus.current_state === SolarAppPermitStates.ARCHIVED && (
          <Button
            variant="outlined"
            color={ui3Enabled ? 'error' : 'secondary'}
            onClick={handleRemoveProject}
            disabled={removeProjectLoading}
          >
            Unlink Permit
          </Button>
        )}
      </>
    </StepActionsWrapper>
  )
}
