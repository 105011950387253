import { FC } from 'react'

/**
 * * Icon Component for: phone-call-01
 */
type PhoneCallIconProps = {
  size?: number
  color?: string
}
export const PhoneCallIcon: FC<PhoneCallIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes} viewBox="0 0 20 20" fill="none">
      <path
        d="M10.8166 4.68056C11.9415 4.86909 12.9634 5.40039 13.7791 6.21448C14.5949 7.02858 15.123 8.04833 15.3162 9.17092M10.9884 1C12.9891 1.33849 14.8139 2.28541 16.2651 3.72935C17.7163 5.17758 18.6608 6.99857 19 8.99524M17.4371 16.9391C17.4371 16.9391 16.3509 18.006 16.0847 18.3187C15.6511 18.7815 15.1401 19 14.4704 19C14.406 19 14.3373 19 14.2729 18.9957C12.9977 18.9143 11.8127 18.4173 10.924 17.9931C8.49385 16.8191 6.36 15.1523 4.5868 13.04C3.12273 11.279 2.14382 9.6508 1.4955 7.90264C1.09621 6.83575 0.950234 6.00452 1.01464 5.22042C1.05757 4.71911 1.25078 4.3035 1.60713 3.94787L3.07121 2.48679C3.28159 2.28969 3.50485 2.18257 3.72381 2.18257C3.9943 2.18257 4.21327 2.34539 4.35066 2.4825C4.35495 2.48679 4.35925 2.49107 4.36354 2.49536C4.62544 2.73958 4.87446 2.99238 5.13636 3.26232C5.26946 3.39943 5.40685 3.53654 5.54424 3.67793L6.71636 4.84765C7.17147 5.30183 7.17147 5.72173 6.71636 6.17591C6.59185 6.30017 6.47163 6.42442 6.34712 6.54439C5.98647 6.91288 6.26978 6.63014 5.89625 6.96435C5.88766 6.97292 5.87908 6.9772 5.87478 6.98577C5.50554 7.35425 5.57424 7.71417 5.65152 7.9584C5.65582 7.97125 5.66011 7.9841 5.6644 7.99696C5.96924 8.73393 6.39859 9.42805 7.05119 10.255L7.05549 10.2593C8.24048 11.7161 9.48988 12.8515 10.8681 13.7213C11.0441 13.8327 11.2244 13.9227 11.3962 14.0084C11.5507 14.0855 11.6967 14.1583 11.8212 14.2355C11.8384 14.244 11.8556 14.2569 11.8728 14.2655C12.0187 14.3383 12.1561 14.3726 12.2978 14.3726C12.6542 14.3726 12.8774 14.1498 12.9504 14.0769L13.792 13.2371C13.938 13.0914 14.1698 12.9157 14.4403 12.9157C14.7065 12.9157 14.9255 13.0828 15.0586 13.2285C15.0629 13.2328 15.0629 13.2328 15.0672 13.2371L17.4329 15.598C17.8751 16.035 17.4371 16.9391 17.4371 16.9391Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
