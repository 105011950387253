import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { InvoicesSummaryType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetInvoicesSummary = (orgId: number | undefined, refreshKey: number) => {
  const [invoicesSummary, setInvoicesSummary] = useState<InvoicesSummaryType>()
  const [invoiceSettingsLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (orgId) {
      setIsLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/cashflow_invoices/summary/`,
      })
        .then((res) => {
          setInvoicesSummary(res.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => setIsLoading(false))
    }
  }, [orgId, refreshKey])

  return { invoicesSummary, invoiceSettingsLoading }
}

export const pushToAccounting = (orgId: number, invoiceId: number) => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/invoices/${invoiceId}/push_to_accounting/`,
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log('err', err)
        console.log('err?.body', err?.body)
        console.log('err?.body?.mesag', err?.body?.message)
        reject(err?.body?.message || 'An error occurred while syncing invoice')
      })
  })
}

export const getSyncStatus = (orgId: number, invoiceId: number) => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/invoices/${invoiceId}/sync_status/`,
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err?.body?.message || 'An error occurred while syncing invoice')
      })
  })
}

export const forcePushPaymentRecord = (orgId: number, invoiceId: number) => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/invoices/${invoiceId}/push_payment_record/`,
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err?.body?.message || 'An error occurred while syncing invoice')
      })
  })
}
