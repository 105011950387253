import { setStudioProfileState } from 'ducks/studio'
import { useCallback, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { useSpecialProjectId } from './useSpecialProjectId'

export const useLoadStudioProfile = (enabled: boolean, doCleanup: boolean): { isLoading: boolean } => {
  // Can get rid of isLoading once 'load_studio_profile_early' feature is fully rolled out
  const [isLoading, setLoading] = useState(enabled)
  const formState = useForm().getState()
  const notify = useNotify()
  const orgId = useSelector((state: RootState) => (state.auth ? state.auth.org_id : null)) || formState.values.org_id
  const identifier = formState.values.identifier || null
  const projectId = useSpecialProjectId()
  const dispatch = useDispatch()

  const reloadSpecs = useCallback(() => {
    const systems = window.editor.getSystems()
    systems.forEach((system) => {
      system.refreshDesignComponentSpecs()
    })
  }, [])

  useEffect(() => {
    if (!enabled) {
      setLoading(false)
      return
    }

    setLoading(true)
    dispatch(setStudioProfileState('loading'))

    //do not render studio until AccountHelper ready
    const loadStudioProfile = async () => {
      if (!window.AccountHelper.isLoaded()) {
        try {
          await window.WorkspaceHelper.loadStudioProfile({ id: projectId, org_id: orgId, identifier })
          reloadSpecs()
        } catch (error: any) {
          //TODO: improve error message
          notify('Error during initialization: ' + error?.['message'], 'error', { error })
        }
      }
      if (isLoading) {
        setLoading(false)
        dispatch(setStudioProfileState('loaded'))
      }
    }

    if (projectId === 'replay') {
      setLoading(false)
      dispatch(setStudioProfileState('loaded'))
    } else {
      loadStudioProfile()
    }

    // Cleanup function when exiting project
    return () => {
      if (doCleanup) {
        dispatch(setStudioProfileState('not-loaded'))
        window.AccountHelper.reset()
      }
    }
  }, [])

  return { isLoading }
}
