import { LinearProgress } from '@material-ui/core'
import { createMuiTheme, createStyles, withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Component, createElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import defaultTheme from '../defaultTheme'
import DefaultAppBar from './AppBar'
import DefaultError from './Error'
import { LayoutFooter } from './LayoutFooter'
import DefaultNotification from './Notification'
import DefaultSidebar from './Sidebar'

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      //CSS3 100vh not constant in mobile browser
      //https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
      minHeight: window.Utils?.iOS() ? '-webkit-fill-available' : '100vh',
      backgroundColor: theme.palette.background.default,
      // position: 'relative',
      // minWidth: 'fit-content',
      width: '100%',
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      // [theme.breakpoints.up('xs')]: {
      //   marginTop: theme.spacing(6),
      // },
      // [theme.breakpoints.down('xs')]: {
      //   marginTop: theme.spacing(7),
      // },
    },
    mainContent: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
      position: 'relative', // Allows child pages to fill the content area exactly
    },
    appHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 100,
      width: '100%',
    },

    content: {
      display: 'flex',
      // width: '100%',
      overflowY: 'auto',
      marginTop: 0,
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      // padding: theme.spacing(3),
      // paddingTop: theme.spacing(1),
      // paddingLeft: 0,
      // [theme.breakpoints.up('xs')]: {
      //   paddingLeft: 5,
      // },
      // [theme.breakpoints.down('xs')]: {
      //   padding: 0,
      // },
    },
  })

const sanitizeRestProps = ({ staticContext, history, location, match, ...props }) => props

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null }

  constructor(props) {
    super(props)
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo })
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className = {},
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      bottomNavigation,
      title,
      header,
      isLoading,
      isNav3Enabled,
      ...props
    } = this.props
    const { hasError, errorMessage, errorInfo } = this.state
    const hasSideBar = !!menu
    return (
      <div className={classnames('layout', classes.root, className.root)} {...sanitizeRestProps(props)}>
        <div className={classes.appFrame}>
          <div id="app-header" className={classes.appHeader}>
            {!!header &&
              createElement(appBar, { title, open, logout, header, hasSideBar, isNav3Enabled, id: 'main-header' })}
            <div id="secondary-appbar" />
          </div>
          <main className={classes.mainContent}>
            {hasSideBar &&
              createElement(sidebar, {
                children: createElement(menu, {
                  logout,
                  hasDashboard: !!dashboard,
                }),
              })}
            <div className={classnames('content', classes.content, className.content)}>
              {hasError
                ? createElement(error, {
                    error: errorMessage,
                    errorInfo,
                    title,
                  })
                : children}
            </div>
          </main>
          {notification && createElement(notification)}
          {isLoading && (
            <LinearProgress
              color={'primary'}
              style={{
                zIndex: 2000,
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                height: '4px',
              }}
            />
          )}

          <LayoutFooter bottomNavigation={bottomNavigation} />
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.object,
  customRoutes: PropTypes.array,
  history: PropTypes.object.isRequired,
  isNav3Enabled: PropTypes.bool,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  appBar: DefaultAppBar,
  error: DefaultError,
  isNav3Enabled: false,
  notification: DefaultNotification,
  sidebar: DefaultSidebar,
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  isLoading: state.admin.loading > 0,
})

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(Layout)

const LayoutWithTheme = ({ theme: themeOverride, ...props }) => {
  const themeProp = useRef(themeOverride)
  const [theme, setTheme] = useState(createMuiTheme(themeOverride))

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride
      setTheme(createMuiTheme(themeOverride))
    }
  }, [themeOverride, themeProp, theme, setTheme])
  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  )
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
}

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
}

export default EnhancedLayout
