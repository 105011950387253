import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const PricingIconSvg: FC<IconProps> = (props) => {
  const { fill, stroke } = props

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 5C17.0596 5 16.5 5.55964 16.5 6.25C16.5 6.94036 17.0596 7.5 17.75 7.5C18.4404 7.5 19 6.94036 19 6.25C19 5.55964 18.4404 5 17.75 5ZM18.25 6.5C18.3881 6.22386 18.2761 5.88807 18 5.75C17.7239 5.61193 17.3881 5.72386 17.25 6C17.1119 6.27614 17.2239 6.61193 17.5 6.75C17.7761 6.88807 18.1119 6.77614 18.25 6.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9141 3C13.6489 3 13.3945 3.10536 13.207 3.29289L3.70696 12.7929C3.31643 13.1834 3.31643 13.8166 3.70696 14.2071L9.79274 20.2929C10.1832 20.6834 10.8165 20.6834 11.207 20.2929L20.7069 10.7929C20.8944 10.6054 20.9998 10.351 20.9998 10.0858V4C20.9998 3.44772 20.5521 3 19.9998 3H13.9141ZM19.9998 5C19.9998 4.44772 19.5522 4 18.9999 4H14.4141C14.1489 4 13.8945 4.10536 13.707 4.29289L5.20696 12.7929C4.81643 13.1834 4.81643 13.8166 5.20696 14.2071L9.79274 18.7929C10.1832 19.1834 10.8165 19.1834 11.207 18.7929L19.7069 10.2929C19.8944 10.1054 19.9998 9.851 19.9998 9.58579V5Z"
        fill={fill}
      />
      <path
        d="M15 11L14 10M14 10C13.4 9.4 12.6 9.4 12 10C11.4 10.6 11.4 11.4 12 12C12.6 12.6 12.6 13.4 12 14C11.4 14.6 10.6 14.6 10 14L9 13M14 10L15 9M9.00001 15L9.70001 14.3"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PricingIcon = createStandardIcon(PricingIconSvg)
