import { Table as Mui4Table, TableProps as Mui4TableProps } from '@material-ui/core'
import { default as Mui5Table, TableProps as Mui5TablePropsType } from '@mui/material/Table'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

export const Table: FC<OsTableProps> = forwardRef((props, ref) => {
  return (
    <Inspector name="Table" propDefs={TableProps} showExample={true} component={OsTable} props={{ ...props, ref }} />
  )
})

export enum OsTableVersion {
  V1 = 1, // MUI4
  V2 = 2, // MUI5 + Stratis UI
}
export type OsTableProps = Mui4TableProps

const OsTable: FC<OsTableProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('table')
  switch (version) {
    case OsTableVersion.V2:
      const { padding, ...rest } = props
      let padding5: Mui5TablePropsType['padding'] = padding === 'default' ? 'normal' : padding
      return <Mui5Table ref={ref} padding={padding5} {...rest} />

    default:
      return <Mui4Table ref={ref} {...props} />
  }
})

const TableProps: PropsDefs = {}
