import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import { FieldValidationErrors } from 'elements/react-admin/FieldValidationErrors'
import { useTranslate } from 'ra-core'
import { TextInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { DraftEditorWithContent } from 'resources/proposalTemplates/ProposalTemplates'
import { required } from 'validations'
import InputAccordion from '../common/InputAccordion'
import { validateVideoUrl } from '../form'
import { useBlockSource } from '../useBlockSource'

const DescFields = (props) => {
  const { disabled } = props
  const translate = useTranslate()
  const source = useBlockSource('our_story')
  const isPremiumExpo = useSelector(orgSelectors.getPremiumExpo)

  if (!source) return null
  return (
    <InputAccordion title={translate('Our Story')} source={source}>
      <CustomField
        label={'Body'}
        component={DraftEditorWithContent}
        name={`${source}.body`}
        source={`${source}.body`}
        record={props.record}
        resource={props.resource}
        disabled={disabled}
        toolbarButtons={{
          moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontSize', 'textColor'],
          },
        }}
        validate={required}
      />
      <FieldValidationErrors source={`${source}.body`} />
      {isPremiumExpo && (
        <TextInput
          label={'Video Link'}
          name={`${source}.video`}
          source={`${source}.video`}
          disabled={disabled}
          validate={validateVideoUrl}
        />
      )}
    </InputAccordion>
  )
}

export default DescFields
