import { FC } from 'react'
import type { IconProps } from './type'

interface SwapHorizIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const SwapHorizIcon: FC<SwapHorizIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  const viewBox = `0 0 ${size} ${size}`
  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.375 9.75L3 6.375M3 6.375L6.375 3M3 6.375H21M17.625 14.25L21 17.625M21 17.625L17.625 21M21 17.625H3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
