import { PartnerExpoStand } from 'elements/hardwareSelectorV2/types'
import { ComponentVersionsInherit, Link, OsTooltipVersion, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { ToolTipRestyle } from 'resources/projects/projects3/styles'
import { useFeatureFlag } from 'util/split'
import ExpoRibbonTag from './ExpoRibbonTag'

const Content = styled('div', { name: 'Content' })({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  fontWeight: 600,
  fontSize: 15,
  '& p': {
    margin: 0,
    fontSize: 15,
  },
  '& .desc': {
    fontWeight: 400,
  },
})

const ExpoTooltip: FC<PartnerExpoStand> = (props) => {
  const expoEnabled = useFeatureFlag('expo', 'on')
  if (!expoEnabled) return null
  return (
    <ComponentVersionsInherit versions={{ tooltip: OsTooltipVersion.V2 }}>
      <ToolTipRestyle title={<TipContent {...props} />} placement={'top'} arrow interactive>
        <div>
          <ExpoRibbonTag text="Expo Partner" />
        </div>
      </ToolTipRestyle>
    </ComponentVersionsInherit>
  )
}

const TipContent: FC<PartnerExpoStand> = ({ partnerName, slug }) => {
  const translate = useTranslate()
  return (
    <Content>
      <p>{translate('Expo Partner')}</p>
      <p className="desc">
        {translate("Get detailed information on this product by visiting the partner's expo stand.")}
      </p>
      <Link href={'#/expo/' + slug} target="_blank">
        {translate('Visit %{partnerName} Stand', { partnerName: partnerName })}
      </Link>
    </Content>
  )
}

export default ExpoTooltip
