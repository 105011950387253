import { isEmpty } from 'lodash'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { SegenQuoteItemType } from 'services/segen/type'
import { formatCurrency } from 'util/misc'

const validContactDetails = (data: any) => {
  return (
    !isEmpty(data?.email) &&
    !isEmpty(data?.firstName) &&
    !isEmpty(data?.lastName) &&
    !isEmpty(data?.name) &&
    !isEmpty(data?.phoneNumber)
  )
}

export const getShippingFeeCurrencySymbol = (fee: string, currencySymbol: string) => {
  return ['TBC', 'FREE'].includes(fee) ? '' : currencySymbol
}

export const validateSegenData = (data: any) => {
  return (
    !data?.isLoading &&
    !isEmpty(data?.quoteId) &&
    !isEmpty(data?.shippingInfo?.dispatchDate) &&
    !isEmpty(data?.shippingInfo?.deliveryOption) &&
    validContactDetails(data)
  )
}

export const validateCityPlumbingData = (data: any) => {
  const isDelivery = data?.shippingInfo?.deliveryMethod === 'delivery'
  if (isDelivery) {
    return (
      !isEmpty(data?.shippingInfo?.deliveryDate) &&
      !isEmpty(data?.shippingInfo?.deliveryTime) &&
      validContactDetails(data)
    )
  }
  return (
    !isEmpty(data?.shippingInfo?.deliveryDate) &&
    !isEmpty(data?.shippingInfo?.deliveryBranch?.name) &&
    validContactDetails(data)
  )
}

export const validateHdmData = (data: any) => {
  return validContactDetails(data)
}

export const validateVtacData = (data: any) => {
  return !isEmpty(data?.dealerId) && validContactDetails(data)
}

export const validateOutletData = (data: any) => {
  if (data?.shippingInfo?.deliveryMethod === 'Pickup at warehouse') {
    return !isEmpty(data?.shippingInfo?.warehouse) && validContactDetails(data)
  }
  return !isEmpty(data?.shippingInfo?.deliveryMethod) && validContactDetails(data)
}

export const getSegenDeliveryCostFromQuoteItems = (quoteItems: SegenQuoteItemType[]) => {
  return quoteItems.reduce((sum, cur) => {
    if (cur.productCode === 'DELIVERY') {
      return sum + cur.totalPrice.amount
    }
    return sum
  }, 0)
}

export const createOrderData = (distributorData, contactInfo, deliveryAddressInfo) => {
  const items = Object.values(distributorData['items']).map((item) => {
    const lineItemObj = new OrderLineItem(item as OrderLineItem)
    const { afterDiscount } = lineItemObj.getBestDiscountOffer()
    return {
      code: lineItemObj.code,
      quantity: lineItemObj.quantity,
      title: lineItemObj.data?.short_description,
      pricePerUnit: lineItemObj.pricePerUnit,
      variantId: lineItemObj.selectedDistributorOrderingData?.distributor_variant_id,
      sku: lineItemObj.data?.code,
      imageUrl: lineItemObj.data?.image_url || '',
      componentType: lineItemObj.componentType,
      totalPrice: formatCurrency((lineItemObj.pricePerUnit || 0) * lineItemObj.quantity),
      discountedPrice: formatCurrency(afterDiscount),
    }
  })
  return {
    ...distributorData,
    contactInfo: contactInfo,
    shippingInfo: {
      ...distributorData['shippingInfo'],
      address: deliveryAddressInfo,
    },
    items,
  }
}
