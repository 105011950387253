import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const BusinessInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()

  return (
    <div data-testid="bluesnap-business-info-top">
      <Container>
        <Typography textVariant="h5SemiBold">{translate("Let's Get Registered")}</Typography>
        <Typography textVariant="body1">
          {translate(
            'We use this information to confirm your business identity. Please enter your information exactly as it appears on your official tax documents.'
          )}
        </Typography>
      </Container>
    </div>
  )
}
export default BusinessInfoTop
