import { duplicate as duplicateAction } from 'actions/restActions'
import { authSelectors } from 'ducks/auth'
import CreateEditToolbar from 'elements/CreateEditToolbar'
import PaginationWithActions from 'elements/PaginationWithActions'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import { AuditInfo } from 'resources/exhibitContents/AuditInfo'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { inputStyle } from '../../activeComponents/common'
import { ActionsField, FieldFromComponentReferenceOrData, LYRA_SUPPORT_OPTIONS } from '../common'
import ComponentOrderingInfo from '../ComponentOrderingInfo'
import GenericComponentField from '../GenericComponentField'
import { customOtherDataFields } from './constants'

export const customOtherDataFieldsForAdditionalCosts = [
  'formula',
  'value',
  'cycle_units',
  'start',
  'end',
  'annual_adjustment_percentage',
]

export const customOtherDataFieldsDcOptimizer = [
  'dc_optimizer_efficiency',
  'dc_optimizer_max_input_power',
  'dc_optimizer_max_input_voltage',
  'dc_optimizer_max_input_current',
  'dc_optimizer_max_output_voltage',
  'dc_optimizer_max_output_current',
]

export const customOtherDataFieldsIsolator = [
  'current_type',
  'current_rating',
  'voltage_to_current_rating',
  'voltage_rating',
  'phase_type',
]

export const customOtherDataFieldsShowCustomer = ['show_in_your_solution', 'show_in_quotation_table']

export const customOtherDataFieldsCable = ['current_type', 'cable_thickness', 'cable_length']

export const customOtherDataFieldsMeter = ['phase_type']

export const otherDataFields = customOtherDataFields
  .filter((item) => item.source !== 'manufacturer_name')
  .filter(
    (item) =>
      customOtherDataFieldsForAdditionalCosts.indexOf(item.source) === -1 && item.source !== 'additional_costs_enabled'
  )

const formatSubmitValues = (values) => {
  const data = {}
  otherDataFields.forEach((fieldConfig) => {
    const key = fieldConfig.source
    data[key] = values[fieldConfig.custom_field_prefix_source]
    if (fieldConfig.component === 'Number') {
      data[key] = parseFloat(data[key])
    }
    if (fieldConfig.component === 'Boolean') {
      data[key] = !!data[key]
    }
  })
  const dataString = JSON.stringify(data)
  values.data = dataString
  values.code = values['custom_data_field_code']
  return values
}

const getDefaultValuesFromDataOthers = (record) => {
  // isCustom triggers whether to use standard or custom data fieldsets
  var data = record.data ? JSON.parse(record.data) : null

  var values
  const componentDataFields = otherDataFields

  values = Object.assign(
    {
      lookup_in_component_database: false,
    },
    record
  )

  if (data) {
    componentDataFields.forEach(
      (fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = data[fieldConfig.source])
    )
  } else {
    componentDataFields.forEach((fieldConfig) => (values[fieldConfig.custom_field_prefix_source] = ''))
  }

  return values
}

const mapStateToProps = (state) => {
  return {
    org_id: state.auth ? state.auth.org_id : null,
    currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
  }
}

const mapDispatchToProps = {
  duplicate: duplicateAction,
}

export const ComponentOtherList = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <List
    pagination={
      <PaginationWithActions
        uploadModel={'component_others'}
        downloadUrl={window.API_BASE_URL + 'component_others/?fieldset=csv&format=csv&ordering=-id'}
        downloadFilename="OpenSolarComponentOthers"
      />
    }
    title="Others"
    {...props}
    hasSearch={true}
    actions={<ListActions createButtonLabel="Add New Other" />}
  >
    <Datagrid>
      <FieldFromComponentReferenceOrData source="code" sortable={true} />
      <FieldFromComponentReferenceOrData source="manufacturer_name" sortable={false} />
      <FieldFromComponentReferenceOrData source="countries_include_csv" sortable={false} />
      <FieldFromComponentReferenceOrData source="countries_exclude_csv" sortable={false} />
      <ActionsField source="actions" duplicate={props.duplicate} sortable={false} />
    </Datagrid>
  </List>
))

const ComponentOtherName = ({ translate, style }) => {
  return <span style={style}>{translate('Edit Other Component')}</span>
}

export const OtherComponentField = ({ props, fieldConfig, isStaff, disabled = false }) => {
  return (
    <GenericComponentField
      props={props}
      fieldConfig={fieldConfig}
      isStaff={isStaff}
      extra={{
        customOtherDataFieldsForAdditionalCosts: customOtherDataFieldsForAdditionalCosts,
        customOtherDataFieldsDcOptimizer: customOtherDataFieldsDcOptimizer,
        customOtherDataFieldsIsolator: customOtherDataFieldsIsolator,
        customOtherDataFieldsCable: customOtherDataFieldsCable,
        customOtherDataFieldsMeter: customOtherDataFieldsMeter,
        customOtherDataFieldsShowCustomer: customOtherDataFieldsShowCustomer,
      }}
      disabled={disabled}
    />
  )
}

const _ComponentOtherEdit = (props) => {
  return (
    <Edit title={<ComponentOtherName />} {...props}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        defaultValue={getDefaultValuesFromDataOthers}
        toolbar={
          <CreateEditToolbar
            resource="component_batteries"
            org_id={props.org_id}
            duplicate={props.duplicate}
            redirect="/component_other_activations"
          />
        }
      >
        <AuditInfo record={props.record} />
        <ReferenceInput
          name="manufacturer"
          source="manufacturer"
          reference="manufacturers"
          optionValueField="url"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
          {...props}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="url"
            options={{
              style: inputStyle,
              containerStyle: inputStyle,
            }}
          />
        </ReferenceInput>
        {otherDataFields.map((fieldConfig, index) => (
          <OtherComponentField key={index} props={props} fieldConfig={fieldConfig} isStaff={true} />
        ))}
        <div style={{ clear: 'both' }} />
        <TextInput style={inputStyle} multiline={true} source="external_data.lyra.uuid" label="Lyra UUID" />
        <SelectInput
          choices={LYRA_SUPPORT_OPTIONS}
          source="external_data.lyra.support_status"
          label="Lyra Support Status"
        />

        <TextInput fullWidth={true} multiline={true} source="countries_include_csv" />
        <TextInput fullWidth={true} multiline={true} source="countries_exclude_csv" />
        <ComponentOrderingInfo record={props.record} />
        <TextInput source="data" style={{ width: '100%', opacity: '0.5', display: 'none' }} label="Hidden Data" />
      </SimpleForm>
    </Edit>
  )
}

export const ComponentOtherEdit = connect(mapStateToProps, mapDispatchToProps)(_ComponentOtherEdit)

const _ComponentOtherCreate = (props) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  return (
    <Create title="Create Other Component" {...props}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        defaultValue={getDefaultValuesFromDataOthers}
        redirect="/component_other_activations"
      >
        <ReferenceInput
          name="manufacturer"
          source="manufacturer"
          reference="manufacturers"
          optionValueField="url"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
          {...props}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="url"
            options={{
              style: inputStyle,
              containerStyle: inputStyle,
            }}
          />
        </ReferenceInput>
        {otherDataFields.map((fieldConfig, index) => (
          <OtherComponentField key={index} props={props} fieldConfig={fieldConfig} isStaff={isStaff} />
        ))}

        <div style={{ clear: 'both' }} />

        <TextInput fullWidth={true} multiline={true} source="countries_include_csv" {...props} />
        <TextInput fullWidth={true} multiline={true} source="countries_exclude_csv" {...props} />

        <TextInput source="data" style={{ width: '100%', opacity: '0.5', display: 'none' }} label="Hidden Data" />
      </SimpleForm>
    </Create>
  )
}

export const ComponentOtherCreate = connect(mapStateToProps, mapDispatchToProps)(_ComponentOtherCreate)
