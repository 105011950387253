import { CalcOperation, Condition, NoticeOperation } from './RuleTypes'

/**
 * Class to execute operations and evaluate conditions for Enphase BOM generation.
 */
export class OperationExecutor {
  constructor(private context: Record<string, any>) {}

  public evaluateNotice(notice: string | NoticeOperation): string | undefined {
    if (typeof notice === 'string') {
      return notice
    }

    if (notice?.type === 'notice') {
      return Object.entries(notice.fields).reduce((message, [placeholder, fieldName]) => {
        const fieldValue = this.getFieldValue(fieldName)
        return message.replace(`{{${placeholder}}}`, String(fieldValue))
      }, notice.template)
    }

    return undefined
  }

  /**
   * Checks if the value includes any of the target values.
   * @param value - The value to check.
   * @param target - The target values to check against.
   * @returns True if the value includes any of the target values, false otherwise.
   */
  private checkInclusion(value: any, target: string | string[] | number | boolean): boolean {
    const targetArray = Array.isArray(target) ? target : [target]
    const stringValue = String(value)
    return targetArray.some((t) => stringValue.includes(String(t)))
  }

  /**
   * Evaluates a condition.
   * @param condition - The condition to evaluate.
   * @returns True if the condition is met, false otherwise.
   */
  public evaluateCondition(condition: Condition): boolean {
    if (condition.logical && condition.conditions) {
      if (condition.logical === 'AND') {
        return condition.conditions.every((cond) => this.evaluateCondition(cond))
      } else if (condition.logical === 'OR') {
        return condition.conditions.some((cond) => this.evaluateCondition(cond))
      }
    }

    const fieldValue = this.getFieldValue(condition.field)

    switch (condition.operator) {
      case 'equals':
        return fieldValue === condition.value
      case 'in':
        return Array.isArray(condition.value) && condition.value.includes(fieldValue)
      case 'includes':
        return this.checkInclusion(fieldValue, condition.value)
      case 'not_includes':
        return !this.checkInclusion(fieldValue, condition.value)
      case 'matches':
        return Array.isArray(condition.value) && condition.value.some((v) => String(fieldValue).includes(String(v)))
      case 'greater_than':
        return Number(fieldValue) > Number(condition.value)
      case 'less_than':
        return Number(fieldValue) < Number(condition.value)
      default:
        return false
    }
  }

  /**
   * Executes a calculation operation.
   * @param operation - The operation to execute.
   * @param conditions - Optional conditions to check before executing the operation.
   * @returns The result of the operation.
   */
  public execute(operation: CalcOperation, conditions: Condition[] = []): number {
    if (!operation || typeof operation !== 'object') {
      return 0
    }

    if (conditions.length && !conditions.every((c) => this.evaluateCondition(c))) {
      return 0
    }

    switch (operation.type) {
      case 'cable': {
        let conditionalQuantity = 0
        if (operation.condition) {
          const meetsCondition = this.evaluateCondition(operation.condition)
          conditionalQuantity =
            meetsCondition && operation.whenTrue
              ? this.execute(operation.whenTrue as CalcOperation)
              : operation.whenFalse
              ? this.execute(operation.whenFalse as CalcOperation)
              : 0
        }

        const isThreePhase = this.context.numberOfPhases === 3
        const bestCableTypes = this.context.getBestCableTypes(isThreePhase)
        if (!Array.isArray(bestCableTypes)) return 0

        const match = bestCableTypes.find((rec) => rec.code === operation.cableCode)
        if (!match) return 0

        const extraDrops = this.context.numberOfExtraDrops

        if (this.context.countryCode === 'GB' || this.context.countryCode === 'AT') {
          const ukBuffer = 2 + 2 * extraDrops
          return match.quantity + ukBuffer + conditionalQuantity
        }

        return match.quantity + extraDrops + conditionalQuantity
      }

      case 'unsealed-cables': {
        const isThreePhase = this.context.numberOfPhases === 3
        const bestCableTypes = this.context.getBestCableTypes(isThreePhase)
        if (!Array.isArray(bestCableTypes)) return 0

        const totalConnectors = bestCableTypes.reduce((total, rec) => {
          const extraDrops = operation.extraDrops ? this.context.numberOfExtraDrops : 0
          if (this.context.countryCode === 'GB' || this.context.countryCode === 'AT') {
            const ukBuffer = 2 + 2 * extraDrops
            return rec.quantity + ukBuffer + total
          }
          return total + rec.quantity + extraDrops
        }, 0)

        return operation.subtractMicros
          ? Math.floor(Math.max(0, totalConnectors - this.context.getInverterCount()))
          : totalConnectors
      }

      case 'unsealed-cables-US': {
        const isThreePhase = this.context.numberOfPhases === 3
        const bestCableTypes = this.context.getBestCableTypes(isThreePhase)
        if (!Array.isArray(bestCableTypes)) return 0

        const totalConnectors =
          bestCableTypes.reduce((total, rec) => {
            const extraDrops = operation.extraDrops ? this.context.numberOfExtraDrops : 0
            return total + rec.quantity + extraDrops
          }, 0) + this.context.numberOfBranches

        return operation.subtractMicros
          ? Math.floor(Math.max(0, totalConnectors - this.context.getInverterCount()))
          : Math.floor(totalConnectors)
      }

      case 'unsealed-cables-BE': {
        const isThreePhase = this.context.numberOfPhases === 3
        const bestCableTypes = this.context.getBestCableTypes(isThreePhase)
        if (!Array.isArray(bestCableTypes)) return 0

        const totalConnectors =
          bestCableTypes.reduce((total, rec) => {
            const extraDrops = operation.extraDrops ? this.context.numberOfExtraDrops : 0
            return total + rec.quantity + extraDrops
          }, 0) + this.context.numberOfBranches

        return operation.subtractMicros
          ? Math.floor(Math.max(0, totalConnectors - this.context.getInverterCount()))
          : Math.floor(totalConnectors)
      }

      case 'Q-CLIP-US': {
        const numberOfPanelsPortrait = this.context.numberOfPanelsPortrait
        const numberOfPanelsLandscape = this.context.numberOfPanelsLandscape
        const numberOfBranches = this.context.numberOfBranches
        const numberOfExtraDrops = this.context.numberOfExtraDrops

        let totalClips = 0

        if (numberOfPanelsLandscape > 0) {
          totalClips += Math.ceil((numberOfExtraDrops + numberOfBranches + numberOfPanelsLandscape) * 1.5)
        }
        if (numberOfPanelsPortrait > 0) {
          totalClips += numberOfExtraDrops + numberOfBranches + numberOfPanelsPortrait
        }

        return totalClips
      }

      case 'fixed':
        return operation.value || 0

      case 'field': {
        const value = this.getFieldValue(operation.field)
        if (typeof value === 'function') {
          const result = value.call(this.context)
          return result
        }

        const result = Number(value)
        return result
      }

      case 'add':
        return operation.subOperations?.reduce((sum, op) => sum + this.execute(op), 0) || 0

      case 'subtract': {
        const ops = operation.subOperations || []
        if (!ops.length) return 0
        return ops.reduce((result, op, idx) => (idx === 0 ? this.execute(op) : result - this.execute(op)), 0)
      }

      case 'multiply':
        return operation.subOperations?.reduce((product, op) => product * this.execute(op), 1) || 0

      case 'value':
        return operation.value || 0

      case 'conditional': {
        const meetsCondition = this.evaluateCondition(operation.condition)
        if (meetsCondition) {
          return typeof operation.whenTrue === 'number' ? operation.whenTrue : this.execute(operation.whenTrue)
        }
        if (operation.whenFalse) {
          return typeof operation.whenFalse === 'number' ? operation.whenFalse : this.execute(operation.whenFalse)
        }
        return 0
      }

      case 'raw-cable': {
        return Math.max(5 * Math.ceil((this.context.getInverterCount() + 1) / 11) + (operation.adder || 0), 0)
      }

      case 'ET-CLIP-GB': {
        const portraitPanels = this.execute(operation.portraitPanels) || 0
        const landscapePanels = this.execute(operation.landscapePanels) || 0
        const totalPanels = portraitPanels + landscapePanels
        if (totalPanels === 0) return 0

        const panelMultiplier = totalPanels === 0 ? 4 : 4 + (landscapePanels / totalPanels) * 2

        const isThreePhase = this.context.numberOfPhases === 3
        const bestCableTypes = this.context.getBestCableTypes(isThreePhase)

        if (!Array.isArray(bestCableTypes)) return 0

        const totalCableLength = bestCableTypes.reduce((total, rec) => {
          const extraDrops = this.context.numberOfExtraDrops
          if (this.context.countryCode === 'GB' || this.context.countryCode === 'AT') {
            const ukBuffer = 2 + 2 * extraDrops
            return rec.quantity + ukBuffer + total
          }
          return total + rec.quantity + extraDrops
        }, 0)

        return Math.ceil(
          (5 * Math.ceil((this.context.getInverterCount() + 1) / 11) + totalCableLength) *
            (operation.multiplier || 1) *
            panelMultiplier
        )
      }

      case 'roundUp': {
        const subOperationResult = this.execute(operation.subOperation)
        return Math.ceil(subOperationResult)
      }

      default:
        console.warn('Unknown operation type found in Enpase BOM Generation:', operation)
        return 0
    }
  }

  /**
   * Gets the value of a field from the context.
   * @param field - The field to get the value of.
   * @returns The value of the field.
   */
  private getFieldValue(field: string): any {
    if (field.endsWith('()')) {
      const fnName = field.slice(0, -2)
      const result = typeof this.context[fnName] === 'function' ? this.context[fnName]() : undefined
      return result
    }
    const result = this.context[field]
    return result
  }
}
