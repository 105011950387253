import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { systemCalculationsSelectors } from 'reducer/designer/systemCalculation'
import useHardwareDetailsDynamic from '../sections/design/sideDrawer/bom/useHardwareDetailsDynamic'

// Manual = set by user. Component = component activation value.
export type CostSource = HardwareSupplierFilterKeyType | 'component' | 'manual' | 'unknown'

export type ComponentCost = {
  code: string
  source: CostSource
  price: number | undefined
  activationId?: string | number
}

/*
 * This is a convenience wrapper over the COGs data structures and functions in OsSystem.
 */
export const useComponentCost = () => {
  const hardwareDetails = useHardwareDetailsDynamic()

  const [forceUpdate, setForceUpdate] = useState(false)
  const system = window?.editor?.selectedSystem
  const calculating = useSelector(systemCalculationsSelectors.isSystemProcessing)(system?.uuid)

  useEffect(() => {
    if (calculating) {
      setForceUpdate(true)
    } else {
      setForceUpdate(false)
    }
  }, [calculating])

  const updateComponentCosts = (componentCosts: ComponentCost[]) => {
    const systemUuid = window.editor.selectedSystem?.uuid
    const system = systemUuid && window.editor.objectByUuid(systemUuid)

    if (!system) return

    componentCosts.forEach((componentCost, i) => {
      // If we know the activation ID, use it.
      const { code, source, price, activationId } = componentCost
      if (activationId) {
        system.setComponentCostOverride({ code, id: activationId }, price, source, i === componentCosts.length - 1)
        return
      }

      // Otherwise, we need to find the activation ID from the code.
      const type = hardwareDetails.find((hardwareDetail) => hardwareDetail.code === code)?.type

      if (!type) {
        console.warn('Could not find type for component ', code)
        return
      }
      const componentActivation = window.AccountHelper.getComponentActivationFromCode(code, type)

      system.setComponentCostOverride(componentActivation, price, source, i === componentCosts.length - 1)
    })
  }

  // Sets up useful defaults in the presence/absence of overrides.
  const componentCosts: ComponentCost[] = useMemo(() => {
    // @ts-ignore
    const existingCogsOverrides = window?.editor?.selectedSystem?.getCogsOverride()
    const pricing: ComponentCost[] = []

    hardwareDetails.forEach((hardwareDetail) => {
      if (hardwareDetail.quantity < 1) return
      const { code, type } = hardwareDetail
      const activationData = window.AccountHelper.getComponentActivationFromCode(code, type)
      const override = existingCogsOverrides && activationData ? existingCogsOverrides[activationData.id] : null

      const source = override ? override.source : 'component'

      const existingHardware = pricing.find((item) => item.code === hardwareDetail.code)
      if (existingHardware) {
        const existingIndex = pricing.indexOf(existingHardware)
        pricing.splice(existingIndex, 1)
      }

      pricing.push({
        code: hardwareDetail.code,
        source,
        price: override ? override.price : source === 'component' ? activationData?.cost : 0,
      })
    })

    return pricing
  }, [hardwareDetails, forceUpdate])

  return { componentCosts, updateComponentCosts }
}
