import { getPermissionFromAuthData } from 'authClient'
import { cloneDeep } from 'lodash'
import { PartnerIdType } from 'pages/auth/sso/types'
import { CLEAR_STATE, USER_LOGOUT } from 'react-admin'
import appStorage, { getOrgId } from 'storage/appStorage'
import { AUTH_STATE_EPHEMERAL_PROPS, AuthState, AuthStatus, AuthType } from 'types/auth'
import { MFAConfigType, MFADeviceType } from 'types/mfa'
import { ConnectedOrgBrief, OrgBrief } from 'types/orgs'
import { RoleType } from 'types/roles'
import { RootState } from 'types/state'
import { UPDATE_EMAIL_PREFERENCES } from '../actions/authActions'
import {
  SET_MFA_CHECK_REQUIRED,
  SET_MFA_CODES_SHOWING,
  SET_MFA_CONFIG,
  SET_MFA_CONFIG_REQUIRED,
  SET_MFA_HAS_DEVICES,
} from './auth_mfa_actions'
import rolesReducer, { rolesSelectors } from './auth_roles'
import { orgSelectors } from './orgs'
import { FujiTourType } from './tour'
export const SET_AUTH = 'SET_AUTH'
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'
export const SET_AUTH_RELOADING = 'SET_AUTH_RELOADING'
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED'
export const SET_ORG_ID = 'SET_ORG_ID'
export const REMOVE_TOUR_BANNER = 'REMOVE_TOUR_BANNER'
export const ADD_TOUR_BANNER = 'ADD_TOUR_BANNER'
export const UPDATE_TOUR_BANNER = 'UPDATE_TOUR_BANNER'

export const setOrgId = ({ orgId, reloadOrg = true }: { orgId: number; reloadOrg?: boolean }) => {
  return {
    type: SET_ORG_ID,
    payload: { orgId },
    meta: { auth: reloadOrg },
  }
}

export const setAuth = (auth: AuthState) => ({
  type: SET_AUTH,
  payload: auth,
})

export const setAuthStatus = (authStatus: AuthStatus) => ({
  type: SET_AUTH_STATUS,
  payload: authStatus,
})

export const setTermsAccepted = () => ({
  type: SET_TERMS_ACCEPTED,
  payload: true,
})

export const removeTourBanner = (userHidIt: boolean, tourType: FujiTourType = 'main') => ({
  type: 'UPDATE_TOUR_BANNER',
  payload: { [tourType]: { shouldShow: false, userHidIt } },
})

export const addTourBanner = (tourType: FujiTourType = 'main') => ({
  type: 'UPDATE_TOUR_BANNER',
  payload: { [tourType]: { shouldShow: true, userHidIt: false } },
})

const getUserTypeFromAuthData = (auth: AuthState) => {
  try {
    if (auth.is_superuser) {
      return 'superuser'
    } else if (auth.is_staff) {
      return 'staff'
    } else if (!auth.org_id) {
      return 'customer'
    } else if (auth.orgs.filter((org) => org.enable_exhibit).length > 0) {
      return 'exhibitor'
    } else {
      return 'pro'
    }
  } catch (err) {
    return null
  }
}

const initGA4OnceWhenReady = (previousState) => {
  if (previousState?.user?.id && previousState?.terms_accepted_date) {
    window.GA4?.initOnce(previousState.user.id, getUserTypeFromAuthData(previousState))
  }
}

export default function reducer(
  previousState: AuthState | undefined = undefined,
  action: { type: string; payload: any }
) {
  let type = action.type
  let payload = action.payload

  if (!previousState || Object.keys(previousState).length === 0) {
    previousState = appStorage.getAuth() as AuthState

    // Token can be updated by MFA/pw reset, so should always treat this one as the latest one.
    if (previousState) {
      previousState.token = appStorage.getToken()

      // Strip emphemeral props from storage auth payload
      // This can be removed after 2.18.0
      for (let key of AUTH_STATE_EPHEMERAL_PROPS) delete previousState[key]
    }

    if (previousState?.token) {
      // Repopulate each individual item in localStorage
      // @TODO: Only store auth in localStorage only for purposes of rehydrating
      // everywhere else use token/etc from Redux store
      // Don't overwrite if token already set (would clobber token updates from MFA)
      appStorage.setToken(previousState.token)

      // If current org_id in localStorage is permitted by auth, retain it
      // this is how we change org_id.

      if (previousState.orgs && previousState.orgs.length) {
        // We have permission to at least one org
        // If one is already selected, retain it.
        // Otherwise automatically use the first

        const orgId = appStorage.getOrgId()
        if (
          previousState.orgs.filter(function (org: OrgBrief) {
            return org.id === orgId
          }).length === 1
        ) {
          //keep current value of org_id and api_key_google
        } else {
          appStorage.setOrgId(previousState.orgs[0].id)
        }
      } else {
        appStorage.setOrg(undefined)
        appStorage.setOrgId(undefined)
      }

      //auth state was retrieved from token. Trigger a refresh which will run asynchronously.
      //If updates are necessary then they will be applied, otherwise the user will not notice any change
      if (previousState) {
        previousState.loadedFromLocalStorage = true
      }
    } else {
      previousState = {} as AuthState
    }
  }

  previousState = rolesReducer(previousState, action)

  if (type === SET_ORG_ID) {
    // Inject payload (orgId) into state.auth.org_id
    appStorage.setOrgId(payload.orgId)

    if (previousState) {
      var newState = {
        ...previousState,
        org_id: payload.orgId,
      }
      appStorage.setJSON('auth', newState)

      return newState
    } else {
      console.warn('Action:SET_ORG_ID: Unable to set org_id, state.auth not set')
      return previousState
    }
  } else if (type === USER_LOGOUT) {
    //deprecated admin-on-rest action
    return { autoLoginProject: previousState.autoLoginProject }
  } else if (type === CLEAR_STATE) {
    return { autoLoginProject: previousState.autoLoginProject }
  } else if (type === SET_AUTH_RELOADING) {
    return {
      ...previousState,
      reloading: !!payload,
    }
  } else if (type === SET_AUTH) {
    // When users log back in with access to multiple orgs,
    // Log back in with the last used org_id if possible.
    // If we have org_id stored in localStorage AND it matches an org in the new auth payload
    // use org_id from localStorage

    // Quick hack for if payload is null
    // This should be rare, but it can happen
    // e.g. when user MyEnergy secure link login fails due to being sent to a Pro account
    if (!payload) {
      payload = {}
    }

    const auth = payload as AuthType

    if (!previousState) {
      previousState = {} as AuthState
    }

    var previousOrgId = getOrgId()

    if (previousOrgId && auth.orgs && auth.orgs.filter((o) => o.id === previousOrgId).length > 0) {
      // Keep existing org_id in localStorage, it matches an org in the new auth payload
      auth.org_id = previousOrgId
    } else {
      appStorage.setOrgId(auth.org_id)
    }

    const storedAuth = { ...auth }
    // Don't store emphemeral props, they can cause issues
    for (let key of AUTH_STATE_EPHEMERAL_PROPS) delete storedAuth[key]
    appStorage.setAuth(storedAuth)

    // If user is logging in without a nearmap-enabled org, ensure the nearmap_token is cleared
    if (appStorage.getString('nearmap_token') && auth.user?.login_authority !== 'nearmap') {
      console.log('Clearing nearmap_token when logging in without NMOS')
      appStorage.setString('nearmap_token')
    }

    let newState = {
      ...previousState,
      ...auth,
      reloading: false,
      loadedFromLocalStorage: false,
    }

    // If user has accepted T&Cs already then we may now be able to enable GA4
    initGA4OnceWhenReady(newState)

    return newState
  } else if (type === SET_AUTH_STATUS) {
    return {
      ...previousState,
      status: payload,
    }
  } else if (type === 'AUTO_LOGIN') {
    return (previousState = payload)
  } else if (type === 'SET_TERMS_ACCEPTED') {
    const now = new Date().toISOString()
    // Update localStorage (ready for a future auto-login)
    var localStorageAuthUpdated = (appStorage.getJSON('auth') || {}) as AuthType
    localStorageAuthUpdated.terms_accepted_date = now
    appStorage.setJSON('auth', localStorageAuthUpdated)

    //Must return new object, not mutate existing state
    previousState = cloneDeep(previousState)
    previousState.terms_accepted_date = now

    // If the user first arrived before terms were accepted, then we may now be able to enable GA4
    initGA4OnceWhenReady(previousState)

    return previousState
  } else if (type === 'SET_PROPOSAL_DATA') {
    // If customer reloads a proposal their state is loaded from localStorage and other auth actions are not fired
    // so handle this case specially by listening for SET_PROPOSAL_DATA which will help to init GA4 for this scenario.
    initGA4OnceWhenReady(previousState)

    return previousState
  } else if (type === UPDATE_EMAIL_PREFERENCES) {
    if (previousState) {
      const prevUser: any = previousState.user
      prevUser.email_marketing_opt_in = payload.email_marketing_opt_in
      return {
        ...previousState,
        prevUser,
      }
    }
  } else if (type === UPDATE_TOUR_BANNER) {
    if (!previousState || !previousState.roles) return { ...previousState }
    previousState.user.show_tour_banner = { ...(previousState.user.show_tour_banner || {}), ...payload }
    appStorage.setJSON('show_tour_banner', previousState?.user?.show_tour_banner)
    return previousState
  } else if (type === SET_MFA_CONFIG) {
    return {
      ...previousState,
      mfaConfig: payload,
    }
  } else if (type === SET_MFA_CHECK_REQUIRED) {
    return {
      ...previousState,
      mfaCheckRequired: payload,
    }
  } else if (type === SET_MFA_CONFIG_REQUIRED) {
    return {
      ...previousState,
      mfaConfigRequired: payload,
    }
  } else if (type === SET_MFA_HAS_DEVICES) {
    return {
      ...previousState,
      mfaHasSms: payload.sms,
      mfaHasTotp: payload.totp,
      mfaHasRecovery: payload.recovery,
    }
  } else if (type === SET_MFA_CODES_SHOWING) {
    return {
      ...previousState,
      mfaCodesShowing: payload.value,
    }
  }

  return previousState
}

// selectors
export const authSelectors = {
  getOrgId: (state: RootState) => state.auth?.org_id || undefined,

  getBriefOrgs: (state: RootState) => {
    return state.auth?.orgs
  },
  getUserRoles: (state: RootState) => {
    return state.auth?.roles
  },
  //TODO: refactor these away (go straight to Org selectors)
  getCurrentOrg: (state: RootState) => {
    return orgSelectors.getOrg(state)
  },
  getCurrentOrgCountry: (state: RootState) => {
    return orgSelectors.getOrgIso2(state)
  },
  getDocusignAccountConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org && current_org.docusign_config ? current_org.docusign_config.docusign_account_connected : false
  },
  getIsSolarAppAccountConnected: (state: RootState): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org && current_org.solarapp_config ? current_org.solarapp_config.solarapp_account_connected : false
  },
  getLoanpalConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_loanpal : false
  },
  getSungageConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_sungage : false
  },
  getDividendConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_dividend : false
  },
  getCommonBondConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_commonbond : false
  },
  getPhoenixConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_phoenix : false
  },
  getSelinaConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_selina : false
  },
  getCheckoutFinanceConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_checkout_finance : false
  },
  getIgnoreLoanpalChannels: (state: RootState) => {
    return authSelectors.getCurrentOrg(state)?.ignore_loanpal_channels
  },
  getAuthReloading: (state: RootState) => {
    return !!state.auth?.reloading
  },
  getLoanpalChannels: (state: { auth: { org_id: any; roles: any[] } }) => {
    let role: RoleType | undefined
    if (state.auth && state.auth.org_id) {
      try {
        role = state.auth.roles.filter((role: { org_id: any }) => role.org_id === state.auth.org_id)[0]
      } catch (e) {
        console.log('error getting loanpal channels', e)
        return []
      }
    } else return []
    if (role) {
      const channelsString = role.loanpal_channels
      let channelsArr: string[] = []
      if (channelsString) {
        try {
          channelsArr = channelsString.split(',')
        } catch (e) {
          return []
        }
      }
      return channelsArr
    }
  },
  //TODO: Should be moved to ducks/auth_roles.ts
  getCurrentRole: (state: RootState): RoleType | undefined => {
    return rolesSelectors.getCurrentRole(state)
  },
  getCurrentUser: (state: RootState) => {
    return state?.auth?.user
  },
  getCurrentUserId: (state: RootState) => {
    return authSelectors.getCurrentUser(state)?.id
  },
  getMosaicConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    if (current_org?.enable_mosaic) return true
    else return false
  },
  getIsAuthenticatedPro: (state: RootState) => {
    const permission = getPermissionFromAuthData(state?.auth)
    return permission.includes('is_pro')
  },
  getIsStaff: (state: RootState) => !!state?.auth?.is_staff,
  getIsSuperUser: (state: RootState): boolean => !!state?.auth?.is_superuser,
  getStaffAccessCountries: (state: RootState): string | undefined => state?.auth?.staff_access_countries_csv,
  getIsAdmin: (state: RootState): boolean => !!rolesSelectors.getCurrentRole(state)?.is_admin,
  getIsAnonymous: (state: RootState) => !!state?.auth?.user?.is_anonymous_share_user,
  getBrighteConnected: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_brighte : false
  },
  getEnableEagleView: (state: RootState) => {
    const current_org = authSelectors.getCurrentOrg(state)
    return !!current_org?.enable_eagleview
  },
  getShowTourBanner: (state: RootState, tourType: FujiTourType) => {
    return state?.auth?.user?.show_tour_banner?.[tourType]
  },
  getShowWallet: (state: RootState): boolean => {
    const orgCountry = authSelectors.getCurrentOrgCountry(state)
    if (!orgCountry) return false
    else return ['NZ', 'AU', 'US', 'GB'].includes(orgCountry)
  },
  getIsLoggedIn: (state: RootState): boolean => {
    if (!state || !state.auth) return false
    return !!state.auth?.token
  },

  getIsMfaEnabledOnOrg: (state: RootState): boolean => {
    return !!authSelectors.getCurrentOrg(state)?.require_mfa
  },
  getIsMfaEnabledOnRole: (state: RootState): boolean => {
    return !!authSelectors.getConfirmedMfaDevice(state)?.length
  },
  getIsSmsMfaAllowedOnOrg: (state: RootState): boolean => {
    // We need to clarify what is the purpose of state.auth.orgs[] compared to orgs.org
    // When a user is force to enroll in MFA due to their org membership, they are forced to choose an MFA
    // method before they know whether they belong to any orgs who permit SMS MFA because state.orgs.org
    // is not yet loaded. However, we can detect this in auth.orgs[n].allow_sms_mfa so we use this for now
    // but acknowledge state.auth.orgs might be deprecated and could cause complications in the future.
    if (!!authSelectors.getCurrentOrg(state)?.allow_sms_mfa) {
      return true
    } else if (state?.auth?.orgs?.length && state?.auth?.orgs.find((org) => !!org.allow_sms_mfa)) {
      return true
    } else {
      return false
    }
  },
  getMfaConfig: (state: RootState): MFAConfigType | undefined => {
    return state.auth?.mfaConfig
  },
  getConfirmedMfaDevice: (state: RootState): MFADeviceType[] | undefined => {
    return authSelectors.getActiveMfaDevice(state)?.filter((d) => d.status === 'confirmed')
  },
  getActiveMfaDevice: (state: RootState): MFADeviceType[] | undefined => {
    return state.auth?.mfaConfig?.active
  },
  getMfaCheckRequired: (state: RootState): boolean => {
    return !!state?.auth?.mfaCheckRequired
  },
  getMfaConfigRequired: (state: RootState): boolean => {
    return !!state?.auth?.mfaConfigRequired
  },
  getMfaCodesShowing: (state: RootState): boolean => {
    return !!state?.auth?.mfaCodesShowing
  },
  getMfaHasSms: (state: RootState): boolean => {
    return !!state?.auth?.mfaHasSms
  },
  getMfaHasTotp: (state: RootState): boolean => {
    return !!state?.auth?.mfaHasTotp
  },
  getMfaHasRecovery: (state: RootState): boolean => {
    return !!state?.auth?.mfaHasRecovery
  },
  isAnonymousShareUser: (state: RootState): boolean => {
    return !!state?.auth?.user?.is_anonymous_share_user
  },
  getSunlightConnected: (state: RootState): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org ? !!current_org.enable_sunlight : false
  },
  getPlentiConnected: (state: RootState): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    return !!current_org?.enable_plenti
  },
  getEnergyEaseConnected: (state: RootState): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    return !!current_org?.enable_energy_ease
  },
  getHasUsFinanceIntegration: (state: RootState): boolean => {
    return (
      authSelectors.getSungageConnected(state) ||
      authSelectors.getMosaicConnected(state) ||
      authSelectors.getDividendConnected(state) ||
      authSelectors.getLoanpalConnected(state) ||
      authSelectors.getSunlightConnected(state)
    )
  },
  getHasAUFinanceIntegration: (state: RootState): boolean => {
    return (
      authSelectors.getBrighteConnected(state) ||
      authSelectors.getPlentiConnected(state) ||
      authSelectors.getEnergyEaseConnected(state)
    )
  },
  getHasAcceptedTerms: (state: RootState): boolean => {
    //TODO: remove terms_accepted after 2.22.0

    // Note we have slightly modified the meaning of "HasAcceptedTerms" which is true if:
    //
    // - the user has accepted the terms as an individual
    // - the user belongs to an org who has a commercial agreement that covers the users, where by the user has
    //   effectively accpted the terms by virtue of being a member of that org.
    //
    // We still leave the AcceptedTermsDate empty if the user has not accepted the terms as an individual so we can still
    // determine if/when the user has accepted the terms as an individual, but this allows us to use the same check
    // to avoid prompting the user to accept the terms when they are already covered by an org agreement.
    return (
      !!state?.auth?.terms_accepted_date || !!state?.auth?.['terms_accepted'] || authSelectors.getBypassUserTerms(state)
    )
  },
  getBypassUserTerms: (state: RootState): boolean => {
    // If any org has org.bypass_user_terms then we can bypass user terms
    return !!state.auth?.orgs?.find((org) => !!org.bypass_user_terms)
  },
  getAcceptedTermsDate: (state: RootState): string | undefined => {
    return state?.auth?.terms_accepted_date
  },
  getPvSellKey: (state: RootState): string | undefined => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org?.api_key_pvsell
  },
  getActiveConnectedOrgs: (state: RootState): ConnectedOrgBrief[] => {
    const current_org = authSelectors.getCurrentOrg(state)
    return current_org?.connected_orgs?.filter((x) => x.is_active === true) || []
  },
  getIsGenericIntegrationAvailable: (state: RootState, integration_name: string): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    // @ts-ignore
    return current_org ? !!current_org[`enable_${integration_name}`] : false
  },
  getIsPartnerUserPaired: (state: RootState, partner_name: PartnerIdType): boolean => {
    return !!state?.auth?.user?.paired_partners?.[partner_name]
  },
  getIsGenericIntegrationActive: (state: RootState, integration_name: string): boolean => {
    const current_org = authSelectors.getCurrentOrg(state)
    if (!current_org) return false
    // @ts-ignore
    return current_org[`enable_${integration_name}`] === 'active'
  },
  getAuthStatus: (state: RootState): AuthStatus | undefined => {
    return state?.auth?.status
  },
  getAutoLoginProject: (state: RootState): number | undefined => {
    return state?.auth?.autoLoginProject
  },
  getApiKeyGoogle: (state: RootState): any => {
    return state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  },
}
