import { permissionsSelectors } from 'ducks/permissions'
import { useShowTabs, useViewShowAsDict } from 'ducks/viewMode'
import { usePvfIntegrationEnabled } from 'hooks/usePvfIntegrationEnabled'
import {
  BatteryIcon,
  IncentivesIcon,
  InverterIcon,
  MountingIcon,
  OtherComponentTypeIcon,
  PanelIcon,
  PaymentOptionIcon,
  PricingIcon,
  ScaffoldOutlineIcon,
  SummaryIcon
} from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import type { DesignTabs } from 'reducer/designer/view'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import { TAB_AND_COMPLETE_CHECK } from './DesignCompleteCheck'
import SummaryTabButton from './SummaryTabButton'
import TabButton from './TabButton'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    gap: '8px',
    overflow: 'hidden',
    padding: '12px 8px',
  },
  divider: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 1px 1px',
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 3,
    gap: '8px',
    overflow: 'auto',
  },
  stickyContainer: {
    display: 'flex',
    flexBasis: 1,
    flexDirection: 'column',
    flexShrink: 0,
    gap: '8px',
  },
}))

const getSystemDesignCompleteCheck = (system): { [key in DesignTabs]?: boolean } => {
  const checkResult = Object.keys(TAB_AND_COMPLETE_CHECK).reduce((result, key) => {
    result[key] = !!system && TAB_AND_COMPLETE_CHECK[key](system)
    return result
  }, {})
  return checkResult
}

type PanelTabsProps = {
  selectedTab: string
  onChange(newValue, paymentsTabEnabled): void
}

const PanelTabsV2 = ({ selectedTab, onChange }: PanelTabsProps) => {
  const [completeCheckResult, setCompleteCheckResult] = useState<{ [key in DesignTabs]?: boolean }>(
    getSystemDesignCompleteCheck(window.editor?.selectedSystem)
  )
  const paymentsTabEnabled = useFeatureFlag('payments_tab', 'on')
  const showMountingTab = useFeatureFlag('studio_tabs_mounting', 'on')
  const enablePvf = usePvfIntegrationEnabled()
  const translate = useTranslate()
  const accessRightsSetting = useSelector(permissionsSelectors.getProjectPermissionsSetting)
  const classes = useStyles()
  const handleClick = (newValue) => {
    onChange(newValue, paymentsTabEnabled)
  }

  const uiShow = useViewShowAsDict([
    'studio.tabs.mounting',
    'studio.tabs.summary',
    'studio.tabs.panels',
    'studio.tabs.panels.select_panel_type',
    'studio.tabs.panels.buildable_panels',
    'studio.tabs.panels.select_dc_optimizer',
    'studio.tabs.inverters',
    'studio.tabs.batteries',
    'studio.tabs.others',
    'studio.tabs.payment_options',
    'studio.tabs.scaffolding',
    'studio.tabs.pricing',
    'studio.tabs.incentives'
  ])
  const showTabs = useShowTabs()

  useStudioSignalsLazy(
    (object) => {
      if (!object) return
      if (!window.editor?.selectedSystem) return
      if (
        object.type !== 'OsSystem' &&
        object.type !== 'OsInverter' &&
        object.type !== 'OsBattery' &&
        object.type !== 'OsOther' &&
        object.type !== 'OsModuleGrid'
      )
        return

      setCompleteCheckResult(getSystemDesignCompleteCheck(window.editor.selectedSystem))
    },
    ['objectAdded', 'objectRemoved', 'objectChanged', 'sceneLoaded'],
    undefined,
    { debounce: 1, maxWait: 2 }
  )

  // If only zero or 1 tabs then there is no need to show the tab-selector, so hide it.
  if (showTabs.length <= 1) {
    return null

  }
  return (
    <div id={'panel-tabs-container'} className={classes.container}>
      <div className={classes.scrollableContainer}>
        {uiShow['studio.tabs.panels'] && <TabButton
          showTick={completeCheckResult['panels']}
          selected={selectedTab === 'panels'}
          tooltipMessage={translate('Panels')}
          onClick={() => handleClick('panels')}
          errorCategories={['module']}
          Icon={PanelIcon}
        />}
        {uiShow['studio.tabs.mounting'] && showMountingTab && (
          <TabButton
            showTick={completeCheckResult['mounting']}
            tooltipMessage={translate('Mounting')}
            selected={selectedTab === 'mounting'}
            onClick={() => handleClick('mounting')}
            errorCategories={['mounting']}
            Icon={MountingIcon}
          />
        )}
        {uiShow['studio.tabs.inverters'] && <TabButton
          showTick={completeCheckResult['inverters']}
          tooltipMessage={translate('Inverters')}
          selected={selectedTab === 'inverters'}
          onClick={() => handleClick('inverters')}
          errorCategories={['inverter']}
          Icon={InverterIcon}
        />}
        {uiShow['studio.tabs.batteries'] && <TabButton
          showTick={completeCheckResult['batteries']}
          selected={selectedTab === 'batteries'}
          onClick={() => handleClick('batteries')}
          tooltipMessage={translate('Batteries')}
          errorCategories={['battery']}
          Icon={BatteryIcon}
        />}
        {uiShow['studio.tabs.others'] && <TabButton
          showTick={completeCheckResult['others']}
          selected={selectedTab === 'others'}
          tooltipMessage={translate('Others')}
          onClick={() => handleClick('others')}
          errorCategories={!showMountingTab ? ['other', 'mounting'] : ['other']}
          Icon={OtherComponentTypeIcon}
        />}

        {
          uiShow['studio.tabs.payment_options'] && !paymentsTabEnabled && (
            <TabButton
              showTick={completeCheckResult['payment options']}
              onClick={() => handleClick('payment options')}
              tooltipMessage={translate('Payment Options')}
              selected={selectedTab === 'payment options'}
              disabled={!accessRightsSetting?.design_payment_options?.view}
              errorCategories={['payment_option']}
              id="system-panel-payment-option-tab"
              Icon={PaymentOptionIcon}
            />
          )}
      </div>
      <div className={classes.divider} />
      <div className={classes.stickyContainer}>
        {
          uiShow['studio.tabs.scaffolding'] && enablePvf && (
            <TabButton
              showTick={completeCheckResult['scaffolding']}
              selected={selectedTab === 'scaffolding'}
              onClick={() => handleClick('scaffolding')}
              tooltipMessage={translate('Scaffolding')}
              errorCategories={['other']}
              Icon={ScaffoldOutlineIcon}
            />
          )}
        {uiShow['studio.tabs.pricing'] && <TabButton
          selected={selectedTab === 'pricing'}
          onClick={() => handleClick('pricing')}
          tooltipMessage={translate('Pricing')}
          disabled={!accessRightsSetting?.design_pricing?.view}
          errorCategories={['price']}
          Icon={PricingIcon}
          id="system-panel-pricing-tab"
        />}
        {uiShow['studio.tabs.incentives'] && <TabButton
          showTick={completeCheckResult['incentives']}
          selected={selectedTab === 'incentives'}
          onClick={() => handleClick('incentives')}
          tooltipMessage={translate('Incentives')}
          disabled={!accessRightsSetting?.design_incentives?.view}
          errorCategories={['incentive']}
          Icon={IncentivesIcon}
        />}
        {uiShow['studio.tabs.summary'] && <SummaryTabButton
          showTick={false}
          selected={selectedTab === 'summary'}
          onClick={() => handleClick('summary')}
          tooltipMessage={translate('Summary')}
          errorCategories={['system']}
          Icon={SummaryIcon}
        />}
      </div>
    </div>
  )
}

export default PanelTabsV2
