import { Grid, Theme, useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AccountingIntegration } from '../configuration/accounting/types'
import {
  getAccountingIntegrations,
  getIsLedgerSelectionComplete,
  useAccountingIntegrationsAreVisible,
} from '../configuration/accounting/utils'

type PropTypes = {}

interface StyledProps {
  isMobile?: boolean
}
const StyledNavLink = styled(NavLink)<StyledProps>(({ theme }) => ({
  textDecoration: 'none',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  padding: '14px',
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
}))

const ConfigureLinksWrapper = styled('div')<StyledProps>(({ isMobile }) => ({
  display: 'flex',
  marginTop: '26px',
  gap: '1rem',
  marginLeft: isMobile ? '1rem' : '3.5rem',
  overflow: isMobile ? 'scroll' : 'unset',
}))

const Badge = styled('div')({
  height: '8px',
  width: '10px',
  marginLeft: '5px',
  borderRadius: '8px',
  backgroundColor: 'rgba(193, 42, 46, 0.95)',
})

const CashFlowConfigurationNav: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const roleId = useSelector(authSelectors?.getCurrentRole)?.id
  const hasApplied = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)
  const isConditionallyApproved = useSelector(orgSelectors.getCashFlowOnboardingStatus) === 'conditionally approved'
  const accountingVisible = useAccountingIntegrationsAreVisible()

  useEffect(() => {
    getAccountingIntegrations(orgId, roleId, dispatch, notify)
  }, [])

  const accInt = useSelector(orgSelectors.getAccountingIntegrations) || []
  const [stableAccInt, setStableAccInt] = useState<AccountingIntegration[]>([])
  const enabledIntegration = useMemo(() => {
    if (!stableAccInt.length) return null
    return stableAccInt.find((acc) => acc.enabled) || null
  }, [stableAccInt])

  useEffect(() => {
    if (accInt.length > 0) {
      setStableAccInt(accInt)
    }
  }, [accInt])

  const hasIncompleteGL = useMemo(() => {
    if (!accInt?.length) return false
    return !getIsLedgerSelectionComplete(enabledIntegration)
  }, [enabledIntegration, accInt])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <ConfigureLinksWrapper isMobile={isMobile}>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/milestones"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Payment Milestones')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/financed_deposits"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Financed Project Deposits')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/communications"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Customer Communications')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/notifications"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Internal Notifications')}
          </StyledNavLink>
          {!hasApplied && (
            <StyledNavLink
              isMobile={isMobile}
              to="/cashflow/configure/registration"
              activeStyle={{
                fontWeight: 'bold',
                background: '#F1F1F1',
                borderRadius: 10,
              }}
            >
              {translate('Registration')}
            </StyledNavLink>
          )}
          {accountingVisible && (
            <StyledNavLink
              isMobile={isMobile}
              to="/cashflow/configure/accounting"
              activeStyle={{
                fontWeight: 'bold',
                background: '#F1F1F1',
                borderRadius: 10,
              }}
            >
              {translate('Accounting')}
              {hasIncompleteGL && <Badge />}
            </StyledNavLink>
          )}
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/documents"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Documents')}
            {isConditionallyApproved && <Badge />}
          </StyledNavLink>
        </ConfigureLinksWrapper>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  )
}

export default CashFlowConfigurationNav
