import { ConnectedRouter } from 'connected-react-router'
import { createHashHistory, History } from 'history'
import { ComponentType, FunctionComponent, useContext } from 'react'
import { Provider, ReactReduxContext } from 'react-redux'

import { AuthContext, convertLegacyAuthProvider } from '../auth'
import { convertLegacyDataProvider, DataProviderContext } from '../dataProvider'
import { DefaultErrorHandlingProvider, ErrorHandlerOverrides } from '../errors'
import TranslationProvider from '../i18n/TranslationProvider'
import {
  AdminChildren,
  AuthProvider,
  CustomRoutes,
  DashboardComponent,
  DataProvider,
  I18nProvider,
  InitialState,
  LegacyAuthProvider,
  LegacyDataProvider,
} from '../types'
import createAdminStore from './createAdminStore'

export type ChildrenFunction = () => ComponentType[]

export interface AdminContextProps {
  authProvider: AuthProvider | LegacyAuthProvider
  children?: AdminChildren
  customSagas?: any[]
  customReducers?: object
  customRoutes?: CustomRoutes
  dashboard?: DashboardComponent
  dataProvider: DataProvider | LegacyDataProvider
  history?: History
  i18nProvider?: I18nProvider
  initialState?: InitialState
  theme?: object
  errorHandlingOverrides?: ErrorHandlerOverrides
}

const CoreAdminContext: FunctionComponent<AdminContextProps> = ({
  authProvider,
  dataProvider,
  i18nProvider,
  children,
  history,
  customReducers,
  customSagas,
  initialState,
  errorHandlingOverrides,
}) => {
  const reduxIsAlreadyInitialized = !!useContext(ReactReduxContext)

  if (!dataProvider) {
    throw new Error(`Missing dataProvider prop.
React-admin requires a valid dataProvider function to work.`)
  }

  const finalAuthProvider: AuthProvider =
    authProvider instanceof Function ? convertLegacyAuthProvider(authProvider) : authProvider

  const finalDataProvider = dataProvider instanceof Function ? convertLegacyDataProvider(dataProvider) : dataProvider

  const finalHistory = history || createHashHistory()

  const renderCore = () => {
    return (
      <DefaultErrorHandlingProvider errorHandlingOverrides={errorHandlingOverrides}>
        <AuthContext.Provider value={finalAuthProvider}>
          <DataProviderContext.Provider value={finalDataProvider}>
            <TranslationProvider i18nProvider={i18nProvider}>
              {typeof window !== 'undefined' ? (
                <ConnectedRouter history={finalHistory}>{children}</ConnectedRouter>
              ) : (
                children
              )}
            </TranslationProvider>
          </DataProviderContext.Provider>
        </AuthContext.Provider>
      </DefaultErrorHandlingProvider>
    )
  }
  if (reduxIsAlreadyInitialized) {
    if (!history) {
      throw new Error(`Missing history prop.
When integrating react-admin inside an existing redux Provider, you must provide the same 'history' prop to the <Admin> as the one used to bootstrap your routerMiddleware.
React-admin uses this history for its own ConnectedRouter.`)
    }
    return renderCore()
  } else {
    // console.log(
    //   createAdminStore({
    //     authProvider: finalAuthProvider,
    //     customReducers,
    //     customSagas,
    //     dataProvider: finalDataProvider,
    //     initialState,
    //     history: finalHistory,
    //   }).getState()
    // )

    return (
      <Provider
        store={createAdminStore({
          authProvider: finalAuthProvider,
          customReducers,
          customSagas,
          dataProvider: finalDataProvider,
          initialState,
          history: finalHistory,
        })}
      >
        {renderCore()}
      </Provider>
    )
  }
}

export default CoreAdminContext
