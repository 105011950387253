import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import History from '@material-ui/icons/History'
import ErrorIcon from '@material-ui/icons/Report'
import classnames from 'classnames'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { useTranslate } from 'ra-core'
import Title, { TitlePropType } from './Title'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '2em',
      height: '2em',
      marginRight: '0.5em',
    },
    panel: {
      marginTop: '1em',
    },
    panelDetails: {
      whiteSpace: 'pre-wrap',
    },
    toolbar: {
      marginTop: '2em',
    },
  }),
  { name: 'RaError' }
)

function goBack() {
  console.warn('Navigating back')
  window.history.go(-1)
}

const Error = (props) => {
  const { error, errorInfo, classes: classesOverride, className, title, ...rest } = props
  const classes = useStyles(props)
  const translate = useTranslate()
  return (
    <Fragment>
      <Title defaultTitle={title} />
      <div className={classnames(classes.container, className)} {...rest}>
        <h1 className={classes.title} role="alert">
          <ErrorIcon className={classes.icon} />
          {translate('ra.page.error')}
        </h1>
        <div>{translate('ra.message.error')}</div>
        {process.env.NODE_ENV !== 'production' && (
          <Accordion className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('ra.message.details')}</AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>
              <div>
                <h2>{translate(error.toString())}</h2>
                {errorInfo.componentStack}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <div className={classes.toolbar}>
          <Button variant="contained" icon={<History />} onClick={goBack}>
            <span>{translate('ra.action.back')}</span>
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

Error.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.object.isRequired,
  errorInfo: PropTypes.object,
  title: TitlePropType,
}

export default Error
