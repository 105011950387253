import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const PanelIconSvg: FC<IconProps> = (props) => {
  const { color, fill } = props
  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.25 23.916L19.25 2.91602L18.0833 2.91602L18.0833 23.916L19.25 23.916Z" fill={fill} />
      <path
        d="M5.2513 18.0827L14.5846 18.0827L23.918 18.0827L23.918 16.916L14.5846 16.916L5.2513 16.916L5.2513 18.0827Z"
        fill={fill}
      />
      <path d="M5.2513 11.0827L23.918 11.0827L23.918 9.91602L5.2513 9.91601L5.2513 11.0827Z" fill={fill} />
      <path d="M14.582 23.916L14.582 2.91602L13.4154 2.91602L13.4154 23.916L14.582 23.916Z" fill={fill} />
      <path d="M9.91797 23.916L9.91797 2.91602L8.7513 2.91602L8.7513 23.916L9.91797 23.916Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.918 5.24935C23.918 3.96068 22.8733 2.91602 21.5846 2.91602L6.41797 2.91601C5.12927 2.91601 4.08464 3.96068 4.08464 5.24935L4.08464 22.7493C4.08464 24.038 5.12927 25.0827 6.41797 25.0827L21.5846 25.0827C22.8733 25.0827 23.918 24.038 23.918 22.7493L23.918 5.24935ZM20.418 23.916C21.7066 23.916 22.7513 22.8714 22.7513 21.5827L22.7513 6.41602C22.7513 5.12735 21.7066 4.08268 20.418 4.08268L7.58464 4.08268C6.29594 4.08268 5.2513 5.12735 5.2513 6.41602L5.2513 21.5827C5.2513 22.8714 6.29594 23.916 7.58464 23.916L20.418 23.916Z"
        fill={fill}
      />
    </svg>
  )
}

export const PanelIcon = createStandardIcon(PanelIconSvg)
