export const formatCurrencyWithSymbol = (value, currencySymbol, locale?, places?) => {
  if (currencySymbol === '€') {
    if (['en', 'en-us'].includes(locale)) {
      // Beware language us lowercase like en-us not en-US
      return '€' + formatCurrencyNumber(value, places, locale)
    } else {
      return formatCurrencyNumber(value, places, locale) + ' €'
    }
  } else {
    return currencySymbol + formatCurrencyNumber(value, places, locale)
  }
}

export const convertLocaleName = (locale) => {
  if (!locale || !locale.includes) {
    return locale
  } else if (!locale.includes('_')) {
    return locale
  } else {
    const parts = locale.split('_')
    return `${parts[0]}-${parts[1]}`
  }
}

export const formatCurrencyNumber = (value, places, locale?: string) => {
  if (!places && places !== 0) {
    places = 2
  }
  locale = convertLocaleName(locale)

  return new Intl.NumberFormat(locale, { minimumFractionDigits: places, maximumFractionDigits: places }).format(
    value ? value : 0
  )
}