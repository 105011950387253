// @ts-nocheck
import { makeStyles, Tooltip } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import Downshift, { DownshiftProps } from 'downshift'
import get from 'lodash/get'
import { IconButton, TextField } from 'opensolar-ui'
import { FieldTitle, InputProps, useDebouncedCallback, useInput, useSuggestions, useTranslate, warning } from 'ra-core'
import React, { FunctionComponent, isValidElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutocompleteSuggestionItem from './AutocompleteSuggestionItem'
import AutocompleteSuggestionList from './AutocompleteSuggestionList'
import InputHelperText from './InputHelperText'

interface Options {
  suggestionsContainerProps?: any
  labelProps?: any
  containerStyle?: any
  suggestionItemStyle?: any
  freeSolo?: boolean
}

/**
 * An Input component for an autocomplete field, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <AutocompleteInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <AutocompleteInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <AutocompleteInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * Note that you must also specify the `matchSuggestion` prop
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const matchSuggestion = (filterValue, choice) => choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />} matchSuggestion={matchSuggestion} />
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <AutocompleteInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 *
 * @example
 * <AutocompleteInput source="author_id" options={{ color: 'secondary', InputLabelProps: { shrink: true } }} />
 */
const AutocompleteInput: FunctionComponent<InputProps<TextFieldProps & Options> & DownshiftProps<any>> = (props) => {
  const {
    allowEmpty,
    className,
    classes: classesOverride,
    choices = [],
    emptyText,
    emptyValue,
    format,
    fullWidth,
    autocompleteInputRef,
    helperText,
    id: idOverride,
    input: inputOverride,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    margin = 'dense',
    matchSuggestion,
    meta: metaOverride,
    onInputChange: customOnInputChange,
    onBlur,
    onChange,
    onFocus,
    options: { suggestionsContainerProps, labelProps, InputProps, placeholder, ...options } = {
      suggestionsContainerProps: undefined,
      labelProps: undefined,
      InputProps: undefined,
      placeholder: undefined,
      containerStyle: {},
    },
    optionText = 'name',
    inputText,
    optionValue = 'id',
    parse,
    resource,
    setFilter,
    shouldRenderSuggestions: shouldRenderSuggestionsOverride,
    source,
    suggestionLimit,
    translateChoice = true,
    validate,
    customItem,
    variant = 'standard',
    freeSolo = false,
    ...rest
  } = props
  if (isValidElement(optionText) && !inputText) {
    throw new Error(`If the optionText prop is a React element, you must also specify the inputText prop:
        <AutocompleteInput
            inputText={(record) => record.title}
        />`)
  }

  warning(
    isValidElement(optionText) && !matchSuggestion,
    `If the optionText prop is a React element, you must also specify the matchSuggestion prop:
<AutocompleteInput
    matchSuggestion={(filterValue, suggestion) => true}
/>
        `
  )
  const translate = useTranslate()
  const classes = useStyles(props)
  let inputEl = useRef<HTMLInputElement>()
  let anchorEl = useRef<any>()
  const {
    id,
    input,
    isRequired,
    meta: { touched, error, submitError },
  } = useInput({
    format,
    id: idOverride,
    input: inputOverride,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  })
  const [filterValue, setFilterValue] = useState('')
  const [openDropDown, setDropDownOpen] = useState(false)
  const [isFilterValueMatched, setIsFilterValueMatched] = useState(true)

  const errorMessage = error || submitError

  const getSuggestionFromValue = useCallback((value) => choices.find((choice) => get(choice, optionValue) === value), [
    choices,
    optionValue,
  ])

  const selectedItem = useMemo(() => getSuggestionFromValue(input.value) || null, [input.value, getSuggestionFromValue])

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    allowEmpty,
    choices,
    emptyText,
    emptyValue,
    limitChoicesToValue,
    matchSuggestion,
    optionText,
    optionValue,
    selectedItem,
    suggestionLimit,
    translateChoice,
    allowDuplicates: rest.hasOwnProperty('allowDuplicates') ? rest.allowDuplicates : false,
  })

  const handleSetFilter = useDebouncedCallback((value) => {
    setFilter(value)
  }, 400)

  const handleFilterChange = useCallback(
    (eventOrValue: React.ChangeEvent<{ value: string }> | string) => {
      const event = eventOrValue as React.ChangeEvent<{ value: string }>
      const value = event.target ? event.target.value : (eventOrValue as string)

      if (setFilter) {
        handleSetFilter(value)
      }
    },
    [setFilter]
  )

  const getFilterTextFromItem = (item): string => {
    if (inputText) {
      return inputText(getChoiceText(item).props.record)
    } else {
      return getChoiceText(item)
    }
  }

  //update filter value
  const matchFilterValue = () => {
    const newFilterValue = getFilterTextFromItem(selectedItem)
    if (newFilterValue !== filterValue) {
      setFilterValue(newFilterValue || '')
    }
    setIsFilterValueMatched(true)
  }

  //update filter value after input value has changed
  useEffect(() => {
    setIsFilterValueMatched(false)
  }, [input.value])

  useEffect(() => {
    if (!isFilterValueMatched || selectedItem?.props?.choices?.length) {
      // It's common that selectedItem will be null initially, as the value won't show up in the first page of results
      if (selectedItem) {
        matchFilterValue()
      } else if (
        freeSolo && // I believe this could be useful in non-freeSolo mode as well, but won't enable to now to be safe
        optionValue === optionText
      ) {
        // If we're using the same field to display and store, then we can be sure that the input value can be used as the filter value
        setFilterValue(input.value)
        setIsFilterValueMatched(true)
      }
    }
  }, [choices])

  const handleDownshiftChange = useCallback(
    (item: any) => {
      input.onChange(getChoiceValue(item))
      setFilterValue(getChoiceText(item))
    },
    [getChoiceValue, input]
  )

  // This function ensures that the suggestion list stay aligned to the
  // input element even if it moves (because user scrolled for example)
  const updateAnchorEl = () => {
    if (!inputEl.current) {
      return
    }

    const inputPosition = inputEl.current.getBoundingClientRect() as DOMRect

    // It works by implementing a mock element providing the only method used
    // by the PopOver component, getBoundingClientRect, which will return a
    // position based on the input position
    if (!anchorEl.current) {
      anchorEl.current = { getBoundingClientRect: () => inputPosition }
    } else {
      const anchorPosition = anchorEl.current.getBoundingClientRect()

      if (anchorPosition.x !== inputPosition.x || anchorPosition.y !== inputPosition.y) {
        anchorEl.current = {
          getBoundingClientRect: () => inputPosition,
        }
      }
    }
  }

  const storeInputRef = (input) => {
    inputEl.current = input
    //used by ExhibitedShowcase
    if (autocompleteInputRef) {
      autocompleteInputRef.current = input
    }
    updateAnchorEl()
  }

  const handleBlur = useCallback(
    (event) => {
      setDropDownOpen(false)

      if (freeSolo) {
        const previousFilterValue = selectedItem ? getFilterTextFromItem(selectedItem) : undefined
        if (previousFilterValue === filterValue) {
          // retain current selection
          return
        }

        // This behaviour only works when the optionValue and optionText are the same
        // This allows a real item to be selected just by typing it's label (i.e. not clicking on it in the dropdown)
        const newSelectedItem = optionValue === optionText ? getSuggestionFromValue(filterValue) : undefined
        if (newSelectedItem) {
          handleDownshiftChange(newSelectedItem)
        } else {
          // This code is the only place where the input value is set to the filter value
          // This happens in freeSolo mode only and it makes an assumption that the
          // filter value and the input value are allowed to both be used as the input value.
          input.onChange(filterValue)
        }
      } else {
        setFilterValue(input.value ? getFilterTextFromItem(selectedItem) : '')
      }

      input.onBlur(event)
    },
    [getChoiceText, handleFilterChange, input, inputText, selectedItem, filterValue]
  )

  const handleFocus = useCallback(
    (openMenu) => (event) => {
      openMenu(event)
      setDropDownOpen(true)
      input.onFocus(event)
    },
    [input]
  )

  const shouldRenderSuggestions = (val) => {
    if (shouldRenderSuggestionsOverride !== undefined && typeof shouldRenderSuggestionsOverride === 'function') {
      return shouldRenderSuggestionsOverride(val)
    }

    return true
  }
  return (
    <Downshift
      inputValue={filterValue}
      onChange={handleDownshiftChange}
      selectedItem={selectedItem}
      itemToString={(item) => getChoiceValue(item)}
      {...rest}
    >
      {({ getInputProps, getItemProps, getLabelProps, getMenuProps, isOpen, highlightedIndex, openMenu }) => {
        const isMenuOpen = openDropDown && isOpen && shouldRenderSuggestions(filterValue)
        const {
          id: downshiftId, // We want to ignore this to correctly link our label and the input
          value,
          onBlur,
          onChange,
          onFocus,
          ref,
          size,
          color,
          ...inputProps
        } = getInputProps({
          onBlur: handleBlur,
          onFocus: handleFocus(openMenu),
          ...InputProps,
        })
        // avoid double filter
        // const suggestions = getSuggestions(filterValue)

        const suggestions = getSuggestions('')
        return (
          <div style={options.containerStyle} className={classes.container}>
            <TextField
              id={id}
              name={input.name}
              onClick={() => {
                if (setFilter) {
                  handleSetFilter(inputEl.current.value)
                }
                // setFilterValue(inputEl.current.value)
              }}
              autoComplete="off"
              placeholder={placeholder && translate(placeholder)}
              InputProps={{
                inputRef: storeInputRef,
                onBlur,
                endAdornment: allowEmpty && filterValue && !options.disabled && (
                  <Tooltip title={translate('clear input')} enterDelay={300}>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault()
                        if (setFilter) {
                          handleSetFilter('')
                        }
                        setFilterValue('')
                        input.onChange(null)
                      }}
                      classes={{ root: classes.clearButton }}
                    >
                      <CancelIcon style={{ color: 'rgb(117 117 117)' }} />
                    </IconButton>
                  </Tooltip>
                ),
                onChange: (event) => {
                  // console.log('event', event.target.value)

                  handleFilterChange(event)
                  setFilterValue(event.target.value)
                  if (customOnInputChange) {
                    customOnInputChange(event)
                  } else {
                    onChange!(event as React.ChangeEvent<HTMLInputElement>)
                  }
                },
                onFocus,
                startAdornment: inputProps?.startAdornment,
              }}
              error={!!(touched && errorMessage)}
              label={
                label !== '' &&
                label !== false && (
                  <FieldTitle
                    label={label}
                    {...labelProps}
                    source={source}
                    resource={resource}
                    isRequired={typeof isRequiredOverride !== 'undefined' ? isRequiredOverride : isRequired}
                  />
                )
              }
              InputLabelProps={getLabelProps({
                htmlFor: id,
              })}
              helperText={
                helperText && <InputHelperText touched={touched} error={errorMessage} helperText={helperText} />
              }
              variant={variant}
              margin={margin}
              fullWidth={fullWidth}
              value={filterValue}
              className={className}
              size={size as any}
              color={color as any}
              {...inputProps}
              {...options}
            />
            <AutocompleteSuggestionList
              isOpen={isMenuOpen}
              menuProps={getMenuProps(
                {},
                // https://github.com/downshift-js/downshift/issues/235
                { suppressRefError: true }
              )}
              inputEl={inputEl.current}
              suggestionsContainerProps={suggestionsContainerProps}
            >
              {customItem
                ? suggestions.map((suggestion, index) =>
                    React.cloneElement(customItem, {
                      key: getChoiceValue(suggestion) + index,
                      suggestion,
                      index,
                      highlightedIndex,
                      isSelected: Boolean(input.value === getChoiceValue(suggestion)),
                      filterValue,
                      getSuggestionText: getChoiceText,
                      getSuggestionValue: getChoiceValue,
                      allowEmpty,
                      ...getItemProps({
                        item: suggestion,
                      }),
                    })
                  )
                : suggestions.map((suggestion, index) => {
                    //handleBlur helps to correct filterValue(search input text) when selecting 'selected suggestion' from dropdown
                    const { onClick: onSuggestionItemClick, ...restProps } = getItemProps({
                      item: suggestion,
                    })
                    const finalOnclick = (event) => {
                      handleBlur(event)
                      onSuggestionItemClick(event)
                    }
                    return (
                      <AutocompleteSuggestionItem
                        key={getChoiceValue(suggestion) + index}
                        suggestion={suggestion}
                        index={index}
                        highlightedIndex={highlightedIndex}
                        isSelected={input.value === getChoiceValue(suggestion)}
                        filterValue={filterValue}
                        style={options.suggestionItemStyle}
                        getSuggestionText={getChoiceText}
                        {...restProps}
                        onClick={finalOnclick}
                      />
                    )
                  })}
            </AutocompleteSuggestionList>
          </div>
        )
      }}
    </Downshift>
  )
}

const useStyles = makeStyles(
  {
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      // flexGrow: 1,
      position: 'relative',
    },
    clearButton: {
      padding: 6,
    },
  },
  { name: 'RaAutocompleteInput' }
)

export default AutocompleteInput
