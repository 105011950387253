import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import { PropsDefs } from './types'

const useStyles = makeStyles(
  {
    root: {
      padding: 12,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      // Checkered BG
      backgroundImage:
        'linear-gradient(45deg, #f3f3f3 25%, transparent 25%, transparent 75%, #f3f3f3 75%, #f3f3f3 100%),linear-gradient(45deg, #f3f3f3 25%, white 25%, white 75%, #f3f3f3 75%, #f3f3f3 100%)',
      backgroundSize: '20px 20px',
      backgroundPosition: '0px 0px, 10px 10px',
    },
  },
  { name: 'ExampleComponent' }
)

interface ExampleComponentProps<T> {
  propDefs?: PropsDefs
  props: T
  type: React.ComponentType<T>
}

export function ExampleComponent<T extends object>({ type, props, propDefs }: ExampleComponentProps<T>) {
  const classes = useStyles()
  const newProps = useMemo(() => {
    const newProps = {} as T
    if (propDefs) {
      for (let key in propDefs) {
        if (props.hasOwnProperty(key)) {
          newProps[key] = props[key]
        }
      }
    }
    return newProps
  }, [propDefs, props])
  return <div className={classes.root}>{React.createElement(type, newProps)}</div>
}
