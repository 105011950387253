import { Button, Link, LinkExternalOutlineIcon, styled } from 'opensolar-ui'
import { FC } from 'react'
import { HighlightConfig } from '../../expoStand'
import Countdown from './Countdown'

interface PropTypes {
  highlight: HighlightConfig
}

const CardWrapper = styled('div', { name: 'CardWrapper' })<{
  backgroundUrl: string
}>(({ backgroundUrl }) => ({
  flexGrow: 1,
  flexBasis: 290,
  borderRadius: 10,
  padding: 30,
  backgroundImage: backgroundUrl ? `url("${backgroundUrl}")` : '',
  backgroundColor: backgroundUrl ? '' : 'white',
  display: 'flex',
}))

const ActionButton = styled(Button, { name: 'ActionButton' })({
  backgroundColor: '#4DC0AC',
  color: 'white',
})

const CardTitle = styled('h1', { name: 'CardTitle' })({
  fontSize: 32,
  fontWeight: 450,
  margin: '10px 0',
})

const CardDesc = styled('h3', { name: 'CardDesc' })({
  fontSize: 16,
  maxWidth: '65%',
  fontWeight: 400,
  lineHeight: '24px',
})

const HighlightCard: FC<PropTypes> = ({ highlight }) => {
  const { title, description, cta, fullWidth, enableCountdown, countdownDate, whiteText, backgroundUrl } = highlight
  if (!highlight) return null
  return (
    <CardWrapper
      style={{
        flexBasis: fullWidth || enableCountdown ? '100%' : undefined,
        color: whiteText ? 'white' : undefined,
      }}
      backgroundUrl={backgroundUrl || ''}
    >
      <div>
        {title && <CardTitle>{title}</CardTitle>}
        {description && <CardDesc>{description}</CardDesc>}
        {cta && cta.label && (
          <>
            {cta.type === 'url' ? (
              <Link href={cta.url} target="_blank">
                <ActionButton variant="contained" endIcon={<LinkExternalOutlineIcon />}>
                  {cta.label}
                </ActionButton>
              </Link>
            ) : (
              <Link href={cta.file} target="_blank" download={'expo_highlight'}>
                <ActionButton variant="contained">{cta.label}</ActionButton>
              </Link>
            )}
          </>
        )}
      </div>
      {enableCountdown && countdownDate && <Countdown endDateTime={countdownDate} />}
    </CardWrapper>
  )
}

export default HighlightCard
