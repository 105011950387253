import { sanitize } from 'dompurify'
import { useTranslateOrTranslatableText } from 'hooks/translation/useTranslateOrTranslatableText'
import { Link, Typography } from 'opensolar-ui'
import { doNotTranslate } from 'util/misc'
import { PromoDialogComponent, PromoDialogElement } from '../usePromoDialogStructs'
import { PromoActionRowElement } from './PromoActionRowElement'
import { PromoCountdownElement } from './PromoCountdownElement'
import { PromoImageElement } from './PromoImageElement'
import { PromoVideoElement } from './PromoVideoElement'

type Props = {
  trackingSource?: string
  elements: PromoDialogElement[]
  onNext: () => void
}
export const PromoContentElements = ({ elements, trackingSource, onNext }: Props) => {
  const translate = useTranslateOrTranslatableText()
  const render = (element: PromoDialogElement, index: number) => {
    const key = `${element.type}-${index}`
    switch (element.type) {
      case 'h3':
      case 'h4':
      case 'h5':
      case 'body1':
      case 'body2':
      case 'subtitle1':
      case 'subtitle2':
      case 'caption1':
      case 'caption2':
        let padding = 12
        switch (element.type) {
          case 'h3':
            padding = 20
            break
          case 'h4':
            padding = 16
            break
        }
        return (
          <div key={key} style={{ padding: `${padding}px` }}>
            <Typography key={key} textVariant={element.type}>
              {translate(element.text)}
            </Typography>
          </div>
        )
      case 'link':
        return (
          <Link key={key} href={element.href} onClick={element.onClick} target={element.target} underline="always">
            {translate(element.text)}
          </Link>
        )
      case 'gap':
        return <div key={key} style={{ height: element.gap_size * 8 }} />
      case 'html':
        return (
          <div
            key={key}
            style={{ textAlign: 'left' }}
            dangerouslySetInnerHTML={{ __html: sanitize(translate(element.html)) }}
          />
        )
      case 'video':
        return <PromoVideoElement key={key} element={element} trackingSource={trackingSource} />

      case 'image':
        return <PromoImageElement key={key} element={element} />

      case 'countdown':
        return <PromoCountdownElement key={key} element={element} />

      case 'action-row':
        return <PromoActionRowElement key={key} element={element} />

      case 'react-component':
        return <CustomReactComponent key={key} element={element} onNext={onNext} />

      default:
        //@ts-ignore
        const typeStr: string = element.type as string
        return <div key={key}>{doNotTranslate('No renderer found for element type: ' + typeStr)}</div>
    }
  }

  return <>{elements?.map((element, index) => render(element, index))}</>
}

const CustomReactComponent = ({ element, onNext }: { element: PromoDialogComponent; onNext: () => void }) => {
  return <div>{element.render({ element, key: 'dummy', onNext })}</div>
}
