export const DUPLICATE = 'DUPLICATE'
export const DUPLICATE_LOADING = 'DUPLICATE_LOADING'
export const DUPLICATE_SUCCESS = 'DUPLICATE_SUCCESS'

export const DATA_PROVIDER_ERROR = 'DATA_PROVIDER_ERROR'

export const duplicate = (payload, params) => ({
  type: DUPLICATE,
  payload,
  meta: { params: params },
})

export const dataProviderError = (error, opts) => ({
  type: DATA_PROVIDER_ERROR,
  error,
  opts,
})
