import { InputLabel } from '@mui/material'
import { FC, ReactNode } from 'react'
import { styled } from '../styles'

interface PropTypes {
  required?: boolean
  label?: ReactNode
}

const StyledLabel = styled(InputLabel)({
  marginBottom: 5,
})

export enum OsFieldTitleVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export const FieldTitle: FC<PropTypes> = ({ label, required }) => {
  return (
    <StyledLabel>
      {label}
      {required && ' *'}
    </StyledLabel>
  )
}

export default FieldTitle
