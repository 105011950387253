export class Signal<Handler extends (...args: any) => any = () => void> {
  private handlers: Handler[] = []

  public add(handler: Handler) {
    this.handlers.push(handler)
  }

  public remove(handler: Handler) {
    const index = this.handlers.indexOf(handler)
    if (index >= 0) {
      this.handlers.splice(index, 1)
    }
  }

  public dispatch(...args: Parameters<Handler>) {
    for (const handler of this.handlers) {
      handler(...args)
    }
  }
}
