import PhoneField from 'elements/field/PhoneField'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { InputRow } from '../../../styles'

interface PropTypes {
  source: string
  index: number
  disabled?: boolean
  showErrors?: boolean
}

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.contrastText,
}))

const ContactStandardFields: React.FC<PropTypes> = ({ index, source, disabled = false, showErrors = false }) => {
  const translate = useTranslate()
  const project = useFormState().values
  const { errors } = useFormState({
    subscription: { errors: true },
  })
  const country = project?.country_name
  const contactErrors = errors?.contacts_data?.[index]
  const hasRequiredErrorMessage = contactErrors?.required
  const emailValue = project?.contacts_data?.[index]?.email
  return (
    <>
      <InputRow>
        <TextInput
          source={`${source}.first_name`}
          variant="outlined"
          label={'First Name'}
          disabled={disabled}
          fullWidth
        />
        <TextInput
          source={`${source}.family_name`}
          variant="outlined"
          label={'Last Name'}
          disabled={disabled}
          fullWidth
        />
      </InputRow>
      <InputRow>
        <TextInput
          fullWidth
          source={`${source}.email`}
          variant="outlined"
          label={'Customer Email'}
          type="email"
          error={showErrors && (!emailValue || emailValue.trim() === '')}
          validate={(value) => {
            let currentEmails = value?.split(',').filter((e) => e && e.trim())
            if (currentEmails) {
              let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i
              for (let i = 0; i < currentEmails.length; i++) {
                if (!regex.test(currentEmails[i].replace(/\s/g, ''))) {
                  return translate('Enter a valid email address.')
                }
              }
            }
          }}
          disabled={disabled}
        />
        <PhoneField source={source} initialCountry={country} label={'Customer Phone Number'} disabled={disabled} />
      </InputRow>
      {hasRequiredErrorMessage && (
        <InputRow>
          <ErrorMessage>{translate(hasRequiredErrorMessage)}</ErrorMessage>
        </InputRow>
      )}
    </>
  )
}

export default ContactStandardFields
