import { FC } from 'react'

/**
 * * Icon Component for: file-01, file-02
 */
type FileOutlineIconProps = {
  variant: 1 | 2
  size?: number
  color?: string
}
export const FileOutlineIcon: FC<FileOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M14.7995 1.4H3.59931C2.27383 1.4 1.19932 2.47451 1.19931 3.79999L1.19922 18.1999C1.19921 19.5254 2.27372 20.5999 3.59921 20.5999L14.3993 20.6C15.7247 20.6 16.7992 19.5255 16.7993 18.2001L16.7995 3.40004C16.7996 2.29546 15.9041 1.4 14.7995 1.4Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M5.39959 6.20001H12.5996M5.39959 9.80001H12.5996M5.39959 13.4H8.99959M3.59931 1.40001H14.3995C15.725 1.40001 16.7996 2.47455 16.7995 3.80005L16.7993 18.2001C16.7992 19.5255 15.7247 20.6 14.3993 20.6L3.59921 20.6C2.27372 20.5999 1.19921 19.5254 1.19922 18.1999L1.19931 3.8C1.19932 2.47452 2.27383 1.40001 3.59931 1.40001Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
