import { makeStyles, Theme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import { FormProps as SubFormFormPros } from 'elements/react-admin/SubFormDialog'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  addContactBtn: {
    marginRight: 'auto !important',
  },
}))

interface FormProps extends SubFormFormPros {
  disabled?: boolean
}

const AddContactBtn: React.FC<FormProps> = ({ form, formState, disabled = false }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = formState.values
  return (
    <Button
      variant="contained"
      color="default"
      startIcon={<AddIcon />}
      onClick={() => {
        form.change('contacts_data', [...formValues?.contacts_data, {}])
      }}
      className={classes.addContactBtn}
      disabled={disabled}
    >
      {translate('Add Contact')}
    </Button>
  )
}
export default AddContactBtn
