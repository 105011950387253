import { IconButton as Mui4IconButton, IconButtonProps as Mui4IconButtonPropsType } from '@material-ui/core'
import { default as Mui5IconButton, IconButtonProps as Mui5IconButtonPropsType } from '@mui/material/IconButton'
import React, { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

export const IconButton: FC<IconButtonProps> = React.forwardRef((props, ref) => {
  return (
    <Inspector
      name="IconButton"
      propDefs={IconButtonPropDefs}
      showExample={true}
      component={OsButton}
      props={{ ...props, ref }}
    />
  )
})

export enum OsIconButtonVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type IconButtonProps = Omit<Mui4IconButtonPropsType, 'color'> & {
  color?: Mui4IconButtonPropsType['color'] | Mui5IconButtonPropsType['color']
  variant?: Mui5IconButtonPropsType['variant']
}

const OsButton: FC<IconButtonProps> = forwardRef((props, ref) => {
  let version = useComponentVersion('icon_button')
  let { color, variant, ...rest } = props

  switch (version) {
    case OsIconButtonVersion.V2:
      let color5: Mui5IconButtonPropsType['color'] = color === 'default' ? undefined : color
      let className = rest.className || ''
      return (
        <Mui5IconButton ref={ref} color={color5} variant={variant} {...rest} className={className}></Mui5IconButton>
      )

    default:
      let color4: Mui4IconButtonPropsType['color'] =
        color === 'inherit' || color === 'primary' || color === 'secondary' || color === 'default' ? color : 'default'
      return <Mui4IconButton ref={ref} color={color4} {...rest}></Mui4IconButton>
  }
})

const IconButtonPropDefs: PropsDefs = {
  variant: { default: 'text' },
  size: { default: 'medium' },
  color: { default: undefined },
  startIcon: { hidden: true },
  endIcon: { hidden: true },
  children: { hidden: true },
  fullWidth: { hidden: true },
}

// extend the spec
declare module '@mui/material/IconButton' {
  interface IconButtonOwnProps {
    variant?: 'squared' | 'rounded'
  }

  interface IconButtonPropsColorOverrides {
    tertiary: true
  }
}
