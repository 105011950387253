import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import BlogFields from './blockInputs/BlogFields'
import ChatFields from './blockInputs/ChatFields'
import CoverFields from './blockInputs/CoverFields'
import CustomContentFields from './blockInputs/CustomContentFields'
import DescFields from './blockInputs/DescFields'
import HighlightsFields from './blockInputs/HighlightsFields'
import NavbarFields from './blockInputs/NavbarFields'
import ResourcesFields from './blockInputs/ResourcesFields'
import StatsFields from './blockInputs/StatsFields'
import VideoFields from './blockInputs/VideoFields'

const contentFields = {
  navbar: NavbarFields,
  cover: CoverFields,
  our_story: DescFields,
  highlights: HighlightsFields,
  stats: StatsFields,
  videos: VideoFields,
  blogs: BlogFields,
  // socials: SocialsFields,
  resources: ResourcesFields,
  custom_content_1: CustomContentFields,
  custom_content_2: CustomContentFields,
  custom_content_3: CustomContentFields,
  chat: ChatFields,
}

const assetSource = 'contents.assets'
const ContentFields = (props) => {
  const formValues = useFormState().values
  const filteredContent = useMemo(() => {
    return formValues.contents?.elements?.filter((x) => x.enabled)
  }, [formValues.contents?.elements])
  if (!!!filteredContent?.length) return null
  return (
    <>
      {filteredContent.map((x, i) => {
        const FieldsComponent = contentFields[x.contentType]
        if (FieldsComponent)
          return <FieldsComponent key={'content-' + i} {...props} blockId={x.contentType} assetSource={assetSource} />
        return null
      })}
    </>
  )
}

export default ContentFields
