// @ts-nocheck
import { Card, makeStyles } from '@material-ui/core'
import ClockIcon from 'icons/ClockIcon'
import EnergyIcon from 'icons/EnergyIcon'
import RevenueIcon from 'icons/RevenueIcon'
import SolarIcon from 'icons/SolarIcon'
import ViewsIcon from 'icons/ViewsIcon'
import React, { useCallback, useEffect, useState } from 'react'
import { useDataProvider, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    padding: 15,
    flexGrow: 1,
    paddingTop: 20,
    margin: 5,
    borderWidth: 2,
    borderColor: '#e7e7e7',
    boxSizing: 'border-box',
    textAlign: 'left',
    background: '#F8F8F8',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px 10px',
    },
    height: 100,
  },
  heading: {
    marginTop: 0,
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    lineHeight: '16px',
  },
  figure: {
    fontSize: 26,
    verticalAlign: 'middle',
    color: '#6F737E',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  figureContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {},
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 5,
    width: '26px',
    height: '26px',
    color: '#6F737E',
    [theme.breakpoints.down('xs')]: {
      width: '20px',
      height: '20px',
    },
  },
  h1Title: {
    marginLeft: 5,
  },
}))

const PROJECT_STATS_CONFIGS = [
  { key: 'total_projects', heading: 'Total Projects', icon: <SolarIcon /> },
  {
    key: 'unsold_projects',
    heading: 'Unsold Projects',
    icon: <SolarIcon />,
  },
  { key: 'sold_projects', heading: 'Sold Projects', icon: <SolarIcon /> },
  { key: 'installed_projects', heading: 'Installed Projects', icon: <SolarIcon /> },
  { key: 'other_projects', heading: 'Other Projects', icon: <SolarIcon /> },
]

const COMPANY_STATS_CONFIGS = [
  {
    key: 'total_revenue',
    heading: 'Total Revenue',
    icon: <RevenueIcon />,
    startAdornment: (currencySymbol: string) => currencySymbol,
  },
  {
    key: 'proposal_views',
    heading: 'Proposal Views in the last 30 days',
    icon: <ViewsIcon />,
  },
  {
    key: 'average_time_lead_to_sold',
    heading: 'Average Time - Lead to Sold',
    icon: <ClockIcon />,
    endAdornment: 'days',
  },
  { key: 'total_kw_sold', heading: 'Total kW Sold', icon: <EnergyIcon />, endAdornment: 'kW' },
]

const STATS_CONFIGS = [...PROJECT_STATS_CONFIGS, ...COMPANY_STATS_CONFIGS]

type ConfigType = {
  key: string
  heading: string
  icon: JSX.Element
  startAdornment?: string | Function
  endAdornment?: string | Function
}

const getAdornment = (adornment: any, currencySymbol: string) => {
  if (typeof adornment === 'string') {
    return adornment
  } else if (typeof adornment === 'function') {
    return adornment(currencySymbol)
  } else {
    return ''
  }
}

const DashboardCompanyStats = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })
  const [stats, setStats] = useState()
  const [loadingKeys, setLoadingKeys] = useState(STATS_CONFIGS.map((config) => config.key))
  const onFetchFinished = useCallback((configs) => {
    setLoadingKeys((loadingKeys) => {
      const set = new Set(loadingKeys)
      configs.forEach((config: ConfigType) => {
        set.delete(config.key)
      })
      //@ts-ignore
      return [...set]
    })
  }, [])

  useEffect(() => {
    const onFetchCompanyStatsSuccess = ({ data }: any) => {
      setStats((stats: any) => {
        return {
          ...stats,
          ...data,
        }
      })
    }

    const onFetchProjectStatsSuccess = ({ data }: any) => {
      setStats((stats: any) => {
        //@ts-ignore
        const sum = Object.values(data).reduce((sum: number, a: number) => sum + a, 0)
        return {
          ...stats,
          total_projects: sum,
          unsold_projects: data['unsold'],
          sold_projects: data['sold_with_installed'],
          installed_projects: data['installed'],
          other_projects: data['others'],
        }
      })
    }

    dataProvider
      .CUSTOM_GET(
        'projects',
        {
          url: 'project_milestone_count/',
        },
        { onSuccess: onFetchProjectStatsSuccess }
      )
      .finally(() => onFetchFinished(PROJECT_STATS_CONFIGS))

    dataProvider
      .CUSTOM_GET(
        'projects',
        {
          url: 'company_stats/',
        },
        { onSuccess: onFetchCompanyStatsSuccess }
      )
      .finally(() => onFetchFinished(COMPANY_STATS_CONFIGS))
  }, [])
  return (
    <div>
      <h1 className={classes.h1Title}>{translate('Your Company Stats')}</h1>
      <div className={classes.cardWrapper}>
        {STATS_CONFIGS.map((config: ConfigType) => {
          const isLoading = loadingKeys.includes(config.key)
          return (
            <Card variant="outlined" classes={{ root: classes.card }}>
              {isLoading ? (
                <Skeleton height={'50px'} />
              ) : (
                <div className={classes.figureContainer}>
                  <h3 className={classes.heading}>{translate(config.heading)}</h3>
                  <div>
                    {React.cloneElement(config.icon, { className: classes.icon })}
                    {stats && stats.hasOwnProperty(config.key) && (
                      <span className={classes.figure}>
                        {getAdornment(config.startAdornment, currencySymbol)}
                        {stats[config.key]?.toLocaleString()}
                        {getAdornment(config.endAdornment, currencySymbol)}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default DashboardCompanyStats
