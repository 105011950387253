import { styled } from 'opensolar-ui'
import { FC } from 'react'
import { CardWrapper } from './styles'

const Title = styled('p', { name: 'Title' })({
  fontWeight: 600,
  fontSize: 16,
})

const Thumbnail = styled('img', { name: 'Thumbnail' })({
  height: 200,
  width: 360,
  borderRadius: 8,
})

interface PropTypes {
  title: string
  thumbnail_url: string
  onClick: () => void
}

const ThumbnailCard: FC<PropTypes> = ({ title, thumbnail_url, onClick }) => {
  return (
    <CardWrapper onClick={onClick}>
      <Thumbnail src={thumbnail_url} />
      <Title>{title}</Title>
    </CardWrapper>
  )
}
export default ThumbnailCard
