import { Grid, GridSize } from '@material-ui/core'
import { Add, DeleteOutlined } from '@material-ui/icons'
import get from 'lodash/get'
import { IconButton } from 'opensolar-ui'
import { useCountrySpecificTariffFields } from 'projectSections/sections/energy/ElectricityUtility/hooks'
import { Button } from 'ra-ui-materialui'
import { FC } from 'react'
import { useForm } from 'react-final-form'
import {
  ADJUSTMENT_OPTIONS,
  defaultAdjustmentRate,
  defaultFixedRate,
  defaultPeriod,
  defaultScheduledRate,
  DEFAULT_UNITS,
  SCHEDULE_OPTIONS,
  SINGLE_TIER_OPTIONS,
} from 'resources/utilityTariffs/common'
import { tableStyles } from '../../styles'
import CriteriaFields from './CriteriaFields'
import RatePeriods from './RatePeriods'

type PropsType = {
  source: string
  disabled?: boolean
  countryIso2?: string
  chargeCategories?: { name: string; id: string }[]
}

export const TARIFF_FIELD_COMPONENTS = {
  CriteriaFields,
} as const

const PricingRates: FC<PropsType> = ({ source, disabled, countryIso2, chargeCategories }) => {
  const form = useForm()
  const classes = tableStyles()
  const rateList = get(form.getState().values, source)
  const sourceName = source.replace('tariffData.', '')
  const allowSetSchedule = SCHEDULE_OPTIONS.includes(sourceName)
  const addNewRate = (e, tiered = false, multiplePeriod = false) => {
    if (rateList) {
      if (SCHEDULE_OPTIONS.includes(sourceName)) {
        form.mutators.updateField(source, [
          ...rateList,
          defaultScheduledRate(defaultPeriod(tiered, sourceName), multiplePeriod),
        ])
      } else if (ADJUSTMENT_OPTIONS.includes(sourceName)) {
        form.mutators.updateField(source, [...rateList, defaultAdjustmentRate])
      } else if (SINGLE_TIER_OPTIONS.includes(sourceName)) {
        form.mutators.updateField(source, [...rateList, defaultFixedRate(DEFAULT_UNITS[sourceName])])
      }
    }
  }
  const handleRateDelete = (index) => {
    let currList = [...rateList]
    currList.splice(index, 1)
    form.mutators.updateField(source, currList)
  }
  const addRatePeriod = (rateIndex) => {
    form.mutators.updateField(`${source}.${rateIndex}.tou_periods`, [
      ...rateList[rateIndex].tou_periods,
      defaultPeriod(false, sourceName),
    ])
  }
  const checkSchedule = (rateIndex) => {
    const rateTOUPeriods = rateList[rateIndex].tou_periods
    return rateTOUPeriods.length > 1 || rateTOUPeriods[0].schedule !== null
  }

  const { hasSpecialDefaults, specialFields } = useCountrySpecificTariffFields(countryIso2)
  const sourceSpecialFields = specialFields[sourceName] || []

  return (
    <>
      {!!rateList?.length && (
        <>
          {rateList.map((rate, i) => {
            return (
              <>
                <div className={classes.rateContainer}>
                  <div className={classes.rateContent}>
                    <Grid container>
                      <Grid item xs={12} xl={hasSpecialDefaults ? 10 : 12}>
                        <RatePeriods
                          disabled={disabled}
                          rateList={rateList}
                          source={source}
                          rateIndex={i}
                          allowSetSchedule={allowSetSchedule}
                          addRatePeriod={() => {
                            addRatePeriod(i)
                          }}
                          chargeCategories={chargeCategories}
                        />
                      </Grid>
                      {sourceSpecialFields.map((field) => {
                        const FieldComponent = TARIFF_FIELD_COMPONENTS[field.component]
                        return (
                          <Grid item xs="auto" xl={field.width as GridSize} key={field.component}>
                            <FieldComponent source={source} rateIndex={i} />
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Grid item style={{ width: 44, padding: 4 }}>
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          handleRateDelete(i)
                        }}
                        className={classes.deleteBtn}
                        style={{ margin: 'auto' }}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </Grid>
                  </div>
                </div>
                {allowSetSchedule && checkSchedule(i) && (
                  <Grid className={classes.addTimeOfUseBtn}>
                    <Button
                      disabled={disabled}
                      hideLabelAt={null}
                      className={classes.addRatePeriod}
                      label="Add Time-of-Use Period"
                      startIcon={<Add />}
                      onClick={(e) => {
                        addRatePeriod(i)
                      }}
                      variant="contained"
                      color="default"
                    />
                  </Grid>
                )}
              </>
            )
          })}
        </>
      )}
      {source === 'tariffData.energy_charges' || (source === 'tariffData.shipping_charges' && !!!rateList?.length) ? (
        <div className={classes.row}>
          <Button
            label="Add Flat Rate"
            startIcon={<Add />}
            onClick={addNewRate}
            variant="contained"
            color="default"
            hideLabelAt={null}
            disabled={disabled || !rateList}
          />
          <Button
            disabled={disabled || !rateList}
            label="Add Tiered Rate"
            startIcon={<Add />}
            onClick={(e) => {
              addNewRate(e, true)
            }}
            variant="contained"
            color="default"
          />
          <Button
            disabled={disabled || !rateList}
            label="Add Time-of-Use Rate"
            onClick={(e) => {
              addNewRate(e, false, true)
            }}
            startIcon={<Add />}
            variant="contained"
            color="default"
          />
        </div>
      ) : (
        <Button
          disabled={disabled || !rateList}
          hideLabelAt={null}
          label="Add Rate"
          startIcon={<Add />}
          onClick={addNewRate}
          variant="contained"
          color="default"
          className={classes.addRate}
        />
      )}
    </>
  )
}

export default PricingRates
