import { FC } from 'react'

/**
 * * Icon Component for: scaffold
 */
type ScaffoldOutlineIconProps = {
  size?: number
  color?: string
}
export const ScaffoldOutlineIcon: FC<ScaffoldOutlineIconProps> = ({
  size = '28px',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <path
        d="M17.2 5H6M7.4 19V5L11.6 10.3667M6 19H8.8M15.8 19V5L11.6 10.3667M14.4 19H17.2M11.6 10.3667L15.8 15.7333H7.4L11.6 10.3667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
