import { Snackbar, styled } from 'opensolar-ui'
import { PromoContentElements } from 'persistentContent/promoDialog/elements/PromoContentElements'
import { FC } from 'react'
import ToastActions from './ToastActions'
import { ToastNotification as ToastType } from './useToastNotificationStructs'

interface PropTypes {
  open: boolean
  onClose: () => void
  toastNotif: ToastType
  onInteract: () => void
  trackingKey: string | undefined
}

const StyledSnackbar = styled(Snackbar, { name: 'StyledSnackbar' })({
  '& .OSUI-SnackbarContent-message': {
    width: '100%',
  },
})

const SnackWrapper = styled('div', { name: 'SnackWrapper' })({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 10,
  paddingRight: 10,
  maxWidth: 380,
  maxHeight: 280,
  overflowY: 'scroll',
})

const ContentWrapper = styled('div', { name: 'ContentWrapper' })({
  display: 'flex',
  gap: 5,
})

const ToastNotification: FC<PropTypes> = ({ open, onClose, trackingKey, toastNotif, onInteract }) => {
  const { elements, cta_label, dismiss_label, cta_link } = toastNotif
  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      message={
        <SnackWrapper>
          <ContentWrapper>
            <PromoContentElements elements={elements} trackingSource={trackingKey} onNext={onClose} />
          </ContentWrapper>
          <ToastActions
            onClick={onInteract}
            onClose={onClose}
            ctaLabel={cta_label}
            dismissLabel={dismiss_label}
            clickUrl={cta_link}
          />
        </SnackWrapper>
      }
    />
  )
}

export default ToastNotification
