import _ from 'lodash'
import { ModuleGridType } from 'types/global'
import { formatNumber } from '../../common/helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: number }) => {
  // do not update panel tilt override unnecessarily
  // if we detect that module grid is not actually using tilt racks
  const isUsingTiltRacks = moduleGrid.isUsingTiltRacks()
  const panelTilt = _.isNumber(moduleGrid.panelTiltOverride) ? formatNumber(moduleGrid.panelTiltOverride) : 0
  const slope = formatNumber(moduleGrid.getSlope())
  const relativeTilt = formatNumber(panelTilt - slope)
  if (isUsingTiltRacks && moduleGrid.panelTiltOverride !== null) {
    moduleGrid.panelTiltOverride = relativeTilt + value
  }
  window.editor.execute(
    new window.SetPanelConfigurationCommand(moduleGrid, 'slope', value, window.Utils.generateCommandUUIDOrUseGlobal())
  )
  window.SceneHelper.snapModuleGridToGroundLevel(moduleGrid)
}

const getter = (moduleGrid: ModuleGridType): number => {
  return formatNumber(moduleGrid.getSlope())
}

export const slopAccessorConfig: BasicAccessorConfig<number> = {
  get: getter,
  set: setter,
}
