import { FC } from 'react'

/**
 * * Icon Component for: mail-01
 */
type EmailIconProps = {
  size?: number
  color?: string
}
export const EmailIcon: FC<EmailIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes} viewBox="0 0 18 14" fill="none">
      <path
        d="M2 2.01471L9 6.58088L16.5 2.01471M3 13C1.89543 13 1 12.0914 1 10.9706V3.02941C1 1.9086 1.89543 1 3 1H15C16.1046 1 17 1.9086 17 3.02941V10.9706C17 12.0914 16.1046 13 15 13H3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
