// @ts-nocheck
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import type { Record } from 'ra-core'
import pure from 'recompose/pure'

import sanitizeRestProps from './sanitizeRestProps'
import { FieldProps, fieldPropTypes, InjectedFieldProps } from './types'

interface Props<T> extends FieldProps {
  render: (record: T, source: string, rest: any) => any
}

/**
 * @example
 * <FunctionField source="last_name" label="Name" render={record => `${record.first_name} ${record.last_name}`} />
 */
export type FunctionFieldType<T> = Props<T> & InjectedFieldProps & TypographyProps

const FunctionField = <T extends Record>({ className, record = {}, source, render, ...rest }: FunctionFieldType<T>) =>
  record ? (
    <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
      {render(record, source, rest)}
    </Typography>
  ) : null

const EnhancedFunctionField = pure<Props<T> & TypographyProps>(FunctionField)

EnhancedFunctionField.defaultProps = {
  addLabel: true,
}

EnhancedFunctionField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
}

EnhancedFunctionField.displayName = 'EnhancedFunctionField'

export default EnhancedFunctionField
