import { styled } from 'opensolar-ui'
import { FC, useMemo, useRef } from 'react'
import ComponentBlock from './ComponentBlock'
import { StandData } from './expoStand'

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  justifyContent: 'center',
})

const PreviewWrapper = styled('div', { name: 'PreviewWrapper' })({
  width: '100%',
  maxWidth: 1440,
  backgroundColor: '#F6F9FC',
  paddingBottom: 30,
})

export const ExpoStandRenderer: FC<StandData> = (props) => {
  const { org, expoStand } = props
  const itemEls = useRef<any>({})
  const elements = expoStand?.contents?.elements
  const enabledContents = useMemo(() => elements?.filter((x) => x.enabled), [elements])
  if (!org || !expoStand) return null
  return (
    <Container>
      <PreviewWrapper>
        {!!enabledContents?.length &&
          enabledContents.map((x) => (
            <div ref={(element) => (itemEls.current[x.contentType] = element)} key={x.contentType}>
              <ComponentBlock item={x} enabledContents={enabledContents} itemEls={itemEls} {...props} />
            </div>
          ))}
      </PreviewWrapper>
    </Container>
  )
}
