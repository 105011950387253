import { Button, Link, styled } from 'opensolar-ui'
import { FC } from 'react'
import { palette } from './common/styles'

interface PropTypes {
  breadcrumbs: { label: string; href?: string }[]
  links: { label: string; onClick: () => void }[]
  cta?: {
    label: string
    link: string
    newTab?: boolean
  }
}

const NavWrapper = styled('div', { name: 'NavWrapper' })(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.grey[600],
  fontWeight: 450,
  padding: '15px 30px',
  display: 'flex',
  alignItems: 'center',
  gap: 30,
}))

const NavButton = styled(Button, { name: 'NavButton' })(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 450,
}))

const ExpoLink = styled(Link, { name: 'ExpoLink' })(({ theme }) => ({
  color: theme.palette.grey[600] + '!important',
  textDecoration: 'underline',
}))

const ActionButton = styled(Button, { name: 'ActionButton' })(({ theme }) => ({
  backgroundColor: palette.blue,
  color: theme.palette.grey[100],
}))

const Breadcrumbs = styled('div', { name: 'Breadcrumbs' })({
  display: 'flex',
  gap: 5,
})

const Navbar: FC<PropTypes> = ({ breadcrumbs, cta, links }) => {
  return (
    <NavWrapper>
      {!!breadcrumbs?.length && (
        <Breadcrumbs>
          {breadcrumbs.map((x, i) => (
            <span key={'breadcrumb-' + i}>
              {x.href ? <ExpoLink href={x.href}>{x.label}</ExpoLink> : <>{x.label}</>}
              {i === breadcrumbs.length - 1 ? '' : ' / '}
            </span>
          ))}
        </Breadcrumbs>
      )}
      {!!links?.length &&
        links.map((x, i) => (
          <NavButton size="small" onClick={x.onClick} key={'navBtn-' + i}>
            {x.label}
          </NavButton>
        ))}
      {cta && cta.label && (
        <Link href={cta.link} target={cta.newTab ? '_blank' : undefined}>
          <ActionButton size="small" variant="contained">
            {cta.label}
          </ActionButton>
        </Link>
      )}
    </NavWrapper>
  )
}

export default Navbar
