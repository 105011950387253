import * as Sentry from '@sentry/react'
import { useMemo } from 'react'
import { ErrorHandlerOverrides } from 'react-admin'

export const useErrorHandlingOverrides = (): ErrorHandlerOverrides => {
  return useMemo(
    () => ({
      after: {
        // Add Sentry tracking to unexpected errors
        unexpected: (err, opts) => {
          Sentry.captureException(err)
        },
      },
    }),
    []
  )
}
