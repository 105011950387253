import lodash from 'lodash'
import { Logger } from '../util/logger'
import { Signal } from './signal'

export class State<Type> extends Signal<(value: Type, old: Type) => void> {
  readonly logger = new Logger('OSSDK.State')

  /** @private */
  path: string = ''

  /**
   * This is purely to appease poor logic in sdk/peers
   * @private
   */
  defaultValue: Type | undefined

  private _value: Type
  private _changeFilter: undefined | ((value: Type, old: Type) => boolean)

  constructor(value: Type, changeFilter?: (value: Type, old: Type) => boolean, defaultValue?: Type) {
    super()
    this._value = value
    this._changeFilter = changeFilter
    this.defaultValue = defaultValue
  }
  public add(handler: (value: Type, old: Type) => void, opts?: { now: boolean }) {
    super.add(handler)
    if (opts?.now) handler(this._value, this._value)
  }

  set value(value: Type) {
    if (this.isAllowed && !this.isAllowed(this, value, this._value)) return
    this.innerSet(value)
  }

  isChanged(): boolean {
    if (this.defaultValue === undefined) {
      return this._value !== this.defaultValue
    }
    const changeComparator = this._changeFilter || valueFilter
    return changeComparator(this.defaultValue, this._value)
  }

  /** @private */
  innerSet(value: Type): boolean {
    const old = this._value
    if (this._changeFilter && this.matchValue(value)) return false
    this.logger.debug('innerSet:', this.path, value, old)
    this._value = value
    this.dispatch(value, old)
    return true
  }

  matchValue(value: Type): boolean {
    return this._matchValue<Type>(value, this.value, this._changeFilter)
  }
  /** @private */
  _matchValue<Type = any>(
    value: Type,
    old: Type,
    changeFilter: undefined | ((value: Type, old: Type) => boolean)
  ): boolean {
    if (!changeFilter) return true
    return !changeFilter(value, old)
  }

  get value(): Type {
    return this._value
  }

  /**
   * Gets set by application to indicate that the action is implemented in this app domain
   * @private
   */
  onOwn?: (state: State<Type>, priority: number) => void

  /**
   * Used to check whether this action is 'allowed' to be executed in this app domain
   * @private
   */
  isAllowed?: (state: State<Type>, value: Type, old: Type) => boolean

  own(priority: number = 0) {
    if (this.onOwn) this.onOwn(this, priority)
  }
}
function valueFilter<Type>(value: Type, old: Type) {
  return value !== old
}
function valueArrayFilter<Type extends any[] | undefined>(value: Type, old: Type): boolean {
  if (!value && !old) return false
  if (!value || !old) return true
  if (value.length !== old.length) return true
  for (let i = 0; i < value.length; i++) {
    if (valueFilter(value[i], old[i])) return true
  }
  return false
}

export class Value<Type extends number | string | boolean | undefined> extends State<Type> {
  constructor(value: Type) {
    super(value, valueFilter, value)
  }
}

export class ValueArray<Type extends number[] | string[] | boolean[]> extends State<Type> {
  constructor(value: Type) {
    super(value, valueArrayFilter)
  }
}

function shallowFilter<Type extends object | undefined>(value: Type, old: Type) {
  if ((value === undefined) !== (old === undefined)) return true
  if (value === undefined && old === undefined) return false

  if ((value === null) !== (old === null)) return true
  if (value === null && old === null) return false

  const keys1 = Object.keys(value!!) as (keyof Type)[]
  const keys2 = Object.keys(old!!) as (keyof Type)[]
  if (keys1.length !== keys2.length || keys1.some((key) => value!![key] !== old!![key])) return true
  return false
}
function deepFilter<Type extends object>(value: Type, old: Type) {
  return !lodash.isEqual(value, old)
}

export class ShallowState<Type extends object | undefined> extends State<Type> {
  constructor(value: Type) {
    super(value, shallowFilter)
  }
}

export class DeepState<Type extends object> extends State<Type> {
  constructor(value: Type) {
    super(value, deepFilter)
  }
}

export class ArrayState<Type extends object> extends State<Type[]> {
  private indexHandlers: IndexHandler[][] = []
  private itemChangeFilter: (value: Type, old: Type) => boolean

  constructor(value: Type[] = [], itemChangeFilter: (value: Type, old: Type) => boolean = deepFilter) {
    super(value, arrayFilter)
    this.itemChangeFilter = itemChangeFilter
  }

  get length() {
    return this.value.length
  }

  public get(index: number) {
    return this.value[index]
  }

  public addAt(index: number, handler: IndexHandler, opts?: { now: boolean }) {
    this.indexHandlers[index] = this.indexHandlers[index] || []
    this.indexHandlers[index].push(handler)
    if (opts?.now) handler(this.value[index], this.value[index], index, this.value)
  }

  public removeAt(index: number, handler: IndexHandler) {
    const handlers = this.indexHandlers[index]
    if (!handlers) return
    const i = handlers.indexOf(handler)
    if (i >= 0) handlers.splice(i, 1)
  }

  matchValue(value: Type[]): boolean {
    // Just checks the length of the arrays
    if (!super.matchValue(value)) return false

    // Check the elements
    for (let i = 0; i < value.length; i++) {
      if (!this._matchValue(value[i], this.value[i], this.itemChangeFilter)) return false
    }
    return true
  }

  /** @private */
  innerSet(value: Type[]): boolean {
    const old = this.value
    if (super.innerSet(value)) {
      for (let i = 0; i < this.indexHandlers.length; i++) {
        const handlers = this.indexHandlers[i]
        if (!handlers) continue
        if (this._matchValue(value[i], old[i], this.itemChangeFilter)) continue
        for (const handler of handlers) {
          handler(value[i], old[i], i, value)
        }
      }
      return true
    } else {
      return false
    }
  }
}

type IndexHandler = <Type>(value: Type | undefined, old: Type | undefined, index: number, all: Type[]) => void

// Just checks the basic shape of the array, elements get checked by itemChangeFilter
const arrayFilter = (value: any[], old: any[]) => {
  if (value === old) return false
  if (!Array.isArray(value) || !Array.isArray(old)) return true
  return value.length !== old.length
}
