import { FC } from 'react'

/**
 * * Icon Component for: table
 */
type TableIconProps = {
  size?: number
  color?: string
}
export const TableIcon: FC<TableIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M7.5 1C7.5 0.447715 7.05228 0 6.5 0C5.94771 0 5.5 0.447715 5.5 1H7.5ZM5.5 17C5.5 17.5523 5.94771 18 6.5 18C7.05228 18 7.5 17.5523 7.5 17H5.5ZM4 2H14V0H4V2ZM16 4V14H18V4H16ZM14 16H4V18H14V16ZM2 14V4H0V14H2ZM4 16C2.89543 16 2 15.1046 2 14H0C0 16.2091 1.79086 18 4 18V16ZM16 14C16 15.1046 15.1046 16 14 16V18C16.2091 18 18 16.2091 18 14H16ZM14 2C15.1046 2 16 2.89543 16 4H18C18 1.79086 16.2091 0 14 0V2ZM4 0C1.79086 0 0 1.79086 0 4H2C2 2.89543 2.89543 2 4 2V0ZM5.5 1V17H7.5V1H5.5ZM1.5 7.5H16.5V5.5H1.5V7.5Z"
        fill={color}
        strokeWidth={0}
      />
    </svg>
  )
}
