import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import FileInput, { FileInputProps, FileInputOptions } from './FileInput'
import { InputProps } from 'ra-core'

const MAX_IMAGE_SIZE_MB = 4

const useStyles = makeStyles(
  (theme) => ({
    root: { width: '100%' },
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {
      display: 'inline-block',
    },
    removeButton: {
      display: 'inline-block',
      position: 'relative',
      float: 'left',
      '& button': {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        minWidth: theme.spacing(2),
        opacity: 0,
      },
      '&:hover button': {
        opacity: 1,
      },
    },
  }),
  { name: 'RaImageInput' }
)

const ImageInput = (props: FileInputProps & InputProps<FileInputOptions>) => {
  const classes = useStyles(props)
  const [showError, setError] = useState(false)
  const { maxSize, options, ...rest } = props
  const defaultOptions = {
    onDropRejected: () => setError(true),
    onDrop: () => showError && setError(false),
  }
  const FileInputOptions = { ...defaultOptions, ...options }
  return (
    <>
      <FileInput
        labelMultiple="ra.input.image.upload_several"
        labelSingle="ra.input.image.upload_single"
        classes={classes}
        maxSize={maxSize}
        options={FileInputOptions}
        {...rest}
      />
      {showError && (
        <div
          style={{
            background: 'rgb(253, 237, 237)',
            margin: '5px 0px',
            padding: '10px 16px',
            lineHeight: '1em',
            color: 'rgb(95, 33, 32)',
            borderRadius: 4,
            textAlign: 'left',
          }}
        >
          <ErrorIcon
            style={{ width: 22, height: 22, color: 'rgb(239 83 80)', verticalAlign: 'middle', marginRight: 10 }}
          />
          <span style={{ verticalAlign: 'middle' }}>
            Please enter a image file with a valid file size no larger than {MAX_IMAGE_SIZE_MB}Mb
          </span>
        </div>
      )}
    </>
  )
}

ImageInput.defaultProps = {
  maxSize: MAX_IMAGE_SIZE_MB * 1024 * 1024,
}

export default ImageInput
