import { RootState } from 'types/state'

export const SET_SEGEN_QUOTE_PROMPT = 'SET_SEGEN_QUOTE_PROMPT'

export type SegenQuotePromptState = {
  segenQuotePromptOpen: boolean
}

const initialState: SegenQuotePromptState = {
  segenQuotePromptOpen: false,
}

interface SegenQuotePromptAction {
  type: typeof SET_SEGEN_QUOTE_PROMPT
  segenQuotePromptOpen: boolean
}

export const segenQuotePromptActions = {
  setSegenQuotePrompt: (segenQuotePromptOpen: boolean) => ({
    type: SET_SEGEN_QUOTE_PROMPT,
    segenQuotePromptOpen,
  }),
}

export default (previousState: SegenQuotePromptState = initialState, action: SegenQuotePromptAction) => {
  if (action.type === SET_SEGEN_QUOTE_PROMPT) {
    previousState = {
      ...previousState,
      ...{
        segenQuotePromptOpen: action.segenQuotePromptOpen,
      },
    }
  }
  return previousState
}

export const segenQuotePromptSelectors = {
  getSegenQuotePromptOpen(state: RootState): boolean {
    return state.segenQuotePrompt.segenQuotePromptOpen
  },
}
