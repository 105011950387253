import { MenuItem, TextField } from '@material-ui/core'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Switch } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { IntegrationJsonType, StudioSystemType } from 'types/global'
import {
  DropdownInput,
  IntegrationData,
  NativeMountingID,
  NativeMountingInputs,
  PartnerAccessoriesID,
  RoofHookCalculationMethodOption,
  TextFieldInput,
} from 'types/mounting'
import { useDebouncedCallback } from 'util/Debounce'
import { partnerAccessoriesIDs } from './constants'
import getNativeMountingSystems from './mountingSystems'
import { partnerAccessories } from './partnerAccessories'

export function getRoofHookCalculationMethodInputs(
  system: StudioSystemType,
  spacingDisabled?: boolean
): NativeMountingInputs {
  let defaultValue = 'basic'
  let roofHookOptions = [{ label: 'None', value: 'basic' }] as RoofHookCalculationMethodOption[]
  let dropdownInputs: DropdownInput[] = []
  let textFieldInputs: TextFieldInput[] = []

  if (window.AccountHelper.getOrgCountryIso2() === 'GB') {
    defaultValue = 'mcs'
    roofHookOptions.push({
      label: 'MCS',
      value: 'mcs',
    })
  }

  dropdownInputs.push({
    label: 'Roof hook loading calculation',
    variableName: 'roofHookCalculationMethod',
    default: defaultValue,
    options: roofHookOptions,
  })

  if (!spacingDisabled) {
    if (window.AccountHelper.getOrgCountryIso2() === 'US') {
      textFieldInputs.push({
        type: 'number',
        label: 'Mounting Spacing (inches)',
        variableName: 'mountingSpacingInches',
        default: 48,
        min: 16,
      })
    } else {
      textFieldInputs.push({
        type: 'number',
        label: 'Mounting Spacing',
        variableName: 'mountingSpacing',
        default: 800,
        min: 200,
      })
    }
  }

  return {
    dropdownInputs,
    textFieldInputs,
  }
}

const initMountingData = (system: StudioSystemType, mountingName: NativeMountingID | PartnerAccessoriesID) => {
  if (!system.integration_json) {
    system.integration_json = {} as IntegrationJsonType
  }
  if (!system.integration_json[mountingName]) {
    system.integration_json[mountingName] = {}
  }
}

const handleApplyMountingSetting = (key, value, uuid, mountingName: NativeMountingID | PartnerAccessoriesID) => {
  var system = window.editor.selectedSystem
  initMountingData(system, mountingName)
  if (system.integration_json && system.integration_json[mountingName] && mountingName && key) {
    if (system.integration_json[mountingName] === undefined) {
      system.integration_json[mountingName] = { [key]: value }
    } else {
      system.integration_json[mountingName] = { ...system.integration_json[mountingName], [key]: value }
    }
  }
  if (partnerAccessoriesIDs.includes(mountingName as PartnerAccessoriesID)) {
    window.editor.signals.objectChanged.dispatch(system, 'partner_accessories')
  } else {
    window.editor.signals.objectChanged.dispatch(system, 'mounting')
  }
}

const useStyles = makeOpenSolarStyles((theme) => ({
  switchWrapper: {
    display: 'flex',
    marginTop: '15px',
    maxWidth: '230px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

type MountingInputsProps = {
  mountingName: NativeMountingID
  allowEdit: boolean
}

type PartnerAccessoryInputsProps = {
  partnerAccessory: PartnerAccessoriesID
  allowEdit: boolean
}

// Sometimes the input options change (eg. if the option is incompatible with other selections) so override the selected input if it is no longer an option
const saveDefaultIfOptionNoLongerExists = (form, system, mountingName) => {
  const mountingJson = system?.integration_json ? system?.integration_json[mountingName] : undefined
  const nativeMountingSystems = getNativeMountingSystems()
  const inputs = nativeMountingSystems[mountingName]?.getInputs(form, system)
  const dropdownInputs = inputs?.dropdownInputs
  if (dropdownInputs) {
    dropdownInputs.forEach((input) => {
      if (mountingJson && mountingJson[input.variableName] && mountingJson[input.variableName]) {
        const selectedInputIsVisible = input.options.filter(
          (option) => option.value === mountingJson[input.variableName]
        ).length
        if (!selectedInputIsVisible) {
          // save the new default input value
          handleApplyMountingSetting(input.variableName, input.default, window.editor.selectedSystem.uuid, mountingName)
        }
      }
    })
  }
}

export const saveInputs = (form, system) => {
  const mountingName = system.mounting
  const nativeMountingSystems = getNativeMountingSystems()
  const inputs = nativeMountingSystems[mountingName]?.getInputs(form, system)
  const mountingJson = system?.integration_json ? system?.integration_json[mountingName] : undefined
  const dropdownInputs = inputs?.dropdownInputs
  const textFieldInputs = inputs?.textFieldInputs
  saveAllDefaultInputs(dropdownInputs, textFieldInputs, mountingJson, mountingName) // Set default if input value is undefined
  saveDefaultIfOptionNoLongerExists(form, system, mountingName) // set default if input value is no longer compatible
}

function saveAllDefaultInputs(dropdownInputs, textFieldInputs, mountingJson, mountingName) {
  return [...(dropdownInputs || []), ...(textFieldInputs || [])].forEach((input) => {
    saveDefaultInputState(input, mountingJson, mountingName)
  })
}

function saveDefaultInputState(input, mountingJson, mountingName) {
  if (typeof mountingJson === 'undefined' || typeof mountingJson[input.variableName] === 'undefined') {
    handleApplyMountingSetting(input.variableName, input.default, window.editor.selectedSystem.uuid, mountingName)
  }
}

type InputsProps = {
  inputs: { dropdownInputs?: DropdownInput[]; textFieldInputs?: TextFieldInput[] }
  mountingJson: any
  allowEdit: boolean
  integrationName: NativeMountingID | PartnerAccessoriesID
}

const Inputs: FC<InputsProps> = ({ inputs, mountingJson, allowEdit, integrationName }) => {
  const system = window.editor.selectedSystem
  const translate = useTranslate()
  const dropdownInputs = inputs?.dropdownInputs
  const textFieldInputs = inputs?.textFieldInputs

  return (
    <>
      {dropdownInputs &&
        dropdownInputs.map((input) => {
          let inputValue =
            typeof mountingJson !== 'undefined' && typeof mountingJson[input.variableName] !== 'undefined'
              ? mountingJson[input.variableName]
              : input.default

          return (
            <MountingInputFieldWrapper
              key={input.variableName}
              input={input}
              systemUuid={system.uuid}
              mountingJson={mountingJson}
              mountingName={integrationName}
            >
              <CustomSelectField
                className={'mounting-dropdown-input'}
                id={`${input.variableName}`}
                label={translate(input.label)}
                value={inputValue}
                style={{ flex: '1 1', width: 230 }}
                selectStyle={{ marginTop: 20 }}
                disabled={!allowEdit}
                onChange={(event) => {
                  handleApplyMountingSetting(
                    input.variableName,
                    event.target.value,
                    window.editor.selectedSystem.uuid,
                    integrationName
                  )
                }}
              >
                {input.options.map((option, index) => (
                  <MenuItem
                    key={option.value as any} // MenuItem is typed to not include boolean
                    value={option.value as any}
                    className={'mounting-dropdown-option'}
                    id={`${option.value}`}
                  >
                    {typeof option.label === 'string' ? translate(option.label) : option.label}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </MountingInputFieldWrapper>
          )
        })}

      {textFieldInputs &&
        textFieldInputs.map((input, index) => (
          <MountingInputFieldWrapper
            key={`${input.variableName}_${index}`}
            input={input}
            systemUuid={system.uuid}
            mountingJson={mountingJson}
            mountingName={integrationName}
          >
            <MountingTextField
              input={input}
              mountingName={integrationName}
              mountingJson={mountingJson}
              allowEdit={allowEdit}
            />
          </MountingInputFieldWrapper>
        ))}
    </>
  )
}

const MountingInputFieldWrapper = ({
  children,
  input,
  systemUuid,
  mountingJson,
  mountingName,
}: {
  children: React.ReactNode
  input: (DropdownInput | TextFieldInput) &
    Partial<Extract<DropdownInput & TextFieldInput, DropdownInput | TextFieldInput>>
  systemUuid: string
  mountingJson: any
  mountingName: NativeMountingID | PartnerAccessoriesID
}): JSX.Element => {
  useEffect(() => {
    if (mountingJson && mountingJson[input.variableName]) {
      const matchingOption = input.options?.filter((option) => option.value === mountingJson[input.variableName])
      if (!matchingOption?.length) {
        handleApplyMountingSetting(input.variableName, input.default, systemUuid, mountingName)
      }
      return
    }
    handleApplyMountingSetting(input.variableName, input.default, systemUuid, mountingName)
  }, [input.variableName, input.default])
  return <>{children}</>
}

export const MountingInputs: FC<MountingInputsProps> = ({ mountingName, allowEdit }) => {
  const nativeMountingSystems = getNativeMountingSystems()
  const form = useForm()
  const system = window.editor.selectedSystem
  const inputs = nativeMountingSystems[mountingName]?.getInputs(form, system)
  const translate = useTranslate()
  const classes = useStyles()
  const mountingJson = system?.integration_json ? system?.integration_json[mountingName] : undefined
  const autoRackingEnabled = typeof mountingJson?.enabled !== 'undefined' ? mountingJson.enabled : false

  return (
    <>
      <div className={classes.switchWrapper}>
        <div>{translate('Auto-Racking')}</div>
        <div id="auto-racking-toggle-container">
          <Switch
            checked={autoRackingEnabled}
            disabled={!allowEdit}
            id="auto-racking-toggle"
            onChange={(e) => {
              handleApplyMountingSetting('enabled', e.target.checked, window.editor.selectedSystem.uuid, mountingName)
            }}
          />
          {autoRackingEnabled ? translate('On') : translate('Off')}
        </div>
      </div>
      <Inputs inputs={inputs} mountingJson={mountingJson} integrationName={mountingName} allowEdit={allowEdit} />
    </>
  )
}

export const PartnerAccessoryInputs: FC<PartnerAccessoryInputsProps> = ({ partnerAccessory, allowEdit }) => {
  const form = useForm()
  const system = window.editor.selectedSystem
  const inputs = partnerAccessories[partnerAccessory]?.getInputs(form, system)
  const mountingJson = system?.integration_json ? system?.integration_json[partnerAccessory] : undefined

  return <Inputs inputs={inputs} mountingJson={mountingJson} integrationName={partnerAccessory} allowEdit={allowEdit} />
}

type TextFieldProps = {
  input: TextFieldInput
  mountingName: NativeMountingID | PartnerAccessoriesID
  mountingJson: IntegrationData | undefined
  allowEdit: boolean
}

const MountingTextField: FC<TextFieldProps> = ({ input, mountingName, mountingJson, allowEdit }) => {
  const translate = useTranslate()
  const [value, setValue] = useState(
    typeof mountingJson !== 'undefined' && typeof mountingJson[input.variableName] !== 'undefined'
      ? mountingJson[input.variableName]
      : input.default
  )

  const handleChangeDebounced = useDebouncedCallback((newValue) => {
    if (input.type === 'number') {
      if (input.min !== undefined && Number(newValue) < input.min) {
        newValue = input.min.toString()
      } else if (input.max !== undefined && Number(newValue) > input.max) {
        newValue = input.max.toString()
      }
      setValue(newValue)
    }
    handleApplyMountingSetting(input.variableName, newValue, window.editor.selectedSystem.uuid, mountingName)
  }, 500)

  return (
    <TextField
      type={input.type}
      label={translate(input.label)}
      value={value}
      style={{ flex: '1 1', width: 215, margin: 15 }}
      disabled={!allowEdit}
      onChange={(event) => {
        let newValue = event.target.value
        setValue(newValue)
        handleChangeDebounced(newValue)
      }}
    />
  )
}
