import { Reducer } from 'redux'
import { CUSTOM_QUERY_RESET, CUSTOM_QUERY_SUCCESS } from '../../actions'

export interface State {
  [key: string]: any
}

// reducer for queries called via useQueryWithStore and without a custom action name
const customQueriesReducer: Reducer<State> = (previousState = {}, { type, requestPayload, payload, meta }) => {
  if (type === CUSTOM_QUERY_RESET) {
    return {}
  }

  if (type === CUSTOM_QUERY_SUCCESS) {
    const key = JSON.stringify({
      type: meta.fetchResponse,
      resource: meta.resource,
      payload: requestPayload,
    })
    return {
      ...previousState,
      [key]: payload,
    }
  }

  return previousState
}

export default customQueriesReducer
