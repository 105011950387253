import { Action } from '../core/action'

export const preferences = {
  // State
  // route: new ShallowState<Route | undefined>(undefined),
  // path: new Value<string | undefined>(undefined),

  // Actions
  setString: new Action<[string, string]>(),
  setObject: new Action<[string, object]>(),
} as const
