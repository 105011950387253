import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const BarChartIconSvg: FC<IconProps> = (props) => {
  const { fill, height, width } = props

  return (
    <svg viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bar_chart" clipPath="url(#clip0_2314_5132)">
        <path
          id="Vector"
          d="M6.6665 12.2667H10.6665V25.3334H6.6665V12.2667ZM14.1332 6.66675H17.8665V25.3334H14.1332V6.66675ZM21.5998 17.3334H25.3332V25.3334H21.5998V17.3334Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_5132">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}

export const BarChartIcon = createStandardIcon(BarChartIconSvg)
