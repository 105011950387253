import { ButtonGroupProps as Mui4ButtonGroupProps, default as Mui4ButtonGroup } from '@material-ui/core/ButtonGroup'
import { ButtonGroupProps as Mui5ButtonGroupProps, default as Mui5ButtonGroup } from '@mui/material/ButtonGroup'
import { FC, forwardRef } from 'react'
import { useComponentVersion } from '../hooks'
import { OsButtonVersion } from './Button'

export type ButtonGroupProps = Omit<Mui5ButtonGroupProps, 'classes'>

const V3_BUTTON_COLORS = ['info', 'error', 'warning', 'success']

export const ButtonGroup: FC<ButtonGroupProps> = forwardRef((props, ref) => {
  let version = useComponentVersion('button')

  switch (version) {
    case OsButtonVersion.V3:
      return <Mui5ButtonGroup ref={ref} {...props}></Mui5ButtonGroup>
    default:
      const mui4Props: Mui4ButtonGroupProps = {
        ...props,
        color:
          !props.color || V3_BUTTON_COLORS.includes(props.color)
            ? 'secondary'
            : (props.color as Mui4ButtonGroupProps['color']),
      }
      return <Mui4ButtonGroup ref={ref} {...mui4Props}></Mui4ButtonGroup>
  }
})
