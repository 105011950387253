import { setImageryAutoSelectStatus } from 'actions/designer'
import { viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import { ToolbarDrawingToolsName } from 'projectSections/sections/design/tools/advanced/ToolbarAdvanced'
import { extractImageryTypeDefaultIfMatchFound } from 'projectSections/sections/design/util/extractImageryTypeDefaultIfMatchFound'
import { hasSystems } from 'projectSections/sections/design/util/hasSystems'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { imagerySelectors } from 'reducer/designer/detectImagery'
import { MapDataTypes } from 'types/map'
import { RootState } from 'types/state'
import { DesignMode } from 'types/studio/map'
import { closablePanels } from 'util/closablePanels'
import { tryAddPremiumImageryIfRequiredAndNotify } from 'util/imagery'
import { useDesignModesFiltered } from './useDesignModesFiltered'

export const useAutoSelectDesignImagery = (
  enabled: boolean,
  opts?: {
    setAppliedDesignMode?: (designMode: DesignMode) => void
    allowSelectWhenPanelOpen?: boolean
  }
) => {
  const dispatch = useDispatch()
  const tour = useSelector((state: RootState) => state.tour?.tour)
  const availableImageryTypes = useSelector((state: RootState) => state.designer?.detectImagery?.availableMapTypes)
  const timezoneOffset = useSelector((state: RootState) => state.designer?.detectImagery?.timezoneOffset || 0)
  const defaultImageryStrategy = useSelector(imagerySelectors.getDefaultImageryStrategy)
  const designModeState = useSelector(viewModeSelectors.designModeState)
  const designModesFiltered = useDesignModesFiltered()
  const notify = useNotify()
  const translate = useTranslate()
  const section = useSelector((state: RootState) => state.project.section)
  const showNotifications = useRef(false)
  showNotifications.current = section === 'design'

  const startDesignModeAndEnablePremiumImageryIfNecessary = async (defaultImageryTypeData: MapDataTypes) => {
    dispatch(setImageryAutoSelectStatus('working'))
    if (showNotifications.current) notify('Auto-applying default imagery type preference', 'success')

    let sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()

    // This assumes premiumImageryActivations is empty but could there be scenarios where this is incorrect?
    const isActivated = await tryAddPremiumImageryIfRequiredAndNotify(
      defaultImageryTypeData,
      [],
      [window.WorkspaceHelper.project.lon, window.WorkspaceHelper.project.lat],
      showNotifications.current ? notify : undefined,
      translate
    )

    if (isActivated) {
      window.SceneHelper.startDesignMode(
        sceneOrigin4326,
        window.WorkspaceHelper.project.country_iso2,
        window.WorkspaceHelper.project.state,
        defaultImageryTypeData,
        timezoneOffset
      )
      opts?.setAppliedDesignMode?.(defaultImageryTypeData.design_mode)
    }
    dispatch(setImageryAutoSelectStatus(isActivated ? 'idle' : 'blocked'))
  }

  useEffect(() => {
    if (!enabled || !window.WorkspaceHelper.project || !section) return

    // @TODO: Rebuild this hack
    var isReplay = window.location.hash.includes('/replay/')
    if (isReplay) {
      return
    }

    // Don't auto-select imagery if the user has explicitly opened the design mode panel
    if (designModeState === 'open-interactive' && !opts?.allowSelectWhenPanelOpen) {
      return
    }

    // // Check hasSystems() again because time may have passed since autoApply was first set before calling this async
    // Do not auto-apply during FUJI tour
    var autoApply = !Boolean(tour)

    if (autoApply && !hasSystems()) {
      // if we find a matching default imagery type then apply it here, otherwise force dialog open
      var defaultImageryTypeData = extractImageryTypeDefaultIfMatchFound(
        availableImageryTypes,
        undefined,
        defaultImageryStrategy
      )
      const enabledDesignModes = designModesFiltered.map((m) => m.key)
      // do not auto-apply premium imagery or disabled
      if (
        defaultImageryTypeData &&
        !defaultImageryTypeData?.variation_data?.require_wallet_product?.activation_requires_permission &&
        !defaultImageryTypeData?.disabled_message &&
        enabledDesignModes.includes(defaultImageryTypeData.design_mode)
      ) {
        startDesignModeAndEnablePremiumImageryIfNecessary(defaultImageryTypeData)
        dispatch(viewModeActions.setDesignModeState('closed-interactive'))
      } else {
        closablePanels.close(ToolbarDrawingToolsName)
        dispatch(viewModeActions.setDesignModeState('open-locked'))
      }
    } else {
      if (!designModeState) {
        //initialize openMode
        dispatch(viewModeActions.setDesignModeState('closed-interactive'))
      }
    }
  }, [availableImageryTypes, enabled, !section])
}
