import { FC } from 'react'

/**
 * * Icon Component for: file-check-01, file-check-02
 */
type FileCheckOutlineIconProps = {
  variant: 1 | 2
  size?: number
  color?: string
}
export const FileCheckOutlineIcon: FC<FileCheckOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  switch (variant) {
    case 1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M8.89922 20.6H4.09921C2.77372 20.6 1.69921 19.5254 1.69922 18.2L1.69931 3.80001C1.69932 2.47453 2.77383 1.40002 4.09931 1.40002H14.8996C16.2251 1.40002 17.2996 2.47454 17.2996 3.80002V10.4M12.4996 17.2L14.6996 19.4L20.2996 13.4"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" fill="none" {...rest} {...iconAttributes}>
          <path
            d="M8.29961 20.6H3.4996C2.17411 20.6 1.0996 19.5254 1.09961 18.2L1.0997 3.80001C1.09971 2.47453 2.17422 1.40002 3.4997 1.40002H14.3C15.6255 1.40002 16.7 2.47454 16.7 3.80002V10.4M11.9 17.2L14.1 19.4L18.9 14.5998M5.29998 6.20002H12.5M5.29998 9.80002H12.5M5.29998 13.4H8.89998"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
