import { Card, FormControlLabel, Tooltip } from '@material-ui/core'
import { DeleteOutlined, EditOutlined, PersonOutline } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Grid, Switch, Typography, styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getOwnerCompanyRepData, saveOnboardingProgress } from '../ownerAndBankInfo/utils'
import { BlueSnapOwnerType, BlueSnapRepType, FieldPrefixFilterType } from '../types'
import { getContactErrorMessage, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'

type PropTypes = {
  contactData: BlueSnapOwnerType | BlueSnapRepType
  newFieldPrefix: FieldPrefixFilterType
  fieldPrefix: FieldPrefixFilterType
  fieldIndex: number | null // used for owners (owner1 vs owner 2, etc) and corresponds to the index in the ownership array
  isUniqueRep?: boolean // used to say this contact is a uniue rep (so not an owner as well)
  hasUniqueRep: boolean // used to say that ANY contact is a unique rep
  setFieldPrefixFilter: (val: FieldPrefixFilterType) => void
  setContactForm: (ContactFormState) => void
}

const StyledCard = styled(Card)({
  flex: 1,
  padding: '1rem',
  borderRadius: '8px',
  textAlign: 'left',
  borderWidth: '1px !important',
})

const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
})

const IconWrapper = styled('span')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey[700],
    margin: '0px 5px',
    cursor: 'pointer',
  },
}))

const StyledAccountIcon = styled(PersonOutline)(({ theme }) => ({
  color: theme.palette.grey[700],
  margin: '0px 5px',
  cursor: 'pointer',
}))

const CardHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
})

const CardHeaderIconsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const ContactDescription = styled('div')({
  margin: '10px 0 0 35px',
  minHeight: 33,
  alignContent: 'center',
})

const StyledFormControlLabel = styled(FormControlLabel)({
  lineHeight: 1.5,
  margin: '0 0 0 35px',
  fontSize: '13px',
  width: '80%',
  display: 'flex',
  justifyContent: 'space-between',
})

const ContactCard: React.FC<PropTypes> = ({
  contactData,
  newFieldPrefix,
  fieldPrefix,
  fieldIndex,
  isUniqueRep,
  setFieldPrefixFilter,
  hasUniqueRep,
  setContactForm,
}) => {
  const formVals = useFormState().values
  const form = useForm()
  const notify = useNotify()

  const translate = useTranslate()
  const normalizedData = getNormalizeBlueSnapContact(contactData)
  const orgId = useSelector(orgSelectors.getOrg)?.id

  let companyRep = formVals?.companyRep
  const [isRep, setIsRep] = useState<boolean>(false)

  const isCompanyRep = useMemo(() => {
    if (!companyRep) return false
    return getContactsAreEqual(normalizedData, getNormalizeBlueSnapContact(companyRep))
  }, [normalizedData, companyRep, formVals])

  const onRepToggle = useCallback(
    (e) => {
      if (hasUniqueRep) {
        return
      } else {
        setIsRep(!isRep)
        if (e.target.checked) {
          const companyRepData = getOwnerCompanyRepData(normalizedData)
          form.change('companyRep', companyRepData)
          saveContactChanges('toggleRep')
        } else {
          form.change('companyRep', {})
          saveContactChanges('removeRep')
        }
      }
    },

    [isRep, normalizedData, hasUniqueRep]
  )

  const errorMsg = useMemo(() => {
    return getContactErrorMessage(normalizedData)
  }, [normalizedData])

  const handleDelete = () => {
    if (fieldPrefix === 'companyRep') {
      form.change('companyRep', null)
      saveContactChanges('removeRep')
    } else {
      let filtered = formVals?.ownershipInfoArr?.filter((owner, i) => {
        return i !== fieldIndex
      })
      form.change('ownershipInfoArr', filtered)
      if (!hasUniqueRep) form.change('companyRep', undefined)
      saveContactChanges('delete')
    }
  }

  const saveContactChanges = (action) => {
    const updatedFormVals = form.getState().values
    console.log(updatedFormVals, 'updated', action)
    if (orgId && fieldPrefix) {
      saveOnboardingProgress(orgId, updatedFormVals, fieldPrefix)
        .then((res) => {
          const message = action === 'delete' ? translate('Contact deleted!') : translate('Contact updated!')
          notify(message, 'success')
        })
        .catch((err) => {
          if (action !== 'delete') {
            if (action === 'removeRep') {
              const companyRepData = getOwnerCompanyRepData(normalizedData)
              form.change('companyRep', companyRepData)
            } else if (action === 'toggleRep') {
              form.change('companyRep', null)
            }
            setIsRep((isRep) => !isRep)
          }

          notify(err?.body?.message, 'warning')
          logAmplitudeEvent('psp_onboarding_next_page_error', { error_message: err?.body?.message })
        })
    }
  }

  const handleEdit = () => {
    setFieldPrefixFilter(fieldPrefix)
    setContactForm({ showForm: true, mode: 'edit' })
  }

  if (newFieldPrefix) return null
  return (
    <Grid item xs={12} md={6}>
      <StyledCard>
        <CardHeaderContainer>
          <HeaderWrapper>
            <StyledAccountIcon />
            <Typography textVariant="subtitle1">
              {normalizedData.firstName} {normalizedData.lastName}
            </Typography>
          </HeaderWrapper>
          <CardHeaderIconsWrapper>
            <IconWrapper>
              <EditOutlined onClick={handleEdit} />
              <DeleteOutlined onClick={handleDelete} />
            </IconWrapper>
          </CardHeaderIconsWrapper>
        </CardHeaderContainer>
        {isUniqueRep ? (
          <ContactDescription>
            <Typography textVariant="helper1" colorHex="#4D4D4D">
              {translate(
                'This is an individual who oversees the business and has significant control over the entities'
              )}
            </Typography>
          </ContactDescription>
        ) : (
          <ContactDescription>
            <Typography textVariant="helper1" colorHex="#4D4D4D">
              {translate('This is a person who owns 25% or more of your business')}
            </Typography>
          </ContactDescription>
        )}

        {hasUniqueRep ? (
          <Tooltip
            title={translate(
              '%{name} has already been entered as the designated Company Representative. If you would like to switch the Company Representative please delete %{name}',
              { name: companyRep.companyRepFirstName + ' ' + companyRep.companyRepLastName }
            )}
            enterDelay={300}
          >
            <StyledFormControlLabel
              value="companyRep"
              control={
                <Switch
                  color="secondary"
                  onChange={onRepToggle}
                  checked={isCompanyRep}
                  disabled={isCompanyRep ? false : true}
                />
              }
              label={translate('Company Rep')}
              labelPlacement="start"
            />
          </Tooltip>
        ) : (
          <StyledFormControlLabel
            value="companyRep"
            control={<Switch color="secondary" onChange={onRepToggle} checked={isCompanyRep} disabled={hasUniqueRep} />}
            label={translate('Company Rep')}
            labelPlacement="start"
          />
        )}

        {errorMsg && <Alert severity="error">{translate(errorMsg)}</Alert>}
      </StyledCard>
    </Grid>
  )
}
export default ContactCard
