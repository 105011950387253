import type { QUERY_ORDER } from 'elements/controller/query/query'
import { GET_LIST, Identifier, RecordMap, useGetList } from 'react-admin'

/*
The basic hook for fetching hardware components data from endpoint 
*/

// Note: consider to combine this hook with useOrderComponentsListBasic
const useHardwareSelectorFetchEngine = ({
  page,
  perPage,
  sort,
  order,
  resource,
  filterValues,
}: {
  page: number
  perPage: number
  sort: string
  order: QUERY_ORDER
  resource: string
  filterValues: { [key: string]: unknown }
}): {
  data?: RecordMap<unknown> // TODO: add type
  ids?: Identifier[]
  total?: number
  error?: any
  loading: boolean
  loaded: boolean
} => {
  // TODO: add type
  const { data, ids, total, loading, loaded } = useGetList<unknown>(
    resource,
    {
      page,
      perPage,
    },
    { field: sort, order },
    {
      ...filterValues,
    },
    {
      action: GET_LIST,
    }
  )

  return { data, ids, total, loading, loaded }
}

export default useHardwareSelectorFetchEngine
