import { Link } from '@mui/material'
import { styled } from '../../styles/styled'

const Wrapper = styled('div')(({ theme }) => {
  return {
    padding: '0px 10px',
  }
})
const LinkWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  }
})

const OrRow = styled('div')(({ theme }) => {
  return {
    height: '1px',
    width: '100%',
    borderBottom: '1px solid #CDCDCD',
    display: 'flex',
    justifyContent: 'center',
    margin: '6px 0px 10px 0px',
  }
})

const Or = styled('span')(({ theme }) => {
  return {
    marginTop: '-8px',
    backgroundColor: 'white',
    padding: '0px 8px',
    height: '12px',
  }
})

const StyledLink = styled(Link)(({ theme }) => {
  return {
    textDecoration: 'underline',
    padding: '14px',
    cursor: 'pointer',
    color: 'rgb(24, 144, 255)',
    '&:link, &:visited, &:hover, &:active': {
      color: 'rgb(24, 144, 255)',
    },
  }
})

export const DepositCtaLink = ({ label, onClick }: { label: string; onClick(): void }) => {
  return (
    <Wrapper>
      <LinkWrapper>
        <OrRow>
          <Or>or</Or>
        </OrRow>
        <div>
          <StyledLink onClick={onClick}>{label}</StyledLink>
        </div>
      </LinkWrapper>
    </Wrapper>
  )
}
