import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ShowNotificationAction, SHOW_NOTIFICATION } from 'ra-core'
import { takeEvery } from 'redux-saga/effects'

/**
 * This side-effect takes all notifications shown to the user and tracks them in amplitude.
 * This is to give us high level visibility on how often certain messages are being shown to the user.
 */
function handleNotification(context: ShowNotificationAction) {
  const payload = context.payload
  const error: Error | undefined = payload.error instanceof Error ? payload.error : undefined
  const data = {
    type: payload.type,
    message: payload.message,
    error_message: error?.message,
    error_name: error?.name,
    error_stack: error?.stack,
    location: window.location.hash,
  }
  logAmplitudeEvent('notification_shown', data, 'notification')
}

export function* amplitudeSideEffects() {
  yield takeEvery(
    // @ts-ignore
    (action) => action.type === SHOW_NOTIFICATION,
    handleNotification
  )
}
