import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import Search from './filtering/Search'
import PartnerCard, { Partner } from './PartnerCard'

interface PropTypes {
  partners: Partner[]
  handleSearch: (value: string) => void
}

const Wrapper = styled('div', { name: 'Wrapper' })({})

const Header = styled('h1', { name: 'Header' })({
  fontSize: 24,
})

const PartnerWrap = styled('div', { name: 'PartnerWrap' })({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
})

const Partners: FC<PropTypes> = ({ partners, handleSearch }) => {
  const translate = useTranslate()

  return (
    <Wrapper>
      <Header>
        {`${translate('Our Partners')} (${partners.length})`}
        <Search handleSearch={handleSearch} />
      </Header>
      {!!partners?.length ? (
        <PartnerWrap>
          {partners.map((partner) => (
            <PartnerCard key={'partner-' + partner.id} {...partner} />
          ))}
        </PartnerWrap>
      ) : (
        <div>{translate('No Expo Stands to display')}</div>
      )}
    </Wrapper>
  )
}

export default Partners
