import {
  default as Mui5ToggleButtonGroup,
  ToggleButtonGroupProps as Mui5ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup'
import { FC, forwardRef } from 'react'

// Removing size from the ToggleButtonGroup spec for now
// Removing 'classes' to prevent unwanted customisation
export type OsToggleButtonGroupProps = Omit<Mui5ToggleButtonGroupProps, 'classes' | 'size'>

export const ToggleButtonGroup: FC<OsToggleButtonGroupProps> = forwardRef(({ fullWidth, ...props }, ref) => {
  return <Mui5ToggleButtonGroup ref={ref} {...props} size="small" />
})

// extend the spec
declare module '@mui/material/ToggleButtonGroup' {
  interface IconButtonOwnProps {
    fullWidth?: boolean
  }
}
