import { FC } from 'react'
import Olark from 'react-olark-plugin'

interface PropTypes {
  apiKey: string
}

const Chat: FC<PropTypes> = ({ apiKey }) => {
  if (!apiKey) return null
  return <Olark siteId={apiKey} />
}

export default Chat
