import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import React, { Suspense } from 'react'
import Homepage from './homepage'

export const ExpoGlobalLandingPage: React.FC = () => {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <Homepage />
    </Suspense>
  )
}
