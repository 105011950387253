import { useVisibleStructLogic } from 'persistentContent/utils/useVisibleStructLogic'
import { useMemo } from 'react'

export const usePromoDialogLogic = () => {
  const {
    open,
    setOpen,
    currentKey: currentPromoKey,
    setCurrentKey: setCurrentPromoKey,
    forceHide,
    forceCheck,
    setForceCheck,
    currentIndex,
    currentItem: currentPromo,
    structsToDisplay: structs,
    showPromo,
    trackDismissed,
    trackAllDismissed,
    trackingKey,
  } = useVisibleStructLogic('promo-dialog')

  // Blocker promos don't show up in the pagination
  const navigableStructs = useMemo(() => {
    return structs.filter((struct) => struct.data.type !== 'blocker')
  }, [structs])

  const backEnabled = useMemo(() => {
    const index = navigableStructs.findIndex((struct) => struct.key === currentPromoKey)
    return !(index === -1 || index === 0)
  }, [currentPromoKey, navigableStructs])

  const nextWillClose = useMemo(() => {
    const index = navigableStructs.findIndex((struct) => struct.key === currentPromoKey)
    return !!(index === -1 || index === navigableStructs.length - 1)
  }, [currentPromoKey, navigableStructs])

  const onBack = () => {
    const newIndex = currentIndex - 1
    if (newIndex >= 0) {
      const struct = navigableStructs[newIndex]
      showPromo(struct)
    }
  }

  const onNext = () => {
    const newIndex = currentIndex + 1
    if (newIndex < navigableStructs.length) {
      const struct = navigableStructs[newIndex]
      showPromo(struct)
    } else {
      // clearing current Promo will close dialog
      trackAllDismissed()
      setOpen(false) // Depends on onCloseComplete to reset the current promo
    }
  }

  const onCloseComplete = () => {
    if (open && forceHide) {
      // Don't clear if this was just the result of forceHide
      return
    }
    setCurrentPromoKey(undefined)
    setForceCheck(forceCheck + 1)
  }

  return {
    open: open && (forceHide === undefined || !forceHide),
    onCloseComplete,
    currentIndex,
    navigableStructs,
    currentPromo,
    trackDismissed,
    onBack,
    onNext,
    backEnabled,
    nextWillClose,
    trackingKey,
  }
}
