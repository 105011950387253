import { FC } from 'react'
import type { IconProps } from './type'

interface CameraIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const CameraIcon: FC<CameraIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  const viewBox = `0 0 ${size} ${size}`
  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.20039 6.11999V7.11999C7.55606 7.11999 7.88496 6.93108 8.06417 6.62386L7.20039 6.11999ZM8.88039 3.23999V2.23999C8.52472 2.23999 8.19582 2.4289 8.01661 2.73612L8.88039 3.23999ZM15.1204 3.23999L15.9842 2.73612C15.805 2.4289 15.4761 2.23999 15.1204 2.23999V3.23999ZM16.8004 6.11999L15.9366 6.62386C16.1158 6.93108 16.4447 7.11999 16.8004 7.11999V6.11999ZM3.40039 18.36V8.51999H1.40039V18.36H3.40039ZM4.80039 7.11999H7.20039V5.11999H4.80039V7.11999ZM8.06417 6.62386L9.74417 3.74386L8.01661 2.73612L6.33661 5.61612L8.06417 6.62386ZM8.88039 4.23999H15.1204V2.23999H8.88039V4.23999ZM14.2566 3.74386L15.9366 6.62386L17.6642 5.61612L15.9842 2.73612L14.2566 3.74386ZM16.8004 7.11999H19.2004V5.11999H16.8004V7.11999ZM20.6004 8.51999V18.36H22.6004V8.51999H20.6004ZM20.6004 18.36C20.6004 19.1332 19.9736 19.76 19.2004 19.76V21.76C21.0782 21.76 22.6004 20.2378 22.6004 18.36H20.6004ZM19.2004 7.11999C19.9736 7.11999 20.6004 7.74679 20.6004 8.51999H22.6004C22.6004 6.64222 21.0782 5.11999 19.2004 5.11999V7.11999ZM3.40039 8.51999C3.40039 7.74679 4.02719 7.11999 4.80039 7.11999V5.11999C2.92262 5.11999 1.40039 6.64222 1.40039 8.51999H3.40039ZM4.80039 19.76C4.02719 19.76 3.40039 19.1332 3.40039 18.36H1.40039C1.40039 20.2378 2.92262 21.76 4.80039 21.76V19.76ZM14.6004 12.84C14.6004 14.2759 13.4363 15.44 12.0004 15.44V17.44C14.5409 17.44 16.6004 15.3805 16.6004 12.84H14.6004ZM12.0004 15.44C10.5644 15.44 9.40039 14.2759 9.40039 12.84H7.40039C7.40039 15.3805 9.45988 17.44 12.0004 17.44V15.44ZM9.40039 12.84C9.40039 11.404 10.5644 10.24 12.0004 10.24V8.23999C9.45988 8.23999 7.40039 10.2995 7.40039 12.84H9.40039ZM12.0004 10.24C13.4363 10.24 14.6004 11.404 14.6004 12.84H16.6004C16.6004 10.2995 14.5409 8.23999 12.0004 8.23999V10.24ZM19.2004 19.76H4.80039V21.76H19.2004V19.76Z"
        fill={color}
      />
    </svg>
  )
}
