import {
  feetToInches,
  ImperialUnits,
  inchesToFeet,
  inchesToMeters,
  metersToInches,
  MetricUnits,
} from 'Designer/common/helpers'
import { feetToMeters, metersToFeet } from 'util/misc'

type ParamsType = {
  from: MetricUnits | ImperialUnits
  to: MetricUnits | ImperialUnits
  trimDecimalPlaces?: number
}

const noConversion = (value: number) => value

// expand this decision tree, as necessary
// missing conversion functions: km to miles, miles to km, etc.
const MeasurementConversionTree = {
  [MetricUnits.Meters]: {
    [MetricUnits.Meters]: noConversion,

    [ImperialUnits.Feet]: metersToFeet,
    [ImperialUnits.Inches]: metersToInches,
  },

  [ImperialUnits.Feet]: {
    [MetricUnits.Meters]: feetToMeters,

    [ImperialUnits.Feet]: noConversion,
    [ImperialUnits.Inches]: feetToInches,
  },

  [ImperialUnits.Inches]: {
    [MetricUnits.Meters]: inchesToMeters,

    [ImperialUnits.Feet]: inchesToFeet,
    [ImperialUnits.Inches]: noConversion,
  },
}

export const useDistanceConverter = (params: ParamsType): ((value: number) => number) => {
  return MeasurementConversionTree[params.from][params.to]
}
