import { authSelectors } from 'ducks/auth'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { ActionType } from 'types/tracking'
import SolarAppCTA from './SolarAppCTA'
import SolarAppProjectManager from './SolarAppProjectManager'
import SolarAppProjectStatus from './SolarAppProjectStatus'

interface PropTypes {
  project: ProjectType
}

const SolarAppIntegration: React.FC<PropTypes> = ({ project }) => {
  const form = useForm()
  const solarapp_account_connected = useSelector(authSelectors.getIsSolarAppAccountConnected)
  const userId = useSelector(authSelectors.getCurrentUserId)

  let solarapp_permit = project?.custom_data?._solarapp_permit

  const { trackSystemEvent } = useTrackComponent({
    componentKey: 'solar_app_integration',
    eventName: 'User Viewed Solar App Integration',
  })

  const updateSolarAppProjectDetails = useCallback(
    (newId?: string, newUrl?: string) => {
      if (!newId || !newUrl) {
        form.change('custom_data._solarapp_permit', undefined)
        return
      }
      form.change('custom_data._solarapp_permit', {
        uuid: newId,
        url: newUrl,
      })
      trackSystemEvent(
        { type: ActionType.UPDATE },
        {
          eventName: 'SolarAPP Project Input Updated',
          metadata: {
            project: {
              uuid: newId,
            },
            userId,
          },
        }
      )
    },
    [form, project.id]
  )

  if (!solarapp_account_connected) {
    return <SolarAppCTA />
  }

  return (
    <>
      {/* SolarAPP+ account is connected */}
      {solarapp_permit ? (
        // SolarAPP+ project exists
        <SolarAppProjectStatus project={project} updateSolarAppProjectDetails={updateSolarAppProjectDetails} />
      ) : (
        // SolarAPP+ project does not exist
        <SolarAppProjectManager project={project} updateSolarAppProjectDetails={updateSolarAppProjectDetails} />
      )}
    </>
  )
}

export default SolarAppIntegration
