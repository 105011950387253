import { AcceptProposalButton, AcceptProposalLink, ProposalAccepted, ProposalAcceptedLink } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type { AcceptProposalType, CheckoutActionCallback, ProposalDataType } from '../../types'
import { getAcceptProposalText } from '../../util'

type AcceptProposalStatus = 'accepted_link' | 'accepted_button' | 'hidden' | 'available_link' | 'available_button'

const useAcceptProposalStatus = ({ status_code, variation, proposalData }): AcceptProposalStatus => {
  if (status_code === 'hidden') {
    return 'hidden'
  }
  if (status_code === 'complete' && variation === 'link') {
    return 'accepted_link'
  }
  if (proposalData.selectedProject?.project_sold || !proposalData.checkoutHidden) {
    if (status_code === 'complete') {
      return 'accepted_button'
    } else if (variation === 'link') {
      return 'available_link'
    } else {
      return 'available_button'
    }
  }
  return 'hidden'
}

const AcceptProposal = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: AcceptProposalType
  translate: Translate
  proposalData: ProposalDataType
  options?: {
    isLoading?: boolean
  }
}) => {
  const { actionData, variation, loanType } = elementData
  const status = useAcceptProposalStatus({ status_code: actionData.status_code, variation, proposalData })
  const handleAction = () => {
    onAction({ elementData, proposalData })
  }
  const isLoading = options?.isLoading ? true : false
  if (status === 'hidden') {
    return null
  } else if (status === 'accepted_link') {
    return <ProposalAcceptedLink textColor={proposalData.myeStyles.textColor} />
  } else if (status === 'accepted_button') {
    return (
      <ProposalAccepted
        myeStyles={proposalData.myeStyles}
        isLoading={isLoading}
        buttonLabel={
          translate(
            getAcceptProposalText(
              actionData.review_action_title,
              actionData.quote_acceptance_content,
              actionData.payment_amount
            )
          ) + ' ✓'
        }
        acceptedLinkLabel={translate('View Statement')}
        onAction={handleAction}
      />
    )
  } else if (status === 'available_link') {
    return (
      <AcceptProposalLink
        myeStyles={proposalData.myeStyles}
        isLoading={isLoading}
        label={translate(
          getAcceptProposalText(
            actionData.review_action_title,
            actionData.quote_acceptance_content,
            actionData.payment_amount
          )
        )}
        onAction={handleAction}
      />
    )
  } else if (status === 'available_button') {
    return (
      <AcceptProposalButton
        myeStyles={proposalData.myeStyles}
        isLoading={isLoading}
        label={translate(
          getAcceptProposalText(
            actionData.review_action_title,
            actionData.quote_acceptance_content,
            actionData.payment_amount
          )
        )}
        disabled={actionData.status_code === 'disabled'}
        onAction={handleAction}
      />
    )
  }

  return null
}

export default AcceptProposal
