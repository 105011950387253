import React, { FC } from 'react'

/**
 * * Icon Component for: hourglass-01, hourglass-02, hourglass-03
 */
type HourGlassOutlineIconProps = {
  variant: 1 | 2 | 3
  size?: number
  color?: string
}
export const HourGlassOutlineIcon: FC<HourGlassOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M2.02128 19H11.9787C12.5428 19 13 18.5395 13 17.9714V15.2237C13 15.0042 12.9302 14.7904 12.8009 14.6136L9.85578 10.5881C9.59813 10.2359 9.58984 9.75815 9.8351 9.39712L12.5663 5.3769C12.6825 5.20582 12.7447 5.00337 12.7447 4.79608V2.02857C12.7447 1.46051 12.2874 1 11.7234 1H2.02128C1.45724 1 1 1.46051 1 2.02857V4.77626C1 4.99584 1.06977 5.20964 1.19909 5.38641L4.12807 9.38985C4.39353 9.75269 4.39353 10.2473 4.12807 10.6101L1.19909 14.6136C1.06977 14.7904 1 15.0042 1 15.2237V17.9714C1 18.5395 1.45724 19 2.02128 19Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 2:
      return (
        <svg viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M4.57447 15.9143H9.42553M11.9787 19H2.02128C1.45724 19 1 18.5395 1 17.9714V15.2237C1 15.0042 1.06977 14.7904 1.19909 14.6136L4.12807 10.6101C4.39353 10.2473 4.39353 9.75269 4.12807 9.38985L1.19909 5.38641C1.06977 5.20964 1 4.99584 1 4.77626V2.02857C1 1.46051 1.45724 1 2.02128 1H11.7234C12.2874 1 12.7447 1.46051 12.7447 2.02857V4.79608C12.7447 5.00337 12.6825 5.20582 12.5663 5.3769L9.8351 9.39712C9.58984 9.75815 9.59813 10.2359 9.85578 10.5881L12.8009 14.6136C12.9302 14.7904 13 15.0042 13 15.2237V17.9714C13 18.5395 12.5428 19 11.9787 19Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 3:
      return (
        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M6 3H20M6 21H20M18.25 3V5.64723C18.25 6.33742 18.012 7.0065 17.5762 7.54167L14.7011 11.072C14.1922 11.6969 14.2043 12.5794 14.7301 13.1912L17.525 16.4423C17.9928 16.9865 18.25 17.6803 18.25 18.3979V21M7.75 3V5.64723C7.75 6.33742 7.98798 7.0065 8.42383 7.54167L11.2989 11.072C11.8078 11.6969 11.7957 12.5794 11.2699 13.1912L8.47505 16.4423C8.00724 16.9865 7.75 17.6803 7.75 18.3979V21"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
