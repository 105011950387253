import { CELL_COVERAGE_CHOICES } from 'projectSections/sections/info/siteDetails/constants'
import React from 'react'
import { NumberInput, SelectInput, TextInput } from 'react-admin'
import { getRange } from 'util/misc'
import { maxDigits } from 'validations'
import { InputRow } from '../styles'

const UtilityFields: React.FC = (props) => {
  return (
    <>
      <InputRow>
        <TextInput source={'meter_identifier'} variant="outlined" label={'Meter Identifier'} fullWidth />
        <NumberInput source={'power_factor'} variant="outlined" label={'Power Factor'} validate={maxDigits(6,2)} fullWidth />
        <SelectInput
          source={'has_cellular_coverage'}
          variant="outlined"
          label={'Cellular Coverage'}
          allowEmpty
          choices={CELL_COVERAGE_CHOICES}
          translateChoice={false}
          fullWidth
        />
        <SelectInput
          source={'number_of_phases'}
          variant="outlined"
          label={'Phases'}
          allowEmpty
          fullWidth
          choices={getRange(3).map((i) => ({ id: i, name: i ? i : '' }))}
          translateChoice={false}
        />
      </InputRow>
    </>
  )
}

export default UtilityFields
