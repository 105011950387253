import TransactionActionLink from '../TransactionActionLink'
import AcceptProposalWrapper from './AcceptProposalWrapper'

export const AcceptProposalLink = ({
  myeStyles,
  isLoading,
  label,
  onAction,
}: {
  isLoading: boolean
  label: string
  onAction(): void
  myeStyles: React.CSSProperties & { textColor: string; highlightColor: string }
}) => {
  return (
    <AcceptProposalWrapper textColor={myeStyles.textColor}>
      <TransactionActionLink
        isLoading={isLoading}
        textColor={myeStyles.highlightColor}
        label={label}
        onClick={onAction}
      />
    </AcceptProposalWrapper>
  )
}
