import StarIcon from '@material-ui/icons/Star'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import ComponentOverviewIcon from 'elements/hardwareSelector/ComponentOverview'
import { Box, Chip, ComponentVersionsInherit, Grid } from 'opensolar-ui'
import { ComponentCost } from 'projectSections/hooks/useComponentCost'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import { HardwareComponentType, HardwareListRowProps } from '../types'
import DistributorBlock from './DistributorBlock'
import DistributorBlockV2 from './DistributorBlockV2'
import DistributorDetails from './DistributorDetails'
import HardwareImage from './HardwareImage'
import ManufacturerBlock from './ManufacturerBlock'
import NameBlock from './NameBlock'
import PriceBlock from './PriceBlock'
import QuantityBlock from './QuantityBlock'
import RatingBlock from './RatingBlock'
import SelectButton from './SelectButton'
import { getComponentData } from './utility'

interface StyleProps {
  isExpandable: boolean
}

export const useHardwareListRowStyles = makeOpenSolarStyles((theme) => ({
  container: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:last-child .distributor-popover': {
      top: '-45px',
    },
  },
  accordion: {
    backgroundColor: 'inherit',
  },
  row: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
  },
  gridContainer: (props: StyleProps) => ({
    height: '100%',
    padding: '10px',
    paddingRight: props.isExpandable ? '60px' : '10px',
    '& > *': {
      minWidth: '0px',
      padding: '0px',
    },
  }),
  selectContainer: {
    '& > button': {
      minWidth: '0px',
    },
  },
  starIcon: {
    color: theme.openSolarYellow,
    left: 0,
    position: 'absolute',
    top: 0,
  },
  accordionDetails: {
    background: '#F8F8F8',
    padding: '14px 64px 18px 18px',
  },
  badgeWrapper: {
    marginTop: 5,
  },
}))

const HardwareListRow: React.FC<HardwareListRowProps<HardwareComponentType>> = ({
  allowCreate,
  record: component,
  onSelect,
  hiddenColumns,
}) => {
  const [costBySource, setCostBySource] = useState<ComponentCost | undefined>()

  const enabledMultiDistributorSelector = useFeatureFlag('design_multi_distributor', 'on')
  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'
  const orgName = useSelector(orgSelectors.getOrg)?.name
  const hasEnabledDistributors = (useSelector(orgSelectors.getEnabledHardwareSuppliers)?.length ?? 0) > 0
  // this is constantly false since accordion functionality removed for phase 1
  const isExpandable = false && component.ordering_v2.length > 1
  const componentType = component.type
  const componentCode = component.code

  const componentData = useMemo(() => getComponentData(component), [component])

  // We show the product image as long as the ordering_v2 has segen as a distributor
  const isProductOnSegen = component.ordering_v2.some(({ distributor }) => distributor === 'segen')
  const shouldShowProductImage =
    component.is_exhibitor || isProductOnSegen || component.ordering_v2.filter((v) => v.is_available).length > 0

  const productImage =
    component.ordering_v2?.find(({ image_url: imageUrl }) => imageUrl)?.image_url || component.promotional_image || ''

  const classes = useHardwareListRowStyles({ isExpandable })
  const distributorPricingRolePermission = useSelector(permissionsSelectors.getPermissionByKey('distributor_pricing'))

  const shouldDisplayPriceAndStocks = hasEnabledDistributors && distributorPricingRolePermission.allowView

  const Row = (
    <div className={classes.row}>
      <Grid alignItems="flex-start" className={classes.gridContainer} container xs={12}>
        {component.is_my_list && !component?.is_archived && <StarIcon className={classes.starIcon} />}
        <Grid item xs={1}>
          <HardwareImage imageUrl={shouldShowProductImage ? productImage : ''} />
        </Grid>
        <Grid item xs={2}>
          <ManufacturerBlock
            name={component.manufacturer_name}
            logoUrl={component.manufacturer_logo}
            isExhibitor={component.is_exhibitor}
            distributors={component.ordering_v2}
          />
          {component.is_exhibitor && (
            <div className={classes.badgeWrapper}>
              <ComponentVersionsInherit versions={ComponentVersions_3_0}>
                <Chip label="Showcase Partner" color="info" />
              </ComponentVersionsInherit>
            </div>
          )}
        </Grid>
        <Grid item xs={isAU ? 4 : 3}>
          <NameBlock
            name={component.code}
            // @ts-ignore
            helperIcon={<ComponentOverviewIcon component={{ ...component, ...(componentData || {}) }} />}
            series={component.ordering_v2?.find(({ short_description }) => short_description)?.short_description}
          />
        </Grid>
        {!hiddenColumns?.has('rating') && (
          <Grid item xs={shouldDisplayPriceAndStocks ? 1 : 4}>
            <RatingBlock componentData={componentData} componentType={componentType} />{' '}
          </Grid>
        )}
        {shouldDisplayPriceAndStocks && (
          <>
            {!isAU && (
              <Grid item xs={1}>
                <QuantityBlock distributors={component.ordering_v2} />
              </Grid>
            )}
            <Grid item xs={1}>
              <PriceBlock
                code={component.code}
                componentData={componentData}
                componentType={componentType}
                distributors={component.ordering_v2}
                costSource={costBySource}
              />
            </Grid>
            <Grid item xs={2}>
              {enabledMultiDistributorSelector ? (
                <DistributorBlockV2
                  distributors={component.ordering_v2}
                  onUpdateCostBySource={(source, price) => setCostBySource({ code: componentCode, source, price })}
                  componentType={componentType}
                  componentCode={componentCode}
                />
              ) : (
                <DistributorBlock distributors={component.ordering_v2} />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={1}>
          <Box className={classes.selectContainer} display="flex">
            <SelectButton
              enabled={allowCreate || component.is_my_list}
              orgName={orgName}
              handleSelect={() => onSelect(component, costBySource?.source || 'component', costBySource?.price || 0)}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div className={classes.container}>
      {isExpandable ? (
        <AccordionCard
          detailsClassName={classes.accordionDetails}
          disableGutters
          elevation={0}
          name={`component-${component.code}`}
          render={() => (
            <DistributorDetails
              code={component.code}
              componentType={componentType}
              // Ignored as isExpandable already ensures min. length of component.ordering_v2 is 2
              // @ts-ignore
              distributors={component.ordering_v2}
            />
          )}
          summaryContent={Row}
        />
      ) : (
        Row
      )}
    </div>
  )
}

export default memo(HardwareListRow)
