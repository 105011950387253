import { Button as Mui4Button, ButtonProps as Mui4ButtonPropsType, makeStyles } from '@material-ui/core'
import { ButtonProps as Mui5ButtonPropsType, default as Mui5Button } from '@mui/material/Button'
import React, { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks/useComponentVersion'

export const Button: FC<ButtonProps> = React.forwardRef((props, ref) => {
  return (
    <Inspector
      name="Button"
      propDefs={ButtonPropDefs}
      showExample={true}
      component={OsButton}
      props={{ ...props, ref }}
    />
  )
})

export enum OsButtonVersion {
  V1 = 1, // Base MUI
  V2 = 2, // Basic style fixes
  V3 = 3, // MUI5 + Stratis
}

const V3_BUTTON_COLORS = ['info', 'error', 'warning', 'success']
export type ButtonProps = Omit<Mui4ButtonPropsType, 'color'> & {
  color?: Mui5ButtonPropsType['color'] | Mui4ButtonPropsType['color']
}

// TODO: get rid of this
export type OsButtonProps = ButtonProps

const useStyles = makeStyles(
  {
    v2: {
      height: 'unset',
      padding: '8.5px 15px',

      '&.Mui4Button-sizeSmall': {
        height: 'unset',
        padding: '7px 12px',
      },

      '& .Mui4Button-label': {
        textTransform: 'none',
      },
    },
  },
  { name: 'OsButton' }
)

const OsButton: FC<ButtonProps> = forwardRef((props, ref) => {
  const classes = useStyles()

  let version = useComponentVersion('button')
  let { color, ...rest } = props
  switch (version) {
    case OsButtonVersion.V3:
      let color5: Mui5ButtonPropsType['color'] = color === 'default' ? 'secondary' : color
      return <Mui5Button ref={ref} color={color5} {...rest}></Mui5Button>

    default:
      const mui4Props: Mui4ButtonPropsType = {
        ...props,
        className: `${version === OsButtonVersion.V2 ? classes.v2 : ''} ${props.className ? props.className : ''}`,
        color: color && V3_BUTTON_COLORS.includes(color) ? 'default' : (color as Mui4ButtonPropsType['color']),
      }
      return <Mui4Button ref={ref} {...mui4Props}></Mui4Button>
  }
})

const ButtonPropDefs: PropsDefs = {
  variant: { default: 'text' },
  size: { default: 'medium' },
  color: { default: undefined },
  startIcon: { hidden: true },
  endIcon: { hidden: true },
  children: { hidden: true },
  fullWidth: { hidden: true },
}
