import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import get from 'lodash/get'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import type { ProposalDataType } from 'types/proposals'
import { detectProposalV2ProposalTemplate } from 'util/misc'

const isDesignValidForProposalV2 = (proposalData: ProposalDataType) => {
  const isSystemLimitExceeded = proposalData.systems.length > 6
  return isSystemLimitExceeded
}

const detectIfNeverChooseBefore = (proposalData: ProposalDataType, proposalFeatureConfig: any) => {
  const proposalConfig = proposalData.selectedProject?.custom_data?.proposal_config_v2
  const hasSelectedTemplate = get(proposalConfig, 'has_selected_template', false)
  // skip if it has selected once before
  if (hasSelectedTemplate) return false

  const projectCreateDate = moment(proposalData.selectedProject?.created_date)
  const showStartDate = moment(proposalFeatureConfig?.proposal_modal_start_date)
  return projectCreateDate >= showStartDate
}

// Auto open the modal if the user never choose the template before
export const useAutoOpenIfNeverChooseBefore = ({
  proposalData,
  open,
  autoOpen,
}: {
  proposalData: ProposalDataType
  open: boolean
  autoOpen(): void
}) => {
  const proposalFeatureConfig = usePublicFeatureConfig('proposal_v2')
  const openRef = useRef(open)
  openRef.current = open
  const proposalFeatureConfigRef = useRef(open)
  proposalFeatureConfigRef.current = proposalFeatureConfig

  const [isConfigReady, setIsConfigReady] = useState(false)

  useEffect(() => {
    if (proposalFeatureConfig) {
      setIsConfigReady(true)
    }
  }, [proposalFeatureConfig])

  useEffect(() => {
    if (isConfigReady) {
      if (openRef.current) {
        return
      }
      if (detectIfNeverChooseBefore(proposalData, proposalFeatureConfigRef.current)) {
        autoOpen()
      }
    }
  }, [isConfigReady])
}

export const useAutoOpenIfTemplateNotCompatible = ({
  proposalData,
  autoOpen,
}: {
  proposalData: ProposalDataType
  autoOpen(): void
}) => {
  useEffect(() => {
    const { isDetected: isProposalV2Template } = detectProposalV2ProposalTemplate(proposalData.proposalTemplateSettings)
    if (isProposalV2Template && isDesignValidForProposalV2(proposalData)) {
      // Open the modal if current proposal using proposal v2 template and system limit exceeded
      autoOpen()
    }
  }, [])
}
