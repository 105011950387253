import CustomField from 'elements/field/CustomField'
import { getToolbarButtons } from 'elements/wysiwyg/utils'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { DraftEditorWithContent } from 'resources/proposalTemplates/ProposalTemplates'
import InputAccordion from '../common/InputAccordion'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  blockId: string
  disabled: boolean
  record: any
  resource: string
}

const customToolbar = getToolbarButtons(true, false, ['html', 'help'])

const CustomContentFields: FC<PropTypes> = ({ blockId, disabled, record, resource }) => {
  const translate = useTranslate()
  const source = useBlockSource(blockId)

  if (!source) return null
  return (
    <InputAccordion title={`${translate('Custom Content')} ${blockId.at(blockId.length - 1)}`}>
      <TextInput
        label={'Navbar Heading'}
        name={`${source}.navHeading`}
        source={`${source}.navHeading`}
        disabled={disabled}
      />
      <CustomField
        label={'Content'}
        component={DraftEditorWithContent}
        name={`${source}.content`}
        source={`${source}.content`}
        record={record}
        resource={resource}
        disabled={disabled}
        toolbarButtons={customToolbar}
      />
    </InputAccordion>
  )
}

export default CustomContentFields
