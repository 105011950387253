import { useState } from 'react'

export const useMenu = (
  menuId: string
): {
  anchorElementAriaProps: {
    'aria-controls'?: string
    'aria-haspopup': 'true'
    'aria-expanded'?: 'true'
  }
  menuProps: {
    id: string
    open: boolean
    onClose(): void
  }
  menuController: {
    handleToggleMenu(): void
    isOpen: boolean
  }
} => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    menuController: {
      isOpen,
      handleToggleMenu: () => setIsOpen((v) => !v),
    },
    anchorElementAriaProps: {
      'aria-controls': isOpen ? menuId : undefined,
      'aria-haspopup': 'true',
      'aria-expanded': isOpen ? 'true' : undefined,
    },
    menuProps: {
      id: menuId,
      onClose: () => setIsOpen(false),
      open: isOpen,
    },
  }
}
