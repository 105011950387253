import { FC } from 'react'

/**
 * * Icon Component for: home-04-filled
 */
type HomeIconProps = {
  size?: number
  color?: string
}
export const HomeIcon: FC<HomeIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
    fill: color || 'none',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...rest} {...iconAttributes}>
      <path
        d="M0.9386 6.25167C0.600941 6.48001 0.400391 6.84967 0.400391 7.24369V17.774C0.400391 18.7824 1.26 19.5999 2.32039 19.5999H6C6.55228 19.5999 7 19.1522 7 18.5999V12.2964C7 11.6241 7.57308 11.0791 8.28 11.0791H11.72C12.4269 11.0791 13 11.6241 13 12.2964V18.5999C13 19.1522 13.4477 19.5999 14 19.5999H17.6804C18.7408 19.5999 19.6004 18.7824 19.6004 17.774V7.24369C19.6004 6.84967 19.3998 6.48001 19.0622 6.25167L10.7422 0.625153C10.2981 0.324819 9.70271 0.324819 9.2586 0.625152L0.9386 6.25167Z"
        fill={color}
      />
    </svg>
  )
}
