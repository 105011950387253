import { DialogContent } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Alert, BaseTheme, UserProfileAddIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ActionType } from 'types/tracking'
import Notice from '../../../common/Notice'
import ContactAccordion from './ContactAccordion'

interface PropTypes {
  alertSeverity: 'error' | 'info'
}

const ContactsFormContent: React.FC<PropTypes> = ({ alertSeverity = 'info' }) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const contacts = useMemo(() => formValues.contacts_data, [formValues.contacts_data])
  const { allowCreate, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic')
  )
  const [noValidEmail, setNoValidEmail] = useState<string | undefined>(undefined)
  const previousEmailsRef = useRef<string[]>([])

  // Determine context based on alertSeverity
  const context = alertSeverity === 'error' ? 'online_proposal' : 'manage_page'

  const { trackSystemEvent } = useTrackComponent({
    componentKey: 'contact_form_content',
    eventName: 'Contact Email Management',
  })

  let notificationText = ''
  if (alertSeverity === 'error') {
    notificationText = 'Add Customer Email to enable sending a proposal.'
  } else if (alertSeverity === 'info') {
    notificationText = 'Add Customer Email to enable correspondence from your project.'
  }

  useEffect(() => {
    if (!contacts || contacts.length === 0) {
      setNoValidEmail(undefined)
      return
    }
    const contactEmails = contacts.map((contact) => contact?.email)
    const validEmails = contactEmails.filter((email) => email)
    const hasNoValidEmail = validEmails.length !== contacts.length

    if (hasNoValidEmail) {
      setNoValidEmail(notificationText)
      trackSystemEvent(
        { type: ActionType.UPDATE },
        {
          event: 'Email Validation Displayed',
          context: context,
          metadata: {
            total_contacts: contacts.length,
            missing_email_count: contacts.length,
            source_page: context,
            notification_text: notificationText,
          },
        }
      )
    }
  }, [])

  useEffect(() => {
    if (!contacts || contacts.length === 0) {
      setNoValidEmail(undefined)
      return
    }
    const contactEmails = contacts.map((contact) => contact?.email)
    const validEmails = contactEmails.filter((email) => email)

    // If a valid email is now present, remove the alert notification
    if (validEmails.length === contacts.length) {
      setNoValidEmail(undefined)

      // Track that a valid email was added after initial validation
      trackSystemEvent(
        { type: ActionType.UPDATE },
        {
          event: 'Email Added Post Validation',
          context: context,
          metadata: {
            previous_valid_count: previousEmailsRef.current.length,
            new_valid_count: validEmails.length,
            source_page: context,
            validation_was_shown: true,
          },
        }
      )
    }

    previousEmailsRef.current = validEmails
  }, [contacts])

  useEffect(() => {
    if (contacts.length === 0) {
      form.change('contacts_data', [])
    }
  }, [])

  const handleAssign = (contact, index) => {
    if (!allowCreate) return
    let newContacts = [...contacts]
    newContacts[index] = contact
    form.change('contacts_data', newContacts)
  }

  const handleRemove = (index) => {
    if (!allowDelete) return
    let newContacts = [...contacts]
    newContacts.splice(index, 1)
    form.change('contacts_data', newContacts)
  }

  const handleDeleteContact = (id) => {
    form.change(
      'contacts_data',
      contacts.filter((x) => x.id !== id)
    )
  }

  const handleAddContact = (isNew, index) => {
    form.change(
      'contacts_data',
      formValues?.contacts_data.map((item, i) =>
        i === index ? (!isNew ? { ...item, assignExisting: true } : {}) : item
      )
    )
  }

  if (!contacts?.length) {
    return (
      <DialogContent>
        <Notice
          icon={<UserProfileAddIcon size={23} color={BaseTheme.palette.grey[810]} />}
          mainText={translate('You have no customer contacts in this project')}
          subText={translate(
            'Click Add Contact below to add a new contact or assign an existing contact to this project'
          )}
        />
      </DialogContent>
    )
  }

  return (
    <>
      {noValidEmail && (
        <Alert severity={alertSeverity}>
          <span>{translate(noValidEmail)}</span>
        </Alert>
      )}
      {contacts?.map((contact, i) => (
        <CustomField
          key={'contact-' + i}
          source={`contacts_data.${i}.id`}
          name={`contacts_data.${i}.id`}
          component={ContactAccordion}
          index={i}
          contact={contact}
          handleAssign={handleAssign}
          handleDeleteContact={handleDeleteContact}
          handleRemove={handleRemove}
          handleAddContact={handleAddContact}
          showErrors={alertSeverity === 'error'}
        />
      ))}
    </>
  )
}

export default ContactsFormContent
