import { CheckOutlineIcon, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const APPROVED_STATUSES = ['Approved', 'Conditionally approved']
const DECISIONS = ['Approved', 'Conditionally approved', 'Pending decision', 'Declined']

const BrighteApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const extra_actions: React.ReactNode[] = []

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Apply Now!" will take you to Brighte's credit application. OpenSolar does not view or store any of the
        data you enter in this application.
      </p>
    )
  }

  if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    if (APPROVED_STATUSES.includes(actionData.status_title)) {
      extra_actions.push(
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />{' '}
          <span style={{ fontWeight: 900, fontSize: '16px' }}>{actionData.status_title}</span>
        </div>
      )
    }
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  if (actionData.status_code === 'complete') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          {APPROVED_STATUSES.includes(actionData.status_title) && <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />}
          <h2>{actionData.status_title === 'created' ? 'Pending decision' : actionData.status_title}</h2>
        </div>
        <div>{actionData.status_description}</div>
      </div>
    )
  }

  return (
    <div>
      <TransactionActionButton
        isLoading={isLoading}
        label={
          <>
            {translate('Apply Now!')}
            <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
          </>
        }
        disabled={actionData.status_code !== 'available' || !!options?.loanDecisionRenderedAt}
        onClick={openApplicationDialog}
      />
      {extra_actions}
    </div>
  )
}

export default BrighteApplication
