import { useStandardProOrgConditionChecker } from 'conditions/hooks/useStandardProOrgConditionChecker'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { splitSelectors, splitValueFromLocalStorage } from 'ducks/split'
import React from 'react'
import { useSelector } from 'react-redux'
import { getFeatureFlagState, getFeatureFlagUserActionKey, useFeatureFlagStructs } from './featureFlags'

export const withFeatureFlag = (BaseComponent: any): any => {
  if (typeof BaseComponent === 'string') throw new Error('Incorrect usage')

  const RefComponent = React.forwardRef((props, ref) => {
    const splitIsReady = useSelector(splitSelectors.getSplitIsReady)
    const client = useSelector(splitSelectors.getClient)
    const roleId = useSelector(rolesSelectors.getCurrentRoleId)
    const userId = useSelector(authSelectors.getCurrentUserId) || -1
    const orgId = useSelector(authSelectors.getOrgId) || -1
    const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
    const isStaff = useSelector(authSelectors.getIsStaff)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const { structs } = useFeatureFlagStructs()
    const checker = useStandardProOrgConditionChecker()
    const userActions = useUserActionsContext()

    const useFeatureFlag = (splitName: string, targetValue: string) => {
      if (!splitIsReady) return undefined
      if (!structs) return undefined
      // Check internal system first

      let isEnabled: boolean | undefined = undefined

      const struct = structs.find((s) => s.key === splitName)
      if (struct) {
        let state = getFeatureFlagState(struct.data, checker)
        if (state === 'enabled') isEnabled = true
        else if (state === 'disabled') isEnabled = false
        else if (state === 'enabled_user_optout') {
          const actionName = getFeatureFlagUserActionKey(state, struct.key)
          isEnabled = !userActions.checkAction(actionName)
        } else if (state === 'disabled_user_optin') {
          const actionName = getFeatureFlagUserActionKey(state, struct.key)
          isEnabled = userActions.checkAction(actionName)
        }
      }

      if (isEnabled !== undefined) return isEnabled

      const attributeMap = {
        roleId,
        userId,
        orgId,
        orgCountry,
        isStaff,
        isAdmin,
      }
      const valueInLocalStorage = splitValueFromLocalStorage(splitName)

      if (valueInLocalStorage !== null) {
        return valueInLocalStorage
      } else {
        const value = client?.getTreatment(splitName, attributeMap)
        return targetValue === value
      }
    }

    return <BaseComponent {...props} ref={ref} useFeatureFlag={useFeatureFlag} />
  })

  RefComponent.defaultProps = BaseComponent.defaultProps

  return RefComponent
}
