import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { ComponentVersionsInherit } from 'opensolar-ui'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'
import ExpoRoutes from './routes/Routes'

type PropTypes = {}

export const ExpoSection: React.FC<PropTypes> = () => {
  const expoEnabled = useFeatureFlag('expo', 'on')
  if (expoEnabled === undefined) return null
  if (expoEnabled === false) return <Redirect to="/home" />
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      Expo Shell/Layout
      <ExpoRoutes />
    </ComponentVersionsInherit>
  )
}
