import { FormControlLabel, InputLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { FormControl, Grid, MenuItem, Select } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import { ChangeEvent, FC, memo, useContext, useEffect } from 'react'
import useSolarOutletService from 'services/outlet/useSolarOutletService'
import { Theme } from 'types/themes'
import { formatCurrency } from 'util/misc'
import AccordionCardSummaryContent from '../components/AccordionCardSummaryContent'
import AccordionCardTitle from '../components/AccordionCartTitle'
import Alert from '../components/Alert'
import DeliveryInstruction from '../components/DeliveryInstruction'
import DistributorDeliveryDetail from '../components/DistributorDeliveryDetail'
import { ErrorHelperText } from '../components/ErrorHelperText'
import PartsList from '../components/PartsList'
import PrivacyLinks from '../components/PrivacyLinks'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CartSectionContentPropType, CartSectionPropType } from '../types'

const PAYMENT_METHOD_OPTIONS = [
  {
    value: 'solar_juice_credit',
    label: 'Pay using Solar Juice credit',
  },
  {
    value: 'pay_now',
    label: 'Pay now',
  },
]

const WAREHOUSES_OPENING_HOURS = [
  'Adelaide SA: 7am - 5pm (Note: 24 hours in advance required)',
  'Brisbane QLD: 7:30am - 3:30pm',
  'Melbourne VIC: 7am - 4pm',
  'Sydney NSW: 7am - 5pm',
  'Perth WA: 7:30am - 4pm',
  'Townsville QLD: 7:30am - 3:30pm',
]

const WAREHOUSES = ['Adelaide SA', 'Brisbane QLD', 'Melbourne VIC', 'Sydney NSW', 'Perth WA', 'Townsville QLD']

const SHIPPING_OPTIONS = [
  {
    value: 'Request Commercial Delivery Quote',
    label: 'Request Commercial Delivery Quote (Cost: TBC)',
  },
  {
    value: 'Request Residential Delivery Quote',
    label: 'Request Residential Delivery Quote (Cost: TBC)',
  },
  {
    value: 'Pickup at warehouse',
    label: 'Pickup at warehouse (Cost: Free)',
  },
  {
    value: 'Mainfreight Shipping (Commercial Delivery Address)',
    label: 'Mainfreight Shipping (Commercial Delivery Address) (Cost: TBC)',
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    gap: '24px',
    padding: '8px',
  },
  gap: {
    gap: '8px',
  },
  radioGroup: {
    marginTop: '4px',
    gap: '20px',
  },
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: COLOR_PALETTE.blue2,
    },
    '&.MuiSvgIcon-root': {
      color: COLOR_PALETTE.blue2,
    },
  },
  paymentMethodItemWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  paymentMethodItem: {
    fontSize: '14px',
    color: COLOR_PALETTE.black,
    lineHeight: '20px',
  },
  //
  branchTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: COLOR_PALETTE.grey5,
  },
  branchInfo: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey5,
  },
  branchMDot: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: COLOR_PALETTE.grey5,
  },
}))

const OutletCartSection: FC<CartSectionPropType> = ({ sortOrder, checkoutSource, distributorKey }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]
  return (
    <AccordionCard
      name={'distributor-outlet'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent
          totalItems={data?.totalItems}
          subtotal={data?.subtotalPrice}
          shippingFee={data?.shippingInfo.fee}
        />
      }
      contentProps={{ distributorKey, checkoutSource }}
      content={OutletCartSectionContent}
      lazyRender={true}
    />
  )
}

const OutletCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const deliveryAddressInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].deliveryAddressInfoContext)
  const contactInfo = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].contactInfoContext)
  const outletService = useSolarOutletService()
  const data = distributorData[distributorKey]
  const translate = useTranslate()
  const deliveryAdress = deliveryAddressInfo[distributorKey]
  const isPickup = data?.shippingInfo?.deliveryMethod === 'Pickup at warehouse'

  useEffect(() => {
    const processShippingRates = async (data) => {
      const items = Object.values(data?.items)
        .flat()
        .map((item: any) => ({
          name: item?.data?.short_description || '',
          sku: item?.data?.base_sku || '',
          quantity: item?.quantity,
        }))
      const destination = {
        city: deliveryAdress?.locality,
        province: deliveryAdress?.state,
        suburb: deliveryAdress?.locality,
        address1: deliveryAdress?.address,
        postal_code: deliveryAdress?.zip,
      }
      const requestData = {
        rate: {
          destination,
          items,
        },
      }

      const rates = await outletService?.getShippingRates(requestData)
      const mainfreight = rates?.find((item) => item.description === 'General Shipping')
      const mainfreightFee = mainfreight ? mainfreight.total_price / 100 : 0
      preOrderPresenter?.updateDistributorData({
        ...distributorData,
        [distributorKey]: {
          ...data,
          shippingInfo: {
            ...data?.shippingInfo,
            fee: formatCurrency(mainfreightFee),
          },
        },
      })
    }

    if (data?.shippingInfo?.deliveryMethod === 'Mainfreight Shipping (Commercial Delivery Address)') {
      processShippingRates(data)
    }
  }, [data?.shippingInfo?.deliveryMethod, deliveryAdress])

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  const handleUpdateWarehouse = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          warehouse: event.target.value,
        },
      },
    })
  }

  const handleUpdateDeliveryMethod = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryMethod: event.target.value,
          warehouse: '',
          fee: event.target.value === 'Mainfreight Shipping (Commercial Delivery Address)' ? 'FREE' : 'TBC',
        },
      },
    })
  }

  const updatePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        paymentMethod: event.target.value,
      },
    })
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <Alert
          title={translate('What’s next after sending my order?')}
          subtitle={translate(
            'Once sent, your order will be transferred to the Solar Outlet Portal for payment processing and final shipping confirmation. You will then receive a Solar Outlet order reference ID for tracking purposes. '
          )}
          severity="info"
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Delivery Method')}</span>
        <RadioGroup
          className={classes.gap}
          name="paymentMethod"
          onChange={updatePaymentMethod}
          value={data?.paymentMethod}
        >
          {PAYMENT_METHOD_OPTIONS.map((item) => (
            <FormControlLabel
              className={classes.paymentMethodItemWrapper}
              key={item.value}
              value={item.value}
              control={<Radio className={classes.radio} />}
              label={
                <div className={sectionStyles.column}>
                  <span className={classes.paymentMethodItem}>{translate(item.label)}</span>
                </div>
              }
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Select Delivery Method')}</span>
        <Grid container direction="row" style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
          <Grid item xs={6} className={sectionStyles.column} style={{ gap: '16px' }}>
            <FormControl fullWidth error={isEmpty(data?.shippingInfo?.deliveryMethod)}>
              <InputLabel className={sectionStyles.inputLabel}>{translate('Delivery Options')}</InputLabel>
              <Select value={data?.shippingInfo?.deliveryMethod} onChange={handleUpdateDeliveryMethod}>
                {SHIPPING_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {translate(item.label)}
                  </MenuItem>
                ))}
              </Select>
              <ErrorHelperText showText={isEmpty(data?.shippingInfo?.deliveryMethod)} />
            </FormControl>
            {isPickup && (
              <div className={sectionStyles.column}>
                <span style={{ fontSize: '14px', color: COLOR_PALETTE.grey5, marginBottom: '16px' }}>
                  {translate('Your order will be ready in approx. 4 hours. Opening hours are')}:
                </span>
                {WAREHOUSES_OPENING_HOURS.map((item) => (
                  <div key={item} className={sectionStyles.row} style={{ gap: '8px' }}>
                    <span className={classes.branchMDot}>&middot;</span>
                    <span style={{ fontSize: '14px', color: COLOR_PALETTE.grey5 }}>{item}</span>
                  </div>
                ))}
              </div>
            )}
          </Grid>
          {isPickup && (
            <Grid item xs={4} style={{ paddingRight: '24px' }}>
              <FormControl fullWidth error={isEmpty(data?.shippingInfo?.warehouse)}>
                <InputLabel className={sectionStyles.inputLabel}>{translate('Warehouse')}</InputLabel>
                <Select value={data?.shippingInfo?.warehouse} onChange={handleUpdateWarehouse}>
                  {WAREHOUSES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorHelperText showText={isEmpty(data?.shippingInfo?.warehouse)} />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <PrivacyLinks
          distributor={'Solar Outlet'}
          policyLink={'https://solarjuice.com.au/terms-conditions/'}
          conditionLink={'https://solarjuice.com.au/terms-conditions/'}
        />
      </Grid>
    </Grid>
  )
}

export default memo(OutletCartSection)
