import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const DatasheetIconSvg: FC<IconProps> = (props) => {
  const { fill, height, width } = props

  return (
    <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="feed">
        <mask id="mask0_3632_9202" maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={height}>
          <rect id="Bounding box" x="0.188965" width={width} height={height} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3632_9202)">
          <path
            id="feed_2"
            d="M5.41302 20.5C4.91584 20.5 4.49501 20.325 4.15052 19.975C3.80603 19.625 3.63379 19.1974 3.63379 18.6923V5.3077C3.63379 4.80257 3.80603 4.375 4.15052 4.025C4.49501 3.675 4.91584 3.5 5.41302 3.5H15.7287L20.366 8.21153V18.6923C20.366 19.1974 20.1938 19.625 19.8493 19.975C19.5048 20.325 19.084 20.5 18.5868 20.5H5.41302ZM5.41302 19H18.5868C18.6751 19 18.7477 18.9711 18.8045 18.9134C18.8613 18.8557 18.8897 18.782 18.8897 18.6923V8.99998H14.9527V4.99998H5.41302C5.32468 4.99998 5.25212 5.02883 5.19533 5.08653C5.13854 5.14423 5.11014 5.21795 5.11014 5.3077V18.6923C5.11014 18.782 5.13854 18.8557 5.19533 18.9134C5.25212 18.9711 5.32468 19 5.41302 19ZM7.32473 16.5H16.6751V15H7.32473V16.5ZM7.32473 8.99998H11.9999V7.5H7.32473V8.99998ZM7.32473 12.75H16.6751V11.25H7.32473V12.75Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export const DatasheetIcon = createStandardIcon(DatasheetIconSvg)
