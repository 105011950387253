import { Divider, Tooltip } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import { Alert, FormControlLabel, Radio, RadioGroup, Switch } from 'opensolar-ui'
import { memo } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { ModuleGridType, ModuleLayout } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { useStudioStyles } from '../common/styles'
import { StudioFieldContainer } from '../components/StudioFieldContainer'
import MODULE_GRID_SETTER_GETTER_CONFIGS from './accessors/configs'
import RackingSelector from './RackingSelector'
import useModuleGridGroupSettings from './useModuleGridGroupSettings'
import useModuleGridGroupSettingsHandler from './useModuleGridGroupSettingsHandler'

import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined'
type BasicSettingsPropTypes = {
  allowEdit?: boolean
  moduleGrids: ModuleGridType[]
}

const BasicSettingsV2 = memo(({ allowEdit = false, moduleGrids }: BasicSettingsPropTypes) => {
  const translate = useTranslate()
  const classes = useStudioStyles()
  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric
  const {
    rackingType,
    isDualTilt,
    azimuth,
    azimuthAuto,
    ridgeDirection,
    slope,
    slopeAuto,
    isUsingTiltRacks,
    relativeTilt,
    moduleOrientation,
    elevationAuto,
    groundClearance,
  } = useModuleGridGroupSettings({ moduleGrids })
  const {
    onRackingTypeChange,
    setAzimuth,
    setAzimuthAuto,
    onSlopeChange,
    setSlopeAuto,
    setPanelTiltOverride,
    setOrientation,
    setElevationAuto,
    setGroundClearance,
  } = useModuleGridGroupSettingsHandler({ moduleGrids })
  const notify = useNotify()

  return (
    <>
      <div className={classes.fieldsRow} style={{ marginBottom: 10 }}>
        <StudioFieldContainer
          label={translate('Racking')}
          style={{ width: '100%' }}
          uiKey={'studio.tabs.selected_panel_group.racking_selector'}
          field={<RackingSelector value={rackingType} onChange={onRackingTypeChange} />}
        />
      </div>

      {isDualTilt && (
        <Alert severity="info" style={{ fontSize: '0.85em', padding: 10, marginBottom: 15 }}>
          {translate("To customize spacing and framing of dual-tilt systems, tap the 'Advanced' tab.")}
        </Alert>
      )}

      <div className={classes.fieldsRow}>
        {!isDualTilt && (
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Azimuth')}
            uiKey="studio.tabs.selected_panel_group.azimuth"
            field={
              <CustomNumberField
                name="moduleGridAzimuth"
                value={azimuth}
                endAdornment="°"
                disabled={!allowEdit || azimuthAuto}
                minValue={0}
                maxValue={360}
                maxDecimalPlaces={3}
                wrapperStyles={{ marginBottom: 0 }}
                onChange={(newValue, event) => {
                  setAzimuth(newValue)
                }}
                allowReEnable={allowEdit}
                onEditabilityChange={(isEditable) => {
                  setAzimuthAuto(!isEditable)
                }}
                reEnableBtnTitleOnDisabled={translate('Override azimuth')}
                reEnableBtnTitleOnEnabled={translate('Remove azimuth override')}
              />
            }
          />
        )}

        {isDualTilt && (
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Ridge Direction')}
            uiKey="studio.tabs.selected_panel_group.ridge_direction"
            field={
              <CustomNumberField
                name="moduleGridRidgeDirectionk"
                value={ridgeDirection}
                endAdornment="°"
                disabled={!allowEdit || azimuthAuto}
                minValue={0}
                maxValue={360}
                maxDecimalPlaces={3}
                wrapperStyles={{ marginBottom: 0 }}
                onChange={(newValue, event) => {
                  setAzimuth(newValue + 90)
                }}
                allowReEnable={allowEdit}
                onEditabilityChange={(isEditable) => {
                  setAzimuthAuto(!isEditable)
                }}
                reEnableBtnTitleOnDisabled={translate('Override ridge direction')}
                reEnableBtnTitleOnEnabled={translate('Remove ridge direction override')}
              />
            }
          />
        )}

        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Slope')}
          uiKey="studio.tabs.selected_panel_group.slope"
          field={
            <CustomNumberField
              name="moduleGridSlope"
              value={slope}
              endAdornment="°"
              disabled={!allowEdit || slopeAuto || isUsingTiltRacks}
              minValue={0}
              maxValue={360}
              maxDecimalPlaces={3}
              wrapperStyles={{ marginBottom: 0 }}
              onChange={(newValue) => {
                // do not update panel tilt override unnecessarily
                // if we detect that module grid is not actually using tilt racks
                onSlopeChange(newValue)
              }}
              allowReEnable={allowEdit}
              onEditabilityChange={(isEditable) => {
                setSlopeAuto(!isEditable)
              }}
              reEnableBtnTitleOnDisabled={translate('Override slope')}
              reEnableBtnTitleOnEnabled={translate('Remove slope override')}
            />
          }
        />
      </div>

      {isUsingTiltRacks && (
        <div className={classes.fieldsRow}>
          <StudioFieldContainer
            style={{ width: '50%' }}
            label={translate('Relative Tilt')}
            uiKey="studio.tabs.selected_panel_group.panel_tilt"
            info={translate("The panels' angle of tilt relative to the surface slope")}
            field={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomNumberField
                  name="moduleGridPanelTiltOverride"
                  value={relativeTilt}
                  endAdornment="°"
                  disabled={!allowEdit || slope === undefined}
                  minValue={rackingType === 'TILT_RACK' ? -360 : 0}
                  maxValue={360}
                  maxDecimalPlaces={3}
                  wrapperStyles={{ marginBottom: 0 }}
                  onChange={(newValue, _event) => {
                    if (slope === undefined) {
                      notify('unable to set panel tilt override without slope', 'warning')
                    } else {
                      setPanelTiltOverride(newValue)
                    }
                  }}
                />
                {relativeTilt !== undefined &&
                  slope !== undefined &&
                  // If the absolute value of the combined angle is > 90°, panels are facing downward
                  Math.abs(slope + relativeTilt) > 90 && (
                    <Tooltip
                      title={
                        <span>{translate('Combined tilt angle exceeds 90° - Your panels are facing downwards')}</span>
                      }
                      style={{ padding: 0, marginLeft: 10 }}
                    >
                      <ErrorOutlineOutlined className={classes.warningIcon} />
                    </Tooltip>
                  )}
              </div>
            }
          />
          <div style={{ width: '50%' }}></div>
        </div>
      )}

      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          label={translate('Panel Orientation')}
          uiKey="studio.tabs.selected_panel_group.panel_orientation"
          field={
            <RadioGroup
              name="moduleLayout"
              value={moduleOrientation}
              row
              style={{ marginTop: 10 }}
              onChange={(_event, value) => setOrientation(value as ModuleLayout)}
            >
              <FormControlLabel
                disabled={!allowEdit}
                value={'portrait'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span style={{ fontSize: 13 }}>{translate('Portrait')}</span>}
              />
              <FormControlLabel
                disabled={!allowEdit}
                value={'landscape'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span style={{ fontSize: 13 }}>{translate('Landscape')}</span>}
              />
            </RadioGroup>
          }
        />
      </div>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <div className={classes.fieldsRow} style={{ alignItems: 'start', marginBottom: 5 }}>
        <StudioFieldContainer
          style={{ width: '50%' }}
          uiKey="studio.tabs.selected_panel_group.auto_elevation"
          label={translate('Auto-Elevation')}
          field={
            <Switch
              disabled={!allowEdit}
              checked={elevationAuto}
              onChange={(_event, checked) => {
                setElevationAuto(checked)
              }}
            ></Switch>
          }
        />
        <StudioFieldContainer
          style={{ width: '50%' }}
          label={translate('Ground Clearance')}
          uiKey="studio.tabs.selected_panel_group.ground_clearance"
          field={
            <CustomNumberField
              name="moduleGridGroundClearance"
              value={MODULE_GRID_SETTER_GETTER_CONFIGS['groundClearance'].parse(groundClearance, {
                measurements,
              })}
              endAdornment={measurements === 'imperial' ? 'in' : 'm'}
              step={measurements === 'imperial' ? 0.5 : 0.1} // 0.5in for imperial or 0.1m for metric
              disabled={!allowEdit}
              minValue={0}
              maxDecimalPlaces={4}
              wrapperStyles={{ marginBottom: 0 }}
              resettable={true}
              onChange={(newValue, _event) => {
                const formattedValue = MODULE_GRID_SETTER_GETTER_CONFIGS['groundClearance'].format(newValue, {
                  measurements,
                })
                if (!formattedValue) return
                setGroundClearance(formattedValue)
              }}
            />
          }
        />
      </div>
    </>
  )
})

export default BasicSettingsV2
