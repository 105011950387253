import { Button, Link, LinkExternalOutlineIcon, styled } from 'opensolar-ui'
import { FC } from 'react'
import { CardWrapper, SectionWrapper } from './common/styles'

interface Resource {
  title: string
  url: string
}

interface PropTypes {
  resources: Resource[]
  viewAllUrl?: string
}

const Header = styled('div', { name: 'Header' })({
  display: 'flex',
  alignItems: 'center',
})

const HeaderText = styled('h1', { name: 'HeaderText' })({
  fontSize: 24,
  margin: 0,
  marginRight: 'auto',
})

const ButtonStyled = styled(Button, { name: 'ButtonStyled' })({
  backgroundColor: 'white',
})

const CardContainer = styled('div', { name: 'CardContainer' })({
  marginTop: 30,
  display: 'flex',
  gap: 20,
  flexWrap: 'wrap',
})

const CardContent = styled('div', { name: 'CardContent' })({
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
})

const CardImg = styled('object', { name: 'CardImg' })({
  height: 300,
  width: 210,
  borderRadius: 8,
})

const CardTitle = styled('span', { name: 'CardTitle' })({
  maxWidth: 210,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: 'black',
})

const StyledLink = styled(Link, { name: 'StyledLink' })({
  width: 249,
})

const Resources: FC<PropTypes> = ({ resources, viewAllUrl }) => {
  if (!resources || !resources.length) return null
  return (
    <SectionWrapper>
      <Header>
        <HeaderText>Resources & Datasheets</HeaderText>
        {viewAllUrl && (
          <ButtonStyled
            endIcon={<LinkExternalOutlineIcon />}
            variant={'outlined'}
            onClick={() => {
              window.open(viewAllUrl, '_blank')?.focus()
            }}
          >
            View All Resources
          </ButtonStyled>
        )}
      </Header>
      <CardContainer>
        {resources.map((resource, i) => (
          <StyledLink key={'resource-' + i} href={resource.url} target="_blank" download={'expo_resource'}>
            <CardWrapper>
              <CardContent>
                <CardImg data={resource.url} type="application/pdf" />
                <CardTitle>{resource.title}</CardTitle>
              </CardContent>
            </CardWrapper>
          </StyledLink>
        ))}
      </CardContainer>
    </SectionWrapper>
  )
}

export default Resources
