import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { useFeatureFlag } from 'util/split'
import {
  CashFlowSetupProgress,
  GetExpectedMilestonesResponseType,
  GetSetupProgressResponseType,
  MilestoneOverrideType,
} from './types'
const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetIsCashFlowVisible = () => {
  return !!useFeatureFlag('cash_flow', 'on')
}

export const useGetCashFlowNavVisible = () => {
  const cashFlowIsVisible = useGetIsCashFlowVisible()
  const permissions = useSelector(permissionsSelectors.getPermissionsSetting)
  const cashflowOnboardingIsSubmitted = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)

  const showCashFlow = useMemo(() => {
    if (!cashFlowIsVisible) return false
    if (!cashflowOnboardingIsSubmitted) return true
    else {
      return (
        permissions?.cashflow_configuration?.view &&
        permissions?.cashflow_nav_configure?.view &&
        permissions?.cashflow_nav_invoices?.view &&
        permissions?.cashflow_nav_projects?.view &&
        permissions?.cashflow_onboarding?.view
      )
    }
  }, [cashFlowIsVisible, cashflowOnboardingIsSubmitted, permissions])

  return showCashFlow
}

export const makeNextPaymentRequest = (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  issueAndSendInvoice?: boolean,
  asDraft: boolean = true
) => {
  return new Promise<{ payment_request_id: number; payment_url: string; invoice_id: number }>((resolve, reject) => {
    if (!orgId || !projectId || !systemUuid || !paymentOptionId) {
      reject('Invalid payment data')
    } else {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/payments/next/`,
        data: {
          payment_option_id: paymentOptionId,
          system_uuid: systemUuid,
          as_draft: asDraft,
        },
      })
        .then((draftResponse) => {
          if (issueAndSendInvoice) {
            restClientInstance('CUSTOM_PATCH', 'custom', {
              url: `orgs/${orgId}/invoices/${draftResponse?.data?.invoice_id}/issue_and_send/`,
              data: {},
            }).then((issueResponse) => resolve(draftResponse?.data))
          } else {
            resolve(draftResponse?.data)
          }
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}

export const previewEmail = (orgId: number, invoiceId: number, template: string) => {
  return new Promise<{ subject: string; body: string }>((resolve, reject) => {
    if (invoiceId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/invoices/${invoiceId}/preview_email/?template=${template}`,
      })
        .then((res) => {
          resolve(res?.data)
        })
        .catch((error) => console.error(error.message))
    }
  })
}

export const cancelPaymentRequest = (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  paymentRecordId: number
) => {
  return new Promise((resolve, reject) => {
    if (!orgId) {
      reject('Invalid payment data')
    }
    if (projectId && (!systemUuid || !paymentOptionId || !paymentRecordId)) {
      reject('Invalid payment data')
    }

    const url = projectId
      ? `orgs/${orgId}/projects/${projectId}/payments/${paymentRecordId}/cancel/`
      : `orgs/${orgId}/payments/${paymentRecordId}/cancel/`
    restClientInstance('CUSTOM_PUT', 'custom', {
      url,
      data: {
        payment_option_id: paymentOptionId,
        system_uuid: systemUuid,
      },
    })
      .then((response) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err?.body?.message)
      })
  })
}

export const markPaymentRequestPaidOffline = (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  paymentRequestId: number | null,
  paymentMethod: string,
  paymentRequestNumber: number
) => {
  return new Promise((resolve, reject) => {
    if (!orgId) {
      return reject('Invalid payment data')
    }
    if (!projectId && !paymentRequestId) {
      // For No project invoice
      return reject('Invalid payment data')
    }
    if (projectId && (!systemUuid || !paymentOptionId || !paymentMethod)) {
      // For invoices with project
      return reject('Invalid payment data')
    }

    const url = projectId
      ? `orgs/${orgId}/projects/${projectId}/payments/paid_offline/`
      : `orgs/${orgId}/payments/paid_offline/`

    restClientInstance('CUSTOM_PATCH', 'custom', {
      url,
      data: {
        payment_option_id: paymentOptionId,
        system_uuid: systemUuid,
        offline_payment_method: paymentMethod,
        payment_number: paymentRequestNumber,
        payment_request_id: paymentRequestId,
      },
    })
      .then((response) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err?.body?.message)
      })
  })
}

export const undoMarkPaymentRequestPaidOffline = (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  paymentRequestId: number | null
) => {
  return new Promise((resolve, reject) => {
    if (!orgId) {
      return reject('Invalid payment data')
    }
    if (projectId && (!systemUuid || !paymentOptionId || !paymentRequestId)) {
      return reject('Invalid payment data')
    }

    const url = projectId
      ? `orgs/${orgId}/projects/${projectId}/payments/paid_offline/undo/`
      : `orgs/${orgId}/payments/paid_offline/undo/`

    restClientInstance('CUSTOM_PATCH', 'custom', {
      url,
      data: {
        payment_option_id: paymentOptionId,
        system_uuid: systemUuid,
        payment_request_id: paymentRequestId,
      },
    })
      .then((response) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err?.body?.message)
      })
  })
}

export const pauseEmailReminders = (orgId: number, invoiceId: number, remindersAreMuted: boolean) => {
  const endpoint = remindersAreMuted ? 'resume_reminders' : 'pause_reminders'
  return new Promise((resolve, reject) => {
    if (!orgId || !invoiceId) {
      reject('Invalid payment data')
    } else {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/invoices/${invoiceId}/${endpoint}/`,
      })
        .then((response) => {
          resolve(true)
        })
        .catch((err) => {
          reject(err?.body?.message)
        })
    }
  })
}

export const getExpectedMilestones = (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  overrides?: MilestoneOverrideType[],
  pricePayable?: number
): Promise<GetExpectedMilestonesResponseType> => {
  return new Promise((resolve, reject) => {
    if (!orgId || !projectId) {
      reject('Invalid payment data')
    } else {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/payments/`,
        data: {
          system_uuid: systemUuid,
          payment_option_id: paymentOptionId,
          overrides,
          price_payable: pricePayable,
        },
      })
        .then((response: GetExpectedMilestonesResponseType) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err?.body?.message)
        })
    }
  })
}

export const getPriceAdjustmentAdderValue = async (
  orgId: number,
  projectId: number,
  systemUuid: string,
  paymentOptionId: number,
  pricePayable: number,
  adderLabel: string
) => {
  if (!orgId || !projectId) {
    return 'Invalid payment data'
  } else {
    try {
      const response = await restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/payments/adder_adjustment_value/`,
        data: {
          system_uuid: systemUuid,
          payment_option_id: paymentOptionId,
          adder_label: adderLabel,
          price_payable: pricePayable,
        },
      })
      return response?.data
    } catch (ex) {
      return undefined
    }
  }
}

export const getSetupCompletedSteps = async (orgId: number): Promise<CashFlowSetupProgress | {}> => {
  const response = await restClientInstance('CUSTOM_GET', 'custom', {
    url: `orgs/${orgId}/cashflow/setup_progress/`,
  })

  const progress: GetSetupProgressResponseType = response.data

  // Restructured to simplify usage
  return Object.entries(progress.setup_progress).reduce((acc, [step, data]) => {
    acc[step] = data.status
    return acc
  }, {})
}

export const updateSetupProgress = async (setupProgress: CashFlowSetupProgress, orgId: number) => {
  return await restClientInstance('CUSTOM_PUT', 'custom', {
    url: `orgs/${orgId}/cashflow/setup_progress/`,
    data: { setup_progress: setupProgress },
  })
}
