import React, { FC } from 'react'

/**
 * * Icon Component for: user-profile-03-filled
 */
type UserProfileIconProps = {
  size?: number
  color?: string
}
export const UserProfileIcon: FC<UserProfileIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M12.8182 4.00966C12.8182 6.05007 11.1087 7.87491 9 7.87491C6.89128 7.87491 5.18182 6.05007 5.18182 4.00966C5.18182 1.96924 6.89128 0 9 0C11.1087 0 12.8182 1.96924 12.8182 4.00966Z"
        fill={color}
      />
      <path
        d="M18 13.7779C18 16.1098 15.2727 18.0002 9 18.0002C2.72727 18.0002 0 16.1098 0 13.7779C0 11.446 4.02944 9.5556 9 9.5556C13.9706 9.5556 18 11.446 18 13.7779Z"
        fill={color}
      />
    </svg>
  )
}
