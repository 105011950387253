// @ts-nocheck
import { Reducer } from 'redux'
import {
  ChangeListParamsAction,
  CRUD_CHANGE_LIST_PARAMS,
  CRUD_CLEAR_LIST_PARAMS,
} from '../../../../actions/listActions'

const defaultListParams = {
  sort: null,
  order: null,
  page: 1,
  perPage: null,
  filter: {},
}

export interface ParamsState {
  sort: string
  order: string
  page: number
  perPage: number
  filter: any
}

type ActionTypes = ChangeListParamsAction | { type: 'OTHER_ACTION'; payload: any }

const paramsReducer: Reducer<ParamsState> = (previousState = defaultListParams, action: ActionTypes) => {
  switch (action.type) {
    case CRUD_CHANGE_LIST_PARAMS:
      return action.payload
    case CRUD_CLEAR_LIST_PARAMS:
      return defaultListParams
    default:
      return previousState
  }
}

export default paramsReducer
