import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { ExpoStandProps, PageRendererLazy } from 'opensolar-pageeditor'
import React, { Suspense } from 'react'

export const ExpoOrgLandingPage: React.FC<ExpoStandProps> = ({ standId }) => {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <PageRendererLazy standId={standId} />
    </Suspense>
  )
}
