import { authSelectors } from 'ducks/auth'
import {
  getProposalV2AppUrlOverride,
  toggleDebugAppUrlByCurrentEnvironment,
} from 'myenergy/mainContent/components/proposalV2/utils'
import { Box, DialogContent, FormControlLabel, styled, Switch, Typography } from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import type { ProposalTemplateType } from 'types/proposalTemplate'
import { doNotTranslate, reloadEntireApp } from 'util/misc'
import SystemLimitProposalAlert from './SystemLimitProposalAlert'
import useTemplateLibraryOptions, { TemplateLibraryTypeEnum } from './useTemplateLibraryOptions'

const StyledSection = styled('div')({
  width: 'calc(50% - 12px)',
  '> p': {
    fontWeight: 600,
    marginBottom: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const StaffDebugTool = () => {
  const debugProposalV2AppUrl = getProposalV2AppUrlOverride()
  return (
    <Box>
      <p>
        <Typography textVariant="h5">{doNotTranslate('Staff Only:')}</Typography>
      </p>
      <div>
        {debugProposalV2AppUrl
          ? doNotTranslate(`You are currently debugging the SM proposal app with ${debugProposalV2AppUrl}`)
          : doNotTranslate(
              `Enable this following toggle overrides the SM proposal app url to ${window.PROPOSAL_V2_BASE_URL}/v1/`
            )}
      </div>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            onChange={() => {
              const setOverride = !debugProposalV2AppUrl
              toggleDebugAppUrlByCurrentEnvironment(setOverride)
              reloadEntireApp()
            }}
            checked={!!debugProposalV2AppUrl}
          />
        }
        label={doNotTranslate('Overrides SM Proposal App Url')}
      />
    </Box>
  )
}

const TemplateLibraryModalContent = ({
  isSystemLimitExceeded,
  handleSelect,
  proposalTemplates,
  loading,
}: {
  isSystemLimitExceeded: boolean
  handleSelect
  proposalTemplates: ProposalTemplateType[]
  loading: boolean
}) => {
  const translate = useTranslate()
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateLibraryTypeEnum | ''>('')
  const isStaff = useSelector(authSelectors.getIsStaff)
  const templateSections = useTemplateLibraryOptions({ proposalTemplates })

  return (
    <DialogContent>
      {isSystemLimitExceeded && <SystemLimitProposalAlert />}

      <Typography textVariant="body1">
        {translate('Choose a template to create a proposal for your customer')}
      </Typography>
      <Box display="flex" gridGap={24} padding="24px 0">
        {templateSections.map(({ key, label, templateCard, value }) => (
          <StyledSection key={key}>
            <Typography textVariant="body2" colorHex="#0A090B" gutterBottom>
              {label}
            </Typography>
            {templateCard({
              selected: key === selectedTemplate,
              onSelect: () => {
                setSelectedTemplate(key)
                handleSelect(key, value)
              },
              disabled: isSystemLimitExceeded && key === TemplateLibraryTypeEnum.ProposalV2,
            })}
          </StyledSection>
        ))}
      </Box>
      {isStaff && <StaffDebugTool />}
    </DialogContent>
  )
}

export default TemplateLibraryModalContent
