import { PROPOSAL_VIEW_INDEXING } from 'projectSections/sections/proposal/ViewIndexing'

export const styles = {
  general: {
    sumInformation: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      frontSize: '5px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: 'normal',
      color: '#4D4D4D',
    },
    acceptButton: {
      border: '1px solid #30862C',
      fontWeight: 'normal',
      margin: 0,
      fontSize: 15,
      lineHeight: 'normal',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
      color: '#FFFFFF',
      minWidth: 155,
      background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
    },
    acceptButtonDisabled: {
      border: '1px solid #b7b7b7',
      fontWeight: 'normal',
      margin: 0,
      fontSize: 15,
      lineHeight: 'normal',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
      color: '#FFFFFF',
      minWidth: 155,
      background: '#dcdcdc',
      pointerEvents: 'none',
    },
    decorativeLine: {
      backgroundColor: '#D3D3D3',
      width: '90%',
      height: '1px',
      margin: '0 auto',
    },
    minPmtDisplayWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minWidth: '120px',
      maxWidth: '240px',
    },
    minPmtDisplayText: {
      marginTop: '5px',
      marginBottom: '5px',
      color: '#a2a2a2',
      fontSize: '10px',
    },
  },
  mobile: {
    container: {
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'space-around',
    },
    title: {
      width: '60px',
      margin: '14px 0',
    },
    system: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    optionsButton: {
      boxShadow: 'none',
      margin: '4px',
      width: '240px',
      border: '1px solid #DFDFDF',
      borderRadius: '3px',
    },
    selectedButton: {
      height: 45,
      boxShadow: 'none',
      width: '100%',
      minWidth: 120,
      maxWidth: 240,
      borderRadius: '3px',
    },
    payment: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    nextStep: {
      zIndex: PROPOSAL_VIEW_INDEXING['Proposal_Next_Step'],
      bottom: 0,
      padding: '10px 0',
      width: '100%',
      position: 'fixed',
      textAlign: 'center',
      borderTop: '1px solid #D3D3D3',
      backgroundColor: 'rgba(255, 255, 255,0.8)',
    },
  },
  standard: {
    container: {
      width: '240px',
      display: 'flex',
      margin: '0 auto',
    },
    optionsButton: {
      boxShadow: 'none',
      height: 45,
      width: 240,
      background: '#F1F1F1',
      boxSizing: 'border-box',
      marginBottom: '9px',
      borderRadius: '3px',
    },
    selectedButton: {
      height: 45,
      boxShadow: 'none',
      width: '240px',
      marginBottom: '9px',
      borderRadius: '3px',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: 'normal',
      letterSpacing: 0.266667,
      color: '#4D4D4D',
    },
    system: {},
    payment: {},
    nextStep: {},
  },
  fixedMobileSelection: {
    system: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      overflow: 'hidden',
      maxWidth: 240,
      minWidth: 120,
      flexGrow: 1,
    },
    payment: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      overflow: 'hidden',
      maxWidth: 240,
      minWidth: 120,
      flexGrow: 1,
    },
  },
}
