import * as Sentry from '@sentry/react'
import type { Translate } from 'ra-core'
import type {
  CheckoutActionCallback,
  CheckoutActionConfig,
  CheckoutActionElementOptions,
  ProposalDataType,
} from '../../types'
import type { LoanApplicationType } from '../../types/CheckoutElementsDataTypes'
import BrighteApplication from '../brighte/BrighteApplication'
import DividendApplication from '../dividend/DividendApplication'
import GenericIntegratedFinanceApplication from '../genericFinance/GenericIntegratedFinanceApplication'
import LoanpalApplication from '../loanpal/LoanpalApplication'
import MosaicApplication from '../mosaic/MosaicApplication'
import PhoenixApplication from '../phoenix/PhoenixApplication'
import PlentiApplication from '../plenti/PlentiApplication'
import SungageApplication from '../sungage/SungageApplication'
import SunlightApplicationAction from '../sunlight/SunlightApplicationAction'

export type LoanApplicationProps = {
  config: CheckoutActionConfig
  onAction: CheckoutActionCallback
  elementData: LoanApplicationType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}

const LoanApplication = (props: LoanApplicationProps) => {
  const { actionData } = props.elementData

  if (actionData.payment_method === 'sunlight_loan_application') {
    return <SunlightApplicationAction {...props} />
  } else if (actionData.payment_method === 'loanpal_application') {
    return <LoanpalApplication {...props} />
  } else if (actionData.payment_method === 'mosaic_application') {
    return <MosaicApplication {...props} />
  } else if (actionData.payment_method === 'sungage_application') {
    return <SungageApplication {...props} />
  } else if (actionData.payment_method === 'brighte_application') {
    return <BrighteApplication {...props} />
  } else if (actionData.payment_method === 'dividend_application') {
    return <DividendApplication {...props} />
  } else if (actionData.payment_method === 'phoenix_application') {
    return <PhoenixApplication {...props} />
  } else if (
    actionData.payment_method === 'plenti_loan_application' ||
    actionData.payment_method === 'plenti_bnpl_application'
  ) {
    return <PlentiApplication {...props} />
  } else if (actionData.finance_integration) {
    return <GenericIntegratedFinanceApplication {...props} />
  } else {
    Sentry.captureException(
      new Error(`Unidentifiable loan application. actionData.payment_method: ${actionData.payment_method}`)
    )
    return null
  }
}

export default LoanApplication
