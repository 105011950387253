import { FC } from 'react'

/**
 * * Icon Component for: star-02
 */
type StarOutlineIconProps = {
  size?: number
  color?: string
}
export const StarOutlineIcon: FC<StarOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M11.4956 1.71381C11.7021 1.29527 12.299 1.29527 12.5055 1.71381L15.1795 7.13194C15.2616 7.29814 15.4201 7.41334 15.6036 7.43999L21.5828 8.30883C22.0447 8.37595 22.2291 8.94357 21.8949 9.26936L17.5683 13.4868C17.4355 13.6162 17.375 13.8026 17.4063 13.9852L18.4277 19.9403C18.5066 20.4004 18.0237 20.7512 17.6106 20.534L12.2626 17.7224C12.0986 17.6361 11.9026 17.6361 11.7385 17.7224L6.3905 20.534C5.97737 20.7512 5.49453 20.4004 5.57343 19.9403L6.59481 13.9852C6.62614 13.8026 6.56557 13.6162 6.43285 13.4868L2.10622 9.26936C1.77199 8.94357 1.95642 8.37595 2.41832 8.30883L8.39757 7.43999C8.58099 7.41334 8.73955 7.29814 8.82158 7.13194L11.4956 1.71381Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
