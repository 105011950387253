import { CardContent as Mui4CardContent, CardContentProps as Mui4CardContentProps } from '@material-ui/core'
import { default as Mui5CardContent } from '@mui/material/CardContent'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsCardVersion } from './Card'

export const CardContent: FC<OsCardContentProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="CardContent"
      propDefs={CardContentProps}
      showExample={true}
      component={OsCardContent}
      props={{ ...props, ref }}
    />
  )
})

export type OsCardContentProps = Mui4CardContentProps

const OsCardContent: FC<OsCardContentProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('card')
  switch (version) {
    case OsCardVersion.V2:
      //@ts-ignore
      return <Mui5CardContent ref={ref} {...props} />

    default:
      return <Mui4CardContent ref={ref} {...props} />
  }
})

const CardContentProps: PropsDefs = {}
