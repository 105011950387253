import { DialogTitle as Mui4DialogTitle, DialogTitleProps as Mui4DialogTitleProps } from '@material-ui/core'
import { default as Mui5DialogTitle } from '@mui/material/DialogTitle'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsDialogVersion } from './Dialog'

export const DialogTitle: FC<OsDialogTitleProps> = (props) => {
  return (
    <Inspector
      name="DialogTitle"
      propDefs={DialogTitleProps}
      showExample={true}
      component={OsDialogTitle}
      props={props}
    />
  )
}

export type OsDialogTitleProps = Mui4DialogTitleProps

const OsDialogTitle: FC<OsDialogTitleProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('dialog')
  switch (version) {
    case OsDialogVersion.V2:
      //@ts-ignore
      return <Mui5DialogTitle ref={ref} {...props} />

    default:
      return <Mui4DialogTitle ref={ref} {...props} />
  }
})

const DialogTitleProps: PropsDefs = {}
