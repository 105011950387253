import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { NumberInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

interface PropTypes {
  source: string
}
const QUANTITY_CHOICES = [
  { id: 0, name: 'Is smaller than', filter: 'has_system_smaller_than' },
  { id: 1, name: 'Is larger than', filter: 'has_system_larger_than' },
]

const SystemSize: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const filterField = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])

  useEffect(() => {
    let newValue = {}
    if (filterField?.conditional !== undefined && filterField?.quantity) {
      const selected = QUANTITY_CHOICES.find((x) => x.id === filterField.conditional)
      if (selected) newValue[selected.filter] = filterField?.quantity
    }
    form.change(`${source}.value`, newValue)
  }, [filterField])
  return (
    <>
      <SelectInput
        choices={QUANTITY_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
        style={{ minWidth: '100px' }}
      />
      <NumberInput label={false} source={`${source}.fields.quantity`} variant="outlined" margin={'none'} />
    </>
  )
}

export default SystemSize
