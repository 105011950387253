export enum FinanceCtaType {
  SUBMIT_CREDIT_APPLICATION_IFRAME = 'submit_credit_application_iframe',
  FETCH_IFRAME_URL = 'fetch_iframe_url',
  FORM_REDIRECT = 'form_redirect',
  PROPOSAL_TOOLBAR_DIALOG = 'proposal_toolbar_dialog',
  SUBMIT_CREDIT_APPLICATION_HOSTED = 'submit_credit_application_hosted',
  ALERT_INFO = 'ALERT_INFO',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_ERROR = 'ALERT_ERROR',
  VIEW_LENDER_DOCS = 'view_lender_docs',
  DISCLAIMER_BEFORE_REDIRECT = 'disclaimer_before_redirect',
  CHECKOUT_FINANCE = 'checkout_finance'
}
