import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      // maxWidth: '70%',
      // flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      // marginTop: '-16px',
      // marginLeft: '-25px',
      paddingRight: 0,
      paddingTop: 0,
      // remove left padding due to select component dialog
      // marginLeft: 'auto',
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        minHeight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        // paddingLeft: theme.spacing(2),
        backgroundColor: 'transparent',
      },
    },
    actions: {
      // paddingTop: theme.spacing(1),
      padding: 0,
      minHeight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        // padding: theme.spacing(1),
        backgroundColor: 'transparent',
      },
      '& .MuiCardActions-spacing > :not(:first-child)': {
        margin: 0,
      },
    },
  }),
  { name: 'RaListToolbar' }
)

const ListToolbar = (props) => {
  const {
    classes: classesOverride,
    filters,
    filterValues, // dynamically set via the UI by the user
    permanentFilter, // set in the List component by the developer
    actions,
    exporter,
    hasSearch,
    hideActions,
    ...rest
  } = props
  const classes = useStyles(props)
  return (
    <Toolbar className={classnames('Ra-List-Toolbar', classes.toolbar)}>
      {filters &&
        React.cloneElement(filters, {
          ...rest,
          filterValues,
          hasSearch,
          context: 'form',
        })}
      <span />
      {actions &&
        hideActions !== true &&
        React.cloneElement(actions, {
          ...rest,
          className: classes.actions,
          exporter, // deprecated, use ExporterContext instead
          filters,
          hasSearch,
          filterValues,
          permanentFilter,
          ...actions.props,
        })}
    </Toolbar>
  )
}

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  permanentFilter: PropTypes.object,
  actions: PropTypes.element,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

export default React.memo(ListToolbar)
