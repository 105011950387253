import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const BarChartSquareIconSvg: FC<IconProps> = (props) => {
  const { fill, color, transform, ...rest } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" {...rest} style={{ transform: transform }}>
      <path
        d="M6.20039 15.8V13.4M11.0004 15.8V11M15.8004 15.8V6.20002M3.80039 20.6C2.47491 20.6 1.40039 19.5255 1.40039 18.2V3.80002C1.40039 2.47454 2.47491 1.40002 3.80039 1.40002H18.2004C19.5259 1.40002 20.6004 2.47454 20.6004 3.80002V18.2C20.6004 19.5255 19.5259 20.6 18.2004 20.6H3.80039Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BarChartSquareIcon = createStandardIcon(BarChartSquareIconSvg)
