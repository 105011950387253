import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const MountingIconSvg: FC<IconProps> = (props) => {
  const { color, fill, stroke } = props
  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M26.0997 14.4531L2.06836 22.192" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M19.9279 5.18164L2.61719 10.6482" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M23.0937 12.2227L17.6758 13.9336" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9.53121 16.2891L4.11328 18" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M6.36353 12.5575C6.21072 12.0991 5.71519 11.8513 5.25674 12.0041C4.79829 12.157 4.55052 12.6524 4.70334 13.1109L6.36353 12.5575ZM7.33223 15.4635L6.36353 12.5575L4.70334 13.1109L5.67203 16.017L7.33223 15.4635Z"
        fill={fill}
      />
      <path
        d="M19.7249 8.58096C19.5721 8.12251 19.0765 7.87474 18.6181 8.02756C18.1596 8.18037 17.9119 8.6759 18.0646 9.13436L19.7249 8.58096ZM20.6936 11.487L19.7249 8.58096L18.0646 9.13436L19.0334 12.0405L20.6936 11.487Z"
        fill={fill}
      />
    </svg>
  )
}

export const MountingIcon = createStandardIcon(MountingIconSvg)
