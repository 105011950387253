import { Divider } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, LoadingDots, styled, Typography } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { AccountingIntegration } from './types'

type PropTypes = {
  accountingIntegrations: AccountingIntegration[]
  integrationId: string | null
  setIsConnected: (newVal: boolean) => void
  setAccountingIntegrations: (newVal: AccountingIntegration[]) => void
  setDialogContentUrl: (newVal: string | null) => void
  getAccountingIntegrations: () => void
}

const DisconnectTitleWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
})

const CancelDisconnectWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  marginLeft: 'auto',
})

const LoadingDotsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  width: '100%',
})

const DisconnectParagraph = styled('div')({
  padding: '32px 16px 32px 16px',
})

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginRight: '45%',
  marginBottom: '52px',
})

const DiscardBtn = styled(Button)({
  marginRight: 10,
  marginLeft: 'auto',
  border: '#fff',
})

const CloseButton = styled(CloseOutlined)(({ theme }) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
}))

const StyledDivider = styled(Divider)({
  height: '1px',
  color: '#D9DBE9',
  marginTop: '16px',
})

const DisconnectButton = styled(Button)({
  marginBottom: '10px',
  backgroundColor: '#ececec',
})

const DisconnectAccountingDialog: React.FC<PropTypes> = (props) => {
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)

  const cancelDisconnect = () => {
    setShowDialog(false)
  }

  const confirmDisconnect = async () => {
    const intId = props.accountingIntegrations.filter((ai) => ai.enabled)[0].id

    if (!props.integrationId && intId === '') {
      console.error('Integration ID is missing.')
      return
    }

    setIsDisconnecting(true)

    try {
      await disconnectIntegration()
      props.getAccountingIntegrations()
      props.setIsConnected(false)
      notify(translate('Integration successfully disconnected'), 'info')
      setShowDialog(false)
    } catch (err) {
      notify(translate('Error disconnecting integration'), 'error')
    } finally {
      setIsDisconnecting(false)
    }
  }

  const disconnectIntegration = () => {
    const aux: AccountingIntegration[] = props.accountingIntegrations.filter((ai) => ai.enabled)
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    return new Promise((resolve, reject) => {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + orgId + '/accounting/disconnect/',
        data: {
          accounting_integration: aux[0].name,
          connection_id: aux[0].id,
        },
      })
        .then((response: any) => {
          props.setDialogContentUrl(response.data.connection_url)
          logAmplitudeEvent('cashfow_accounting_integration_disabled', { integration: aux[0]?.name })
          resolve(true)
        })
        .catch((err) => {
          reject(err?.body?.message)
        })
    })
  }
  return (
    <>
      <DisconnectButton
        onClick={() => {
          setShowDialog(true)
        }}
        variant="contained"
      >
        {translate('Disconnect')}
      </DisconnectButton>
      <Dialog open={showDialog} maxWidth="xl" style={{ height: 'xl' }}>
        <DialogTitle style={{ display: 'flex', flexDirection: 'column' }}>
          <DisconnectTitleWrapper>
            <Typography>
              {isDisconnecting
                ? translate('Disconnecting Accounting Integration...')
                : translate('Disconnect Accounting Integration')}
            </Typography>
            <CancelDisconnectWrapper>
              <IconButton onClick={cancelDisconnect} disabled={isDisconnecting}>
                <CloseButton />
              </IconButton>
            </CancelDisconnectWrapper>
          </DisconnectTitleWrapper>
          <StyledDivider />
        </DialogTitle>
        <DialogContent>
          {isDisconnecting ? (
            <LoadingDotsContainer>
              <LoadingDots text="Disconnecting account Integration ..." />
            </LoadingDotsContainer>
          ) : (
            <>
              <DisconnectParagraph>
                <Typography>
                  {translate(
                    'Please confirm you would like to disconnect the accounting integration. Note that disconnecting the accounting integration will prevent future invoices and customers from being synced from OpenSolar to your accounting software.'
                  )}
                </Typography>
              </DisconnectParagraph>
              <ButtonContainer>
                <DiscardBtn variant="outlined" color="inherit" onClick={cancelDisconnect}>
                  {translate('Cancel')}
                </DiscardBtn>
                <Button variant="contained" color="primary" onClick={confirmDisconnect}>
                  {translate('Confirm')}
                </Button>
              </ButtonContainer>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DisconnectAccountingDialog
