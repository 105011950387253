import { useProjectConfiguration } from 'hooks/useProjectConfiguration'
import { useMemo } from 'react'

const setbackTypes = [
  {
    key: 'setbacks_arrays',
    name: 'Arrays',
  },
  {
    key: 'setbacks_default',
    name: 'Default',
  },
  {
    key: 'setbacks_dormer',
    name: 'Dormer',
  },
  {
    key: 'setbacks_flat_gutter',
    name: 'Flat Gutter',
  },
  {
    key: 'setbacks_gutter',
    name: 'Gutter',
  },
  {
    key: 'setbacks_hip',
    name: 'Hip',
  },
  {
    key: 'setbacks_objects',
    name: 'Objects',
  },
  {
    key: 'setbacks_rake',
    name: 'Rake',
  },
  {
    key: 'setbacks_ridge',
    name: 'Ridge',
  },
  {
    key: 'setbacks_shared',
    name: 'Shared',
  },
  {
    key: 'setbacks_skylight',
    name: 'Skylight',
  },
  {
    key: 'setbacks_valley',
    name: 'Valley',
  },
] as const

export type SetbackType = typeof setbackTypes[number]
export type SetbackTypeKey = typeof setbackTypes[number]['key']
export type SetbackTypeName = typeof setbackTypes[number]['name']

type SetbackConfig = {
  key: SetbackTypeKey
  keyShort: string
  name: SetbackTypeName
  distance: number
}

type PropTypes = {
  filter?: (key: SetbackType) => boolean
  setbackDistanceConverter?: (value: number) => number
}

export const useSetbacksConfiguration = (props: PropTypes) => {
  const projectConfig = useProjectConfiguration()

  const filteredSetbackTypes = useMemo(() => {
    if (!props.filter) {
      return setbackTypes
    }
    return setbackTypes.filter(props.filter)
  }, [props.filter])

  const setbacksConfig = useMemo(() => {
    const config: SetbackConfig[] = []
    const convert = props.setbackDistanceConverter
    filteredSetbackTypes.forEach((setbackType: SetbackType) => {
      config.push({
        key: setbackType.key,
        keyShort: setbackType.key.replace('setbacks_', ''),
        name: setbackType.name,
        distance: convert ? convert(projectConfig[setbackType.key]) : projectConfig[setbackType.key],
      })
    })
    return config
  }, [...filteredSetbackTypes.map((setbackType: SetbackType) => projectConfig[setbackType.key]), filteredSetbackTypes])

  return setbacksConfig
}
