import { Dialog, DialogCloseButton, styled } from 'opensolar-ui'
import { FC, useEffect, useState } from 'react'
import { getVideoData } from '../utils'
import ContentSlider from './common/ContentSlider'
import { SectionWrapper } from './common/styles'
import ThumbnailCard from './common/ThumbnailCard'

interface VideoData {
  title: string
  thumbnail_url: string
  video_url: string
}
interface PropTypes {
  videos: { url: string }[]
}

const Videos: FC<PropTypes> = ({ videos }) => {
  const [data, setData] = useState<VideoData[]>([])
  const [playVideo, setPlayVideo] = useState<string | null>(null)
  useEffect(() => {
    if (videos?.length) {
      Promise.all(videos.map((x) => getVideoData(x.url))).then((result) => {
        setData(result.filter((x) => x !== null))
      })
    }
  }, [videos])

  const handleCloseDialog = () => {
    setPlayVideo(null)
  }

  if (!data.length) return null
  return (
    <SectionWrapper>
      <ContentSlider
        cards={data}
        header={'Videos'}
        render={(video, i) => (
          <ThumbnailCard
            key={'video-' + i}
            onClick={() => {
              setPlayVideo(video.video_url)
            }}
            {...video}
          />
        )}
      />
      {playVideo && <VideoDialog videoLink={playVideo} onClose={handleCloseDialog} />}
    </SectionWrapper>
  )
}
const VideoPlayer = styled('iframe', { name: 'VideoPlayer' })({
  width: '100%',
  height: '100%',
})
const CloseBtn = styled('div', { name: 'CloseBtn' })({
  marginLeft: 'auto',
})

const VideoDialog = ({ videoLink, onClose }) => {
  return (
    <Dialog
      open={videoLink}
      PaperProps={{
        style: {
          padding: 15,
          paddingTop: 5,
          width: '80%',
          height: '80%',
          maxHeight: 'unset',
          maxWidth: 'unset',
        },
      }}
    >
      <CloseBtn>
        <DialogCloseButton onClick={onClose} />
      </CloseBtn>
      <VideoPlayer src={videoLink} frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
    </Dialog>
  )
}

export default Videos
