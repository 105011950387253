import { Button, ChevronDownOutlineIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useState } from 'react'

interface PropTypes {
  label: string
  onClick: () => void
  toggledOn: boolean
  items: string[]
  setFilter: (values) => void
  value?: string
}

const ChevronDownIcon = styled(ChevronDownOutlineIcon, { name: 'ChevronDownIcon' })(({ theme }) => ({
  height: 10,
  width: 10,
  color: theme.palette.grey[700],
}))

const ChevronUpIcon = styled(ChevronDownIcon, { name: 'ChevronUpIcon' })({
  transform: 'rotate(180deg)',
})

const LeftButton = styled(Button, { name: 'LeftButton' })({
  justifyContent: 'flex-start',
})

const BtnLabel = styled('span', { name: 'BtnLabel' })(({ theme }) => ({
  color: theme.palette.grey[700],
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 12,
  marginRight: 'auto',
}))

const ItemBtn = styled(LeftButton, { name: 'ItemBtn' })(({ theme }) => ({
  padding: '6px 40px',
  textAlign: 'left',
  lineHeight: '20px',
  margin: '5px 0',
  '&.selected': {
    backgroundColor: theme.palette.grey[100],
    fontWeight: 600,
  },
}))

const FilterBtn: FC<PropTypes> = ({ label, onClick, toggledOn, items, setFilter, value }) => {
  const translate = useTranslate()
  const [filterValue, setFilterValue] = useState<string | null>(null)

  useEffect(() => {
    setFilter(filterValue)
  }, [filterValue])
  return (
    <>
      <LeftButton onClick={onClick} endIcon={toggledOn ? <ChevronUpIcon /> : <ChevronDownIcon />}>
        <BtnLabel>{label}</BtnLabel>
      </LeftButton>
      {toggledOn && (
        <>
          <ItemBtn
            color="default"
            size="small"
            className={!filterValue ? 'selected' : ''}
            onClick={() => {
              setFilterValue(null)
            }}
          >
            {translate('All')}
          </ItemBtn>
          {items.map((x) => (
            <ItemBtn
              className={filterValue === x ? 'selected' : ''}
              color="default"
              size="small"
              onClick={() => {
                setFilterValue(x)
              }}
            >
              {translate(x)}
            </ItemBtn>
          ))}
        </>
      )}
    </>
  )
}

export default FilterBtn
