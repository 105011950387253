// @ts-nocheck
import { InputAdornment } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { FieldTitle, InputProps, useInput } from 'ra-core'
import React, { FunctionComponent } from 'react'

import InputHelperText from './InputHelperText'
import ResettableTextField from './ResettableTextField'
import sanitizeRestProps from './sanitizeRestProps'

export type TextInputProps = InputProps<TextFieldProps> & Omit<TextFieldProps, 'label' | 'helperText'>

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  resource,
  source,
  validate,
  InputProps: InputPropsOverride,
  startAdornment,
  endAdornment,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched, submitError },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  })
  const errorMessage = error || submitError

  const adornment = {
    startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
    endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
  }

  const InputProps = { ...adornment, ...InputPropsOverride }
  return (
    <ResettableTextField
      id={id}
      {...input}
      label={
        label !== '' &&
        label !== false && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      error={!!(touched && errorMessage)}
      helperText={<InputHelperText touched={touched} error={errorMessage} helperText={helperText} />}
      InputProps={InputProps}
      {...sanitizeRestProps(rest)}
      {...options}
    />
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
}

TextInput.defaultProps = {
  options: {},
}

export default TextInput
