import { LoggerAPI } from './logger'

export function client_check(
  domains_whitelist: string[],
  nested: boolean,
  referrer?: string,
  logger: LoggerAPI = console
): boolean {
  if (!nested) return true
  if (!referrer) {
    logger.error('No referrer found, client_check failed!')
    return false
  }

  const url = new URL(referrer)
  const domain = url.host

  const ret = domains_whitelist.some((d) => {
    // Change from wildcard syntax to regex syntax
    // 1. Escape all dots
    let regstr = d.replace(/\./g, '\\.')
    // 2. Replace * with .*
    regstr = regstr.replace(/\*/g, '.*')
    const regex = new RegExp(`^${regstr}$`)
    const ret = !!domain.match(regex)
    if (ret) {
      logger.debug('referrer domain passed client_check: ', domain, d)
    }
    return ret
  })

  if (!ret) {
    logger.error('referrer domain failed client_check: ', domain, domains_whitelist)
  }

  return ret
}
