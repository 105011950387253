import { styled } from '../styles/styled'

const Wrapper = styled('div')<{ textColor?: string }>(({ theme, textColor }) => {
  return {
    color: textColor,
    display: 'block',
    textAlign: 'center',
    margin: '15px 0px 15px 0px',
  }
})

const Label = styled('span')(({ theme }) => {
  return {
    backgroundColor: '#ffffff',
    padding: 10,
  }
})

const Break = styled('hr')(({ theme }) => {
  return {
    marginTop: -7,
    borderBottom: 'none',
    borderTop: '1px solid #d3d3d3',
  }
})

export const Divider = ({ textColor, label }: { textColor?: string; label?: string }) => {
  return (
    <Wrapper textColor={textColor}>
      {!!label && <Label>{label}</Label>}
      <Break />
    </Wrapper>
  )
}
