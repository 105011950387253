import { DistributorTradePricing } from 'elements/hardwareSelectorV2/HardwareSelectorCore'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useDistributorTradePricing = ({
  orgId,
  distributors,
}: {
  orgId: number
  distributors: HardwareSupplierEnum[]
}) => {
  const [distributorTradePricing, setDistributorTradePricing] = useState<DistributorTradePricing>({})
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const distributorsParam = distributors.map((distributor) => getSupplierConfig(distributor)?.filterKey || '').join(',')

  useEffect(() => {
    if (!orgId || distributors.length === 0) {
      return
    }
    setIsChecking(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/distributors/distributors_trade_pricing/?distributors=${distributorsParam}`,
    })
      .then((response) => {
        if (response?.data?.result) {
          setDistributorTradePricing(response?.data?.result)
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => {
        setIsChecking(false)
      })
  }, [orgId, distributors])

  return { distributorTradePricing, isChecking }
}

export default useDistributorTradePricing
