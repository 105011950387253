import { useStandardProOrgConditionChecker } from 'conditions/hooks/useStandardProOrgConditionChecker'
import { FilterStruct } from 'conditions/types'
import { useStructs } from 'contexts/structs/hooks/useStructs'
import { Struct, StructsLoadInfo } from 'contexts/structs/types/types'
import { useMemo } from 'react'

export const useFeatureFlagStructs = () => {
  return useStructs<FeatureFlagType>('feature-flag')
}
export type FeatureFlagOptState = 'enabled_user_optout' | 'disabled_user_optin'
export type FeatureFlagState = 'enabled' | 'disabled' | FeatureFlagOptState

export type FeatureFlagType = {
  description: string
  default_state: FeatureFlagState
  cases?: {
    description: string
    state: FeatureFlagState
    conditions: FilterStruct[]
  }[]
}

export const getFeatureFlagState = (data: FeatureFlagType, checker: (c: FilterStruct[]) => boolean) => {
  if (data.cases) {
    for (const c of data.cases) {
      if (checker(c.conditions)) {
        return c.state
      }
    }
  }
  return data.default_state
}

export function useFeatureFlagStructsWithState(): StructsLoadInfo<FeatureFlagType> & {
  states: FeatureFlagStateMap
} {
  const { loadState, structs } = useFeatureFlagStructs()
  const checker = useStandardProOrgConditionChecker()
  const states = useMemo(() => {
    let states: FeatureFlagStateMap = {}
    for (const struct of structs) {
      let state = getFeatureFlagState(struct.data, checker)
      let list = states[state]
      if (list) list.push(struct)
      else states[state] = [struct]
    }
    return states
  }, [structs])

  return useMemo(() => ({ loadState, structs, states }), [loadState, structs, states])
}

export type FeatureFlagStateMap = Partial<Record<FeatureFlagState, Struct<FeatureFlagType>[]>>

export const getFeatureFlagUserActionKey = (state: FeatureFlagOptState, struct_key: string) => {
  return state === 'enabled_user_optout' ? 'feature_flag_opt_out_' + struct_key : 'feature_flag_opt_in_' + struct_key
}
