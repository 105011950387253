import React, { FC } from 'react'

/**
 * * Icon Component for: edit-01, edit-02, edit-03, edit-04
 */
type EditOutlineIconProps = {
  variant: 1 | 2 | 3 | 4
  size?: number
  color?: string
}
export const EditOutlineIcon: FC<EditOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...rest} {...iconAttributes}>
          <path
            d="M11.5362 16.2428L12.2784 15.4109C13.1892 14.3902 14.8232 14.5229 15.5574 15.6772C16.2413 16.7525 17.727 16.9563 18.6753 16.105L20.0208 14.8969M1.97852 16.4699L6.3445 15.5902C6.57628 15.5435 6.78909 15.4293 6.95623 15.2621L16.7299 5.48307C17.1985 5.01422 17.1982 4.25423 16.7292 3.78577L14.6588 1.71769C14.19 1.24942 13.4304 1.24974 12.962 1.7184L3.18728 11.4985C3.02046 11.6654 2.90656 11.8777 2.85982 12.109L1.97852 16.4699Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 2:
      return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M13.4492 6.95143L17.0492 10.5514M4.44922 19.5514L8.8152 18.6717C9.04698 18.625 9.2598 18.5109 9.42693 18.3437L19.2006 8.56461C19.6692 8.09576 19.6689 7.33577 19.1999 6.86731L17.1295 4.79923C16.6607 4.33097 15.9011 4.33129 15.4327 4.79995L5.65798 14.58C5.49117 14.7469 5.37727 14.9593 5.33052 15.1906L4.44922 19.5514Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 3:
      return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M10.7992 16.5514H16.7992M1.19922 16.5514L5.5652 15.6717C5.79698 15.625 6.0098 15.5109 6.17694 15.3437L15.9506 5.56461C16.4192 5.09576 16.4189 4.33577 15.9499 3.86731L13.8795 1.79923C13.4107 1.33097 12.6511 1.33129 12.1827 1.79995L2.40798 11.58C2.24117 11.7469 2.12727 11.9593 2.08052 12.1906L1.19922 16.5514Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 4:
      return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.324 1.90729C12.7144 1.51674 13.3474 1.51647 13.738 1.9067L16.0914 4.25746C16.4823 4.64784 16.4825 5.28116 16.092 5.67187L6.13422 15.6352C5.99494 15.7745 5.81759 15.8696 5.62444 15.9085L1.19922 16.8002L2.09247 12.3802C2.13142 12.1875 2.22634 12.0105 2.36535 11.8714L12.324 1.90729Z"
            stroke={color}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
