import { Dialog, DialogContent, DialogTitle, Divider, makeStyles, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import { generateDocumentSelectors } from 'ducks/generateDocument'
import LoadingDots from 'layout/widgets/LoadingDots'
import { IconButton } from 'opensolar-ui'
import { useDataProvider, useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { PrivateFileType } from 'types/privateFile'
import FileRowLite from './FileRowLite'

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    margin: 'auto',
    padding: '8px 0px',
  },
  filesContainer: {
    width: '100%',
    maxHeight: 400,
    overflow: 'hidden auto',
    margin: 'auto',
  },
})

type PropTypes = {
  handleClose: () => void
}

const ProjectFileDialog: React.FC<PropTypes> = ({ handleClose }) => {
  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  const formState = form.getState()
  const record = formState.values
  const files: PrivateFileType[] = useField('private_files_data', { subscription: { value: true } })?.input?.value
  const [isLoading, setisLoading] = useState<boolean>(false)

  const updateEventInForm = (newFile: PrivateFileType) => {
    const newFileArray: PrivateFileType[] = files
      .filter((f) => f.id !== newFile.id)
      .concat(newFile)
      .sort((f1, f2) => f1.title.localeCompare(f2.title))
    formState.initialValues.private_files_data = newFileArray
    form.change('private_files_data', newFileArray)
  }

  const doc = useSelector(generateDocumentSelectors.getLastDocument)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  useEffect(() => {
    if (doc?.status !== 'success' || !doc?.data || doc.projectId !== record.id) return
    setisLoading(true)
    dataProvider.CUSTOM_GET(
      'private_files',
      {
        url: `private_files/${doc.data.id}/`,
      },
      {
        onSuccess: (response: { data: PrivateFileType }) => {
          const { data } = response
          updateEventInForm(data)
          setisLoading(false)
        },
        onFailure: (e: any) => {
          setisLoading(false)
        },
      }
    )
  }, [dataProvider, notify, doc])

  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h6">
            {translate('Generated files for %{address}', { address: record.address })}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        {isLoading ? (
          <LoadingDots />
        ) : files.length > 0 ? (
          <div className={classes.filesContainer}>
            {files &&
              files.length > 0 &&
              files
                ?.sort((a, b) => (a.id < b.id ? 1 : -1))
                .map((file) => <FileRowLite file={file} key={file.id} recordUrl={record.url} />)}
          </div>
        ) : (
          <div>{translate('No files')}</div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ProjectFileDialog
