import { FC } from 'react'
import type { IconProps } from './type'

interface AddIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const AddIcon: FC<AddIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  const viewBox = `0 0 ${size} ${size}`
  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0008 4.7998L12.0008 19.1998M19.2008 11.9998L4.80078 11.9998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
