import { ButtonProps } from '@material-ui/core'
import { newSupportTicketLink } from 'constants/links'
import Alert from 'elements/Alert'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Confirm from './dialog/Confirm'

export enum ProjectLoadErrorTypes {
  NONE = 0,
  PROJECT = 1,
  DESIGN_NON_RECOVERABLE = 2,
  DESIGN_RECOVERABLE = 3,
}

export type ErrorPromptProps = {
  errorOnLoad: ProjectLoadErrorTypes
  recoverDesignFunc: (() => void) | null
  restartDesignFunc: (() => void) | null
}

export const ProjectLoadErrorPrompt = ({ errorOnLoad, recoverDesignFunc, restartDesignFunc }: ErrorPromptProps) => {
  const history = useHistory()
  const translate = useTranslate()

  const promptButtons = useMemo(() => {
    const buttons: ButtonProps[] = []

    const returnToProjectsBtn: ButtonProps = {
      children: 'Return to Projects',
      onClick: () => history.push('/projects'),
    }
    buttons.push(returnToProjectsBtn)

    if (errorOnLoad >= ProjectLoadErrorTypes.DESIGN_NON_RECOVERABLE) {
      const restartDesignBtn: ButtonProps = {
        children: 'Restart Design',
      }
      if (restartDesignFunc) {
        restartDesignBtn.onClick = restartDesignFunc
      }
      buttons.push(restartDesignBtn)
    }

    if (errorOnLoad >= ProjectLoadErrorTypes.DESIGN_RECOVERABLE) {
      const recoverDesignBtn: ButtonProps = {
        children: 'Recover Design',
      }
      if (recoverDesignFunc) {
        recoverDesignBtn.onClick = recoverDesignFunc
      }
      buttons.push(recoverDesignBtn)
    }

    const contactSupportBtn: ButtonProps = {
      color: 'primary',
      children: 'Contact Support',
      onClick: () => window.open(newSupportTicketLink, '_blank'),
    }
    buttons.push(contactSupportBtn)

    return buttons
  }, [restartDesignFunc, recoverDesignFunc, errorOnLoad])

  return (
    <Confirm
      open={errorOnLoad !== ProjectLoadErrorTypes.NONE}
      header={{
        title: translate(`Project failed to load.`),
      }}
      buttons={promptButtons}
      content={
        <Alert severity="error" options={{ style: { marginBottom: 15 } }}>
          {translate(
            'An error prevented the project to load. Please contact OpenSolar Support or choose how to proceed from the options below.'
          )}
        </Alert>
      }
    />
  )
}
