import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useCheckMinimumRequiredProps, useCreateController } from 'ra-core'
import React, { Children, cloneElement } from 'react'
import Subtitle from '../layout/Subtitle'
import Title from '../layout/Title'
import DefaultBreadCrumbs from './BreadCrumbs'
import DefaultActions from './CreateActions'

/**
 * Page component for the Create view
 *
 * The `<Create>` component renders the page title and actions.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes pass the `record` as prop.
 *
 * The <Create> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 *
 * @example
 *
 * // in src/posts.js
 * import React from 'react';
 * import { Create, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostCreate = (props) => (
 *     <Create {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Create>
 * );
 *
 * // in src/App.js
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostCreate } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" create={PostCreate} />
 *     </Admin>
 * );
 * export default App;
 */
const Create = (props) => {
  return <CreateView {...props} {...useCreateController(props)} />
}

Create.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  title: PropTypes.node,
  record: PropTypes.object,
  hasList: PropTypes.bool,
  successMessage: PropTypes.string,
}

export const CreateView = (props) => {
  const {
    actions,
    aside,
    basePath,
    children,
    classes: classesOverride,
    className,
    component: Content,
    defaultTitle,
    hasList,
    hasShow,
    record = {},
    redirect,
    resource,
    save,
    hideTitle,
    saving,
    breadCrumbs,
    hideBreadCrumbs,
    title,
    subtitle,
    version,
    options,
    ...rest
  } = props
  useCheckMinimumRequiredProps('Create', ['children'], props)
  const classes = useStyles(props)
  const finalActions = typeof actions === 'undefined' && hasList ? <DefaultActions /> : actions
  const finalTitle = title || (options.create && options.create.title)
  const finalSubtitle = subtitle || (options.create && options.create.subtitle)

  return (
    <div className={classnames('create-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      {!hideBreadCrumbs &&
        resource &&
        React.cloneElement(breadCrumbs, {
          record,
          resource,
          hasList,
          detail: 'create',
          options,
        })}
      <div
        className={classnames(classes.main, {
          [classes.noActions]: !finalActions,
        })}
      >
        <Content className={classes.card}>
          {!hideTitle && (
            <div style={{ padding: '0px 16px 0 16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: 68,
                  alignItems: 'center',
                }}
              >
                <Title title={finalTitle} record={record} defaultTitle={defaultTitle} />
                {finalActions &&
                  cloneElement(finalActions, {
                    basePath,
                    resource,
                    hasList,
                    //  Ensure we don't override any user provided props
                    ...finalActions.props,
                  })}
              </div>
              {finalSubtitle && <Subtitle subtitle={finalSubtitle} />}
            </div>
          )}
          {cloneElement(Children.only(children), {
            basePath,
            record,
            redirect: typeof children.props.redirect === 'undefined' ? redirect : children.props.redirect,
            resource,
            save,
            saving,
            version,
          })}
        </Content>
        {aside &&
          cloneElement(aside, {
            basePath,
            record,
            resource,
            save,
            saving,
            version,
          })}
      </div>
    </div>
  )
}

CreateView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
}

CreateView.defaultProps = {
  classes: {},
  options: {},
  component: Card,
  breadCrumbs: <DefaultBreadCrumbs />,
}

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: 'flex',
    },
    noActions: {
      // [theme.breakpoints.up('sm')]: {
      //   marginTop: '1em',
      // },
    },
    card: {
      marginTop: '12px',
      paddingBottom: '30px',
      flex: '1 1 auto',
    },
  }),
  { name: 'RaCreate' }
)

const sanitizeRestProps = ({
  actions,
  children,
  className,
  crudCreate,
  loading,
  loaded,
  saving,
  resource,
  title,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  successMessage,
  translate,
  ...rest
}) => rest

export default Create
