import { FC } from 'react'

/**
 * * Icon Component for: tag
 */
type TagOutlineIconProps = {
  size?: number
  color?: string
}
export const TagOutlineIcon: FC<TagOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M15.3543 6.65238L15.3479 6.65235M18.5229 1.85129L12.7226 1.40511C12.2083 1.36555 11.7024 1.55269 11.3377 1.91739L1.91787 11.3373C1.2279 12.0272 1.2279 13.1459 1.91787 13.8359L8.16441 20.0824C8.85439 20.7724 9.97306 20.7724 10.663 20.0824L20.0829 10.6626C20.4476 10.2978 20.6347 9.79198 20.5952 9.27773L20.149 3.47737C20.0822 2.6085 19.3918 1.91813 18.5229 1.85129Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
