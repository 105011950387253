import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip
} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddOutlined'
import ActionDelete from '@material-ui/icons/DeleteOutlined'
import PencilIcon from '@material-ui/icons/EditOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import FlashIcon from '@material-ui/icons/FlashOnOutlined'
import ContentSave from '@material-ui/icons/SaveOutlined'
import SyncIcon from '@material-ui/icons/SyncOutlined'
import { push as pushAction } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import _, { debounce } from 'lodash'
import { Button, CloseIcon, DialogTitle, IconButton, styled, Switch } from 'opensolar-ui'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import {
  inputValueToFloat,
  inputValueToFloatOrEmptyStringWhenNull,
  ManageIconButton,
  PriceOverrideButton,
  PriceOverrideLink,
  styles,
  valueOrEmptyStringIfNull
} from 'projectSections/sections/design/systems/PanelSystem'
import CommissionInputs from 'projectSections/sections/design/systems/tabs/pricing/CommissionInputs'
import CostingSelectInput from 'projectSections/sections/design/systems/tabs/pricing/CostingSelectInput'
import PriceBreakdown from 'projectSections/sections/design/systems/tabs/pricing/PriceBreakdown'
import { detectIsSystemPriceOverriddenByPaymentOption } from 'projectSections/sections/design/systems/tabs/summary/Summary'
import { useCallback, useEffect, useState } from 'react'
import { useLocale, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import {
  ADDER_FORMULA_CHOICES,
  ADDER_PRICE_TREATMENT_CHOICES,
  ADDER_TYPE_CHOICES,
  PRICE_ONLY_ADDER_FORMULA_CHOICES
} from 'resources/adders/AddersForm'
import { ADDER_DEALER_FEE_INTERACTION_CHOICES, getAdderDealerFeeChoices } from 'resources/adders/PriceAdderInputs'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { ActionType, EventType } from 'types/tracking'
import {
  formatCurrencyWithSymbol,
  isPricingLockedForStage,
  round_number_to_specific_decimal_places,
  taxNameForCountryIso2,
  taxRateDefaultForCountryIso2
} from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { setStudioSavePrompt as setStudioSavePromptAction } from '../../ducks/studioPrompt'
import { DISTRIBUTOR_LABEL } from '../../pages/ordering/constants'
import useHardwareDetailsDynamic from '../../projectSections/sections/design/sideDrawer/bom/useHardwareDetailsDynamic'
import useMapHardwareDetailsToBomLineItems from '../../projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import AutoApplyButton from './AutoApplyButton'
import PaymentOptionInputs from './paymentOptionInputs'
import SelectableAddersTable from './SelectableAddersTable'

export const FormSubSectionGrey = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.grey[50],
  borderRadius: 8,
  padding: '10px 20px',
  marginBottom: 10,
  marginTop: 15,
  gap: 10,
  flex: 1,
  alignItems: 'center',
  '& >div:first-of-type': {
    display: 'flex',
    flex: 4,
  },
  '& >div:last-of-type': {
    display: 'flex',
    flex: 1,
  },
  '& >div:last-of-type > button': {
    width: '100%',
  },
}))

export const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  '& > *': {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const StyledTrashIconButton = styled('div')(({ disabled }) => ({
  padding: 4,
  margin: 0,
  color: disabled ? 'lightgrey' : 'rgb(0,0,0)',
  cursor: disabled ? 'default' : 'pointer',
}));

const roundCurrencyWithDynamicDecimalPlaces = (value, currencySymbol, locale) => {
  if (value !== 0 && Math.abs(value) < 1) {
    return formatCurrencyWithSymbol(round_number_to_specific_decimal_places(value, 3), currencySymbol, locale, null)
  } else {
    return formatCurrencyWithSymbol(value, currencySymbol, locale, 0)
  }
}

const ADDER_FORMULA_FROM_DATABASE = {
  0: 'fixed',
  1: 'perWatt',
  2: 'perPanel',
}

const PRICE_ONLY_ADDER_FORMULA_FROM_DATABASE = {
  0: 'fixed',
  1: 'perWatt',
  2: 'perPanel',
  3: 'priceInclTax',
  4: 'priceExclTax',
  5: 'pricePayableInclTax',
  6: 'pricePayableExclTax',
}

const getNameFromChoices = (choices, id) => {
  return choices.find((choice) => choice.id === id)?.name || ''
}

const adderEndAdornmentValue = (adderFormula) => {
  switch (adderFormula) {
    case 'perWatt':
      return 'Per Watt'
    case 'perPanel':
      return 'Per Panel'
    case 'priceInclTax':
      return '% of Price inc tax'
    case 'priceExclTax':
      return '% of Price ex tax'
    case 'pricePayableInclTax':
      return '% of Price Payable Inc tax'
    case 'pricePayableExclTax':
      return '% of Price Payable ex tax'
    default:
      return 'Fixed'
  }
}

const useStyles = () =>
  makeStyles(
    (theme) => {
      return {
        button: {
          '&:hover': {
            cursor: 'pointer',
            background: theme.themeColorDark,
          },

          position: 'relative',
          padding: '4px 10px',
          margin: 10,
          width: 100,
          background: theme.themeColor,
          color: theme.headerFontColor,
        },
        fields: {
          width: '100%',
        },
      }
    },
    { name: 'pricing-form' }
  )()

const LineItemDialog = ({
  selectedLineItemIndex,
  panelSystem,
  currencySymbol,
  system,
  handleClose,
  countryIso2,
  showLibrarySelection,
  setShowLibrarySelection,
  setLineItemIndex,
}) => {
  const [state, setState] = useState({
    adder_id: null,
    type: 0, //price, cost, price_and_cost
    label: '',
    value: 0,
    cost_value: 0,
    formula: 'fixed',
    tax_included: taxRateDefaultForCountryIso2(countryIso2),
    show_customer: false,
    value_includes_tax: true,
    quantity: 1,
    unit_label: '',
    dealer_fee_interaction_type: 2,
    price_treatment: 0,
    show_customer_cost: false,
    display_as_line_item: false,
  })
  // const [selectedPmtId, setSelectedPmtId] = useState(undefined)
  const classes = useStyles()
  const translate = useTranslate()
  const isUs = useSelector(orgSelectors.getOrgIso2) === 'US'

  const { trackEvent } = useTrackComponent({
    componentKey: 'line_item_dialog',
    eventName: 'User Created Custom Adder On Project In Design',
  })

  useEffect(() => {
    if (system.line_items && system.line_items[selectedLineItemIndex]) {
      setState(system.line_items[selectedLineItemIndex])
    }
  }, [])
  const handleSave = () => {
    // Any manual update should break auto-sync
    system.autoSync.adders = false

    const lineItems = system.line_items ? [...system.line_items] : []

    const adderId = state.adder_id ? state.adder_id : 0 // Custom adders are retrived by index, adder ID just needs to not be null

    const newLineItem = { ...state, adder_id: adderId }

    if (newLineItem.type === 0) {
      // type is Price
      // clear cost related fields
      const value = isNaN(inputValueToFloat(newLineItem.value)) ? 0 : inputValueToFloat(newLineItem.value)
      const tax_included = isNaN(inputValueToFloat(newLineItem.tax_included))
        ? 0
        : inputValueToFloat(newLineItem.tax_included)
      lineItems[selectedLineItemIndex] = { ...newLineItem, tax_included, value, cost_value: null }
    } else if (newLineItem.type === 1) {
      // type is Cost
      // clear price related fields
      const cost_value = isNaN(inputValueToFloat(newLineItem.cost_value))
        ? 0
        : inputValueToFloat(newLineItem.cost_value)
      lineItems[selectedLineItemIndex] = { ...newLineItem, cost_value, tax_included: null, value: null }
    } else {
      // type is Price and Cost

      const value = isNaN(inputValueToFloat(newLineItem.value)) ? 0 : inputValueToFloat(newLineItem.value)
      const cost_value = isNaN(inputValueToFloat(newLineItem.cost_value))
        ? 0
        : inputValueToFloat(newLineItem.cost_value)
      const tax_included = isNaN(inputValueToFloat(newLineItem.tax_included))
        ? 0
        : inputValueToFloat(newLineItem.tax_included)
      lineItems[selectedLineItemIndex] = { ...newLineItem, value, tax_included, cost_value }
    }

    trackEvent(EventType.SYSTEM_EVENT, { type: ActionType.SAVE }, { ...newLineItem })

    window.editor.execute(new window.SetValueCommand(system, 'line_items', lineItems, undefined, true))
    handleClose()
  }

  const PopoverTooltip = withStyles(() => ({
    tooltip: {
      minWidth: 250,
      maxWidth: 'none',
    },
  }))(InfoTooltip)

  return (
    <Dialog open={true} style={{ maxHeight: 'calc(100vh - 56px)' }} maxWidth="md" className="line-item-dialog">
      <StyledTitle
        onClose={
          showLibrarySelection
            ? () => {
                handleClose()
              }
            : undefined
        }
      >
        <span>{translate('Apply Adder (or discount)')}</span>
        <IconButton id="close-adders-dialog" onClick={handleClose}>
          <Tooltip title={translate('Close Dialog')} enterDelay={300}>
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </StyledTitle>
      <DialogContent dividers>
        {showLibrarySelection && (
          <>
            <SelectableAddersTable />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 28 }}>
              <FormSubSectionGrey>
                <div>
                  <p>
                    {translate(
                      'You can create a library of price adders (or discounts) in Control > Pricing & Payment options.'
                    )}
                    {translate('Alternatively, "Enter Manually"')}
                  </p>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      const lineItems = system?.line_items || []
                      const index = lineItems.length
                      setLineItemIndex(index)
                      setShowLibrarySelection(false)
                    }}
                  >
                    <span>{translate('Enter manually')}</span>
                  </Button>
                </div>
              </FormSubSectionGrey>
              <div>
                <Button variant="contained" color="primary" onClick={handleClose} fullWidth>
                  <span>{translate('Save and Close')}</span>
                </Button>
              </div>
            </div>
          </>
        )}

        {!showLibrarySelection &&
          state.adder_id &&
          !window.AccountHelper.getAdderById(state.adder_id)?.matchesLineItem(state) && (
            <Alert severity="warning">
              <div>{translate('Selected Adder customised for project')}</div>
            </Alert>
          )}

        {!showLibrarySelection && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                select
                type="number"
                className={classes.fields}
                label={translate('Adder Type')}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.type}
                name="type"
                onFocus={() => {
                  markFieldActive.call(panelSystem, 'lineItemType', system)
                }}
                onBlur={() => {
                  markFieldInactive.call(panelSystem)
                }}
                onChange={(event) => {
                  const value = event.target.value
                  setState({ ...state, type: value })
                }}
              >
                {ADDER_TYPE_CHOICES.map((choice) => (
                  <MenuItem key={choice.id} value={choice.id}>
                    {translate(choice.name)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={translate('Label')}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                value={state.label}
                name="label"
                onFocus={() => {
                  markFieldActive.call(panelSystem, 'lineItemLabel', system)
                }}
                onBlur={() => {
                  markFieldInactive.call(panelSystem)
                }}
                onChange={(event) => {
                  const value = event.target.value
                  setState({ ...state, label: value })
                }}
              />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                select
                type="number"
                className={classes.fields}
                label={translate('Formula')}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.formula}
                name="formula"
                onFocus={() => {
                  markFieldActive.call(panelSystem, 'lineItemFormula', system)
                }}
                onBlur={() => {
                  markFieldInactive.call(panelSystem)
                }}
                onChange={(event) => {
                  const value = event.target.value
                  setState({ ...state, formula: value })
                }}
              >
                {state.type === 0
                  ? PRICE_ONLY_ADDER_FORMULA_CHOICES.map((config) => (
                      <MenuItem key={config.id} value={PRICE_ONLY_ADDER_FORMULA_FROM_DATABASE[config.id]}>
                        {translate(config.name)}
                      </MenuItem>
                    ))
                  : ADDER_FORMULA_CHOICES.map((config) => (
                      <MenuItem key={config.id} value={ADDER_FORMULA_FROM_DATABASE[config.id]}>
                        {translate(config.name)}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={4}>
              {state.formula === 'fixed' && (
                <TextField
                  label={translate('Unit label (optional)')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth={true}
                  value={state.unit_label}
                  name="unit_label"
                  onFocus={() => {
                    markFieldActive.call(panelSystem, 'lineItemUnitLabel', system)
                  }}
                  onBlur={() => {
                    markFieldInactive.call(panelSystem)
                  }}
                  onChange={(event) => {
                    const unit_label = event.target.value
                    setState({ ...state, unit_label })
                  }}
                />
              )}
            </Grid>

            <Grid item xs={6} sm={4}>
              {state.formula === 'fixed' && (
                <TextField
                  type="number"
                  className={classes.fields}
                  label={translate('Quantity')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.quantity}
                  name="quantity"
                  inputProps={{
                    min: 1,
                    step: 1,
                    pattern: '[0-9]*', // Only allow numeric input
                  }}
                  onFocus={() => {
                    markFieldActive.call(panelSystem, 'lineItemQuantity', system)
                  }}
                  onBlur={() => {
                    markFieldInactive.call(panelSystem)
                  }}
                  onChange={(event) => {
                    const value = event.target.value
                    const parsedValue = parseInt(value)

                    if (!isNaN(parsedValue) && parsedValue >= 1) {
                      setState({ ...state, quantity: parsedValue })
                    }
                  }}
                />
              )}
            </Grid>
            {isUs && (
              <Grid item xs={12} sm={6} lg={6}>
                <TextField
                  select
                  className={classes.fields}
                  label={translate('Price Treatment (optional)')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.price_treatment}
                  name="price_treatment"
                  onFocus={() => {
                    markFieldActive.call(panelSystem, 'lineItemPriceTreatment', system)
                  }}
                  onBlur={() => {
                    markFieldInactive.call(panelSystem)
                  }}
                  onChange={(event) => {
                    const value = event.target.value
                    setState({ ...state, price_treatment: value })
                  }}
                >
                  {ADDER_PRICE_TREATMENT_CHOICES.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {translate(choice.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {(state.type === 0 || state.type === 2) && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={translate('Price Adder')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.value}
                    name="value"
                    fullWidth={true}
                    onFocus={() => {
                      markFieldActive.call(panelSystem, 'lineItemValue', system)
                    }}
                    onBlur={() => {
                      markFieldInactive.call(panelSystem)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {state?.formula?.includes('price') ? '%' : currencySymbol}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {state?.formula?.includes('price') ? '' : translate(adderEndAdornmentValue(state.formula))}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      const value = event.target.value
                      setState({ ...state, value })
                    }}
                    helperText={`${translate('Discount')}(-)/${translate('Adder')}(+)`}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={translate('Tax (%)')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.tax_included}
                    name="tax_included"
                    onFocus={() => {
                      markFieldActive.call(panelSystem, 'lineItemTax', system)
                    }}
                    onBlur={() => {
                      markFieldInactive.call(panelSystem)
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    onChange={(event) => {
                      const tax_included = event.target.value
                      setState({ ...state, tax_included })
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    type="number"
                    className={classes.fields}
                    label={translate('Dealer Fee Interaction Type')}
                    defaultValue={2}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.dealer_fee_interaction_type}
                    name="dealer_fee_interaction_type"
                    onFocus={() => {
                      markFieldActive.call(panelSystem, 'lineItemFormula', system)
                    }}
                    onBlur={() => {
                      markFieldInactive.call(panelSystem)
                    }}
                    onChange={(event) => {
                      const value = event.target.value
                      setState({ ...state, dealer_fee_interaction_type: value })
                    }}
                  >
                    {getAdderDealerFeeChoices(state.show_customer).map((choice) => {
                      return (
                        <MenuItem key={choice.id} value={choice.id}>
                          {translate(choice.name)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        labelPosition="right"
                        checked={state.value_includes_tax}
                        onChange={(event) => {
                          setState({ ...state, value_includes_tax: event.target.checked })
                        }}
                      />
                    }
                    label={translate('Including Tax')}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        labelPosition="right"
                        checked={state.show_customer}
                        onChange={(event) => {
                          setState({ ...state, show_customer: event.target.checked })
                        }}
                      />
                    }
                    label={translate('Show to Customer')}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <PopoverTooltip
                    placement="bottom-start"
                    title={translate(
                      'Toggling "Display as line item on invoices" to on will cause adders to appear as separate line items on all Invoices created using OpenSolar CashFlow'
                    )}
                    interactive
                  ></PopoverTooltip>
                  <FormControlLabel
                    control={
                      <Switch
                        labelPosition="right"
                        checked={state.display_as_line_item}
                        onChange={(event) => {
                          setState({ ...state, display_as_line_item: event.target.checked })
                        }}
                      />
                    }
                    label={translate('Display as line item on invoices')}
                  />
                </Grid>
              </>
            )}

            {state.type === 2 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            {(state.type === 1 || state.type === 2) && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={translate('Cost Adder (ex tax)')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={state.cost_value}
                    name="cost"
                    onFocus={() => {
                      markFieldActive.call(panelSystem, 'lineItemCostValue', system)
                    }}
                    onBlur={() => {
                      markFieldInactive.call(panelSystem)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                      endAdornment: (
                        <InputAdornment position="end">{adderEndAdornmentValue(state.formula)}</InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      const cost_value = event.target.value
                      setState({ ...state, cost_value })
                    }}
                    helperText={`${translate('Discount')}(-)/${translate('Adder')}(+)`}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.show_customer_cost}
                        onChange={(event) => {
                          setState({ ...state, show_customer_cost: event.target.checked })
                        }}
                      />
                    }
                    label={translate('Show to Customer when using Markup Pricing')}
                    labelPlacement="start"
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </DialogContent>
      {!showLibrarySelection && (
        <DialogActions style={{ paddingTop: 0 }}>
          <Button
            startIcon={<AlertError />}
            onClick={handleClose}
            style={{ margin: 10, position: 'relative' }}
            // backgroundColor="#D8D8D8"
            variant="contained"
            color="default"
          >
            <span>{translate('Cancel')}</span>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<ContentSave />}
            name={'raSaveButton'}
            onClick={handleSave}
          >
            <span>{translate('Save')}</span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

const LineItems = ({ panelSystem, system, currencySymbol, countryIso2, features, pricingIsLocked }) => {
  const locale = useLocale()
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_price_adders')
  )
  const [isOpen, setOpen] = useState(false)
  const [showLibrarySelection, setShowLibrarySelection] = useState(false)
  const [selectedLineItemIndex, setLineItemIndex] = useState(0)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  
  const isDeleteButtonDisabled = (line_item) => {
    return !isAdmin && line_item?.only_admin_can_remove === true
  }
  
  const translate = useTranslate()
  if (!allowView) {
    return null
  }
  return (
    <div>
      <p style={{ fontSize: 14, fontWeight: 'bold' }}>
        {translate('Adders & Discounts')}
        {window.editor.selectedSystem?.autoSync?.adders === true && (
          <FlashIcon style={{ height: 20, verticalAlign: 'top', fontSize: '1em' }} />
        )}
      </p>

      {false && allowCreate && (
        <AutoApplyButton pricingIsLocked={pricingIsLocked} warningKey={'ADDERS-AUTO-APPLY'} systemId={system.uuid} />
      )}

      {system.line_items &&
        system.line_items.map((line_item, index) => {
          var adderLibraryItem = window.AccountHelper.getAdderById(line_item.adder_id)
          var isAutoApply = adderLibraryItem ? new window.AutoApplyHelper().matchesAutoApply(adderLibraryItem) : false
          return (
            <div key={line_item.label + line_item.index} style={{ margin: '20px 0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', width: '50%' }}>
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {line_item.label}
                  </span>
                  {allowEdit && (
                    <IconButton
                      onClick={() => {
                        setLineItemIndex(index)
                        setShowLibrarySelection(false)
                        setOpen(true)
                      }}
                      style={{ padding: 4, margin: 0, color: 'rgb(0,0,0)' }}
                    >
                      <PencilIcon color="black" fontSize="small" />
                    </IconButton>
                  )}
                  {allowDelete && (

                    <Tooltip title={isDeleteButtonDisabled(line_item) ? "Locked: Only admin can remove this adder" : ""}>
                      <span>
                        <StyledTrashIconButton
                          onClick={() => {
                            const newValue = [...system.line_items]
                            newValue.splice(index, 1)
                            system.autoSync.adders = false
                            window.editor.execute(
                              new window.SetValueCommand(system, 'line_items', newValue, undefined, true)
                            )
                          }}
                          disabled={isDeleteButtonDisabled(line_item)}
                        >
                          <ActionDelete color="black" fontSize="small" />
                        </StyledTrashIconButton>
                      </span>
                    </Tooltip>

                  )}
                </div>
              </div>
              {/* <div style={{ overflow: 'visible', textAlign: 'right' }}>
                {line_item.value > 0 && (
                  <div style={{ display: 'flex', margin: '5px 0', alignItems: 'baseline' }}>
                    {`Price:  ${currencySymbol}${line_item.value.toFixed(2)}`}{' '}
                    <span style={{ fontSize: 11, color: '#a2a2a2', paddingLeft: 5 }}>
                      {adderEndAdornmentValue(line_item.formula)}
                    </span>
                  </div>
                )}
                {line_item.cost_value > 0 && (
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    {`Cost:  ${currencySymbol}${line_item.cost_value.toFixed(2)}`}{' '}
                    <span style={{ fontSize: 11, color: '#a2a2a2', paddingLeft: 5 }}>
                      {adderEndAdornmentValue(line_item.formula)}
                    </span>
                  </div>
                )}
              </div> */}
              <div
                style={{
                  margin: '5px 0',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'end',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  {_.isNumber(line_item.value) && <div>{translate('Price') + ':'}</div>}
                  {_.isNumber(line_item.cost_value) && <div>{translate('Cost') + ':'}</div>}
                </div>
                <div>
                  {_.isNumber(line_item.value) && (
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'right' }}>
                      {line_item.formula?.includes('price')
                        ? formatCurrencyWithSymbol(line_item.value, '', locale, 2)
                        : formatCurrencyWithSymbol(line_item.value, currencySymbol, locale, 2)}{' '}
                      <span style={{ fontSize: 11, color: '#a2a2a2', paddingLeft: 5 }}>
                        {adderEndAdornmentValue(line_item.formula)}
                      </span>
                    </div>
                  )}
                  {_.isNumber(line_item.cost_value) && (
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'right' }}>
                      {line_item.formula?.includes('price')
                        ? formatCurrencyWithSymbol(line_item.cost_value, '', locale, 2)
                        : formatCurrencyWithSymbol(line_item.cost_value, currencySymbol, locale, 2)}{' '}
                      <span style={{ fontSize: 11, color: '#a2a2a2', paddingLeft: 5 }}>
                        {adderEndAdornmentValue(line_item.formula)}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div style={{ display: 'flex', margin: '5px 0 10px 0' }}>
                <div style={{ flex: '1 1', fontSize: 11, color: '#a2a2a2', margin: 0 }}>
                  {`${getNameFromChoices(ADDER_TYPE_CHOICES, line_item.type)} ${translate('Adder')} | `}
                  {line_item.tax_included
                    ? `Tax ${line_item.value_includes_tax ? translate('Including') : translate('Excluding')}: ${
                        line_item.tax_included
                      }% | `
                    : translate('Tax: Not Set') + ' | '}
                  {line_item.show_customer && translate('Shown to Customer') + '| '}
                  {`${getNameFromChoices(
                    ADDER_DEALER_FEE_INTERACTION_CHOICES,
                    line_item.dealer_fee_interaction_type
                  )} | `}
                  {line_item.adder_id &&
                    (adderLibraryItem?.matchesLineItem(line_item) ? (
                      <span>
                        {translate('Adder Library')}
                        {isAutoApply && window.editor.selectedSystem?.autoSync?.adders === true && (
                          <FlashIcon style={{ height: 16, verticalAlign: 'top', fontSize: '1em' }} />
                        )}
                      </span>
                    ) : (
                      <span style={{ color: '#000000' }}>
                        {translate('Selected Adder customised for project')}
                        {isAutoApply && window.editor.selectedSystem?.autoSync?.adders === true && (
                          <FlashIcon style={{ height: 16, verticalAlign: 'top', fontSize: '1em' }} />
                        )}
                      </span>
                    ))}
                </div>
              </div>

              {line_item.formula === 'fixed' && (
                <div style={{ marginTop: 10 }}>
                  <TextField
                    type="number"
                    label={line_item.unit_label || translate('Qty')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        padding: 10,
                        width: 100,
                      },
                      min: 1,
                      step: 1,
                      pattern: '[0-9]*', // Only allow numeric input
                    }}
                    value={line_item.quantity}
                    onFocus={() => {
                      markFieldActive.call(panelSystem, 'lineItemCostValue', system)
                    }}
                    onBlur={() => {
                      markFieldInactive.call(panelSystem)
                    }}
                    variant="outlined"
                    onChange={(event) => {
                      // Changing quantity does NOT break auto-sync
                      const value = event.target.value
                      let parsedValue = parseInt(value)

                      if (isNaN(parsedValue) || parsedValue < 1) {
                        parsedValue = 1
                      }
                      event.target.value = parsedValue

                      const quantity = parsedValue
                      const lineItems = [...system.line_items]
                      lineItems[index] = { ...line_item, quantity }
                      window.editor.execute(
                        new window.SetValueCommand(system, 'line_items', lineItems, undefined, true)
                      )
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}

      {!system?.line_items?.length && <p>{translate('No adders or discounts applied')}</p>}

      {allowCreate && (
        <Button
          style={{
            minWidth: 40,
            marginTop: 10,
            width: '100%',
          }}
          variant="contained"
          color="default"
          disabled={pricingIsLocked}
          onClick={(e) => {
            const index = (system?.line_items && system?.line_items?.length) || 0
            setLineItemIndex(index)
            setShowLibrarySelection(true)
            setOpen(true)
          }}
          startIcon={<AddIcon />}
        >
          <span>{translate('Adder/Discount')}</span>
        </Button>
      )}
      {isOpen && (
        <LineItemDialog
          setLineItemIndex={setLineItemIndex}
          selectedLineItemIndex={selectedLineItemIndex}
          currencySymbol={currencySymbol}
          countryIso2={countryIso2}
          panelSystem={panelSystem}
          system={system}
          handleClose={() => setOpen(false)}
          showLibrarySelection={showLibrarySelection}
          setShowLibrarySelection={setShowLibrarySelection}
        />
      )}
    </div>
  )
}

const CurrencyValueOrPercentage = (props) => {
  if (props.value?.includes && props.value.includes('%')) {
    return props.value
  } else {
    return <>{roundCurrencyWithDynamicDecimalPlaces(props.value, props.currencySymbol, props.locale)}</>
  }
}

const CostSection = ({ item, level, currencySymbol, formatCurrency }) => {
  const translate = useTranslate()
  const locale = useLocale()
  return (
    <div
      className={
        level === 1
          ? item.format === 'highlight'
            ? 'breakdown-highlight'
            : item.format === 'highlight-color'
            ? 'breakdown-highlight-color'
            : ''
          : 'small-studio-note'
      }
      style={{
        borderTop: level === 1 ? '1px solid rgb(221, 221, 221)' : '',
        padding: level === 1 ? '5px 5px' : '',
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 1' }}>{translate(item.label).replace('$', currencySymbol)}</div>
        {item.value !== null && (
          <div style={{ flex: '0 0 45px', textAlign: 'right' }}>
            <CurrencyValueOrPercentage value={item.value} currencySymbol={currencySymbol} locale={locale} />
          </div>
        )}
      </div>
      {item.items &&
        item.items.length > 0 &&
        item.items.map((subItem, index) => (
          <CostSection key={index} item={subItem} level={level + 1} currencySymbol={currencySymbol} />
        ))}
    </div>
  )
}

export const CostsBreakdown = ({ sectionData, currencySymbol }) => (
  <div
    id="price_breakdown"
    className="studio-panel-system-margin"
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: 10,
      marginBottom: 20,
      background: 'rgb(240, 240, 240)',
      // borderBottom: '1px solid rgb(221,221,221)',
    }}
  >
    {sectionData.map((item, index) => (
      <CostSection item={item} key={index} level={1} currencySymbol={currencySymbol} />
    ))}
  </div>
)

// const PriceBreakdownContent = ({ breakdownSections, currencySymbol }) => {
//   const translate = useTranslate()
//   const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('design_cost_breakdown'))
//   const getHasAdjustedMargin = useCallback(() => {
//     let marginSection = breakdownSections?.find((section) => section.label?.includes('Margin'))
//     return marginSection?.items?.length > 0
//   }, [breakdownSections])
//   if (!allowView) {
//     return null
//   }
//   return (
//     <div>
//       <p style={{ fontSize: 14, fontWeight: 'bold' }}>{translate('Price Breakdown')}</p>
//       {breakdownSections && breakdownSections.length > 0 ? (
//         <>
//           {breakdownSections.map((sectionData, index) => (
//             <CostsBreakdown key={'top' + index} sectionData={sectionData} currencySymbol={currencySymbol} />
//           ))}
//           <div style={{ padding: '0 5px', fontSize: 12 }}>
//             {getHasAdjustedMargin() && (
//               <span>
//                 *
//                 <span>
//                   {' '}
//                   {translate(
//                     'Pricing calculations do not include payment specific variations due to dealer fee or discounts.'
//                   )}{' '}
//                 </span>
//               </span>
//             )}
//             ^<span> {translate('Base price and dealer fee breakdown not visible to customer.')}</span>
//           </div>
//         </>
//       ) : (
//         <span>{translate('Not available.')}</span>
//       )}
//     </div>
//   )
// }

// const PriceBreakdownDialog = ({ breakdownSections, currencySymbol }) => {
//   const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('design_cost_breakdown'))
//   const [isOpen, setOpen] = useState(false)
//   const translate = useTranslate()
//   return (
//     <div>
//       <PrimaryButton
//         style={{
//           minWidth: 40,
//           margin: '0px 5px 5px 0px',
//           width: '100%',
//         }}
//         wrapperStyle={{ textAlign: 'center', display: 'block' }}
//         labelWrapperStyle={{
//           minWidth: 32,
//           paddingLeft: 8,
//           paddingRight: 8,
//         }}
//         disabled={!allowView}
//         onClick={(e) => setOpen(true)}
//         label={translate('View Price Breakdown')}
//       />
//       <Dialog
//         open={isOpen}
//         style={{ maxHeight: 'calc(100vh - 56px)' }}
//         maxWidth="xs"
//         onBackdropClick={() => setOpen(false)}
//         className="price-breakdown-dialog"
//       >
//         <DialogContent>
//           <PriceBreakdownContent breakdownSections={breakdownSections} currencySymbol={currencySymbol} />
//         </DialogContent>
//       </Dialog>
//     </div>
//   )
// }

// const stripSensitiveBreakdownData = (breakdownSections) => {
//   // Filter sensitive breakdownSectionEntries
//   breakdownSections = breakdownSections.map((breakdownSection) => {
//     return breakdownSection.filter((breakdownSectionEntry) => breakdownSectionEntry.sensitive !== true)
//   })

//   // Filter sensitive breakdownSectionItems
//   breakdownSections.forEach((breakdownSection) => {
//     breakdownSection.forEach((breakdownSectionEntry) => {
//       if (breakdownSectionEntry.items) {
//         breakdownSectionEntry.items = breakdownSectionEntry.items.filter((item) => item.sensitive !== true)
//       }
//     })
//   })

//   // Finally, strip any sections with no items
//   breakdownSections = breakdownSections.filter((breakdownSection) => breakdownSection.length > 0)

//   return breakdownSections
// }

const hasExistValue = (value) => {
  if (value) {
    return Boolean(parseFloat(value))
  } else {
    return false
  }
}

const _PricingDetail = ({
  system,
  systems,
  systemsLoadingUuids,
  currencySymbol,
  features,
  countryIso2,
  pricingSchemes,
  setStudioSavePrompt,
  panelSystem,
  nonSolarPriceIncludedRaw,
  nonSolarProjectType,
  addersPerSystemRaw,
  push,
  basicPriceRaw,
  basicPriceRawPerWatt,
  addersPerPanelRaw,
  addersPerWattRaw,
  discountRaw,
  updateFieldState,
  taxOverrideHardwareRaw,
  taxOverrideNonHardwareRaw,
}) => {
  const hardwareDetails = useHardwareDetailsDynamic()
  const bomLineItemsHashMap = useMapHardwareDetailsToBomLineItems(hardwareDetails)
  const [selectedPaymentId, setSelectedPmtId] = useState(undefined)
  const cogsModalEnabled = useFeatureFlag('enable_cogs_dialog', 'on')

  //org sharing settings
  const form = useForm()
  const sharedWith = form.getState().values.shared_with

  // Fix tax overrides labels
  const translate = useTranslateWithVariable()
  const locale = useLocale()
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('design_pricing'))
  const { allowView: allowViewCostingOverride, allowEdit: allowEditCostingOverride } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_costing_override')
  )

  const { allowView: allowViewTaxOverride, allowEdit: allowEditTaxOverride } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_tax_override')
  )

  const isPriceOverriddenByPaymentOption = detectIsSystemPriceOverriddenByPaymentOption(system)

  const [showLegacyField, setShowLegacyField] = useState(false)

  const { trackEvent } = useTrackComponent({
    componentKey: 'pricing_detail',
    eventName: 'User Viewed Pricing In Design',
  })

  useEffect(() => {
    if ([addersPerSystemRaw, addersPerPanelRaw, addersPerWattRaw, discountRaw].some((value) => hasExistValue(value))) {
      setShowLegacyField(true)
    }
  }, [])

  const handleChoosePricingScheme = useCallback((value, uuid) => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.SELECT }, { eventName: 'User Selected Pricing Scheme' })
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'pricing_scheme_id', value))
    if (value) {
      // Clear price override if adding a pricing scheme
      window.editor.execute(new window.SetValueCommand(system, 'basicPriceOverride', null))
      window.editor.execute(new window.SetValueCommand(system, 'basicPriceOverridePerWatt', null))
    } else {
      if (value === 0) {
        window.editor.execute(new window.SetValueCommand(system, 'basicPriceOverride', null))
        window.editor.execute(new window.SetValueCommand(system, 'basicPriceOverridePerWatt', 0))
      } else {
        // Copy calculated price into price override
        if (system.pricing && system.pricing.system_price_including_tax) {
          const calculatedValue = system.pricing.system_price_including_tax + system.pricing.discount
          window.editor.execute(
            new window.SetValueCommand(
              system,
              'basicPriceOverride',
              round_number_to_specific_decimal_places(calculatedValue, 2)
            )
          )
        }
        window.editor.execute(new window.SetValueCommand(system, 'basicPriceOverridePerWatt', null))
      }
      // Clear adders if using price override
      window.editor.execute(new window.SetValueCommand(system, 'non_solar_price_included', 0))
      window.editor.execute(new window.SetValueCommand(system, 'adders_per_system', 0))
      window.editor.execute(new window.SetValueCommand(system, 'adders_per_panel', 0))
      window.editor.execute(new window.SetValueCommand(system, 'adders_per_watt', 0))
    }

    trackEvent(
      EventType.SYSTEM_EVENT,
      { type: ActionType.UPDATE },
      { eventName: 'User Updated Pricing Scheme', value, uuid }
    )

    // Any manual update should break auto-sync
    system.autoSync.pricingScheme = false
  }, [])

  const debouncedTrackEvent = debounce((type, action, data) => {
    trackEvent(type, action, data)
  }, 500)

  const handleObjectChangedAsCommand = useCallback((uuid, attributeName, newValue) => {
    if (uuid && !isNaN(newValue) && !window.editor.changingHistory) {
      var object = window.editor.objectByUuid(uuid)
      if (object) {
        window.editor.execute(new window.SetValueCommand(object, attributeName, newValue, undefined, true))
      }
      debouncedTrackEvent(
        EventType.SYSTEM_EVENT,
        { type: ActionType.UPDATE },
        { eventName: 'User Added Value To Tax Overrides', attributeName, uuid, value: newValue }
      )
    }
  }, [])

  // showCostAndMargin === true ? breakdownSectionsRaw : stripSensitiveBreakdownData(breakdownSectionsRaw)
  const formValues = useFormState().values
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const pricingIsLocked = isPricingLockedForStage(formValues, orgWorkflows) && system.override_price_locking !== true
  const pricingSchemeId = system.pricing_scheme_id
  let selectedPmt = system?.payment_options?.find((pmt) => pmt?.id === selectedPaymentId && selectedPaymentId)
  let system_price_payable =
    selectedPmt?.pricing?.system_price_payable !== undefined
      ? selectedPmt.pricing.system_price_payable
      : system?.pricing?.system_price_payable
  let hasPriceDiff = system_price_payable !== system?.pricing?.system_price_payable

  const getCurrentPricingSchemeValue = useCallback(() => {
    if (system.pricing_scheme_id === 0) return 0
    else return system.pricing_scheme_id ? system.pricing_scheme_id : 'manual'
  }, [system.pricing_scheme_id])

  if (!allowView) {
    return null
  }

  const cogsOverrideExists = system.getCogsOverride() && Object.keys(system.getCogsOverride()).length > 0

  let taxName = taxNameForCountryIso2(countryIso2)
  taxName = taxName === 'tax' ? 'Tax' : taxName
  const otherPriceSchemeOptions = () => {
    const returnList = []
    if (!!system.pricing_scheme_id) {
      const priceSchemeIds = pricingSchemes.map((x) => x.id)
      const getPriceScheme = window.AccountHelper.getPricingSchemeById(system.pricing_scheme_id)
      if (getPriceScheme && !priceSchemeIds.includes(system.pricing_scheme_id)) {
        returnList.push({
          id: system.pricing_scheme_id,
          title: `${getPriceScheme?.title} ${getPriceScheme?.is_archived ? 'archived' : ''}`,
        })
      }
    }
    return returnList
  }

  return (
    <div style={allowEdit ? styles.expansionPanelDetailsContainer : { pointerEvents: 'none', cursor: 'not-allowed' }}>
      {system.pricing && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{translate('Purchase Price')}</span>{' '}
            <span style={{ textDecoration: isPriceOverriddenByPaymentOption ? 'line-through' : 'unset' }}>
              {formatCurrencyWithSymbol(system_price_payable, currencySymbol, locale, 0)}
            </span>
          </div>
          {hasPriceDiff && selectedPmt && <span className="small">for {selectedPmt.title}</span>}
          <PriceOverrideLink
            linkStyle={{ fontSize: 12, margin: '10px 0', display: 'block' }}
            linkVisibility={isPriceOverriddenByPaymentOption}
            systemsLoadingUuids={systemsLoadingUuids}
            pricingIsLocked={pricingIsLocked}
            currencySymbol={currencySymbol}
            systems={systems}
            tab="pricing"
          />
        </>
      )}
      <div id="pricing-detail-container" style={pricingIsLocked ? { opacity: 0.2, pointerEvents: 'none' } : {}}>
        <div style={{ display: 'flex' }}>
          <SharedEntitySelectInput
            choices={pricingSchemes}
            style={{ flex: '1 1', width: 'auto' }}
            variant="outlined"
            standardPadding={true}
            label={
              <span>
                {translate('Pricing Scheme')}
                {window.editor.selectedSystem?.autoSync?.pricingScheme === true && (
                  <FlashIcon style={{ height: 20, verticalAlign: 'top' }} />
                )}
              </span>
            }
            MenuProps={{ style: { zIndex: 2500 } }}
            id="PricingSchemeSelectInput"
            value={getCurrentPricingSchemeValue()}
            onChange={(event) => {
              const value = event.target.value
              if (value === 'manage') {
                setStudioSavePrompt({
                  show_prompt: true,
                  redirect: '/pricing_schemes',
                  prompt_heading: 'Leave Studio to manage available Pricing Schemes?',
                  prompt_message: 'Any unsaved changes will be discarded.',
                })
                return
              } else if (value === 'manual') {
                handleChoosePricingScheme(null, system.uuid)
              } else {
                handleChoosePricingScheme(value, system.uuid)
              }
            }}
            disabled={pricingIsLocked}
            defaultOptions={[
              { id: 'manual', title: translate('Enter Price Manually %{currencySymbol}', { currencySymbol }) },
              { id: 0, title: translate('Enter Price Manually %{currencySymbol}/W', { currencySymbol }) },
              { id: 'manage', title: 'Manage Pricing Schemes' },
            ].concat(otherPriceSchemeOptions())}
            optionText={(choice) => translate(choice?.title)}
          />

          <ManageIconButton
            heading={translate('Leave Studio to manage available Pricing Schemes?')}
            tooltipLabel={translate('Manage Pricing Schemes')}
            url={'/pricing_schemes'}
            push={push}
          />
        </div>
        {pricingSchemeId === null && (
          <TextField
            type="number"
            label={translate('System Price')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="System Price"
            value={valueOrEmptyStringIfNull(basicPriceRaw)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'basicPriceRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('basicPriceRaw', value)
              var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

              handleObjectChangedAsCommand(system.uuid, 'basicPriceOverride', valueAsFloatOrNull)
            }}
            errorText={
              null && isNaN(inputValueToFloatOrEmptyStringWhenNull(basicPriceRaw))
                ? translate('Supply system price as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked}
          />
        )}

        {pricingSchemeId === 0 && (
          <TextField
            type="number"
            label={translate('System Price Per Watt')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="System Price per Watt"
            value={valueOrEmptyStringIfNull(basicPriceRawPerWatt)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'basicPriceRawPerWatt', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}/W</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('basicPriceRawPerWatt', value)
              var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

              handleObjectChangedAsCommand(system.uuid, 'basicPriceOverridePerWatt', valueAsFloatOrNull)
            }}
            errorText={
              null && isNaN(inputValueToFloatOrEmptyStringWhenNull(basicPriceRawPerWatt))
                ? 'Supply system price as a number or leave blank'
                : null
            }
            disabled={pricingIsLocked}
          />
        )}

        {!!pricingSchemeId && showLegacyField && (
          <TextField
            type="number"
            label={translate('Price Adders')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="Price Adders"
            value={addersPerSystemRaw}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'addersPerSystemRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('fixed, inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('addersPerSystemRaw', value)
              var valueAsFloat = inputValueToFloat(value)
              if (!isNaN(valueAsFloat)) {
                handleObjectChangedAsCommand(system.uuid, 'adders_per_system', valueAsFloat)
              }
            }}
            errorText={
              isNaN(inputValueToFloat(addersPerSystemRaw))
                ? translate('Supply price adders as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked}
          />
        )}

        {!!pricingSchemeId && showLegacyField && (
          <TextField
            type="number"
            label={translate('Price Adders')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="Price Adders"
            value={addersPerPanelRaw}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'addersPerPanelRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('per panel, inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('addersPerPanelRaw', value)
              var valueAsFloat = inputValueToFloat(value)
              if (!isNaN(valueAsFloat)) {
                handleObjectChangedAsCommand(system.uuid, 'adders_per_panel', valueAsFloat)
              }
            }}
            errorText={
              isNaN(inputValueToFloat(addersPerPanelRaw))
                ? translate('Supply price adders per panel as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked}
          />
        )}

        {!!pricingSchemeId && showLegacyField && (
          <TextField
            type="number"
            label={translate('Price Adders')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="Price Adders"
            value={addersPerWattRaw}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'addersPerWattRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('per watt, inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('addersPerWattRaw', value)
              var valueAsFloat = inputValueToFloat(value)
              if (!isNaN(valueAsFloat)) {
                handleObjectChangedAsCommand(system.uuid, 'adders_per_watt', valueAsFloat)
              }
            }}
            errorText={
              isNaN(inputValueToFloat(addersPerWattRaw))
                ? translate('Supply price adders per watt as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked}
          />
        )}
        {showLegacyField && (
          <TextField
            type="number"
            label={translate('Discount')}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="Discount"
            value={discountRaw}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'discountRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              endAdornment: <InputAdornment position="end">{translate('inc tax')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('discountRaw', value)
              var valueAsFloat = inputValueToFloat(value)
              if (!isNaN(valueAsFloat)) {
                handleObjectChangedAsCommand(system.uuid, 'discount', valueAsFloat)
              }
            }}
            errorText={
              isNaN(inputValueToFloat(discountRaw)) ? translate('Supply discount as a number or leave blank') : null
            }
            disabled={pricingIsLocked}
          />
        )}

        <CostingSelectInput system={system} pricingIsLocked={pricingIsLocked} sharedWith={sharedWith} />
        <CommissionInputs system={system} pricingIsLocked={pricingIsLocked} />
        {countryIso2 === 'US' && (
          <>
            <TextField
              type="number"
              label={translate('Non-Solar Amount Included')}
              style={styles.inputField}
              underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
              name="Non-Solar Amount Included"
              value={nonSolarPriceIncludedRaw}
              onFocus={() => {
                markFieldActive.call(panelSystem, 'nonSolarPriceIncludedRaw', system)
              }}
              onBlur={() => {
                markFieldInactive.call(panelSystem)
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{translate('inc tax')}</InputAdornment>,
              }}
              onChange={(event) => {
                const value = event.target.value
                updateFieldState('nonSolarPriceIncludedRaw', value)
                var valueAsFloat = inputValueToFloat(value)
                if (!isNaN(valueAsFloat)) {
                  handleObjectChangedAsCommand(system.uuid, 'non_solar_price_included', valueAsFloat)
                }
              }}
              errorText={
                isNaN(inputValueToFloat(nonSolarPriceIncludedRaw))
                  ? translate('Supply Non-Solar Price Included as a number or leave blank')
                  : null
              }
              disabled={pricingIsLocked}
            />
            <p className="small">{translate('(Already included in price)')}</p>
          </>
        )}
        <PaymentOptionInputs
          system={system}
          updateFieldState={updateFieldState}
          nonSolarProjectType={nonSolarProjectType}
        />
      </div>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 20 }} />
      </Grid>
      <LineItems
        currencySymbol={currencySymbol}
        features={features}
        pricingIsLocked={pricingIsLocked}
        countryIso2={countryIso2}
        panelSystem={panelSystem}
        system={system}
      />
      <Grid item xs={12}>
        <Divider style={{ marginTop: 20 }} />
      </Grid>
      <PriceOverrideButton
        systemsLoadingUuids={systemsLoadingUuids}
        pricingIsLocked={pricingIsLocked}
        currencySymbol={currencySymbol}
        systems={systems}
        tab="pricing"
        disabled={!allowViewCostingOverride || !allowEditCostingOverride}
      />

      <PriceBreakdown paymentOptions={system?.payment_options} onPmtChange={setSelectedPmtId} />
      {!cogsModalEnabled && (countryIso2 === 'GB' || cogsOverrideExists === true) && (
        <>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 20 }} />
          </Grid>
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>COGS Overrides</p>
          <p className="small">
            COGS are set based on the wholesale price when the component is added to the system. Clearing COGS overrides
            will allow COGS to be refreshed from the current wholesale price when a component is next added or modified.
          </p>
          {system.getCogsOverride() &&
            Object.entries(system.getCogsOverride()).map(([key, override]) => {
              const price = override.price
              const priceSource =
                override.source && override.source === 'unknown' ? 'others' : DISTRIBUTOR_LABEL[override.source]
              const priceSourceText =
                override.source && override.source === 'manual'
                  ? 'Pricing was manually overridden'
                  : `Pricing source from ${priceSource}`
              let componentActivation = window.AccountHelper.getComponentActivationSpecs(parseInt(key))
              if (componentActivation) {
                let lineItem = bomLineItemsHashMap.all?.find((item) => item.code === componentActivation.code)
                let distributorPrice = lineItem?.getBestDiscountOffer()?.afterDiscount
                if (distributorPrice) {
                  distributorPrice = distributorPrice / lineItem.quantity
                }
                let priceHasChanged = distributorPrice && distributorPrice !== price
                return (
                  <div style={{ display: 'flex', marginBottom: 10 }}>
                    <div style={Object.assign({ flex: '1' }, priceHasChanged ? { color: 'red' } : {})}>
                      {componentActivation.code}: {formatCurrencyWithSymbol(price, currencySymbol, locale, 2)}
                      {priceHasChanged && (
                        <div className="small" style={{ color: 'red' }}>
                          * Price has changed
                        </div>
                      )}
                      {priceSourceText && <div className="small">{priceSourceText}</div>}
                    </div>
                    {
                      <PencilIcon
                        style={{ width: 18, height: 18, cursor: 'pointer', color: '#4d4d4d' }}
                        onClick={() => {
                          var cogsOverrideRaw = prompt('Enter COGS Override...')
                          var cogsOverride = parseFloat(cogsOverrideRaw)
                          system.setComponentCostOverride(componentActivation, cogsOverride, 'manual', true)
                        }}
                      />
                    }
                    {
                      <ActionDelete
                        style={{ width: 18, height: 18, cursor: 'pointer', color: '#4d4d4d' }}
                        onClick={() => {
                          system.removeComponentCostOverride(componentActivation)
                        }}
                      />
                    }
                  </div>
                )
              } else return null
            })}

          <div style={{ display: 'flex' }}>
            <Button
              style={{
                minWidth: 40,
                marginTop: 10,
              }}
              variant="contained"
              color="default"
              disabled={pricingIsLocked}
              onClick={(e) => {
                var code = prompt('Component Code')

                var componentActivation = window.AccountHelper.getComponentActivationFromCode(code)
                if (!componentActivation) {
                  window.Designer.showNotification('Component not found', 'warning')
                  return
                }

                var priceRaw = prompt('COGS')
                var price = parseFloat(priceRaw)

                system.setComponentCostOverride(componentActivation, price, 'manual', true)
              }}
              startIcon={<AddIcon />}
            >
              <span>{translate('Add')}</span>
            </Button>

            {cogsOverrideExists && !cogsModalEnabled && (
              <Button
                style={{
                  minWidth: 40,
                  marginTop: 10,
                  marginLeft: 10,
                }}
                variant="contained"
                color="default"
                disabled={pricingIsLocked}
                onClick={(e) => {
                  system.refreshComponentCostOverrides(bomLineItemsHashMap.all)
                }}
                startIcon={<SyncIcon />}
              >
                <span>{translate('Refresh')}</span>
              </Button>
            )}
          </div>
        </>
      )}
      {allowViewTaxOverride && (
        <>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 20 }} />
          </Grid>
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>
            {translate('%{TaxName} overrides', {
              TaxName: translate(taxName),
            })}
          </p>

          <TextField
            type="number"
            label={translate('%{TaxName} override (hardware)', {
              TaxName: translate(taxName),
            })}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="tax-override-hardware"
            value={valueOrEmptyStringIfNull(taxOverrideHardwareRaw)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'taxOverrideHardwareRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{translate('%')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('taxOverrideHardwareRaw', value)
              var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

              handleObjectChangedAsCommand(system.uuid, 'tax_override_hardware', valueAsFloatOrNull)
            }}
            errorText={
              null && isNaN(inputValueToFloatOrEmptyStringWhenNull(taxOverrideHardwareRaw))
                ? translate('Supply tax override as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked || !allowEditTaxOverride}
          />

          <TextField
            type="number"
            label={translate('%{TaxName} override (non-hardware)', {
              TaxName: translate(taxName),
            })}
            style={styles.inputField}
            underlineStyle={{ borderColor: 'rgb(108, 108, 108)' }}
            name="tax-override-non-hardware"
            value={valueOrEmptyStringIfNull(taxOverrideNonHardwareRaw)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'taxOverrideNonHardware', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{translate('%')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              updateFieldState('taxOverrideNonHardware', value)
              var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

              handleObjectChangedAsCommand(system.uuid, 'tax_override_non_hardware', valueAsFloatOrNull)
            }}
            errorText={
              null && isNaN(inputValueToFloatOrEmptyStringWhenNull(taxOverrideNonHardwareRaw))
                ? translate('Supply tax override as a number or leave blank')
                : null
            }
            disabled={pricingIsLocked || !allowEditTaxOverride}
          />
        </>
      )}
    </div>
  )
}

export const PricingDetail = connect(
  (state) => ({
    features: (state.access && state.access.features) || {},
  }),
  {
    push: pushAction,
    setStudioSavePrompt: setStudioSavePromptAction,
  }
)(_PricingDetail)
