import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useMemo, useState } from 'react'
import FilterBtn from './FilterBtn'
import { PRODUCT_FILTERS, StandFilters } from './util'

const SideWrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
  borderRight: '1px solid ' + theme.palette.grey[100],
  width: 250,
}))

const Header = styled('h1', { name: 'Header' })({
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: 400,
  margin: 20,
})

interface PropTypes {
  filters: StandFilters
  setFilters: (values) => void
  categories?: string[]
}

const FilterSideNav: FC<PropTypes> = ({ filters, setFilters, categories }) => {
  const translate = useTranslate()
  const [searchByLoc, setSearchByLoc] = useState<boolean | null>(false)
  const handleSetFilter = (type: 'tags', value) => {
    setFilters({ ...filters, [type]: value })
  }
  const productCategories = useMemo(
    () => (categories?.length ? PRODUCT_FILTERS.filter((x) => categories.includes(x)) : PRODUCT_FILTERS),
    [categories]
  )
  return (
    <SideWrapper>
      <Header>{translate('Explore Partners')}</Header>
      {/* <FilterBtn
        label={translate('Search By Location')}
        onClick={() => {
          setSearchByLoc(searchByLoc ? null : true)
        }}
        toggledOn={searchByLoc === true}
        items={locations}
        setFilter={(values) => {
          handleSetFilter('location', values)
        }}
      /> */}
      <FilterBtn
        value={filters.tags}
        label={translate('Search By Product')}
        onClick={() => {
          setSearchByLoc(searchByLoc === false ? null : false)
        }}
        toggledOn={searchByLoc === false}
        items={productCategories}
        setFilter={(value) => {
          handleSetFilter('tags', value)
        }}
      />
    </SideWrapper>
  )
}

export default FilterSideNav
