import React, { FC, ReactElement } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ActionList from '@material-ui/icons/List'
import { Link } from 'react-router-dom'
// import { Button } from '@material-ui/core'
import Button, { ButtonProps } from './Button'

const getLinkPath = (basePath, lastList) => {
  if (lastList && lastList.length > 0) {
    //we have lastList, but only use it if it matches resource of basePath

    var resource = lastList.split('/')[1]

    //strip anything after ?
    resource = resource.split('?')[0]

    if (basePath.startsWith('/' + resource)) {
      return lastList
    }
  }

  return basePath
}

const ListButton: FC<ListButtonProps> = ({
  basePath = '',
  label = 'ra.action.list',
  icon = defaultIcon,
  lastList,
  ...rest
}) => (
  <Link style={{ textDecoration: 'none' }} to={getLinkPath(basePath, lastList)}>
    <Button
      color="default"
      variant={'contained'}
      size="medium"
      label={label}
      startIcon={icon}
      style={{ marginRight: 0 }}
      {...(rest as any)}
    />
    {/* <span>{label}</span>
    </Button> */}
  </Link>
)

const defaultIcon = <ActionList />

interface Props {
  basePath?: string
  icon?: ReactElement
  lastList?: string
}

export type ListButtonProps = Props & ButtonProps

ListButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
}

const mapStateToProps = (state) => ({
  lastList: state.lastList,
})

export default connect(mapStateToProps, {})(ListButton)

// export default ListButton;
