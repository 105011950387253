import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { refreshCustomQueries } from '../actions'

/**
 * Hook for Clear Cached Custom Queries
 *
 * @example
 *
 * const refreshCustomQueries = useRefreshCustomQueries();
 * refreshCustomQueries();
 */

const useRefreshCustomQueries = () => {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(refreshCustomQueries()), [dispatch])
}

export default useRefreshCustomQueries
