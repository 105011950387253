import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const CheckIconSvg: FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Vector"
        d="M1.60736 3.90031C2.09581 4.16253 2.41402 4.38039 2.79287 4.7691C3.77528 3.29661 4.96059 1.65124 6.34721 0.493124L6.48283 0.444458H8C5.96571 2.54837 4.37686 5.0188 2.85682 7.55557C1.94321 6.12188 1.32841 5.19888 0 4.2385L1.60736 3.90031Z"
        fill={props.fill || 'white'}
      />
    </svg>
  )
}

export const CheckIcon = createStandardIcon(CheckIconSvg)
