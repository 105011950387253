import { ListItemText } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { AddIcon, Button, Grid, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import useTranslateParse from 'util/useTranslateParse'
import ContactCardWrapper from '../components/ContactCardWrapper'
import { ContactFormState, FieldPrefixFilterType } from '../types'
import { contactIsNotEmpty } from '../utils'
import OwnerAndRepsForm from './OwnerAndRepsForm'

type PropTypes = {
  ownerFields: any
  fieldPrefixFilter: FieldPrefixFilterType
  setFieldPrefixFilter: (boolean) => void
  setIsManualAddressEntryEnabled: (boolean) => void
  isManualAddressEntryEnabled: boolean
}

const TextWrapper = styled('div')({
  margin: '10px 0',
})

const RepsContainer = styled('div')(({ theme }) => ({
  padding: '20px',
  margin: '1rem 0',
  background: theme.palette.grey[50],
  width: 'fit-content',
  borderRadius: 6,
}))

const StyledItem = styled(ListItemText)({
  display: 'list-item',
  margin: 0,

  '& p': {
    lineHeight: '18px',
  },
})

const OwnerAndRepsSection: React.FC<PropTypes> = (props) => {
  const [contactForm, setContactForm] = useState<ContactFormState>({
    showForm: false,
    mode: 'create',
  })
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const orgCountry = useSelector(orgSelectors.getOrgIso2)

  const formVals = useFormState().values
  const companyRep = formVals?.companyRep
  const owner1 = formVals.ownershipInfoArr?.[0]
  const owner2 = formVals.ownershipInfoArr?.[1]
  const owner3 = formVals.ownershipInfoArr?.[2]
  const owner4 = formVals.ownershipInfoArr?.[3]

  const contactIsFilled = useMemo(() => {
    return (
      (owner1 && contactIsNotEmpty(owner1)) ||
      (owner2 && contactIsNotEmpty(owner2)) ||
      (owner3 && contactIsNotEmpty(owner3)) ||
      (owner4 && contactIsNotEmpty(owner4)) ||
      (companyRep && contactIsNotEmpty(companyRep))
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const allContactsFilled = useMemo(() => {
    return (
      owner1 &&
      contactIsNotEmpty(owner1) &&
      owner2 &&
      contactIsNotEmpty(owner2) &&
      owner3 &&
      contactIsNotEmpty(owner3) &&
      owner4 &&
      contactIsNotEmpty(owner4) &&
      companyRep &&
      contactIsNotEmpty(companyRep)
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const onAddContact = () => {
    setContactForm({ showForm: true, mode: 'create' })
    props.setFieldPrefixFilter?.(null)
    logAmplitudeEvent('psp_onboarding_add_contact_clicked', {})
  }

  return (
    <div data-testid="owner-and-reps-section">
      <TextWrapper>
        <Typography textVariant="body1">
          {translate(
            'You can add up to 4 contacts. All individuals who own 25% or more interest in your business must be added.'
          )}
        </Typography>
      </TextWrapper>

      {contactIsFilled && !contactForm.showForm && (
        <Grid container spacing={1}>
          <ContactCardWrapper
            ownersData={formVals.ownershipInfoArr}
            companyRepData={companyRep}
            fieldPrefixFilter={props.fieldPrefixFilter}
            setFieldPrefixFilter={props.setFieldPrefixFilter}
            setContactForm={setContactForm}
          />
        </Grid>
      )}

      <RepsContainer>
        {contactForm.showForm && !allContactsFilled ? (
          <OwnerAndRepsForm
            fieldPrefixFilter={props.fieldPrefixFilter}
            ownerFields={props.ownerFields}
            setFieldPrefixFilter={props.setFieldPrefixFilter}
            setIsManualAddressEntryEnabled={props.setIsManualAddressEntryEnabled}
            isManualAddressEntryEnabled={props.isManualAddressEntryEnabled}
            contactForm={contactForm}
            setContactForm={setContactForm}
          />
        ) : (
          <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} onClick={onAddContact}>
            {translate('Add owner or company representative')}
          </Button>
        )}
      </RepsContainer>
    </div>
  )
}
export default OwnerAndRepsSection
