import { EnergyTypeEnum } from '../types'
import { CONVERSION_FACTORS_TO_KWH } from './constants'
import { NaturalGasType } from './NaturalGasContent'

const convertValueToKwh = (value: number, unitValue?: string, reverse: boolean = false): number => {
  if (!unitValue) return value
  if (value === null || value === undefined) return value

  const conversionFactor = CONVERSION_FACTORS_TO_KWH[unitValue]
  if (reverse) return value / conversionFactor
  return value * conversionFactor
}

const convertValuesToKWh = (
  values?: number | number[],
  unitValue?: string,
  reverse: boolean = false
): number | number[] | undefined => {
  if (!values) return undefined
  if (!unitValue) return values
  if (!Array.isArray(values)) {
    return convertValueToKwh(values, unitValue, reverse)
  }

  return values.map((value) => convertValueToKwh(value, unitValue, reverse))
}

export const formatNaturalGasValues = (data): NaturalGasType => {
  return {
    energy_type: EnergyTypeEnum.NATURAL_GAS,
    unit_value: data?.unit_value,
    usage_data_source: data?.usage_data_source,
    values: data?.values,
    values_kwh: convertValuesToKWh(data?.values, data?.unit_value),
    price: data?.price,
    price_per_kwh: convertValueToKwh(data?.price, data?.unit_value, true),
  }
}

export const parseNaturalGasValues = (data): NaturalGasType => {
  const unit_value =
    !data?.unit_value && Object.values(data).some((value) => !Number.isNaN(value)) ? 'kwh' : data?.unit_value
  return {
    ...data,
    energy_type: EnergyTypeEnum.NATURAL_GAS,
    unit_value,
    // If only kwh values are available (i.e. data from API users), fill up other empty values
    values: !data?.values && !!data?.values_kwh ? convertValueToKwh(data?.values_kwh, unit_value, true) : data?.values,
    price: !data?.price && !!data?.price_per_kwh ? convertValueToKwh(data?.price_per_kwh, unit_value) : data?.price,
  }
}
