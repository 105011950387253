import { CircularProgress } from '@material-ui/core'
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined'
import { orgSelectors } from 'ducks/orgs'
import { Chip, Dialog, DialogContent, styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { Confirm } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'

const restClientInstance = restClient(window.API_ROOT + '/api')

const ButtonContainer = styled('div')(({ theme }) => ({
  marginLeft: '10px',
}))

const ReloadTextDiv = styled('div')(({ theme }) => ({
  margin: '30px 0px 40px 0px',
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[900],
}))

const VerifyButton = styled('img')<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  height: 45,
  cursor: 'pointer',
  pointerEvents: isLoading ? 'none' : 'auto',
}))

const VerifiedChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  borderColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  borderRadius: '5px',
  fontSize: '15px',
  padding: '20px 5px',
}))

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)(({ theme }) => ({
  color: theme.palette.success.contrastText,
}))

type PropTypes = {
  roleId: number
}

type VerifiedStatus = 'verified' | 'notverified' | 'error' | undefined

const VerifyWithRecheck: React.FC<PropTypes> = (props) => {
  const { roleId } = props
  const notify = useNotify()
  const org = useSelector(orgSelectors.getOrg)
  const translate = useTranslate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [disconnectConfirmOpen, setDisconnectConfirmOpen] = useState(false)
  const [verified, setVerified] = useState<VerifiedStatus>(undefined)

  const authorizeRecheck = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${org?.id}/recheck/authorize/?role_id=${roleId}`,
    })
      .then((res) => {
        appStorage.setString('recheck_oauth2_code_verifier', res.data.code_verifier)
        appStorage.setNumber('recheck_role_id', roleId)

        window.open(res.data.auth_url, '_blank')
        setIsDialogOpen(true)
      })
      .catch((err) => {
        notify(err.message, 'warning')
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const checkIsVerified = useCallback(() => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `recheck/is_verified/`,
      data: {
        role_id: roleId,
      },
    })
      .then((res) => {
        setVerified(res.data.is_verified ? 'verified' : 'notverified')
      })
      .catch((err) => {
        setVerified('error')
      })
  }, [roleId])

  const disconnectRecheck = useCallback(() => {
    restClientInstance('CUSTOM_DELETE', 'custom', {
      url: `orgs/${org?.id}/recheck/authorize/?role_id=${roleId}`,
    })
      .then((res) => {
        window.location.reload()
      })
      .catch((err) => {
        notify(err.message, 'warning')
        console.error(err)
      })
  }, [])

  useEffect(() => {
    checkIsVerified()
  }, [])

  if (verified === undefined) return <CircularProgress />

  if (verified === 'error') return null

  return (
    <ButtonContainer>
      {verified === 'verified' && (
        <VerifiedChip
          variant="outlined"
          label={translate('Verified with Recheck')}
          color="success"
          icon={<StyledCheckCircleOutlined />}
          size="medium"
          onClick={() => setDisconnectConfirmOpen(true)}
        />
      )}
      {verified === 'notverified' && (
        <VerifyButton
          src={`${window.PUBLIC_URL}/images/verify-with-recheck-light.png`}
          onClick={authorizeRecheck}
          isLoading={isLoading}
        />
      )}

      <Confirm
        isOpen={disconnectConfirmOpen}
        title="Remove integration?"
        content="Are you sure you want to remove integration with Recheck for this user?"
        onConfirm={disconnectRecheck}
        onClose={() => setDisconnectConfirmOpen(false)}
      />
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <ReloadTextDiv>{translate('Please reload this page upon successful integration.')}</ReloadTextDiv>
        </DialogContent>
      </Dialog>
    </ButtonContainer>
  )
}

export default VerifyWithRecheck
