import React, { FC } from 'react'

/**
 * * Icon Component for: send-01, send-02, send-03, send-04
 */
type SendOutlineIconProps = {
  variant: 1 | 2 | 3 | 4
  size?: number
  color?: string
}
export const SendOutlineIcon: FC<SendOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M19.5587 4.44132L10.672 13.328M4.72591 8.86272L18.5642 4.0617C19.4164 3.76604 20.234 4.58364 19.9383 5.43584L15.1373 19.2741C14.8084 20.2221 13.4771 20.2481 13.1115 19.3136L10.9141 13.698C10.8043 13.4176 10.5824 13.1957 10.302 13.0859L4.68636 10.8885C3.75191 10.5229 3.7779 9.19162 4.72591 8.86272Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )

    case 2:
      return (
        <svg viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M20.8477 8.00003L9.84487 8.00003M5.20833 11.8827H3.08923M5.20833 8.13638H1M5.20833 4.39011H3.08923M8.92811 1.10505L20.4671 7.08999C21.1776 7.45856 21.1776 8.54149 20.4671 8.91006L8.92811 14.895C8.13761 15.305 7.2974 14.4406 7.64952 13.5796L9.76561 8.4054C9.87129 8.14698 9.87129 7.85308 9.76561 7.59466L7.64952 2.42045C7.2974 1.55945 8.13761 0.695041 8.92811 1.10505Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )

    case 3:
      return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M6.625 9.84048H8.60879M3.25 19C2.00736 19 1 17.9926 1 16.75V3.25C1 2.00736 2.00736 1 3.25 1H16.75C17.9926 1 19 2.00736 19 3.25V16.75C19 17.9926 17.9926 19 16.75 19H3.25ZM6.625 13.039V6.49319C6.625 5.75408 7.40432 5.27461 8.06406 5.60781L13.9553 8.58316C14.6505 8.93431 14.6883 9.91309 14.0222 10.3168L8.13099 13.8872C7.46996 14.2879 6.625 13.8119 6.625 13.039Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )

    case 4:
      return (
        <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M9.14756 10H3.71319M2.62123 2.12003L16.3652 8.95996C17.2116 9.38118 17.2116 10.6188 16.3652 11.04L2.62123 17.88C1.67968 18.3486 0.6789 17.3607 1.09831 16.3767L3.61877 10.4633C3.74466 10.1679 3.74466 9.83206 3.61877 9.53672L1.09831 3.62334C0.678901 2.63934 1.67968 1.65144 2.62123 2.12003Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )
  }
}
