import { FC } from 'react'

/**
 * * Icon Component for: stepper check mark
 */
type StepperCheckIconProps = {
  size?: number
  color?: string
}
export const StepperCheckIcon: FC<StepperCheckIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <>
      <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector"
          d="M1.60736 4.40019C2.09581 4.66241 2.41402 4.88027 2.79287 5.26898C3.77528 3.79649 4.96059 2.15112 6.34721 0.993002L6.48283 0.944336H8C5.96571 3.04825 4.37686 5.51867 2.85682 8.05545C1.94321 6.62176 1.32841 5.69876 0 4.73838L1.60736 4.40019Z"
          fill="white"
        />
      </svg>
    </>
  )
}
