import { addDevTool } from 'debug/ostools'
import lodash from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Struct, StructsContextType, StructsLoadInfo } from '../types/types'
import { useStructVersionWatcher } from './useStructVersionWatcher'

const pollTimeInt = 10 * 1000 // 10s
const pollTimeExt = 2 * 60 * 1000 // 2m

// Polls the external data sources for Structs, should only be used once in the app.
// Sets up two watchers, one for internal structs and one for external structs (i.e.manually published structs)
// Then merges the results of the two watchers.
export const useStructContextLogic = (): StructsContextType => {
  const valueInternal = useStructVersionWatcher(getInternalStructsUrl(), pollTimeInt)
  const valueExternal = useStructVersionWatcher(getExternalStructsUrl(), pollTimeExt)

  const runtimeStructs = useRef<Record<string, Struct<any>[]>>({})

  const recompileTypeCallback = (type: string) => {
    setValue((prev) => {
      return {
        ...prev,
        structs: {
          ...prev.structs,
          [type]: combineAllStructs(valueInternal[type], valueExternal[type], runtimeStructs.current[type]),
        },
      }
    })
  }
  const recompileType = useRef(recompileTypeCallback)
  recompileType.current = recompileTypeCallback

  addDevTool(
    'structs.logState',
    () => {
      console.log('Structs:', value.structs)
    },
    { help: 'Logs out all of the current structs available in the app' }
  )

  const add = useCallback((struct: Struct<any>, opts?: { update?: boolean }) => {
    let structs = runtimeStructs.current[struct.type]
    if (structs) {
      const found = structs.find((s) => s.key === struct.key)
      if (found) {
        if (opts?.update) {
          structs = structs.filter((s) => s.key !== struct.key)
        } else {
          console.warn('Struct already exists in runtime structs', struct)
          return
        }
      }
    }
    if (!structs) structs = []
    else structs = [...structs]
    structs.push(struct)
    runtimeStructs.current = { ...runtimeStructs.current, [struct.type]: structs }
    recompileType.current(struct.type)
  }, [])

  const remove = useCallback((struct: Struct<any>) => {
    removeByKey(struct.type, struct.key)
  }, [])

  const removeByKey = useCallback((type: string, key: string) => {
    const structs = runtimeStructs.current[type]
    if (!structs) return
    if (!structs || !structs.find((s) => s.key === key)) {
      // Ignore if the struct does not exist, it's common to attempt to remove a struct that does not exist
      return
    }
    runtimeStructs.current = { ...runtimeStructs.current, [type]: structs.filter((s) => s.key !== key) }
    recompileType.current(type)
  }, [])

  const [value, setValue] = useState<StructsContextType>({ structs: {}, add, remove, removeByKey })

  useEffect(() => {
    const newValue: StructsContextType = { structs: {}, add, remove, removeByKey }

    const allKeys = lodash.uniq([...Object.keys(valueInternal), ...Object.keys(valueExternal)])
    for (const key of allKeys) {
      newValue.structs[key] = combineAllStructs(valueInternal[key], valueExternal[key], runtimeStructs.current[key])
    }

    setValue(newValue)
  }, [valueInternal, valueExternal])

  return value
}

declare global {
  interface Window {
    PUBLIC_BUCKET_URL: string
  }
}

const defaultLoadState: StructsLoadInfo<any> = { loadState: 'not-loaded', structs: [] }

const combineAllStructs = (
  loadInfo: StructsLoadInfo<any> | undefined,
  extLoadInfo: StructsLoadInfo<any> | undefined,
  runtime: Struct<any>[] | undefined
): StructsLoadInfo<any> => {
  if (!loadInfo) loadInfo = defaultLoadState
  if (!extLoadInfo) extLoadInfo = defaultLoadState

  let loadState
  if (loadInfo.loadState === extLoadInfo.loadState) {
    loadState = loadInfo.loadState
  } else {
    const bothStates = [loadInfo.loadState, extLoadInfo.loadState]
    if (bothStates.includes('reloading')) {
      loadState = 'reloading'
    } else if (loadInfo.loadState === 'not-loaded') {
      // Intenionally ignore extLoadInfo.loadState === 'not-loaded' here
      // this allows new struct types to be added without explicitly beind supported in the external source
      loadState = 'not-loaded'
    } else {
      loadState = 'loaded'
    }
  }

  // allow external structs to override internal structs by key
  const filteredInternalStructs = extLoadInfo
    ? loadInfo.structs.filter((s) => !extLoadInfo?.structs.find((es) => es.key === s.key))
    : loadInfo.structs

  // allow external structs to override runtime structs by key
  const filteredRuntimeStructs =
    runtime && extLoadInfo ? runtime.filter((s) => !extLoadInfo?.structs.find((es) => es.key === s.key)) : runtime

  let structs
  if (filteredRuntimeStructs?.length)
    structs = [...filteredInternalStructs, ...extLoadInfo.structs, ...filteredRuntimeStructs]
  else structs = [...filteredInternalStructs, ...extLoadInfo.structs]
  return {
    loadState,
    structs,
  }
}

const getInternalStructsUrl = () => {
  return window.PUBLIC_BUCKET_URL + `/structs/`
}

const getExternalStructsUrl = () => {
  const folder =
    window.ENV === 'production'
      ? 'structs_production'
      : window.ENV?.includes('staging')
      ? 'structs_staging'
      : 'structs_other'
  return `https://content.opensolar.com/${folder}/`
}
