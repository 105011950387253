import { useEffect } from 'react'
import { useLogout, useNotify, useTranslate } from 'react-admin'
import appStorage from 'storage/appStorage'

interface DocusignRedirectData {
  url?: string
  params?: Record<string, any>
}

export const DocusignRedirect = () => {
  const logout = useLogout()
  const notify = useNotify()
  const translate = useTranslate()

  useEffect(() => {
    const redirectData = appStorage.getJSON('docusign_redirect') as DocusignRedirectData | null

    if (!redirectData?.url) {
      notify(translate('An error occurred during the signing process.'), 'error')
      logout({}, '/login', false)
      return
    }

    let targetUrl = redirectData.url
    if (redirectData.params) {
      const queryString = Object.entries(redirectData.params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

      targetUrl += `${targetUrl.includes('?') ? '&' : '?'}${queryString}&event=signing_complete`
    }
    appStorage.clear('docusign_redirect')
    window.location.href = targetUrl
  }, [logout])

  return null
}

export default DocusignRedirect
