import React from 'react'
import PropTypes from 'prop-types'
import Title, { TitlePropType } from './Title'
import { useTranslate } from 'ra-core'

const Subtitle = ({ subtitle }) => {
  const translate = useTranslate()
  return subtitle ? (
    <p
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        marginBottom: 10,
      }}
    >
      {typeof subtitle === 'string' ? translate(subtitle) : subtitle}
    </p>
  ) : null
}

export default Subtitle
