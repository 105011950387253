import { authSelectors } from 'ducks/auth'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import Empty from 'elements/Empty'
import ListActions from 'elements/react-admin/ListActions'
import { Create, Datagrid, Edit, FunctionField, List } from 'react-admin'
import { useSelector } from 'react-redux'
import BrandForm from './BrandForm'

export const BrandList = (props) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  return (
    <List
      {...props}
      perPage={20}
      hasCreate={isStaff}
      actions={<ListActions createButtonLabel={'Register Brand'} {...props} />}
      empty={<Empty resourceName={'brands'} />}
    >
      <Datagrid>
        <FunctionField source={'name'} label="Name" render={(record) => record.name} />
        <FunctionField source={'slug'} label="URL Slug" render={(record) => record.slug} />
        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource={props.resource}
          isAdmin={isStaff}
          allowEdit={isStaff}
          allowDelete={isStaff}
          allowDuplicate={false}
        />
      </Datagrid>
    </List>
  )
}

export const BrandCreate = (props) => {
  return (
    <Create {...props}>
      <BrandForm {...props} />
    </Create>
  )
}

export const BrandEdit = (props) => {
  return (
    <Edit {...props}>
      <BrandForm {...props} />
    </Edit>
  )
}
