import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { HelpOutline, SaveOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors, updateAccountingIntegrations } from 'ducks/orgs'
import {
  Alert,
  BaseThemeType,
  Button,
  ComponentVersionsInherit,
  FormLabel,
  LoadingDots,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
  Typography,
} from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccountingTaxRateSelection from './AccountingTaxRateSelection'
import { AccountingIntegration, TaxRateSelection } from './types'
import { getAccountingIntegrations, saveAccountingSettings, useGetLedgerAccounts } from './utils'

type PropTypes = {
  accountingIntegrations: AccountingIntegration[]
  selectedAccountingIntegration: string | null
  accountingContactEmail: string | null
  setAccountingContactEmail: (email: string) => void
  setAccountingIntegrations: (integrations: AccountingIntegration[]) => void
  setIsConnected: (connected: boolean) => void
  setSelectedAccountingIntegration: (integration: string) => void
}

const SettingsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginTop: '20px',
  marginBottom: '20px',
}))

const InputWrapper = styled('div')(({ theme }) => ({
  margin: '10px 0px',
  width: '100%',
}))

const InputRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const LoadingDotsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '0px',
  right: '0px',
  top: '0px',
  bottom: '0px',
  opacity: 0.9,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const SaveFooter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '0px',
  right: '0px',
  left: '0px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '10px 30px',
  boxShadow: '0px -2px 2px #F6F6F6;',
  backgroundColor: theme.palette.background.default,
  zIndex: 2,
}))

const RequiredField = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: '5px',
  marginRight: '10px',
  color: 'red',
}))

const AccountingSettingsForm: React.FC<PropTypes> = (props) => {
  const [selectedProductLedgerAccount, setSelectedProductLedgerAccount] = useState<string | null>(null)
  const [selectedPaymentLedgerAccount, setSelectedPaymentLedgerAccount] = useState<string | null>(null)
  const { accountingContactEmail, setAccountingContactEmail } = props
  const [taxRateSelection, setTaxRateSelection] = useState<TaxRateSelection>({})
  const [isSaving, setIsSaving] = useState(false)

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const roleId = useSelector(rolesSelectors.getCurrentRoleId)

  const translate = useTranslate()
  const notify = useNotify()
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('sm'))

  const { ledgerAccounts, isLoading } = useGetLedgerAccounts(orgId, roleId)

  const activeIntegration: AccountingIntegration | undefined = useMemo(() => {
    return props.accountingIntegrations.find((ai) => ai.name === props.selectedAccountingIntegration)
  }, [props.accountingIntegrations, props.selectedAccountingIntegration])

  useEffect(() => {
    if (activeIntegration) {
      if (activeIntegration.product_ledger_account_id) {
        setSelectedProductLedgerAccount(activeIntegration.product_ledger_account_id)
      }
      if (activeIntegration.payment_ledger_account_id) {
        setSelectedPaymentLedgerAccount(activeIntegration.payment_ledger_account_id)
      }
      if (activeIntegration.accounting_contact_email) {
        setAccountingContactEmail(activeIntegration.accounting_contact_email)
      }
      if (activeIntegration.tax_rate_json) {
        setTaxRateSelection(activeIntegration.tax_rate_json)
      }
    }
  }, [activeIntegration])

  const isEnabled = useMemo(() => {
    const result = props.accountingIntegrations?.some((integration) => integration.enabled)
    return result
  }, [props.accountingIntegrations])

  const handleProductLedgerAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedProductLedgerAccount(event.target.value as string)
  }

  const handlePaymentLedgerAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPaymentLedgerAccount(event.target.value as string)
  }

  const dispatch = useDispatch()

  const accInt = useSelector(orgSelectors.getAccountingIntegrations)
  const stableAccInt = useMemo(() => accInt, [accInt])

  const saveLedgerAccount = async () => {
    if (!orgId || !roleId || !activeIntegration) return

    setIsSaving(true)
    try {
      await saveAccountingSettings(
        selectedProductLedgerAccount,
        selectedPaymentLedgerAccount,
        accountingContactEmail,
        taxRateSelection,
        activeIntegration?.name,
        activeIntegration?.id,
        orgId
      )
        .then(async (res) => {
          notify(translate('Your changes have been saved'), 'success')
          logAmplitudeEvent('cashflow_accounting_settings_updated', {
            product_ledger_id: selectedProductLedgerAccount,
            payment_ledger_id: selectedPaymentLedgerAccount,
            accounting_contact_email: accountingContactEmail,
            integration: activeIntegration?.name,
            taxRateSelection: JSON.stringify(taxRateSelection),
          })
          dispatch(updateAccountingIntegrations([...stableAccInt]))
          const { integrations, isConnected, connectedIntegrationName } = await getAccountingIntegrations(
            orgId,
            roleId,
            dispatch,
            notify
          )

          props.setAccountingIntegrations(integrations)
          props.setIsConnected(isConnected)

          if (connectedIntegrationName) {
            props.setSelectedAccountingIntegration(connectedIntegrationName)
          }
        })
        .catch((err) => {
          notify(translate(err), 'warning')
          logAmplitudeEvent('cashflow_accounting_settings_error', {
            product_ledger_id: selectedProductLedgerAccount,
            payment_ledger_id: selectedPaymentLedgerAccount,
            accounting_contact_email: accountingContactEmail,
            integration: activeIntegration?.name,
          })
        })
        .finally(() => setIsSaving(false))
    } catch (ex) {}
  }

  if (!isEnabled || !props.selectedAccountingIntegration) return null
  if (!isLoading && !ledgerAccounts?.length) {
    return (
      <Alert severity="warning">
        {translate(
          'No ledger accounts found - If you just connected your account please wait a minute or so while we fetch all of the necessary data. Otherwise you may need to issue an invoice before ledger accounts will appear.'
        )}
      </Alert>
    )
  }
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <SettingsWrapper>
        <>
          <Typography textVariant="h5">{translate('General Ledger Account')}</Typography>
          <Typography>
            {translate('Select from your available General Ledger accounts to associate customers and invoices.')}
          </Typography>

          <InputWrapper>
            {props.selectedAccountingIntegration === 'quickbooks' && (
              <>
                <FormLabel>{translate('Product Ledger Account')}</FormLabel>
                <InputRow>
                  <Select
                    value={selectedProductLedgerAccount}
                    onChange={handleProductLedgerAccount}
                    displayEmpty
                    disabled={isLoading}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      {translate('Select a Product Ledger Account')}
                    </MenuItem>
                    {ledgerAccounts.map((ledger) => (
                      <MenuItem key={ledger.id} value={ledger.id}>
                        {ledger.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip
                    title={translate('This is the ledger account that all invoice line items will be assigned to')}
                  >
                    <HelpOutline />
                  </Tooltip>
                </InputRow>
              </>
            )}
            {props.selectedAccountingIntegration === 'xero' && (
              <>
                <FormLabel>{translate('Payment Ledger Account')}</FormLabel>
                <InputRow>
                  <Select
                    value={selectedPaymentLedgerAccount}
                    onChange={handlePaymentLedgerAccount}
                    displayEmpty
                    disabled={isLoading}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="" disabled>
                      {translate('Select a Payment Ledger Account')}
                    </MenuItem>
                    {ledgerAccounts.map((ledger) => (
                      <MenuItem key={ledger.id} value={ledger.id}>
                        {ledger.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip
                    title={translate(
                      'This is the ledger account that all payment records for paid invoices will be assigned to'
                    )}
                  >
                    <HelpOutline />
                  </Tooltip>
                </InputRow>
              </>
            )}
          </InputWrapper>
        </>

        <InputWrapper>
          <FormLabel>{translate('Accounting Contact Email')} </FormLabel>
          <InputRow>
            <TextField
              fullWidth
              variant="outlined"
              disabled={isLoading}
              value={accountingContactEmail}
              onChange={(e) => setAccountingContactEmail(e.target.value)}
            />
            <Tooltip title={translate('This email will receive important upates on the accounting integration')}>
              <HelpOutline />
            </Tooltip>
          </InputRow>
        </InputWrapper>

        {props.selectedAccountingIntegration === 'xero' && (
          <InputWrapper>
            <AccountingTaxRateSelection
              onChange={setTaxRateSelection}
              taxRateSelection={taxRateSelection}
              ledgerAccounts={ledgerAccounts}
            />
          </InputWrapper>
        )}

        {isLoading && (
          <LoadingDotsWrapper>
            <LoadingDots text={translate('Fetching your general ledger accounts...')} />
          </LoadingDotsWrapper>
        )}
        {isMobile ? (
          <Button
            onClick={() => {
              saveLedgerAccount()
            }}
            variant="contained"
            color="primary"
            disabled={isSaving || isLoading}
            startIcon={isSaving ? <CircularProgress size={20} /> : <SaveOutlined />}
          >
            {translate('Save')}
          </Button>
        ) : (
          <SaveFooter>
            <Button
              onClick={() => {
                saveLedgerAccount()
              }}
              variant="contained"
              color="primary"
              disabled={isSaving || isLoading}
              startIcon={isSaving ? <CircularProgress size={20} /> : <SaveOutlined />}
            >
              {translate('Save')}
            </Button>
          </SaveFooter>
        )}
      </SettingsWrapper>
      {console.log('test')}
    </ComponentVersionsInherit>
  )
}
export default AccountingSettingsForm
