import { FC } from 'react'

/**
 * * Icon Component for: lightning-01
 */
type LightningIconProps = {
  size?: number
  color?: string
}
export const LightningIcon: FC<LightningIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M6.22192 13.4889L5.51081 20.6L14.7553 10.6445L9.77747 7.80002L10.4886 1.40002L1.24414 11.3556L6.22192 13.4889Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
