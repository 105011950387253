import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_FILTER_KEY_V2 } from 'elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import { DistributorTradePricingContext } from 'elements/hardwareSelectorV2/HardwareSelectorCore'
import Pagination from 'elements/pagination/Pagination'
import { Box, Button } from 'opensolar-ui'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import { useComponentCost } from 'projectSections/hooks/useComponentCost'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDistributorTradePricing from 'services/useDistributorTradePricing'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import { useMultiDistributors } from '../../../../pages/ordering/catalogv3/useMultiDistributors'
import { HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2 } from '../../../hardwareFilter/constantsV2'
import HardwareListRow from '../../elements/HardwareListRow'
import HardwareSelectorFilterSidebar from '../../elements/HardwareSelectorFilterSidebar'
import HardwareSelectorLayout from '../../elements/HardwareSelectorLayout'
import HardwareSelectorList from '../../elements/HardwareSelectorList'
import HardwareSelectorListHeader from '../../elements/HardwareSelectorListHeader'
import HardwareSelectorToolBars from '../../elements/HardwareSelectorToolBars'
import useHardwareSelectorFetchEngine from '../../fetch/useHardwareSelectorFetchEngine'
import useHandleSelect from '../../useHandleSelect'
import { INVERTER_SELECTOR_MODE_NODE_KEY } from '../constants'
import { InverterDesignAssistantFlowNodeComponentStandardPropsType } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    minHeight: '0px',
    display: 'flex',
  },
}))

interface InverterDesignAssistantSelectorProps extends InverterDesignAssistantFlowNodeComponentStandardPropsType {}

const InverterManualSelectorNode: React.FC<InverterDesignAssistantSelectorProps> = ({ flowController, flowStore }) => {
  const classes = useStyles()
  const enabledMultiDistributorSelector = useFeatureFlag('design_multi_distributor', 'on')
  const translate = useTranslate()
  const distributors = useMultiDistributors()

  const defaultFilterValues = useMemo(() => {
    return {
      // This is changed to be consistent with modules etc. See #16160
      [DISTRIBUTOR_FILTER_KEY_V2]: null,
    }
  }, [])

  const {
    componentsData,
    setFilters,
    total = 0,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useHardwareSelectorFetchEngine({
    componentTypes: ['inverter'],
    limit: 20,
    defaultFilterValues,
  })

  const { updateComponentCosts } = useComponentCost()

  const hardwareDistributors = useEnabledHardwareSuppliers()

  const { distributorTradePricing, isChecking } = useDistributorTradePricing({
    orgId: useSelector(orgSelectors.getOrg)?.id || 0,
    distributors: hardwareDistributors,
  })

  const handleSelect = useHandleSelect({
    onClose: flowStore.onFinish,
    config: {
      targetUuids: flowStore.targetUuids,
      componentTypes: ['inverter'],
    },
  })

  const doHandleSelect = useCallback(
    async (component, priceSource, price) => {
      await handleSelect(component)

      if (enabledMultiDistributorSelector) {
        const componentActivation = window.AccountHelper.getComponentActivationFromCode(component.code, component.type)
        // This handles the default case for the pricing dropdown in the hardware selector
        const effectivePrice = priceSource === 'component' ? componentActivation?.cost || 0 : price
        updateComponentCosts([
          {
            code: component.code,
            source: priceSource,
            price: effectivePrice,
            activationId: componentActivation?.id || 0,
          },
        ])
      }
    },
    [handleSelect]
  )

  const handleBack = () => {
    flowController.goTo({
      title: 'Add Inverter',
      currentNodeKey: INVERTER_SELECTOR_MODE_NODE_KEY,
      options: {},
    })
  }

  const handleUpdate = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  const handleSearchTextUpdate = useCallback(
    (searchText) => {
      handleUpdate({ search: searchText })
    },
    [handleUpdate]
  )

  return (
    <DistributorTradePricingContext.Provider value={{ distributorTradePricing, isChecking }}>
      <div className={classes.wrapper}>
        <HardwareSelectorLayout
          footer={
            <Box display="flex" justifyContent="flex-end">
              <Button color="default" onClick={handleBack} variant="contained">
                {translate('Back')}
              </Button>
            </Box>
          }
          listContent={
            <HardwareSelectorList
              data={componentsData}
              Header={HardwareSelectorListHeader}
              loading={loading}
              onSelect={doHandleSelect}
              Pagination={
                <Pagination total={total} page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
              }
              Row={HardwareListRow}
              total={total}
            />
          }
          toolBars={
            <HardwareSelectorToolBars
              placeholder={'Search by inverter code and/or brand name...'}
              onChange={handleSearchTextUpdate}
              componentType={'inverter'}
            />
          }
          sidebar={
            <HardwareSelectorFilterSidebar
              hardwareFilterConfigList={HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2}
              persistentFilterValues={persistentFilterValues}
              modifiableFilterValues={modifiableFilterValues}
              allFilterValues={allFilterValues}
              setModifiableFilterValues={setFilters}
              componentTypes={['inverter']}
              location="design"
            />
          }
        />
      </div>
    </DistributorTradePricingContext.Provider>
  )
}

export default InverterManualSelectorNode
