import { FC } from 'react'
import { ExpoStandRenderer } from './ExpoStandRenderer'
import { useLoadExpoStand } from './useLoadExpoStand'

export interface ExpoStandProps {
  standId: string
}

export const OrgPageRenderer: FC<ExpoStandProps> = (props) => {
  const standData = useLoadExpoStand(props)

  if (!standData) return null
  return <ExpoStandRenderer {...standData} />
}
