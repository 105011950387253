export const CUSTOM_QUERY_RESET = 'CUSTOM_QUERY_RESET'
export const CUSTOM_QUERY_SUCCESS = 'CUSTOM_QUERY_SUCCESS'

export interface RefreshCustomQueriesAction {
  readonly type: typeof CUSTOM_QUERY_RESET
}

export const refreshCustomQueries = (): RefreshCustomQueriesAction => ({
  type: CUSTOM_QUERY_RESET,
})
