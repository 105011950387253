import { handleAddComponent } from 'projectSections/sections/design/util/handleAddComponent'
import type { ComponentTypes } from 'types/selectComponent'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import type { WizardComponentLikeType } from './types'

export const handleAddComponentHybrid = ({
  type,
  id,
  parentUuid,
  options,
}: {
  type: ComponentTypes
  id: number
  parentUuid: string
  options?: { [key in string]: any }
}) => {
  switch (type) {
    case 'inverter':
      return handleAddComponent(type, parentUuid, { inverterId: id })
    case 'battery':
      return handleAddComponent(type, parentUuid, { batteryId: id, ...options })
    case 'other':
      return handleAddComponent(type, parentUuid, { otherId: id, ...options })
    case 'module':
      // Module can not be added as a component. It should be added as a module grid.
      console.warn(`Unable to handle module. Skip adding component.`)
      return
    default:
      console.warn(`Unknown component type ${type}. Skip adding component.`)
  }
}

const addComponentSetToSystem = ({
  systemUuid,
  componentsSet,
}: {
  systemUuid: string
  componentsSet: WizardComponentLikeType[]
}) => {
  for (const component of componentsSet) {
    const activateComponentData = window.AccountHelper.getComponentActivationFromCode(
      component.code,
      mapComponentTypesV2ToV1(component.type)
    )
    if (activateComponentData === undefined) {
      console.warn(`Unable to find ${component.type} activation with code=${component.code}. Skip adding component.`)
      continue
    }
    const quantity = component.quantity || 1
    if (component.type === 'battery' || mapComponentTypesV2ToV1(component.type) === 'other') {
      handleAddComponentHybrid({
        type: mapComponentTypesV2ToV1(component.type),
        parentUuid: systemUuid,
        id: activateComponentData.id as number,
        options: { quantity: quantity },
      })
    } else {
      for (let i = 0; i < quantity; i++) {
        handleAddComponentHybrid({
          type: mapComponentTypesV2ToV1(component.type),
          parentUuid: systemUuid,
          id: activateComponentData.id as number,
        })
      }
    }
  }
}

export default addComponentSetToSystem
