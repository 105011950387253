import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const SummaryIconSvg: FC<IconProps> = (props) => {
  const { fill } = props

  return (
    <svg viewBox="0 0 28 28" {...props}>
      <mask
        id="mask0_5805_42805"
        // style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5805_42805)">
        <path
          d="M9.33268 10.275C9.58845 10.275 9.80945 10.1819 9.99567 9.99567C10.1819 9.80945 10.275 9.58845 10.275 9.33268C10.275 9.07691 10.1819 8.85591 9.99567 8.66969C9.80945 8.48347 9.58845 8.39037 9.33268 8.39037C9.07691 8.39037 8.85592 8.48347 8.6697 8.66969C8.48348 8.85591 8.39037 9.07691 8.39037 9.33268C8.39037 9.58845 8.48348 9.80945 8.6697 9.99567C8.85592 10.1819 9.07691 10.275 9.33268 10.275ZM9.33268 14.9417C9.58845 14.9417 9.80945 14.8486 9.99567 14.6623C10.1819 14.4761 10.275 14.2551 10.275 13.9993C10.275 13.7436 10.1819 13.5226 9.99567 13.3364C9.80945 13.1501 9.58845 13.057 9.33268 13.057C9.07691 13.057 8.85592 13.1501 8.6697 13.3364C8.48348 13.5226 8.39037 13.7436 8.39037 13.9993C8.39037 14.2551 8.48348 14.4761 8.6697 14.6623C8.85592 14.8486 9.07691 14.9417 9.33268 14.9417ZM9.33268 19.6083C9.58845 19.6083 9.80945 19.5152 9.99567 19.329C10.1819 19.1428 10.275 18.9218 10.275 18.666C10.275 18.4102 10.1819 18.1892 9.99567 18.003C9.80945 17.8168 9.58845 17.7237 9.33268 17.7237C9.07691 17.7237 8.85592 17.8168 8.6697 18.003C8.48348 18.1892 8.39037 18.4102 8.39037 18.666C8.39037 18.9218 8.48348 19.1428 8.6697 19.329C8.85592 19.5152 9.07691 19.6083 9.33268 19.6083ZM6.55062 23.3327C6.01366 23.3327 5.56532 23.1528 5.2056 22.7931C4.84588 22.4334 4.66602 21.985 4.66602 21.4481V6.55062C4.66602 6.01366 4.84588 5.56532 5.2056 5.2056C5.56532 4.84588 6.01366 4.66602 6.55062 4.66602H18.1724L23.3327 9.82627V21.4481C23.3327 21.985 23.1528 22.4334 22.7931 22.7931C22.4334 23.1528 21.985 23.3327 21.4481 23.3327H6.55062ZM6.55062 22.166H21.4481C21.6575 22.166 21.8295 22.0987 21.9641 21.9641C22.0987 21.8295 22.166 21.6575 22.166 21.4481V10.4993H17.4993V5.83268H6.55062C6.34122 5.83268 6.16922 5.89999 6.0346 6.0346C5.89999 6.16922 5.83268 6.34122 5.83268 6.55062V21.4481C5.83268 21.6575 5.89999 21.8295 6.0346 21.9641C6.16922 22.0987 6.34122 22.166 6.55062 22.166Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export const SummaryIcon = createStandardIcon(SummaryIconSvg)
