import { colors, Paper, styled } from '@material-ui/core'
import { ReportProblemOutlined } from '@material-ui/icons'
import { Checkbox, DialogActions, DialogContent, DialogTitle } from 'opensolar-ui'
import { OpenSolarTheme } from 'Themes'

export const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 5,
  padding: '10px 40px',
  width: '40%',
  [theme.breakpoints.down('md')]: {
    width: '60%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: '10px 20px',
  },
}))

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(3, 0, 2),
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  boxSizing: 'border-box',
  fontWeight: 600,
  fontSize: '1.25rem',
}))

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '10px 20px !important',
  backgroundColor: colors.grey[100],
}))

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '24px 0 !important',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const Section = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const AddContactsNote = styled('p')(({ theme }) => ({
  color: colors.grey[600],
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
}))

export const AttachmentsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const AttachmentItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const CustomCheckbox = styled(Checkbox)({
  padding: '0',
})

export const FileIcon = styled('img')(({ theme }) => ({
  width: 24,
  height: 24,
  margin: theme.spacing(0, 1),
}))

export const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const FileTitle = styled('span')({
  display: 'block',
  height: 24,
  lineHeight: '24px',
})

export const FileSize = styled('span')(({ theme }) => ({
  display: 'block',
  fontSize: '0.8rem',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(0.25),
}))

export const InfoMessage = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: colors.grey[200],
  borderRadius: 4,
  marginTop: theme.spacing(2),
  textAlign: 'center',
}))

export const ErrorBox = styled('div')(({ theme }) => ({
  backgroundColor: OpenSolarTheme.alertBg_error,
  border: `1px solid ${OpenSolarTheme.alert_error}`,
  color: OpenSolarTheme.alertIcon_error,
  padding: theme.spacing(2),
  borderRadius: 4,
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'flex-start',
}))

export const ErrorIcon = styled(ReportProblemOutlined)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginTop: 2,
  color: OpenSolarTheme.alertIcon_error,
}))
