import { AUTH_RELOAD_FAILURE, authReload } from 'actions/authActions'
import { setAmplitudeIdentity } from 'amplitude/amplitude'
import { push } from 'connected-react-router'
import { incrementAppViewVersion } from 'ducks/app'
import { SET_AUTH, setOrgId } from 'ducks/auth'
import { viewModeActions } from 'ducks/viewMode'
import { PartnerIdType } from 'pages/auth/sso/types'
import { AccountingIntegration } from 'pages/cashFlow/configuration/accounting/types'
import { getSupplierConfig } from 'pages/ordering/configs'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { MfaErrorStatus } from 'ra-core'
import { showNotification } from 'react-admin'
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import { CustomFormUsageType } from 'resources/customForms/types'
import restClient from 'restClient'
import appStorage, { getOrgId } from 'storage/appStorage'
import { ReduxActionType } from 'types/global'
import { ExpoModes, GetActionsResponseType, IntegrationEnabledType, MeasurementUnits, OrgType } from 'types/orgs'
import { RootState } from 'types/state'
import { WorkflowType } from 'types/workflows'
import { currencySymbolForCountry, getReduxSelectorCachedValueWhenEqual } from 'util/misc'
import { authSelectors } from './auth'
import { setMfaCheckRequired, setMfaConfigRequired, setMfaHasDevices } from './auth_mfa_actions'
import { updateSelectedHardwareSupplier } from './orderComponents'
import { doGetProjectViews, GET_PROJECT_VIEWS_SUCCESS, ProjectView } from './projectListViews'
import { changeTheme, OsTheme, setTheme } from './theme'
import { wsActions } from './websocket'

const API_URL = window.API_ROOT + '/api'

export const GET_ORG = 'GET_ORG'
export const GET_ORG_LOADING = 'GET_ORG_LOADING'
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS'
export const SET_ORG_SUCCESS = 'SET_ORG_SUCCESS'
export const GET_ORG_FAIL = 'GET_ORG_FAIL'
export const CLEAR_ORG = 'CLEAR_ORG'
export const UPDATE_ORG_WITH_CA_TEMPLATE = 'UPDATE_ORG_WITH_CA_TEMPLATE'
export const JOIN_ORG_AND_SWITCH = 'JOIN_ORG_AND_SWITCH'
export const STORE_RATE_REFRESH_DATA = 'STORE_RATE_REFRESH_DATA'
export const UPDATE_BLUESNAP_ONBOARIDNG_STATUS = 'UPATE_BLUESNAP_ONBOARDING_STATUS'
export const UPDATE_ACTIVE_ACCOUNTING_INTEGRATIONS = 'UPDATE_ACTIVE_ACCOUNTING_INTEGRATIONS'
export const ACCOUNTING_INTEGRATIONS = 'ACCOUNTING_INTEGRATIONS'

export const updateActiveAccountingIntegrations = (activeIntegrations: string[]) => {
  return {
    type: UPDATE_ACTIVE_ACCOUNTING_INTEGRATIONS,
    payload: {
      activeIntegrations,
    },
  }
}

export const updateAccountingIntegrations = (accountingIntegrations: AccountingIntegration[]) => {
  return {
    type: ACCOUNTING_INTEGRATIONS,
    payload: {
      accountingIntegrations,
    },
  }
}

export type OrgReducerType = {
  loading: boolean
  org?: OrgType
  workflows?: WorkflowType[]
  rate_refresh_reminder?: RateRefreshReminderType
}
export type RateRefreshReminderType = {
  cached_response: GetActionsResponseType | undefined
  last_fetched: string | undefined
  last_all_clear: string | undefined
}

const getInitialState = (): OrgReducerType => ({
  loading: false,
  org: appStorage.getOrg(),
})

export const orgsActions = {
  reloadOrg: (orgId?: number) => {
    return { type: GET_ORG, payload: { orgId } }
  },
  clearOrg: () => ({ type: CLEAR_ORG, payload: {} }),
  updateOrgWithCaTemplate: (orgId: number, newVal: boolean) => ({
    type: UPDATE_ORG_WITH_CA_TEMPLATE,
    payload: { orgId, newVal },
  }),
  joinOrgAndSwitch: (payload: { orgUrl: string; orgId: number; userId?: number }) => {
    return { type: JOIN_ORG_AND_SWITCH, payload }
  },
  storeRateRefreshData: (
    cached_response: GetActionsResponseType | undefined,
    last_fetched: string,
    last_all_clear: string | undefined
  ) => {
    return { type: STORE_RATE_REFRESH_DATA, payload: { cached_response, last_fetched, last_all_clear } }
  },
}

export default function orgsReducer(state = getInitialState(), action: ReduxActionType): OrgReducerType {
  const newState = orgsInnerReducer(state, action)

  // clear org if logout
  // this is a quick hack to clear localStorage.org on logout until it is implemented properly
  if (action?.type === '@@router/LOCATION_CHANGE' && action?.payload?.location?.pathname === '/logout') {
    appStorage.setOrg(undefined)
    appStorage.setIso2(undefined)
    newState.org = undefined
  } else if (action?.payload?.hasOwnProperty('org')) {
    // Save any org changes to local storage
    appStorage.setOrg(newState.org)
    const iso2 = newState.org?.country?.iso2
    if (iso2) appStorage.setIso2(iso2)
  }
  return newState
}

// This is extracted from reducer simply to allow the local storage or org to work
function orgsInnerReducer(state: OrgReducerType, { type, payload }: ReduxActionType): OrgReducerType {
  switch (type) {
    case GET_ORG_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ORG_SUCCESS:
      const org: OrgType = payload.org

      var orgHasNMOS = !!org.external_account_id

      appStorage.setString('api_key_google', org.api_key_google)

      window.hasNearmapOnOpenSolarInjectedFromReact = function () {
        return orgHasNMOS
      }

      //Inject Nearmap and Google API Keys so they can be used by the window.* outside React
      // If api_key not set then we will clear any exising value
      if (window.AccountHelper) {
        // Note that org may be null for customers so ensure we do not throw an exception
        window.AccountHelper.overrideApiKeyVexcel = org.api_key_vexcel

        window.AccountHelper.overrideApiKeyNearmap = org.api_key_nearmap

        window.AccountHelper.overrideApiKeyArcGisOSM = org.api_key_arcgis_osm

        const auth = appStorage.getAuth()
        // Use org override if available, otherwise use generic value at top level
        var apiKeyGoogle = org.api_key_google || auth?.api_key_google

        // Never clear overrideApiKeyGoogle, only overwrite with a new (not-empty) value
        // to avoid some race-condition bugs
        if (apiKeyGoogle) {
          window.AccountHelper.overrideApiKeyGoogle = apiKeyGoogle
        }
      }

      return {
        ...state,
        loading: false,
        org: payload.org,
        workflows: payload.workflows,
        rate_refresh_reminder: undefined,
      }
    case GET_ORG_FAIL:
      return {
        ...state,
        loading: false,
        org: undefined,
        rate_refresh_reminder: undefined,
      }
    case CLEAR_ORG:
      appStorage.setString('api_key_google')
      return {
        ...state,
        loading: false,
        org: undefined,
        rate_refresh_reminder: undefined,
      }
    case UPDATE_ACTIVE_ACCOUNTING_INTEGRATIONS:
      if (!state.org) {
        return { ...state }
      } else {
        return {
          ...state,
          org: {
            ...state.org,
            active_accounting_integrations: payload.activeIntegrations,
          },
        }
      }

    case ACCOUNTING_INTEGRATIONS:
      if (!state.org) {
        return { ...state }
      } else {
        const updatedState = {
          ...state,
          org: {
            ...state.org,
            accounting_integrations: payload.accountingIntegrations,
          },
        }
        return updatedState
      }

    case UPDATE_ORG_WITH_CA_TEMPLATE:
      if (!state.org?.docusign_config) {
        throw new Error("Can't update Org's docusign config as it doesn't exist")
      }
      if (!state.org?.id !== payload.orgId) {
        throw new Error("Can't update Org's docusign config, not current Org")
      }
      return {
        ...state,
        org: {
          ...state.org,
          docusign_config: {
            ...state.org.docusign_config,
            enable_california_protection_guide: payload.newVal,
          },
        },
      }
    case STORE_RATE_REFRESH_DATA:
      return {
        ...state,
        rate_refresh_reminder: {
          cached_response: payload?.cached_response,
          last_fetched: payload?.last_fetched,
          last_all_clear: payload?.last_all_clear,
        },
      }
    default:
      return { ...state }
  }
}

export function doLoadOrg(orgId: number): Promise<OrgType> {
  return new Promise<OrgType>((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/as_object/`,
    })
      .then((response: any) => {
        const org: OrgType = response.data
        resolve(org)
      })
      .catch((err: any) => {
        if (!err.body?.mfa_status) console.warn('Failed to load Org data: ', err)
        // MFA errors will be handled in `useLogoutIfAccessDenied.ts`
        reject(err)
      })
  })
}

export function loadOrgWorkflows(orgId: number): Promise<WorkflowType[]> {
  return new Promise<WorkflowType[]>((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/workflows/?show_archived=1&owned_by=${orgId}`,
    })
      .then((response: any) => {
        const workflows: WorkflowType[] = response.data
        resolve(workflows)
      })
      .catch((err: any) => {
        if (!err.body?.mfa_status) console.warn('Failed to load Org data: ', err)
        // MFA errors will be handled in `useLogoutIfAccessDenied.ts`
        reject(err)
      })
  })
}

export function* getCurrentOrgSaga({ payload }: ReduxActionType) {
  try {
    let orgId = payload.orgId
    if (orgId === undefined) {
      orgId = yield select(authSelectors.getOrgId)
      if (orgId === undefined) orgId = getOrgId()
    }
    yield put({ type: GET_ORG_LOADING, payload })
    const res: [OrgType | undefined, WorkflowType[] | undefined, ProjectView[] | undefined] = yield all([
      call(doLoadOrg, orgId),
      call(loadOrgWorkflows, orgId),
      call(doGetProjectViews, orgId),
    ])
    if (!res) yield put({ type: GET_ORG_FAIL })
    else {
      yield put({ type: GET_ORG_SUCCESS, payload: { org: res[0], workflows: res[1] } })
      yield put({ type: GET_PROJECT_VIEWS_SUCCESS, payload: { project_list_views_data: res[2] } })
      var theme: OsTheme = res[0]?.external_account_id ? 'nearmap' : 'opensolar'
      yield put(changeTheme(theme))
      yield put(wsActions.checkConnection())
      yield put({ type: SET_ORG_SUCCESS })
    }
  } catch (e) {
    yield put(wsActions.checkConnection())
    yield put(setTheme('opensolar'))
    yield put({ type: GET_ORG_FAIL, error: e })

    const body = (e as any).body

    const mfaStatus: MfaErrorStatus | undefined = body?.mfa_status
    if (mfaStatus) {
      console.debug('MFA issue, redirecting to MFA setup', body?.mfa_status)
      yield put(setMfaHasDevices(!!body?.has_sms_device, !!body?.has_totp_device, !!body?.has_recovery_codes))
      yield put(setMfaCheckRequired(mfaStatus === 'mfa_required' || mfaStatus === 'mfa_rejected'))
      yield put(setMfaConfigRequired(mfaStatus === 'mfa_required_but_not_configured'))
      push('/login')
      return true
    }
  }
}

export function* setOrgSuccessEffect() {
  const org = yield select(orgSelectors.getOrg)
  const user = yield select(authSelectors.getCurrentUser)
  const role = yield select(authSelectors.getCurrentRole)
  const isStaff = yield select(authSelectors.getIsStaff)
  const isAnonymous = yield select(authSelectors.getIsAnonymous)

  // set header state
  const isLinkedIronRidgeOrg = yield select((state) => orgSelectors.getIsPartnerOrgLinked(state, 'ironridge'))
  yield put(viewModeActions.setBrandingState(isLinkedIronRidgeOrg ? 'ironridge' : 'opensolar'))

  setAmplitudeIdentity({
    orgId: org.id,
    orgName: org.name,
    userId: user.id,
    roleId: role.id,
    isStaff,
    isAnonymous,
    userEmail: role.email || user.email,
    phone: role.phone || user.phone,
  })
}

export const accountingIntegrations = async (dispatch, orgId, roleId) => {
  try {
    const API_URL = window.API_ROOT + '/api'
    const restClientInstance = restClient(API_URL)
    const response = await restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/accounting/connections/',
    })
    const accResponse = response.data

    if (!accResponse || !Array.isArray(accResponse)) {
      console.error('Unexpected API response structure:', response)
      return
    }

    const mappedData: AccountingIntegration[] = accResponse.map((acc) => {
      return {
        name: acc.name,
        value: acc.name,
        enabled: acc.status === 'connected',
        id: acc.integration_id,
        connection_url: acc.connection_url,
        product_ledger_account_id: acc.product_ledger_account_id,
        payment_ledger_account_id: acc.payment_ledger_account_id,
        accounting_contact_email: acc.accounting_contact_email,
        provider_account_name: acc.provider_account_name,
      }
    })
    dispatch(updateAccountingIntegrations(mappedData))

    return mappedData
  } catch (error) {
    console.error('Error fetching accounting integrations: ', error)
  }
}

export const createRole = ({ orgUrl, userId }) => {
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'global_roles/',
      data: {
        is_admin: true,
        org: orgUrl,
        permissions_role: 1,
        is_hidden: true,
        user: `${window.API_ROOT_ABSOLUTE}/auth/users/${userId}/`,
      },
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.warn('Error trying to create role: ', error)
        reject(error)
      })
  })
}

export function* joinOrgAndSwitch({ payload }: ReduxActionType) {
  yield call(createRole, payload)
  yield put(authReload({ reloadOrg: false })) // load latest auth data by existing orgId
  yield take([SET_AUTH, AUTH_RELOAD_FAILURE]) // wait for auth reload to complete, otherwise auth check will fail on some pages
  yield put(setOrgId({ orgId: payload.orgId })) // reloads org data by new orgId
  yield put(incrementAppViewVersion())
  yield put(showNotification('Switch Success', 'info'))
}

function findEnabledHardwareSuppliers(state: RootState): HardwareSupplierEnum[] {
  let enabledSuppliers: HardwareSupplierEnum[] = []

  if (orgSelectors.getEnableOutlet(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.SolarOutlet)
  }
  if (orgSelectors.getEnableSegen(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.Segen)
  }
  if (orgSelectors.getEnableCityPlumbing(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.CityPlumbing)
  }
  if (orgSelectors.getEnableHdm(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.Hdm)
  }
  if (orgSelectors.getEnableVtacUk(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.VtacUk)
  }
  if (orgSelectors.getEnableVtacPoland(state)) {
    enabledSuppliers.push(HardwareSupplierEnum.VtacPoland)
  }
  return enabledSuppliers
}

export const orgSelectors = {
  getOrgLoading: (state: RootState) => {
    return state.orgs.loading
  },
  getOrg: (state: RootState) => {
    return state.orgs.org
  },
  getOrgUrl: (state: RootState) => {
    return state.orgs.org?.url
  },
  getConnectedOrgs: (state: RootState) => {
    return state.orgs.org?.connected_orgs || []
  },
  getWorkflows: (state: RootState) => {
    return state.orgs.workflows || []
  },
  getOrgCountryId: (state: RootState) => {
    return orgSelectors.getOrg(state)?.country?.id
  },
  getOrgIso2: (state: RootState) => {
    return orgSelectors.getOrg(state)?.country?.iso2
  },
  getEnableSegen: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return ['GB', 'JE', 'GG'].includes(orgSelectors.getOrgIso2(state) || '') && !!current_org?.enable_segen
  },
  getIsPartnerOrgLinked: (state: RootState, partner: PartnerIdType) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.linked_partner_orgs?.[partner]
  },
  getExpoEnabled: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return current_org?.expo_mode === ExpoModes.Basic || current_org?.expo_mode === ExpoModes.Premium
  },
  getPremiumExpo: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return current_org?.expo_mode === ExpoModes.Premium
  },
  getEnableCityPlumbing: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return orgSelectors.getOrgIso2(state) === 'GB' && !!current_org?.enable_city_plumbing
  },
  getEnableHdm: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return orgSelectors.getOrgIso2(state) === 'GB' && !!current_org?.enable_hdm
  },
  getEnableVtacUk: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return orgSelectors.getOrgIso2(state) === 'GB' && !!current_org?.enable_vtac
  },
  getEnableVtacPoland: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return orgSelectors.getOrgIso2(state) === 'PL' && !!current_org?.enable_vtac
  },
  getEnableOutlet: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return orgSelectors.getOrgIso2(state) === 'AU' && !!current_org?.enable_outlet
  },
  getEnableUKHardwareSuppliers: (state: RootState) => {
    const ukSuppliers: HardwareSupplierEnum[] = [
      HardwareSupplierEnum.Segen,
      HardwareSupplierEnum.CityPlumbing,
      HardwareSupplierEnum.Hdm,
      HardwareSupplierEnum.VtacUk,
    ]
    return ukSuppliers.filter((v: HardwareSupplierEnum) => findEnabledHardwareSuppliers(state).includes(v))
  },
  getEnabledHardwareSuppliers: (state: RootState): HardwareSupplierEnum[] => {
    return getReduxSelectorCachedValueWhenEqual({
      name: 'getEnabledHardwareSuppliers',
      newValue: findEnabledHardwareSuppliers(state),
    })
  },
  getSelectedHardwareSupplier: (state: RootState): HardwareSupplierEnum | undefined => {
    let selectedSupplier = state.orderComponents.selectedHardwareSupplier
    let enabledSuppliers: HardwareSupplierEnum[] = findEnabledHardwareSuppliers(state)

    // A previously enabled supplier might have since been disabled.
    if (selectedSupplier && enabledSuppliers.includes(selectedSupplier)) {
      return selectedSupplier
    } else {
      appStorage.clear('selected_hardware_supplier')
    }

    if (enabledSuppliers.length > 0) {
      let defaultSupplier = enabledSuppliers[0]
      window.reduxStore.dispatch(updateSelectedHardwareSupplier(defaultSupplier))
      return defaultSupplier
    }
    return undefined
  },
  getSelectedHardwareSupplierFilterKey: (state: RootState): HardwareSupplierFilterKeyType | undefined => {
    const selectedSupplier = orgSelectors.getSelectedHardwareSupplier(state)
    return getSupplierConfig(selectedSupplier)?.filterKey
  },
  getRegistrableHardwareSuppliersByCountry: (state: RootState): HardwareSupplierEnum[] => {
    const countryIso2 = orgSelectors.getOrgIso2(state)
    if (countryIso2 === 'AU') {
      return [HardwareSupplierEnum.SolarOutlet]
    } else if (countryIso2 === 'GB') {
      return [HardwareSupplierEnum.Segen]
    } else {
      return []
    }
  },
  distributorRegistrationRequired: (state: RootState): HardwareSupplierEnum | undefined => {
    const selectedDistributor = orgSelectors.getSelectedHardwareSupplier(state)
    const availableDistributorsToRegister = orgSelectors.getRegistrableHardwareSuppliersByCountry(state)
    const enabledDistributors = orgSelectors.getEnabledHardwareSuppliers(state)
    if (!selectedDistributor) {
      return
    }

    if (
      !enabledDistributors.includes(selectedDistributor) &&
      availableDistributorsToRegister.includes(selectedDistributor)
    ) {
      return selectedDistributor
    } else {
      return undefined
    }
  },
  getEnableAutoDesign: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.enable_auto_design
  },
  getIsNearmapOrg: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.external_account_id
  },
  getEnableExhibit: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.enable_exhibit
  },
  getEnableShowcase: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.enable_showcase
  },
  getCobrandingOrg: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return !!current_org?.cobranding_org
  },
  getCustomForms: (state: RootState): CustomFormUsageType[] => {
    const current_org = orgSelectors.getOrg(state)
    return current_org?.custom_forms || []
  },
  hasAllOrgFeatures: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return (features: string[]) => {
      if (!current_org) return false
      for (const feature of features) {
        if (!current_org['enable_' + feature]) return false
      }
      return true
    }
  },
  getAvailableFinanceIntegrations: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return current_org?.available_integrations?.finance || []
  },
  getCentrixCreditCheck: (state: RootState) => {
    const current_org = orgSelectors.getOrg(state)
    return current_org?.enable_centrix_credit_check
  },
  getSungageIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_sungage
  },
  getSunlightIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_sunlight
  },
  getMosaicIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_mosaic
  },
  getLoanpalIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_loanpal
  },
  getDividendIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_dividend
  },
  getPhoenixIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_phoenix
  },
  getBrighteIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_brighte
  },
  getSelinaIntegrationStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_selina
  },
  getCheckoutFinanceStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.enable_checkout_finance
  },
  getGenericIntegrationStatus: (state: RootState, integration_name: string): IntegrationEnabledType => {
    // @ts-ignore these keys are dynamic and driven by the db, not sure how to type this properly
    return orgSelectors.getOrg(state)?.[`enable_${integration_name}`]
  },
  getCachedRefreshReminder: (state: RootState) => {
    return state.orgs.rate_refresh_reminder
  },
  getCurrencySymbolFromOrg: (state: RootState) => {
    const currentOrgIso2 = orgSelectors.getOrgIso2(state)
    if (currentOrgIso2) return currencySymbolForCountry(currentOrgIso2)
    else return '$'
  },
  getCashFlowIsActive: (state: RootState) => {
    return orgSelectors.getOrg(state)?.cashflow_is_active
  },
  getCashFlowOnboardingStatus: (state: RootState) => {
    return orgSelectors.getOrg(state)?.cashflow_onboarding_status
  },
  getBlueSnapOnboardingIsSubmitted: (state: RootState) => {
    const statusValue = orgSelectors.getCashFlowOnboardingStatus(state)
    return statusValue && statusValue !== 'created'
  },
  getBlueSnapOnboardingIsStarted: (state: RootState) => {
    const statusValue = orgSelectors.getCashFlowOnboardingStatus(state)
    return Boolean(statusValue)
  },
  getBlueSnapOnboardingIsInProgress: (state: RootState) => {
    const isStarted = orgSelectors.getBlueSnapOnboardingIsStarted(state)
    const isSubmitted = orgSelectors.getBlueSnapOnboardingIsSubmitted(state)
    return isStarted && !isSubmitted
  },
  getMeasurementUnits: (state: RootState): MeasurementUnits | undefined => {
    const org = orgSelectors.getOrg(state)
    return org?.measurement_units
  },
  getActiveAccountingIntegrations: (state: RootState) => {
    const org = orgSelectors.getOrg(state)
    return org?.active_accounting_integrations
  },
  getAccountingIntegrations: (state: RootState) => {
    const org = orgSelectors.getOrg(state)
    return org?.accounting_integrations || []
  },
}

export function* orgsSagas() {
  yield all([
    takeEvery(GET_ORG, getCurrentOrgSaga),
    takeEvery(JOIN_ORG_AND_SWITCH, joinOrgAndSwitch),
    takeEvery(SET_ORG_SUCCESS, setOrgSuccessEffect),
  ])
}
