import { createPoller, pollingManager, PollResponseInterpreter } from './usePolling'

interface CalculationResponse {
  calculation_id: string
}

interface CalculationStatus {
  status: 'pending' | 'completed'
  result?: any
  partial_results?: any
  error?: string
}

export interface CalculationOptions {
  simulationType: 'initial' | 'full' | 'both'
  onPartialResult?: (data: CalculationStatus) => void
}

const calculationInterpreter: PollResponseInterpreter<CalculationStatus> = {
  shouldContinue: (response) => response.status === 'pending',
  extractResult: (response) => response.result,
  isComplete: (response) => response.status === 'completed',
  getPartialResult: (response) => response.partial_results,
}

export const calculationService = {
  async startCalculation(projectId: string, systemId: string, options: CalculationOptions) {
    try {
      pollingManager.cancelPoll(systemId)

      const baseUrl = `${window.API_BASE_URL}orgs/${window.getStorage().getItem('org_id')}/projects/${projectId}`
      const calculationUrl = `${baseUrl}/perform_system_calculations/`

      // Get filtered scene data
      const sceneJSONFiltered = window.editor.sceneSystemsOnlyAsJSON([systemId], true)

      // Inject system_current into each system if found
      const system_current_matches = window.editor.filterObjects(
        (s: any) => s.type === 'OsSystem' && s.userData.is_current === true
      )

      if (system_current_matches.length > 0) {
        for (const s of sceneJSONFiltered['object']['children']) {
          if (s.userData.is_current !== true) {
            s.userData.system_current = system_current_matches[0].userData
          }
        }
      }

      // Get additional data from project form
      const restPostData = window.projectForm ? window.projectForm.mutators.getUnsavedChangeAffectSystemCalcs() : {}

      const response = await fetch(
        `${calculationUrl}?simulate=${options.simulationType}&is_lite=${
          window.WorkspaceHelper?.getIsProjectLite() ?? false
        }&calc_version=${localStorage.getItem('calc_version') || ''}&async=true`,
        {
          method: 'POST',
          headers: {
            ...window.Utils.tokenAuthHeaders(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...restPostData,
            design: window.CompressionHelper.compress(JSON.stringify(sceneJSONFiltered)),
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to start calculation')
      }

      const result = (await response.json()) as CalculationResponse

      if (result.calculation_id) {
        const statusUrl = `${baseUrl}/calculations/status/${result.calculation_id}/`
        const poller = createPoller<CalculationStatus>(statusUrl, systemId, {
          onPartialResult: options.onPartialResult,
          maxAttempts: 60,
          initialInterval: 1000,
          maxInterval: 3000,
          backoffRate: 1.2,
          interpreter: calculationInterpreter,
        })

        return poller.start()
      }
      throw new Error('No calculation ID received from server')
    } catch (error) {
      // Don't clear status if it was just a cancellation
      if (!(error instanceof Error && error.name === 'AbortError')) {
        throw error
      }
    }
  },

  cancelCalculation(systemId: string) {
    pollingManager.cancelPoll(systemId)
  },

  isCalculating(systemId: string, calculationId: string): boolean {
    return pollingManager.isActivePoll(systemId, calculationId)
  },
}
