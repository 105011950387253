import { MeasurementUnits } from 'types/orgs'

export const trimDecimalPlaces = (v, places) => {
  if (isNaN(v)) {
    console.log('isNaN(v)', v)
    return v
  } else {
    return parseFloat(v.toFixed(places).toString())
  }
}

export const inchesToMeters = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const metersToInches = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const feetToInches = (v: number): number => {
  return v * 12
}

export const inchesToFeet = (v: number): number => {
  return v / 12
}

// Warning: trimming decimal places is EXTREMELY delicate. So easy to screw it up.
// Known issue: Trying to enter more than 4 decimal places gives strange behavior but at least it's not unsfae.

// @TODO: find all calls to this function and refactor such that
// they don't expect the return value to be trimmed automatically
export const toMeters = (value: number, measurements: MeasurementUnits): number => {
  return measurements === 'imperial' ? trimDecimalPlaces(inchesToMeters(value), 4) : value
}

// @TODO: find all calls to this function and refactor such that
// they don't expect the return value to be trimmed automatically
export const fromMeters = (value: number, measurements: MeasurementUnits): number => {
  return measurements === 'imperial' ? trimDecimalPlaces(metersToInches(value), 2) : value
}

export const formatNumber = (n: number): number => {
  return Math.round(n * 10) / 10
}

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
}

export enum ImperialUnits {
  Feet = 'ft',
  Inches = 'in',
  // add more if necessary
}

export enum MetricUnits {
  Meters = 'm',
  // add more if necessary
}
