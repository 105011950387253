import { default as Step } from '@material-ui/core/Step'
import { default as StepLabel } from '@material-ui/core/StepLabel'
import { default as Mui4Stepper, Orientation } from '@material-ui/core/Stepper'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight } from '@material-ui/icons'
import { Theme } from '@mui/material'

const useStepperStyles = makeStyles<Theme, { orientation: Orientation }>({
  root: {
    padding: ({ orientation }) => (orientation === 'horizontal' ? '.5rem 0 1rem 0' : '24px'),

    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiStepIcon-root': {
      color: 'rgb(193 193 193 / 38%)',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#FFDD52',
    },
    '& .MuiStepIcon-text': {
      fill: '#000',
    },
    '& .MuiStepLabel-label': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#019939',
    },
    '& .MuiTypography-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiStepLabel-label .MuiSvgIcon-root': {
      fontSize: '20px',
      margin: '0 10px',
    },
  },
})

export type StepType = {
  content: string | JSX.Element // Displayed text or component for the step (eg. Link or you can add some tooltip in your text)
  isComplete: boolean // Marks step as completed
  id: number // Used as key
}

export type OsStepperProps = {
  steps: StepType[]
  activeStep: number
  orientation: Orientation //orientation of steps in reference to eachother
  alternativeLabel?: boolean //if true orientation of label in reference to step icon/circle is vertical
}

export const Stepper = (props: OsStepperProps) => {
  const { steps, ...stepperProps } = props
  const orientation = stepperProps.orientation

  const stepperClasses = useStepperStyles({ orientation: stepperProps.orientation })

  return (
    <Mui4Stepper classes={stepperClasses} {...stepperProps}>
      {props.steps.map((step, index) => (
        <Step key={step.id} completed={step.isComplete}>
          <StepLabel>
            {step.content}
            {orientation === 'horizontal' && index !== steps.length - 1 && <ChevronRight />}
          </StepLabel>
        </Step>
      ))}
    </Mui4Stepper>
  )
}
