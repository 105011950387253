import { Dialog, makeStyles, TextField, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import ContentSave from '@material-ui/icons/SaveOutlined'
import Alert from 'elements/Alert'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Box, Button, IconButton, Typography } from 'opensolar-ui'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { useEffect, useState } from 'react'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { getRecommendedQuantities } from './EnphaseSystemCalculator'
import {
  getComponentTitle,
  getFormSections,
  isComponentRelevant,
} from './enphase_bom_generator/EnphaseCalculationHelpers'
import { SiteSummary } from './enphase_bom_generator/EnphaseSiteSummary'

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h1>{children}</h1>
      {onClose ? (
        <IconButton id="TransactionDialogClose" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: '0px 20px 20px 20px',
  },
}))(MuiDialogContent)

const useStyles = () =>
  makeStyles(
    (theme) => {
      return {
        button: {
          '&:hover': {
            cursor: 'pointer',
            background: theme.themeColorDark,
          },

          position: 'relative',
          padding: '4px 10px',
          margin: 10,
          width: 100,
          background: theme.themeColor,
          color: theme.headerFontColor,
        },
        fields: {
          width: 120,
        },
      }
    },
    { name: 'RaSaveButton' }
  )()

const activateBomWhenForComponentFound = [
  'SPR-MAX5-420-E3-AC',
  'SPR-MAX5-415-E3-AC',
  'SPR-MAX5-410-E3-AC',
  'SPR-MAX5-400-E3-AC',
  'SPR-P3-370-BLK-E3-AC',
  'SPR-P3-375-BLK-E3-AC',
  'SPR-P3-380-BLK-E3-AC',
  'SPR-P3-385-BLK-E3-AC',
  'SPR-MAX6-450-E3-AC',
  'SPR-MAX6-445-E3-AC',
  'SPR-MAX6-440-E3-AC',
  'SPR-MAX6-435-E3-AC',
  'SPR-MAX6-430-E3-AC',
  'SPR-MAX6-425-E3-AC',
  'SPR-MAX6-420-E3-AC',
  'SPR-MAX6-430-BLK-E3-AC',
  'SPR-MAX6-425-BLK-E3-AC',
  'SPR-MAX6-420-BLK-E3-AC',
  'SPR-MAX6-415-BLK-E3-AC',
  'SPR-MAX6-410-BLK-E3-AC',
  'SPR-MAX6-405-BLK-E3-AC',
  'SPR-MAX6-400-BLK-E3-AC',
  // Add panel codes for 25 year and 40 warranty (40 year is no longer in use)
  'SPR-MAX5-400-E3-AC/25',
  'SPR-MAX5-410-E3-AC/25',
  'SPR-MAX5-415-E3-AC/25',
  'SPR-MAX5-420-E3-AC/25',
  'SPR-MAX5-400-E3-AC/40',
  'SPR-MAX5-410-E3-AC/40',
  'SPR-MAX5-415-E3-AC/40',
  'SPR-MAX5-420-E3-AC/40',
]

export const checkEnableEnphaseBomGeneratorForSystem = (system) =>
  systemHasComponent(system, activateBomWhenForComponentFound) || systemHasEnphaseInverter(system)

const systemToParams = (project, system) => {
  if (!project || !system) {
    throw new Error('Project and system are required')
  }
  const params = {
    numberOfPhases: project.number_of_phases || 1,
    countryCode: project.country_iso2,
    isCommercial: project.is_commercial,
    system: system,
    numberOfPanels: 0,
    numberOfPanelsPortrait: 0,
    numberOfPanelsLandscape: 0,
  }

  /*
  @TODO: Add support for unstring modules
  */
  system.inverters().forEach((i) => {
    i.mppts().forEach((m) => {
      m.strings().forEach((s) => {
        s.modules.forEach((m) => {
          if (m.getGrid().moduleLayout() === 'portrait') {
            params.numberOfPanelsPortrait++
          } else {
            params.numberOfPanelsLandscape++
          }
        })
      })
    })
  })

  // Count unstrung modules
  const unstrungModules = system.getUnstrungModules?.() ?? []
  unstrungModules.forEach((unstrungModule) => {
    if (unstrungModule.getGrid().moduleLayout() === 'portrait') {
      params.numberOfPanelsPortrait++
    } else {
      params.numberOfPanelsLandscape++
    }
  })

  params.numberOfPanels = params.numberOfPanelsPortrait + params.numberOfPanelsLandscape

  return params
}

const getWarnings = (params, systemCodeToQuantity) => {
  var warnings = {}
  if (systemCodeToQuantity['ENV-S-WB-230'] > 0 && systemCodeToQuantity['ENV-S-WM-230'] === 0) {
    warnings['ENV-S-WM-230'] =
      'You have chosen the ENVOY-S STANDARD which means that you will not be able to optimise for storage or self consumption. Are you sure?'
  }
  return warnings
}

const upperCaseSafe = (value) => {
  return value && value.toUpperCase ? value.toUpperCase() : value
}

const indexOfCaseInsensitive = (valuesArray, value) => {
  var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
  return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
}

const getOtherComponentForCode = (code) => {
  var codeUpperCase = upperCaseSafe(code)
  return window.AccountHelper.getComponentOtherSpecsAvailable(true).filter(
    (component) => upperCaseSafe(component.code) === codeUpperCase
  )[0]
}

const codesForSystem = (system) => {
  return [system.moduleType().code].concat(
    system.inverters().map((i) => i.code),
    system.batteries().map((b) => b.code),
    system.others().map((o) => o.code)
  )
}

const systemHasEnphaseInverter = (system) => {
  return system.inverters().some((inverter) => inverter.manufacturer_name === 'Enphase Energy Inc.')
}

const systemHasComponent = (system, codes) => {
  if (codes && codes.constructor !== Array) {
    codes = [codes]
  }
  return countComponentsForSystem(system, codes) > 0
}

const countComponentsForSystem = (system, codes) => {
  return codesForSystem(system).filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
}

export const applyComponentQuantities = (system, componentQuantities, ephemeralComponentsData) => {
  /*
  We assume that any missing components have already been activated by the time this runs

  If component activation is not found we can still create a component using data in ephemeralComponentData
  ephemeralComponentData is in the form:
  [{
    code: "abc",
    title: "def",
    description: "hij",
    ...
  },...]

  */
  var hasChanged = false

  var missingComponents = []

  Object.entries(componentQuantities).forEach(([code, quantity]) => {
    var componentType, ephemeralComponentData

    if (quantity > 0) {
      // We do not need a component to be loaded in order to remove it.
      // We only need a component to be loaded if we are setting quantity >= 1
      componentType = getOtherComponentForCode(code)

      if (!componentType && ephemeralComponentsData) {
        // if no activation, lookup specs in ephemeralComponentData
        ephemeralComponentData = ephemeralComponentsData.find((c) => c.code === code)
      }

      if (!componentType && !ephemeralComponentData) {
        missingComponents.push(code)
        return
      }
    }

    if (quantity > 0 && !systemHasComponent(system, code)) {
      var osOther = componentType
        ? new window.OsOther({
            other_component_type: 'mounting_other',
            other_id: componentType.id,
            quantity: quantity,
          })
        : new window.OsOther({ ...ephemeralComponentData, other_component_type: 'mounting_other', quantity: quantity })

      window.editor.execute(new window.AddObjectCommand(new window.OsOther(osOther), system, false))
      hasChanged = true
    } else {
      // component already added to system, update quantity if necessary
      system
        .others()
        .filter((other) => other.code === code)
        .forEach((other) => {
          if (other.quantity !== quantity) {
            if (quantity === 0) {
              window.editor.deleteObject(other)
            } else {
              window.editor.execute(new window.SetValueCommand(other, 'quantity', quantity, undefined, true))
            }
            hasChanged = true
          }
        })
    }
  })

  if (missingComponents.length) {
    window.Designer.showNotification(
      'Warning: components have not been activated: ' + missingComponents.join(', '),
      'danger'
    )
    return
  }

  // Force System panel to refresh
  if (hasChanged) {
    window.Designer.showNotification('Components updated')
    window.editor.signals.sceneGraphChanged.dispatch()
  }
}

const submitEnphaseBomGeneratorForm = async (system, componentQuantities) => {
  var otherComponentActivationCodes = window.AccountHelper.getComponentOtherSpecsAvailable(true).map((c) => c.code)

  // First add any components which have quantity > 0 but are not yet activated
  var otherComponentCodesToActivate = Object.entries(componentQuantities)
    .filter(([code, quantity]) => quantity > 0 && indexOfCaseInsensitive(otherComponentActivationCodes, code) === -1)
    .map(([code, quantity]) => code)

  await new Promise((resolve, reject) => {
    if (otherComponentCodesToActivate.length === 0) {
      resolve()
    } else {
      window.AccountHelper.activateComponents(otherComponentCodesToActivate, resolve, reject)
    }
  })
    .then(() => {
      applyComponentQuantities(system, componentQuantities)
    })
    .catch((error) => {
      console.log('Error activating components', error)
    })
}

const parsePositiveIntOrZero = (value) => {
  var valueInt = value ? parseInt(value) : 0
  return valueInt >= 0 ? valueInt : 0
}

const getCodesAndQuantities = (system) => {
  var result = {}

  ;[
    // Modules uses different format to other components
    { code: system.moduleType().code, quantity: system.moduleQuantity() },
  ]
    .concat(system.inverters(), system.batteries(), system.others())
    .forEach((o) => {
      if (result[o.code]) {
        result[o.code] += o.quantity
      } else {
        result[o.code] = o.quantity
      }
    })
  return result
}

export const EnphaseBomGenerator = ({
  selectedLineItemIndex,
  panelSystem,
  currencySymbol,
  system,
  handleClose,
  countryIso2,
}) => {
  const [state, setState] = useState({
    systemCodeToQuantityOverride: {},
  })
  const classes = useStyles()
  var project = window.projectForm?.getState().values || {}
  var params = systemToParams(project, system)
  const [loading, setLoading] = useState(true)
  const [systemCodeToQuantity, setSystemCodeToQuantity] = useState({})
  const [recommendedQuantities, setRecommendedQuantities] = useState({})
  const [warningsByCode, setWarningsByCode] = useState({})
  const [formSections, setFormSections] = useState([])
  const translate = useTranslateWithVariable()
  const enphaseRules = usePublicFeatureConfig('enphase_bom_generator_rules')

  useEffect(() => {
    if (enphaseRules) {
      setLoading(false)
      initializeData()
    }
  }, [enphaseRules])

  const initializeData = () => {
    var systemCodeToQuantity = getCodesAndQuantities(system)
    setSystemCodeToQuantity(systemCodeToQuantity)

    var recommendedQuantities = getRecommendedQuantities(params, enphaseRules)
    setRecommendedQuantities(recommendedQuantities)

    const warningsByCode = getWarnings(params, { ...systemCodeToQuantity, ...state.systemCodeToQuantityOverride })
    setWarningsByCode(warningsByCode)

    const formSections = getFormSections(enphaseRules)
    setFormSections(formSections)
  }

  return (
    <Dialog
      open={true}
      style={{ maxHeight: 'calc(100vh - 56px)' }}
      maxWidth="sm"
      className="enphase-bom-generator-dialog"
    >
      {loading ? (
        <>
          <DialogTitle onClose={handleClose}>{translate('Enphase Bom Generator')}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <LoadingDots />
              <Typography>{translate('Loading Enphase Components')}</Typography>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle onClose={handleClose}>{translate('Generate Enphase BOM')}</DialogTitle>
          <DialogContent>
            <SiteSummary params={params} />
            <div>
              {formSections.map((formSection) => {
                const relevantCodes = formSection.codes.filter((code) =>
                  isComponentRelevant(code, params, enphaseRules)
                )

                if (relevantCodes.length === 0) return null

                return (
                  <div key={formSection.heading}>
                    <hr className="light" style={{ marginTop: 30, marginBottom: 10 }} />
                    <h2>{formSection.heading}</h2>
                    {relevantCodes.map((code) => {
                      const inputFieldValue = state.systemCodeToQuantityOverride.hasOwnProperty(code)
                        ? state.systemCodeToQuantityOverride[code]
                        : systemCodeToQuantity[code] || recommendedQuantities[code].quantity || 0

                      return (
                        <div key={code} style={{ display: 'flex', alignItems: 'flex-start', alignContent: 'center' }}>
                          <div style={{ flex: '0 0 50px' }}>
                            <TextField
                              label={null}
                              type="number"
                              InputLabelProps={{ shrink: true }}
                              fullWidth={false}
                              value={inputFieldValue}
                              name="quantity"
                              onFocus={() => markFieldActive.call(panelSystem, 'EnphaseBomTextInput', system)}
                              onBlur={() => markFieldInactive.call(panelSystem)}
                              onChange={(event) => {
                                const value = parsePositiveIntOrZero(event.target.value)
                                setState({
                                  systemCodeToQuantityOverride: {
                                    ...state.systemCodeToQuantityOverride,
                                    [code]: value,
                                  },
                                })
                              }}
                              error={
                                typeof recommendedQuantities[code].quantity !== 'undefined' &&
                                recommendedQuantities[code].quantity !== null &&
                                inputFieldValue !== recommendedQuantities[code].quantity
                              }
                              inputProps={{ min: 0 }}
                            />
                          </div>
                          <div style={{ paddingLeft: 10, paddingTop: 6 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span>{getComponentTitle(code, enphaseRules)}</span>
                              <span className="small" style={{ marginLeft: 5 }}>
                                ({code})
                              </span>
                            </div>
                            {typeof recommendedQuantities[code].showNotice !== 'undefined' &&
                              recommendedQuantities[code].quantity > 0 && (
                                <Alert severity="info">{translate(recommendedQuantities[code].showNotice)}</Alert>
                              )}
                            {typeof recommendedQuantities[code].quantity !== 'undefined' &&
                              recommendedQuantities[code].quantity !== null &&
                              inputFieldValue !== recommendedQuantities[code].quantity &&
                              recommendedQuantities[code].showWarning && (
                                <Alert severity="warning">
                                  {translate('Recommended quantity: %{quantity}', {
                                    quantity: recommendedQuantities[code].quantity,
                                  })}
                                </Alert>
                              )}
                            {warningsByCode[code] && (
                              <Alert severity="warning">{translate(warningsByCode[code])}</Alert>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </DialogContent>
          <DialogActions style={{ paddingTop: 0 }}>
            <Button
              startIcon={<AlertError />}
              onClick={handleClose}
              style={{ margin: 10, position: 'relative' }}
              backgroundColor="#D8D8D8"
            >
              <span>{translate('Cancel')}</span>
            </Button>
            <Button
              className={classes.button}
              startIcon={<ContentSave />}
              name={'raSaveButton'}
              onClick={() =>
                submitEnphaseBomGeneratorForm(system, {
                  ...Object.entries(recommendedQuantities).reduce(
                    (acc, [code, value]) => ({
                      ...acc,
                      [code]: systemCodeToQuantity[code] ?? value.quantity,
                    }),
                    {}
                  ),
                  ...state.systemCodeToQuantityOverride,
                }).then(handleClose)
              }
            >
              <span>{translate('Apply')}</span>
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
