import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { apiVersion } from './apiVersion'
import { Authorization } from './auth'
import { Http } from './http'

export * from './auth'

export type ApiRequest<D = any> = AxiosRequestConfig<D> & {
  org?: true | number
}
export type InternalApiRequest<D = any> = InternalAxiosRequestConfig<D> & {
  org?: true | number
}

const auth = new Authorization()

export const interceptors = {
  addAuthorization: (request: InternalApiRequest): InternalApiRequest => {
    auth.intercept(request)
    return request
  },

  addBaseUrl: (request: InternalApiRequest): InternalApiRequest => {
    request.baseURL = window.API_BASE_URL || 'https://api.opensolar.com/api/'
    if (request.org !== undefined) {
      //TODO: pull from store
      const orgId = request.org === true ? localStorage.getItem('org_id') : request.org
      request.baseURL += `orgs/${orgId}/`
    }
    return request
  },
  acceptLocale: (request: InternalApiRequest): InternalApiRequest => {
    // add user preferred language
    // browser will add header if not specified
    //TODO: pull from store
    const locale = localStorage.getItem('locale')
    if (locale) {
      //format locale e.g. en_US => en-US
      request.headers['Accept-Language'] = locale.replace('_', '-')
    }
    return request
  },
}

export function createOsHttp(): Http {
  // API for hitting the OpenSolar API
  const http = axios.create()

  // Request middleware
  http.interceptors.request.use(interceptors.addBaseUrl)
  http.interceptors.request.use(interceptors.acceptLocale)
  http.interceptors.request.use(interceptors.addAuthorization)

  // Response middleware
  http.interceptors.response.use(apiVersion)

  return {
    getUri: http.getUri,
    request: http.request,
    get: (req) => (typeof req === 'string' ? http.get(req) : http.get(req.url as string, req)),
    delete: (req) => http.delete(req.url as string, req),
    head: (req) => http.head(req.url as string, req),
    options: (req) => http.options(req.url as string, req),
    post: (data, req) => http.post(req.url as string, data, req),
    put: (data, req) => http.put(req.url as string, data, req),
    patch: (data, req) => http.patch(req.url as string, data, req),
    postForm: (data, req) => http.postForm(req.url as string, data, req),
    putForm: (data, req) => http.putForm(req.url as string, data, req),
    patchForm: (data, req) => http.patchForm(req.url as string, data, req),
  }
}

// API for hitting the OpenSolar API
const os = createOsHttp()

// API for hitting external APIs
const other = axios.create()
other.interceptors.request.use(interceptors.acceptLocale)

export let http = {
  os,
  other: other as Http,
  auth,
}
