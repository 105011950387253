import { makeStyles, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Button, CashFlowIcon, Grid, KeyIcon, Typography } from 'opensolar-ui'
import { GetExpectedMilestonesResponseType, PaymentRequestType } from 'pages/cashFlow/types'
import { getExpectedMilestones } from 'pages/cashFlow/utils'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import MilestoneTimeline from 'projectSections/sections/payments/projectProgress/cashFlow/MilestoneTimeline'
import PaymentsPieChart from 'projectSections/sections/payments/projectProgress/cashFlow/PaymentsPieChart'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { StudioSystemType } from 'types/global'
import { ExpectedMilestonePaymentType, PaymentOptionDataType } from 'types/paymentOptions'
import { urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { StyledSectionWrapper } from '../styles'
import { getIsProjectSold } from './util'

type PropTypes = {
  project: Record<string, any>
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean; enableManageShop?: boolean }>((theme) => ({
  mainContainer: {
    width: ({ isMobile }) => (isMobile ? 'unset' : '98%'),
  },
  sectionWrapper: {
    padding: 0,
  },
  cfPmtsIcon: {
    marginTop: 10,
  },
  cfSoldContainer: {
    height: '130px',
    borderRight: ({ isMobile }) => (isMobile ? 'none' : '1px solid #ECECED'),
  },
  ccOptionsRow: {
    display: ({ isMobile }) => (isMobile ? 'none' : 'flex'),
    position: 'absolute',
    right: 0,
    gap: 10,
  },
  border: {
    borderRight: ({ isMobile }) => (isMobile ? 'none' : '1px solid #ECECED'),
  },
  smallContainer: {
    fontSize: 'small',
  },
  previewWrapper: {
    padding: '0 10px',
  },
  preview: {
    width: ({ isMobile }) => (isMobile ? '90%' : '98%'),
    opacity: 0.3,
    border: '1px solid',
    borderColor: theme.greyLight2,
    padding: 10,
    borderRadius: 5,
  },
  keyWrapper: {
    position: 'absolute',
    right: ({ isMobile }) => (isMobile ? '40%' : '23%'),
    top: ({ isMobile }) => (isMobile ? '101%' : 'unset'),
    transform: 'translate(-50%, -140%)',
    textAlign: 'center',
    background: theme.greyLight3,
    padding: 5,
    borderRadius: 10,
    border: '1px solid',
    borderColor: theme.greyMid2,
    cursor: 'pointer',
  },
  pmtIcons: {
    height: 25,
  },

  blueBtn: {
    backgroundColor: '#4272DD !important',
    color: 'white !important',
    marginTop: '10px !important',
  },

  accordion: {
    border: 'none !important',
    borderRadius: 0,
    borderBottomLeftRadius: ({ enableManageShop }) => (enableManageShop ? '0' : '8px !important'),
    borderBottomRightRadius: ({ enableManageShop }) => (enableManageShop ? '0' : '8px !important'),

    margin: '0 !important',

    '& .MuiAccordionSummary-content': {
      position: 'relative',
      '& span': {
        fontWeight: 500,
        fontSize: '1rem',
      },
    },
  },
}))

const CashFlowWidgetContent: React.FC<PropTypes> = ({ project }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)

  const soldSystemURL = project?.system_sold
  const soldPmtURL = project?.payment_option_sold
  const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)
  const [isFetchingMilestones, setIsFetchingMilestones] = useState<boolean>(false)
  const [selectedSystem, setSelectedSystem] = useState<StudioSystemType | undefined>(soldSystem)
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOptionDataType | undefined>(undefined)
  const [milestones, setMilestones] = useState<ExpectedMilestonePaymentType[]>([])
  const [adHocPaymentRequests, setAdHocPaymentRequests] = useState<PaymentRequestType[]>([])
  const [totalPaid, setTotalPaid] = useState<number | undefined>(undefined)
  const [totalPayable, setTotalPayable] = useState<number | undefined>(undefined)

  const refreshMilestones = useCallback(() => {
    if (selectedPaymentOption?.org_id && project?.id && selectedSystem?.uuid && selectedPaymentOption?.id) {
      const system = window.editor.getSystems()?.find((sys) => sys.uuid === selectedSystem?.uuid)
      const paymentOption = system?.payment_options?.find((pmt) => pmt.id === selectedPaymentOption?.id)
      getExpectedMilestones(
        selectedPaymentOption?.org_id,
        project.id,
        selectedSystem.uuid,
        selectedPaymentOption.id,
        system?.milestone_payment_overrides?.[selectedPaymentOption.id],
        paymentOption?.pricing?.system_price_payable
      )
        .then((res: GetExpectedMilestonesResponseType) => {
          setMilestones(res.data.expected_milestone_payments)
          setAdHocPaymentRequests(res.data.ad_hoc_payment_requests)
          setTotalPaid(res.data.total_amount_paid)
          setTotalPayable(res.data.total_amount_payable)
        })
        .catch((err) => {
          console.log('fetch err', err)
        })
        .finally(() => {
          setIsFetchingMilestones(false)
        })
    }
  }, [selectedPaymentOption, selectedSystem])

  useEffect(() => {
    if (soldSystemURL && soldPmtURL) {
      const allSystemsOnProject = window.editor.getSystems()
      const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)
      setSelectedSystem(soldSystem)
      if (soldSystem) {
        const soldPmtId = urlToId(soldPmtURL)
        const soldPaymentOption = allSystemsOnProject
          ?.find((sys) => sys.uuid === soldSystem.uuid)
          ?.payment_options?.find((pmt) => pmt.id === soldPmtId)
        setSelectedPaymentOption(soldPaymentOption)
      }
    } else {
      if (selectedSystem && !soldSystemURL) setSelectedSystem(undefined)
      if (selectedPaymentOption && !soldPmtURL) setSelectedPaymentOption(undefined)
    }
  }, [soldSystemURL, soldPmtURL])

  useEffect(() => {
    setIsFetchingMilestones(true)
    refreshMilestones()
  }, [selectedPaymentOption, selectedSystem])

  if (!selectedPaymentOption && !selectedSystem && cashFlowActiveOnOrg) return null
  return (
    <div className={classes.mainContainer} data-testid="cashflow-widget-container">
      <Grid container spacing={1}>
        {selectedPaymentOption && selectedSystem && cashFlowActiveOnOrg ? (
          <>
            <Grid item xs={12} md={milestones.length > 3 ? 3 : 5} className={classes.cfSoldContainer}>
              <Typography variant="caption">
                {translate('Sold Payment Option:')} {selectedPaymentOption?.title}
              </Typography>
              <img src={`${window.PUBLIC_URL}/images/cashflow/cashflow-payments.svg`} className={classes.cfPmtsIcon} />
            </Grid>

            <Grid item xs={12} md={milestones.length > 3 ? 7 : 5} className={classes.smallContainer}>
              <MilestoneTimeline
                paymentOption={selectedPaymentOption}
                system={selectedSystem}
                milestones={milestones}
                refreshMilestones={refreshMilestones}
                adHocPaymentRequests={adHocPaymentRequests}
                isFetchingMilestones={isFetchingMilestones}
                projectId={project?.id}
                displayedInWidget={true}
              />
            </Grid>
            <Grid item xs={12} md={2} className={classes.smallContainer}>
              {totalPayable && (
                <PaymentsPieChart totalPaid={totalPaid || 0} totalPayable={totalPayable} widget={true} />
              )}
            </Grid>
          </>
        ) : (
          <NonCashFlowDetails />
        )}
      </Grid>
    </div>
  )
}

const NonCashFlowDetails = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  return (
    <>
      <Grid item xs={12} md={6} className={classes.border} data-testid="non-cashflow-widget-container">
        <Typography variant="caption">
          {translate('Collect payments direct to your bank account, set payment milestones.')}
        </Typography>
        <Typography variant="caption">
          {translate('End to end payment tracking and management.')}

          <a
            href="https://support.opensolar.com/hc/en-us/categories/9535153058447?utm_campaign=CashFlow"
            target="_blank"
            rel="noreferrer"
          >
            {translate('Learn more')}
          </a>
        </Typography>

        <Link to="/cashflow" target="_blank" rel="noopener noreferrer">
          <Button size="small" className={classes.blueBtn}>
            {translate('Setup')}
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to="/cashflow" target="_blank" rel="noopener noreferrer">
          <div className={classes.previewWrapper}>
            <img src={`${window.PUBLIC_URL}/images/cashflow/cashflow-preview.svg`} className={classes.preview} />
          </div>
          <Tooltip title="Unlock CashFlow feature by completing registration">
            <div className={classes.keyWrapper}>
              <KeyIcon size={isMobile ? 25 : 36} />
            </div>
          </Tooltip>
        </Link>
      </Grid>
    </>
  )
}

const CashFlowWidget: React.FC<PropTypes> = ({ project }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const enableManageShop = useFeatureFlag('manage_shop', 'on')
  const isProjectSold = getIsProjectSold(project)
  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)
  const title = isProjectSold && cashFlowActiveOnOrg ? 'Payments' : 'CashFlow'

  const translate = useTranslate()
  const classes = useStyles({ isMobile, enableManageShop })

  if (!isProjectSold && cashFlowActiveOnOrg) return null
  return (
    <StyledSectionWrapper last={!!!enableManageShop}>
      <AccordionCard
        name={'cashflow-widget'}
        defaultExpanded={true}
        title={translate(title)}
        titleIcon={CashFlowIcon}
        summaryContent={
          !isProjectSold &&
          !cashFlowActiveOnOrg && (
            <div className={classes.ccOptionsRow}>
              <img className={classes.pmtIcons} src={`${window.PUBLIC_URL}/images/cashflow/visa.svg`} />
              <img className={classes.pmtIcons} src={`${window.PUBLIC_URL}/images/cashflow/mastercard.svg`} />
              <img className={classes.pmtIcons} src={`${window.PUBLIC_URL}/images/cashflow/apple-pay.svg`} />
              <img className={classes.pmtIcons} src={`${window.PUBLIC_URL}/images/cashflow/google-pay.svg`} />
              <img className={classes.pmtIcons} src={`${window.PUBLIC_URL}/images/cashflow/paypal.svg`} />
            </div>
          )
        }
        render={() => <CashFlowWidgetContent project={project} />}
        lazyRender={true}
        className={classes.accordion}
      />
    </StyledSectionWrapper>
  )
}

export default CashFlowWidget
