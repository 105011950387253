import { makeStyles } from '@material-ui/core'
import useResizeObserver from '@react-hook/resize-observer'
import { createElement, useCallback, useRef, useState } from 'react'

const useStyles = makeStyles({
  appFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    width: '100%',
  },
  footerPadding: {},
})

export const LayoutFooter = ({ bottomNavigation }) => {
  const classes = useStyles()
  const footerRef = useRef<HTMLDivElement | null>(null)
  const [footerHeight, setFooterHeight] = useState(0)
  const target = footerRef.current

  const checkSize = useCallback(() => {
    if (!target) return

    const bounds = target.getBoundingClientRect()
    setFooterHeight(bounds.height)
  }, [target])

  useResizeObserver(footerRef, checkSize)
  return (
    <>
      <div className={classes.footerPadding} style={{ height: footerHeight }} />
      <div id="app-footer" className={classes.appFooter} ref={footerRef}>
        <div id="secondary-footer" />
        {bottomNavigation && createElement(bottomNavigation)}
      </div>
    </>
  )
}
