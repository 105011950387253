import { Box, LinearProgress, makeStyles, Tab, Tabs, TextField } from '@material-ui/core'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import Alert from 'elements/Alert'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { Severities } from 'elements/notificationVariants'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import { handleObjectChangedAsCommand } from 'projectSections/sections/design/systems/PanelSystem'
import {
  filterOverridesForSlot,
  getComponentTypeV2BySlot,
  getDefaultFilterValuesBySlot,
  getSlotKey,
  getSlotRestriction,
  getSlotsByLocation,
  getSlotTitle,
} from 'projectSections/sections/design/systems/Slots'
import { ComponentSelectorDropdown } from 'projectSections/sections/design/systems/tabs/common/ComponentSelectorDropdown'
import { RemoveComponentButton } from 'projectSections/sections/design/systems/tabs/common/RemoveComponentButton'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { systemCalculationsSelectors } from 'reducer/designer/systemCalculation'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { StudioInverterType, StudioSystemType } from 'types/global'
import { SlotType } from 'types/slots'
import { Theme } from 'types/themes'
import { ActionStatus, ActionType, EventType } from 'types/tracking'
import { useAutoAddElectrical } from './autoAddElectrical'
import { ACIsolatorValidation, CableValidation, DCIsolatorValidation } from './ElectricalValidation'

const useStyles = makeStyles((theme: Theme) => ({
  slotTitle: {
    fontWeight: 'bold',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
  },
  icon: {
    color: '#000000',
  },
  slotIcons: {
    textAlign: 'right',
    marginBottom: '5px',
    fontWeight: 'bold',
    '& span': {
      verticalAlign: 'middle',
    },
  },
  wrapper: {
    width: '100%',
    margin: 'auto',
  },
  subWrapper: {
    display: 'flex',
  },
  spacerElem: {
    '& > *': {
      width: '30px',
      [theme.breakpoints.down('xs')]: {
        width: '15px',
      },
      borderLeft: 'solid rgb(126, 126, 126) 1px',
      marginRight: '5px',
    },
    '&>*:first-child': {
      height: '15px',
      borderBottom: 'solid rgb(126, 126, 126) 1px',
    },
    '&>*:last-child': {
      height: '100%',
    },
  },
  container: {
    width: '100%',
    '& > *:last-child': {
      '& > $spacerElem': {
        '& > div:last-child': {
          borderLeft: 'none',
        },
      },
    },
  },
  subContainer: {
    backgroundColor: '#F8F8F8',
    padding: '5px 10px',
    margin: '5px 0',
    border: theme.border.default,
    borderRadius: '3px',
  },
  headingWrapper: {
    margin: '10px 0',
    fontSize: 14,
    color: 'rgb(126, 126, 126)',
    '& > span': { paddingRight: '10px' },
  },
  heading: {
    color: '#000000',
    fontWeight: 400,
    fontSize: 14,
  },
  itemRow: {
    color: '#000000',
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  figure: { fontWeight: 500, textAlign: 'right', width: 90 },
  componentSelectorContainer: {
    '& > * > *': {
      backgroundColor: 'white',
    },
  },
  autoApplyButtons: {
    marginBottom: '10px',
    marginLeft: '2px',
    display: 'flex',
    flexFlow: 'wrap',
    columnGap: '6px',
    alignItems: 'center',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '10px',
  },
}))

type IsolatePerMPPT = boolean

type AutoApplyButtonPropsType = {
  isolatePerMPPT: IsolatePerMPPT
  callback: (IsolatePerMPPT) => void
}

type SetSelectComponentDialogType = (...args: any[]) => void

const AutoApplyButton: FC<AutoApplyButtonPropsType> = ({ isolatePerMPPT, callback }) => {
  const translate = useTranslate()
  const label = isolatePerMPPT ? translate('Isolate strings in parallel') : translate('Isolate strings separately')
  return (
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        callback(isolatePerMPPT)
      }}
    >
      <span>{label}</span>
    </Button>
  )
}

interface InverterPropsType {
  system: StudioSystemType
  inverterUuid?: string
}

const InverterElectricalComponents: FC<InverterPropsType> = ({ inverterUuid, system }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const inverters = system.inverters()
  const inverterUuids = inverters.map((inverter) => inverter.uuid)
  const [selectedInverterIndex, setSelectedInverterIndex] = useState(
    inverterUuid ? inverterUuids.findIndex((uuid) => uuid === inverterUuid) : 0
  )
  const inverter = window.editor.objectByUuid(inverterUuids[selectedInverterIndex])

  const microinverter = Boolean(inverter.microinverter)

  const { errorMessage, autoAddElectrical } = useAutoAddElectrical(system)
  const { trackEvent } = useTrackComponent({
    componentKey: 'inverter_electrical_components',
    eventName: 'Isolator Auto Applied',
  })

  const forceUpdate = useForceUpdate()
  const calculating = useSelector(systemCalculationsSelectors.isSystemProcessing)(system?.uuid)

  useEffect(() => {
    forceUpdate()
  }, [calculating])

  useEffect(() => {
    //TODO: remove this once we're confident that the system is always keeping it's slots up to date
    system.assessSlots()
  }, [])

  function autoApplyButtonClicked(isolatePerMPPT) {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { isolatePerMPPT })
    setIsLoading(true)
    autoAddElectrical(inverter, isolatePerMPPT, () => {
      trackEvent(EventType.SYSTEM_EVENT, { type: ActionType.SAVE, status: ActionStatus.SUCCESS }, { isolatePerMPPT })
      setIsLoading(false)
    })
  }

  function handleTabChange(event, newInverterIndex: number) {
    setSelectedInverterIndex(newInverterIndex)
  }

  function getTabProps(inverter) {
    return {
      id: `electrical-tab-${inverter.uuid}`,
    }
  }

  return (
    <div>
      {Boolean(isLoading) && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
      <Box className={classes.tabContainer}>
        <Tabs variant={'scrollable'} value={selectedInverterIndex} onChange={handleTabChange}>
          {system.inverters().map((inverter, index) => (
            <Tab label={`Inverter ${index + 1}`} {...getTabProps(inverter)} />
          ))}
        </Tabs>
      </Box>
      <div className={[classes.wrapper, classes.container].join(' ')}>
        {!inverter?.microinverter && (
          <>
            <div className={classes.autoApplyButtons}>
              <div>{translate('Auto-apply components')}:</div>
              <AutoApplyButton isolatePerMPPT={true} callback={autoApplyButtonClicked} />
              <AutoApplyButton isolatePerMPPT={false} callback={autoApplyButtonClicked} />
            </div>
            {errorMessage && (
              <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
                {errorMessage}
              </Alert>
            )}
          </>
        )}
        <div className={classes.subContainer}>
          <div className={classes.headingWrapper}>
            <span className={classes.heading}>{translate('Inverter')}</span>
            <span>
              {inverter.manufacturer_name} {inverter.code}
            </span>
          </div>
          {getSlotsByLocation(inverter.uuid, system).map((slot: SlotType) => (
            <ComponentSlotRow system={system} slot={slot} />
          ))}
          <div>
            {getInverterMessages(inverter).map((inverterMessage) => (
              <Alert severity={inverterMessage.severity} styles={{ padding: '4px 4px 4px 2px' }}>
                {translate(inverterMessage.text)}
              </Alert>
            ))}
          </div>
        </div>

        {inverter.mppts().map((mppt: any, mpptIndex: number) => (
          <MpptElectricalComponents index={mpptIndex} microinverter={microinverter} mppt={mppt} />
        ))}
      </div>
    </div>
  )
}

type MpptPropsType = {
  index: number
  mppt: any // For string inverters we use MPPT, for microinverters this is equivalent to a branch of microinverters
  microinverter: boolean
}

const MpptElectricalComponents: FC<MpptPropsType> = ({ index, mppt, microinverter }) => {
  const classes = useStyles()
  const system = mppt.getSystem()

  return (
    <div className={classes.subWrapper}>
      <div className={classes.spacerElem}>
        <div></div>
        <div></div>
      </div>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.headingWrapper}>
            <span className={classes.heading}>
              {microinverter ? 'Branch' : 'MPPT'} {index + 1}
            </span>
          </div>
          {getSlotsByLocation(mppt.uuid, system).map((slot: SlotType) => (
            <ComponentSlotRow system={system} slot={slot} />
          ))}
        </div>

        {mppt.strings().map((electricalString: any, electricalStringIndex: number) => (
          <StringElectricalComponents
            string={electricalString}
            index={electricalStringIndex}
            system={system}
            microinverter={microinverter}
          />
        ))}
      </div>
    </div>
  )
}

type StringPropsType = {
  string: any // Equivalent to cabling within a branch of microinverters
  index: number
  system: StudioSystemType
  microinverter: boolean
}

const StringElectricalComponents: FC<StringPropsType> = ({ string, index, system, microinverter }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div className={classes.subWrapper}>
      <div className={classes.spacerElem}>
        <div></div>
        <div></div>
      </div>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.headingWrapper}>
            <span className={classes.heading}>
              {microinverter ? 'Cabling' : translate('String')} {index + 1}
            </span>
            <span>{string.moduleQuantity()} Panels</span>
          </div>
          {getSlotsByLocation(string.uuid, system).map((slot: SlotType) => (
            <ComponentSlotRow system={system} slot={slot} />
          ))}
        </div>
      </div>
    </div>
  )
}

interface ComponentSlotRowType extends WithHardwareSelectorV2Props {
  system: StudioSystemType
  slot: SlotType
}

function useForceUpdate() {
  const [value, setValue] = useState(0)
  return () => setValue((value) => value + 1)
}

const ComponentSlotRowComponent: FC<ComponentSlotRowType> = ({
  system,
  slot,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const slotKey = getSlotKey(slot)
  const slotRestriction = getSlotRestriction(slot)
  const slotTitle = getSlotTitle(slot)
  const forceUpdate = useForceUpdate()
  const { trackEvent } = useTrackComponent({
    componentKey: 'component_slot_row',
    eventName: 'User Viewed Component Slot Row Actions',
  })
  const project = window.projectForm?.getState().values
  const supplyPhase = project?.number_of_phases === 3 ? 'three_phase' : 'single_phase'

  const checkUpdate = useCallback(
    (object) => {
      if ((object.type === 'OsOther' && !object.parent) || object.parent === system) forceUpdate()
    },
    [system]
  )

  useStudioSignalsLazy(checkUpdate, ['objectChanged', 'objectRemoved', 'objectAdded'], undefined, {
    trackHandler: true,
  })

  return (
    <div className={classes.itemRow}>
      <div>
        <span className={classes.slotTitle}>{translate(slotTitle)}</span>
        {(system.getComponentsForSlot(slotKey).length === 0 || !slotRestriction) && (
          <button
            style={{ flex: '0 0 24px', padding: 0, width: 24, height: 24 }}
            onClick={() => {
              trackEvent(
                EventType.USER_INTERACTION,
                { type: ActionType.CLICK },
                { eventName: 'User Added Component in Design', source: slotTitle }
              )
              if (enableHardwareDialogV2) {
                const componentType = getComponentTypeV2BySlot(slot)
                handleOpenHardwareSelector({
                  componentTypes: componentType ? [componentType] : COMPONENT_TYPE_OPTIONS.map((option) => option.id), // Fall back to all other component types
                  defaultFilterValues: getDefaultFilterValuesBySlot(slot, supplyPhase),
                  slotKey,
                })
              } else {
                dispatch(
                  setSelectComponentDialog(
                    true,
                    window.WorkspaceHelper.project.org_id,
                    window.WorkspaceHelper.project.id,
                    null,
                    'other',
                    filterOverridesForSlot(slot, supplyPhase),
                    slotKey
                  )
                )
              }
            }}
          >
            {'+'}
          </button>
        )}
      </div>
      <div>
        {system.getComponentsForSlot(slotKey).map((component) => (
          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
              >
                <div className={classes.componentSelectorContainer}>
                  <ComponentSelectorDropdown
                    componentType="other"
                    /*@ts-ignore*/
                    component={component}
                    availableComponents={window.AccountHelper.getComponentOtherSpecsAvailable()}
                    select={() => {
                      if (enableHardwareDialogV2) {
                        const componentType = getComponentTypeV2BySlot(slot)
                        handleOpenHardwareSelector({
                          componentTypes: componentType
                            ? [componentType]
                            : COMPONENT_TYPE_OPTIONS.map((option) => option.id), // Fall back to all other component types
                          defaultFilterValues: getDefaultFilterValuesBySlot(slot, supplyPhase),
                          targetUuids: [component.uuid],
                        })
                      } else {
                        dispatch(
                          setSelectComponentDialog(
                            true,
                            window.WorkspaceHelper.project.org_id,
                            window.WorkspaceHelper.project.id,
                            [component.uuid],
                            'other',
                            filterOverridesForSlot(slot, supplyPhase)
                          )
                        )
                      }
                    }}
                  />
                </div>
                {!slotRestriction && (
                  <TextField
                    type="number"
                    variant="outlined"
                    label={translate('Quantity')}
                    name="quantity"
                    value={component.quantity !== 0 ? component.quantity : 1}
                    onChange={(event) => {
                      const value = Number(event.target.value)
                      if (value !== 0) {
                        handleObjectChangedAsCommand(component.uuid, 'quantity', value)
                      }
                    }}
                    style={{ width: 75, marginRight: 15, backgroundColor: 'white' }}
                    inputProps={{ style: { padding: '10px 12px' }, min: 1 }}
                    disabled={component.other_component_type === 'dc_optimizer'}
                  />
                )}
                <RemoveComponentButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.editor.deleteObject(component)
                  }}
                />
              </div>
            </div>
            {slot.type === 'dc_isolator' && (
              <DCIsolatorValidation
                // @ts-ignore
                isolator={component}
                strings={
                  window.editor.objectByUuid(slot.attachToUuid).type === 'OsString'
                    ? [window.editor.objectByUuid(slot.attachToUuid)]
                    : window.editor.objectByUuid(slot.attachToUuid).children
                }
              />
            )}
            {slot.type === 'ac_isolator' && (
              <ACIsolatorValidation
                // @ts-ignore
                isolator={component}
                isolatorLocation={window.editor.objectByUuid(slot.attachToUuid)}
              />
            )}
            {(slot.type === 'ac_cable' || slot.type === 'dc_cable') && (
              <CableValidation
                slot={slot}
                // @ts-ignore
                cable={component}
                supplyPhase={supplyPhase}
                cableLocation={window.editor.objectByUuid(slot.attachToUuid)}
              />
            )}
          </>
        ))}
      </div>
    </div>
  )
}

const ComponentSlotRow = withHardwareSelectorV2(ComponentSlotRowComponent)

type InverterMessage = {
  severity: Severities
  text: string
}

function getInverterMessages(inverter: StudioInverterType): InverterMessage[] {
  var slotRowMessages: InverterMessage[] = []
  if (inverter.inbuilt_dc_isolator)
    slotRowMessages.push({
      severity: 'info',
      text: 'Inverter includes an integrated isolator already, so DC isolators are not required',
    })
  return slotRowMessages
}

export default InverterElectricalComponents
