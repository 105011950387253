export const STAFF_SAMPLE = {
  title: 'Sample Template',
  description: '',
  is_published: false,
  locale: 'en',
  countries_include: 'AU',
  tags: ['Inverters', 'Battery Optimization', 'EV Chargers'],
  contents: JSON.stringify({
    elements: [
      {
        contentType: 'navbar',
        enabled: true,
        data: {
          cta: {
            label: 'Become an Installer',
          },
        },
      },
      {
        contentType: 'cover',
        enabled: true,
        data: {
          tagline: 'Empowering energy freedom for all',
        },
      },
      {
        contentType: 'our_story',
        enabled: true,
        data: {
          body:
            "<p>GivEnergy is on a mission; to make sustainable energy accessible and affordable to all. In the process, we're building a world where energy doesn't cost the earth. GivEnergy provcontentTypees premium energy management solutions at an accessible price. We engineer and manufacture a portfolio of electronic equipment designed to manage energy use and production.<br><br>The GivEnergy range includes inverters, battery systems, EV chargers, energy management software, and a host of supporting accessories. Together, these products create an end-to-end ecosystem for customers to control their energy.</p>",
          video: 'https://www.youtube.com/watch?v=zhoLKq4oq1k',
        },
      },
      {
        contentType: 'featured_products',
        enabled: true,
      },
      {
        contentType: 'highlights',
        enabled: true,
        data: [
          {
            cta: {
              type: 'url',
              label: 'Learn more',
            },
            title: 'All in One',
            description: 'The ultimate home battery, at an accessible price',
            whiteText: false,
          },
          {
            cta: {
              type: 'url',
              label: 'Learn more',
            },
            title: 'Become an installer',
            description: 'Join our international network of GivEnergy installers',
            fullWidth: false,
            whiteText: false,
          },
          {
            cta: {
              type: 'url',
              label: 'Learn more',
            },
            title: 'Full energy ecosystem overview',
            description: "Find out what's included in an energy ecosystem from GivEnergy",
            whiteText: false,
          },
          {
            cta: {
              type: 'url',
              label: 'Add to calendar',
            },
            title: 'The future of battery storage with GivEnergy',
            description: 'Come watch online at 11AM PT on what the dev team is cooking up behind the scenes.',
            enableCountdown: true,
            countdownDate: '2025-10-03T18:11:00.000Z',
            whiteText: false,
          },
        ],
      },
      {
        contentType: 'videos',
        enabled: true,
        data: [
          {
            url: 'https://www.youtube.com/watch?v=zhoLKq4oq1k',
          },
          {
            url: 'https://www.youtube.com/watch?v=zhoLKq4oq1k',
          },
          {
            url: 'https://www.youtube.com/watch?v=zhoLKq4oq1k',
          },
          {
            url: 'https://www.youtube.com/watch?v=zhoLKq4oq1k',
          },
        ],
      },
      {
        contentType: 'blogs',
        enabled: true,
        data: [
          {
            url: 'https://app.opensolar.com/#/login',
          },
        ],
      },
      {
        contentType: 'socials',
        enabled: true,
        data: {
          linkedInUrl: 'test',
        },
      },
      {
        contentType: 'stats',
        enabled: true,
        data: [
          {
            value: '85%',
            description: 'Potential energy bill savings per year',
          },
          {
            value: '93%',
            description: 'Electric round trip efficiency',
          },
          {
            value: '12 ',
            description: 'year product warranties on offer',
          },
          {
            value: '78 million',
            description: 'hours of running monitoring data',
          },
          {
            value: '>300kg',
            description: '/p.a. average carbon emission reduction per home',
          },
          {
            value: '1',
            description: "UK's best-selling battery storage brand",
          },
          {
            value: '<1 ',
            description: 'day installation turnaround',
          },
          {
            value: '100%',
            description: 'compatible with all solar PV Systems',
          },
        ],
      },
      {
        contentType: 'resources',
        enabled: true,
      },
      {
        contentType: 'custom_content_1',
        enabled: false,
      },
      {
        contentType: 'custom_content_2',
        enabled: false,
      },
      {
        contentType: 'custom_content_3',
        enabled: false,
      },
    ],
  }),
}
