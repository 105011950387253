import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ActionHide from '@material-ui/icons/HighlightOff'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'
import React from 'react'

const emptyRecord = {}

const useStyles = makeStyles(
  (theme) => ({
    body: { display: 'flex', alignItems: 'center', flexWrap: 'wrap', minHeight: 56 },
    spacer: { width: theme.spacing(2) },
    hideButton: { padding: 5 },
  }),
  { name: 'RaFilterFormInput' }
)

const FilterFormInput = (props) => {
  const { filterElement, handleHide, resource, variant, margin } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  return (
    <div data-source={filterElement.props.source} className={classnames('filter-field', classes.body)}>
      {!filterElement.props.alwaysOn && (
        <IconButton
          className={classnames('hide-filter', classes.hideButton)}
          onClick={handleHide}
          data-key={filterElement.props.source}
          title={translate('ra.action.remove_filter')}
        >
          <ActionHide />
        </IconButton>
      )}
      {React.cloneElement(filterElement, {
        allowEmpty: filterElement.props.allowEmpty === undefined ? true : filterElement.props.allowEmpty,
        resource,
        record: emptyRecord,
        variant,
        margin,
        helperText: false,
        // ignore defaultValue in Field because it was already set in Form (via mergedInitialValuesWithDefaultValues)
        defaultValue: undefined,
      })}
      <div className={classes.spacer}>&nbsp;</div>
    </div>
  )
}

FilterFormInput.propTypes = {
  filterElement: PropTypes.node,
  handleHide: PropTypes.func,
  classes: PropTypes.object,
  resource: PropTypes.string,
}

export default FilterFormInput
