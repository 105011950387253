import { orgSelectors } from 'ducks/orgs'
import PrivacyLinks from 'pages/ordering/checkoutv3/preOrder/components/PrivacyLinks'
import { memo } from 'react'
import { useSelector } from 'react-redux'

const VtacDisclaimer = () => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const policyLink =
    countryIso2 === 'GB'
      ? 'https://www.vtacexports.com/faq#faq-privacy'
      : 'https://www.vtacexports.com/poland/polityka-prywatnosci'

  const conditionLink =
    countryIso2 === 'GB'
      ? 'https://www.vtacexports.com/faq#faq-terms-and-conditions'
      : 'https://www.vtacexports.com/poland/regulamin'

  return <PrivacyLinks distributor={'V-TAC'} policyLink={policyLink} conditionLink={conditionLink} />
}

export default memo(VtacDisclaimer)
