import { Route, Switch } from 'react-router-dom'
import { ExpoGlobalLandingPage } from '../pages/ExpoGlobalLandingPage'
import { ExpoOrgLandingPage } from '../pages/ExpoOrgLandingPage'

const ExpoRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path={'/expo/'} component={ExpoGlobalLandingPage} />
        <Route
          exact
          path={'/expo/:stand_id/'}
          render={({ match }) => {
            const standId = match.params.stand_id
            return <ExpoOrgLandingPage standId={standId} />
          }}
        />
      </Switch>
    </div>
  )
}

export default ExpoRoutes
