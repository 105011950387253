import React, { FC } from 'react'

/**
 * * Icon Component for: text-input-01, text-input-02
 */
type TextInputOutlineIconProps = {
  variant: 1 | 2
  size?: number
  color?: string
}
export const TextInputOutlineIcon: FC<TextInputOutlineIconProps> = ({
  variant,
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  switch (variant) {
    case 1:
      return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M4.80078 4.7998H8.40078"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.60039 8.39981H3.60039C2.93765 8.39981 2.40039 8.93706 2.40039 9.59981V14.3998C2.40039 15.0625 2.93765 15.5998 3.60039 15.5998H6.60039M10.8004 8.39981H20.4004C21.0631 8.39981 21.6004 8.93706 21.6004 9.59981V14.3998C21.6004 15.0625 21.0631 15.5998 20.4004 15.5998H10.8004M6.60039 19.1998V4.7998M4.80039 19.1998H8.40039"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case 2:
      return (
        <svg viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
          <path
            d="M5.60039 5.5999V4.3999M20.6004 2.5999V7.3999C20.6004 8.06264 20.0631 8.5999 19.4004 8.5999H2.60039C1.93765 8.5999 1.40039 8.06264 1.40039 7.3999V2.5999C1.40039 1.93716 1.93765 1.3999 2.60039 1.3999H19.4004C20.0631 1.3999 20.6004 1.93716 20.6004 2.5999Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}
