import { SnackbarCloseReason, SnackbarProps } from '@material-ui/core/Snackbar'
import PropTypes from 'prop-types'
import { complete, getNotification, hideNotification, NotificationType, undoableEventEmitter } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PureNotification from './PureNotification'

interface Props {
  type?: NotificationType
  autoHideDuration?: number
}

const Notification: React.FunctionComponent<Props & Omit<SnackbarProps, 'open'>> = (props) => {
  const { classes: classesOverride, type, className, autoHideDuration: defaultAutoHideDuration, ...rest } = props
  const [open, setOpen] = useState(false)
  const notification = useSelector(getNotification)
  const dispatch = useDispatch()
  const isInteractive = !!notification?.buttons?.length
  const hasDetail = !!notification?.detail

  useEffect(() => {
    setOpen(!!notification)
  }, [notification])

  const handleRequestClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const getAutoHideDuration = () => {
    if (notification?.hasOwnProperty('autoHideDuration')) {
      return notification?.autoHideDuration
    }
    return defaultAutoHideDuration || (isInteractive ? undefined : hasDetail ? 10000 : 4000)
  }

  const handleExited = useCallback(() => {
    if (notification?.undoable) {
      dispatch(complete())
      undoableEventEmitter.emit('end', { isUndo: false })
    }
    dispatch(hideNotification())
  }, [dispatch, notification])
  //   const handleUndo = useCallback(() => {
  //     dispatch(undo())
  //     undoableEventEmitter.emit('end', { isUndo: true })
  //   }, [dispatch])
  const message = notification?.message || ''

  let id = notification?.id || message.toLowerCase().replace(' ', '_')
  if (id) id = 'notification_' + id

  const handleSnackbarClose = (_, reason: SnackbarCloseReason) => {
    if ((isInteractive || hasDetail) && reason === 'clickaway') return
    handleRequestClose()
  }

  return (
    <PureNotification
      id={id}
      open={open}
      message={message}
      messageArgs={notification?.messageArgs}
      detail={notification?.detail}
      type={notification?.type || type}
      onExited={handleExited}
      onClose={handleSnackbarClose}
      handleRequestClose={handleRequestClose}
      buttons={notification?.buttons}
      disableWindowBlurListener={notification && notification.undoable}
      isInteractive={isInteractive || hasDetail}
      autoHideDuration={getAutoHideDuration()}
      {...rest}
    />
  )
}

Notification.propTypes = {
  type: PropTypes.string,
}

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: undefined,
}

export default Notification
