import { FC } from 'react'

/**
 * * Icon Component for: plug-01
 */
type PlugIconProps = {
  size?: number
  color?: string
}
export const PlugIcon: FC<PlugIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M7.98182 16.6273V20.7M4.49091 3.24545V1.5M11.4727 3.24545V1.5M14.9636 7.31818H1M2.74545 7.31818H13.2182V11.9727C13.2182 14.5434 11.1343 16.6273 8.56364 16.6273H7.4C4.82937 16.6273 2.74545 14.5434 2.74545 11.9727V7.31818Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
