import { Card, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { PersonOutline, SaveOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { Button, Grid, Typography, styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import OnboardingField from '../OnboardingField'
import { CONTACT_FIELD_MAP } from '../constants'
import { ContactFormState, FieldPrefixFilterType } from '../types'
import { contactIsNotEmpty, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'
import { saveOnboardingProgress } from './utils'

interface StyledProps {
  isMobile?: boolean
  isCardSelected?: boolean
  isCardDisabled?: boolean
}

type PropTypes = {
  ownerFields: any
  fieldPrefixFilter: FieldPrefixFilterType
  setFieldPrefixFilter: (boolean) => void
  setIsManualAddressEntryEnabled: (boolean) => void
  isManualAddressEntryEnabled: boolean
  contactForm: ContactFormState
  setContactForm: (ContactFormState) => void
}

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const StyledCard = styled(Card)<StyledProps>(({ theme, isCardSelected, isCardDisabled }) => ({
  flex: 1,
  padding: '1rem',
  borderRadius: '8px',
  cursor: isCardDisabled ? 'default' : 'pointer',
  color: isCardDisabled ? theme.palette.grey[500] : 'inherit',
  border: isCardSelected ? '2px solid #4272DD !important' : 'none',
}))

const Row = styled('div')<StyledProps>(({ isMobile }) => ({
  display: 'flex',
  gap: isMobile ? '10px' : '16px',
  paddingBottom: '30px',
  flexFlow: isMobile ? 'column' : 'row',
}))

const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  margin: 0,
})

const ContactDescription = styled('div')({
  margin: '10px 0 0 35px',
})

const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: 10,
  marginTop: 20,
})

const SaveButton = styled(Button)({
  background: '#2251D0',
  color: 'white',
  fontSize: 13,
  border: 'none',
  borderRadius: 5,

  '&:hover': {
    background: '#2251D0 !important',
    color: 'white',
    fontSize: 13,
    border: 'none',
  },
})

const OwnerAndRepsForm: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const form = useForm()
  const formVals = useFormState().values
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const savedOwnershipArr = useRef(formVals?.ownershipInfoArr).current
  const savedCompanyRep = useRef(formVals?.companyRep).current

  const [ownerIndexToShow, setOwnerIndexToShow] = useState<number>(0)
  const [ownerFieldToShow, setOwnerFieldToShow] = useState<FieldPrefixFilterType>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const OWNER_FIELD_PREFIX = {
    0: 'ownershipInfoArr[0]',
    1: 'ownershipInfoArr[1]',
    2: 'ownershipInfoArr[2]',
    3: 'ownershipInfoArr[3]',
  }

  const companyRep = formVals?.companyRep
  const owner1 = formVals?.ownershipInfoArr?.[0]
  const owner2 = formVals?.ownershipInfoArr?.[1]
  const owner3 = formVals?.ownershipInfoArr?.[2]
  const owner4 = formVals?.ownershipInfoArr?.[3]

  useEffect(() => {
    let indexToShow = formVals?.ownershipInfoArr?.length ? formVals?.ownershipInfoArr?.length : 0
    let prevIndex = 0

    if (indexToShow > 0 && prevIndex < 4) {
      while (prevIndex !== indexToShow) {
        const checkPrevIndex = contactIsNotEmpty(formVals?.ownershipInfoArr[prevIndex])
        checkPrevIndex ? prevIndex++ : (indexToShow = prevIndex)
      }
    }

    setOwnerIndexToShow(indexToShow)
    setOwnerFieldToShow(OWNER_FIELD_PREFIX[indexToShow])
  }, [])

  const repIsUnique = useMemo(() => {
    if (!companyRep) return false

    const normalizedRep = getNormalizeBlueSnapContact(companyRep)
    const owners = [owner1, owner2, owner3, owner4]
    return !owners.some((owner) => getContactsAreEqual(normalizedRep, getNormalizeBlueSnapContact(owner)))
  }, [])

  const onChangeContactType = (type: 'owner' | 'rep') => {
    if (type === 'owner') {
      props.setFieldPrefixFilter(ownerFieldToShow)
      form.change(`ownershipInfoArr[${ownerIndexToShow}]`, {})
    } else {
      props.setFieldPrefixFilter('companyRep')
      const newCompanyRepValue = {}
      CONTACT_FIELD_MAP?.forEach((field) => {
        newCompanyRepValue[field.companyRep] = formVals?.ownershipInfoArr?.[ownerIndexToShow]?.[field.owner]
      })
      form.change('companyRep', newCompanyRepValue)
      // completely remove the owner in this index sinc they are switching to companyrep
      let newOwnershipInfoArr = formVals?.ownershipInfoArr?.filter((owner, i) => i !== ownerIndexToShow)
      form.change(`ownershipInfoArr`, newOwnershipInfoArr)
    }
  }

  const onCancel = () => {
    props.setFieldPrefixFilter(null)
    props.setContactForm((prevState) => ({
      ...prevState,
      showForm: false,
    }))

    if (props.contactForm.mode === 'create') {
      form.change('ownershipInfoArr', savedOwnershipArr)
      form.change('companyRep', savedCompanyRep)
    }
  }

  const onSave = () => {
    setIsLoading(true)
    if (orgId && props.fieldPrefixFilter) {
      saveOnboardingProgress(orgId, formVals, props.fieldPrefixFilter)
        .then(() => {
          props.setFieldPrefixFilter(null)
          props.setContactForm((prevState) => ({
            ...prevState,
            showForm: false,
          }))
        })
        .catch((err) => {
          notify(err?.body?.message, 'warning')
          logAmplitudeEvent('psp_onboarding_next_page_error', { error_message: err?.body?.message })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <div>
      {isLoading ? (
        <FullScreenLoading />
      ) : (
        <>
          {props.contactForm.mode === 'create' && (
            <>
              <Container>
                <Typography textVariant="subtitle1">{translate('Add owner or company representative')}</Typography>
                <Typography textVariant="body1">
                  {translate(
                    'Please provide the personal information of representative for verification, not the business.'
                  )}
                </Typography>
              </Container>

              <Row isMobile={isMobile}>
                <StyledCard
                  isCardSelected={props.fieldPrefixFilter !== 'companyRep' && props.fieldPrefixFilter !== null}
                  onClick={() => onChangeContactType('owner')}
                >
                  <CardHeader>
                    <PersonOutline />
                    <Typography textVariant="subtitle1">{translate('Owner')}</Typography>
                  </CardHeader>
                  <ContactDescription>
                    <Typography textVariant="body1" colorHex="#4D4D4D">
                      {translate('This is a person who owns 25% or more of your business')}
                    </Typography>
                  </ContactDescription>
                </StyledCard>

                {repIsUnique ? (
                  <Tooltip
                    title={translate(
                      '%{name} has already been entered as the designated Company Representative. If you would like to switch the Company Representative please delete %{name}',
                      { name: companyRep.companyRepFirstName + ' ' + companyRep.companyRepLastName }
                    )}
                    enterDelay={300}
                  >
                    <StyledCard isCardDisabled={true}>
                      <CompanyRepHeader isCardDisabled={true} />
                    </StyledCard>
                  </Tooltip>
                ) : (
                  <StyledCard
                    isCardSelected={props.fieldPrefixFilter === 'companyRep' && props.fieldPrefixFilter !== null}
                    onClick={() => onChangeContactType('rep')}
                  >
                    <CompanyRepHeader isCardDisabled={false} />
                  </StyledCard>
                )}
              </Row>
            </>
          )}
          <div>
            <Grid container spacing={2}>
              {props.ownerFields?.map((field) => (
                <OnboardingField
                  field={field}
                  key={field.field_name}
                  fieldPrefixFilter={props.fieldPrefixFilter}
                  setIsManualAddressEntryEnabled={props.setIsManualAddressEntryEnabled}
                  isManualAddressEntryEnabled={props.isManualAddressEntryEnabled}
                />
              ))}
            </Grid>

            <ButtonsWrapper>
              <Button variant="text" color="primary" size="small" onClick={onCancel}>
                {translate('Cancel')}
              </Button>
              {props.fieldPrefixFilter && (
                <SaveButton variant="outlined" color="primary" startIcon={<SaveOutlined />} onClick={onSave}>
                  {translate('Save %{contact} Info', {
                    contact: props.fieldPrefixFilter === 'companyRep' ? 'Company Rep' : 'Owner',
                  })}
                </SaveButton>
              )}
            </ButtonsWrapper>
          </div>
        </>
      )}
    </div>
  )
}

const CompanyRepHeader = ({ isCardDisabled }) => {
  const translate = useTranslate()
  const headerColor = isCardDisabled ? '#A6A6A6' : '#212121'

  return (
    <>
      <CardHeader>
        <PersonOutline />
        <Typography textVariant="subtitle1" colorHex={headerColor}>
          {translate('Company Representative')}
        </Typography>
      </CardHeader>

      <ContactDescription>
        <Typography textVariant="body1" colorHex="#4D4D4D">
          {translate('This is an individual who oversees the business and has significant control over the entities')}
        </Typography>
      </ContactDescription>
    </>
  )
}
export default OwnerAndRepsForm
