import { default as Mui4RadioGroup } from '@material-ui/core/RadioGroup'
import { default as Mui5RadioGroup, RadioGroupProps as Mui5RadioGroupProps } from '@mui/material/RadioGroup'
import { useComponentVersion } from '../hooks/useComponentVersion'
import { OsRadioVersion } from './Radio'

export type RadioGroupProps = Mui5RadioGroupProps

export const RadioGroup = (props: RadioGroupProps) => {
  let version = useComponentVersion('radio')

  switch (version) {
    case OsRadioVersion.V2:
      return <Mui5RadioGroup {...props} />

    default:
      return <Mui4RadioGroup {...props} />
  }
}
