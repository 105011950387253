import { default as Mui5Button } from '@mui/material/Button'
import { LoadingDots } from '../../core/LoadingDots'
import { styled } from '../../styles/styled'

const styles = {
  acceptButton: {
    border: '1px solid #30862C',
    fontWeight: 'normal',
    margin: 0,
    lineHeight: 'normal',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: '#FFFFFF',
    minWidth: 155,
    background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
  },
  acceptButtonDisabled: {
    border: '1px solid #b7b7b7',
    fontWeight: 'normal',
    margin: 0,
    lineHeight: 'normal',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: '#FFFFFF',
    minWidth: 155,
    background: '#dcdcdc',
    pointerEvents: 'none',
  },
}

const LoanAgreementLabel = styled('div')(({ theme }) => {
  return {
    padding: 0,
    width: '100%',
    textTransform: 'none',
    whiteSpace: 'normal',
  }
})

const LoanAgreementButton = styled(Mui5Button)(({ theme, disabled }) => {
  return {
    height: 'unset',
    minHeight: 40,
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    padding: 5,
    fontSize: 16,
    wordWrap: 'break-word',
    width: 'calc(100% - 2px)',
    letterSpacing: 'normal',
    marginBottom: '10px',
    ...styles.acceptButton,
    '&.Mui-disabled': {
      ...styles.acceptButtonDisabled,
    },
  }
})

export const LoanAgreementActionButton = ({
  isLoading,
  label,
  disabled,
  onClick,
}: {
  isLoading: boolean
  label: React.ReactNode
  disabled: boolean
  onClick?(): void
}) => {
  return (
    <LoanAgreementButton
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        onClick?.()
      }}
    >
      {isLoading ? <LoadingDots color="#fff" /> : <LoanAgreementLabel>{label}</LoanAgreementLabel>}
    </LoanAgreementButton>
  )
}

export default LoanAgreementActionButton
