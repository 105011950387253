import {
  default as Mui5InputAdornment,
  InputAdornmentProps as Mui5InputAdornmentProps,
} from '@mui/material/InputAdornment'
import { FC, forwardRef } from 'react'

export type InputAdornmentProps = Omit<Mui5InputAdornmentProps, 'classes'>

export const InputAdornment: FC<InputAdornmentProps> = forwardRef(({ children, ...props }, ref) => {
  return <Mui5InputAdornment {...props}>{children}</Mui5InputAdornment>
})
