import { Delete } from '@material-ui/icons'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { required } from 'validations'
import InputAccordion from '../common/InputAccordion'
import MultipleCardField, { BtnWrapper } from '../common/MultipleCardField'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled?: boolean
}
const BlogFields: FC<PropTypes> = ({ disabled = false }) => {
  const translate = useTranslate()
  const source = useBlockSource('blogs')
  if (!source) return null
  return (
    <InputAccordion title={translate('Blogs')} defaultExpanded={false} source={source}>
      <MultipleCardField
        source={source}
        addLimit={10}
        cardTitle={translate('Blog')}
        render={(item, { index, deleteCard }) => (
          <BlogInputs
            key={'blog-' + index}
            source={`${source}.${index}`}
            disabled={disabled}
            removeCard={() => {
              deleteCard(index)
            }}
            index={index}
          />
        )}
      />
    </InputAccordion>
  )
}

interface BlogInputsProps {
  source: string
  disabled: boolean
  removeCard: () => void
  index: number
}
const BlogInputs: FC<BlogInputsProps> = ({ source, disabled, removeCard, index }) => {
  const translate = useTranslate()
  return (
    <InputAccordion title={`${translate('Blog')} ${index + 1}`}>
      <TextInput source={`${source}.url`} label="Blog Embed Link" disabled={disabled} validate={required} />
      <BtnWrapper>
        <Button onClick={removeCard} startIcon={<Delete />} variant="contained" color="error" style={{ marginTop: 20 }}>
          {translate('Delete Blog')}
        </Button>
      </BtnWrapper>
    </InputAccordion>
  )
}

export default BlogFields
