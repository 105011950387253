import { FC, useEffect, useState } from 'react'
import { http } from '../../services/http'
import Resources from './Resources'

interface PropTypes {
  standId: number
  viewAllUrl?: string
}
const ExhibitResources: FC<PropTypes> = ({ viewAllUrl, standId }) => {
  const [resources, setResources] = useState([])
  useEffect(() => {
    http.os
      .get(`partners/component_resources/${standId}/`)
      .then((res) => {
        if (res.data) setResources(res.data)
      })
      .catch((err) => {
        console.error('Failed to fetch component resources:', err)
      })
  }, [standId])
  return <Resources resources={resources} viewAllUrl={viewAllUrl} />
}

export default ExhibitResources
