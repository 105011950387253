import { Action } from '../core/action'
import { DeepState } from '../core/state'

export const auth = {
  // State
  // Ideally perhaps we could use the existing RoleType but it looks like perhaps we cannot import it here
  // import { RoleType } from 'types/roles'
  current_role: new DeepState<any | undefined>(undefined),

  // Actions
  attemptAutoLogin: new Action<[]>(),
  loginWithToken: new Action<[{ token: string }]>(),
  refreshToken: new Action<[token: string]>(),
  logout: new Action<[]>(),
  setProjectIdentifiersToken: new Action<[token: string]>(),
} as const
