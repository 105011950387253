import { makeStyles } from '@material-ui/core'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { FieldTitle, styled } from 'opensolar-ui'
import { CountryMapType } from 'pages/mfa/types'
import React from 'react'
import { SelectInput, TextInput, useTranslate } from 'react-admin'
import { Theme } from 'types/themes'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
  },
  flagRow: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 5,
    },
    paddingRight: 20,
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
}))

const FullWidth = styled('div')({
  width: '100%',
  marginTop: 8,
})
interface PropTypes {
  source: string
  initialCountry?: string
  label?: string
  disabled?: boolean
}

const PhoneField: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { countriesAll } = useCountryListsTranslated()
  const initialCountryObject = Object.values(countriesAll).find((x) => x.name === props.initialCountry)

  return (
    <FullWidth>
      {props.label && <FieldTitle label={translate(props.label)} />}
      <div className={classes.wrapper}>
        <SelectInput
          label={false}
          margin="none"
          source={`${props.source}.custom_data.os_meta.phone_country_iso2`}
          variant="outlined"
          optionValue="iso2"
          defaultValue={initialCountryObject?.iso2 ?? ''}
          SelectProps={{
            renderValue: (value) => {
              const country = Object.values(countriesAll).find((x) => x.iso2 === value)
              if (country) {
                return <div className={classes.flagRow}>{value}</div>
              }
            },
          }}
          optionText={(country: unknown) => {
            const countryType = country as CountryMapType
            if (countryType == null) return ''
            return `${countryType.name} (${countryType.prefix})`
          }}
          choices={Object.values(countriesAll).filter((x) => x.prefix)}
          disabled={props.disabled}
        />
        <TextInput
          margin="none"
          source={props.source + '.phone'}
          variant="outlined"
          label={false}
          disabled={props.disabled}
        />
      </div>
    </FullWidth>
  )
}

export default PhoneField
