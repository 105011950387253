import { styled } from '@material-ui/core'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import { Bag05OutlineIcon, Button, Chip, ComponentVersionsInherit, Grid, Typography } from 'opensolar-ui'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logAmplitudeEvent } from '../../amplitude/amplitude'
import { orgSelectors } from '../../ducks/orgs'
import { getSupplierConfigByFilterKey } from '../../pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from '../../pages/ordering/type'
import { ExtendedHardwareSupplierConfig } from '../../projectSections/sections/manage3/hardware/DistributorChip'
import useGetDistributorConnectionStatusAndConfig from '../../projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { useDistributorConnect } from '../../services/useDistributorConnect'
import { makeOpenSolarStyles } from '../../themes/makeOpenSolarStyles'
import { useFeatureFlag } from '../../util/split'
import { SelectedMenu } from './constant'
import SideBarNavigationSubMenu from './SideBarNavigationSubMenu'
import { MenuConfigType } from './type'

const SIDEBAR_MENU_CONFIG: MenuConfigType[] = [
  {
    type: SelectedMenu.BUY_HARDWARE,
    buttonLabel: 'Shop',
    amplitudeEventSource: 'hardware_sidebar_navigation_buy_hardware_button',
    redirectUrl: '/shop',
    routeRule: '^/shop(/search(/.*)?)?$',
    icon: <LocalShippingOutlinedIcon />,
    subMenu: [
      {
        title: 'Home',
        url: '/shop',
        amplitudeEventSource: 'hardware_sidebar_submenu_home_button',
        key: 'home',
      },
      {
        title: 'Panels',
        url: '/shop/search/modules',
        amplitudeEventSource: 'hardware_sidebar_submenu_panels_button',
        key: 'module',
      },
      {
        title: 'Inverters',
        url: '/shop/search/inverters',
        amplitudeEventSource: 'hardware_sidebar_submenu_inverters_button',
        key: 'inverter',
      },
      {
        title: 'Batteries',
        url: '/shop/search/batteries',
        amplitudeEventSource: 'hardware_sidebar_submenu_batteries_button',
        key: 'battery',
      },
      {
        title: 'Mounting + BOS',
        url: '/shop/search/others',
        amplitudeEventSource: 'hardware_sidebar_submenu_others_button',
        key: 'others',
      },
    ],
    subMenuHeading: 'Shop',
  },
  {
    type: SelectedMenu.ORDER_BY_PROJECT,
    buttonLabel: 'Order By Project',
    amplitudeEventSource: 'hardware_sidebar_navigation_order_by_project_button',
    redirectUrl: '/shop/order_by_project',
    routeRule: '^/shop/order_by_project(/.*)?$',
    icon: <FormatListBulletedIcon />,
    subMenu: [],
    subMenuHeading: 'Order By Project',
  },
  {
    type: SelectedMenu.MANAGE_ORDERS,
    buttonLabel: 'Manage Orders',
    amplitudeEventSource: 'hardware_sidebar_navigation_manage_orders_button',
    redirectUrl: '/manage_orders/incoming_transfer',
    routeRule: '^/manage_orders(/.*)?$',
    icon: <Bag05OutlineIcon width="19.5" height="19.5" fill="none" />,
    subMenu: [{ title: 'Incoming POs', url: '/manage_orders/incoming_transfer' }],
    subMenuHeading: 'Manage Orders',
  },
  {
    type: SelectedMenu.INVENTORY_MANAGEMENT,
    buttonLabel: 'Inventory Management',
    amplitudeEventSource: 'hardware_sidebar_navigation_inventory_management_button',
    redirectUrl: '/inventory/dashboard',
    routeRule: '^/inventory(/.*)?$',
    icon: <AssignmentTurnedInOutlinedIcon />,
    subMenu: [
      { title: 'Dashboard', url: '/inventory/dashboard' },
      { title: 'Inventory', url: '/inventory/location' },
      { title: 'Outgoing Orders', url: '/inventory/outgoing_transfer' },
      { title: 'Activity', url: '/inventory/activity' },
      { title: 'Insights', url: '/inventory/insights' },
      { title: 'Settings ', url: '/inventory/configuration' },
    ],
    subMenuHeading: 'Manage Inventory',
  },
]

const updateBuyHardwareSubMenuUrls = (
  config: MenuConfigType[],
  distributor: HardwareSupplierFilterKeyType
): MenuConfigType[] => {
  const homePath = distributor ? `/shop/distributor/${distributor}` : '/shop/'

  return config.map((item) => {
    if (item.type === SelectedMenu.BUY_HARDWARE && item.subMenu) {
      return {
        ...item,
        subMenu: item.subMenu.map((subItem) => ({
          ...subItem,
          url: subItem.key === 'home' ? homePath : `/shop/search/${subItem.key}/distributor/${distributor}`,
        })),
      }
    }
    return item
  })
}

const GridSpace = styled(Grid)(({ theme }) => ({
  padding: '5px 0',
}))

const ButtonNoPadding = styled(Button)(({ theme }) => ({
  padding: '0 2px',
}))

interface DistributorStatusMenusProps {
  onClickDistributor: (config: ExtendedHardwareSupplierConfig) => void
  onClickConnect: (config: ExtendedHardwareSupplierConfig) => void
}

const DistributorConnectStatusMenus = ({ onClickDistributor, onClickConnect }: DistributorStatusMenusProps) => {
  const { distributorConfigs, isChecking } = useGetDistributorConnectionStatusAndConfig(undefined)

  const sortedDistributors = useMemo(() => {
    if (!distributorConfigs) return []

    const enableDistributor = distributorConfigs.filter((distributorConfigs) => distributorConfigs.isEnabled)

    return [...enableDistributor].sort((a, b) => {
      if (a.isConnected === b.isConnected) return 0
      return a.isConnected ? -1 : 1
    })
  }, [distributorConfigs])

  return (
    <ComponentVersionsInherit versions={{ chip: 2 }}>
      {sortedDistributors && sortedDistributors.length > 1 && (
        <Grid container>
          <Typography textVariant="helper1" colorHex="#757575">
            SHOP BY DISTRIBUTOR
          </Typography>
          {sortedDistributors?.map((config, index) => (
            <ButtonNoPadding key={config.filterKey} size="small" fullWidth>
              <GridSpace container justify="space-between" key={index} onClick={() => onClickDistributor(config)}>
                <Grid item xs={6} container alignItems="center">
                  {isChecking ? <></> : config.name}
                </Grid>
                <Grid container justify="flex-end" item xs={6}>
                  {isChecking ? (
                    <></>
                  ) : (
                    <Chip
                      size="small"
                      color={config.isConnected ? 'success' : 'info'}
                      label={config.isConnected ? 'connected' : 'connect'}
                      clickable={!config.isConnected}
                      onClick={() => onClickConnect(config)}
                    />
                  )}
                </Grid>
              </GridSpace>
            </ButtonNoPadding>
          ))}
        </Grid>
      )}
    </ComponentVersionsInherit>
  )
}

const getSelectedMenuByRoute = (pathName: string) => {
  return SIDEBAR_MENU_CONFIG.find((menu) => new RegExp(menu.routeRule).test(pathName))
}

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    maxHeight: '100vh',
    scrollbarColor: 'transparent transparent',
    scrollbarWidth: 'thin',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '&:hover': {
      overflow: 'overlay',
      maxHeight: '100vh',
      scrollbarColor: 'grey transparent',
    },
  },
  menuRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    padding: '26px 12px',
    marginBottom: '12px',
    background: '#F6F6F6',
  },
  subMenuContainer: {
    padding: '0 12px',
  },
  button: {
    border: '1px solid #7F7D83',
    borderRadius: '6px',
    fontSize: '15px',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '0 10px',
    color: '#0A090B',
    '&:hover': {
      border: 'none',
      background: '#4272DD',
      color: '#FFFFFF',
    },
  },
  buttonActive: {
    border: 'none',
    background: '#4272DD',
    color: '#FFFFFF',
  },
}))

const SideBarNavigationMenus = () => {
  const history = useHistory()

  const classes = useStyles()
  const location = useLocation()
  const pathname = location.pathname

  const enableInventoryManagement = useFeatureFlag('inventory_management', 'on')
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  const menuForRoute = getSelectedMenuByRoute(pathname)
  const [selectedMenu, setSelected] = useState<MenuConfigType>(menuForRoute || SIDEBAR_MENU_CONFIG[0])
  const [selectedDistributor, updateDistributor] = useState<HardwareSupplierFilterKeyType>()
  const [menuConfig, setMenuConfig] = useState<MenuConfigType[]>(SIDEBAR_MENU_CONFIG)
  const distributorConnectService = useDistributorConnect()
  const [visits, setVisits] = useState<number>(0)

  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'

  useEffect(() => {
    // Update config and selection when selectedDistributor changes
    if (selectedDistributor) {
      const newConfig = updateBuyHardwareSubMenuUrls(SIDEBAR_MENU_CONFIG, selectedDistributor)
      setMenuConfig(newConfig)
      const menuWithDistributorUrls = newConfig.find((newMenu) => newMenu.type === selectedMenu.type)
      if (menuWithDistributorUrls) setSelected(menuWithDistributorUrls)
    }
  }, [selectedDistributor])

  useEffect(() => {
    // Case 1: Reset everything when on shop page
    if (location.pathname.includes('/shop')) {
      // Force rerender of menu
      setMenuConfig([...SIDEBAR_MENU_CONFIG])
      setSelected(SIDEBAR_MENU_CONFIG[0])
      updateDistributor(undefined)

      // Force re-render of distributor connection status menus if this is after a connection
      // TODO: Maybe we can handle the re-rendering on DistributorConnectStatusMenus or useGetDistributorConnectionStatusAndConfig.
      if (location.pathname.includes('after_connect')) {
        setVisits(visits + 1)
      }
      return
    }

    // Update config and selection when path changes
    if (selectedDistributor) {
      let menu = getSelectedMenuByRoute(location.pathname)
      const newConfig = updateBuyHardwareSubMenuUrls(SIDEBAR_MENU_CONFIG, selectedDistributor)
      const menuWithDistributorUrls = newConfig.find((newMenu) => newMenu.type === menu?.type)
      if (menuWithDistributorUrls) setSelected(menuWithDistributorUrls)
    }
  }, [location.pathname])

  const onChange = (menu: MenuConfigType) => {
    history.push(menu.redirectUrl)
    setSelected(menu)

    logAmplitudeEvent('hardware_sidebar_navigation_button_clicked', {
      source: menu.amplitudeEventSource,
    })
  }

  const onClickConnect = useCallback(async (config: ExtendedHardwareSupplierConfig) => {
    updateDistributor(config.filterKey)

    if (!config.isConnected) {
      window.scrollTo({
        top: 10000,
        left: 0,
        behavior: 'smooth',
      })
      return
    }
  }, [])

  const onSelectDistributorMenu = useCallback(async (config: ExtendedHardwareSupplierConfig) => {
    updateDistributor(config.filterKey)
    history.push(`/shop/distributor/${config.filterKey}`)
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.menuRoot}>
        {menuConfig.map((menu) => {
          if (menu.type === SelectedMenu.INVENTORY_MANAGEMENT && !enableInventoryManagement) {
            return null
          }

          if (menu.type === SelectedMenu.ORDER_BY_PROJECT && enableMultiDistributorShop) {
            return null
          }

          return (
            <>
              <Button
                className={`${classes.button} ${selectedMenu?.type === menu.type ? classes.buttonActive : ''}`}
                fullWidth
                onClick={() => onChange(menu)}
                startIcon={menu.icon}
              >
                {menu.buttonLabel}
              </Button>

              {enableMultiDistributorShop &&
                !isAU &&
                selectedMenu.type === SelectedMenu.BUY_HARDWARE &&
                menu.type === SelectedMenu.BUY_HARDWARE && (
                  <DistributorConnectStatusMenus
                    key={`connection-menus-${visits}`}
                    onClickDistributor={onSelectDistributorMenu}
                    onClickConnect={onClickConnect}
                  />
                )}
            </>
          )
        })}
      </div>

      <div className={classes.subMenuContainer}>
        <SideBarNavigationSubMenu
          title={
            selectedDistributor
              ? `Shop ${getSupplierConfigByFilterKey(selectedDistributor as HardwareSupplierFilterKeyType).name}`
              : selectedMenu.buttonLabel
          }
          subMenus={selectedMenu.subMenu}
          activeMenu={selectedMenu.type}
        />
      </div>
    </div>
  )
}

export default SideBarNavigationMenus
