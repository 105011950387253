import { duplicate } from 'actions/restActions'
import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'constants/locales'
import { authSelectors } from 'ducks/auth'
import EditOrImportButton from 'elements/button/EditOrImportButton'
import Empty from 'elements/Empty'
import ListActions from 'elements/react-admin/ListActions'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { Button } from 'opensolar-ui'
import { useMemo, useState } from 'react'
import { BooleanField, Create, Datagrid, Edit, FunctionField, List, useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ExpoStandForm from './ExpoStandForm'
import { createSampleTemplate } from './util'

export const ExpoStandList = (props) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const duplicateFunc = (payload) => {
    dispatch(duplicate(payload))
  }
  const listLabels = useMemo(
    () => ({
      createBtn: `Create Expo ${props.homepage ? 'Homepage' : 'Stand'}`,
      emptyLabel: translate(`Expo ${props.homepage ? 'Homepages' : 'Stands'}`),
    }),
    [props.homepage]
  )

  return (
    <List
      {...props}
      perPage={20}
      hasCreate={isAdmin}
      actions={<ListActions createButtonLabel={listLabels.createBtn} {...props} />}
      empty={<Empty resourceName={listLabels.emptyLabel} />}
    >
      <Datagrid>
        <FunctionField source={'title'} label="Title" render={(record) => record.title} />
        <FunctionField
          label={'Countries Included'}
          displayInCard={true}
          render={(record) => record.countries_include}
          style={{ width: 20 }}
        />
        <FunctionField
          label={'Language'}
          displayInCard={true}
          render={(record) => {
            const selected = LOCALES_SUPPORTED_CUSTOMER_FACING[record.locale]
            return selected
          }}
        />
        {!props.homepage && <FunctionField source={'brand'} label="Brand" render={(record) => record.brand?.name} />}
        <BooleanField source="is_published" label={'Is Published'} displayInCard={true} sortable={false} />
        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource={props.resource}
          duplicate={duplicateFunc}
          isAdmin={isAdmin}
          allowEdit={isAdmin}
          allowDelete={isAdmin}
        />
      </Datagrid>
    </List>
  )
}

const StaffCreateSample = (props) => {
  const notify = useNotify()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const [loading, setLoading] = useState(false)

  if (!isStaff) return null
  return (
    <>
      {loading && <FullScreenLoading />}
      <Button
        variant="contained"
        onClick={() => {
          if (!loading) {
            setLoading(true)
            createSampleTemplate(
              orgId,
              notify,
              (id) => {
                if (id) history.push(`/${props.resource}/${id}`)
              },
              () => {
                setLoading(false)
              }
            )
          }
        }}
      >
        Create Sample Template
      </Button>
    </>
  )
}

export const ExpoStandCreate = (props) => {
  return (
    <Create {...props} actions={<StaffCreateSample {...props} />}>
      <ExpoStandForm {...props} />
    </Create>
  )
}

export const ExpoStandEdit = (props) => {
  return (
    <Edit {...props}>
      <ExpoStandForm {...props} />
    </Edit>
  )
}
