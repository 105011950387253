import { CircularProgress, TextField, Tooltip } from '@material-ui/core'
import {
  BugReportOutlined,
  CancelOutlined,
  CheckCircleOutlined,
  MoneyOutlined,
  RefreshOutlined,
} from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { Chip, ComponentVersionsInherit, Dialog, DialogContent, styled } from 'opensolar-ui'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { formatDateStringBaseOnLocale } from 'util/misc'
import { forcePushPaymentRecord, getSyncStatus, pushToAccounting } from './utils'

type PropTypes = {
  status: string
  failureReason: string | null
  successDate: string | null
  lastAttemptDate: string | null
  orgId: number
  invoiceId: number
  showDebug: boolean
}

const Wrapper = styled('div')({
  cursor: 'pointer',
})

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const FailedActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marinLeft: '4px',
  cursor: 'pointer',
  color: theme.palette.grey[800],
}))

const AccountingSyncStatusChip: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showDebugDialog, setShowDebugDialog] = useState<boolean>(false)
  const [syncJSON, setSyncJSON] = useState<object | null>(null)

  const notify = useNotify()
  const translate = useTranslate()
  const refresh = useRefresh()

  const chipColor = useMemo(() => {
    if (props.status === 'synced') return 'success'
    if (props.status === 'failed') return 'error'
    if (props.status === 'pending') return 'info'
    else return 'warning'
  }, [props.status])

  const label = useMemo(() => {
    if (props.status === 'synced') return 'Synced'
    if (props.status === 'failed') return 'Failed'
    if (props.status === 'pending') return 'Pending'
    return 'Ineligible'
  }, [props.status])

  const icon = useMemo(() => {
    if (props.status === 'synced') return <CheckCircleOutlined />
    if (props.status === 'failed') return <CancelOutlined />
    return undefined
  }, [props.status])

  const tooltipText = useMemo(() => {
    if (props.status === 'synced') return `Synced on ${formatDateStringBaseOnLocale(props.successDate)}`
    if (props.status === 'failed') return props.failureReason || 'Unknown failure reason'
    if (props.status === 'pending') return 'Pending Invoices should be processed shortly'
    return 'This invoice was sent before the accounting integration was activated and cannot be synced.'
  }, [props.status])

  const forcePush = () => {
    setIsLoading(true)
    logAmplitudeEvent('cashflow_accounting_invoic_sync_retry', { invoice_id: props.invoiceId })
    pushToAccounting(props.orgId, props.invoiceId)
      .then((res) => {
        notify(translate('Your invoice has been sent to your accounting system'), 'success')
        logAmplitudeEvent('cashflow_accounting_invoic_sync_retry_success', { invoice_id: props.invoiceId })
      })
      .catch((err) => {
        notify(err ? translate(err) : translate('Unable to sync invoice - reason unknown'), 'warning')
        logAmplitudeEvent('cashflow_accounting_invoic_sync_retry_error', { invoice_id: props.invoiceId })
      })
      .finally(() => {
        setIsLoading(false)
        refresh()
      })
  }

  const getStatus = () => {
    getSyncStatus(props.orgId, props.invoiceId)
      .then((res) => {
        setShowDebugDialog(true)
        // @ts-ignore
        setSyncJSON(res.data?.[0])
      })
      .catch((err) => console.log('err', err))
  }

  const pushPaymentRecord = () => {
    forcePushPaymentRecord(props.orgId, props.invoiceId)
      .then((res) => {
        notify(translate('Payment record pushed successfully'), 'success')
      })
      .catch((err) => {})
  }

  const closeDebugDialog = () => {
    setShowDebugDialog(false)
    setSyncJSON(null)
  }

  return (
    <Row>
      {!isLoading && (
        <Tooltip title={tooltipText}>
          <Wrapper>
            <ComponentVersionsInherit versions={ComponentVersions_3_0}>
              <Chip label={label} color={chipColor} icon={icon} />
            </ComponentVersionsInherit>
          </Wrapper>
        </Tooltip>
      )}

      {props.showDebug && label !== 'Ineligible' && (
        <Tooltip title="Staff Only - get sync details">
          <BugReportOutlined onClick={getStatus} />
        </Tooltip>
      )}
      {props.showDebug && label !== 'Ineligible' && (
        <Tooltip title="Staff Only - push payment record">
          <MoneyOutlined onClick={pushPaymentRecord} />
        </Tooltip>
      )}
      {['failed', 'pending'].includes(props.status) && (
        <FailedActionsContainer>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Tooltip title="Retry Sync">
              <RefreshOutlined onClick={forcePush} />
            </Tooltip>
          )}
        </FailedActionsContainer>
      )}
      <Dialog open={showDebugDialog} onClose={closeDebugDialog} maxWidth="xl">
        <DialogContent>
          <TextField
            value={JSON.stringify(syncJSON, undefined, 4)}
            label="Current Sync Status"
            disabled={true}
            fullWidth
            multiline={true}
            rows={30}
          />
        </DialogContent>
      </Dialog>
    </Row>
  )
}
export default AccountingSyncStatusChip
