import { Tab, Tabs } from '@material-ui/core'
import useFetchComponentsData from 'elements/hardwareSelectorV2/fetch/useFetchComponentsData'
import { ComponentLikeType } from 'elements/hardwareSelectorV2/types'
import { Box, Button } from 'opensolar-ui'
import { useEffect, useMemo, useState } from 'react'
import useSegenService from 'services/segen/useSegenServiceV2'
import { useFeatureFlag } from 'util/split'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box margin={8}>{children}</Box>}
    </div>
  )
}

const ComponentOrderingInfo = ({ record }) => {
  const { code } = record
  const codes = [code]
  const { fetchComponentsData } = useFetchComponentsData()
  const [componentData, setComponentData] = useState<ComponentLikeType | null>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [distributorData, setDistributorData] = useState(null)
  const isEnabled = useFeatureFlag('admin_component_ordering_info', 'on')
  const segenService = useSegenService()
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    fetchComponentsData({ parameters: { codes }, onReady: (data) => setComponentData(data) })
  }, [code])

  const fetchSegenData = async (variantId) => {
    setIsFetching(true)
    const segenData = await segenService.getProduct(variantId)
    setIsFetching(false)
    setDistributorData(segenData)
  }

  const segenOrderingInfo = useMemo(() => {
    if (!componentData) {
      return null
    }

    return componentData[0].ordering_v2?.find((distributors) => distributors.distributor === 'segen')
  }, [componentData])

  if (!isEnabled) {
    return null
  }

  return (
    <Box>
      <Box>
        <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
          <Tab label="ComponentsV2 Data" key="tab-1"></Tab>
          <Tab label="Segen API response" key="tab-2"></Tab>
        </Tabs>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        <pre>{JSON.stringify(componentData, null, 2)}</pre>
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        {segenOrderingInfo ? (
          <Button
            disabled={isFetching}
            variant={'outlined'}
            onClick={() => {
              setDistributorData(null)
              fetchSegenData(segenOrderingInfo?.distributor_variant_id)
            }}
          >
            Fetch from Segen API
          </Button>
        ) : (
          'Segen not available for this component'
        )}
        <>
          {distributorData && (
            <>
              <h3>Segen API response</h3>
              <pre>{JSON.stringify(distributorData, null, 2)}</pre>
            </>
          )}
        </>
      </CustomTabPanel>
    </Box>
  )
}

export default ComponentOrderingInfo
