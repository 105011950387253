import { ImperialUnits, MetricUnits, toTitleCase } from 'Designer/common/helpers'
import { StudioFieldContainer } from 'Designer/components/StudioFieldContainer'
import { useDistanceConverter } from 'Designer/hooks/useDistanceConverter'
import { SetbackType, useSetbacksConfiguration } from 'Designer/hooks/useSetbacksConfiguration'
import { orgSelectors } from 'ducks/orgs'
import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import { debounce } from 'lodash'
import { MenuItem, TextField } from 'opensolar-ui'
import Panel from 'projectSections/sections/design/Panel'
import { useTranslate } from 'ra-core'
import React, { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import { MeasurementUnits } from 'types/orgs'
import { EdgeType, StudioItem } from 'types/studio/items'
import { trimDecimalPlaces } from 'util/misc'
import { useStudioStyles } from '../common/styles'

type PropTypes = {
  edge: EdgeType
  allowEdit: boolean
}

const setbackConfigTypesFilter = (setbackType: SetbackType) => {
  const allowedKeys = [
    'setbacks_default',
    'setbacks_flat_gutter',
    'setbacks_gutter',
    'setbacks_hip',
    'setbacks_rake',
    'setbacks_ridge',
    'setbacks_shared',
    'setbacks_valley',
  ]
  return allowedKeys.includes(setbackType.key)
}

const PanelEdge: React.FC<PropTypes> = memo((props) => {
  const measurementStd = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric
  const measurementUnit = measurementStd === 'imperial' ? ImperialUnits.Feet : MetricUnits.Meters
  const convert = useDistanceConverter({
    from: MetricUnits.Meters,
    to: measurementUnit,
  })

  const translate = useTranslate()
  const studioStyles = useStudioStyles()
  const defaultSetbacksConfig = useSetbacksConfiguration({
    filter: setbackConfigTypesFilter,
    setbackDistanceConverter: (value) => trimDecimalPlaces(convert(value), 3),
  })

  const [_refresh, setRefresh] = useState<number>(0)

  const length = convert(props.edge.getLength())
  const maxLength = convert(props.edge.getLengthConstraint())
  const setbackDistance = convert(props.edge.getSetbackDistance())
  const isWire = props.edge.isWire()
  const edgeType = props.edge.edgeType
  const title = isWire ? 'Wire' : 'Edge'

  const onObjectChanged = useCallback(
    (obj: StudioItem) => {
      if (obj !== props.edge) return
      // force a re-render if the edge has changed
      setRefresh((prev) => (prev < 99 ? prev + 1 : 0))
    },
    [props.edge, setRefresh]
  )

  const setLengthDebounced = useCallback(
    debounce((lengthInMeters) => {
      props.edge.setLength(lengthInMeters)
    }, 1000),
    [props.edge]
  )

  useStudioSignals(onObjectChanged, ['objectChanged'], undefined, { trackHandler: true })

  return (
    <Panel
      showTools={props.allowEdit}
      selectedObject={props.edge}
      title={title}
      summary={null}
      content={null}
      feature={
        <>
          <div className={studioStyles.fieldsRow}>
            <StudioFieldContainer
              style={{ width: '50%' }}
              label={translate('Length')}
              uiKey="studio.tabs.selected_edge.length"
              field={
                <DistanceNumberField
                  disabled={!props.allowEdit}
                  name="edge-length"
                  value={length}
                  maxDecimalPlaces={4}
                  minValue={0.0001}
                  maxValue={maxLength}
                  measurementStd={measurementStd}
                  wrapperStyles={{ marginBottom: 0 }}
                  convertTo="metric"
                  onChange={(newValue) => {
                    if (!props.edge.belongsToStructure()) {
                      // the edge is not part of a quick roof
                      // calculations are light, real-time visual update
                      props.edge.setLength(newValue)
                    } else {
                      // the edge is part of a quick roof
                      // the calculations can be heavy, no real-time visual update
                      setLengthDebounced(newValue)
                    }
                  }}
                />
              }
            />
            {!isWire && (
              <StudioFieldContainer
                style={{ width: '50%' }}
                label={translate('Type')}
                field={
                  <TextField
                    disabled={!props.allowEdit}
                    name="edge-type"
                    select
                    label={null}
                    value={edgeType}
                    onChange={(event) => {
                      window.editor.execute(new window.SetEdgeTypeCommand(props.edge, event.target.value))
                    }}
                    fullWidth
                  >
                    {defaultSetbacksConfig.map((setbackConfig) => {
                      return (
                        <MenuItem key={setbackConfig.keyShort} value={setbackConfig.keyShort}>
                          {`${translate(setbackConfig.name)} (${setbackConfig.distance} ${measurementUnit})`}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                }
              />
            )}
            {isWire && window.OsEdge.wireTypes && (
              <StudioFieldContainer
                style={{ width: '50%' }}
                label={translate('Type')}
                field={
                  <TextField
                    disabled={!props.allowEdit}
                    name="wire-type"
                    select
                    label={null}
                    value={edgeType}
                    onChange={(event) => {
                      const newWireType = event.target.value
                      window.OsEdge.setEdgeTypesForAllLinkedEdge(props.edge, newWireType)
                    }}
                    fullWidth
                  >
                    {window.OsEdge.wireTypes.map((wireType: string) => {
                      return (
                        <MenuItem key={wireType} value={wireType}>
                          {translate(toTitleCase(wireType))}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                }
              />
            )}
          </div>
          {!isWire && (
            <div className={studioStyles.fieldsRow} style={{ marginBottom: 0 }}>
              <StudioFieldContainer
                style={{ width: '50%' }}
                label={translate('Setback Distance')}
                uiKey="studio.tabs.selected_edge.setback_distance"
                field={
                  <DistanceNumberField
                    disabled={!props.allowEdit || !props.edge.hasSetbackDistanceOverride()}
                    name="edge-setback-distance"
                    value={setbackDistance}
                    maxDecimalPlaces={3}
                    minValue={0}
                    maxValue={10}
                    measurementStd={measurementStd}
                    wrapperStyles={{ marginBottom: 0 }}
                    convertTo="metric"
                    onChange={(newValue) => {
                      window.editor.execute(new window.SetEdgeSetbackDistanceCommand(props.edge, newValue))
                    }}
                    onEditabilityChange={(isEditable) => {
                      if (!isEditable) {
                        // reset the setback distance
                        window.editor.execute(new window.SetEdgeSetbackDistanceCommand(props.edge, null))
                      }
                    }}
                    allowReEnable={props.allowEdit}
                    reEnableBtnTitleOnDisabled={translate('Override Setback Distance')}
                    reEnableBtnTitleOnEnabled={translate('Reset Setback Distance')}
                  />
                }
              />
              <div style={{ width: '50%' }}></div>
            </div>
          )}
        </>
      }
    />
  )
})

export default PanelEdge
