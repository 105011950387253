import { FC } from 'react'

/**
 * * Icon Component for: marker-02
 */
type MarkerOutlineIconProps = {
  size?: number
  color?: string
}
export const MarkerOutlineIcon: FC<MarkerOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M8.99937 20.6C8.99937 20.6 16.5124 13.9218 16.5124 8.91307C16.5124 4.76373 13.1487 1.40002 8.99937 1.40002C4.85003 1.40002 1.48633 4.76373 1.48633 8.91307C1.48633 13.9218 8.99937 20.6 8.99937 20.6Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M11.3997 8.60018C11.3997 9.92566 10.3252 11.0002 8.99968 11.0002C7.6742 11.0002 6.59968 9.92566 6.59968 8.60018C6.59968 7.27469 7.6742 6.20018 8.99968 6.20018C10.3252 6.20018 11.3997 7.27469 11.3997 8.60018Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  )
}
