import { Button, Link, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'

interface ActionProps {
  dismissLabel?: string
  ctaLabel: string
  clickUrl: string
  onClose: () => void
  onClick: () => void
}

const ActionWrapper = styled('div', { name: 'ActionWrapper' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 10,
})

const ToastActions: FC<ActionProps> = ({ onClose, dismissLabel, ctaLabel, clickUrl, onClick }) => {
  const translate = useTranslate()
  return (
    <ActionWrapper>
      <Button onClick={onClose} variant="text" color="secondary">
        {dismissLabel || translate('Dismiss')}
      </Button>
      <Link href={clickUrl} target="_blank" rel="noreferrer">
        <Button variant="text" color="info" onClick={onClick}>
          {ctaLabel}
        </Button>
      </Link>
    </ActionWrapper>
  )
}

export default ToastActions
