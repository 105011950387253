import { FC } from 'react'

/**
 * * Icon Component for: arrow-up-right-square-contained
 */
type ArrowUpRightSquareOutlineIconProps = {
  size?: number
  color?: string
}
export const ArrowUpRightSquareOutlineIcon: FC<ArrowUpRightSquareOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M7.65625 6.75772H13.2813M13.2813 6.75772V12.3828M13.2813 6.75772L7.1875 12.8515M15.625 19L4.37498 19C2.51103 19 1 17.489 1 15.625L1 4.375C1 2.51104 2.51103 1 4.37498 1L15.625 1C17.489 1 19 2.51104 19 4.375V15.625C19 17.489 17.489 19 15.625 19Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
