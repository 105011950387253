import { CheckOutlineIcon, ReapplicationConfirm, TransactionActionButton } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const MosaicApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isPowerSwitch, setIsPowerSwitch] = useState<boolean>(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  useEffect(() => {
    setIsPowerSwitch(proposalData.selectedPaymentOption?.category_id === 'Zero')
  }, [proposalData.selectedPaymentOption?.category_id])

  const extra_actions: React.ReactNode[] = []

  if (actionData.status_code === 'available') {
    if (isPowerSwitch) {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          Clicking "Get Pre-Qualified" will take you to Mosaic's platform. OpenSolar does not view or store any of the
          data you enter in this application except name and email address.
        </p>
      )
    } else {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          Clicking "Get Pre-Qualified" will take you to Mosaic's credit application. OpenSolar does not view or store
          any of the data you enter in this application except name and email address.
        </p>
      )
    }
  } else if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  return (
    <>
      {actionData.status_code !== 'complete' && (
        <div>
          <TransactionActionButton
            isLoading={isLoading}
            label={
              <>
                <span>{translate('Get Pre-Qualified')}</span>
                <div style={{ fontSize: 11 }}>({translate('Takes less than 5 minutes')})</div>
              </>
            }
            disabled={actionData.status_code !== 'available' || !!options?.loanDecisionRenderedAt}
            onClick={openApplicationDialog}
          />
          {extra_actions}
        </div>
      )}
      {actionData.status_code === 'complete' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(actionData.status_title?.includes('approved') || actionData.status_title?.includes('Approved')) && (
              <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />
            )}
            <h2>{actionData.status_title}</h2>
          </div>
          <div>{actionData.status_description}</div>
          {actionData.status_title === 'Declined' && (
            <ReapplicationConfirm
              highlightColor={proposalData.myeStyles.highlightColor}
              onConfirm={openApplicationDialog}
              loading={isLoading}
            />
          )}
        </div>
      )}
    </>
  )
}

export default MosaicApplication
