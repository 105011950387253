import { FC } from 'react'

/**
 * * Icon Component for: check-contained-filled
 */
type Props = {
  color?: string
  width?: number
  height?: number
}
export const ConfirmCircleIcon: FC<Props> = ({ color = 'currentColor', width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6183 8.31916L9.0625 11.875L7.85041 10.6629M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
