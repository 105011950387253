// @ts-nocheck

import type { CheckoutElementDataType } from '../types'
import { getIsPhoenixV2 } from '../util'
import { FinanceCtaType } from './genericFinance/constants'

// This method copied directly from original customerActionsData method
// Copy the latest customerActionsData and remove the original customerActionsData before merging
const customerActionsData = (
  project_id,
  system_uuid,
  payment_option_id,
  available_customer_actions,
  myeStyles,
  acceptButtonStyle,
  acceptButtonStyleDisabled,
  paymentOptionData,
  pricing,
  quotationConfiguration,
  systemSize,
  contacts,
  showDialog,
  setWaitingForAction,
  isPro,
  translate,
  additional_costs,
  selectedPaymentOptionName,
  docusignKBAFailures,
  docusignContractEnabled,
  contractTemplate,
  hasAvailableDocusignAction,
  selectedProject,
  selectedPaymentOptionId,
  attachedPhoenixPaymentOption,
  checkoutHidden,
  isMobileFixedSelection
): CheckoutElementDataType[] => {
  /*
    We inject id into each actionData but this is just a temporary id which can be used to identify a specific action
    instead of needing to use its index in the list which may not be reliable (e.g. if we filter the actions)
    This was added to allow Bespoke proposals to identify and trigger a particular action.
    */

  let actionsData = []

  // If two options are the same but differ by the payment_method (e.g. credit_card_stripe and offline)
  // Only show first button for a particular payment option, others can be accessed inside the payment form

  // Refactor this so its easy to compose actions based on payment type
  // Elements include:
  //    Action buttons (Enabled=Green, Disabled=Grey, can have a status message underneath)
  //    Action links
  //    Custom element (e.g. "OR" divider, etc)
  //    System/Payment summary description

  var actionsForSystemAndPaymentOption = []
  let hasAvailablePhoenixApplication = false

  available_customer_actions
    .filter((aca) => aca.system_uuid === system_uuid)
    .forEach((available_customer_action) => {
      available_customer_action.actions_available
        .filter((aca) => aca.system_uuid === system_uuid)
        .filter(
          (actionData) =>
            actionData.status_code !== 'hidden' &&
            (actionData.payment_option_id === null ||
              actionData.payment_option_id === payment_option_id ||
              actionData.payment_method === 'phoenix_application')
        )
        .forEach((actionData, i) => {
          if (actionData.payment_method === 'phoenix_application') hasAvailablePhoenixApplication = true
          actionsForSystemAndPaymentOption.push(actionData)
        })
    })

  // only used for finance integrations on the new structure
  const genericIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter((a) => {
    if (!a.is_pro_facing && isPro) return false
    else if (!a.is_customer_facing && !isPro) return false
    return a.finance_integration && !a.document_type && !a.stipulation_name
  })

  if (hasAvailablePhoenixApplication && getIsPhoenixV2(attachedPhoenixPaymentOption?.integration_external_reference)) {
    hasAvailablePhoenixApplication = false
  }

  // don't use docusign actions if they just signed, and don't use cashflow actions since those are always separate
  var firstAcceptProposalAction = actionsForSystemAndPaymentOption.filter(
    (a) =>
      a !== 'hidden' &&
      (a.payment_method === 'offline' ||
        a.payment_method === 'none' ||
        a.payment_method === 'credit_card_stripe' ||
        (a.payment_method === 'docusign' &&
          (actionsForSystemAndPaymentOption.length === 1 ||
            !window.location.href.includes('event=signing_complete') ||
            !window.location.href.includes('all_signed=true'))))
  )[0]

  const unpaidCashFlowDepositAction = actionsForSystemAndPaymentOption?.filter(
    (a) => a.payment_method === 'cashflow' && a.status_code === 'available'
  )?.[0]

  var elements = []
  if (paymentOptionData.payment_type === 'cash') {
    // Use the first proposal acceptance action for this system and payment option
    elements = [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction }]
  } else {
    // make a map of stips where the key is review status and the value is an array of all stips with that status
    const stipulationMap = {}
    const stipulationActions = actionsForSystemAndPaymentOption.filter((action) => {
      return action?.stipulation_review_status
    })
    if (stipulationActions && stipulationActions.length > 0) {
      stipulationActions.forEach((action) => {
        if (!stipulationMap[action.stipulation_review_status])
          stipulationMap[action.stipulation_review_status] = [action]
        else stipulationMap[action.stipulation_review_status].push(action)
      })
    }

    // Detect the type of loan
    // only used for finance integrations that are still on the original structure
    const actionIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter(
      (a) =>
        (a.finance_integration ||
          [
            'sunlight_loan_application',
            'sunlight_loan_prequal',
            'loanpal_application',
            'mosaic_application',
            'sungage_application',
            'brighte_application',
            'dividend_application',
            'phoenix_application',
          ].indexOf(a.payment_method) !== -1) &&
        !a.document_type &&
        !a.stipulation_name
    )

    const loanAgreementActionsByPartner = {}
    actionsForSystemAndPaymentOption.forEach((action) => {
      if (action.document_type === 'mosaic_loan_agreement') {
        if (!loanAgreementActionsByPartner['mosaic']) loanAgreementActionsByPartner['mosaic'] = action
      } else if (action.document_type === 'loanpal_loan_agreement') {
        if (!loanAgreementActionsByPartner['loanpal']) loanAgreementActionsByPartner['loanpal'] = action
      } else if (action.document_type === 'dividend_loan_agreement') {
        if (!loanAgreementActionsByPartner['dividend']) loanAgreementActionsByPartner['dividend'] = action
      }
    })

    // Special case: If sunlight_loan_application is available then ensure we use it to prevent accidentally choosing
    // sunlight_loan_prequal which does not show the full loan application option
    // Otherwise, just choose the first available loan application option.
    const actionIntegratedLoan =
      actionIntegratedLoanOptions.indexOf('sunlight_loan_application') !== -1
        ? 'sunlight_loan_application'
        : actionIntegratedLoanOptions[0]

    let loanType = actionIntegratedLoan ? actionIntegratedLoan?.payment_method : 'standard'
    // a hack to say even though the application is done we still have stips to deal with so keep this in integrated finance action mode
    if (
      loanType === 'standard' &&
      paymentOptionData.integration === 'loanpal' &&
      Object.keys(stipulationMap).length > 0
    ) {
      loanType = 'loanpal_application'
    }

    const actionPrequal = actionsForSystemAndPaymentOption.filter(
      (a) => a.payment_method === 'sunlight_loan_prequal'
    )[0]

    elements = {
      sunlight_loan_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },

        // Toggle type for Divider based on other actions
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan && ['complete', 'disabled'].includes(actionIntegratedLoan?.status_code)
              ? 'button'
              : 'link',
          loanType,
        },
        { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: false, loanType },
      ],
      sunlight_loan_prequal: [
        { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: true, loanType },

        // Toggle type for Divider based on other actions
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr: firstAcceptProposalAction && firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan && actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      phoenix_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' || isPro ? 'button' : 'link',
          loanType,
        },
      ],
      loanpal_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        { type: 'FinanceStipulations', actionData: stipulationMap, variation: 'button', loanType },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['loanpal'], loanType },
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            !actionIntegratedLoan?.stipulation_review_status &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan?.status_code === 'available' ||
            stipulationMap['created']?.length > 0 ||
            (loanAgreementActionsByPartner['loanpal'] &&
              loanAgreementActionsByPartner['loanpal'].status_code !== 'complete')
              ? 'link'
              : 'button',
          loanType,
        },
      ],
      mosaic_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: !stipulationMap,
          showOr: false,
          loanType,
        },
        { type: 'FinanceStipulations', actionData: stipulationMap, variation: 'button', loanType },
        {
          type: 'Divider',
          hide: !stipulationMap,
          showOr: false,
          loanType,
        },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['mosaic'], loanType },
        {
          type: 'Divider',
          hide:
            (!loanAgreementActionsByPartner['mosaic'] && actionIntegratedLoan?.status_code === 'complete') ||
            !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            (loanAgreementActionsByPartner['mosaic']?.status_code === 'available' ||
              actionIntegratedLoan?.status_code === 'available') &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan?.status_code === 'complete' &&
            (!loanAgreementActionsByPartner['mosaic'] ||
              loanAgreementActionsByPartner['mosaic'].status_code === 'complete')
              ? 'button'
              : 'link',
          loanType,
        },
      ],
      sungage_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      dividend_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['dividend'], loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      brighte_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      standard: [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction, variation: 'button', loanType }],
    }[loanType]
  }

  if (hasAvailablePhoenixApplication) {
    elements.push({ type: 'PhoenixApplication', actionData: firstAcceptProposalAction })
  }

  // Iterate over initial elements to make some adjustments to elements and build the final actionData list
  elements?.forEach((element, index) => {
    if (!element.actionData && element.type !== 'Divider') {
      console.warn('Missing element.actionData from customer action', element)
      // skip to next, not possible to render an action without actionData
      return
    }
    //hack to deal with the fact that the user will be returned from Docusign after signing before our db reflects the fact that they have signed
    if (
      element.actionData &&
      element.actionData.status_code === 'available' &&
      window.location.href.includes('event=signing_complete') &&
      window.location.href.includes('all_signed=true')
    ) {
      if (element.actionData.payment_method === 'offline') {
        // if they just signed a DS contract and all they have left is an offline payment don't show that offline payment CTA
        return
      }
      if (element.actionData.payment_method === 'docusign') {
        // Beware this modifies actionData, it would be nicer to handle above
        element.actionData.status_code = 'complete'
      } else if (element.type === 'AcceptProposal') {
        // Beware this modifies actionData, it would be nicer to handle above
        element.actionData.collect_signature = false

        if (element.actionData.payment_method === 'none') {
          // Beware this modifies actionData, it would be nicer to handle above
          element.actionData.status_code = 'complete'
        }
      }
    }

    // @TODO: Consolidate actionData.eror and actionData.messages (errors and warnings) into a single place
    if (element.actionData && element.actionData.error && element.actionData.error.length > 0) {
      actionsData.push({
        type: 'ActionError',
        error: element.actionData.error,
        id: actionsData.length + 1,
      })
    }

    // Only show actionData validation errors/warnings for Pros. Don't show to a customer.
    // If the action will fail for the customer, let them attempt it first.
    if (isPro && element.actionData && element.actionData.messages && element.actionData.messages.length) {
      actionsData.push({
        type: 'ActionMessages',
        messages: element.actionData.messages,
        id: actionsData.length + 1,
      })
    }

    // Refactor to make this standard for all actions
    if (element.type === 'AcceptProposal') {
      element.actionData.transactionRequestData = Object.assign(
        _.omit(element.actionData, ['transactionRequestData']),
        {
          paymentOptionData: paymentOptionData,
          pricing: pricing,
          quotationConfiguration: quotationConfiguration,
          systemSize: systemSize,
          additional_costs,
        }
      )
    }

    var elementTypesToAddAction = [
      'LoanApplication',
      'AcceptProposal',
      'Prequal',
      'FinanceStipulations',
      'LoanAgreement',
      'PhoenixApplication',
    ]

    if (elementTypesToAddAction.includes(element.type) || (element.type === 'Divider' && !element.hide)) {
      element.id = actionsData.length + 1
      actionsData.push(element)
    }
  })

  genericIntegratedLoanOptions?.forEach((loanOption) => {
    // keeping this simple for now since we're only supporting one finance CTA and accept proposal
    const prioritizeApplication = loanOption?.priority === 0 || loanOption?.priority === 1
    const justFinishedDocusign =
      window.location.href.includes('event=signing_complete') && window.location.href.includes('all_signed=true')
    if (firstAcceptProposalAction && justFinishedDocusign) firstAcceptProposalAction.status_code = 'complete'
    let tempActions = []
    let actionType = 'LoanApplication'
    if (['ALERT_WARNING', 'ALERT_INFO', 'ALERT_ERROR'].includes(loanOption.cta_type)) {
      actionType = 'AlertCTA'
    }
    tempActions.push({ type: actionType, actionData: loanOption, loanType: loanOption.payment_method })
    if (firstAcceptProposalAction) {
      if (actionType !== 'AlertCTA') {
        tempActions.push({
          type: 'Divider',
          hide: loanOption?.status_code === 'complete',
          showOr:
            loanOption &&
            loanOption?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType: loanOption.payment_method,
        })
      }
      tempActions.push({
        type: 'AcceptProposal',
        actionData: firstAcceptProposalAction,
        variation: loanOption?.status_code === 'complete' || !prioritizeApplication ? 'button' : 'link',
        loanType: loanOption.payment_method,
      })
    }

    if (!prioritizeApplication && firstAcceptProposalAction) tempActions.reverse()
    actionsData.push(...tempActions)
  })

  // if after giong through the above scenarios we don't have any actions in actionsData but we do
  // have a value in firstAcceptProposalAction, then just add firstAcceptProposalAction directly
  if (!actionsData?.length && firstAcceptProposalAction) {
    actionsData.push({
      type: 'AcceptProposal',
      actionData: firstAcceptProposalAction,
      variation: 'button',
    })
  }

  if (unpaidCashFlowDepositAction) {
    const variation = firstAcceptProposalAction?.status_code === 'available' ? 'link' : 'button'
    if (variation === 'button') {
      actionsData.unshift({
        type: 'CashFlowDeposit',
        actionData: unpaidCashFlowDepositAction,
        variation: variation,
      })
    } else {
      actionsData.push({
        type: 'CashFlowDeposit',
        actionData: unpaidCashFlowDepositAction,
        variation: variation,
      })
    }
  }

  const { finance_product_category_id, disabled_finance_product_category_ids = [], finance_ctas } = paymentOptionData
  const hasCheckoutFinance =
    finance_ctas?.find(({ type }) => type === FinanceCtaType.CHECKOUT_FINANCE) &&
    finance_product_category_id &&
    !disabled_finance_product_category_ids?.includes(finance_product_category_id)

  if (hasCheckoutFinance) {
    // Checkout finance will be hidden when in mobile and user scrolled down
    actionsData.push({
      type: 'CheckoutFinance',
      actionData: {
        ...firstAcceptProposalAction,
        hidden: isMobileFixedSelection,
      },
    })
  }
  return actionsData
}

/*
  This method consolidate actionData.eror and actionData.messages into a new element type and inject to the result of customerActionsData
  This aim to handle the logic outside of type switch within customerActionButtons function

  Refactor and move logic to populateCheckoutElementsData once fully transition to opensolar-checkout.
*/
const injectMessageElement = (fn): (() => CheckoutElementDataType[]) => {
  return (...args) => {
    const elements: CheckoutElementDataType[] = fn(...args)
    const finalElements: CheckoutElementDataType[] = []
    elements.forEach((element) => {
      const error: string | undefined = element.actionData?.error
      const alertMessages: string[] | undefined = element.actionData?.messages

      // The following logic replicating the existing logic in customerActionButtons to avoid any unexpected behavior
      // Please further consolidate the "message" and "alert" for the BE response and UI
      if (error) {
        finalElements.push({ type: 'Message', actionData: { message: error } })
      }

      if (alertMessages) {
        finalElements.push({ type: 'Alert', actionData: { messages: alertMessages } })
      }

      finalElements.push(element)
    })
    return finalElements
  }
}

export const populateCheckoutElementsData = injectMessageElement(customerActionsData)
