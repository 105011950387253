import { styled } from 'opensolar-ui'
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom'

const ACTIVE_CLASS_NAME = 'active'

const NavLink = styled(RouterLink)<{ accentColor?: string }>(({ accentColor, theme }) => ({
  alignItems: 'center',
  background: 'white',
  boxSizing: 'border-box',
  color: 'black',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  padding: '16px 12px',
  position: 'relative',
  textDecoration: 'none',
  '&::after': {
    backgroundColor: 'rgb(236, 236, 237)',
    bottom: '0px',
    content: "''",
    height: '1px',
    left: '0',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  '&:has(img)': {
    paddingBottom: '11px',
    paddingTop: '11px',
  },
  '&:hover': {
    background: '#F5F8FD',
    '&::after': {
      backgroundColor: 'rgba(236, 236, 237, 1)',
    },
  },
  [`&.${ACTIVE_CLASS_NAME}`]: {
    fontWeight: 550,
  },
  [`&.${ACTIVE_CLASS_NAME}::after`]: {
    height: '2px',
    backgroundColor: accentColor || theme.palette.primary.main,
  },
}))

type NavigationLinkProps = RouterLinkProps & { accentColor?: string }

const NavigationLink: React.FC<NavigationLinkProps> = (props) => (
  <NavLink activeClassName={ACTIVE_CLASS_NAME} {...props} />
)

export default NavigationLink
