import { CheckOutlined } from '@material-ui/icons'
import { Checkbox } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { Chip } from '../../core'
import { styled } from '../../styles'

const TimelineBar = styled('div')<{ isPaid: boolean }>(({ isPaid }) => ({
  position: 'absolute',
  top: '16px',
  left: '0px',
  right: '0px',
  height: '4px',
  backgroundColor: isPaid ? '#4272DD' : '#ECECED',
  zIndex: 4,
}))
const StyledChip = styled(Chip)(() => ({
  backgroundColor: 'rgb(215, 233, 220)',
  color: 'rgb(43, 100, 46)',
}))
const AmountDiv = styled('div')(() => ({
  fontSize: '15px',
  marginBottom: '5px',
  color: '#212121',
}))
const MilestoneRow = styled('div')(() => ({
  textAlign: 'left',
  padding: '3px 0px',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}))
const StyledCheckbox = styled(Checkbox)(() => ({
  padding: '0px 5px 0px 0px',
  '&.Mui-checked': {
    color: '#1751D0',
  },
}))
const MilestoneTitle = styled('span')<{ isPaid: boolean; isChecked: boolean }>(({ isPaid, isChecked }) => ({
  fontWeight: 500,
  color: isChecked ? '#4272DD' : isPaid ? '#ADACB0' : '#212121',
}))
const MilestoneWrapper = styled('div')<{ isLast: boolean }>(({ isLast }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px 40px 0px 0px',
  paddingRight: isLast ? '0px' : '40px',
  position: 'relative',
  minWidth: '100px',
}))
const IconWrapper = styled('div')(() => ({
  zIndex: 5,
}))
const BlueCircle = styled('div')(() => ({
  borderRadius: '50px',
  backgroundColor: '#1751D0',
  color: '#FFF',
  height: '36px',
  width: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  marginTop: '-3px',
  border: '2px solid #618DF2',
  backgroundClip: 'content-box',
  padding: '2px',
  marginBottom: '5px',
}))
const BlueCheck = styled(CheckOutlined)(() => ({
  height: '16px',
  width: '16px',
  color: 'white',
  backgroundColor: '#1751D0',
  borderRadius: '50px',
  padding: '11px',
  marginBottom: '6px',
}))

type PropTypes = {
  onCheck: (payment_amount: number) => void
  isChecked: boolean
  isPaid: boolean
  isLast: boolean
  title: string
  amount: string
  paymentNumber: number
}

const LoanMilestoneCell: React.FC<PropTypes> = (props) => {
  const { onCheck, isChecked, isPaid, isLast, title, amount, paymentNumber } = props

  const renderIcon = useCallback(() => {
    if (isPaid) {
      return <BlueCheck />
    } else {
      return <BlueCircle>{paymentNumber}</BlueCircle>
    }
  }, [isPaid, paymentNumber])

  const showTimelineBar = useMemo(() => {
    return !isLast
  }, [isLast])

  const toggleCheckbox = () => {
    onCheck(paymentNumber)
  }

  return (
    <MilestoneWrapper isLast={isLast}>
      <IconWrapper>{renderIcon()}</IconWrapper>

      <MilestoneRow>
        {!isPaid && <StyledCheckbox checked={isChecked} onChange={(e) => toggleCheckbox()} />}
        <MilestoneTitle isPaid={isPaid} isChecked={isChecked}>
          {title}
        </MilestoneTitle>
      </MilestoneRow>

      <MilestoneRow>
        <AmountDiv>{amount}</AmountDiv>
      </MilestoneRow>
      <div>{isPaid && <StyledChip label="Paid" variant="default" size="small" />}</div>
      {showTimelineBar && <TimelineBar isPaid={isPaid} />}
    </MilestoneWrapper>
  )
}

export default LoanMilestoneCell
