import { Link } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import useTranslateParse from 'util/useTranslateParse'

const Wrapper = styled('div')({
  paddingTop: '8px',
})

const PrivacyLinks = ({ distributor, policyLink, conditionLink }) => {
  const translateParse = useTranslateParse()

  return (
    <Wrapper>
      {translateParse(
        `By placing your order, you agree to ${distributor}'s <a1>Privacy Policy<a1> and <a2>Conditions of Use<a2>`,
        {
          a1: (label) => (
            <Link href={policyLink} target="_blank">
              {label}
            </Link>
          ),
          a2: (label) => (
            <Link href={conditionLink} target="_blank">
              {label}
            </Link>
          ),
        }
      )}
    </Wrapper>
  )
}

export default PrivacyLinks
