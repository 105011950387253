import PropTypes from 'prop-types'
import { useTranslate } from 'ra-core'
import React from 'react'

const style = {
  fontSize: 20,
  display: 'inline-block',
  fontWeight: 500,
  margin: '10px 0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const Title = ({ defaultTitle, record, title, ...rest }) => {
  const translate = useTranslate()
  if (!title) {
    return <span style={style}>{defaultTitle}</span>
  }
  if (typeof title === 'string') {
    return <span style={style}>{translate(title, { _: title })}</span>
  }
  return React.cloneElement(title, { record, translate, style, ...rest })
}

export const TitlePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.element])

Title.propTypes = {
  defaultTitle: PropTypes.string,
  record: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default Title
