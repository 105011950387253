import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import ChartistGraph from 'react-chartist'
import { useSelector } from 'react-redux'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  totalPayable: number
  totalPaid: number
  widget?: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'row' : 'row'),
    justifyContent: 'center',
    width: '100%',
  },
  chartWrapper: {
    flex: 1,
  },
  textWrapper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '10px',
  },
  legendWrapper: {
    color: theme.greyDark2,
    margin: '5px',
    display: 'flex',
    alignItems: 'baseline',
    gap: 10,
  },
  labelWrapper: {
    display: 'flex',
    flexFlow: 'column',
    gap: 5,
  },
  label: {
    color: theme.greyMid1,
  },

  amountLabel: {
    fontSize: 16,
    fontWeight: 500,
  },
  paid: {
    fill: theme.alertIcon_success,
  },
  payable: {
    fill: theme.greyLight2,
  },
  paidColorLabel: {
    width: 10,
    height: 10,
    background: theme.alertIcon_success,
    borderRadius: '100%',
  },
  payableColorLabel: {
    width: 10,
    height: 10,
    background: theme.greyLight2,
    borderRadius: '100%',
  },
}))

const PaymentsPieChart: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const chartHeight = props.widget ? '75px' : '100px'
  const chartWidth = props.widget ? '75px' : '100px'

  const startAngle = useMemo(() => {
    let percentPaid = props.totalPaid / props.totalPayable
    return percentPaid * 360
  }, [props.totalPaid, props.totalPayable])

  return (
    <div className={classes.wrapper}>
      <div className={classes.chartWrapper}>
        <ChartistGraph
          data={{
            labels: ['Payable', 'Paid'],
            series: [
              { value: props.totalPayable - (props.totalPaid || 0), className: classes.payable },
              { value: props.totalPaid, className: classes.paid },
            ],
          }}
          //@ts-ignore
          options={{
            //@ts-ignore
            showLabel: false,
            startAngle: startAngle,
            height: chartHeight,
            width: chartWidth,
            donut: true,
            donutSolid: true,
            donutWidth: 12,
          }}
          type={'Pie'}
        />
      </div>
      <div className={classes.textWrapper}>
        <div className={classes.legendWrapper}>
          <div className={classes.paidColorLabel}> </div>
          <div className={classes.labelWrapper}>
            <span className={classes.label}>{translate('Collected')}</span>
            <span className={classes.amountLabel} data-testid="total-collected-amount">
              {formatCurrencyWithSymbol(props.totalPaid, currencySymbol, undefined, 0)}
            </span>
          </div>
        </div>

        <div className={classes.legendWrapper}>
          <div className={classes.payableColorLabel}> </div>
          <div className={classes.labelWrapper}>
            <span className={classes.label}>{translate('Job Value')}</span>
            <span className={classes.amountLabel} data-testid="total-job-value">
              {formatCurrencyWithSymbol(props.totalPayable, currencySymbol, undefined, 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentsPieChart
