import { authSelectors } from 'ducks/auth'
import { FORM_ERROR } from 'final-form'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Dialog } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { ActionType } from 'types/tracking'
import { reloadEntireApp } from 'util/misc'
import SolarAppCreateProjectForm from '../SolarAppForms/SolarAppCreateProjectForm'
import { SolarAppErrorResponsePayload, SolarAppProjectSubmittedResponsePayload } from '../types'
import { getErrorMessage, getValidationErrors, SOLAR_APP_ERROR_CODES } from '../utils'

type Props = {
  open: boolean
  onClose: () => void
  project: ProjectType
  updateSolarAppProjectDetails?: (newId?: string, newUrl?: string) => void
}

const SolarAppCreateProjectDialog: React.FunctionComponent<Props> = ({
  project,
  updateSolarAppProjectDetails,
  open,
  onClose,
}) => {
  const notify = useNotify()
  const [loading, setLoading] = useState<boolean>(false)
  const userId = useSelector(authSelectors.getCurrentUserId)

  const { trackSystemEvent, trackErrorEvent } = useTrackComponent({
    componentKey: 'solar_app_create_project_dialog',
    eventName: 'User Opened Create Solar App Project Dialog',
  })

  const initialFormValues = {
    title: project.title,
    product_type: '',
    street_number: '',
    street_name: '',
    street_name_2: '',
    city: project.locality,
    state: project.state,
    zip: project.zip,
  }

  const handleSubmit = (formValues: any) => {
    return new Promise((resolve, reject) => {
      setLoading(true)

      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${project.org_id}/${project.id}/solarapp_create_project/`,
        data: formValues,
      })
        .then((response: any) => {
          const { uuid, url } = response.data as SolarAppProjectSubmittedResponsePayload
          notify('Successfully created SolarAPP+ project', 'success')
          trackSystemEvent(
            { type: ActionType.SAVE },
            {
              eventName: 'SolarAPP Project Created',
              metadata: {
                project: {
                  uuid,
                  type: response.data.product_type,
                },
                userId,
              },
            }
          )
          if (updateSolarAppProjectDetails) {
            updateSolarAppProjectDetails(uuid, url)
          } else {
            reloadEntireApp()
          }
          onClose()
        })
        .catch((error: any) => {
          const errorResponse = error?.body as SolarAppErrorResponsePayload

          const globalFormError = getErrorMessage(errorResponse)
          let fieldErrors = getValidationErrors(errorResponse)

          if (errorResponse) {
            if (errorResponse?.error_code) {
              console.error('SolarAPP+ error_code:', errorResponse.error_code)

              if (errorResponse.error_code === SOLAR_APP_ERROR_CODES.AHJ_CONFIG_ERROR.code) {
                // Highlight that the product_type field needs changing
                fieldErrors = {
                  ...fieldErrors,
                  product_type: SOLAR_APP_ERROR_CODES.AHJ_CONFIG_ERROR.details,
                }
              }
              trackErrorEvent(
                { type: ActionType.SAVE },
                {
                  eventName: 'SolarAPP Validation Error Viewed',
                  project: {
                    type: formValues.product_type,
                  },
                  errorCode: errorResponse.error_code,
                  userId,
                }
              )
            } else {
              console.error(error)
            }
          } else {
            console.error('Network or unknown error:', error)
          }
          resolve({
            ...fieldErrors,
            [FORM_ERROR]: globalFormError,
          })
        })
        .finally(() => {
          setLoading(false)
          resolve(true)
        })
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        render={(formProps) => <SolarAppCreateProjectForm {...formProps} isLoading={loading} onClose={onClose} />}
      />
    </Dialog>
  )
}

export default SolarAppCreateProjectDialog
