import { SnackbarContent, withStyles } from '@material-ui/core'
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar'
import { CloudDownload } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Clear'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { Button } from 'opensolar-ui'
import { ButtonInfo, useTranslate } from 'ra-core'
import React from 'react'

interface Props {
  id: string
  type: 'success' | 'warning' | 'error' | 'info'
  isInteractive: boolean
  buttons?: ButtonInfo[]
  handleRequestClose(): void
  detail?: string
  messageArgs?: object
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}
const styles = {
  success: {
    backgroundColor: 'rgba(66, 195, 17, 0.95)',
    borderRadius: 5,
    padding: '6px 12px',
  },
  error: {
    backgroundColor: 'rgba(193, 42, 46, 0.95)',
    borderRadius: 5,
    padding: '6px 12px',
  },
  info: {
    backgroundColor: 'rgba(35, 87, 137, 0.95)',
    borderRadius: 5,
    padding: '6px 12px',
  },
  warning: {
    backgroundColor: 'rgba(255,160,0,0.95)',
    borderRadius: 5,
    padding: '6px 12px',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: 10,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

const StyledSnackbarContent = withStyles({
  message: {
    width: '100%',
  },
})(SnackbarContent)

const PureNotification: React.FunctionComponent<Props & Omit<SnackbarProps, 'type'>> = (props) => {
  const { open, type, message, messageArgs, detail, buttons, id, isInteractive, handleRequestClose, ...rest } = props
  const translate = useTranslate()
  const Icon = variantIcon[type]

  const buttonClick = (b: ButtonInfo) => {
    b.action()
    if (!b.avoidClose) handleRequestClose()
  }

  const getIcon = (icon: any) => {
    switch (icon) {
      case 'CloudDownload':
        return <CloudDownload />
      default:
        return icon
    }
  }

  const onCloseButtonClicked = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()
    handleRequestClose()
  }

  const messageTrans = typeof message === 'string' ? translate(message, messageArgs) : message
  let detailTrans = detail ? translate(detail) : undefined

  return (
    <Snackbar
      id={id}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ zIndex: 2600 }}
      {...rest}
    >
      <StyledSnackbarContent
        aria-describedby="client-snackbar"
        style={{ ...styles[type], display: !!message ? 'flex' : 'none' }}
        onClick={isInteractive ? undefined : handleRequestClose}
        message={
          <span id="client-snackbar" style={styles.message}>
            {!!Icon && <Icon style={styles.icon} />}
            {detailTrans ? (
              <details>
                <summary>{messageTrans}</summary>
                {detailTrans}
              </details>
            ) : (
              messageTrans
            )}
            {
              <>
                {buttons &&
                  buttons.map((button: ButtonInfo) => {
                    return (
                      <Button
                        style={{ marginLeft: '16px', marginRight: '10px' }}
                        size="small"
                        onClick={() => {
                          buttonClick(button)
                        }}
                        variant={button.variant || 'contained'}
                        startIcon={getIcon(button.icon)}
                      >
                        {button.label}
                      </Button>
                    )
                  })}
              </>
            }
            <CloseIcon style={{ fontSize: 20, cursor: 'pointer', marginLeft: 'auto' }} onClick={onCloseButtonClicked} />
          </span>
        }
      />
    </Snackbar>
  )
}

export default PureNotification
