import { styled } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Box, Checkbox, FormControlLabel, Tooltip } from 'opensolar-ui'
import { OpenSolarTheme } from 'Themes'
import { Recipient } from './EmailRecipientsInput'

const RecipientsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}))

interface RecipientsCheckboxesProps {
  recipients: Recipient[]
  toggleRecipient: (recipient: Recipient) => void
}

const RecipientsCheckboxes: React.FC<RecipientsCheckboxesProps> = ({ recipients, toggleRecipient }) => {
  if (!Array.isArray(recipients) || recipients.length === 0) {
    return null
  }

  return (
    <RecipientsContainer>
      {recipients.map((recipient) => {
        const disabled = !recipient.email && !recipient.user_email
        return disabled ? (
          <Tooltip title="Add email address to send to contact" placement="top">
            <FormControlLabel
              key={recipient.url}
              control={<Checkbox color={'secondary'} disabled={true} />}
              label={recipient.display}
            />
          </Tooltip>
        ) : (
          <FormControlLabel
            key={recipient.url}
            control={
              <Checkbox
                color={'default'}
                checked={recipient.checked && !disabled}
                disabled={disabled}
                onChange={() => toggleRecipient(recipient)}
                checkedIcon={<CheckBoxIcon style={{ color: OpenSolarTheme.blue4 }} />}
              />
            }
            label={recipient.display}
          />
        )
      })}
    </RecipientsContainer>
  )
}

export default RecipientsCheckboxes
