import { Paper } from '@material-ui/core'
import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'constants/locales'
import { authSelectors } from 'ducks/auth'
import ChipsInput from 'elements/input/ChipsInput'
import { Link, styled } from 'opensolar-ui'
import { PRODUCT_FILTERS } from 'pages/expo/pages/homepage/filtering/util'
import { useMemo } from 'react'
import { BooleanInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { getLanguages } from 'resources/documentTemplates/util'
import { useResourceStyles } from 'resources/styles'
import { required } from 'validations'
import InputAccordion from './common/InputAccordion'
import ContentFields from './ContentFields'
import { formatSubmitValues, validateExpoForm } from './form'
import PageContentField from './PageContentField'
import PreviewTemplateButton from './PreviewTemplateButton'

const InputWrapper = styled(Paper, { name: 'InputWrapper' })({
  display: 'flex',
  flexDirection: 'column',
})
const DEFAULT_TAG_OPTIONS = PRODUCT_FILTERS.map((tag) => ({ id: tag, name: tag }))
const ExpoStandForm = (props) => {
  const translate = useTranslate()
  const resourceStyles = useResourceStyles()
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const languages = getLanguages(LOCALES_SUPPORTED_CUSTOMER_FACING)
  const disabled = useMemo(() => !isAdmin || props.disabled, [props.disabled, isAdmin])

  return (
    <SimpleForm {...props} formatSubmitValues={formatSubmitValues} validate={validateExpoForm}>
      <PreviewTemplateButton />
      <InputWrapper elevation={1} className={resourceStyles.formSection}>
        <TextInput label={'Title'} disabled={disabled} name="title" source="title" validate={required} />
        <TextInput label={'Description'} disabled={disabled} name="description" source="description" multiline={true} />
        <SelectInput
          choices={languages}
          label={'Language'}
          disabled={disabled}
          fullWidth={true}
          name="locale"
          source="locale"
        />
        <ChipsInput source={'tags'} choices={DEFAULT_TAG_OPTIONS} allChoices={DEFAULT_TAG_OPTIONS} label={'Tags'} />
      </InputWrapper>
      <InputAccordion title={translate('Restricted Access (OpenSolar Staff Only)')}>
        <TextInput
          label={'Countries Included'}
          disabled={disabled || !isStaff}
          fullWidth={true}
          name="countries_include"
          source="countries_include"
        />
        <BooleanInput
          label={'Is Published'}
          disabled={disabled || !isStaff}
          source="is_published"
          name="is_published"
        />
        <ReferenceInput
          name="brand"
          resource="brands"
          source="brand"
          reference="brands"
          allowEmpty={true}
          fullWidth={true}
          style={{ width: '100%' }}
          renderChildrenBeforeRecord={true}
          label="Brand"
        >
          <SelectInput
            source={'url'}
            optionText="name"
            optionValue="url"
            disabled={disabled || !isStaff}
            helperText={isStaff ? <Link href="#/brands">Configure Brands</Link> : undefined}
          />
        </ReferenceInput>
      </InputAccordion>
      <InputAccordion title={translate('Expo Stand Settings')} defaultExpanded={true}>
        <PageContentField />
      </InputAccordion>
      <ContentFields {...props} disabled={disabled} isStaff={isStaff} />
    </SimpleForm>
  )
}

export default ExpoStandForm
