import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { UiSwitch } from 'elements/UiSwitch'
import { ComponentVersionsInherit } from 'opensolar-ui'
import ToastNotification from './ToastNotification'
import { useToastNotificationLogic } from './useToastNotificationLogic'

export const ToastNotificationManager = () => {
  const { open, onCloseComplete, currentNotif, onInteract, currentNotifKey } = useToastNotificationLogic()
  if (!currentNotif) return null
  return (
    <UiSwitch uiKey="toast_notification">
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        <ToastNotification
          open={open}
          onClose={onCloseComplete}
          toastNotif={currentNotif.data}
          onInteract={onInteract}
          trackingKey={currentNotifKey}
        />
      </ComponentVersionsInherit>
    </UiSwitch>
  )
}
