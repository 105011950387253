import { detectImagery } from 'actions/designer'
import { useDispatch } from 'react-redux'
import { MapDataTypes } from 'types/map'
import { Location4326 } from 'types/studio/structs'

export const useDetectImageryForProject = () => {
  const dispatch = useDispatch()
  return function (overrideLocation4326?: Location4326) {
    return new Promise<[MapDataTypes[], number]>((resolve, reject) => {
      let sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()

      //TODO: should we reject if country is not available?
      let country_iso2 = window.WorkspaceHelper.project?.country_iso2 || ''
      let state = window.WorkspaceHelper.project?.state || ''
      let address = window.WorkspaceHelper.project?.address || ''

      let premiumImgResponse = window.AccountHelper.getIsPremiumImageryAvailable(sceneOrigin4326, country_iso2, state)
      let premiumImgIsAvailable = !!premiumImgResponse.isAvailable
      let preloadTerrainProvider = null

      dispatch(
        detectImagery(
          overrideLocation4326 ? overrideLocation4326 : sceneOrigin4326,
          country_iso2,
          state,
          address,
          premiumImgIsAvailable,
          preloadTerrainProvider,
          window.WorkspaceHelper.project?.is_lite,
          (res, e) => {
            if (res) resolve([res.availableMapTypes, res.timezoneOffset])
            else reject(e)
          },
          false
        )
      )
    })
  }
}
