import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { BooleanInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStyles } from '../styles'
const PaymentAutoApplyInputs = () => {
  const translate = useTranslate()
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  const classes = useStyles()

  return (
    <div>
      <div className={classes.marginleft} style={{ marginBottom: '22px', marginTop: '22px' }}>
        <CustomField
          component={BooleanInput}
          name={'is_default'}
          label="Default for org"
          source="is_default"
          defaultValue={false}
          disabled={!isAdmin}
        />
      </div>
      <span className={classes.marginleft}>
        <span>{translate('Override default for specific payment types')} </span>
      </span>
      <div className={classes.container}>
        <CustomField
          component={CheckboxInput}
          name={'is_default_loan'}
          label="Loan"
          source="is_default_loan"
          defaultValue={false}
          disabled={!isAdmin}
        />
        <CustomField
          component={CheckboxInput}
          name={'is_default_ppa'}
          label="PPA"
          source="is_default_ppa"
          defaultValue={false}
          disabled={!isAdmin}
        />
        <CustomField
          component={CheckboxInput}
          name={'is_default_cash'}
          label="Cash"
          source="is_default_cash"
          defaultValue={false}
          disabled={!isAdmin}
        />
        <CustomField
          component={CheckboxInput}
          name={'is_default_regular_payment'}
          label="Regular Payment"
          source="is_default_regular_payment"
          defaultValue={false}
          disabled={!isAdmin}
        />
        <CustomField
          component={CheckboxInput}
          name={'is_default_lease'}
          label="Lease"
          source="is_default_lease"
          defaultValue={false}
          disabled={!isAdmin}
        />
      </div>
    </div>
  )
}

export default PaymentAutoApplyInputs
