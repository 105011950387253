import { FC } from 'react'

/**
 * * Icon Component for: filter
 */
type FilterIconProps = {
  size?: number
  color?: string
}
export const FilterIcon: FC<FilterIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M3.46154 6H14.5385M1 1H17M7.15385 11H10.8462"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
