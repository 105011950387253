import { FC } from 'react'

/**
 * * Icon Component for: notification-box
 */
type NotificationBoxOutlineIconProps = {
  size?: number
  color?: string
}
export const NotificationBoxOutline: FC<NotificationBoxOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M8.875 1H4.375C2.51104 1 1 2.51103 1 4.37498V15.625C1 17.489 2.51104 19 4.375 19H15.625C17.489 19 19 17.489 19 15.625V10.5624M19 3.81248C19 5.36578 17.7408 6.62497 16.1875 6.62497C14.6342 6.62497 13.375 5.36578 13.375 3.81248C13.375 2.25919 14.6342 1 16.1875 1C17.7408 1 19 2.25919 19 3.81248Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
