import IconButton from '@material-ui/core/IconButton'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { useTimeout } from 'ra-core'
import { memo } from 'react'
import Placeholder from './Placeholder'

const times = (nbChildren, fn) => Array.from({ length: nbChildren }, (_, key) => fn(key))

const DatagridLoading = ({ classes = {}, className, expand, hasBulkActions, nbChildren, nbFakeLines = 5, size }) => {
  // alway show list loading animation
  const SHOW_LOADING_ANIMATION = true
  const oneSecondHasPassed = useTimeout(1000) || SHOW_LOADING_ANIMATION
  return oneSecondHasPassed ? (
    <Table className={classnames(classes.table, className)} size={size}>
      <TableHead>
        <TableRow className={classes.row}>
          {expand && <TableCell padding="none" className={classes.expandHeader} />}
          {hasBulkActions && (
            <TableCell padding="checkbox" className={classes.expandIconCell}>
              <Checkbox
                className="select-all"
                color="primary"
                checked={false}
                checkedIcon={<CheckBoxIcon style={{ color: '#4272DD' }} />}
              />
            </TableCell>
          )}
          {times(nbChildren, (key) => (
            <TableCell variant="head" className={classes.headerCell} key={key}>
              <Placeholder />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {times(nbFakeLines, (key1) => (
          <TableRow key={key1} style={{ opacity: 1 / (key1 + 1) }}>
            {expand && (
              <TableCell padding="none" className={classes.expandIconCell}>
                <IconButton className={classes.expandIcon} component="div" aria-hidden="true">
                  <ExpandMoreIcon />
                </IconButton>
              </TableCell>
            )}
            {hasBulkActions && (
              <TableCell padding="checkbox" className={classes.expandIconCell}>
                <Checkbox
                  className="select-all"
                  color="primary"
                  checked={false}
                  checkedIcon={<CheckBoxIcon style={{ color: '#4272DD' }} />}
                />
              </TableCell>
            )}
            {times(nbChildren, (key2) => (
              <TableCell className={classes.rowCell} key={key2}>
                <Placeholder />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : null
}

DatagridLoading.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool,
  nbChildren: PropTypes.number,
  nbFakeLines: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
}

export default memo(DatagridLoading)
