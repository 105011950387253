import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEditController } from 'ra-core'
import React, { Children, cloneElement } from 'react'
import Subtitle from '../layout/Subtitle'
import Title from '../layout/Title'
import DefaultBreadCrumbs from './BreadCrumbs'
import DefaultActions from './EditActions'

/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes pass the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 * - undoable
 *
 * @example
 *
 * // in src/posts.js
 * import React from 'react';
 * import { Edit, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * // in src/App.js
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostEdit } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" edit={PostEdit} />
 *     </Admin>
 * );
 * export default App;
 */
const getBasePath = (location) => location.pathname.split('/').slice(0, -1).join('/')

const Edit = ({ basePath = undefined, id = undefined, hasDelete = true, undoable = undefined, ...props }) => {
  // set basePath and id automatically
  // disable undoable behavior
  const actionsUndoable = false
  const newProps = {
    ...props,
    basePath: basePath || getBasePath(props.location),
    id: id || decodeURIComponent(props.match.params.id),
    undoable: undoable || actionsUndoable,
    hasDelete,
  }
  return <EditView {...newProps} {...useEditController(newProps)} />
}

Edit.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any,
  resource: PropTypes.string.isRequired,
  title: PropTypes.node,
  successMessage: PropTypes.string,
}

export const EditView = (props) => {
  const {
    actions,
    aside,
    basePath,
    children,
    classes: classesOverride,
    className,
    component: Content,
    defaultTitle,
    hasList,
    hasDelete,
    hasShow,
    record,
    redirect,
    resource,
    save,
    saving,
    title,
    breadCrumbs,
    hideBreadCrumbs,
    hideTitle,
    options,
    subtitle,
    undoable,
    version,
    renderChildrenBeforeRecord,
    ...rest
  } = props
  const classes = useStyles(props)
  const finalActions = typeof actions === 'undefined' && hasList ? <DefaultActions /> : actions
  const finalTitle = title || (options.edit && options.edit.title)
  const finalSubtitle = subtitle || (options.edit && options.edit.subtitle)

  if (!children) {
    return null
  }
  return (
    <div className={classnames('edit-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      {!hideBreadCrumbs &&
        resource &&
        React.cloneElement(breadCrumbs, {
          options,
          resource,
          hasList,
          detail: 'edit',
        })}
      <div
        className={classnames(classes.main, {
          [classes.noActions]: !finalActions,
        })}
      >
        <Content className={classes.card}>
          {!hideTitle && (
            <div style={{ padding: '0px 16px 0 16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: 68,
                  alignItems: 'center',
                }}
              >
                <Title title={finalTitle} record={record} defaultTitle={defaultTitle} />
                {finalActions &&
                  cloneElement(finalActions, {
                    basePath,
                    data: record,
                    hasShow,
                    hasList,
                    hasDelete,
                    resource,
                    //  Ensure we don't override any user provided props
                    ...finalActions.props,
                  })}
              </div>
              {finalSubtitle && <Subtitle subtitle={finalSubtitle} />}
            </div>
          )}

          {record || renderChildrenBeforeRecord ? (
            cloneElement(Children.only(children), {
              basePath,
              record,
              redirect: typeof children.props.redirect === 'undefined' ? redirect : children.props.redirect,
              resource,
              save,
              saving,
              undoable,
              version,
            })
          ) : (
            <CardContent>&nbsp;</CardContent>
          )}
        </Content>
        {aside &&
          React.cloneElement(aside, {
            basePath,
            record,
            resource,
            version,
            save,
            saving,
          })}
      </div>
    </div>
  )
}

EditView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  // component: PropTypes.oneOfType([ComponentPropType, UndefinedPropType]),
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
  version: PropTypes.number,
  renderChildrenBeforeRecord: PropTypes.bool,
}

EditView.defaultProps = {
  classes: {},
  options: {},
  component: Card,
  breadCrumbs: <DefaultBreadCrumbs />,
  renderChildrenBeforeRecord: false,
}

const useStyles = makeStyles(
  {
    root: {},
    main: {
      display: 'flex',
    },
    noActions: {
      // marginTop: '12px',
    },
    card: {
      marginTop: '12px',
      flex: '1 1 auto',
    },
  },
  { name: 'RaEdit' }
)

const sanitizeRestProps = ({
  data,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  id,
  loading,
  loaded,
  saving,
  resource,
  title,
  version,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  undoable,
  successMessage,
  translate,
  accessRights,
  ...rest
}) => rest

export default Edit
