import { styled, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'
import { FinanceCtaType } from './constants'

const DisclaimerWrapper = styled('div')(({ theme }) => {
  return {
    marginTop: '20px',
    fontSize: '10px',
    fontWeight: 500,
    color: '#4F4F4F',
  }
})

const GenericIntegratedFinanceApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const extra_actions: React.ReactNode[] = []
  if (actionData.status_description) {
    extra_actions.push(
      <p className="small" style={{ textAlign: 'center' }} key={extra_actions.length}>
        {actionData.status_description}
      </p>
    )
  }

  if (options?.isPro && !actionData.is_pro_facing) return null
  else if (!options?.isPro && !actionData.is_customer_facing) return null

  return (
    <>
      <div>
        <TransactionActionButton
          isLoading={isLoading}
          label={
            <>
              {actionData.status_title}
              {actionData?.status_title_secondary && (
                <div style={{ fontSize: 11 }}>{actionData?.status_title_secondary}</div>
              )}
            </>
          }
          disabled={
            actionData.status_code !== 'available' ||
            !!options?.loanDecisionRenderedAt ||
            !!options?.transactionActionButtonDisabled
          }
          onClick={openApplicationDialog}
        />
        {extra_actions}
      </div>
      {actionData.cta_type === FinanceCtaType.DISCLAIMER_BEFORE_REDIRECT &&
        options?.ctaDialogConfigJson?.disclaimer_text && (
          <DisclaimerWrapper>{options.ctaDialogConfigJson.disclaimer_text}</DisclaimerWrapper>
        )}
    </>
  )
}

export default GenericIntegratedFinanceApplication
