import { ApiRequest } from '.'

export class Authorization {
  private auth?: string

  set(value?: string) {
    this.auth = value
  }
  clear() {
    this.auth = undefined
  }

  intercept(request: ApiRequest): ApiRequest {
    if (this.auth) {
      request.headers = request.headers || {}
      request.headers.Authorization = 'Bearer ' + this.auth
    }
    return request
  }
}
