import { stringify } from 'query-string'
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  resolveBrowserLocale,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin'
import { addAsset, getFileUploads, processFiles } from 'resources/expoStands/form'
import appStorage from 'storage/appStorage'
import { fetchJson } from './util/fetch'
import { apiVersionMismatchType, parseQueryStringToDictionary } from './util/misc'

// ResourceType defines the type of "resource' that the restClient understands. Most of the time, it is
// just a string but there seem to be cases where it is a more complex type.
type ResourceType = string | { resource: string; org_id: any; id: any }

let endpointsWhereOrgIsAllowedInPayload = ['landing_pages']

function urlsToIds(urls) {
  return urls.map(function (url) {
    return urlToId(url)
  })
}

// Disabled because other systems will fail if id is not passed
// These URLs still work fine with /orgs/{org_id}/content/{org_id}
// const resources_sub_route_for_org = ['content']
const resources_sub_route_for_org: string[] = []

function urlToId(url) {
  let id
  if (!url) {
    throw Error('url is invalid')
  } else if (Number.isInteger(parseInt(url, 10))) {
    id = url
  } else {
    id = url.split('/').slice(-2, -1)[0]
  }
  return id
}

/**
 * Convert a `File` object returned by the upload input into
 * a base 64 string. That's easier to use on FakeRest, used on
 * the ng-admin example. But that's probably not the most optimized
 * way to do in a production database.
 */
export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = (requestHandler) => (type: string, resource: ResourceType, params: any) => {
  if (type === 'UPDATE' && resource === 'content') {
    if (
      params.data.logo_file_contents &&
      params.data.logo_file_contents.constructor === Array &&
      params.data.logo_file_contents &&
      params.data.logo_file_contents.length
    ) {
      // only freshly dropped pictures are instance of File
      const formerPicturesLogo =
        params.data.logo_file_contents && params.data.logo_file_contents.constructor === String
          ? [params.data.logo_file_contents]
          : []

      const newPicturesLogo =
        params.data.logo_file_contents && params.data.logo_file_contents.constructor === Array
          ? params.data.logo_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const newPictures: any[] = []
      newPicturesLogo.forEach((p) => {
        p.field = 'logo_file_contents'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        logo_file_contents: null,
      }

      let counter = 0

      if (newPicturesLogo.length > 0) {
        pictureToArrayPosition.logo_file_contents = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${params.data.title || newPictures[index].title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              logo_file_contents: [
                ...(pictureToArrayPosition.logo_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.logo_file_contents]]
                  : []),
                ...formerPicturesLogo,
              ][0],
            },
          })
        )
    }
  }

  if (type === 'UPDATE' && resource === 'orgs') {
    if (
      params.data.exhibit_data_sharing_agreement_file_contents &&
      params.data.exhibit_data_sharing_agreement_file_contents.constructor === Array &&
      params.data.exhibit_data_sharing_agreement_file_contents &&
      params.data.exhibit_data_sharing_agreement_file_contents.length
    ) {
      // only freshly dropped pictures are instance of File
      const formerPicturesLogo =
        params.data.exhibit_data_sharing_agreement_file_contents &&
        params.data.exhibit_data_sharing_agreement_file_contents.constructor === String
          ? [params.data.exhibit_data_sharing_agreement_file_contents]
          : []

      const newPicturesLogo =
        params.data.exhibit_data_sharing_agreement_file_contents &&
        params.data.exhibit_data_sharing_agreement_file_contents.constructor === Array
          ? params.data.exhibit_data_sharing_agreement_file_contents.filter(
              (p) => p && p.rawFile && p.rawFile instanceof File
            )
          : []

      const newPictures: any[] = []
      newPicturesLogo.forEach((p) => {
        p.field = 'exhibit_data_sharing_agreement_file_contents'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        exhibit_data_sharing_agreement_file_contents: null,
      }

      let counter = 0

      if (newPicturesLogo.length > 0) {
        pictureToArrayPosition.exhibit_data_sharing_agreement_file_contents = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${newPictures[index].title || params.data.title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              exhibit_data_sharing_agreement_file_contents: [
                ...(pictureToArrayPosition.exhibit_data_sharing_agreement_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.exhibit_data_sharing_agreement_file_contents]]
                  : []),
                ...formerPicturesLogo,
              ][0],
            },
          })
        )
    }
  }

  if ((type === 'UPDATE' || type === 'CREATE') && resource === 'proposal_templates') {
    if (
      (params.data.proposal_image_file_contents &&
        params.data.proposal_image_file_contents.constructor === Array &&
        params.data.proposal_image_file_contents &&
        params.data.proposal_image_file_contents.length) ||
      (params.data.co_branding_logo_file_contents &&
        params.data.co_branding_logo_file_contents.constructor === Array &&
        params.data.co_branding_logo_file_contents &&
        params.data.co_branding_logo_file_contents.length) ||
      (params.data.bespoke_template_file_contents &&
        params.data.bespoke_template_file_contents.constructor === Array &&
        params.data.bespoke_template_file_contents &&
        params.data.bespoke_template_file_contents.length) ||
      (params.data.override_logo_file_contents &&
        params.data.override_logo_file_contents.constructor === Array &&
        params.data.override_logo_file_contents &&
        params.data.override_logo_file_contents.length)
    ) {
      // only freshly dropped pictures are instance of File
      const formerPicturesProposalImage =
        params.data.proposal_image_file_contents && params.data.proposal_image_file_contents.constructor === Array
          ? params.data.proposal_image_file_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newPicturesProposalImage =
        params.data.proposal_image_file_contents && params.data.proposal_image_file_contents.constructor === Array
          ? params.data.proposal_image_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerPicturesCoBrandingLogo =
        params.data.co_branding_logo_file_contents && params.data.co_branding_logo_file_contents.constructor === Array
          ? params.data.co_branding_logo_file_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newPicturesCoBrandingLogo =
        params.data.co_branding_logo_file_contents && params.data.co_branding_logo_file_contents.constructor === Array
          ? params.data.co_branding_logo_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerBespokeTemplateFile =
        params.data.bespoke_template_file_contents && params.data.bespoke_template_file_contents.constructor === Array
          ? params.data.bespoke_template_file_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newBespokeTemplateFile =
        params.data.bespoke_template_file_contents && params.data.bespoke_template_file_contents.constructor === Array
          ? params.data.bespoke_template_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerOverrideLogoFile =
        params.data.override_logo_file_contents && params.data.override_logo_file_contents.constructor === Array
          ? params.data.override_logo_file_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newOverrideLogoFile =
        params.data.override_logo_file_contents && params.data.override_logo_file_contents.constructor === Array
          ? params.data.override_logo_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const newPictures: { title: string; field: string }[] = []
      newPicturesProposalImage.forEach((p) => {
        p.field = 'proposal_image_file_contents'
        newPictures.push(p)
      })
      newPicturesCoBrandingLogo.forEach((p) => {
        p.field = 'co_branding_logo_file_contents'
        newPictures.push(p)
      })
      newBespokeTemplateFile.forEach((p) => {
        p.field = 'bespoke_template_file_contents'
        newPictures.push(p)
      })
      newOverrideLogoFile.forEach((p) => {
        p.field = 'override_logo_file_contents'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        proposal_image_file_contents: null,
        co_branding_logo_file_contents: null,
        bespoke_template_file_contents: null,
        override_logo_file_contents: null,
      }

      let counter = 0

      if (newPicturesProposalImage.length > 0) {
        pictureToArrayPosition.proposal_image_file_contents = counter
        counter++
      }
      if (newPicturesCoBrandingLogo.length > 0) {
        pictureToArrayPosition.co_branding_logo_file_contents = counter
        counter++
      }
      if (newBespokeTemplateFile.length > 0) {
        pictureToArrayPosition.bespoke_template_file_contents = counter
        counter++
      }
      if (newOverrideLogoFile.length > 0) {
        pictureToArrayPosition.override_logo_file_contents = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${params.data.title || newPictures[index].title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              proposal_image_file_contents: [
                ...(pictureToArrayPosition.proposal_image_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.proposal_image_file_contents]]
                  : []),
                ...formerPicturesProposalImage,
              ][0],
              co_branding_logo_file_contents: [
                ...(pictureToArrayPosition.co_branding_logo_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.co_branding_logo_file_contents]]
                  : []),
                ...formerPicturesCoBrandingLogo,
              ][0],
              bespoke_template_file_contents: [
                ...(pictureToArrayPosition.bespoke_template_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.bespoke_template_file_contents]]
                  : []),
                ...formerBespokeTemplateFile,
              ][0],
              override_logo_file_contents: [
                ...(pictureToArrayPosition.override_logo_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.override_logo_file_contents]]
                  : []),
                ...formerOverrideLogoFile,
              ][0],
            },
          })
        )
    }
  }

  if ((type === 'UPDATE' || type === 'CREATE') && resource === 'finance_partners') {
    if (
      (params.data.logo_large_contents &&
        params.data.logo_large_contents.constructor === Array &&
        params.data.logo_large_contents &&
        params.data.logo_large_contents.length) ||
      (params.data.logo_small_contents &&
        params.data.logo_small_contents.constructor === Array &&
        params.data.logo_small_contents &&
        params.data.logo_small_contents.length) ||
      (params.data.bespoke_template_file_contents &&
        params.data.bespoke_template_file_contents.constructor === Array &&
        params.data.bespoke_template_file_contents &&
        params.data.bespoke_template_file_contents.length) ||
      (params.data.banner_image_contents &&
        params.data.banner_image_contents.constructor === Array &&
        params.data.banner_image_contents &&
        params.data.banner_image_contents.length)
    ) {
      // only freshly dropped pictures are instance of File
      const formerPicturesProposalImage =
        params.data.logo_large_contents && params.data.logo_large_contents.constructor === Array
          ? params.data.logo_large_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newPicturesProposalImage =
        params.data.logo_large_contents && params.data.logo_large_contents.constructor === Array
          ? params.data.logo_large_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerPicturesCoBrandingLogo =
        params.data.logo_small_contents && params.data.logo_small_contents.constructor === Array
          ? params.data.logo_small_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newPicturesCoBrandingLogo =
        params.data.logo_small_contents && params.data.logo_small_contents.constructor === Array
          ? params.data.logo_small_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerBespokeTemplateFile =
        params.data.bespoke_template_file_contents && params.data.bespoke_template_file_contents.constructor === Array
          ? params.data.bespoke_template_file_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newBespokeTemplateFile =
        params.data.bespoke_template_file_contents && params.data.bespoke_template_file_contents.constructor === Array
          ? params.data.bespoke_template_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerOverrideLogoFile =
        params.data.banner_image_contents && params.data.banner_image_contents.constructor === Array
          ? params.data.banner_image_contents.filter((p) => p && p.rawFile && !(p.rawFile instanceof File))
          : []

      const newOverrideLogoFile =
        params.data.banner_image_contents && params.data.banner_image_contents.constructor === Array
          ? params.data.banner_image_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const newPictures: { title: string; field: string }[] = []
      newPicturesProposalImage.forEach((p) => {
        p.field = 'logo_large_contents'
        newPictures.push(p)
      })
      newPicturesCoBrandingLogo.forEach((p) => {
        p.field = 'logo_small_contents'
        newPictures.push(p)
      })
      newBespokeTemplateFile.forEach((p) => {
        p.field = 'bespoke_template_file_contents'
        newPictures.push(p)
      })
      newOverrideLogoFile.forEach((p) => {
        p.field = 'banner_image_contents'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        logo_large_contents: null,
        logo_small_contents: null,
        bespoke_template_file_contents: null,
        banner_image_contents: null,
      }

      let counter = 0

      if (newPicturesProposalImage.length > 0) {
        pictureToArrayPosition.logo_large_contents = counter
        counter++
      }
      if (newPicturesCoBrandingLogo.length > 0) {
        pictureToArrayPosition.logo_small_contents = counter
        counter++
      }
      if (newBespokeTemplateFile.length > 0) {
        pictureToArrayPosition.bespoke_template_file_contents = counter
        counter++
      }
      if (newOverrideLogoFile.length > 0) {
        pictureToArrayPosition.banner_image_contents = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${params.data.title || newPictures[index].title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              logo_large_contents: [
                ...(pictureToArrayPosition.logo_large_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.logo_large_contents]]
                  : []),
                ...formerPicturesProposalImage,
              ][0],
              logo_small_contents: [
                ...(pictureToArrayPosition.logo_small_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.logo_small_contents]]
                  : []),
                ...formerPicturesCoBrandingLogo,
              ][0],
              bespoke_template_file_contents: [
                ...(pictureToArrayPosition.bespoke_template_file_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.bespoke_template_file_contents]]
                  : []),
                ...formerBespokeTemplateFile,
              ][0],
              banner_image_contents: [
                ...(pictureToArrayPosition.banner_image_contents !== null
                  ? [transformedNewPictures[pictureToArrayPosition.banner_image_contents]]
                  : []),
                ...formerOverrideLogoFile,
              ][0],
            },
          })
        )
    }
  }

  if (type === 'UPDATE' && resource === 'component_content') {
    if (
      (params.data.logo_image &&
        params.data.logo_image.constructor === Array &&
        params.data.logo_image &&
        params.data.logo_image.length) ||
      (params.data.promotional_image &&
        params.data.promotional_image.constructor === Array &&
        params.data.promotional_image &&
        params.data.promotional_image.length) ||
      (params.data.module_texture &&
        params.data.module_texture.constructor === Array &&
        params.data.module_texture &&
        params.data.module_texture.length) ||
      (params.data.spec_sheet_pdf &&
        params.data.spec_sheet_pdf.constructor === Array &&
        params.data.spec_sheet_pdf &&
        params.data.spec_sheet_pdf.length)
    ) {
      // only freshly dropped pictures are instance of File
      const formerLogoImage =
        params.data.logo_image && params.data.logo_image.constructor === String ? [params.data.logo_image] : []

      const newLogoImage =
        params.data.logo_image && params.data.logo_image.constructor === Array
          ? params.data.logo_image.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerPromotionalImage =
        params.data.promotional_image && params.data.promotional_image.constructor === String
          ? [params.data.promotional_image]
          : []

      const newPromotionalImage =
        params.data.promotional_image && params.data.promotional_image.constructor === Array
          ? params.data.promotional_image.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerModuleTexture =
        params.data.module_texture && params.data.module_texture.constructor === String
          ? [params.data.module_texture]
          : []

      const newModuleTexture =
        params.data.module_texture && params.data.module_texture.constructor === Array
          ? params.data.module_texture.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const formerSpecSheetPdf =
        params.data.spec_sheet_pdf && params.data.spec_sheet_pdf.constructor === String
          ? [params.data.spec_sheet_pdf]
          : []

      const newSpecSheetPdf =
        params.data.spec_sheet_pdf && params.data.spec_sheet_pdf.constructor === Array
          ? params.data.spec_sheet_pdf.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const newPictures: any[] = []
      newLogoImage.forEach((p) => {
        p.field = 'logo_image'
        newPictures.push(p)
      })
      newPromotionalImage.forEach((p) => {
        p.field = 'promotional_image'
        newPictures.push(p)
      })
      newModuleTexture.forEach((p) => {
        p.field = 'module_texture'
        newPictures.push(p)
      })
      newSpecSheetPdf.forEach((p) => {
        p.field = 'spec_sheet_pdf'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        logo_image: null,
        promotional_image: null,
        module_texture: null,
        spec_sheet_pdf: null,
      }

      let counter = 0

      if (newLogoImage.length > 0) {
        pictureToArrayPosition.logo_image = counter
        counter++
      }

      if (newPromotionalImage.length > 0) {
        pictureToArrayPosition.promotional_image = counter
        counter++
      }

      if (newModuleTexture.length > 0) {
        pictureToArrayPosition.module_texture = counter
        counter++
      }

      if (newSpecSheetPdf.length > 0) {
        pictureToArrayPosition.spec_sheet_pdf = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, newPictureIndex) => ({
            src: picture64,

            // this would rename all images to match the title of the component content
            // but we want to use the uploaded filename
            // title: `${params.data.title}`,

            // keep the filename from the uploaded file
            title: newPictures[newPictureIndex].rawFile.name,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              logo_image: [
                ...(pictureToArrayPosition.logo_image !== null
                  ? [transformedNewPictures[pictureToArrayPosition.logo_image]]
                  : []),
                ...formerLogoImage,
              ][0],
              promotional_image: [
                ...(pictureToArrayPosition.promotional_image !== null
                  ? [transformedNewPictures[pictureToArrayPosition.promotional_image]]
                  : []),
                ...formerPromotionalImage,
              ][0],
              module_texture: [
                ...(pictureToArrayPosition.module_texture !== null
                  ? [transformedNewPictures[pictureToArrayPosition.module_texture]]
                  : []),
                ...formerModuleTexture,
              ][0],
              spec_sheet_pdf: [
                ...(pictureToArrayPosition.spec_sheet_pdf !== null
                  ? [transformedNewPictures[pictureToArrayPosition.spec_sheet_pdf]]
                  : []),
                ...formerSpecSheetPdf,
              ][0],
            },
          })
        )
    }
  }

  if ((type === 'UPDATE' || type === 'CREATE') && resource === 'testimonials') {
    if (
      params.data.image_file_contents &&
      params.data.image_file_contents.constructor === Array &&
      params.data.image_file_contents &&
      params.data.image_file_contents.length
    ) {
      // only freshly dropped pictures are instance of File
      const formerPictures = params.data.image_file_contents.filter(
        (p) => p && p.rawFile && !(p.rawFile instanceof File)
      )
      const newPictures = params.data.image_file_contents.filter((p) => p && p.rawFile && p.rawFile instanceof File)

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${params.data.title || newPictures[index].title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              image_file_contents: [...transformedNewPictures, ...formerPictures][0],
            },
          })
        )
    }
  }

  if (resource === 'private_files' || resource === 'public_files') {
    if (type === 'CREATE' || (type === 'UPDATE' && params.data.do_not_strip_file_contents)) {
      if (
        params.data.file_contents &&
        params.data.file_contents.constructor === Array &&
        params.data.file_contents &&
        params.data.file_contents.length
      ) {
        let form = new FormData()
        form.append('file_contents', params.data.file_contents[0].rawFile)

        for (let key in params.data) {
          if (key !== 'file_contents') {
            form.append(key, params.data[key])
          }
        }

        return requestHandler(type, resource, {
          ...params,
          data: form,
          headers: new Headers({}),
        })
      }
    } else if (type === 'UPDATE') {
      // Remove file upload on updated... keep the original file and only update other fields
      if (params.data.file_contents) {
        delete params.data.file_contents
      }
    }
  }

  if ((type === 'CREATE' || type === 'UPDATE') && (resource === 'roles' || resource === 'myroles')) {
    if (
      params.data.portrait_image &&
      params.data.portrait_image.constructor === Array &&
      params.data.portrait_image &&
      params.data.portrait_image.length
    ) {
      // only freshly dropped pictures are instance of File
      const formerPicturesLogo =
        params.data.portrait_image && params.data.portrait_image.constructor === String
          ? [params.data.portrait_image]
          : []

      const newPicturesLogo =
        params.data.portrait_image && params.data.portrait_image.constructor === Array
          ? params.data.portrait_image.filter((p) => p && p.rawFile && p.rawFile instanceof File)
          : []

      const newPictures: any[] = []
      newPicturesLogo.forEach((p) => {
        p.field = 'portrait_image'
        newPictures.push(p)
      })

      // Nasty: new images get merged into an array with no way to identify which field they came from
      // We record the index in the new pictures array for each field.
      // null value indicates it is not present

      const pictureToArrayPosition: { [key: string]: any } = {
        portrait_image: null,
      }

      let counter = 0

      if (newPicturesLogo.length > 0) {
        pictureToArrayPosition.portrait_image = counter
        counter++
      }

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${params.data.title || newPictures[index].title}`,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              portrait_image: [
                ...(pictureToArrayPosition.portrait_image !== null
                  ? [transformedNewPictures[pictureToArrayPosition.portrait_image]]
                  : []),
                ...formerPicturesLogo,
              ][0],
            },
          })
        )
    }
  }
  if ((type === 'CREATE' || type === 'UPDATE') && resource === 'brands') {
    let newFiles: any[] = []
    let brandLogo = params.data.logo_contents
    if (brandLogo) newFiles = addAsset(brandLogo, newFiles, 'logo_contents', undefined)
    if (newFiles.length) {
      return Promise.all(newFiles.map(convertFileToBase64))
        .then((base64Files) =>
          base64Files.map((file64, index) => ({
            ...newFiles[index],
            src: file64,
          }))
        )
        .then((transformedFiles) => {
          let files = {}
          transformedFiles.forEach((file) => {
            const newObj = {
              src: file.src,
              title: file.title,
            }
            files[file.fieldName] = newObj
          })
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              ...files,
            },
          })
        })
    }
  }

  if ((type === 'CREATE' || type === 'UPDATE') && (resource === 'expo_stands' || resource === 'expo_homepages')) {
    let newFiles: any[] = []
    let assets = params.data.contents?.assets
    if (assets) newFiles = getFileUploads(assets)
    if (newFiles.length) {
      return Promise.all(newFiles.map(convertFileToBase64))
        .then((base64Files) =>
          base64Files.map((file64, index) => ({
            ...newFiles[index],
            src: file64,
          }))
        )
        .then((transformedFiles) => {
          const updatedAssets = { ...assets, ...processFiles(transformedFiles, assets).assets }
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              contents: {
                ...params.data.contents,
                assets: updatedAssets,
              },
            },
          })
        })
    }
  }

  return requestHandler(type, resource, params)
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrlBase: string, httpClient = fetchJson) => {
  const getApiUrlForResource = (resource, org_id) => {
    // For convenience we only specify routes outside the /admin prefix
    // Rather than specifying all

    const resources_outside_org_prefix = [
      'component_modules',
      'component_inverters',
      'component_batteries',
      'component_others',
      'countries',
      'utilities',
      'distributors',
      'states',
      'zips',
      'stars',
      'user_logins',
      'orgs',
      'file_tags',
      'roof_types',
      'public_orgs',
      'manufacturers',
      'structs',
      'struct-types',
      'landing_pages',
      // Custom routes, not following standard URL structure
      'accept_terms',
      'custom',
      'components',
      'components___aliases',
      'global_projects',
      'global_contacts',
      'global_roles',
      'global_users',
      'global_wallet_line_items',
      'global_wallet_transactions',
      'superuser_wallet_products',
      'global_custom_forms',
      'expo_homepages',
      'brands',
    ]

    if (resources_outside_org_prefix.indexOf(resource) > -1) {
      //inside org prefix
      return apiUrlBase
    } else {
      // Note: This is where we automatically convert /content and /settings routes to
      // orgs model. We should probably make this more explicit
      // @ts-ignore TODO: org_id does not exist on WorkspaceHelper type
      return apiUrlBase + '/orgs/' + org_id
    }
  }

  const getUtilityTariffIdFromQueryString = () => {
    //Nasty hack to inject querystring if supplied

    try {
      let queryParams = parseQueryStringToDictionary(window.location.hash.split('?')[1])
      return queryParams.utility_tariff_id
    } catch (e) {
      return null
    }
  }

  const convertRESTRequestToHTTP = (type: string, resource: ResourceType, params) => {
    let apiUrl

    let org_id = window.WorkspaceHelper?.org_id || appStorage.getOrgId()
    if (resource === 'connected_orgs_roles') resource = 'permissions_role'

    //Quick hack so we auto-detect these resources...
    if (typeof resource !== 'string' && resource.resource) {
      if (resource.org_id) org_id = resource.org_id
      apiUrl = getApiUrlForResource(resource.resource, org_id)
    } else {
      if (resource === 'global_orgs') {
        // special case, modify this here so apiUrl lookup is correct and
        // resource is update for preparing the list URL with filters
        resource = 'orgs'
      } else if (resource === 'global_document_templates') {
        // special case, modify this here so apiUrl lookup is correct and
        // resource is update for preparing the list URL with filters
        resource = 'document_templates'
        org_id = 1
      } else if (resource === 'global_contracts') {
        // special case, modify this here so apiUrl lookup is correct and
        // resource is update for preparing the list URL with filters
        resource = 'contracts'
        org_id = 1
      } else if (resource === 'transactions') {
        if (window.WorkspaceHelper.project?.org_id) {
          // If a project is loaded use its org_id but if not then just keep the regular org_id
          // e.g. on the /transactions list page there is no project available

          // Ideally we would be able to just inject a param into the <Edit ...> component
          // to allow overriding orgId when we launch the edit transaction dialog on the
          // project page, but sadly I cannot see how to do this within the structure of
          // react-admin and restClient. Therefore, we workaround by first attempting to
          // detect the org_ids used in the transaction_data items since all transactions should
          // generally have the same org_ids.
          // If we do not find a match then just keep the org_id for the project, which was the
          // old functionality anyway.
          var transactions: any = window.WorkspaceHelper.project?.transactions_data || []
          if (transactions.length > 0 && transactions[0].org_id) {
            org_id = transactions[0].org_id
          } else {
            // Workaround: Next, try to match the org_id for the transactions in the project
            org_id = window.WorkspaceHelper.project?.org_id
          }
        }
      }

      apiUrl = getApiUrlForResource(resource, org_id)
    }

    let url = ''
    const options: RequestInit /*{ headers: Headers | undefined; [key: string]: FormData | string | Headers }*/ = {}
    if (params?.url && params.url.startsWith(apiUrl)) url = params.url
    else {
      switch (type) {
        case 'CUSTOM_DELETE':
          url = `${apiUrl}/${params.url}`
          options.method = 'DELETE'
          options.body = JSON.stringify(params.data)
          break
        case 'CUSTOM_PUT':
          url = `${apiUrl}/${params.url}`
          options.method = 'PUT'
          options.body = JSON.stringify(params.data)
          break
        case 'CUSTOM_PATCH':
          url = `${apiUrl}/${params.url}`
          options.method = 'PATCH'
          options.body = JSON.stringify(params.data)
          break
        case 'AUTH_USER_DELETE':
          let apiUrlRoot = apiUrl.split('/api/')[0]
          url = `${apiUrlRoot}/auth/users/me/`
          options.method = 'DELETE'
          options.body = JSON.stringify(params.data)
          break
        case 'CUSTOM_POST':
          url = `${apiUrl}/${params.url}`
          options.method = 'POST'
          options.body = JSON.stringify(params.data)
          break
        case 'CUSTOM_GET':
          url = `${apiUrl}/${params.url}`
          options.method = 'GET'
          break
        case 'DUPLICATE':
          if (typeof resource !== 'string') {
            url = `${apiUrl}/${resource.resource}/duplicate/?id=${resource.id}`
            options.method = 'POST'
          } else {
            throw Error(`DUPLICATE got unexpected resource type ${resource}`)
          }
          break
        case GET_LIST: {
          const query: { [key: string]: any } = {
            page: 1,
            fieldset: 'list',
          }
          if (params.pagination) {
            //Workaround an issue where unknown parameters (such as ?customize)
            //can lead to a pagination.page value of NaN
            const page = !isNaN(params.pagination.page) ? params.pagination.page : 1

            const perPage = params.pagination.perPage

            query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1])

            query.page = page
            query.limit = perPage
          }

          if (params.sort) {
            query['ordering'] = (params.sort.order === 'DESC' ? '-' : '') + params.sort.field
          }

          let filter = {}
          if (params.filter) {
            for (const [key, value] of Object.entries(params.filter)) {
              if (key === 'require_distributor') {
                if (value != null) {
                  console.log('require distributor', value)
                  filter['require_distributor'] = value
                }
              } else if (key !== 'q') {
                filter[key] = value
              }
            }

            if (params.filter.q) {
              query['search'] = params.filter.q
            }

            if (params.filter.fieldset) {
              query['fieldset'] = params.filter.fieldset
            }
          }
          // DRF filtering
          if (Object.keys(filter).length > 0) {
            for (let key in filter) {
              query[key] = filter[key]
            }
          }

          url = `${apiUrl}/${resource}/?${stringify(query)}`

          // Special hack to prevent list results from overwriting records in redux
          // adding the _aliases suffix will have the suffix stripped when resolving the endpoint
          if (url.includes('___aliases')) {
            url = url.replace('___aliases', '')
          }

          break
        }
        case GET_ONE:
          if (resource === 'custom_tariff_current') {
            let id = urlToId(params.id)
            url =
              `${apiUrl}/projects/${id}/custom_tariff_current/` +
              (getUtilityTariffIdFromQueryString() ? '?utility_tariff_id=' + getUtilityTariffIdFromQueryString() : '')
          } else if (resource === 'custom_tariff_proposed') {
            let id = urlToId(params.id)
            url =
              `${apiUrl}/projects/${id}/custom_tariff_proposed/` +
              (getUtilityTariffIdFromQueryString() ? '?utility_tariff_id=' + getUtilityTariffIdFromQueryString() : '')
          } else {
            if (typeof resource === 'string' && resources_sub_route_for_org.indexOf(resource) > -1) {
              url = `${apiUrl}/${resource}/`
            } else {
              let id = urlToId(params.id)
              url = `${apiUrl}/${resource}/${id}/`
            }
          }

          // Allows sending arbitrary query parameters to the entity read endpoint
          // Use like `<Edit filter={{extra:"param"}}/>`
          if (params.filter && typeof params.filter === 'object') {
            url = `${url}?${stringify(params.filter)}`
          }

          break
        case GET_MANY: {
          const query = {
            //filter: JSON.stringify({ id: params.ids }),
            filter: urlsToIds(params.ids).join(','),
            limit: 1000,

            // Use the list version of the RESTful endpoint if configured.
            // Some results will load much faster.
            // Beware: The list view may not always contain the fields you expect in the result.
            fieldset: 'list',
          }
          url = `${apiUrl}/${resource}/?${stringify(query)}`

          // Special hack to prevent list results from overwriting records in redux
          // adding the _aliases suffix will have the suffix stripped when resolving the endpoint
          if (url.includes('___aliases')) {
            url = url.replace('___aliases', '')
          }

          break
        }
        case GET_MANY_REFERENCE: {
          // const { perPage } = params.pagination
          const { field, order } = params.sort
          const query = {
            // sort: JSON.stringify([field, order]),
            // range: JSON.stringify([
            //     (page - 1) * perPage,
            //     page * perPage - 1,
            // ]),
            // filter: JSON.stringify({
            //     ...params.filter,
            //     [params.target]: params.id,
            // }),
            //filter: params.ids.join(',').map(function(x){ return urlToId(x) }),
          }

          if (field && order) {
            query['ordering'] = (order === 'DESC' ? '-' : '') + field
          }

          if (params.ids) {
            query['filter'] = urlsToIds(params.ids).join(',')
          }

          if (params.target) {
            query[params.target] = params.id
          }

          url = `${apiUrl}/${resource}/?${stringify(query)}`
          break
        }
        case UPDATE:
          if (typeof resource !== 'string') {
            throw new Error(`UPDATE got unexpected resource ${resource}`)
          }
          // Remove this from staff/global endpoints which ARE allowed to post 'org' in the payload.
          if (!endpointsWhereOrgIsAllowedInPayload.includes(resource)) {
            if ('org' in params.data) {
              delete params.data.org
            }
          }
          if (resource === 'custom_tariff_current') {
            let id = urlToId(params.id)
            url = `${apiUrl}/projects/${id}/custom_tariff_current/`
          } else if (resource === 'custom_tariff_proposed') {
            let id = urlToId(params.id)
            url = `${apiUrl}/projects/${id}/custom_tariff_proposed/`
          } else {
            url = `${apiUrl}/${resource}/${params.id}/`
          }

          /*
        Private files should use PATCH otherwise PUT forces all missing fields to be treated
        like they are null which results in tags being stripped
        */
          // 11/17/2022: Added a condition to submit request as PATCH for editing org connections
          if (resource === 'private_files' || resource === 'connected_orgs') {
            options.method = 'PATCH'
          } else {
            options.method = 'PUT'
          }

          if (params.data instanceof FormData) {
            options.body = params.data
          } else {
            options.body = JSON.stringify(params.data)
          }
          break
        case UPDATE_MANY:
          if (typeof resource !== 'string') {
            throw new Error(`UPDATE_MANY got unexpected resource ${resource}`)
          }
          if (!params?.ids || !params.ids.length) {
            throw new Error('UPDATE_MANY was not passed any record ids')
          }
          // Remove this from staff/global endpoints which ARE allowed to post 'org' in the payload.
          if (!endpointsWhereOrgIsAllowedInPayload.includes(resource)) {
            if ('org' in params.data) {
              delete params.data.org
            }
          }

          url = `${apiUrl}/bulk/${resource}/`

          options.method = 'PUT'
          options.body = JSON.stringify({ ...params.data, resource, ids: params.ids })
          break
        case CREATE:
          url = `${apiUrl}/${resource}/`

          if (params.extras && params.extras.skipResponse) {
            url += '?skip_response=true'
          }

          options.method = 'POST'

          if (params.data instanceof FormData) {
            options.body = params.data
          } else {
            options.body = JSON.stringify(params.data)
          }
          break
        case DELETE:
          if (resource === 'custom_tariff_current') {
            let id = urlToId(params.id)
            url = `${apiUrl}/projects/${id}/custom_tariff_current/`
          } else if (resource === 'custom_tariff_proposed') {
            let id = urlToId(params.id)
            url = `${apiUrl}/projects/${id}/custom_tariff_proposed/`
          } else {
            url = `${apiUrl}/${resource}/${params.id}/`
          }
          options.method = 'DELETE'
          break
        case DELETE_MANY:
          if (typeof resource !== 'string') {
            throw new Error(`DELETE_MANY got unexpected resource ${resource}`)
          }
          if (!params?.ids || !params.ids.length) {
            throw new Error('DELETE_MANY was not passed any record ids')
          }

          url = `${apiUrl}/bulk/${resource}/`

          options.method = 'DELETE'
          options.body = JSON.stringify({ ids: params.ids })
          break
        default:
          throw new Error(`Unsupported fetch action type ${type}`)
      }
    }

    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }
    // add your own headers here
    let authToken = appStorage.getToken()
    // @ts-ignore TODO: .set is in the standard but not in all type definitions yet. :(
    if (authToken) options.headers.set('Authorization', 'Bearer ' + authToken)

    let projectIdentifiersToken = appStorage.getProjectIdentifiersToken()
    // @ts-ignore TODO: .set is in the standard but not in all type definitions yet. :(
    if (projectIdentifiersToken) options.headers.set('Os-Project-Identifiers', 'Bearer ' + projectIdentifiersToken)

    if (params?.headers) {
      Object.keys(params.headers).forEach((key) => {
        // @ts-ignore TODO: .set is in the standard but not in all type definitions yet. :(
        options.headers.set(key, params.headers[key])
      })
    }

    // If this is a mutation call to the MFA API, allow the client to send cookies. We specifically care about this
    // for the "remember device" cookie, which is HttpOnly, so we don't get to observe the actual value in JavaScript
    // nor do we need to persist it in localStorage.
    const is_mfa_device_mutation =
      (type === 'CUSTOM_POST' && (url === `${apiUrl}/mfa/clear` || url.startsWith(`${apiUrl}/mfa/devices`))) ||
      (type === 'CUSTOM_DELETE' && url.startsWith(`${apiUrl}/mfa/devices`))
    if (is_mfa_device_mutation) {
      options.credentials = 'include'
    }

    // add user preferred language
    const locale = appStorage.getLocale() || resolveBrowserLocale()
    if (locale) {
      //format locale e.g. en_US => en-US
      // @ts-ignore TODO: .set is in the standard but not in all type definitions yet. :(
      options.headers.set('Accept-Language', locale.replace('_', '-'))
    }

    return { url, options }
  }

  const convertHTTPResponseToREST = (
    response: { headers: Headers; json: any; body: string; status: number },
    type: string,
    resource: ResourceType,
    params: any,
    hackOverrideTotalIfZero: any
  ) => {
    const { headers, json } = response

    if (typeof json === 'undefined' && typeof headers === 'undefined') {
      throw new Error('ra.notification.http_error')
    }

    const TALKING_TO_OPENSOLAR_API_VERSION = headers.get('OpenSolar-Api-Version')

    // @ts-ignore The built-in type of window does not include our custom types.
    window.setVersion(TALKING_TO_OPENSOLAR_API_VERSION)

    // 'mandatory', 'optional', null
    // @ts-ignore The built-in type of window does not include our custom values.
    const mismatchType = apiVersionMismatchType(window.OPENSOLAR_API_VERSION, TALKING_TO_OPENSOLAR_API_VERSION)

    if (mismatchType) {
      // @ts-ignore
      if (window.setVersionMismatch) {
        // @ts-ignore
        window.setVersionMismatch(mismatchType)
      } else {
        console.error('window.setVersionMismatch not found')
      }
    }

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        const content_range = headers.get('content-range')
        if (content_range === null) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          )
        }

        let total = parseInt(content_range.split('/').pop()!, 10)

        if (total === 0 && hackOverrideTotalIfZero) {
          total = hackOverrideTotalIfZero
        }

        return {
          data: json,
          total: total,
        }
      case CREATE:
        const url = json.url ? { url: json.url } : {}
        //return full payload for events
        if (resource === 'events') {
          return { data: json }
        }
        return { data: { ...params.data, id: json.id, ...url } }
      case DELETE:
        return { data: {} }
      default:
        return { data: json, body: response.body }
    }
  }

  /**
   * @param type Request type, e.g GET_LIST
   * @param resource Resource name, e.g. "posts"
   * @param params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  let restClient = (type: string, resource: ResourceType, params: any) => {
    // @TODO: Remove this hack which allows us to avoid making a network request when loading the list for components
    // so we can initially show component activations instead of making a network request, until a search is performed
    let hackOverrideTotalIfZero: null | 1 = null

    if (type === GET_LIST && params?.filter?.emptyUntilSearch === 'true') {
      // Even nastier hack to allow filtering by exhibitor_org_id when there is no search query "q" supplied
      // It's a nasty hack but this is MUCH simpler than alternatives.
      if (!params?.filter?.q && !params?.filter?.exhibitor_org_id && !params?.filter?.require_distributor) {
        return Promise.resolve({
          data: [],
          total: 1,
        })
      } else {
        hackOverrideTotalIfZero = 1
      }
    }

    const { url, options } = convertRESTRequestToHTTP(type, resource, params)
    return httpClient(url, options).then((response) =>
      convertHTTPResponseToREST(response, type, resource, params, hackOverrideTotalIfZero)
    )
  }

  return addUploadCapabilities(restClient)
}
