import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Alert from 'elements/Alert'
import { updateSlot } from 'projectSections/sections/design/systems/Slots'
import { useTranslate } from 'ra-core'
import React, { FC, useState } from 'react'
import { Theme } from 'types/themes'
import { round_number_to_specific_decimal_places } from 'util/misc'
import {
  getACIsolatorCompatibility,
  getCableVoltageDrop,
  getDCIsolatorCompatibility,
  getMaxCableCurrent,
} from './helpers'
import { ACIsolatorValidationPropsType, CableValidationPropsType, DCIsolatorValidationPropsType } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  validationResults: {
    display: 'grid',
    gridTemplateColumns: '1fr 0fr 1fr 0fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 0fr',
    },
    textAlign: 'right',
    lineHeight: 'normal',
    fontSize: 12,
    columnGap: '20px',
    rowGap: '6px',
  },
  validationHeading: {
    marginRight: '-10px',
    minWidth: '110px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px',
    },
  },
  validationValue: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    width: '70px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px',
    },
  },
  validationInput: {
    fontSize: 12,
    maxHeight: '18px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}))

const CompatibilityIcon = ({ compatibility }: { compatibility: 'good' | 'ok' | 'bad' | null }) => {
  if (!compatibility) return <div style={{ width: 12, display: 'inline-block' }}></div>
  return React.createElement(compatibility === 'good' ? CheckIcon : InfoOutlinedIcon, {
    style: {
      marginLeft: 5,
      width: 12,
      height: 12,
      transform: 'scale(1.5)',
      color: compatibility === 'good' ? 'green' : compatibility === 'ok' ? 'orange' : 'red',
    },
  })
}

export const DCIsolatorValidation: FC<DCIsolatorValidationPropsType> = ({ isolator, strings }) => {
  const validationResults = getDCIsolatorCompatibility(isolator, strings)
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>{translate('String Isc')}</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.current, 2)}A
        </div>
        <div className={classes.validationHeading}>{translate('Isolator current rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.currentRating, 2)}A{' '}
          <CompatibilityIcon compatibility={validationResults.data.currentCompatibility} />
        </div>
        <div className={classes.validationHeading}>
          String V<sub>oc:</sub>
        </div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.voltage, 2)}V
        </div>
        <div className={classes.validationHeading}>{translate('Isolator voltage rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.voltageRating, 2)}V{' '}
          <CompatibilityIcon compatibility={validationResults.data.voltageCompatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}

export const ACIsolatorValidation: FC<ACIsolatorValidationPropsType> = ({ isolator, isolatorLocation }) => {
  const inverter = isolatorLocation.type === 'OsMppt' ? isolatorLocation.parent : isolatorLocation
  const validationResults = getACIsolatorCompatibility(isolator, isolatorLocation)
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>
          {inverter.microinverter
            ? isolatorLocation.type === 'OsMppt'
              ? translate('Branch output current rating')
              : translate('Combined branches output current rating')
            : translate('Inverter output current rating')}
          :
        </div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.current, 2)}A
        </div>
        <div className={classes.validationHeading}>{translate('Isolator current rating')}:</div>
        <div className={classes.validationValue}>
          {round_number_to_specific_decimal_places(validationResults.data.currentRating, 2)}A
          <CompatibilityIcon compatibility={validationResults.data.currentCompatibility} />
        </div>
        <div className={classes.validationHeading}>{translate('Inverter phases')}:</div>
        <div className={classes.validationValue}>
          {validationResults.data.phase === 'single_phase'
            ? '1'
            : validationResults.data.phase === 'two_phase'
            ? '2'
            : validationResults.data.phase === 'three_phase'
            ? '3'
            : ''}
        </div>
        <div className={classes.validationHeading}>Isolator phases:</div>
        <div className={classes.validationValue}>
          {validationResults.data.isolatorPhase === 'single_phase'
            ? '1'
            : validationResults.data.isolatorPhase === 'two_phase'
            ? '2'
            : validationResults.data.isolatorPhase === 'three_phase'
            ? '3'
            : ''}
          <CompatibilityIcon compatibility={validationResults.data.phaseCompatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}

export const CableValidation: FC<CableValidationPropsType> = ({ slot, cable, supplyPhase, cableLocation }) => {
  const stringOrMPPT = cableLocation
  const current = getMaxCableCurrent(cableLocation)
  const productCableLength = cable.cable_length || 0
  const slotCableLength = slot?.properties?.cableLength
  const [cableLength, setCableLength] = useState(slotCableLength || productCableLength)
  const validationResults = getCableVoltageDrop(cable, cableLength, supplyPhase, current)
  const system = cableLocation.getSystem()
  const classes = useStyles()

  return (
    <>
      <div className={classes.validationResults}>
        <div className={classes.validationHeading}>Cable length:</div>
        <TextField
          className={classes.validationValue}
          type="number"
          id="cable-length"
          onChange={(e) => {
            setCableLength(Number(e.target.value))
            if (!slot.properties) slot.properties = {}
            slot.properties.cableLength = Number(e.target.value)
            updateSlot(slot, system)
          }}
          value={round_number_to_specific_decimal_places(cableLength, 2)}
          variant={'outlined'}
          style={{ backgroundColor: 'white' }}
          InputProps={{
            inputProps: {
              min: 0,
              max: productCableLength || null,
            },
            endAdornment: <div className={classes.validationInput}>m</div>,
            classes: { input: classes.validationInput, root: classes.validationInput },
          }}
        />
        <div className={classes.validationHeading}>Voltage drop:</div>
        <div className={classes.validationValue}>
          {validationResults?.data?.voltageDropPercentage
            ? round_number_to_specific_decimal_places(validationResults.data.voltageDropPercentage, 3) + '% '
            : ''}
          <CompatibilityIcon compatibility={validationResults.compatibility} />
        </div>
      </div>
      {validationResults.data.error && (
        <Alert severity="warning" styles={{ padding: '4px 4px 4px 2px' }}>
          {validationResults.data.error}
        </Alert>
      )}
    </>
  )
}
