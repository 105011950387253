import { CheckboxProps as Mui4CheckboxProps, default as Mui4Checkbox } from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { CheckboxProps as Mui5CheckboxProps, default as Mui5Checkbox } from '@mui/material/Checkbox'
import { FC } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks/useComponentVersion'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'rgba(0, 0, 0, 0.25)',
  },
  checkedIcon: {
    '&.MuiSvgIcon-root': {
      color: '#1751D0',
    },
  },
})

export const Checkbox: FC<CheckboxProps> = (props) => {
  return (
    <Inspector name="Checkbox" propDefs={CheckboxPropDefs} showExample={true} component={OsCheckbox} props={props} />
  )
}

const V2_CHECKBOX_COLORS = ['info', 'error', 'warning', 'success']

export enum OsCheckboxVersion {
  V1 = 1, // OS UI 2.0
  V2 = 2, // MUI5 + Stratis
}

export type CheckboxProps = Mui5CheckboxProps

const OsCheckbox = (props: CheckboxProps) => {
  const classes = useStyles()
  let version = useComponentVersion('checkbox')

  switch (version) {
    case OsCheckboxVersion.V2:
      return <Mui5Checkbox disableRipple {...props} />

    default:
      const mui4Props: Mui4CheckboxProps = {
        ...props,
        color:
          !props.color || V2_CHECKBOX_COLORS.includes(props.color)
            ? 'default'
            : (props.color as Mui4CheckboxProps['color']),
        size: props.size === 'large' ? 'medium' : props.size,
      }
      return (
        <Mui4Checkbox
          className={classes.root}
          disableRipple
          checkedIcon={<CheckBoxIcon className={props.checked ? classes.checkedIcon : ''} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...mui4Props}
        />
      )
  }
}

const CheckboxPropDefs: PropsDefs = {
  defaultChecked: { default: false },
  checked: { hidden: true },
  indeterminate: { hidden: true },
  size: { default: 'medium' },
  color: { default: 'default' },
  icon: { hidden: true },
}
