import DateFnsUtils from '@date-io/date-fns'
import {
  DateTimePicker as Mui4DateTimePicker,
  DateTimePickerProps as Mui4DateTimePickerPropsType,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { DateTimePicker as Mui5DateTimePicker } from '@mui/x-date-pickers'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

// * MUI DateTimePicker API Spec  https://mui.com/x/react-date-pickers/date-time-picker/
export const DateTimePicker: FC<DateTimePickerProps> = (props) => (
  <Inspector
    name="DateTimePicker"
    propDefs={DateTimePickerPropsDefs}
    showExample={false}
    component={OsDateTimePicker}
    props={props}
  />
)

export enum OsDateTimePickerVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type DateTimePickerProps = Mui4DateTimePickerPropsType

const OsDateTimePicker: FC<DateTimePickerProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('date_picker')

  switch (version) {
    case OsDateTimePickerVersion.V2:
      return (
        //@ts-ignore
        <Mui5DateTimePicker
          ref={ref}
          slotProps={{
            dialog: {
              sx: {
                zIndex: 2000,
              },
            },
            popper: {
              sx: {
                zIndex: 2000,
              },
            },
            textField: {
              InputProps: {
                color: 'secondary',
              },
            },
          }}
          {...props}
        />
      )

    default:
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Mui4DateTimePicker ref={ref} {...props} />
        </MuiPickersUtilsProvider>
      )
  }
})

const DateTimePickerPropsDefs: PropsDefs = {}
