import { useTranslate } from 'ra-core'
import { ExpoHomepageCreate, ExpoHomepageEdit, ExpoHomepageList } from './ExpoHomepages'

const ExpoHomepageTitle = ({ record }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate('Expo Homepage')} - {record && record.title ? `"${record.title}"` : ''}
    </span>
  )
}

export default {
  list: ExpoHomepageList,
  create: ExpoHomepageCreate,
  edit: ExpoHomepageEdit,
  options: {
    create: {
      title: 'Expo Homepages',
      subtitle: null,
      breadCrumb: 'Create Expo Homepage',
    },
    list: {
      title: 'Expo Homepages',
      subtitle: null,
      breadCrumb: 'Expo Homepages',
    },
    edit: {
      title: <ExpoHomepageTitle />,
      subtitle: null,
      breadCrumb: 'Edit Expo Homepage',
    },
  },
}
