/**
 * Base class for extensible functions
 */

// @ts-ignore
class ExtensibleFunction<Args extends [...any] = [], Ret = void> extends Function implements ActionType<Args, Ret> {
  // @ts-ignore
  constructor(f) {
    return Object.setPrototypeOf(f, new.target.prototype)
  }
}
// This interface ensures that the return value has the correct type
interface ActionType<Args extends [...any] = [], Ret = void> {
  (...args: Args): Promise<Ret>
}

export class Action<Args extends [...any] = [], Ret = void> extends ExtensibleFunction<Args, Ret> {
  constructor() {
    super(
      async (...args: Args): Promise<Ret> => {
        return this.run(...args)
      }
    )
  }

  // Gets set by application to indicate that the action is implemented in this app domain
  //@hidden
  onOwn?: (action: Action<Args, Ret>, priority: number) => void

  //@hidden
  handler: undefined | ((...args: Args) => Promise<Ret>)

  //@hidden
  unownedHandler: undefined | ((...args: Args) => Promise<Ret>)

  own(handler: (...args: Args) => Promise<Ret>, priority: number = 0) {
    this.handler = handler
    if (this.onOwn) this.onOwn(this, priority)
  }

  async run(...args: Args): Promise<Ret> {
    return this.handler ? this.handler(...args) : this.unownedHandler ? this.unownedHandler(...args) : Promise.reject()
  }
}
