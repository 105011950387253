import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled: boolean
}

const ChatFields: FC<PropTypes> = ({ disabled }) => {
  const translate = useTranslate()
  const source = useBlockSource('chat')
  if (!source) return null
  return (
    <InputAccordion title={translate('Chat')}>
      <span>{translate('Configure a dedicated Olark Chat account to appear in your Expo Stand.')}</span>
      <TextInput
        label={'Olark Chat API Key'}
        name={`${source}.apiKey`}
        source={`${source}.apiKey`}
        disabled={disabled}
      />
    </InputAccordion>
  )
}

export default ChatFields
