export const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_5091_184877" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5091_184877)">
        <path
          d="M6.75642 16.5831C6.38795 16.5831 6.07253 16.4519 5.81017 16.1896C5.54781 15.9272 5.41663 15.6118 5.41663 15.2433V5.49978H4.95829C4.80482 5.49978 4.67621 5.44825 4.57246 5.3452C4.46857 5.24214 4.41663 5.1145 4.41663 4.96228C4.41663 4.80992 4.46857 4.68089 4.57246 4.5752C4.67621 4.46936 4.80482 4.41645 4.95829 4.41645H7.99996V4.16645C7.99996 3.98603 8.06385 3.83193 8.19163 3.70415C8.31927 3.57651 8.47329 3.5127 8.65371 3.5127H11.3462C11.5266 3.5127 11.6807 3.57651 11.8083 3.70415C11.9361 3.83193 12 3.98603 12 4.16645V4.41645H15.0416C15.1951 4.41645 15.3237 4.46797 15.4275 4.57103C15.5313 4.67408 15.5833 4.80172 15.5833 4.95395C15.5833 5.10631 15.5313 5.23533 15.4275 5.34103C15.3237 5.44686 15.1951 5.49978 15.0416 5.49978H14.5833V15.2344C14.5833 15.613 14.4521 15.9324 14.1898 16.1927C13.9274 16.453 13.612 16.5831 13.2435 16.5831H6.75642ZM13.5 5.49978H6.49996V15.2433C6.49996 15.3182 6.52399 15.3796 6.57204 15.4277C6.6201 15.4758 6.68156 15.4998 6.75642 15.4998H13.2435C13.3184 15.4998 13.3798 15.4758 13.4279 15.4277C13.4759 15.3796 13.5 15.3182 13.5 15.2433V5.49978ZM8.87413 13.9998C9.02635 13.9998 9.15538 13.9479 9.26121 13.8442C9.3669 13.7403 9.41975 13.6116 9.41975 13.4581V7.54145C9.41975 7.38797 9.36829 7.25929 9.26538 7.1554C9.16232 7.05165 9.03461 6.99978 8.88225 6.99978C8.73003 6.99978 8.601 7.05165 8.49517 7.1554C8.38947 7.25929 8.33663 7.38797 8.33663 7.54145V13.4581C8.33663 13.6116 8.38808 13.7403 8.491 13.8442C8.59406 13.9479 8.72177 13.9998 8.87413 13.9998ZM11.1177 13.9998C11.2699 13.9998 11.3989 13.9479 11.5048 13.8442C11.6104 13.7403 11.6633 13.6116 11.6633 13.4581V7.54145C11.6633 7.38797 11.6118 7.25929 11.5089 7.1554C11.4059 7.05165 11.2782 6.99978 11.1258 6.99978C10.9736 6.99978 10.8445 7.05165 10.7387 7.1554C10.633 7.25929 10.5802 7.38797 10.5802 7.54145V13.4581C10.5802 13.6116 10.6316 13.7403 10.7345 13.8442C10.8376 13.9479 10.9653 13.9998 11.1177 13.9998Z"
          fill="#6B6A76"
        />
      </g>
    </svg>
  )
}
