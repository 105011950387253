import { Dialog as Mui4Dialog, DialogProps as Mui4DialogProps } from '@material-ui/core'
import { default as Mui5Dialog } from '@mui/material/Dialog'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

export const Dialog: FC<OsDialogProps> = (props) => {
  return <Inspector name="Dialog" propDefs={DialogProps} showExample={true} component={OsDialog} props={props} />
}

export enum OsDialogVersion {
  V1 = 1, // MUI4
  V2 = 2, // MUI5 + Stratis UI
}
export type OsDialogProps = Mui4DialogProps

const OsDialog: FC<OsDialogProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('dialog')
  switch (version) {
    case OsDialogVersion.V2:
      //@ts-ignore TODO: fix this ref typing issue
      return <Mui5Dialog ref={ref} {...props} />

    default:
      return <Mui4Dialog ref={ref} {...props} />
  }
})

const DialogProps: PropsDefs = {
  open: { hidden: true },
}
