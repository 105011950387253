import { default as Mui5ToggleButton, ToggleButtonProps as Mui5ToggleButtonProps } from '@mui/material/ToggleButton'
import { FC, forwardRef } from 'react'

export type ToggleButtonProps = Omit<Mui5ToggleButtonProps, 'classes'>

export const ToggleButton: FC<ToggleButtonProps> = forwardRef(({ color, ...props }, ref) => {
  if (!color) color = 'secondary'

  return <Mui5ToggleButton ref={ref} color={color} {...props}></Mui5ToggleButton>
})
