import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const AvatarIconSvg: FC<IconProps> = (props) => {
  const { height, width } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={'#ECECED'} />
      <path
        d="M11.9789 7.07965C11.9789 8.2282 11.0929 9.15929 9.99992 9.15929C8.90698 9.15929 8.02097 8.2282 8.02097 7.07965C8.02097 5.93109 8.90698 5 9.99992 5C11.0929 5 11.9789 5.93109 11.9789 7.07965Z"
        fill="#7F7D83"
      />
      <path
        d="M14 11.9027V13.2301C13.5271 14.2494 11.9155 15 9.99992 15C8.08438 15 6.4727 14.2494 6 13.2301V11.9027C6 10.9252 7.17895 9.86726 9.87368 9.86726C12.5684 9.86726 14 10.9252 14 11.9027Z"
        fill="#7F7D83"
      />
    </svg>
  )
}

export const AvatarIcon = createStandardIcon(AvatarIconSvg)
