import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const IncentivesIconSvg: FC<IconProps> = (props) => {
  const { fill, stroke } = props

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C3.44772 6 3 6.44772 3 7V9C3 9.55228 3.44772 10 4 10H20C20.5523 10 21 9.55228 21 9V7C21 6.44772 20.5523 6 20 6H4ZM20 8C20 7.44772 19.5523 7 19 7H5C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H19C19.5523 9 20 8.55229 20 8Z"
        fill={fill}
      />
      <path d="M9.5 20V7H10.5V20H9.5Z" fill={fill} />
      <path d="M13.5 13V7H14.5V12L13.5 13Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9V19C4 20.1046 4.89543 21 6 21H13.191C13.5627 21 13.8044 20.6088 13.6382 20.2764C13.5535 20.107 13.3804 20 13.191 20H7C5.89543 20 5 19.1046 5 18V10H19V12.191C19 12.3804 19.107 12.5535 19.2764 12.6382C19.6088 12.8044 20 12.5627 20 12.191V9H4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 22C19.5376 22 22 19.5376 22 16.5C22 13.4624 19.5376 11 16.5 11C13.4624 11 11 13.4624 11 16.5C11 19.5376 13.4624 22 16.5 22ZM16.5 21C18.9853 21 21 18.9853 21 16.5C21 14.0147 18.9853 12 16.5 12C14.0147 12 12 14.0147 12 16.5C12 18.9853 14.0147 21 16.5 21Z"
        fill={fill}
      />
      <path
        d="M17.4882 14.6562H16.5664M16.5664 14.6562C16.0133 14.6562 15.6445 15.025 15.6445 15.5781C15.6445 16.1312 16.0133 16.4999 16.5664 16.4999C17.1195 16.4999 17.4882 16.8686 17.4882 17.4217C17.4882 17.9748 17.1195 18.3436 16.5664 18.3436H15.6445M16.5664 14.6562V13.7344M16.5664 19.2654V18.6201"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 2C9.11929 2 8 3.11929 8 4.5C8 5.88071 9.11929 7 10.5 7H12.5V3.5C12.5 2.67157 11.8284 2 11 2H10.5ZM10.5 6C9.67157 6 9 5.32843 9 4.5C9 3.67157 9.67157 3 10.5 3H10.6667C11.1269 3 11.5 3.3731 11.5 3.83333V5.16667C11.5 5.6269 11.1269 6 10.6667 6H10.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 2C12.6716 2 12 2.67157 12 3.5V7H14C15.3807 7 16.5 5.88071 16.5 4.5C16.5 3.11929 15.3807 2 14 2H13.5ZM13.8333 6C13.3731 6 13 5.6269 13 5.16667V3.83333C13 3.3731 13.3731 3 13.8333 3H14C14.8284 3 15.5 3.67157 15.5 4.5C15.5 5.32843 14.8284 6 14 6H13.8333Z"
        fill={fill}
      />
    </svg>
  )
}

export const IncentivesIcon = createStandardIcon(IncentivesIconSvg)
