import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const BatteryIconSvg: FC<IconProps> = (props) => {
  const { color, fill, stroke } = props
  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66732 8.16602C3.37865 8.16602 2.33398 9.21068 2.33398 10.4993V18.666C2.33398 19.9547 3.37865 20.9993 4.66732 20.9993H21.0007C22.2894 20.9993 23.334 18.788 23.334 17.4993V10.4993C23.334 9.21068 22.2894 8.16602 21.0007 8.16602H4.66732ZM22.1673 10.4993C22.1673 9.21068 21.1227 9.33268 19.834 9.33268H5.83398C4.54532 9.33268 3.50065 10.3774 3.50065 11.666V17.4993C3.50065 18.788 4.54532 19.8327 5.83398 19.8327H19.834C21.1227 19.8327 22.1673 18.788 22.1673 17.4993V10.4993Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.166 10.5V18.6667H25.0827C25.727 18.6667 26.2493 18.1444 26.2493 17.5V11.6667C26.2493 11.0223 25.727 10.5 25.0827 10.5H22.166ZM25.0827 12.8333C25.0827 12.189 24.5604 11.6667 23.916 11.6667H23.3327V17.5H23.916C24.5604 17.5 25.0827 16.9777 25.0827 16.3333V12.8333Z"
        fill={fill}
      />
      <path
        d="M5.83398 18.0833V11.0833C5.83398 10.7612 6.09515 10.5 6.41732 10.5C6.73948 10.5 7.00065 10.7612 7.00065 11.0833V18.0833C7.00065 18.4055 6.73948 18.6667 6.41732 18.6667C6.09515 18.6667 5.83398 18.4055 5.83398 18.0833Z"
        fill={fill}
      />
      <path
        d="M9.33398 18.0833V11.0833C9.33398 10.7612 9.59515 10.5 9.91732 10.5C10.2395 10.5 10.5007 10.7612 10.5007 11.0833V18.0833C10.5007 18.4055 10.2395 18.6667 9.91732 18.6667C9.59515 18.6667 9.33398 18.4055 9.33398 18.0833Z"
        fill={fill}
      />
      <path
        d="M12.834 18.0833V11.0833C12.834 10.7612 13.0952 10.5 13.4173 10.5C13.7394 10.5 14.0007 10.7612 14.0007 11.0833V18.0833C14.0007 18.4055 13.7394 18.6667 13.4173 18.6667C13.0952 18.6667 12.834 18.4055 12.834 18.0833Z"
        fill={fill}
      />
      <path
        d="M16.334 18.0833V11.0833C16.334 10.7612 16.5952 10.5 16.9173 10.5C17.2394 10.5 17.5007 10.7612 17.5007 11.0833V18.0833C17.5007 18.4055 17.2394 18.6667 16.9173 18.6667C16.5952 18.6667 16.334 18.4055 16.334 18.0833Z"
        fill={fill}
      />
    </svg>
  )
}

export const BatteryIcon = createStandardIcon(BatteryIconSvg)
