import { styled } from '../../styles/styled'

type PropTypes = {
  textColor: string
  label: string
  onClick(): void
}

const StyledLink = styled('a')<{ textColor: string }>(({ theme, textColor }) => {
  return {
    '&:link, &:visited, &:hover, &:active': {
      color: textColor,
    },
  }
})

export const PrequalActionLink = ({ textColor, label, onClick }: PropTypes) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }
  return (
    <StyledLink href="#" textColor={textColor} onClick={handleClick}>
      {label}
    </StyledLink>
  )
}
