import { default as Mui4Radio, RadioProps as Mui4RadioProps } from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'
import AdjustIcon from '@material-ui/icons/Adjust'
import { default as Mui5Radio, RadioProps as Mui5RadioProps } from '@mui/material/Radio'
import classnames from 'classnames'
import { FC } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks/useComponentVersion'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'rgba(0, 0, 0, 0.25)',
  },
  checkedIcon: {
    '&.MuiSvgIcon-root': {
      color: '#1751D0',
    },
  },
  v2root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  v2icon: {
    // Style for the default icon
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1.5px #DCDCDE',
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: 'fff',
      boxShadow: 'inset 0 0 0 1.5px #6C92E4',
    },
  },
  v2checkedIcon: {
    // Style for the checked icon
    backgroundColor: '#1751D0',
    boxShadow: 'inset 0 0 0 1.5px #1751D0',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      backgroundColor: 'white',
      borderRadius: '50%',
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    'input:hover ~ &': {
      backgroundColor: '#113B98',
      boxShadow: 'inset 0 0 0 1.5px #113B98',
    },
    'input:focus ~ &': {
      outline: '1px solid white',
      boxShadow: '0px 0px 0px 3px #618DF2, 0px 0px 0px 4px #FFF',
    },
  },
})

export const Radio: FC<RadioProps> = (props) => {
  return <Inspector name="Radio" propDefs={RadioPropDefs} showExample={true} component={OsRadio} props={props} />
}

export enum OsRadioVersion {
  V1 = 1, // OS UI 2.0
  V2 = 2, // MUI5 + Stratis
}

export type RadioProps = Omit<Mui5RadioProps, 'icon' | 'checkedIcon'>

export const OsRadio = (props: RadioProps) => {
  const classes = useStyles()
  const version = useComponentVersion('radio')

  switch (version) {
    case OsRadioVersion.V2:
      const mui5props: Mui5RadioProps = {
        ...props,
        className: classnames(classes.v2root, props.className),
        checkedIcon: <span className={`${classes.v2icon} ${classes.v2checkedIcon}`} />,
        icon: <span className={classes.v2icon} />,
        color: props.color || 'default',
      }
      return <Mui5Radio disableRipple {...mui5props} />

    default:
      const { color, ...rest } = props
      const color4: Mui4RadioProps['color'] = color === 'primary' || color === 'secondary' ? color : 'default'
      return (
        <Mui4Radio
          className={classes.root}
          disableRipple
          color={color4}
          checkedIcon={<AdjustIcon className={props.checked ? classes.checkedIcon : ''} />}
          {...rest}
        />
      )
  }
}

const RadioPropDefs: PropsDefs = {
  defaultChecked: { default: false },
  checked: { hidden: true },
  size: { default: 'medium' },
  color: { default: 'default' },
  checkedIcon: { hidden: true },
}
