import lodash from 'lodash'

const _serialiseDeep = (value: any): any => {
  if (value && typeof value === 'object' && value instanceof Error) {
    return {
      '@type': 'Error',
      message: value.message,
      //@ts-ignore
      cause: value.cause,
    }
  }
}

export const serialiseDeep = (obj: any): any => {
  return lodash.cloneDeepWith(obj, _serialiseDeep)
}

const _deserialiseDeep = (value: any): any => {
  // Beware: typeof(null) === 'object'
  if (value && typeof value === 'object' && value['@type'] === 'Error') {
    //@ts-ignore - not sure why the Error typedef is incorrect here
    return new Error(value.message, { cause: value.cause })
  }
}

export const deserialiseDeep = (obj: any): any => {
  return lodash.cloneDeepWith(obj, _deserialiseDeep)
}
