import { useSdkBindings } from './useSdkBindings'
import { useSdkInit } from './useSdkInit'

export const SdkBindings = (props) => {
  useSdkBindings(props)
  return <></>
}

export const SdkMounterIfNested = () => {
  let { sdk, shouldBind } = useSdkInit()
  // We init the SDK always but wait for peers to be added
  // before doing any further work, such as binding to actions/state/etc.
  if (!sdk || !shouldBind) return <></>
  else return <SdkBindings sdk={sdk} />
}
