export function zoomOut() {
  if (window.editor && window.editor.controllers.Camera && window.Designer.controlMode !== 'map') {
    var factor = 1.1
    var undoFactor = 1 / 1.1
    window.editor.execute(new window.ZoomCameraByFactorCommand(factor, undoFactor))
    window.editor.signals.sceneGraphChanged.dispatch()
  } else {
    if (window.MapHelper && window.MapHelper.activeMapInstance && window.MapHelper.activeMapInstance.dom) {
      const value = window.MapHelper.activeMapInstance.dom.getZoom()
      window.editor.execute(new window.SetZoomCommand(value - 0.1, value, window.MapHelper))
      window.editor.signals.sceneGraphChanged.dispatch()
    }
  }
}
