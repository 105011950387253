import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_ACCOUNT_MANAGER, DISTRIBUTOR_DEFAULT_VALUES } from 'pages/ordering/checkoutv3/preOrder/constants'
import usePreOrderService from 'pages/ordering/checkoutv3/preOrder/hooks/usePreOrderService'
import PreOrderPresenter from 'pages/ordering/checkoutv3/preOrder/PreOrderPresenter'
import { AccountManagerType, ContactInfoType, DeliveryAddressInfoType } from 'pages/ordering/checkoutv3/preOrder/types'
import { getSupplierConfig } from 'pages/ordering/configs'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { formatCurrency } from 'util/misc'
import useMapSytemToCheckoutLineItems from './hooks/useMapSytemToCheckoutLineItems'

export const PreOrderPresenterContext = createContext<PreOrderPresenter | undefined>(undefined)
export const SystemContext = createContext<any>(undefined)
export const DistributorDataContext = createContext<any>(undefined)
export const ContactInfoContext = createContext<{ [key: string]: ContactInfoType }>({})
export const DeliveryAddressInfoContext = createContext<{ [key: string]: DeliveryAddressInfoType }>({})
export const AccountManagerContext = createContext<{ [key: string]: AccountManagerType | undefined }>({})
export const TotalItemCountContext = createContext<number>(0)
export const ShowConnectDistributorModalContext = createContext<boolean>(false)

const PreOrderProvider = ({ children }) => {
  const project = useFormState().values
  const systems = window.editor.getSystems()
  const enabledDistributorsEnum = useEnabledHardwareSuppliers()
  const enabledDistributors = enabledDistributorsEnum.map((item) => getSupplierConfig(item)?.filterKey)
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)
  const org = useSelector(orgSelectors.getOrg)
  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }
  const service = usePreOrderService()
  const [distributorData, setDistributorData] = useState<any>({})
  const [contactInfo, setContactInfo] = useState<{ [key: string]: ContactInfoType }>({})
  const [deliveryAddressInfo, setDeliveryAddressInfo] = useState<{ [key: string]: DeliveryAddressInfoType }>({})
  const [accountMangerInfo, setAccountManagerInfo] = useState<{ [key: string]: AccountManagerType | undefined }>(
    DISTRIBUTOR_ACCOUNT_MANAGER
  )
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const [system, setSystem] = useState<any>(systems.length > 0 ? systems[0] : undefined)
  const { checkoutlineItems } = useMapSytemToCheckoutLineItems(systems, system?.uuid)
  const [showConnectDistributorModal, setShowConnectDistributorModal] = useState<boolean>(false)

  useEffect(() => {
    const componentData = {}
    const distributorInfo = {}
    let itemCount = 0
    checkoutlineItems?.forEach((item: OrderLineItem) => {
      const distributor = item.selectedDistributor
      const distributorItems = componentData[distributor] || []
      componentData[distributor] = [...distributorItems, item]

      // process distributor specific info
      // pricing related
      const distributorOrderingInfo = distributorInfo[distributor] || {}
      const beforeDiscountTotal = distributorOrderingInfo['beforeDiscountTotal'] || 0
      const afterDiscountTotal = distributorOrderingInfo['afterDiscountTotal'] || 0
      const totalItems = distributorOrderingInfo['totalItems'] || 0
      const { beforeDiscount, afterDiscount } = item.getBestDiscountOffer()
      distributorInfo[distributor] = {
        beforeDiscountTotal: beforeDiscountTotal + beforeDiscount,
        afterDiscountTotal: afterDiscountTotal + afterDiscount,
        totalItems: totalItems + item.quantity,
      }
      itemCount += item.quantity
    })

    const parsedData = {}
    Object.keys(componentData).forEach(
      (key) =>
        (parsedData[key] = {
          ...distributorInfo[key],
          ...DISTRIBUTOR_DEFAULT_VALUES[key],
          items: componentData[key],
          shippingInfo: {
            ...DISTRIBUTOR_DEFAULT_VALUES[key]['shippingInfo'],
          },
          subtotalPrice: formatCurrency(distributorInfo[key]['afterDiscountTotal']),
          totalPrice: formatCurrency(
            distributorInfo[key]['afterDiscountTotal'] + distributorInfo[key]['afterDiscountTotal'] * 0.2
          ),
          totalTax: formatCurrency(distributorInfo[key]['afterDiscountTotal'] * 0.2),
          projectIds: [project.id],
        })
    )
    setDistributorData(parsedData)
    setTotalItemCount(itemCount)
  }, [checkoutlineItems])

  useEffect(() => {
    const contactData = {}
    const contact: ContactInfoType = {
      name: contactName,
      email: user?.email,
      phoneNumber: role?.phone,
      firstName: role?.first_name,
      lastName: role?.family_name,
      businessName: org?.name
    }
    contactData['general'] = contact

    const deliveryAddressData = {}
    const deliveryAddress: DeliveryAddressInfoType = {
      address: project?.address || '',
      locality: project?.locality || '',
      state: project?.state || '',
      zip: project?.zip || '',
      countryName: project?.country_name || '',
      countryIso2: project?.country_iso2 || '',
    }
    deliveryAddressData['general'] = deliveryAddress
    enabledDistributors.forEach((item) => {
      if (item) {
        contactData[item] = contact
        deliveryAddressData[item] = deliveryAddress
      }
    })
    setContactInfo(contactData)
    setDeliveryAddressInfo(deliveryAddressData)
  }, [])

  const preorderPresenter = useMemo(
    () =>
      new PreOrderPresenter(
        service,
        setSystem,
        setDistributorData,
        setContactInfo,
        setDeliveryAddressInfo,
        setAccountManagerInfo,
        setShowConnectDistributorModal
      ),
    []
  )

  return (
    <SystemContext.Provider value={system}>
      <TotalItemCountContext.Provider value={totalItemCount}>
        <AccountManagerContext.Provider value={accountMangerInfo}>
          <DeliveryAddressInfoContext.Provider value={deliveryAddressInfo}>
            <ContactInfoContext.Provider value={contactInfo}>
              <DistributorDataContext.Provider value={distributorData}>
                <PreOrderPresenterContext.Provider value={preorderPresenter}>
                  <ShowConnectDistributorModalContext.Provider value={showConnectDistributorModal}>
                    {children}
                  </ShowConnectDistributorModalContext.Provider>
                </PreOrderPresenterContext.Provider>
              </DistributorDataContext.Provider>
            </ContactInfoContext.Provider>
          </DeliveryAddressInfoContext.Provider>
        </AccountManagerContext.Provider>
      </TotalItemCountContext.Provider>
    </SystemContext.Provider>
  )
}

export default PreOrderProvider
