import { Divider } from '@material-ui/core'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { celsiusToFahrenheit, getMeasurementsFromState, trimDecimalPlaces } from 'util/misc'
import MouseOverPopover from './MouseOverPopover'
import { SpecsRangeWithValidation, usePopoverStyles } from './MpptDetailPopover'
import {
  calculateAddedTempIncrease,
  getInverterMaxParallelStrings,
  getInverterMpptVoltageRange,
  getInverterVoltageRange,
  getMaxPanelsForInverter,
  getMaxPanelsInSeries,
  getMinMaxTemperature,
  getMinPanelsInSeries,
  getPanelSpecAtTemp,
  inverterOverSizingRatio,
  isValueWithinRange
} from './Utils'

type InverterPropsType = {
  inverter: any
}

export const getTotalPanel = (inverter: any) => {
  if (
    inverter.getSystem()?.inverters()?.length === 1 &&
    inverter.moduleQuantity() === 0 &&
    !inverter.getSystem()?.dcOptimizer()
  ) {
    //use unstrung module quantity if there is only one string inverter (with or without optimisers) AND there is no stringing done,
    return inverter.getSystem()?.moduleQuantity()
  } else {
    return inverter.moduleQuantity()
  }
}

const hasDesignError = (
  mpptQuantity: number,
  isMicroInverter: boolean,
  totalPanel: number,
  maxPanels: number,
  inverterVoltageRange: number[],
  mpptVoltageRange: number[],
  maxPanelVoc: number,
  minPanelVoc: number,
  maxPanelVmp: number,
  minPanelVmp: number
) => {
  if (isMicroInverter) {
    return (
      !(maxPanels >= mpptQuantity) ||
      !isValueWithinRange(inverterVoltageRange, maxPanelVoc) ||
      !isValueWithinRange(inverterVoltageRange, minPanelVoc) ||
      !isValueWithinRange(mpptVoltageRange, maxPanelVmp) ||
      !isValueWithinRange(mpptVoltageRange, minPanelVmp)
    )
  } else {
    return totalPanel > maxPanels
  }
}

const InverterDetailPopover: FC<InverterPropsType> = ({ inverter }) => {
  const translate = useTranslate()
  const [minTemp, maxTemp] = getMinMaxTemperature(window.WorkspaceHelper?.project)
  const isFahrenheit = useSelector((state: any) => getMeasurementsFromState(state) === 'imperial')
  const classes = usePopoverStyles()
  const isMicroInverter = !!inverter.microinverter
  const InverterTotalOutput = inverter.getPanelsPower()
  const totalPanel = getTotalPanel(inverter)
  const inverterData = inverter?.getComponentData()
  const moduleData = inverter.getSystem().moduleType()
  const hasMaxDCPower = inverterData?.max_dc_power !== 0 && inverterData?.max_dc_power
  const system = inverter.getSystem();
  const dispatch = useDispatch()

  if (!inverterData) return <></>

  const iscMax = inverterData?.current_isc_max
  // Calculate operating temperatures dynamically
  const systemHasModules = system.moduleGrids().length
  const maxPanelOperatingTemp = maxTemp + calculateAddedTempIncrease(moduleData.noct, systemHasModules ? system.moduleGrids()[0].getPanelConfiguration() : 'STANDARD')


  // Panel voltage calculations based on calculated operating temperatures
  const maxPanelVoc = getPanelSpecAtTemp(moduleData.voc, moduleData.temp_coefficient_voc, minTemp)
  const maxPanelVmp = getPanelSpecAtTemp(moduleData.max_power_voltage, moduleData.temp_coefficient_voc, minTemp)

  const minPanelVoc = getPanelSpecAtTemp(moduleData.voc, moduleData.temp_coefficient_voc, maxPanelOperatingTemp)
  const minPanelVmp = getPanelSpecAtTemp(
      moduleData.max_power_voltage,
      moduleData.temp_coefficient_voc,
      maxPanelOperatingTemp
  )

  const maxPanelIsc = getPanelSpecAtTemp(moduleData.isc, moduleData.temp_coefficient_isc, maxPanelOperatingTemp)

  const inverterVoltageRange = getInverterVoltageRange(inverterData)
  const mpptVoltageRange = getInverterMpptVoltageRange(inverterData)

  const maxPanelsForInverterOnPowerRating = hasMaxDCPower
    ? Math.floor(inverterData?.max_dc_power / moduleData.kw_stc)
    : Math.floor((inverterData?.max_power_rating * inverterOverSizingRatio(inverterData)) / moduleData.kw_stc)

  const maxPanelsInSeries = getMaxPanelsInSeries(inverterData, maxPanelVoc, maxPanelVmp)
  const minPanelsInSeries = getMinPanelsInSeries(inverterData, minPanelVmp)
  const maxParallelStrings = getInverterMaxParallelStrings(inverterData, maxPanelIsc)

  const maxPanelsForInverter = getMaxPanelsForInverter(
    inverterData,
    maxPanelsInSeries,
    minPanelsInSeries,
    maxParallelStrings === null ? 1 : maxParallelStrings,
    maxPanelsForInverterOnPowerRating
  )

  return (
    <MouseOverPopover
      hasError={hasDesignError(
        inverterData?.mppt_quantity,
        isMicroInverter,
        totalPanel,
        maxPanelsForInverter,
        inverterVoltageRange,
        mpptVoltageRange,
        maxPanelVoc,
        minPanelVoc,
        maxPanelVmp,
        minPanelVmp
      )}
    >
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <span className={classes.heading}>{translate('Inverter')}</span>{' '}
          <span>{trimDecimalPlaces(InverterTotalOutput, 2)}kW</span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('Total Panels')}</span>
          <span className={classes.figure}>{totalPanel}</span>
        </div>
        {!isMicroInverter && (
          <div className={classes.itemRow}>
            <span>{translate('Number of MPPTs')}</span>
            <span className={classes.figure}>{inverter.getComponentData()?.mppt_quantity || '-'}</span>
          </div>
        )}
        <div className={classes.itemRow}>
          <span>{translate('Rated Output Power')}</span>
          <span className={classes.figure}>{inverterData?.max_power_rating} kW</span>
        </div>
        {hasMaxDCPower && (
          <div className={classes.itemRow}>
            <span>{translate('Max DC Power')}</span>
            <span className={classes.figure}>{inverterData?.max_dc_power} kW</span>
          </div>
        )}
        <div className={classes.itemRow}>
          <span>{translate('DC Input Voltage Range')}</span>
          <span className={classes.figure}>
            {inverterVoltageRange[0]} - {inverterVoltageRange[1]} V
          </span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('MPPT Voltage Range')}</span>
          <span className={classes.figure}>
            {mpptVoltageRange[0]} - {mpptVoltageRange[1]} V
          </span>
        </div>
        {iscMax && (
          <div className={classes.itemRow}>
            <span>{translate('Max Short-Circuit Current')}</span>
            <span className={classes.figure}>{iscMax} A</span>
          </div>
        )}

        <Divider className={classes.divider} />
        <div className={classes.headingWrapper}>
          <span className={classes.subHeading}>{translate('Site Detail')}</span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('Maximum Location Temperature')}</span>
          <span className={classes.figure}>
            {isFahrenheit
              ? `${trimDecimalPlaces(celsiusToFahrenheit(maxTemp), 1)}°F`
              : `${trimDecimalPlaces(maxTemp, 1)}°C`}
          </span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('Minimum Location Temperature')}</span>
          <span className={classes.figure}>
            {isFahrenheit
              ? `${trimDecimalPlaces(celsiusToFahrenheit(minTemp), 1)}°F`
              : `${trimDecimalPlaces(minTemp, 1)}°C`}
          </span>
        </div>
        <Divider className={classes.divider} />
        {isMicroInverter ? (
          <div>
            <span className={classes.subHeading}>{translate('Microinverter')}</span>
            <div className={classes.stringItemRow}>
              <span></span>
              <span className={classes.highlightedFigure}>
                {isFahrenheit
                  ? `${trimDecimalPlaces(celsiusToFahrenheit(minTemp), 1)}°F`
                  : `${trimDecimalPlaces(minTemp, 1)}°C`}
              </span>
              <span className={classes.highlightedFigure}>
                {isFahrenheit
                  ? `${trimDecimalPlaces(celsiusToFahrenheit(maxPanelOperatingTemp), 1)}°F`
                  : `${trimDecimalPlaces(maxPanelOperatingTemp, 1)}°C`}
              </span>
            </div>
            <SpecsRangeWithValidation
              unit={'V'}
              label={'Voc'}
              range={inverterVoltageRange}
              lowTempValue={maxPanelVoc}
              highTempValue={minPanelVoc}
            />
            <SpecsRangeWithValidation
              unit={'V'}
              label={'Vmp'}
              range={mpptVoltageRange}
              lowTempValue={maxPanelVmp}
              highTempValue={minPanelVmp}
            />
           <div className={classes.itemRow}>
              <span>
                  {translate('OpenSolar combines local solar irradiance and panel data to determine cell temperatures.')}{' '}
                  <a 
                    href="https://support.opensolar.com/hc/en-us/articles/12035668582543-Voc-and-Vmp-Calculations-in-Inverter-Tool-Tab" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{cursor: "pointer"}}
                    >
                      {translate('Click here')}
                  </a>{' '}
                  {translate('to find out more.')}
              </span>
           </div>
          </div>
        ) : (
          <div>
            <div className={classes.headingWrapper}>
              <span className={classes.heading}>{translate('Design Recommendations')}</span>
            </div>
            <div className={classes.itemRow}>
              <span>{translate('Max panels per Inverter')}</span>
              <span className={classes.figure}>{maxPanelsForInverter}</span>
            </div>
            <div className={classes.itemRow}>
              <span>{translate('Panels per string')}</span>
              <span className={classes.figure}>
                {minPanelsInSeries} - {maxPanelsInSeries}
              </span>
            </div>
            {iscMax && (
              <div className={classes.itemRow}>
                <span>{translate('Max Parallel strings per MPPT')}</span>
                <span className={classes.figure}>
                  {maxParallelStrings === null ? translate('Unknown') : maxParallelStrings}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </MouseOverPopover>
  )
}

export default InverterDetailPopover
