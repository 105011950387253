import { FC } from 'react'

/**
 * * Icon Component for: arrow-curve-left-up
 */
type ArrowCurveLeftUpOutlineIconProps = {
  size?: number
  color?: string
}
export const ArrowCurveLeftUpOutlineIcon: FC<ArrowCurveLeftUpOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M6.91362 1.1998L1.59961 6.51381M1.59961 6.51381L6.91362 11.8278M1.59961 6.51381L14.3996 6.51382C16.6087 6.51382 18.3996 8.30468 18.3996 10.5138L18.3996 16.7998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
