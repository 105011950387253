import { LoadingDots } from '../core/LoadingDots'
import { styled } from '../styles/styled'

type PropTypes = {
  isLoading: boolean
  textColor: string
  label: string
  onClick(): void
}

const StyledLink = styled('a')<{ textColor: string }>(({ theme, textColor }) => {
  return {
    '&:link, &:visited, &:hover, &:active': {
      color: textColor,
      margin: 15,
    },
  }
})

const TransactionActionLink = ({ isLoading, textColor, label, onClick }: PropTypes) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }
  return (
    <p>
      <StyledLink href="#" textColor={textColor} onClick={handleClick}>
        {isLoading ? <LoadingDots color="#a2a2a2" /> : label}
      </StyledLink>
    </p>
  )
}

export default TransactionActionLink
