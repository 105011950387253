import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { ReduxActionType } from 'types/global'

export const GET_PROJECT_VIEWS = 'GET_PROJECT_VIEWS'
export const GET_PROJECT_VIEWS_SUCCESS = 'GET_PROJECT_VIEWS_SUCCESS'
export const GET_PROJECT_VIEWS_FAIL = 'GET_PROJECT_VIEWS_FAIL'
export const ADD_PROJECT_VIEWS = 'ADD_PROJECT_VIEWS'
export const REMOVE_PROJECT_VIEWS = 'REMOVE_PROJECT_VIEWS'
export const UPDATE_PROJECT_VIEWS = 'UPDATE_PROJECT_VIEWS'
export const UPDATE_MULTIPLE_PROJECT_VIEWS = 'UPDATE_MULTIPLE_PROJECT_VIEWS'

export type Field = {
  field: string
}

export type Filter = {
  field: string
  operator: '=' | 'in'
  value: boolean | number[] | number
}

export type ViewSettings = {
  fields_shown: Field[]
  sort: Record<string, unknown>
  filters: Filter[]
}

export enum ViewType {
  Table = 0,
  Kanban = 1,
}

export type ProjectView = {
  id: number | string
  name: string
  view_type: ViewType
  order: number
  filter_output: string | null
  view_settings: ViewSettings
  org: string
  url: string
  view_count: number | null
  not_saved?: boolean
}

export type ProjectListViewStoreType = {
  project_list_views_data: ProjectView[]
  loading: boolean
  ready: boolean
}

const defaultState: ProjectListViewStoreType = {
  project_list_views_data: [],
  loading: false,
  ready: false,
}

export default (state: ProjectListViewStoreType = defaultState, action: ReduxActionType) => {
  switch (action.type) {
    case ADD_PROJECT_VIEWS:
      return {
        ...state,
        project_list_views_data: [...state.project_list_views_data, action.payload.project_list_view],
      }
    case REMOVE_PROJECT_VIEWS:
      return {
        ...state,
        project_list_views_data: state.project_list_views_data.filter(
          (view) => view.id !== action.payload.project_list_view_id
        ),
      }
    case UPDATE_PROJECT_VIEWS:
      return {
        ...state,
        project_list_views_data: state.project_list_views_data.map((view) => {
          if (view.id === action.payload.project_list_view_id) {
            return action.payload.project_list_view
          }
          return view
        }),
      }
    case UPDATE_MULTIPLE_PROJECT_VIEWS:
      const updatedViews = action.payload
      return {
        ...state,
        project_list_views_data: state.project_list_views_data.map((view) => {
          const updatedView = updatedViews.find((v) => v.id === view.id)
          return updatedView ? updatedView : view
        }),
      }
    case GET_PROJECT_VIEWS:
      return {
        ...state,
        loading: true,
        orgId: action.payload.orgId,
      }
    case GET_PROJECT_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        project_list_views_data: action?.payload?.project_list_views_data,
        ready: true,
      }
    case GET_PROJECT_VIEWS_FAIL:
      return {
        ...state,
        loading: false,
        ready: false,
      }
  }
  return state
}

export function* viewsSaga(action: ReduxActionType) {
  try {
    const project_list_views_data = yield call(doGetProjectViews, action.payload.orgId)
    yield put({ type: GET_PROJECT_VIEWS_SUCCESS, payload: { project_list_views_data } })
  } catch (e) {
    yield put({ type: GET_PROJECT_VIEWS_FAIL, key: action.payload.key })
  }
}

export const doGetProjectViews = (orgId) => {
  const API_URL = window.API_ROOT + '/api'
  return new Promise((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/views/`,
    })
      .then((response) => {
        const views = response?.data
        const orderViews = views.sort((a, b) => a.order - b.order)
        resolve(orderViews)
      })
      .catch((err) => {
        console.error('Error getting project list view data', err)
        reject(false)
      })
  })
}

export const loadProjectViews = (orgId) => {
  return {
    type: GET_PROJECT_VIEWS,
    payload: {
      orgId: orgId,
    },
  }
}

export const saveProjectView = (view) => {
  return {
    type: ADD_PROJECT_VIEWS,
    payload: {
      project_list_view: view,
    },
  }
}

export const removeProjectView = (viewId) => {
  return {
    type: REMOVE_PROJECT_VIEWS,
    payload: {
      project_list_view_id: viewId,
    },
  }
}

export const updateProjectView = (view) => {
  return {
    type: UPDATE_PROJECT_VIEWS,
    payload: {
      project_list_view_id: view.id,
      project_list_view: view,
    },
  }
}

export const updateMultipleProjectViews = (views) => ({
  type: UPDATE_MULTIPLE_PROJECT_VIEWS,
  payload: views,
})

export const projectListViewSelectors = {
  getIsLoading: (state: any): boolean => !!state.projectListViews?.loading,
  getIsReady: (state: any): boolean => !!state.projectListViews?.ready,
  getProjectListViewData: (state: any): ProjectView[] => state.projectListViews.project_list_views_data,
  getDefaultViewId: (state: any): number | undefined => {
    const views = projectListViewSelectors.getProjectListViewData(state)
    if (views?.length) {
      let defaultView = views.find((x) => x.name === 'All Projects')
      if (!defaultView) defaultView = views[0]
      return Number(defaultView.id)
    }
  },
  getIsUnsavedViewExist: (state: any): boolean =>
    state.projectListViews.project_list_views_data.some((item) => !!item.not_saved),
  getUnsavedViewIndex: (state: any): number =>
    state.projectListViews.project_list_views_data.findIndex((item) => !!item.not_saved),
}

export function* projectListViewsSagas() {
  yield all([takeEvery(GET_PROJECT_VIEWS, viewsSaga)])
}
