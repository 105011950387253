import { restClientInstance } from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import { getSectionName } from '../utils'

export const saveOnboardingProgress = (
  orgId: number,
  formVals: any,
  fieldPrefixFilter?: string,
  pageNumber?: number
) => {
  const sectionName = getSectionName(fieldPrefixFilter)

  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/bluesnap/onboarding/save_progress/`,
      data: {
        form_vals: formVals,
        page_number: pageNumber,
        section_name: sectionName || '',
      },
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getOwnerCompanyRepData = (normalizedData) => {
  return {
    companyRepFirstName: normalizedData.firstName,
    companyRepLastName: normalizedData.lastName,
    companyRepAddress: normalizedData.address,
    companyRepState: normalizedData.state,
    companyRepCity: normalizedData.city,
    companyRepCountry: normalizedData.country,
    companyRepDateOfBirth: normalizedData.dateOfBirth,
    companyRepGovID: normalizedData.govID,
    companyRepPhone: normalizedData.phone,
    companyRepZip: normalizedData.zip,
  }
}
