import { FC } from 'react'

/**
 * * Icon Component for: arrow-right
 */
type ArrowRightOutlineIconProps = {
  size?: number
  color?: string
}
export const ArrowRightOutlineIcon: FC<ArrowRightOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M10.3333 1L17 8M17 8L10.3333 15M17 8L1 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
