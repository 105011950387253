import { CardMedia as Mui4CardMedia, CardMediaProps as Mui4CardMediaProps } from '@material-ui/core'
import { default as Mui5CardMedia } from '@mui/material/CardMedia'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsCardVersion } from './Card'

export const CardMedia: FC<OsCardMediaProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="CardMedia"
      propDefs={CardMediaProps}
      showExample={true}
      component={OsCardMedia}
      props={{ ...props, ref }}
    />
  )
})

export type OsCardMediaProps = Mui4CardMediaProps

const OsCardMedia: FC<OsCardMediaProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('card')
  switch (version) {
    case OsCardVersion.V2:
      //@ts-ignore
      return <Mui5CardMedia ref={ref} {...props} />

    default:
      return <Mui4CardMedia ref={ref} {...props} />
  }
})

const CardMediaProps: PropsDefs = {}
