/**
 * A compact version of the Mui Stepper component styled for use in integration cards
 */
import { default as Step } from '@material-ui/core/Step'
import { default as StepConnector } from '@material-ui/core/StepConnector'
import { default as StepLabel } from '@material-ui/core/StepLabel'
import { default as Mui4Stepper } from '@material-ui/core/Stepper'
import type { StepIconProps } from '@mui/material/StepIcon'
import { StepperCheckIcon } from '../icons'
import { styled } from '../styles'

const StyledStep = styled(Step)({
  padding: 0,
})

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  right: '100%',
  left: '-100%',
  '& .MuiStepConnector-active .MuiStepConnector-line': {
    backgroundColor: theme.palette.blue[700],
  },
  '& .MuiStepConnector-completed .MuiStepConnector-line': {
    backgroundColor: theme.palette.blue[700],
  },
  '& .MuiStepConnector-line': {
    height: 3,
    borderWidth: 6,
    borderTop: 'none',
    background: theme.palette.grey[200],
  },
}))

const ColorlibStepIconRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{ ownerState: { completed?: boolean; active?: boolean } }>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[50],
  zIndex: 1,
  color: 'white',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState?.active && {
    outline: '1px solid white',
    boxShadow: `0px 0px 0px 3px ${theme.palette.blue[500]}, 0px 0px 0px 4px white`,
    backgroundColor: theme.palette.blue[700],
  }),
  ...(ownerState?.completed && {
    backgroundColor: theme.palette.blue[700],
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props
  const steps = [1, 2, 3]
  const icons: { [index: string]: React.ReactElement } = steps.reduce(
    (acc, step) => ({
      ...acc,
      [step]: <StepperCheckIcon />,
    }),
    {}
  )

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        icons[String(props.icon)]
      ) : (
        <span style={{ color: active ? 'white' : 'black' }}>{String(props.icon)}</span>
      )}
    </ColorlibStepIconRoot>
  )
}

const StyledStepLabel = styled(StepLabel)<{ narrow?: boolean }>(({ narrow }) => ({
  alignItems: 'start !important',
  marginBottom: '8px',

  '& .MuiStepLabel-label': {
    // @ts-ignore
    textAlign: 'start !important',
    marginTop: narrow ? '10px !important' : '16px !important',
  },
}))

const StyledStepper = styled(Mui4Stepper)<{ narrow?: boolean }>(({ theme, narrow }) => ({
  padding: '0',
  paddingTop: narrow ? '8px' : '24px',

  '& .MuiStepIcon-active': {
    color: theme.palette.blue[700],
  },
}))

export type CompactStepType = {
  content: string | JSX.Element
  label: string | JSX.Element
  id: number
}

export type OsCompactStepperProps = {
  steps: CompactStepType[]
  activeStep: number
  narrow?: boolean
}

export const CompactStepper = ({ steps, activeStep, narrow }: OsCompactStepperProps) => {
  return (
    <StyledStepper
      connector={<StyledConnector />}
      orientation="horizontal"
      alternativeLabel
      activeStep={activeStep}
      narrow={narrow}
    >
      {steps.map((step) => (
        <StyledStep key={step.id} active={activeStep === step.id}>
          <StyledStepLabel StepIconComponent={ColorlibStepIcon} narrow={narrow}>
            {step.label}
          </StyledStepLabel>
          {step.content}
        </StyledStep>
      ))}
    </StyledStepper>
  )
}
