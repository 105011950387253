import { Identifier, Pagination, Record, RecordMap, Sort } from '../types'
import useQueryFromStore, { QueryOptions } from './useQueryFromStore'

const defaultSort = { field: 'id', order: 'DESC' }
const defaultPagination = {
  page: 1,
  perPage: 20,
}
const useGetListFromStore = <RecordType = Record>(
  resource: string,
  pagination: Pagination = defaultPagination,
  sort: Sort = defaultSort,
  filter: object,
  options?: QueryOptions
): {
  data?: RecordMap<RecordType>
  ids?: Identifier[]
  total?: number
  error?: any
  loading: boolean
  loaded: boolean
} => {
  const { data, total, error, loading, loaded } = useQueryFromStore(
    { type: 'getList', resource, payload: { pagination, sort, filter } },
    options
  )
  return { data, total, error, loading, loaded }
}

export default useGetListFromStore
