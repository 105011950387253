import { ReapplicationConfirm, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'
export const LOANPAL_OR_GOODLEAP = 'Goodleap'

const LoanpalApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const SendLoanDocs = async () => {
    await onAction({
      elementData,
      proposalData,
      additionalActionData: {
        action: 'requestLoanDocs',
      },
    })
  }

  const handleOnConfirm = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  let buttonLabelText =
    actionData.status_title === 'InitiatedButIncomplete'
      ? translate('Finish Loan Application')
      : translate('Get Pre-Qualified!')

  const extra_actions: React.ReactNode[] = []
  const orgId = proposalData.selectedPaymentOption?.org_id
    ? proposalData.selectedPaymentOption.org_id
    : actionData.org_id

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        Clicking "Get Pre-Qualified!" will take you to {LOANPAL_OR_GOODLEAP}'s credit application. OpenSolar does not
        view or store any of the data you enter in this application except name and email address.
      </p>
    )
  }

  if (actionData.status_title === 'ApprovedPendingDocs') {
    extra_actions.push(
      <p key={extra_actions.length}>
        Loan Approved{' '}
        <a
          onClick={(event) => {
            event.preventDefault()

            SendLoanDocs()
          }}
          // eslint-disable-line
          href="#"
        >
          Send Loan Docs
        </a>
      </p>
    )
  } else if (actionData.status_title === 'Declined') {
    extra_actions.push(
      <div
        key={extra_actions.length}
        style={{ textAlign: 'center', color: proposalData.myeStyles.textColor, margin: '15px 0px 15px 0px' }}
      >
        ✖ Not Approved for Financing
      </div>
    )
  } else if (actionData.status_description) {
    // Other statuses where we simply print the status_description
    // Including: OtherOrUnknown
    extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
  }

  return (
    <>
      {actionData.status_code !== 'complete' && (
        <div>
          <TransactionActionButton
            isLoading={isLoading}
            label={
              <div>
                {buttonLabelText}
                <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
              </div>
            }
            disabled={actionData.status_code !== 'available' || !!options?.loanDecisionRenderedAt}
            onClick={openApplicationDialog}
          />
          {extra_actions}
        </div>
      )}
      {actionData.status_code === 'complete' && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h2>Your Financing Decision</h2>
            <div>{actionData.status_description}</div>
            {actionData.status_title?.includes('Declined') && (
              <ReapplicationConfirm
                highlightColor={proposalData.myeStyles.highlightColor}
                onConfirm={handleOnConfirm}
                loading={isLoading}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default LoanpalApplication
