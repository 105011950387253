import { Grid, Paper } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { Box } from 'opensolar-ui'
import React, { FC, useState } from 'react'
import {
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useInput,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { SECTOR_CHOICES } from '../../constants/sectors'
import useStyle from './styles'

const OneDecimalNumberInput = ({ source, label, ...props }) => {
  const {
    input: { value, onChange },
  } = useInput({ source, ...props })

  const [localValue, setLocalValue] = useState(value ?? '')

  const handleChange = (e) => {
    setLocalValue(e.target.value)
  }

  const handleBlur = () => {
    const floatVal = parseFloat(localValue)
    if (isNaN(floatVal)) {
      onChange(null)
      setLocalValue('')
    } else {
      // Round to 1 decimal
      const rounded = Math.round(floatVal * 10) / 10
      onChange(rounded)
      setLocalValue(rounded.toFixed(1))
    }
  }

  return (
    <NumberInput
      type="number"
      source={source}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      step="0.1"
      {...props}
    />
  )
}

type AutoApplyInputTypes = {
  enableByState?: boolean
  enableByZip?: boolean
  enableByUttility?: boolean
  enableByCountry?: boolean
  enableByComponentCode?: boolean
  enableByMinSystemSize?: boolean
  enableByMaxSystemSize?: boolean
  enableByMinBatterySize?: boolean
  enableByMaxBatterySize?: boolean
  enableBySector?: boolean
  enableByOrder?: boolean // This currently ONLY applies to incentive auto-apply
  enableByPriority?: boolean
  enableByRoofType?: boolean
  enableBySlope?: boolean
  disabled?: boolean
  isAdder?: boolean
  containerComponent?: React.ComponentType
}

const AutoApplyInputs: FC<AutoApplyInputTypes> = ({
  enableByState = true,
  enableByZip = true,
  enableByUttility = false,
  enableByCountry = false,
  enableByComponentCode = true,
  enableByMinSystemSize = true,
  enableByMaxSystemSize = true,
  enableByMinBatterySize = false,
  enableByMaxBatterySize = false,
  enableBySector = true,
  enableByOrder = false,
  enableByPriority = false,
  enableByRoofType = false,
  enableBySlope = false,
  disabled = false,
  isAdder = false,
  containerComponent = Paper,
}) => {
  const translate = useTranslate()
  const classes = useStyle()
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const countryId = useSelector(orgSelectors.getOrgCountryId)

  const Comp = containerComponent

  return (
    <Comp className={classes.paperStyle}>
      <Grid container spacing={3}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <h2>{translate('Auto Apply Settings')}</h2>
          </Grid>
        </Grid>
        {enableByState && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified states'}
              name={'auto_apply_only_specified_states'}
              source="auto_apply_only_specified_states"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByZip && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified ZIP codes'}
              name={'auto_apply_only_specified_zips'}
              source="auto_apply_only_specified_zips"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByUttility && (
          <Grid item xs={12} sm={12} lg={12}>
            <Box display="flex" alignItems="center" width="100%">
              <Box flexGrow={1}>
                <ReferenceArrayInput
                  name="auto_apply_only_specified_utilities"
                  source="auto_apply_only_specified_utilities"
                  reference="utilities"
                  filter={{ country: countryId }}
                  sort={{ field: 'name', order: 'ASC' }}
                  label={translate('Auto Apply') + ' ' + translate('Utility')}
                  fullWidth={true}
                  id="auto_apply_only_specified_utilities"
                  disabled={disabled}
                  onBlur={undefined}
                  onChange={undefined}
                  onFocus={undefined}
                  validate={undefined}
                  parse={(value) => value}
                  format={(value) => value}
                >
                  <ChipsInput optionText="name" optionValue="id" source="auto_apply_only_specified_utilities" />
                </ReferenceArrayInput>
              </Box>
              {enableByZip && (
                <Box ml={1}>
                  <InfoTooltip
                    placement="bottom-start"
                    title={translate(
                      'Specify one or more utility names for automatic adder application. If both utility name and zip code are set, the system will first try to match the utility name. If it matches, the adder will be applied. If not, it will check the zip code and apply the adder if there’s a match.'
                    )}
                    interactive
                  />
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {enableByCountry && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply only in specified countries (iso2)'}
              name={'auto_apply_only_specified_countries'}
              source="auto_apply_only_specified_countries"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByComponentCode && (
          <Grid item xs={12} sm={12} lg={12}>
            <TextInput
              className={classes.defaultFieldStyle}
              label={'Auto apply Component codes'}
              name={'auto_apply_component_codes'}
              source="auto_apply_component_codes"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">{translate('Comma separated values')}</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMinSystemSize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply min system size'}
              name={'auto_apply_min_system_size'}
              source="auto_apply_min_system_size"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kW</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMaxSystemSize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply max system size'}
              name={'auto_apply_max_system_size'}
              source="auto_apply_max_system_size"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kW</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMinBatterySize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply min battery size'}
              name={'auto_apply_min_battery_kwh'}
              source="auto_apply_min_battery_kwh"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByMaxBatterySize && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label={'Auto apply max battery size'}
              name={'auto_apply_max_battery_kwh'}
              source="auto_apply_max_battery_kwh"
              disabled={disabled}
              endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableBySector && (
          <Grid item xs={12} sm={4} lg={3}>
            <SelectInput
              className={classes.defaultFieldStyle}
              label={'Auto apply sector'}
              name={'auto_apply_sector'}
              source="auto_apply_sector"
              disabled={disabled}
              choices={SECTOR_CHOICES}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByPriority && (
          <Grid item xs={12} sm={4} lg={3}>
            <NumberInput
              className={classes.defaultFieldStyle}
              label="Auto apply priority"
              name="auto_apply_priority"
              source="auto_apply_priority"
              disabled={disabled}
              fullWidth={true}
            />
          </Grid>
        )}
        {enableByRoofType && (
          <Grid item xs={12} sm={4} lg={3}>
            <ReferenceInput
              className={classes.defaultFieldStyle}
              source="auto_apply_roof_type"
              name="auto_apply_roof_type"
              reference="roof_types"
              label={translate('Auto Apply') + ' ' + translate('Roof Type')}
              perPage={1000}
              fullWidth={true}
              id="auto_apply_roof_type"
              disabled={disabled}
              allowEmpty={true}
            >
              <SelectInput
                className={classes.defaultFieldStyle}
                optionText={(choice: any) => translate(choice?.name)}
                optionValue="id"
                id="roof_type_select_input"
                source={'roof_type'}
                fullWidth={true}
              />
            </ReferenceInput>
          </Grid>
        )}
        {enableBySlope && (
          <Grid container alignItems="center" spacing={2} style={{ paddingLeft: 12 }}>
            <Grid item xs={12} sm={4} lg={3}>
              <OneDecimalNumberInput
                className={classes.defaultFieldStyle}
                label={'Auto apply min slope'}
                name={'auto_apply_min_slope'}
                source="auto_apply_min_slope"
                disabled={disabled}
                endAdornment={<InputAdornment position="end">{'\u00B0'}</InputAdornment>}
                fullWidth
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <OneDecimalNumberInput
                className={classes.defaultFieldStyle}
                label={'Auto apply max slope'}
                name={'auto_apply_max_slope'}
                source="auto_apply_max_slope"
                disabled={disabled}
                endAdornment={<InputAdornment position="end">{'\u00B0'}</InputAdornment>}
                fullWidth
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item>
              <InfoTooltip
                placement="bottom-start"
                title={translate(
                  'Automatically apply adders based on roof slope. Define a minimum and maximum slope (degrees) for application. If maximum is blank, the adder applies if the slope is greater than or equal to the minimum. Adders are applied per panel group; thus, a system may receive multiple adders if multiple groups meet the criteria.'
                )}
                interactive
              />
            </Grid>
          </Grid>
        )}
        {enableByOrder && (
          <Grid item xs={12} sm={4} lg={3}>
            <SelectInput
              className={classes.defaultFieldStyle}
              name={'order'}
              source="order"
              choices={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                return { id: value, name: value }
              })}
              disabled={disabled}
              fullWidth={true}
            />
          </Grid>
        )}
        {isAdder && isAdmin && (
          <Grid item xs={12} sm={12} lg={12}>
            <CustomField
              style={{ width: '100%' }}
              name="only_admin_can_remove"
              label="Only Admins can remove"
              defaultValue={false}
              component={BooleanInput}
            />
          </Grid>
        )}
      </Grid>
    </Comp>
  )
}

export default AutoApplyInputs
