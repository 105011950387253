import { DialogActions as Mui4DialogActions, DialogActionsProps as Mui4DialogActionsProps } from '@material-ui/core'
import { default as Mui5DialogActions } from '@mui/material/DialogActions'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsDialogVersion } from './Dialog'

export const DialogActions: FC<OsDialogActionsProps> = (props) => {
  return (
    <Inspector
      name="DialogActions"
      propDefs={DialogActionsProps}
      showExample={true}
      component={OsDialogActions}
      props={props}
    />
  )
}

export type OsDialogActionsProps = Mui4DialogActionsProps

const OsDialogActions: FC<OsDialogActionsProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('dialog')
  switch (version) {
    case OsDialogVersion.V2:
      return <Mui5DialogActions ref={ref} {...props} />

    default:
      return <Mui4DialogActions ref={ref} {...props} />
  }
})

const DialogActionsProps: PropsDefs = {}
