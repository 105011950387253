import { FoundStyleValue } from './types'

let styleCache: Record<string, FoundStyleValue[]> = {}
let styleSheetsLength = 0

const fillStyleCache = () => {
  var styleSheets = window.document.styleSheets
  if (styleSheetsLength === styleSheets.length) return

  styleCache = {}
  styleSheetsLength = styleSheets.length

  for (var i = 0; i < styleSheetsLength; i++) {
    try {
      var classes = styleSheets[i].rules || styleSheets[i].cssRules
    } catch (e) {
      // Stylesheets from other domains are not accessible, skip them
      continue
    }
    if (!classes) continue

    var classesLength = classes.length
    for (var x = 0; x < classesLength; x++) {
      const cssRule = classes[x] as CSSStyleRule
      if (!cssRule.selectorText) continue
      const styles = cssRule.selectorText.split(',').map((s) => s.trim())
      for (const styleName of styles) {
        // if (!styleCache[styleName]) styleCache[styleName] = []
        // styleCache[styleName].push({
        //   selectorFull: cssRule.selectorText,
        //   selectorMatched: styleName,
        //   values: cssRule.style,
        // })

        const partials = styleName.split(/[> ]|(\b(?=\.))/).map((s) => (s?.trim ? s.trim() : s))
        // if (partials.length === 1) continue
        for (const styleName of partials) {
          if (!styleName) continue
          if (!styleCache[styleName]) styleCache[styleName] = []
          styleCache[styleName].push({
            selectorFull: cssRule.selectorText,
            selectorMatched: styleName,
            values: cssRule.style,
          })
        }
      }
    }
  }
}

export function getStyleDefinition(className: string): FoundStyleValue[] | undefined {
  fillStyleCache()
  return styleCache[className]
}
