import { FC } from 'react'

/**
 * * Icon Component for: search-01
 */
type SearchOutlineIconProps = {
  size?: number
  color?: string
}
export const SearchOutlineIcon: FC<SearchOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M14.9265 15.0401L18.3996 18.4001M17.2796 9.4401C17.2796 13.77 13.7695 17.2801 9.43961 17.2801C5.1097 17.2801 1.59961 13.77 1.59961 9.4401C1.59961 5.11019 5.1097 1.6001 9.43961 1.6001C13.7695 1.6001 17.2796 5.11019 17.2796 9.4401Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
