import { FC } from 'react'

/**
 * * Icon Component for: check-broken
 */
type CheckBrokenOutlineIconProps = {
  size?: number
  color?: string
}
export const CheckBrokenOutlineIcon: FC<CheckBrokenOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C11.4121 1 12.7482 1.32519 13.9375 1.90476M17.3125 4.375L9.4375 12.25L7.1875 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
