import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import inflection from 'inflection'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Transfer } from 'resources/inventoryTransfer/type'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import PricingSourceSelector from '../components/PricingSourceSelector'
import UnitCostField from '../components/UnitCostField'
import { BasicInsight, TableColumn } from '../type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  table: {
    tableLayout: 'fixed',
  },
  tableHeader: {
    backgroundColor: theme.greyLight3,
    height: '48px',
  },
  tableHeaderCell: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.subtitle1.fontSize,
    padding: '8px 20px',
  },
  tableHeaderCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    fontSize: theme.typography.subtitle2.fontSize,
    padding: '8px 20px',
  },
  tableFooter: {
    backgroundColor: theme.greyLight3,
    height: '48px',
  },
  tableFooterCell: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.subtitle1.fontSize,
  },
}))

type InsightsFlexibleTableProps = {
  isTouched: boolean
  columns: TableColumn[]
  data?: BasicInsight[] | Transfer[]
  action: (params: { code: string; field: string; value: number }) => void
  showFooter?: boolean
}

const FooterCellRenderer = ({ column, totals }) => {
  switch (column.id) {
    case 'component_type':
      return column.label
    case 'total_value':
      return `${totals.currencySymbol}${formatCurrency(totals.totalValue)}`
    case 'on_hand':
      return totals.onHand.toLocaleString()
    default:
      return null
  }
}

const CellRenderer = ({ row, column, action, isTouched, currencySymbol }) => {
  switch (column.type) {
    case 'selectInput':
      return <PricingSourceSelector row={row} column={column} />
    case 'dynamic':
      return <UnitCostField row={row} column={column} action={action} isTouched={isTouched} />
    default:
      if (['module', 'inverter', 'battery', 'other'].includes(row[column.id])) {
        return inflection.capitalize(row[column.id])
      }
      if (column.id === 'total_value') {
        const value = parseFloat(row[column.id])
        return `${currencySymbol} ${formatCurrency(value)}`
      }
      return row[column.id]
  }
}

const FooterTitle = { id: 'component_type', label: 'Total', type: 'text' }

const InsightsFlexibleTable: React.FC<InsightsFlexibleTableProps> = ({
  columns,
  isTouched,
  data,
  action,
  showFooter,
}) => {
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])

  const totals = useMemo(() => {
    let onHand = 0,
      totalValue = 0
    data?.forEach((row) => {
      onHand += Number(row.on_hand) || 0
      totalValue += parseFloat(String(row.total_value).replace(/[^0-9.-]+/g, '')) || 0
    })
    return { onHand, totalValue, currencySymbol }
  }, [data, currencySymbol])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} className={classes.tableHeaderCell} style={{ width: column?.width }}>
                <div className={classes.tableHeaderCellContent}>
                  {column.label} {column.msg && <InfoTooltip title={column?.msg} severity="info" />}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell key={column.id} className={classes.tableCell} style={{ width: column?.width }}>
                  <CellRenderer
                    row={row}
                    column={column}
                    action={action}
                    isTouched={isTouched}
                    currencySymbol={currencySymbol}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {showFooter && (
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              {columns
                .map((column) => (column.id !== FooterTitle.id ? column : FooterTitle))
                .map((column) => {
                  return (
                    <TableCell key={column.id} className={classes.tableFooterCell}>
                      <FooterCellRenderer column={column} totals={totals} />
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export default InsightsFlexibleTable
