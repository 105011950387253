import { LinearProgress } from '@material-ui/core'
import { Component } from 'react'
import withStudioSignals from 'Studio/signals/withStudioSignals'

class ProgressOverlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      progress: 0,
    }
  }

  componentDidMount() {
    this.props.useStudioSignals(
      (visible, progress) => {
        this.setState({
          ...this.state,
          visible,
          progress,
        })
      },
      ['loadHelperShowing'],
      this
    )
  }

  render() {
    return (
      <div
        id="ProgressOverlay"
        style={{
          display: this.state.visible === true ? '' : 'none',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1500,
          backgroundColor: 'rgba(255,255,255,0.8)',
          pointerEvents: 'none',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={this.state.progress}
          style={{
            position: 'absolute',
            width: 300,
            top: '50%',
            left: 'calc(50% - 150px)',
          }}
        />
      </div>
    )
  }
}

export default withStudioSignals(ProgressOverlay)
