import { Collapse, Grid, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { orgSelectors } from 'ducks/orgs'
import { getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import { DistributorBreakdownProps } from './BomDrawerFooter'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    marginTop: 16,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 550,
    lineHeight: '20px',
  },
  expand: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  info: {
    color: theme.palette.grey[1000],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: 4,
  },
  discount: {
    marginTop: 4,
    color: theme.palette.success.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: '20px',
  },
}))

const DistributorBreakdown = ({
  supplier,
  cost,
  tradeDiscountValue,
  cartDiscount,
  totalItemQty,
  overallTotal,
}: DistributorBreakdownProps) => {
  const classes = useStyles()
  const [showBreakdown, setShowBreakdown] = useState(false)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  return (
    <Grid xs={12} style={{ cursor: 'pointer' }}>
      <div className={classes.container} onClick={() => setShowBreakdown(!showBreakdown)}>
        <Grid container direction="row" justify="space-between">
          <Typography className={classes.heading}>{`${supplier?.name} (${totalItemQty} items)`}</Typography>
          <div className={classes.expand}>
            <Typography className={classes.heading}>{`${formatCurrencyWithSymbol(
              overallTotal,
              currencySymbol
            )}`}</Typography>
            <ExpandMoreIcon />
          </div>
        </Grid>
      </div>
      <Collapse in={showBreakdown}>
        <Grid>
          <Grid container direction="row" justify="space-between">
            <Typography className={classes.info}>Subtotal (ex. VAT)</Typography>
            <Typography className={classes.info}>{`${formatCurrencyWithSymbol(cost, currencySymbol)}`}</Typography>
          </Grid>
          {tradeDiscountValue > 0 && (
            <Grid container direction="row" justify="space-between">
              <Typography className={classes.discount}>{`Trade Discount (incl. in Subtotal)`}</Typography>
              <Typography className={classes.discount}>{`- ${formatCurrencyWithSymbol(
                tradeDiscountValue,
                currencySymbol
              )}`}</Typography>
            </Grid>
          )}
          {cartDiscount && cartDiscount.eligible && (
            <Grid container direction="row" justify="space-between">
              <Typography className={classes.discount}>{`${cartDiscount.discountName}`}</Typography>
              <Typography className={classes.discount}>{`- ${formatCurrencyWithSymbol(
                getCartDiscountValue(cost, cartDiscount),
                currencySymbol
              )}`}</Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default DistributorBreakdown
