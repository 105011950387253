import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

/**
 * * Icon Component for: plus-02-filled
 */

const PlusIconSvg: FC<IconProps> = (props) => {
  const { fill, ...rest } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C8.82843 0.5 9.5 1.17157 9.5 2L9.5 6.5H14C14.8284 6.5 15.5 7.17157 15.5 8C15.5 8.82843 14.8284 9.5 14 9.5H9.5V14C9.5 14.8284 8.82843 15.5 8 15.5C7.17157 15.5 6.5 14.8284 6.5 14L6.5 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5H6.5L6.5 2C6.5 1.17157 7.17157 0.5 8 0.5Z"
        fill={fill}
      />
    </svg>
  )
}

export const PlusIcon = createStandardIcon(PlusIconSvg)
