import { FC } from 'react'

/**
 * * Icon Component for: alert-triangle
 */
type AlertTriangleOutlineIconProps = {
  size?: number
  color?: string
}
export const AlertTriangleOutlineIcon: FC<AlertTriangleOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M10 9.90003V5.41447M10 13.2248V13.2642M15.6699 17H4.33007C2.7811 17 1.47392 15.9763 1.06265 14.5757C0.887092 13.9778 1.10281 13.3551 1.43276 12.8249L7.10269 2.60102C8.4311 0.466323 11.5689 0.466326 12.8973 2.60103L18.5672 12.8249C18.8972 13.3551 19.1129 13.9778 18.9373 14.5757C18.5261 15.9763 17.2189 17 15.6699 17Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
