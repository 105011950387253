import { countryMapping } from 'constants/countries'

const formatManageValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  if (submitValues.contacts_data) {
    //UI 3 flow
    submitValues['contacts'] = formValues.contacts_data?.filter((x) => x.url).map((x) => x.url)
    submitValues['contacts_new'] = formValues.contacts_data.map((x) => {
      let prefix = ''
      const countryData = countryMapping[x?.custom_data?.os_meta?.phone_country_iso2]
      if (countryData) prefix = countryData.prefix || ''
      const formattedPrefix = prefix ? `(${prefix})` : ''
      const regex = new RegExp(`^(?:${formattedPrefix})+`) // This regex matches one or more occurrences of the formatted prefix at the start of the string
      let phone = x?.phone || ''
      phone.replace(regex, formattedPrefix)
      // Add the prefix if it’s not already there
      if (formattedPrefix && !phone.startsWith(formattedPrefix)) {
        phone = formattedPrefix + phone
      }
      return {
        id: x.id,
        email: x.email,
        phone: phone,
        first_name: x.first_name,
        family_name: x.family_name,
        middle_name: x.middle_name,
        gender: x.gender,
        passport_number: x.passport_number,
        licence_number: x.licence_number,
        date_of_birth: x.date_of_birth,
        custom_contact_info_1: x.custom_contact_info_1,
        custom_contact_info_2: x.custom_contact_info_2,
        custom_data: x.custom_data,
      }
    })
    delete submitValues['contacts_data']
  } else if (submitValues.contacts_new) {
    //UX 2 flow
    submitValues['contacts_new'] = [...(formValues.contacts_data || []), ...submitValues.contacts_new]
  }
  if (submitValues.internalTeam) {
    delete submitValues['internalTeam']
  }

  if (submitValues.active_stage_id) {
    //ensure project_sold/installed values are included to prevent BE auto logic
    submitValues['project_sold'] = formValues.project_sold
    submitValues['project_installed'] = formValues.project_installed
  }

  return submitValues
}

export default formatManageValues
