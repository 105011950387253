import { CheckOutlineIcon, ReapplicationConfirm, TransactionActionButton } from 'opensolar-ui'
import { useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const SunlightApplicationAction = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { actionData } = elementData

  const handleOnConfirm = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const openDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const handleRequestLoanDocs = async () => {
    setIsLoading(true)
    await onAction({
      elementData,
      proposalData,
      additionalActionData: {
        action: 'requestLoanDocs',
      },
    })
    setIsLoading(false)
  }

  if (actionData.payment_method === 'sunlight_loan_application') {
    let buttonLabelText =
      actionData.status_title === 'InitiatedButIncomplete'
        ? translate('Finish Loan Application')
        : translate('Apply Now')

    const extra_actions: React.ReactNode[] = []

    if (actionData.status_code === 'available') {
      extra_actions.push(
        <p key={extra_actions.length} className="small">
          {'Clicking "' +
            buttonLabelText +
            '" or "Get Pre-Qualified Instantly"' +
            " will take you to Sunlight Financial's credit application. OpenSolar does not view or store any of the data you enter in this application."}
        </p>
      )
    }

    if (['ApprovedPendingDocs', 'Pending Loan Docs'].includes(actionData.status_title)) {
      extra_actions.push(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <CheckOutlineIcon color="rgba(55, 169, 46, 0.9)" />
            <h2>Approved</h2>
          </div>
          <div style={{ marginBottom: '10px' }}>You have been approved for financing!</div>
        </div>
      )

      extra_actions.push(
        <TransactionActionButton
          isLoading={false}
          label={
            <div>
              <div>Request Loan Docs</div>
              <div style={{ fontSize: '10px' }}>(can take up to 2 minutes to send)</div>
            </div>
          }
          disabled={false}
          onClick={handleRequestLoanDocs}
        />
      )
    } else if (actionData.status_title === 'Credit Declined') {
      extra_actions.push(
        <div
          key={extra_actions.length}
          style={{ textAlign: 'center', color: proposalData.myeStyles.textColor, margin: '15px 0px 15px 0px' }}
        >
          Your previous application was not approved for financing
        </div>
      )
    } else if (actionData.status_description) {
      // Other statuses where we simply print the status_description
      // Including: OtherOrUnknown
      extra_actions.push(<p key={extra_actions.length}>{actionData.status_description}</p>)
    }

    if (actionData.status_code === 'complete' && extra_actions) return <>{extra_actions}</>
    else
      return (
        <>
          {actionData.status_code === 'available' && (
            <div>
              <TransactionActionButton
                isLoading={isLoading}
                label={
                  <div>
                    {buttonLabelText}
                    <div style={{ fontSize: 11 }}>(Takes less than 5 minutes)</div>
                  </div>
                }
                disabled={false}
                onClick={openDialog}
              />
              {extra_actions}
            </div>
          )}
          {actionData.status_title === 'Credit Declined' &&
            actionData.payment_method === 'sunlight_loan_application' && (
              <ReapplicationConfirm
                highlightColor={proposalData.myeStyles.highlightColor}
                onConfirm={handleOnConfirm}
                loading={isLoading}
                extraMessage={'Your previous application was not approved for financing.'}
              />
            )}
          {['disabled', 'complete'].includes(actionData.status_code) &&
            actionData.status_title !== 'Credit Declined' &&
            extra_actions?.length > 0 && <div>{extra_actions}</div>}
        </>
      )
  }

  return <></>
}

export default SunlightApplicationAction
