import { Typography } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import InsightsFlexibleTable from 'pages/inventory/insights/table/InsightsFlexibleTable'
import { TableColumn } from 'pages/inventory/insights/type'
import { getSupplierName } from '../../util'

const Wrapper = styled('div')({
  margin: 4,
  '& > div': {
    paddingTop: 20,
    paddingBottom: 20,
  },
  '& > div:first-child': {
    paddingTop: 0,
    paddingBottom: 20,
  },
  '& > div:last-child': {
    paddingBottom: 0,
    paddingTop: 20,
  },
})

const Title = styled(Typography)({
  paddingBottom: 20,
})

const OrderParts = ({ orderData }) => {
  const partsOrderedColumns: TableColumn[] = [
    { id: 'code', label: 'Item', type: 'text', width: '50%' },
    { id: 'component_type', label: 'Category', type: 'text', width: '25%' },
    { id: 'quantity', label: 'Quantity', type: 'text', width: '25%' },
  ]

  return (
    <Wrapper>
      {orderData
        ?.sort((a, b) => a.order_id - b.order_id)
        .map((order, i) => {
          const orderNumber = order.order_number_display || order.order_number || order.order_id
          return (
            <div key={i}>
              <Title variant="subtitle1">
                {`Order ${order.order_id} (${getSupplierName(order)} ID ${orderNumber})`}
              </Title>
              <InsightsFlexibleTable
                columns={partsOrderedColumns}
                isTouched={false}
                data={order.line_items}
                action={() => {}}
              />
            </div>
          )
        })}
    </Wrapper>
  )
}

export default OrderParts
