import { makeStyles } from '@material-ui/core'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useUxVersion } from 'hooks/useUxVersion'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import { memo } from 'react'
import { SaveButton } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { ActionType, EventType } from 'types/tracking'

const useStyles = makeStyles({
  buttonRight: {
    margin: '10px 0px 10px 10px !important',
  },
  buttonMiddle: {
    // margin: 10,
    margin: 0,
    minWidth: 'unset',
    marginLeft: 5,
  },
})

export default memo(
  (props: {
    submitting?: boolean
    size?: 'small' | 'medium' | 'large'
    style?: object
    label?: string
    disabled?: boolean
  }) => {
    const form = useForm()
    const submitting = props.hasOwnProperty('submitting') ? props.submitting : form.getState().submitting
    const projectId = useSelector((state: any) => state.projectId)
    const classes = useStyles()
    const ui3Enabled = useUxVersion() === 3

    const buttonStyle = props.style || { marginRight: 0 }

    const project = useFormState().values
    const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
    const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
    const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)
    const { trackEvent } = useTrackComponent({
      componentKey: 'save_button',
      eventName: 'User Clicked On Project Save Button',
    })

    return (
      <SaveButton
        label={props.label || 'Save'}
        submitOnEnter={false}
        id={'save-project-button'}
        saving={submitting}
        size={props.size}
        style={buttonStyle}
        className={classes.buttonMiddle}
        handleSubmitWithRedirect={form.submit}
        disabled={props.disabled}
        icon={ui3Enabled ? <></> : undefined}
        onClick={() => trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK })}
        //@ts-ignore
        onSave={(values: ProjectType, redirect: string, form: any, handleSubmit: Function) => {
          const redirectOverride = projectId === 'new' ? 'info' : redirect
          return handleSubmit(values, redirectOverride, form)
            .then(() => {
              // This handler looks unimportant, but it isn't!
              // it changes the response from `handleSubmit` to undefined
              // without this, final-form assumes the response is a payload of submit errors
            })
            .catch((e) => {
              console.error('Unable to Save Project: ', e)
            })
        }}
      />
    )
  }
)
