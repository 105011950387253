import { styled } from '../../styles/styled'

const MessageWrapper = styled('div')<{ textColor: string }>(({ theme, textColor }) => {
  return {
    color: textColor,
    margin: 0,
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'center',
      margin: '15px 0px 15px 0px',
    },
  }
})

export const PreQualifiedMessage = ({ textColor }: { textColor: string }) => {
  return <MessageWrapper textColor={textColor}>✓ You're Pre-Qualified!</MessageWrapper>
}
