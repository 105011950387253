import { styled } from 'opensolar-ui'
import { FC, useEffect, useMemo, useState } from 'react'
import { http } from '../../services/http'
import { BlogConfig } from '../expoStand'
import { formatDate } from '../utils'
import ContentSlider from './common/ContentSlider'
import { CardWrapper, SectionWrapper } from './common/styles'
import DefaultThumbnail from './default_thumbnail.jpg'

interface PropTypes {
  blogs: BlogConfig[]
}

const Blogs: FC<PropTypes> = ({ blogs }) => {
  const [blogsLoaded, setBlogsLoaded] = useState<BlogConfig[]>([])
  useEffect(() => {
    //add in blogs that already have loaded metadata
    const blogsLoaded = blogs.filter((x) => x.url && x.title && x.image)
    const blogsToLoad = blogs.filter((x) => x.url && x.title === undefined).map((x) => x.url)
    if (blogsToLoad.length) {
      http.os
        .post(blogsToLoad, { url: 'partners/url_metadata/' })
        .then((res) => {
          if (res.data) setBlogsLoaded([...blogsLoaded, ...res.data])
        })
        .catch((err) => {
          setBlogsLoaded(blogsLoaded)
          console.error('Failed to retrieve blog data: ', err)
        })
    } else {
      setBlogsLoaded(blogsLoaded)
    }
  }, [blogs])
  if (!blogsLoaded.length) return null
  return (
    <SectionWrapper>
      <ContentSlider
        cards={blogsLoaded}
        header={'Blogs'}
        render={(blog, i) => <BlogCard {...blog} key={'blog-' + i} />}
      />
    </SectionWrapper>
  )
}

const Content = styled('div', { name: 'Content' })({
  lineHeight: '20px',
  maxWidth: 256,
  minHeight: 75,
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: 3,
    webkitBoxOrient: 'vertical',
  },
})

const Thumbnail = styled('img', { name: 'Thumbnail' })({
  height: 150,
  width: 265,
})

const Subtext = styled('span', { name: 'Subtext' })({
  color: '#4F4D55',
  fontSize: 12,
})

const BlogCard: FC<BlogConfig> = ({ url, image, title, author, publish_date }) => {
  const imgDisplay = useMemo(() => image || DefaultThumbnail, [image])
  const formattedDate = useMemo(() => formatDate(publish_date), [publish_date])
  return (
    <CardWrapper
      onClick={() => {
        window.open(url, '_blank')?.focus()
      }}
    >
      <Thumbnail src={imgDisplay} />
      <Content>
        <p>
          <strong>{title || url}</strong>
        </p>
        <Subtext>
          {author} {formattedDate ? `● ${formattedDate}` : ''}
        </Subtext>
      </Content>
    </CardWrapper>
  )
}

export default Blogs
