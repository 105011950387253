import { useCallback } from 'react'
import { HeatPumpType } from 'types/components'

type BaseArgs = {
  activeComponentsData?: OtherType[]
  heatPumpComponentData: HeatPumpType
}

const getMatchingComponent = (key: string, value: number | string, activeComponentsData: OtherType[]) => {
  const matchingComponent = activeComponentsData.find((component) => component[key] === value)
  return matchingComponent
}

const getMatchingComponentFromList = (key: string, value: number | string, activeComponentsData: OtherType[]) => {
  let matchingComponent
  activeComponentsData.forEach((component) => {
    if (component[key]?.includes(value)) {
      matchingComponent = component
    }
  })
  return matchingComponent
}

// Matches the imported component to an existing database component if possible
// The following logic is used to match in order:
// 1. Check against AHRI reference number
// 2. Check against MCS certificate number
// 3. Use a generic heat pump component
const useMatchImportedComponentToActiveComponents = () => {
  return useCallback(({ activeComponentsData, heatPumpComponentData }: BaseArgs) => {
    if (!activeComponentsData || !heatPumpComponentData) {
      return
    }

    const { ahri_reference_numbers, mcs_certificate_number } = heatPumpComponentData
    // heatPumpComponentData should only contain one ahri_reference_number
    const ahri_reference_number = ahri_reference_numbers?.[0]

    let matchingComponent: HeatPumpType | undefined

    if (ahri_reference_number) {
      matchingComponent = getMatchingComponentFromList(
        'ahri_reference_numbers',
        ahri_reference_number,
        activeComponentsData
      )
    }

    if (!matchingComponent && mcs_certificate_number) {
      matchingComponent = getMatchingComponent('mcs_certificate_number', mcs_certificate_number, activeComponentsData)
    }

    // TODO: Change to default to "imported heat pump component"
    return (
      matchingComponent || (window.AccountHelper.getComponentActivationFromCode('FTXM42A', 'other') as HeatPumpType)
    )
  }, [])
}

export default useMatchImportedComponentToActiveComponents
