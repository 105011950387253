import { Dialog, DialogActions, DialogContent, Tooltip, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import { ItemTableRow } from 'myenergy/elements/PaymentOptions'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { QuotationLineType } from 'types/quotationLines'
import { Theme } from 'types/themes'
import IntegratedChip from '../IntegratedChip'
import { useGetIntegration } from '../hooks'
import { CalculatedPaymentOptionMessageType } from '../paymentOptionSelection/types'
import CardActions from './cardActions'

type PropTypes = {
  title: string
  quotationLines: QuotationLineType[]
  integration: string | undefined | null
  isCalculating: boolean
  paymentOptionId: number
  systemUuid: string | null
  mostUrgentMessage: CalculatedPaymentOptionMessageType | null
  isGhost?: boolean
}

type LinesBySectionType = {
  [key: number]: QuotationLineType[]
}

const SECTION_NUMBER_TO_TITLE = {
  2: 'Additional Incentives',
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  logoWrapper: {
    marginBottom: '10px',
  },
  pmtTitleText: {
    fontWeight: 200,
    fontSize: '18px',
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  tableBody: {
    marginBottom: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableTitle: {
    fontWeight: 900,
  },
  row: {
    marginTop: '10px',
  },
  link: { cursor: 'pointer', color: 'rgb(62, 142, 332)', textDecoration: 'underline' },
}))

const SeeDetails: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [logoURL, setLogoURL] = useState<string | undefined>(undefined)
  const [linesBySection, setLinesBySection] = useState<LinesBySectionType>({})

  const integration = useGetIntegration(props.integration)
  const classes = useStyles()
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (props.quotationLines) {
      let bySection: LinesBySectionType = {}
      props.quotationLines?.forEach((line) => {
        let section = line.section || 0
        if (!bySection[section]) bySection[section] = []
        bySection[section].push(line)
      })
      setLinesBySection(bySection)
    }
  }, [props.quotationLines])

  useEffect(() => {
    if (integration) {
      if (integration.logo_large_contents) setLogoURL(integration.logo_large_contents)
      else if (LENDER_LOGO_PATHS[integration.integration_name])
        setLogoURL(`${window.PUBLIC_URL}/images/${LENDER_LOGO_PATHS[integration.integration_name]?.large}`)
    }
  }, [integration])

  const onClick = () => {
    if (props.isCalculating || props.isGhost) {
      return
    } else {
      setShowDialog(true)
      logAmplitudeEvent('payments_page_see_details_clicked', { integration: props.integration })
    }
  }
  const closeDialog = () => {
    setShowDialog(false)
  }

  return (
    <>
      <div className={classes.row} onClick={onClick} data-testid="see-details-link">
        {props.isCalculating ? (
          <Tooltip title={translate('Please wait until calculations are complete')}>
            <div className={classes.link}>{translate('See details')}</div>
          </Tooltip>
        ) : (
          <Tooltip
            title={translate('Open a preview of the detailed Quotation Table for this system and payment option')}
            disableHoverListener={!props.isGhost}
          >
            <div className={classes.link}>{translate('See details')}</div>
          </Tooltip>
        )}
      </div>
      {showDialog && (
        <Dialog open={true} onClose={closeDialog} fullScreen={isMobile} fullWidth={isMobile}>
          <DialogActions>
            <div>
              <CloseDialogButton onClose={closeDialog} />
            </div>
          </DialogActions>
          <DialogContent>
            <div>
              <div className={classes.titleWrapper}>
                {logoURL && (
                  <div className={classes.logoWrapper}>
                    <img src={logoURL} height="40px" />
                  </div>
                )}
                <div className={classes.pmtTitleText}>
                  {translate('Quotation %{pmt_title}', { pmt_title: props.title })}
                </div>
                {props.integration && (
                  <div>
                    <IntegratedChip />
                  </div>
                )}
                {props.integration && (
                  <div className={classes.buttonsRow}>
                    <CardActions
                      integration={props.integration}
                      paymentOptionId={props.paymentOptionId}
                      systemUuid={props.systemUuid}
                      paymentOptionTitle={props.title}
                      isInDialog={true}
                      mostUrgentMessage={null}
                    />
                  </div>
                )}
              </div>

              {Object.keys(linesBySection)?.map((sectionNumber) => (
                <div className={classes.tableBody}>
                  {SECTION_NUMBER_TO_TITLE[sectionNumber] && (
                    <div className={classes.tableTitle}>{translate(SECTION_NUMBER_TO_TITLE[sectionNumber])}</div>
                  )}
                  <table className={sectionNumber === '2' ? 'standard' : 'transaction-details'}>
                    <tbody key={sectionNumber}>
                      {linesBySection[sectionNumber]?.map((row, i) => (
                        <ItemTableRow key={i} data={row} />
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </>
  )
}
export default SeeDetails
