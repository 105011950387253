// @ts-nocheck
import { Divider, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { Button, Checkbox } from 'opensolar-ui'
import DialogEditor from 'projectSections/elements/DialogEditor'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { financingIntegrations } from 'resources/paymentOptions/financialIntegrations'
import FinancialIntegration from 'resources/paymentOptions/financialIntegrations/FinancialIntegration'
import { getOrgCountryIso2FromState, getOrgFromState } from 'util/org'
import useTranslateParse from '../../../util/useTranslateParse'
import { TOUR_Z_INDEX } from '../tools/common'
import { ToolTipPropsType, TourConfigType } from '../types'
import DefaultTooltip from './DefaultToolTip'
import AddRole from './form/AddRole'
import EditPersonalDetail from './form/EditPersonalDetail'
import EnterBusinessInfo from './form/EnterBusinessInfo'
import UploadLogo from './form/UploadLogo'

type RecommendStepTypes = {
  label: string
  name: string
  component: any
}

const RECOMMEND_STEPS = [
  {
    label: 'Upload company logo',
    name: 'upload_logo',
    component: UploadLogo,
  },
  {
    label: 'Enter personal details',
    name: 'personal_detail',
    component: EditPersonalDetail,
  },
  {
    label: 'Enter business information',
    name: 'business_info',
    component: EnterBusinessInfo,
  },
  {
    label: 'Add team members',
    name: 'add_role',
    component: AddRole,
  },
]

const useStyles = makeStyles((theme: any) => ({
  stepLabel: {
    cursor: 'pointer',
    marginLeft: 10,
    '&:hover': {
      color: '#1890ff',
    },
  },
  learnMore: {
    color: theme.blue,
    lineHeight: '1.4em',
    '& li:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}))

const FinanceIntegrationsDialog = ({ isOpen, handleClose }: any) => {
  const translate = useTranslate()
  return (
    <Dialog
      style={{ zIndex: TOUR_Z_INDEX }}
      // maxWidth="xs"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Finance Partners</DialogTitle>
      <DialogContent>
        <FinancialIntegration />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          <span>{translate('Back')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Tooltip = (props: ToolTipPropsType) => {
  const [checked, setChecked] = useState(() => new Set())
  const [editing, setEditing] = useState('')
  const orgData = useSelector((state) => getOrgFromState(state))
  const orgCountry = useSelector((state) => getOrgCountryIso2FromState(state))
  useEffect(() => {
    if (orgData?.logo_public_url) {
      const newChecked = new Set(checked)
      newChecked.add('upload_logo')
      setChecked(newChecked)
    }
  }, [])
  const showFinancingIntegration = useMemo(() => {
    return financingIntegrations.filter((a) => a.config.support_countries.includes(orgCountry)).length > 0
  }, [])
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const { handleNext, handlePrev, handleCancel } = props
  const handleCheck = (name: string, isChecked: boolean) => {
    if (isChecked) {
      setChecked((checked) => {
        const newChecked = new Set(checked)
        newChecked.add(name)
        return newChecked
      })
    } else {
      setChecked((checked) => {
        const newChecked = new Set(checked)
        newChecked.delete(name)
        return newChecked
      })
    }
  }

  const onSuccess = useCallback((name: string) => {
    notify('ra.notification.updated', 'info')
    handleCheck(name, true)
    setEditing('')
  }, [])

  const onDismiss = useCallback(() => {
    setEditing('')
  }, [])

  return (
    <div
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed', zIndex: TOUR_Z_INDEX }}
    >
      <DefaultTooltip
        title={translate('CONGRATULATIONS')}
        // onClose={handleCancel}
        icon={CheckCircleOutlineIcon}
        disableDrag={true}
        content={
          <div>
            <h3 style={{ margin: 0 }}>{translate('Well Done!')}</h3>
            <p style={{ lineHeight: '1.4em' }}>
              {translate(
                "You're well on your way to creating the most accurate and compelling designs and proposals to grow your solar business."
              )}
            </p>
            <Divider />
            <h3>{translate('Recommended Next Steps')}</h3>
            {RECOMMEND_STEPS.map((config: RecommendStepTypes) => {
              const isChecked = checked.has(config.name)
              return (
                <div key={config.name}>
                  {config.component && (
                    <DialogEditor
                      isOpen={editing === config.name}
                      options={{ style: { zIndex: TOUR_Z_INDEX } }}
                      handleClose={onDismiss}
                      editorComponent={React.createElement(config.component, {
                        hideCancelButton: true,
                        hideBreadCrumbs: true,
                        onSuccess: () => onSuccess(config.name),
                        onDismiss: onDismiss,
                      })}
                    />
                  )}
                  <Checkbox
                    size="small"
                    checked={isChecked}
                    onChange={(event) => {
                      handleCheck(config.name, event.target.checked)
                    }}
                  />
                  <span
                    className={classes.stepLabel}
                    style={{ textDecoration: isChecked ? 'line-through' : 'underline' }}
                    onClick={() => {
                      setEditing(config.name)
                    }}
                  >
                    {translate(config.label)}
                  </span>
                </div>
              )
            })}
            {showFinancingIntegration && (
              <div>
                <FinanceIntegrationsDialog
                  isOpen={editing === 'finance_integration'}
                  handleClose={() => {
                    handleCheck('finance_integration', true)
                    setEditing('')
                  }}
                />
                <Checkbox
                  size="small"
                  checked={checked.has('finance_integration')}
                  onChange={(event) => {
                    handleCheck('finance_integration', event.target.checked)
                  }}
                />
                <span
                  className={classes.stepLabel}
                  style={{ textDecoration: checked.has('finance_integration') ? 'line-through' : 'underline' }}
                  onClick={() => {
                    setEditing('finance_integration')
                  }}
                >
                  {translate('Add integrated financing solution')}
                </span>
              </div>
            )}
            <Divider />
            <h3>{translate('Learn More')}</h3>
            <ol className={classes.learnMore}>
              <li onClick={() => window.open('https://support.opensolar.com/hc/en-us/articles/4408435379481')}>
                {translate('Pricing and payment options')}
              </li>
              <li onClick={() => window.open('https://support.opensolar.com/hc/en-us/articles/4408427358489')}>
                {translate('Hardware preference')}
              </li>
            </ol>
            <p style={{ marginBottom: 0, lineHeight: '1.4em' }}>
              {translateParse(
                "If you have any questions, we're always here for you at <a1>support.opensolar.com<a1> or <a2>support@opensolar.com<a2>",
                {
                  a1: (label: string) => (
                    <a href={'https://support.opensolar.com/hc/en-us'} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ),
                  a2: (label: string) => <a href={'mailto:support@opensolar.com'}>{label}</a>,
                }
              )}
            </p>
          </div>
        }
        actions={
          <>
            <Button onClick={() => handlePrev && handlePrev()}>
              <span>{translate('Back')}</span>
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleCancel && handleCancel()}>
              <span>{translate('Finish Tour')}</span>
            </Button>
          </>
        }
      />
    </div>
  )
}

const FinishConfig: TourConfigType = {
  spotlights: [],
  tooltips: [<Tooltip />],
}

export default FinishConfig
