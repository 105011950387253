import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const ArrowForwardIconComponent: FC<IconProps> = (props) => {
  const { color, strokeWidth, fill, stroke, width, height } = props

  return (
    <ArrowForwardIosRoundedIcon width={width} height={height} stroke={stroke} fill={fill} strokeWidth={strokeWidth} />
  )
}

export const ArrowForwardIcon = createStandardIcon(ArrowForwardIconComponent)
