import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'

import * as Sentry from '@sentry/react'
import { CLEAR_STATE } from '../actions/clearActions'
import createAppReducer from '../reducer'
import { adminSaga } from '../sideEffect'
import { AuthProvider, DataProvider, I18nProvider, InitialState } from '../types'

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: object) => Function
}

interface Params {
  dataProvider: DataProvider
  history: History
  authProvider: AuthProvider | null
  customReducers?: any
  customSagas?: any[]
  i18nProvider?: I18nProvider
  initialState?: InitialState
  locale?: string
}

interface Auth {
  is_staff?: Boolean
  is_superuser?: Boolean
  org_id?: number
  OPENSOLAR_API_VERSION?: string
  OPENSOLAR_GIT_COMMIT?: string
  orgs?: any[]
  user?: any
}

export default ({
  dataProvider,
  history,
  customReducers = {},
  authProvider = null,
  customSagas = [],
  initialState,
}: Params) => {
  const appReducer = createAppReducer(customReducers, history)
  const resettableAppReducer = (state, action) => {
    return appReducer(
      action.type !== CLEAR_STATE
        ? state
        : // Erase data from the store but keep location, notifications, ui prefs, etc.
          // This allows e.g. to display a notification on logout
          {
            ...state,
            admin: {
              ...state.admin,
              loading: 0,
              resources: {},
              customQueries: {},
              references: { oneToMany: {}, possibleValues: {} },
            },
          },
      action
    )
  }

  const saga = function* rootSaga() {
    yield all([adminSaga(dataProvider, authProvider), ...customSagas].map(fork))
  }
  const sagaMiddleware = createSagaMiddleware()
  const typedWindow = window as Window

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof typedWindow !== 'undefined' &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
      var auth: Auth = {}

      try {
        auth.is_staff = state.auth.is_staff
      } catch (e) {
        //
      }

      try {
        auth.is_superuser = state.auth.is_superuser
      } catch (e) {
        //
      }

      try {
        auth.org_id = state.auth.org_id
      } catch (e) {
        //
      }

      try {
        auth.OPENSOLAR_API_VERSION = state.auth.OPENSOLAR_API_VERSION
      } catch (e) {
        //
      }

      try {
        //@ts-ignore
        auth.OPENSOLAR_GIT_COMMIT = window.GIT_COMMIT
      } catch (e) {
        //
      }

      try {
        //@ts-ignore
        auth.OPENSOLAR_RELEASE_ID = window.RELEASE_IDENTIFIER
      } catch (e) {
        //
      }

      try {
        auth.orgs = state.auth.orgs.map((org) => ({ id: org.id }))
      } catch (e) {
        //
      }

      try {
        auth.user = { id: state.auth.user.id }
      } catch (e) {
        //
      }

      const transformedState = {
        auth: auth,
        projectId: state.projectId,
      }

      return transformedState
    },

    actionTransformer: (action) => {
      if (true) {
        return null
      } else {
      }
    },
  })

  const store = createStore(
    resettableAppReducer,
    typeof initialState === 'function' ? initialState() : initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)), sentryReduxEnhancer)
  )
  sagaMiddleware.run(saga)
  //@ts-ignore
  window.reduxStore = store
  return store
}
