import { COMPONENT_CONFIG, ContentBlock } from 'opensolar-pageeditor'
import restClient from 'restClient'
import { STAFF_SAMPLE } from './sample_template'

export const defaultExpoContents = (isPremium = false, isPro = false): ContentBlock[] => {
  let includedBlocks = Object.keys(COMPONENT_CONFIG)
  if (!isPremium) {
    //remove premium blocks
    includedBlocks = includedBlocks.filter((x) => {
      const value = COMPONENT_CONFIG[x]
      return x === 'cover' || !value.premium
    })
  } else if (!isPro) {
    //remove pro blocks
    includedBlocks = includedBlocks.filter((x) => x !== 'featured_products')
  }
  const enabledByDefault = ['navbar', 'our_story']
  return includedBlocks.map((block) => {
    let enabled = enabledByDefault.includes(block)
    if (block === 'cover') {
      enabled = isPremium
    }
    return {
      contentType: block,
      enabled,
    }
  })
}

const restClientInstance = restClient(window.API_ROOT + '/api')
export const createSampleTemplate = (orgId: number | undefined, notify, redirect, errorRedirect) => {
  if (orgId) {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/expo_stands/`,
      data: STAFF_SAMPLE,
    })
      .then((res) => {
        redirect(res.data?.id)
      })
      .catch((err) => {
        notify('Error creating sample template: ' + err, 'warning')
        errorRedirect()
      })
  }
}
