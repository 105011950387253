import { PollCancellationError } from './errors'

export interface PollResponseInterpreter<T> {
  shouldContinue: (response: T) => boolean
  extractResult: (response: T) => any
  isComplete: (response: T) => boolean
  getPartialResult?: (response: T) => any
}

interface PollingState {
  controller: AbortController
  pollId: string
  startTime: number
}

interface PollController<T> {
  start: () => Promise<T>
  cancel: () => void
  isPending: () => boolean
}

interface PollingConfig<T extends object> {
  maxAttempts?: number
  initialInterval?: number
  maxInterval?: number
  backoffRate?: number
  onPartialResult?: (data: any) => void
  interpreter?: PollResponseInterpreter<T>
}

const defaultInterpreter: PollResponseInterpreter<any> = {
  shouldContinue: (response) => response.status === 'pending',
  extractResult: (response) => response.result,
  isComplete: (response) => response.status === 'completed',
  getPartialResult: (response) => response.partial_results,
}

export class PollingManager {
  private activePolls: Map<string, PollingState> = new Map()

  cancelPoll(id: string) {
    const poll = this.activePolls.get(id)
    if (poll) {
      console.debug(`Cancelling poll ${poll.pollId}`)
      poll.controller.abort()
      this.activePolls.delete(id)
    }
  }

  startNewPoll(id: string, pollId: string) {
    const existingPoll = this.activePolls.get(id)
    if (existingPoll) {
      if (existingPoll.pollId === pollId) {
        // If same poll, return existing controller
        return existingPoll.controller
      }
      // Only abort and remove if it's a different poll
      console.debug(`Replacing poll ${existingPoll.pollId} with ${pollId}`)
      existingPoll.controller.abort()
      this.activePolls.delete(id)
    }

    const controller = new AbortController()
    this.activePolls.set(id, {
      controller,
      pollId,
      startTime: Date.now(),
    })
    return controller
  }

  isActivePoll(id: string, pollId: string): boolean {
    return this.activePolls.get(id)?.pollId === pollId
  }

  removePoll(id: string) {
    this.activePolls.delete(id)
  }
}

export const pollingManager = new PollingManager()

export const createPoller = <T extends object>(
  url: string,
  id: string,
  config: PollingConfig<T> = {}
): PollController<T> => {
  const {
    maxAttempts = 30,
    initialInterval = 500,
    maxInterval = 3000,
    backoffRate = 1.5,
    onPartialResult,
    interpreter = defaultInterpreter,
  } = config

  const pollId = crypto.randomUUID()
  let attempt = 0
  let hasHandledPartialResult = false
  let currentInterval = initialInterval

  const controller = pollingManager.startNewPoll(id, pollId)

  const poll = async (): Promise<any> => {
    try {
      // Check if poll is still active before doing anything
      if (!pollingManager.isActivePoll(id, pollId)) {
        console.debug(`Poll ${pollId} is no longer active, stopping gracefully`)
        return null
      }

      if (attempt >= maxAttempts) {
        pollingManager.removePoll(id)
        throw new PollCancellationError(pollId, 'timeout exceeded')
      }

      const response = await fetch(url, {
        headers: window.Utils.tokenAuthHeaders(),
        signal: controller.signal,
      })

      if (!response.ok) {
        throw new Error(`HTTP error: status: ${response.status}`)
      }

      const result = (await response.json()) as T

      // Check again if poll is still active after the fetch
      if (!pollingManager.isActivePoll(id, pollId)) {
        console.debug(`Poll ${pollId} was cancelled during fetch, stopping gracefully`)
        return null
      }

      if (interpreter.shouldContinue(result)) {
        // Handle partial results
        if (interpreter.getPartialResult && onPartialResult && !hasHandledPartialResult) {
          const partialResult = interpreter.getPartialResult(result)
          if (partialResult) {
            hasHandledPartialResult = true
            onPartialResult(partialResult)
          }
        }

        attempt++
        currentInterval = Math.min(currentInterval * backoffRate, maxInterval)
        await new Promise((resolve) => setTimeout(resolve, currentInterval))
        return poll()
      }

      pollingManager.removePoll(id)

      if (interpreter.isComplete(result)) {
        return interpreter.extractResult(result)
      }

      throw new Error((result as any).error || 'Unknown error')
    } catch (error) {
      pollingManager.removePoll(id)

      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          console.debug(`Poll ${pollId} aborted: natural cancellation`)
          return null
        }

        if (error instanceof PollCancellationError) {
          console.debug(`Poll ${pollId} cancelled: ${error.message}`)
          return null
        }

        console.error('Poll error:', {
          name: error.name,
          message: error.message,
          pollId,
          attempt,
          wasActive: pollingManager.isActivePoll(id, pollId),
        })
      }

      throw error
    }
  }

  return {
    start: poll,
    cancel: () => pollingManager.cancelPoll(id),
    isPending: () => pollingManager.isActivePoll(id, pollId),
  }
}
