import { FC } from 'react'

/**
 * * Icon Component for: alert-triangle-filled
 */
type ArrowDropDownIconProps = {
  size?: number
  color?: string
}
export const ArrowDropDownIcon: FC<ArrowDropDownIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
    fill: color || 'none',
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest} {...iconAttributes}>
      <path d="m7 10 5 5 5-5z"></path>
    </svg>
  )
}
