import { FC } from 'react'

/**
 * * Icon Component for: flip-left
 */
type FlipLeftIconProps = {
  size?: number
  color?: string
}
export const FlipLeftIcon: FC<FlipLeftIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M5.95426 1.80019L1.40039 6.41569M1.40039 6.41569L5.95426 11.0312M1.40039 6.41569L16.6004 6.41569C18.8095 6.41569 20.6004 8.20655 20.6004 10.4157L20.6004 12.2002C20.6004 14.4093 18.8095 16.2002 16.6004 16.2002L11.0004 16.2002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
