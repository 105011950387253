import { EnergyTypeEnum } from '../types'
import { CurrentAnnualEnergyUsageType, EnergyUsageDetailType, EnergyUsageType } from './types'

export const mapFuelTypeToEnergyTypeEnum = (fuelType: string): EnergyTypeEnum | undefined => {
  switch (fuelType) {
    case 'natural_gas':
      return EnergyTypeEnum.NATURAL_GAS
    case 'electricity':
      return EnergyTypeEnum.ELECTRICITY
    default:
      return undefined
  }
}

/**
 * Formats the energy usage values for heating, cooling, and hot water.
 *
 * @param data - The current annual energy usage data.
 * @returns An array of formatted energy usage values.
 *
 * The function processes the input data for heating, cooling, and hot water,
 * maps the fuel type to an energy type enum, and formats the values accordingly.
 * It merges the values if an entry for the same energy type already exists.
 */
export const formatEnergyUsageValues = (data: CurrentAnnualEnergyUsageType): EnergyUsageType[] => {
  const formatValues = (type: 'heating' | 'cooling' | 'hotWater', data: EnergyUsageDetailType): EnergyUsageType => {
    const energyType = mapFuelTypeToEnergyTypeEnum(data.fuelType)
    const baseValues = {
      energy_type: energyType,
      unit_value: data.units,
      usage_data_source: 'interval_data',
      interval_mins: 60,
    }

    switch (type) {
      case 'heating':
        return { ...baseValues, values_kwh_heating: data.values }
      case 'cooling':
        return { ...baseValues, values_kwh_cooling: data.values }
      case 'hotWater':
        return { ...baseValues, values_kwh_hot_water: data.values }
      default:
        return baseValues
    }
  }

  const energyUsageMap = new Map<EnergyTypeEnum, EnergyUsageType>()

  const addOrUpdateEnergyUsage = (type: 'heating' | 'cooling' | 'hotWater', data: EnergyUsageDetailType) => {
    const formattedValues = formatValues(type, data)
    const { energy_type } = formattedValues

    if (energy_type === undefined) {
      console.warn(`Unknown fuel type: ${data.fuelType}`)
      return
    }
    const existingEntry = energyUsageMap.get(energy_type)

    if (existingEntry) {
      energyUsageMap.set(energy_type, {
        ...existingEntry,
        values_kwh_heating: existingEntry.values_kwh_heating || formattedValues.values_kwh_heating,
        values_kwh_cooling: existingEntry.values_kwh_cooling || formattedValues.values_kwh_cooling,
        values_kwh_hot_water: existingEntry.values_kwh_hot_water || formattedValues.values_kwh_hot_water,
      })
    } else {
      energyUsageMap.set(energy_type, formattedValues)
    }
  }

  const energyTypes: Array<keyof CurrentAnnualEnergyUsageType> = ['heating', 'cooling', 'hotWater']
  energyTypes.forEach((useType) => {
    const energyUsageDetail = data[useType]
    if (energyUsageDetail) {
      addOrUpdateEnergyUsage(useType, energyUsageDetail)
    }
  })

  return Array.from(energyUsageMap.values())
}

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0
}
