import { CircularProgress } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { parseQueryStringToDictionary } from 'util/query'
const restClientInstance = restClient(window.API_ROOT + '/api')

const StyledContent = styled('div')(({ theme }) => ({
  padding: '40px 20px',
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[900],
  margin: 8,
}))

const Body = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  minHeight: '100vh',
}))

const Container = styled('div')(({ theme }) => ({
  marginTop: 40,
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  width: '500px',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  margin: 15,
}))

const Logo = styled('img')(({ theme }) => ({
  width: 200,
}))

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0px 40px 0px',
}))

const LoaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const TextContainer = styled('div')(({ theme }) => ({
  lineHeight: 1.5,
}))

type PropTypes = {
  location: {
    search: string
    href: string
  }
}

const RecheckAuthorized = (props: PropTypes) => {
  const org = useSelector(orgSelectors.getOrg)
  const translate = useTranslate()
  const query_params = parseQueryStringToDictionary(props?.location?.search)
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const { code, state, error, error_description } = query_params
  const code_verifier = appStorage.getString('recheck_oauth2_code_verifier')
  const roleId = appStorage.getNumber('recheck_role_id')

  useEffect(() => {
    if (org && code && state && code_verifier) {
      setIsLoading(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org?.id}/recheck/authorize/?role_id=${roleId}`,
        data: {
          authorization_response: window.location.href,
          code,
          code_verifier,
        },
      })
        .then((res) => {
          setIsSuccess(true)
          appStorage.clear('recheck_oauth2_code_verifier')
          appStorage.clear('recheck_role_id')
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }

    return () => {
      // Cleanup upon unmount regardless if authorization is successful
      appStorage.clear('recheck_oauth2_code_verifier')
      appStorage.clear('recheck_role_id')
    }
  }, [])

  return (
    <Body>
      <Container>
        <ContentWrapper>
          <StyledContent>
            <LogoContainer>
              <Logo src={`${window.PUBLIC_URL}/images/logo.svg`} />
            </LogoContainer>
            {isLoading && (
              <LoaderContainer>
                <CircularProgress />
                <TextContainer>{translate('Please wait while we integrate your Recheck account...')}</TextContainer>
              </LoaderContainer>
            )}
            {!isLoading && !isSuccess && (
              <TextContainer>
                {translate('Failed to integrate with Recheck... Please close this tab and try again')}
                {error && <div>{`Error code: ${error} - ${error_description}`}</div>}
              </TextContainer>
            )}
            {!isLoading && isSuccess && (
              <TextContainer>
                {translate('User has been successfully integrated with Recheck! You may close this tab')}
              </TextContainer>
            )}
          </StyledContent>
        </ContentWrapper>
      </Container>
    </Body>
  )
}

export default RecheckAuthorized
