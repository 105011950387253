import { FC } from 'react'

/**
 * * Icon Component for: check-01
 */
type CheckOutlineIconProps = {
  size?: number
  color?: string
}
export const CheckOutlineIcon: FC<CheckOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M10.7992 1.3999L3.6397 8.5999L1.19922 6.14562"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
