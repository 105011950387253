import { FormControlLabel, InputLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { orgSelectors } from 'ducks/orgs'
import { DateInput } from 'elements/input/DateInput'
import { isEmpty } from 'lodash'
import { Alert, FormControl, Grid, MenuItem, Select } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { useTranslate } from 'ra-core'
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DealerInfo, DealerType } from 'services/vtac/type'
import useVtacService from 'services/vtac/useVtacService'
import { Theme } from 'types/themes'
import AccordionCardSummaryContent from '../components/AccordionCardSummaryContent'
import AccordionCardTitle from '../components/AccordionCartTitle'
import DeliveryInstruction from '../components/DeliveryInstruction'
import DistributorConnectionAlert from '../components/DistributorConnectionAlert'
import DistributorDeliveryDetail from '../components/DistributorDeliveryDetail'
import { ErrorHelperText } from '../components/ErrorHelperText'
import PartsList from '../components/PartsList'
import PrivacyLinks from '../components/PrivacyLinks'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CartSectionContentPropType, CartSectionPropType } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: COLOR_PALETTE.grey,
    marginBottom: '4px',
  },
  distributorDisclaimer: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey5,
  },
  gap: {
    gap: '8px',
  },
  radio: {
    padding: 0,
    '&.Mui-checked': {
      color: COLOR_PALETTE.blue2,
    },
  },
  deliveryItemDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
    marginLeft: '40px',
  },
  deliveryItemDetailTitle: {
    fontSize: '16px',
    color: COLOR_PALETTE.black,
    lineHeight: '24px',
  },
  deliveryItemDetailSubtitle: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey,
    paddingRight: '40px',
  },
  deliveryItemTitleWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
}))

const VTACCartSection: FC<CartSectionPropType> = ({ sortOrder, distributorKey, checkoutSource }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]
  return (
    <AccordionCard
      name={'distributor-vtac'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent totalItems={data?.totalItems} subtotal={data?.subtotalPrice} shippingFee={'TBC'} />
      }
      content={VTACCartSectionContent}
      contentProps={{ distributorKey, checkoutSource }}
      lazyRender={true}
    />
  )
}

const VTACCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const vtacService = useVtacService()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const [dealers, setDealers] = useState<DealerType[]>([])
  const data = distributorData[distributorKey]
  const translate = useTranslate()
  const { distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const distributorConfig = distributorConfigs.find(
    (item: ExtendedHardwareSupplierConfig) => item.filterKey === distributorKey
  )
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const policyLink =
    countryIso2 === 'GB'
      ? 'https://www.vtacexports.com/faq#faq-privacy'
      : 'https://www.vtacexports.com/poland/polityka-prywatnosci'

  const conditionLink =
    countryIso2 === 'GB'
      ? 'https://www.vtacexports.com/faq#faq-terms-and-conditions'
      : 'https://www.vtacexports.com/poland/regulamin'
  const isDelivery = data?.shippingInfo?.deliveryMethod === 'delivery'
  const now = new Date()
  const deliveryMinDate = new Date()
  const clickAndCollectMinDate = new Date()
  deliveryMinDate.setDate(now.getDate() + 1)
  clickAndCollectMinDate.setDate(now.getDate() + 3)

  useEffect(() => {
    const fetchDealers = async () => {
      const response = await vtacService?.getDealers(distributorKey)
      if (response) {
        const dealerArray: DealerType[] = Object.entries(JSON.parse(response)).map(([key, value]) => {
          return {
            id: +key,
            ...(value as DealerInfo),
          } as DealerType
        })
        setDealers(dealerArray)
      }
    }
    fetchDealers()
  }, [preOrderPresenter, distributorKey])

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  const handleUpdateDealer = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        dealerId: `${event.target.value}`,
      },
    })
  }

  const handleUpdateDeliveryMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryMethod: event.target.value,
          deliveryDate: '',
          deliveryTime: '',
          deliveryBranch: {
            name: '',
            manager: '',
            email: '',
            contactNumber: '',
            address: '',
            weekdayHours: '',
            saturdayHours: '',
            sundayHours: '',
          },
          fee: 'TBC',
        },
      },
    })
  }

  const handleUpdateDeliveryDate = (date: any) => {
    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryDate: formattedDate,
          fee: 'TBC',
        },
      },
    })
  }

  return (
    <Grid container style={{ gap: '24px', padding: '8px 8px' }}>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        {distributorConfig?.isConnected ? (
          <Alert severity="info">
            <Typography variant="subtitle2" display="block">
              {translate('What’s next after sending my order?')}
            </Typography>
            <Typography variant="caption" display="block">
              {translate(
                'Once sent, your order will be transferred to the V-TAC’s portal for payment processing and final shipping confirmation. You will then receive a V-TAC order reference ID for tracking purposes.'
              )}
            </Typography>
          </Alert>
        ) : (
          <DistributorConnectionAlert distributor={distributorKey} checkoutSource={checkoutSource} />
        )}
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '16px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Select Distributor Location')}</span>
        <span className={classes.distributorDisclaimer}>
          {translate(
            'Choose your preferred distributor to fulfill your order. V-TAC will provide updated delivery estimates and\n' +
              'shipping cost before order is processed.'
          )}
        </span>
        <FormControl fullWidth>
          <InputLabel className={classes.inputLabel}>{translate('Distributor')}</InputLabel>
          <Select value={data?.dealerId} onChange={handleUpdateDealer}>
            {dealers.map((item, index) => (
              <MenuItem id={'dealer' + index} value={item.id} key={item.id}>
                {item.dealer_name} ({item.city}, {item.postcode})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Select Delivery Method')}</span>
        <RadioGroup
          className={classes.gap}
          name="deliveryMethod"
          onChange={handleUpdateDeliveryMethod}
          value={data?.shippingInfo?.deliveryMethod}
        >
          <FormControlLabel
            className={classes.deliveryItemTitleWrapper}
            key={'delivery-method-delivery'}
            value={'delivery'}
            control={<Radio className={classes.radio} />}
            label={
              <div className={sectionStyles.column}>
                <span className={classes.deliveryItemDetailTitle}>{translate('Delivery')}</span>
              </div>
            }
          />
          <Grid className={classes.deliveryItemDetailContainer} container>
            <Grid item xs={4}>
              <DateInput
                fullWidth
                size="medium"
                value={isDelivery ? dayjs(data?.shippingInfo?.deliveryDate) : null}
                onChange={handleUpdateDeliveryDate}
                inputVariant="outlined"
                label={<InputLabel className={sectionStyles.inputLabel}>{translate('Ship by')}</InputLabel>}
                minDate={dayjs(deliveryMinDate)}
                autoFocus={isDelivery}
                shouldDisableDate={(date) => dayjs(date).day() === 0 || dayjs(date).day() === 6}
                disabled={!isDelivery}
              />
              <ErrorHelperText showText={isDelivery && isEmpty(data?.shippingInfo?.deliveryDate)} />
            </Grid>
          </Grid>
          <FormControlLabel
            className={classes.deliveryItemTitleWrapper}
            key={'delivery-method-click-&-collect'}
            value={'click_and_collect'}
            control={<Radio className={classes.radio} />}
            label={
              <div className={sectionStyles.column}>
                <span className={classes.deliveryItemDetailTitle}>{translate('Click & Collect')}</span>
                <span className={classes.deliveryItemDetailSubtitle}>
                  {translate(
                    'Select your preferred date for pickup, and V-TAC will notify you by email and text when your\n' +
                      'order is ready. Please note that a 3-day advance notice is required, so the earliest available\n' +
                      'collection dates will be 3 days from the order date.'
                  )}
                </span>
              </div>
            }
          />
          <Grid className={classes.deliveryItemDetailContainer} container>
            <Grid item xs={4}>
              <DateInput
                fullWidth
                size="medium"
                value={!isDelivery ? dayjs(data?.shippingInfo?.deliveryDate) : null}
                onChange={handleUpdateDeliveryDate}
                inputVariant="outlined"
                label={<InputLabel className={sectionStyles.inputLabel}>{translate('Pick up by')}</InputLabel>}
                minDate={dayjs(clickAndCollectMinDate)}
                autoFocus={isDelivery}
                shouldDisableDate={(date) => dayjs(date).day() === 0 || dayjs(date).day() === 6}
                disabled={isDelivery}
              />
              <ErrorHelperText showText={!isDelivery && isEmpty(data?.shippingInfo?.deliveryDate)} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <PrivacyLinks distributor={'V-TAC'} policyLink={policyLink} conditionLink={conditionLink} />
      </Grid>
    </Grid>
  )
}

export default memo(VTACCartSection)
