import { useViewShowChecker } from 'ducks/viewMode'
import {
  DesignMode2D,
  DesignMode3D,
  DesignModeManual,
} from 'projectSections/sections/design/tools/designMode/ToolbarDesignModeContent'
import { useMemo } from 'react'

import IconDesignMode2D from './images/DesignMode2D.svg'
import IconDesignMode3D from './images/DesignMode3D.svg'
import IconDesignModeManual from './images/DesignModeManual.svg'

const DESIGN_MODES_CONFIG = [
  { key: '3D', label: '3D', imagery: IconDesignMode3D, content: DesignMode3D },
  { key: '2D', label: '2D', imagery: IconDesignMode2D, content: DesignMode2D },
  { key: 'Manual', label: 'Manual', imagery: IconDesignModeManual, content: DesignModeManual },
] as const

export const useDesignModesFiltered = () => {
  const viewShow = useViewShowChecker()
  return useMemo(() => {
    return DESIGN_MODES_CONFIG.filter((designMode) => {
      return viewShow('studio.design_mode.tabs.' + designMode.key.toLowerCase())
    })
  }, [viewShow])
}
