import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { http } from '../services/http'
import { StandData } from './expoStand'
import { ExpoStandProps } from './OrgPageRenderer'

export const useLoadExpoStand = ({ standId }: ExpoStandProps) => {
  const history = useHistory()
  const [standData, setStandData] = useState<StandData | null>(null)
  useEffect(() => {
    if (standId && standData === null) {
      http.os
        .get(`/partners/partner_expo_stand/${standId}/`)
        .then((res) => {
          if (res.data) {
            setStandData(res.data)
          } else {
            history.push('/expo')
          }
        })
        .catch((err) => {
          console.error('Failed to retrieve expo stand: ', err)
        })
    }
  }, [standId, standData])

  return standData
}
