import { makeStyles } from '@material-ui/core/styles'
import { getSystemName } from 'Designer/hooks/useSystemName'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { projectViewSettingsActions, projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import ActionsIconButton from 'elements/button/ActionsIconButton'
import {
  Button,
  Divider,
  DownloadIcon,
  HourGlassOutlineIcon,
  IconButton,
  SendOutlineIcon,
  styled,
  TextInputOutlineIcon,
  Tooltip,
  UploadIcon,
} from 'opensolar-ui'
import { projectDataLoad } from 'projectSections/sections/design/util/projectDataLoad'
import { projectDataSave } from 'projectSections/sections/design/util/projectDataSave'
import SoldChip from 'projectSections/sections/payments/SoldChip'
import { useTranslate } from 'ra-core'
import { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { doNotTranslate } from 'util/misc'
import { getCustomFields } from '../common/CustomFields'
import SelectChipField from '../common/SelectChipField'
import CustomFieldsModal from './elements/CustomFieldsModal'
import ProjectHistoryDrawer from './elements/history/ProjectHistoryDrawer'
import ProjectAddress from './elements/ProjectAddress'
import ProjectDeleteButton from './elements/ProjectDeleteButton'
import { ProjectDuplicateButton } from './elements/ProjectDuplicateButton'
import ShareProposalLinkModal from './elements/sharing/ShareProposalLinkModal'
import { ProjectThumbnail } from './elements/thumbnail/ProjectThumbnail'

const useStyles = makeStyles((theme: Theme) => ({
  tags: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 'auto',
  },
  icon: {
    marginBottom: 32,
    marginLeft: 10,
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: 5,
  },
  chip: {
    marginRight: 5,
    height: '16px !important',
  },
}))

const ActionsWrapper = styled('div')({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
})

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const DisplayWrapper = styled('div', { name: 'DisplayWrapper' })({
  display: 'flex',
})

const DetailHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const project: ProjectType = useFormState().values as ProjectType
  const org = useSelector(orgSelectors.getOrg)
  const orgId = useSelector(authSelectors.getOrgId)
  const historyOpen = useSelector(projectViewSettingsSelectors.getProjectHistoryOpen)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage'))
  const { allowDelete } = useSelector(permissionsSelectors.getProjectPermissionByKey('project'))
  const [systemSelected, setSystemSelected] = useState(project.systems?.length ? project.systems[0]?.url : undefined)
  const [customFieldsOpen, setCustomFieldsOpen] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, 'Project'), [])
  const soldInstalledChip = useMemo(() => {
    if (
      project.project_installed !== undefined &&
      project.project_installed !== null &&
      systemSelected === project.system_installed
    )
      return translate('Installed')
    if (project.project_sold !== undefined && project.project_sold !== null && systemSelected === project.system_sold)
      return translate('Sold')
  }, [project.project_installed, project.project_sold, systemSelected, project.system_installed, project.system_sold])

  return (
    <FlexWrapper>
      <ProjectThumbnail />
      <DisplayWrapper>
        <ProjectAddress />
        <div className={classes.tags}>
          {/* <ReferenceInput
          label={''}
          resource={'projects'}
          reference="systems"
          source="system_sold"
          filter={{
            project: project ? project.id : null,
            visible_to: orgId,
          }}
          sort={{ field: 'order', order: 'ASC' }}
          allowEmpty
        >
          <SystemSelectField
            systemSelected={systemSelected}
            onChange={(e) => {
              setSystemSelected(e.target.value)
            }}
          />
        </ReferenceInput> */}
          {soldInstalledChip && <SoldChip chipText={soldInstalledChip} />}
        </div>
      </DisplayWrapper>

      <div className={classes.actions}>
        <Tooltip title={translate('Activity')}>
          <IconButton
            color="secondary"
            variant="squared"
            onClick={() => {
              dispatch(projectViewSettingsActions.setHistoryOpen(!historyOpen))
            }}
          >
            <HourGlassOutlineIcon variant={3} size={20} />
          </IconButton>
        </Tooltip>
        {!!customFields.length && (
          <Tooltip title={translate('Custom Fields')}>
            <IconButton
              color="secondary"
              variant="squared"
              onClick={() => {
                setCustomFieldsOpen(true)
              }}
              disabled={!allowEdit}
            >
              <TextInputOutlineIcon variant={1} size={20} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={translate('Share Proposal')}>
          <IconButton
            color="secondary"
            variant="squared"
            onClick={() => {
              setShowShareModal(true)
            }}
            disabled={!allowEdit}
          >
            <SendOutlineIcon variant={1} size={20} />
          </IconButton>
        </Tooltip>

        <ActionsIconButton disabled={!allowEdit}>
          <ActionsWrapper>
            {orgId && project.id && <ProjectDuplicateButton orgId={orgId} projectId={project.id} />}
            {allowDelete && project.id && <ProjectDeleteButton project={project} />}

            {isStaff && (
              <>
                {project.id && <Divider />}
                <Button variant="text" color="secondary" onClick={projectDataSave} startIcon={<DownloadIcon />}>
                  {doNotTranslate('Save Project Data')}
                </Button>
                <Button variant="text" color="secondary" onClick={projectDataLoad} startIcon={<UploadIcon />}>
                  {doNotTranslate('Load Project Data')}
                </Button>
              </>
            )}
          </ActionsWrapper>
        </ActionsIconButton>
      </div>
      <CustomFieldsModal
        open={customFieldsOpen}
        onClose={() => {
          setCustomFieldsOpen(false)
        }}
      />
      <ProjectHistoryDrawer
        open={historyOpen}
        onClose={() => {
          dispatch(projectViewSettingsActions.setHistoryOpen(false))
        }}
      />
      <ShareProposalLinkModal
        open={showShareModal}
        onClose={() => {
          setShowShareModal(false)
        }}
      />
    </FlexWrapper>
  )
}

const SystemSelectField = (props) => {
  const translate = useTranslate()
  const { choices, systemSelected, onChange } = props
  const displaySystemName = (sys) => {
    return getSystemName(sys, translate)
  }
  return (
    <SelectChipField
      choices={choices}
      chipLabel={translate('System')}
      optionValue={'url'}
      optionText={displaySystemName}
      value={systemSelected}
      onChange={onChange}
    />
  )
}

export default DetailHeader
