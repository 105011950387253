import { FC } from 'react'
import type { IconProps } from './type'

interface DotsVerticalIconProps extends Partial<IconProps> {
  size?: number
  color?: string
}

export const DotsVerticalIcon: FC<DotsVerticalIconProps> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  const viewBox = `0 0 ${size} ${size}`
  return (
    <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9996 7.1999C10.6741 7.1999 9.59961 6.12539 9.59961 4.7999C9.59961 3.47442 10.6741 2.3999 11.9996 2.3999C13.3251 2.3999 14.3996 3.47442 14.3996 4.7999C14.3996 6.12539 13.3251 7.1999 11.9996 7.1999Z"
        fill={color}
      />
      <path
        d="M11.9996 14.3999C10.6741 14.3999 9.59961 13.3254 9.59961 11.9999C9.59961 10.6744 10.6741 9.5999 11.9996 9.5999C13.3251 9.5999 14.3996 10.6744 14.3996 11.9999C14.3996 13.3254 13.3251 14.3999 11.9996 14.3999Z"
        fill={color}
      />
      <path
        d="M11.9996 21.5999C10.6741 21.5999 9.59961 20.5254 9.59961 19.1999C9.59961 17.8744 10.6741 16.7999 11.9996 16.7999C13.3251 16.7999 14.3996 17.8744 14.3996 19.1999C14.3996 20.5254 13.3251 21.5999 11.9996 21.5999Z"
        fill={color}
      />
    </svg>
  )
}
