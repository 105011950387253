import { Card, useMediaQuery } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import PropTypes from 'prop-types'
import React, { Children, cloneElement } from 'react'
// import Button from '../../elements/button/Button'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import {
  ComponentPropType,
  defaultExporter,
  ExporterContext,
  getListControllerProps,
  useCheckMinimumRequiredProps,
  useListController,
  useTranslate,
} from 'ra-core'
import { Link } from 'react-router-dom'
import DefaultBreadCrumbs from '../detail/BreadCrumbs'
import Subtitle from '../layout/Subtitle'
import Title, { TitlePropType } from '../layout/Title'
import BulkActionsToolbar from './BulkActionsToolbar'
import Empty from './Empty'
import DefaultActions from './ListActions'
import ListToolbar from './ListToolbar'
import DefaultPagination from './Pagination'

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * The <List> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - filter (the permanent filter to apply to the query)
 * - filters (a React component used to display the filter form)
 * - pagination
 * - perPage
 * - sort
 * - title
 *
 * @example
 *
 * const PostFilter = (props) => (
 *     <Filter {...props}>
 *         <TextInput label="Search" source="q" alwaysOn />
 *         <TextInput label="Title" source="title" />
 *     </Filter>
 * );
 * export const PostList = (props) => (
 *     <List {...props}
 *         title="List of posts"
 *         sort={{ field: 'published_at' }}
 *         filter={{ is_published: true }}
 *         filters={PostFilter}
 *     >
 *         <Datagrid>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 */

const CreateButton = ({ extraCreateButton, extraCreateButtonLabel, basePath }) => {
  const translate = useTranslate()
  return (
    <div
      style={{
        marginTop: 100,
        textAlign: 'center',
        padding: '0 12px',
      }}
    >
      {extraCreateButton ? (
        extraCreateButton
      ) : (
        <Link style={{ textDecoration: 'none' }} to={`${basePath}/create`}>
          <Button
            variant="contained"
            style={{
              margin: 'auto',
            }}
            name="raCreateButton"
          >
            <span>{translate(extraCreateButtonLabel || 'Create A New Record')}</span>
          </Button>
        </Link>
      )}
    </div>
  )
}

const getBasePath = (location) => location.pathname.split('/').slice(0, -1).join('/')

const List = ({ basePath, id, ...props }) => {
  // set basePath and id automatically
  const newProps = {
    ...props,
    basePath: basePath || getBasePath(props.location),
  }
  return <ListView {...newProps} {...useListController(newProps)} />
}
// const List = (props) => <ListView {...props} {...useListController(props)} />

List.propTypes = {
  // the props you can change
  actions: PropTypes.element,
  aside: PropTypes.element,
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.element,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: TitlePropType,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  basePath: PropTypes.string,
  id: PropTypes.string,
  path: PropTypes.string,
  resource: PropTypes.string,
  hasSearch: PropTypes.bool,
  showNoResultsPage: PropTypes.bool,
  breadCrumbs: PropTypes.element,
  empty: PropTypes.element,
}

List.defaultProps = {
  filter: {},
  perPage: 10,
}

export const ListView = (props) => {
  const {
    actions,
    aside,
    filter,
    filters,
    bulkActionButtons,
    hasBulkActions,
    pagination,
    children,
    className,
    classes: classesOverride,
    component: Content,
    exporter = defaultExporter,
    title,
    record,
    hasSearch,
    subtitle,
    breadCrumbs,
    hasList,
    hideBreadCrumbs,
    empty,
    hideTitle,
    options,
    showNoResultsPage,
    ...rest
  } = props
  useCheckMinimumRequiredProps('List', ['children'], props)
  const classes = useStyles(props)
  const { defaultTitle, version, total, loaded, loading, hasCreate, filterValues, resource } = rest
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const controllerProps = getListControllerProps(rest)
  const finalTitle = title || (options.list && options.list.title)
  const finalSubtitle = subtitle || (options.list && options.list.subtitle)

  const renderList = () => (
    <>
      {!hideBreadCrumbs &&
        resource &&
        React.cloneElement(breadCrumbs, {
          resource,
          hasList,
          detail: 'list',
          options,
        })}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: !hideTitle || filters || actions ? 68 : 0,
          alignItems: 'center',
          flexWrap: isSmall ? 'wrap' : 'nowrap',
        }}
        className={'Ra-List-Toolbar-wrapper'}
      >
        {!hideTitle && (
          <Title
            title={finalTitle}
            record={record}
            defaultTitle={defaultTitle}
            filterValues={controllerProps.filterValues}
            setFilters={controllerProps.setFilters}
            {...controllerProps}
          />
        )}
        {(filters || actions) && (
          <ListToolbar
            filters={filters}
            {...controllerProps}
            hasSearch={hasSearch}
            actions={actions}
            exporter={exporter} // deprecated, use ExporterContext instead
            permanentFilter={filter}
          />
        )}
      </div>
      {finalSubtitle && <Subtitle subtitle={finalSubtitle} />}
      <div id="Ra-List-Search-Input-Wrapper"></div>
      <div className={classnames('Ra-List-Main', classes.main)}>
        <Content
          className={classnames(classes.content, {
            [classes.bulkActionsDisplayed]: controllerProps.selectedIds.length > 0,
          })}
          key={version}
        >
          {bulkActionButtons && <BulkActionsToolbar {...controllerProps}>{bulkActionButtons}</BulkActionsToolbar>}
          {children &&
            cloneElement(Children.only(children), {
              ...controllerProps,
              hasBulkActions: bulkActionButtons || hasBulkActions,
            })}
          {pagination && cloneElement(pagination, controllerProps)}
        </Content>
        {aside && cloneElement(aside, controllerProps)}
      </div>
    </>
  )

  const shouldRenderEmptyPage =
    hasCreate && loaded && !loading && !total && !Object.keys(filterValues).length && showNoResultsPage

  return (
    <ExporterContext.Provider value={exporter}>
      <div className={classnames('list-page', classes.root, className)} {...sanitizeRestProps(rest)}>
        {shouldRenderEmptyPage ? cloneElement(empty, controllerProps) : renderList()}
        {/* to do clean this */}
        {resource !== 'projects' && hasCreate && total < 1 && !filterValues.q && showNoResultsPage ? (
          <CreateButton {...props} />
        ) : null}
      </div>
    </ExporterContext.Provider>
  )
}

ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  bulkActionButtons: PropTypes.element,
  hasBulkActions: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  component: ComponentPropType,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: TitlePropType,
  total: PropTypes.number,
  version: PropTypes.number,
}

// const DefaultBulkActionButtons = (props) => <BulkDeleteButton {...props} />

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  options: {},
  component: Card,
  hideTitle: false,
  // bulkActionButtons: <DefaultBulkActionButtons />,
  // hide bulk action by default
  breadCrumbs: <DefaultBreadCrumbs />,
  bulkActionButtons: false,
  pagination: <DefaultPagination />,
  empty: <Empty />,
  showNoResultsPage: true,
}

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: 'flex',
      paddingTop: 10,
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create('margin-top'),
      position: 'relative',
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
      },
      overflow: 'inherit',
    },
    bulkActionsDisplayed: {
      marginTop: '0px',
      transition: theme.transitions.create('margin-top'),
    },
    actions: {
      zIndex: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    noResults: { padding: 20 },
  }),
  { name: 'RaList' }
)

const sanitizeRestProps = ({
  actions,
  basePath,
  changeListParams,
  children,
  classes,
  className,
  crudGetList,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  exporter,
  filter,
  filterDefaultValues,
  filters,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  loading,
  loaded,
  locale,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  pagination,
  params,
  permissions,
  perPage,
  push,
  query,
  refresh,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSelectedIds,
  setSort,
  showFilter,
  sort,
  title,
  toggleItem,
  total,
  version,
  empty,
  ...rest
}) => rest

export default List
