import { FC } from 'react'

/**
 * * Icon Component for: data-03
 */
type DeleteOutlineIconProps = {
  size?: number
  color?: string
}
export const DeleteOutlineIcon: FC<DeleteOutlineIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg focusable="false" viewBox="0 0 24 24" {...rest} {...iconAttributes}>
      <path
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"
        fill={color}
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}
