import { FC } from 'react'

/**
 * * Icon Component for: file icon
 */
type FileIconProps = {
  size?: number
  color?: string
}
export const FileIcon: FC<FileIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
  }
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <g id="file-02-filled">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.11095 3C5.03706 3 4.1665 3.87056 4.1665 4.94444V16.0556C4.1665 17.1294 5.03706 18 6.11095 18H13.8887C14.9626 18 15.8332 17.1294 15.8332 16.0556V4.94444C15.8332 3.87056 14.9626 3 13.8887 3H6.11095ZM6.0415 5.91667C6.0415 5.57149 6.32133 5.29167 6.6665 5.29167H13.3332C13.6783 5.29167 13.9582 5.57149 13.9582 5.91667C13.9582 6.26184 13.6783 6.54167 13.3332 6.54167H6.6665C6.32133 6.54167 6.0415 6.26184 6.0415 5.91667ZM6.0415 8.83333C6.0415 8.48816 6.32133 8.20833 6.6665 8.20833H9.1665C9.51168 8.20833 9.7915 8.48816 9.7915 8.83333C9.7915 9.17851 9.51168 9.45833 9.1665 9.45833H6.6665C6.32133 9.45833 6.0415 9.17851 6.0415 8.83333ZM6.6665 11.125C6.32133 11.125 6.0415 11.4048 6.0415 11.75C6.0415 12.0952 6.32133 12.375 6.6665 12.375H11.6665C12.0117 12.375 12.2915 12.0952 12.2915 11.75C12.2915 11.4048 12.0117 11.125 11.6665 11.125H6.6665Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
