import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useVisibleStructLogic } from 'persistentContent/utils/useVisibleStructLogic'

export const useToastNotificationLogic = () => {
  const {
    open,
    currentKey: currentNotifKey,
    setCurrentKey: setCurrentNotifKey,
    forceHide,
    setForceCheck,
    currentItem: currentNotif,
    trackAllDismissed,
  } = useVisibleStructLogic('toast-notification')

  const onCloseComplete = () => {
    if (open && forceHide) {
      // Don't clear if this was just the result of forceHide
      return
    }
    trackAllDismissed()
    logAmplitudeEvent('toast_notification_dismissed', { notif_id: currentNotifKey })
    setCurrentNotifKey(undefined)
    setForceCheck((prev) => prev + 1)
  }

  const onNotifInteract = () => {
    logAmplitudeEvent('toast_notification_accepted', { notif_id: currentNotifKey })
  }

  return {
    open: open && (forceHide === undefined || !forceHide),
    onCloseComplete,
    onInteract: onNotifInteract,
    currentNotif,
    currentNotifKey,
  }
}
