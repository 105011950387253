import { makeStyles, Typography } from '@material-ui/core'
import { CopyValuesButton } from 'elements/react-admin/CopyValuesButton'
import TinyBox from 'elements/TinyBox'
import { TinyLabel } from 'elements/TinyLabel'
import Tooltip from 'elements/tooltip/Tooltip'
import { Button, Dialog, DialogCloseButton, DialogContent, DialogTitle, EyeIcon } from 'opensolar-ui'
import { useState } from 'react'
import { useForm } from 'react-final-form'
import { doNotTranslate } from 'util/misc'
import { StructVersionBrief } from './types'

const useStyles = makeStyles(
  (theme) => ({
    versions: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      overflowY: 'auto',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    },
    buttons: { paddingTop: 8, width: '100%', display: 'flex', gap: 8 },
    comment: {
      whiteSpace: 'pre-wrap',
      background: theme.palette.grey[200],
      maxWidth: 300,
      width: '100%',
      padding: 8,
      boxSizing: 'border-box',
      borderRadius: 4,
      fontFamily: 'monospace',
    },
  }),
  { name: 'StructVersionList' }
)

type Props = { versions?: StructVersionBrief[] }

export const StructVersionList = ({ versions }: Props) => {
  const classes = useStyles()
  const form = useForm()
  const [previewing, setPreviewing] = useState<undefined | StructVersionBrief>()

  const restore = (v: StructVersionBrief) => {
    form.change('data', v.fields.data)
  }

  const preview = (v: StructVersionBrief) => {
    setPreviewing(v)
  }

  if (!versions?.length) return <></>
  return (
    <div>
      <Typography variant="h6">{doNotTranslate('History')}</Typography>
      <div className={classes.versions}>
        {versions.map((v, i) => (
          <TinyBox key={v.hash} title={v.hash}>
            <TinyLabel label={doNotTranslate('Author:')}>{v.author?.email || 'Auto-created'}</TinyLabel>
            <TinyLabel label={doNotTranslate('At:')}>{v.created_at}</TinyLabel>
            <div className={classes.buttons}>
              <Tooltip title={doNotTranslate('Copy Data')}>
                <CopyValuesButton size="small" label="" values={v.fields} resource="" />
              </Tooltip>
              <Button onClick={() => preview(v)} variant="contained" size="small" color="default">
                <EyeIcon />
                {doNotTranslate('View')}
              </Button>
              <Button onClick={() => restore(v)} variant="outlined" size="small" color="primary">
                {doNotTranslate('Restore')}
              </Button>
            </div>
          </TinyBox>
        ))}
      </div>
      <Dialog open={!!previewing} onClose={() => setPreviewing(undefined)}>
        <DialogTitle>
          <span>{doNotTranslate('Previewing: ' + previewing?.hash)}</span>
          <DialogCloseButton onClick={() => setPreviewing(undefined)} />
        </DialogTitle>
        <DialogContent>
          <div className="code-block">{JSON.stringify(previewing?.fields, null, ' ')}</div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
