import { FC } from 'react'

/**
 * * Icon Component for: flip-right
 */
type FlipRightIconProps = {
  size?: number
  color?: string
}
export const FlipRightIcon: FC<FlipRightIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M16.0457 1.80019L20.5996 6.41569M20.5996 6.41569L16.0457 11.0312M20.5996 6.41569L5.39961 6.41569C3.19047 6.41569 1.39961 8.20655 1.39961 10.4157L1.39961 12.2002C1.39961 14.4093 3.19047 16.2002 5.39961 16.2002L10.9996 16.2002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
