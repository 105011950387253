import { TableCell as Mui4TableCell, TableCellProps as Mui4TableCellProps } from '@material-ui/core'
import { default as Mui5TableCell, TableCellProps as Mui5TableCellPropsType } from '@mui/material/TableCell'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsTableVersion } from './Table'

export const TableCell: FC<OsTableCellProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="TableCell"
      propDefs={TableCellProps}
      showExample={true}
      component={OsTableCell}
      props={{ ...props, ref }}
    />
  )
})

export type OsTableCellProps = Mui4TableCellProps

const OsTableCell: FC<OsTableCellProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('table')
  switch (version) {
    case OsTableVersion.V2:
      const { padding, ...rest } = props
      let padding5: Mui5TableCellPropsType['padding'] = padding === 'default' ? 'normal' : padding
      return <Mui5TableCell ref={ref} padding={padding5} {...rest} />

    default:
      return <Mui4TableCell ref={ref} {...props} />
  }
})

const TableCellProps: PropsDefs = {}
