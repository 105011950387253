import _ from 'lodash'
import { ModuleGridType } from 'types/global'
import { formatNumber } from '../../common/helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: number }) => {
  const slope = formatNumber(moduleGrid.getSlope())
  const tiltOverride = value + slope
  window.editor.execute(
    new window.SetPanelConfigurationCommand(
      moduleGrid,
      'panelTiltOverride',
      tiltOverride,
      window.Utils.generateCommandUUIDOrUseGlobal()
    )
  )
}

const getter = (moduleGrid: ModuleGridType): number => {
  return _.isNumber(moduleGrid.panelTiltOverride) ? formatNumber(moduleGrid.panelTiltOverride) : 0
}

export const tiltAccessorConfig: BasicAccessorConfig<number> = {
  get: getter,
  set: setter,
}
