import { FC } from 'react'

/**
 * * Icon Component for: arrow-right-sm
 */
type ArrowVerticalCenterIconProps = {
  size?: number
  color?: string
}
export const ArrowVerticalCenterIcon: FC<ArrowVerticalCenterIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <path
        d="M5.32884 7.81836L7.59557 10.0002M7.59557 10.0002L5.32885 12.182M7.59557 10.0002H2M14.6712 12.182L12.4044 10.0002M12.4044 10.0002L14.6712 7.81836M12.4044 10.0002L18 10.0002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
