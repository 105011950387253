import { Box } from '@material-ui/core'
import { BugReportOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import ProjectName from 'elements/ProjectName'
import { Button, styled, Typography } from 'opensolar-ui'
import PreviewInvoiceLink from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions/PreviewInvoiceLink'
import React, { useState } from 'react'
import { Datagrid, FunctionField, List, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import AccountingSyncStatusChip from './AccountingSyncChip'
import DownloadInvoiceDataButton from './DownloadInvoiceDataButton'
import InvoiceStatusChip from './InvoiceStatusChip'
import InvoiceTableActions from './InvoiceTableActions'
import InvoicesFilter from './InvoicesFilter'
import InvoicesSummarySection from './InvoicesSummarySection'
import InvoiceTableHeader from './InvoicesTableHeader'
import StandaloneInvoiceBtn from './standaloneInvoices/StandaloneInvoiceBtn'

type PropTypes = {}

const ProjectCell = styled('div')({
  lineHeight: 1.5,
  margin: '8px 0',
})

const Row = styled('div')({
  display: 'flex',
  gap: 10,
  float: 'right',
  position: 'relative',
  top: 38,
  right: 25,
})

const Invoices: React.FC<PropTypes> = (props) => {
  const [showDebug, setShowDebug] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(countryIso2)
  const translate = useTranslate()
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const activeAccountingIntegrations = useSelector(orgSelectors.getActiveAccountingIntegrations)

  return (
    <Form
      onSubmit={() => {}}
      render={() => (
        <Box>
          <InvoicesSummarySection refreshKey={refreshKey} />

          <Row>
            <StandaloneInvoiceBtn setRefreshKey={setRefreshKey} />
            <DownloadInvoiceDataButton />
          </Row>

          <List
            key={refreshKey}
            hasSearch={true}
            sort={{ field: 'issued_date', order: 'DESC' }}
            perPage={20}
            toolbar={<div></div>}
            filters={<InvoicesFilter />}
            hideBreadCrumbs={true}
            resource="cashflow_invoices"
            basePath="cashflow_invoices"
            id="cashflow_invoices"
            hideTitle={true}
          >
            <Datagrid
              // @ts-ignore
              header={(stuff) => <InvoiceTableHeader />}
            >
              <FunctionField
                source="invoice_number"
                sortable={false}
                label={'Invoice Number'}
                render={(record) => (
                  <PreviewInvoiceLink invoiceDetails={{ id: record.id, invoice_num: record.invoice_number }} />
                )}
              />
              {activeAccountingIntegrations?.length && (
                <FunctionField
                  source="sync_status"
                  sortable={false}
                  label={'Accounting Sync'}
                  render={(record) => (
                    <AccountingSyncStatusChip
                      status={record?.invoice_sync_status?.status}
                      failureReason={record?.invoice_sync_status?.failure_reason}
                      successDate={record?.invoice_sync_status?.sync_success_date}
                      lastAttemptDate={record?.invoice_sync_status?.last_attempt_date}
                      orgId={record?.org_id}
                      invoiceId={record?.id}
                      showDebug={showDebug}
                    />
                  )}
                />
              )}
              <FunctionField
                source="issued_date"
                sortable={false}
                label={'Project'}
                render={(record) => (
                  <ProjectCell>
                    <Typography textVariant="body1Bold">
                      {record?.project?.id ? (
                        <Link
                          to={`/projects/${record?.project?.id}/payments`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ProjectName address={record?.project?.address} />
                        </Link>
                      ) : (
                        <div>{translate('No project')}</div>
                      )}
                    </Typography>
                    <Typography textVariant="helper1">
                      {record?.contact?.recipient_first_name?.charAt(0)} {record?.contact?.recipient_last_name}
                    </Typography>
                  </ProjectCell>
                )}
              />

              <FunctionField
                source="payment_milestone"
                sortable={true}
                label={'Milestone'}
                render={(record) => <div>{record.payment_milestone}</div>}
              />
              <FunctionField
                source="status"
                sortable={true}
                label={'Status'}
                render={(record) => record.status && <InvoiceStatusChip status={record.status} />}
              />

              <FunctionField
                source="issued_date"
                sortable={true}
                label={'Issue Date'}
                render={(record) => (
                  <div>{record?.issued_date && new Date(record?.issued_date).toLocaleDateString()}</div>
                )}
              />
              <FunctionField
                source="due_date"
                sortable={true}
                label={'Due Date'}
                render={(record) => <div>{record?.due_date && new Date(record?.due_date).toLocaleDateString()}</div>}
              />
              <FunctionField
                source="date_paid_out"
                sortable={true}
                label={'Paid Date'}
                render={(record) => (
                  <div>{record?.date_paid_out && new Date(record?.date_paid_out).toLocaleDateString()}</div>
                )}
              />

              <FunctionField
                source="amount_requested"
                sortable={true}
                label={'Amount Invoiced'}
                render={(record) => <div>{formatCurrencyWithSymbol(record.amount_requested, currencySymbol)}</div>}
              />

              <FunctionField
                source="amount_paid_out"
                sortable={true}
                label={'Payment Received'}
                render={(record) => <div>{formatCurrencyWithSymbol(record.amount_paid_out, currencySymbol)}</div>}
              />

              <FunctionField
                source=""
                sortable={false}
                label={'Actions'}
                render={(record) => <InvoiceTableActions record={record} />}
              />
            </Datagrid>
          </List>
          {isStaff && activeAccountingIntegrations?.length ? (
            <div>
              <Button onClick={() => setShowDebug(!showDebug)}>
                <BugReportOutlined />
                Staff Only - Toggle Accouting Debug Mode
              </Button>
            </div>
          ) : null}
        </Box>
      )}
    />
  )
}

export default Invoices
