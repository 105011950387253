import Alert from 'elements/Alert'
import CheckoutRoot from 'myenergy/dialogs/checkout'
import { PureComponent } from 'react'
import { withTranslate } from 'react-admin'
import { GROUP_BREAK_PREFIX } from 'resources/proposalTemplates/SectionConfiguration'
import { detectIframeProposalTemplate, getTemplateSettings } from '../../util/misc'
import { LetterDialog } from '../dialogs/LetterDialog'
import ActionButtonRow from '../elements/ActionButtonRow'
import EmbedVideo from '../elements/EmbedVideo'
import EnergyFlowDiagram from '../elements/EnergyFlowDiagram'
import EnergySupply from '../elements/EnergySupply'
import EnvironmentalBenefits from '../elements/EnvironmentalBenefits'
import FinancialBenefits from '../elements/FinancialBenefits'
import PaymentOptions from '../elements/PaymentOptions'
import { letterIsAvailable } from '../prepareProposalData'
import ViewPort from '../ViewPort'
import Annotations from './components/Annotations'
import BillSavings from './components/billSavings'
import ExhibitCustomTile from './components/ExhibitCustomTile'
import FeatureFigures from './components/FeatureFigures'
import IframeBespoke from './components/IframeBespoke'
import IframeWithPost from './components/IframeWithPost'
import PoweredBy from './components/PoweredBy'
import ProposalV2 from './components/proposalV2/ProposalV2'
import RenderDialog from './components/RenderDialog'
import { SolarProvider } from './components/SolarProvider'
import SystemHardware from './components/SystemHardware'

// const validationErrorsToMessage = (last_calculation_error) => {
//   var parts = []
//   for (let field in last_calculation_error) {
//     let value = last_calculation_error[field]
//     if (value.length) {
//       // error is simple string (not the standard case, but may occur)
//       parts.push(field + ': ' + value)
//     } else {
//       // error is array of messages
//       value.forEach((message) => parts.push(field + ': ' + message))
//     }
//   }
//   return parts.length ? parts.map((part) => <span>{part} </span>) : null
// }

class MainContentComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      paymentChart: 'bank_balance',
    }
  }

  selectPaymentChart(paymentChart) {
    this.setState({
      paymentChart,
    })
  }

  getDefaultSection(sectionsData, section, group, proposalTemplateSettings) {
    if (section.includes('content_block')) {
      const indexOfSection = sectionsData.indexOf(section)
      const nextSection = sectionsData[indexOfSection + 1]
      const groupBreak = nextSection ? nextSection.replace(GROUP_BREAK_PREFIX, '') : 'top'
      if (groupBreak === group) {
        let dangerouslySetInnerHTML =
          groupBreak === 'systems_group' && proposalTemplateSettings.selectedSystem
            ? proposalTemplateSettings.selectedSystem[section]
            : proposalTemplateSettings[section]
        return (
          <section key={section}>
            <div className="mye-generic-block">
              <div
                dangerouslySetInnerHTML={{
                  __html: dangerouslySetInnerHTML,
                }}
              />
            </div>
          </section>
        )
      }
    }
    return null
  }

  render() {
    const {
      proposalData,
      proposalDataRaw,
      isMobile,
      activeDialog,
      showDialog,
      translate,
      layout,
      onPaymentMethodChange,
      onSystemChange,
      setSelectedViewUuid,
      quotationConfiguration,
      isPro,
      isNestedInProjectForm,
      fetchProjectData,
    } = this.props

    const { paymentChart } = this.state
    const sectionsData = getTemplateSettings(proposalData.proposalTemplateSettings, 'sections')
    const myeStyles = proposalData.myeStyles
    const showAboutUsContent = !Boolean(proposalData.proposalTemplateSettings.hide_about_us_content_in_letter)
    const attachedPhoenixPaymentOption = proposalData?.selectedProject?.proposal_data?.systems?.find(
      (sys) => sys?.data?.uuid === proposalData.selectedSystem.uuid
    )?.attached_phoenix_payment_option

    const { isIframe, isProposalV2 } = detectIframeProposalTemplate(proposalData.proposalTemplateSettings)

    // Keep 1px right margin for bespoke to preserve the border separator on the right sidebar selection component
    const mainContentMargin = !isIframe ? 20 : '0px 1px 0px 0px'

    const isProjectSold = proposalData?.selectedProject?.project_sold
    return (
      <div>
        <LetterDialog
          org={proposalData.org}
          showAboutUsContent={showAboutUsContent}
          selectedProject={proposalData.selectedProject}
          title={proposalData.proposalTemplateSettings.heading_cover}
          content={letterIsAvailable(proposalData) ? proposalData.selectedProject.proposal_data.proposal_message : null}
          myeStyles={myeStyles}
          overrideLogo={proposalData.proposalTemplateSettings.override_logo_public_url}
        />
        {proposalData.checkoutHidden &&
          !isProposalV2 &&
          !isProjectSold &&
          proposalData.proposalTemplateSettings.proposal_checkout_message != null &&
          proposalData.proposalTemplateSettings.proposal_checkout_message.replace(/<[^>]+>|\s|(?:&nbsp;)/g, '').trim()
            .length > 0 && (
            <div
              id="proposal-checkout-message"
              style={{ margin: mainContentMargin }}
              dangerouslySetInnerHTML={{ __html: proposalData.proposalTemplateSettings.proposal_checkout_message }}
            />
          )}
        {proposalData.checkoutHidden &&
          !isProjectSold &&
          !isProposalV2 &&
          isPro &&
          !window.location.href.includes('mode=customerView') && (
            <div style={{ margin: mainContentMargin }}>
              <Alert severity="info">
                {translate(
                  "This proposal's checkout is disabled. Based on the proposal template settings you can update the valid until date by clicking on 'Customise Proposal' or updating the proposal template settings (Advanced Settings > Proposal Checkout Settings)"
                )}
              </Alert>
            </div>
          )}
        {sectionsData.indexOf('systems_group') !== -1 && (
          <FeatureFigures proposalData={proposalData} isMobile={isMobile} translate={translate} />
        )}
        {sectionsData.indexOf('systems_group') !== -1 && (
          <ViewPort {...proposalData} setSelectedViewUuid={setSelectedViewUuid} isMobile={isMobile} isPro={isPro} />
        )}
        <div style={{ margin: mainContentMargin }}>
          {sectionsData.indexOf('systems_group') !== -1 && (
            <Annotations annotations={proposalData.selectedSystem && proposalData.selectedSystem.annotations} />
          )}
          {isProposalV2 && (
            <ProposalV2
              key={'proposalV2Iframe'}
              proposalData={proposalData}
              onPaymentMethodChange={onPaymentMethodChange}
              onSystemChange={onSystemChange}
              isNestedInProjectForm={isNestedInProjectForm}
              fetchProjectData={fetchProjectData}
            />
          )}
          {sectionsData.map((section) => {
            switch (section) {
              case 'iframe_bespoke':
                return (
                  <IframeBespoke
                    key={section}
                    bespokeProposalUrl={
                      // First URL which does not include "pdf_only"
                      proposalData.proposalTemplateSettings?.bespoke_proposal_url
                        .split(',')
                        .find((bespoke_proposal_url) => !bespoke_proposal_url.includes('pdf_only'))
                    }
                    proposalDataRaw={proposalDataRaw}
                    showDialog={showDialog}
                  />
                )

              case 'iframe_with_post_data':
                return (
                  <IframeWithPost
                    key={section}
                    proposalId={proposalData.proposalTemplateSettings?.pvprosell_template_id}
                    proposalDataRaw={proposalDataRaw}
                    systemIndex={
                      proposalData?.selectedProject?.proposal_data?.systems?.length
                        ? proposalData.selectedProject.proposal_data.systems
                            .map((s) => s.data.uuid)
                            .indexOf(proposalData.selectedSystem.uuid)
                        : null
                    }
                    paymentOptionIndex={
                      proposalData?.selectedSystem?.payment_options?.length
                        ? proposalData.selectedSystem.payment_options
                            .map((po) => po.id)
                            .indexOf(proposalData.selectedPaymentOption.id)
                        : null
                    }
                  />
                )

              case 'systems_group':
                return (
                  <div key={section}>
                    {sectionsData.map((systemSection) => {
                      switch (systemSection) {
                        case 'system_hardware':
                          return (
                            <section key={systemSection}>
                              <SystemHardware
                                key={systemSection}
                                showDialog={showDialog}
                                selectedSystem={proposalData.selectedSystem}
                                proposalData={proposalData}
                                proposalTemplateSettings={proposalData.proposalTemplateSettings}
                                isMobile={isMobile}
                              />
                            </section>
                          )
                        case 'exhibit_tile_hardware': {
                          return sectionsData.map((exhibitTileSection) => {
                            switch (exhibitTileSection) {
                              case 'exhibit_tile_module':
                                return (
                                  <section key={exhibitTileSection}>
                                    <ExhibitCustomTile system={proposalData.selectedSystem} categories={['module']} />
                                  </section>
                                )

                              case 'exhibit_tile_inverter':
                                return (
                                  <section key={exhibitTileSection}>
                                    <ExhibitCustomTile
                                      system={proposalData.selectedSystem}
                                      categories={['inverters']}
                                    />
                                  </section>
                                )

                              case 'exhibit_tile_battery':
                                return (
                                  <section key={exhibitTileSection}>
                                    <ExhibitCustomTile
                                      system={proposalData.selectedSystem}
                                      categories={['batteries']}
                                    />
                                  </section>
                                )

                              case 'exhibit_tile_other':
                                return (
                                  <section key={exhibitTileSection}>
                                    <ExhibitCustomTile system={proposalData.selectedSystem} categories={['others']} />
                                  </section>
                                )

                              default:
                                return null
                            }
                          })
                        }

                        // monthly energy
                        case 'energy_supply':
                          return (
                            <section key={systemSection}>
                              <EnergySupply
                                key={systemSection}
                                {...proposalData}
                                showDialog={(name) => showDialog(name)}
                                dailyEnergyFlows={sectionsData.indexOf('daily_energy_flows') !== -1}
                                selfConsumptionBreakdown={sectionsData.indexOf('self_consumption_breakdown') !== -1}
                                layout={layout}
                              />
                            </section>
                          )

                        // monthly energy
                        case 'business_video_link':
                          if (proposalData.proposalTemplateSettings.business_video_link) {
                            return (
                              <section key={section}>
                                <EmbedVideo key={section} {...proposalData} layout={layout} />
                              </section>
                            )
                          } else {
                            return null
                          }

                        case 'how_your_system_works':
                          return (
                            <section key={systemSection}>
                              <h1 className="mye-section-title" style={{ pageBreakBefore: 'always' }}>
                                {proposalData.proposalTemplateSettings.heading_how_your_system_works}
                              </h1>
                              <EnergyFlowDiagram
                                solarkW={proposalData.selectedSystem.kw_stc}
                                batteryKwh={proposalData.selectedSystem.battery_total_kwh}
                                showBlackoutEvenWithoutBattery={false && true}
                                myeStyles={proposalData.myeStyles}
                                isCommercial={
                                  proposalData.selectedProject &&
                                  proposalData.selectedProject.proposal_data &&
                                  proposalData.selectedProject.proposal_data.project &&
                                  proposalData.selectedProject.proposal_data.project.is_commercial
                                }
                                layout={layout}
                                country_iso2={proposalData.org.country_iso2}
                              />
                            </section>
                          )

                        case 'environmental_benefits':
                          return (
                            <section key={systemSection}>
                              <EnvironmentalBenefits layout={layout} key={systemSection} proposalData={proposalData} />
                            </section>
                          )
                        case 'payment_options_group': {
                          return sectionsData.map((paymentOptionSection) => {
                            switch (paymentOptionSection) {
                              case 'bill_savings':
                                return (
                                  <section key={paymentOptionSection}>
                                    <BillSavings
                                      key={paymentOptionSection}
                                      {...proposalData}
                                      sectionsData={sectionsData}
                                      getDefaultSection={this.getDefaultSection}
                                      showDialog={(name) => showDialog(name)}
                                      translate={translate}
                                      layout={layout}
                                      hasPhoenix={!!attachedPhoenixPaymentOption}
                                    />
                                  </section>
                                )
                              case 'payment_options':
                                return (
                                  <section key={paymentOptionSection}>
                                    <PaymentOptions
                                      quotationConfiguration={quotationConfiguration}
                                      layout={layout}
                                      onPaymentMethodChange={onPaymentMethodChange}
                                      key={paymentOptionSection}
                                      {...proposalData}
                                      selectPaymentChart={this.selectPaymentChart.bind(this)}
                                      paymentChart={paymentChart}
                                      translate={translate}
                                    />
                                  </section>
                                )

                              case 'financial_benefits':
                                return (
                                  <section key={paymentOptionSection}>
                                    <FinancialBenefits
                                      key={paymentOptionSection}
                                      {...proposalData}
                                      showDialog={(name) => showDialog(name)}
                                      selectPaymentChart={this.selectPaymentChart.bind(this)}
                                      paymentChart={paymentChart}
                                      translate={translate}
                                      sectionsData={sectionsData}
                                      getDefaultSection={this.getDefaultSection}
                                      layout={layout}
                                      hasPhoenix={!!attachedPhoenixPaymentOption}
                                    />
                                  </section>
                                )

                              case 'exhibit_tile_utility_tariffs':
                                return (
                                  <section key={paymentOptionSection}>
                                    <ExhibitCustomTile
                                      system={proposalData.selectedSystem}
                                      categories={['utility_tariffs']}
                                    />
                                  </section>
                                )

                              case 'exhibit_tile_finance':
                                return (
                                  <section key={paymentOptionSection}>
                                    <ExhibitCustomTile
                                      selectedPaymentOption={proposalData.selectedPaymentOption}
                                      system={proposalData.selectedSystem}
                                      categories={['finance']}
                                    />
                                  </section>
                                )

                              default:
                                return this.getDefaultSection(
                                  sectionsData,
                                  paymentOptionSection,
                                  'payment_options_group',
                                  proposalData.proposalTemplateSettings
                                )
                            }
                          })
                        }
                        default:
                          return this.getDefaultSection(sectionsData, systemSection, 'systems_group', {
                            selectedSystem: proposalData.selectedSystem,
                            ...proposalData.proposalTemplateSettings,
                          })
                      }
                    })}
                  </div>
                )

              case 'solar_provider':
                if (!proposalData.org) {
                  return null
                } else {
                  return (
                    <section key={section}>
                      <SolarProvider
                        key={section}
                        {...proposalData}
                        selectedPaymentOption={proposalData.selectedPaymentOption}
                      />
                    </section>
                  )
                }

              case 'action_buttons':
                return (
                  <section key={section} style={{ marginTop: 0 }}>
                    <ActionButtonRow
                      key={section}
                      isMobile={isMobile}
                      showDialog={(name) => showDialog(name)}
                      myeStyles={myeStyles}
                      proposalData={proposalData}
                      testimonials={proposalData.selectedProject.testimonials}
                      org={proposalData.org}
                      assigned_team_member={proposalData.selectedProject.assigned_team_member}
                      hasNextSteps={
                        !!proposalData.selectedProject.proposal_data?.proposal_template_settings?.next_steps_content?.trim?.()
                          .length
                      }
                      isPro={isPro}
                    />
                  </section>
                )

              case 'spec_sheets':
                return proposalData.selectedProject.proposal_data.proposal_attachments &&
                  proposalData.selectedProject.proposal_data.proposal_attachments.length > 0 ? (
                  <section key={section} style={{ marginTop: 0 }}>
                    <hr className="light" />
                    <p className="small" style={{ fontSize: 12 }}>
                      {translate('File Downloads')}:{' '}
                      {proposalData.selectedProject.proposal_data.proposal_attachments.map(
                        (proposalAttachment, index) => {
                          return (
                            <span key={index} style={{ display: 'inline-block' }}>
                              <a
                                href={proposalAttachment.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: 5 }}
                              >
                                {/* <NoteIcon style={{ verticalAlign: 'middle', marginRight: 5 }} /> */}
                                <span>{proposalAttachment.title}</span>
                              </a>
                            </span>
                          )
                        }
                      )}
                    </p>
                  </section>
                ) : null

              case 'opensolar_disclaimer':
                return (
                  <section key={section} style={{ marginTop: 0 }}>
                    <PoweredBy
                      key={section}
                      org={proposalData.org}
                      proposalTemplateSettings={proposalData.proposalTemplateSettings}
                      selectedSystem={proposalData.selectedSystem}
                      selectedPaymentOption={proposalData.selectedPaymentOption}
                    />
                  </section>
                )
              default:
                return this.getDefaultSection(sectionsData, section, 'top', proposalData.proposalTemplateSettings)
            }
          })}
        </div>
        {activeDialog && (
          <RenderDialog
            {...this.props}
            paymentChart={paymentChart}
            content={
              letterIsAvailable(proposalData) ? proposalData.selectedProject.proposal_data.proposal_message : null
            }
          />
        )}
        <CheckoutRoot
          projectId={proposalData.selectedProject?.id}
          systemUuid={proposalData.selectedSystem?.uuid}
          paymentOptionId={proposalData.selectedPaymentOption.id}
          orgId={proposalData.org?.id}
          proposalData={proposalData}
        />
      </div>
    )
  }
}

export default withTranslate(MainContentComponent)
