import { Alert, Button } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'

import { getComponentInSystem } from 'Designer/designRules/utils'
import { heatPumpSelectors, setShowHeatPumpAlert } from 'ducks/heatPump'
import { useAddComponentToSystem } from 'hooks/components/useAddComponentToSystem'
import useMatchImportedComponentToActiveComponents from 'projectSections/sections/energy/Heat/useMatchImportedComponentToActiveComponents'
import { HeatPumpType } from 'types/components'
import { RootState } from 'types/state'

const HeatPumpCTABox = ({ system }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const addComponentToSystem = useAddComponentToSystem()
  const matchImportedComponentToActiveComponents = useMatchImportedComponentToActiveComponents()

  const heatPumpData = useSelector(heatPumpSelectors.getHeatPumpData)?.data

  // This grabs the manufacturer/model info from only the first in the list of outdoor units for now
  const manufacturerName = heatPumpData?.billOfMaterials?.outdoorUnits?.[0]?.manufacturer
  const modelName = heatPumpData?.data?.billOfMaterials?.outdoorUnits?.[0]?.model
  const currentProjectId = useSelector((state: RootState) => state.projectId)
  const heatPumpProjectId = useSelector(heatPumpSelectors.getProjectId)

  const mapHeatPumpJSONDataToComponent = (jsonData: any): HeatPumpType => {
    return {
      // IDs are required for HeatPumpType, but these won't be used since they will come from the database
      id: 0,
      other_id: 0,
      // TODO: Placeholder to be able to lookup based on the code
      code: 'FTXM42A',
      title: 'Imported Partner Heat Pump',
      hasActivation: false,
      is_default: false,
      share_with_orgs: [],
      show_customer: true,
      external_data: null,
      // This only references the first outdoor unit in the JSON data
      description: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.model,
      manufacturer: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.manufacturer,
      manufacturer_name: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.manufacturer,
      ahri_reference_numbers: [jsonData?.billOfMaterials?.outdoorUnits?.[0]?.ahriReferenceNumber],
      mcs_certificate_number: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.mcsCertificateNumber,
      quantity: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.quantity,
      cost: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.pricePerUnit,
      product_warranty: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.warrantyYears,
      skus: jsonData?.billOfMaterials?.outdoorUnits?.[0]?.modelSku,
      other_component_type: 'heat_pump',
      // Assume that the heat pump used for heating is the same as the one used for cooling
      heat_pump_type: jsonData?.futureAnnualEnergyUsage?.heating?.heatPumpType,
      is_active: false,
      is_current: true,
      created_from_json: true,

      energy_data: {
        heating: {
          values: jsonData?.futureAnnualEnergyUsage?.heating?.values,
          fuel_type: jsonData?.futureAnnualEnergyUsage?.heating?.fuelType,
          units: jsonData?.futureAnnualEnergyUsage?.heating?.units,
          cost_per_unit: jsonData?.futureAnnualEnergyUsage?.heating?.costPerUnit,
        },
        cooling: {
          values: jsonData?.futureAnnualEnergyUsage?.cooling?.values,
          fuel_type: jsonData?.futureAnnualEnergyUsage?.cooling?.fuelType,
          units: jsonData?.futureAnnualEnergyUsage?.cooling?.units,
          cost_per_unit: jsonData?.futureAnnualEnergyUsage?.cooling?.costPerUnit,
        },
        hot_water: {
          fuel_type: jsonData?.futureAnnualEnergyUsage?.hotWater?.fuelType,
          units: jsonData?.futureAnnualEnergyUsage?.hotWater?.units,
          cost_per_unit: jsonData?.futureAnnualEnergyUsage?.hotWater?.costPerUnit,
          values: jsonData?.futureAnnualEnergyUsage?.hotWater?.values,
        },
      },

      system_performance_metrics: {
        COP: jsonData?.futureSystemPerformanceMetrics?.COP,
        SCOP: jsonData?.futureSystemPerformanceMetrics?.SCOP,
        EER: jsonData?.futureSystemPerformanceMetrics?.EER,
        SEER: jsonData?.futureSystemPerformanceMetrics?.SEER,
        maxFlowTemperatureC: jsonData?.futureSystemPerformanceMetrics?.maxFlowTemperatureC,
      },
    }
  }

  const handleAddHeatPump = async (heatPumpData, system) => {
    const componentType = 'other'

    const heatPumpComponentData = mapHeatPumpJSONDataToComponent(heatPumpData)
    const activeComponentsData = window.AccountHelper.getComponentOtherSpecsAvailable() as OtherType[]
    const matchedComponent = matchImportedComponentToActiveComponents({
      activeComponentsData,
      heatPumpComponentData,
    })
    // TODO: We also need to search all components, not just the activated ones

    if (!matchedComponent) {
      console.error('No matching component found for heat pump:', heatPumpComponentData)
      return
    }

    const componentActivationId = matchedComponent.id
    const componentCode = matchedComponent.code
    const quantity = 1

    console.log('Adding heat pump component:', heatPumpComponentData)
    await addComponentToSystem({
      componentType,
      componentActivationId,
      activeComponentsData,
      quantity,
    })
    const heatPumpComponent = getComponentInSystem(system, componentType, componentCode)

    // Set only the energy data for the new heat pump component, since the other attributes will be set from the database component
    window.editor.execute(
      new window.SetValueCommand(heatPumpComponent[0], 'energy_data', heatPumpComponentData.energy_data)
    )

    dispatch(setShowHeatPumpAlert(false))
  }

  const handleCancelHeatPumpCTA = () => {
    dispatch(setShowHeatPumpAlert(false))
  }

  const showAlert = useSelector(heatPumpSelectors.getShowHeatPumpAlert)
  const showHeatPumpAlert = showAlert && heatPumpData && currentProjectId === heatPumpProjectId

  return (
    showHeatPumpAlert && (
      <Alert severity="warning" style={{ marginBottom: 10, marginTop: 10 }}>
        {translate("You've uploaded heating data in the Energy Tab, would you like to add ")} {manufacturerName}{' '}
        {modelName} {translate('to this system?')}`
        <Button
          onClick={() => {
            handleAddHeatPump(heatPumpData, system)
          }}
        >
          {translate('Add Heat Pump')}
        </Button>
        <Button onClick={handleCancelHeatPumpCTA}>{translate('Cancel')}</Button>
      </Alert>
    )
  )
}

export default HeatPumpCTABox
