import { LoanAgreementActionButton } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import { useState } from 'react'
import type { CheckoutActionCallback, CheckoutActionElementOptions, ProposalDataType } from '../../types'
import type { LoanAgreementType } from '../../types/CheckoutElementsDataTypes'

const LoanAgreementAction = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: LoanAgreementType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }
  const { actionData, loanType } = elementData

  if (actionData.status_code === 'complete') {
    return (
      <div
        style={{
          textAlign: 'center',
          color: proposalData.myeStyles ? proposalData.myeStyles.textColor : 'black',
          margin: '15px 0px 15px 0px',
        }}
      >
        ✓ Your Loan Agreement has been signed!
      </div>
    )
  }

  return (
    <LoanAgreementActionButton
      isLoading={isLoading}
      label={
        <>
          {loanType === 'loanpal_application' && <div>{translate('Request Loan Docs Email')}</div>}
          {loanType === 'mosaic_application' && <div>{translate('Sign Your Loan Agreement')}</div>}
          {loanType === 'dividend_application' && <div>{translate('Sign Your Loan Agreement')}</div>}
        </>
      }
      disabled={isLoading}
      onClick={handleClick}
    />
  )
}

export default LoanAgreementAction
