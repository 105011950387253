import { Box as Mui4Box, BoxProps as Mui4BoxPropsType } from '@material-ui/core'
import { default as Mui5Box } from '@mui/material/Box'
import { SystemCssProperties } from '@mui/system'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { BaseThemeType } from '../theme'

// * MUI Box API Spec  https://mui.com/material-ui/api/Box/
export const Box: FC<BoxProps> = (props) => (
  <Inspector name="Box" propDefs={BoxPropsDefs} showExample={true} component={OsBox} props={props} />
)

export enum BoxVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type BoxProps = Mui4BoxPropsType

const OsBox: FC<BoxProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('box')

  switch (version) {
    case BoxVersion.V2:
      // Add support for gap props in MUI5
      // This assumes the props['sx'] is an object, which is not strictly true, but safe enough
      const sx: SystemCssProperties<BaseThemeType> = props['sx'] || {}
      if (props.gridGap) sx.gap = props.gridGap
      if (props.gridColumnGap) sx.columnGap = props.gridColumnGap
      if (props.gridRowGap) sx.rowGap = props.gridRowGap
      return <Mui5Box ref={ref} {...props} sx={sx} />

    default:
      return <Mui4Box {...props} />
  }
})

const BoxPropsDefs: PropsDefs = {}
