import { EditorPlaceholderCategory } from './types'

export const mapPlaceholders = (placeholderDefs: EditorPlaceholderCategory[]): Record<string, string> => {
  var placeholdersMapper = {}
  placeholderDefs?.forEach((section) => {
    section.items.forEach((a) => {
      placeholdersMapper[`{{${a.field}}}`] = a.label
    })
  })
  return placeholdersMapper
}

export const getToolbarButtons = (
  allowUploadImage,
  docusignSelected,
  moreMiscButtons: string[] = [],
  fieldName = '',
  mentionContext = 'empty'
) => ({
  moreText: {
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'clearFormatting',
      'fontSize',
      'textColor',
    ],
  },
  moreParagraph: {
    buttons: [
      'paragraphFormat',
      'formatOL',
      'formatUL',
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
      'outdent',
      'indent',
    ],
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: [
      mentionContext !== 'empty' && `placeholder_${fieldName}`,
      allowUploadImage ? 'insertImage' : '',
      // 'insertVideo',
      'insertTable',
      'insertLink',
      'lineBreak',
      'insertHR',
      'pageBreak',
    ],
    buttonsVisible: 2,
  },
  docusignButton: {
    buttons: docusignSelected ? ['docusign_button'] : [],
    buttonsVisible: 1,
  },
  moreMisc: {
    buttons: moreMiscButtons,
    align: 'right',
    buttonsVisible: 3,
  },
})
