import _ from 'lodash'
import { ModuleGridType } from 'types/global'
import type { MeasurementUnits } from 'types/orgs'
import { fromMeters, toMeters } from '../../common/helpers'
import type { BasicAccessorConfig } from './types'

const setter = ({ moduleGrid, value }: { moduleGrid: ModuleGridType; value: number }) => {
  if (moduleGrid.getGroundClearance() !== value) {
    window.editor.execute(new window.SetPanelConfigurationCommand(moduleGrid, 'groundClearance', value))
  }
}

const getter = (moduleGrid: ModuleGridType): number => {
  return _.isNumber(moduleGrid.getGroundClearance()) ? moduleGrid.getGroundClearance() : 0
}

const parse = (value: number | undefined, options: { measurements: MeasurementUnits }) => {
  if (value === undefined) return undefined
  const measurements = options?.measurements
  if (measurements === undefined) return value

  return fromMeters(value, measurements)
}

const format = (value: number | undefined, options: { measurements: MeasurementUnits }) => {
  if (value === undefined) return undefined
  const measurements = options?.measurements
  if (measurements === undefined) return value
  // what we save to studio MUST always be in meters
  return toMeters(value, measurements)
}

export const groundClearanceAccessorConfig: BasicAccessorConfig<number> & {
  format: typeof format
  parse: typeof parse
} = {
  get: getter,
  set: setter,
  parse,
  format,
}
