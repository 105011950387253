import { makeStyles } from '@material-ui/core'
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { AlertTriangleIcon } from '../icons/AlertTriangleIcon'
import { CheckContainedIcon } from '../icons/CheckContainedIcon'
import { InformationCircleIcon } from '../icons/InformationCircleIcon'
import { OsAlertVersion, Severities } from './Alert'

export const AlertIcon: FC<OsAlertIconProps> = (props) => {
  return (
    <Inspector name="AlertIcon" propDefs={AlertIconProps} showExample={true} component={OsAlertIcon} props={props} />
  )
}

interface OsAlertIconProps {
  severity?: Severities
}

const VersionIcons = (version) => {
  if (version === OsAlertVersion.V2)
    return {
      warning: AlertTriangleIcon,
      error: InformationCircleIcon,
      info: InformationCircleIcon,
      success: CheckContainedIcon,
    }
  return {
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    success: SuccessIcon,
  }
}
const v1Styles = makeStyles<any, { severity: Severities }>((theme) => ({
  icon: ({ severity }) => ({
    width: 22,
    height: 22,
    color: theme['alertIcon_' + severity],
    marginRight: 10,
  }),
}))

const OsAlertIcon = forwardRef<SVGSVGElement, OsAlertIconProps>((props, ref) => {
  const { severity = 'info' } = props
  let version = useComponentVersion('alert')
  const Icon = VersionIcons(version)[severity]

  switch (version) {
    case OsAlertVersion.V2:
      return <Icon ref={ref} fill={'inherit'} width={'18px'} height={'18px'} />

    default:
      const classes = v1Styles({ severity })
      return <Icon ref={ref} className={classes.icon} />
  }
})

const AlertIconProps: PropsDefs = {
  severity: { default: 'info' },
}
