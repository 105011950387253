import { FC } from 'react'

type Props = {
  color?: string
}
export const DownloadIcon: FC<Props> = ({ color = 'currentColor' }) => {
  const iconAttributes = {
    stroke: color,
  }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...iconAttributes}>
      <path
        d="M2.66669 13.5885C2.93649 13.852 3.30243 14 3.68399 14H12.3161C12.6976 14 13.0635 13.852 13.3334 13.5885M8.00082 2V9.96164M8.00082 9.96164L11.2892 6.91953M8.00082 9.96164L4.71242 6.91953"
        strokeWidth="1.2"
        stroke={color}
      />
    </svg>
  )
}
