import { orgSelectors } from 'ducks/orgs'
import _ from 'lodash'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import { useStudioSignalsLazy } from '../../../../Studio/signals/useStudioSignalsLazy'
import useHardwareDetailsDynamic from '../sideDrawer/bom/useHardwareDetailsDynamic'
import useDistributorPricing, { getLineItemPrice } from '../systems/tabs/pricing/useDistributorPricing'

const defaultComponentOverrides = {}

const useInjectDistributorCostOverride = () => {
  const [componentOverrides, setComponentOverrides] = useState(defaultComponentOverrides)
  const multiDistributor = useFeatureFlag('design_multi_distributor', 'on')
  const componentOverridesRef = useRef<object>()
  componentOverridesRef.current = componentOverrides
  const hardwareDetails = useHardwareDetailsDynamic()
  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const { codesToDistributorPricing, loading } = useDistributorPricing()

  const supplierFilterKeyRef = useRef<HardwareSupplierFilterKeyType>()
  const ukHardwareSuppliers = useSelector(orgSelectors.getEnableUKHardwareSuppliers)

  supplierFilterKeyRef.current = supplierFilterKey

  useEffect(() => {
    // There is probably a better way to make the override calculation happen when the compnent
    // pricing is done loading.
    if (!loading && hardwareDetails.length > 0 && !multiDistributor) {
      overrideComponentCost()
    }
  }, [loading, hardwareDetails])

  useEffect(() => {
    // Clear existing overrides from suppliers other than the current one.
    // There was a silent bug previously where the overrides were not cleared and the new overrides
    // were added on top of the existing ones.
    const systemUuid = window.editor.selectedSystem?.uuid
    const system = systemUuid && window.editor.objectByUuid(systemUuid)

    if (system) {
      const existingCogsOverrides = system.getCogsOverride()

      if (existingCogsOverrides) {
        Object.keys(existingCogsOverrides).forEach((activationId) => {
          const existingOverride = existingCogsOverrides[activationId]
          if (
            !['manual', 'component', 'unknown'].includes(existingOverride.source) &&
            existingOverride.source !== supplierFilterKeyRef.current
          ) {
            system.removeComponentCostOverride({ id: activationId })
          }
        })
      }
    }
    if (!multiDistributor) {
      overrideComponentCost()
    }
  }, [supplierFilterKeyRef.current])

  const overrideComponentCost = useCallback(() => {
    if (loading || hardwareDetails.length === 0 || multiDistributor) return

    const component_overrides =
      hardwareDetails.reduce(function (map, hardwareDetail) {
        const code = hardwareDetail.code
        const activationData = window.AccountHelper.getComponentActivationFromCode(code, hardwareDetail.type)
        const lineItemPrice = getLineItemPrice(
          hardwareDetail,
          supplierFilterKeyRef.current as HardwareSupplierFilterKeyType
        )

        if (lineItemPrice && activationData?.id) {
          map[activationData.id] = lineItemPrice
        }
        return map
      }, {}) || {}
    // const component_overrides = hardwareDetails.reduce{}

    if (!_.isEqual(componentOverridesRef.current, component_overrides)) {
      const systemUuid = window.editor.selectedSystem?.uuid
      const system = systemUuid && window.editor.objectByUuid(systemUuid)

      if (system) {
        const existingCogsOverrides = system.getCogsOverride()
        Object.entries(component_overrides).forEach(([activationId, amount], i) => {
          // Don't replace existing overrides.
          if (existingCogsOverrides && existingCogsOverrides[activationId]) {
            return
          }
          system.setComponentCostOverride(
            { id: activationId },
            amount,
            supplierFilterKeyRef.current,
            i === Object.entries(componentOverrides).length - 1
          )
        })
      }

      setComponentOverrides(component_overrides)
    }
  }, [hardwareDetails, codesToDistributorPricing, supplierFilterKey, loading])

  useStudioSignalsLazy(
    (object) => {
      if (
        (object.type !== 'OsSystem' &&
          object.type !== 'OsInverter' &&
          object.type !== 'OsBattery' &&
          object.type !== 'OsOther' &&
          object.type !== 'OsModule' &&
          object.type !== 'OsModuleGrid') ||
        !ukHardwareSuppliers.length
      ) {
        return
      }

      overrideComponentCost()
    },
    ['objectAdded', 'objectRemoved', 'objectChanged'],
    undefined,
    { debounce: 1 }
  )
}
export default useInjectDistributorCostOverride
