// @ts-nocheck
import { Avatar, Card, makeStyles, Theme } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { ThemeProvider } from '@material-ui/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useCheckAuth } from 'ra-core'
import React, { HtmlHTMLAttributes, ReactNode, useEffect, useMemo, useRef } from 'react'
import { StaticContext } from 'react-router'
import { useHistory } from 'react-router-dom'
import { default as createMuiTheme } from '../createMyTheme'

import defaultTheme from '../defaultTheme'
import Notification from '../layout/Notification'
import DefaultLoginForm from './LoginForm'

interface Props {
  backgroundImage?: string
  children: ReactNode
  classes?: object
  className?: string
  staticContext?: StaticContext
  theme: object
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' }
)

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<Props & HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { theme, classes: classesOverride, className, children, staticContext, backgroundImage, ...rest } = props
  const containerRef = useRef<HTMLDivElement>()
  const classes = useStyles(props)
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme])
  let backgroundImageLoaded = false
  const checkAuth = useCheckAuth()
  const history = useHistory()
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, history])

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image()
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          {children}
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  )
}

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
}

Login.defaultProps = {
  theme: defaultTheme,
  children: <DefaultLoginForm />,
}

export default Login
