import { FC } from 'react'

/**
 * * Icon Component for: message-circle
 */
type MessageCircleIconProps = {
  size?: number
  color?: string
}
export const MessageCircleIcon: FC<MessageCircleIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes} viewBox="0 0 19 18" fill="none">
      <path
        d="M17.069 12.2316C17.5058 11.2434 17.7485 10.15 17.7485 9C17.7485 4.58172 14.1671 1 9.74924 1C5.33138 1 1.75 4.58172 1.75 9C1.75 13.4183 5.33138 17 9.74924 17C11.1716 17 12.5073 16.6287 13.6646 15.9778L17.75 16.9992L17.069 12.2316Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
