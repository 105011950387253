import { makeStyles, Paper, Popper } from '@material-ui/core'
import { FunctionComponent, ReactNode } from 'react'

const useStyles = makeStyles(
  {
    suggestionsContainer: {
      zIndex: 3000,
      maxWidth: '100%',
    },
    suggestionsPaper: {
      maxHeight: '40vh',
      overflowY: 'auto',
    },
  },
  { name: 'RaAutocompleteSuggestionList' }
)

interface Props {
  children: ReactNode
  isOpen: boolean
  menuProps: any
  inputEl: HTMLElement
  classes?: any
  suggestionsContainerProps?: any
  PaperStyle?: any
  variant?: 'elevation' | 'outlined'
}

const AutocompleteSuggestionList: FunctionComponent<Props> = (props) => {
  const {
    children,
    isOpen,
    menuProps,
    inputEl,
    suggestionsContainerProps,
    PaperStyle = {},
    variant = 'elevation',
  } = props
  const classes = useStyles(props)

  return (
    <Popper
      open={isOpen}
      anchorEl={inputEl}
      className={classes.suggestionsContainer}
      modifiers={{}}
      {...suggestionsContainerProps}
    >
      <div {...(isOpen ? menuProps : {})}>
        <Paper
          style={{
            marginTop: 8,
            minWidth: inputEl ? inputEl.clientWidth : null,
            ...PaperStyle,
          }}
          variant={variant}
          className={classes.suggestionsPaper}
        >
          {children}
        </Paper>
      </div>
    </Popper>
  )
}

export default AutocompleteSuggestionList
