const filterAvailableImageryTypesWithFeatureFlags = (
  availableImageryTypes,
  enableGoogleSolarApi,
  enableGoogleSunroof
) => {
  return (
    availableImageryTypes?.filter((imageryType) => {
      if (['Google 3D (Legacy)', 'Google Sunroof Imagery (Legacy)'].includes(imageryType.variation_name)) {
        return !!enableGoogleSunroof
      } else if (['Google Solar API'].includes(imageryType.variation_name)) {
        return !!enableGoogleSolarApi
      } else {
        return true
      }
    }) || []
  )
}

export default filterAvailableImageryTypesWithFeatureFlags
