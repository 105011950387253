import { UploadMoreStipulationLink, UploadStipulationButton } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import { useEffect, useState } from 'react'
import type { CheckoutActionCallback, CheckoutActionElementOptions, ProposalDataType } from '../../types'
import type { FinanceStipulationsType, StipulationActionType } from '../../types/CheckoutElementsDataTypes'

type ShowUploadDialogType = (additionalActionData: {
  stipulation: StipulationActionType
  action: 'uploadMoreStipulation' | 'uploadStipulation'
}) => void

const UploadStipulationAction = ({
  stipulation,
  linkColor,
  showUploadDialog,
}: {
  stipulation: StipulationActionType
  linkColor: string
  showUploadDialog: ShowUploadDialogType
}) => {
  if (stipulation.stipulation_review_status === 'created') {
    return (
      <UploadStipulationButton
        description={stipulation.stipulation_description}
        onClick={() => showUploadDialog({ stipulation, action: 'uploadStipulation' })}
        stipulationName={stipulation.stipulation_name}
      />
    )
  }
  if (stipulation.stipulation_review_status === 'submitted') {
    return (
      <UploadMoreStipulationLink
        linkColor={linkColor}
        onClick={() => showUploadDialog({ stipulation, action: 'uploadMoreStipulation' })}
        stipulationName={stipulation.stipulation_name}
      />
    )
  }
  return null
}

const StipulationUpload = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: FinanceStipulationsType
  translate: Translate
  proposalData: ProposalDataType
  options?: CheckoutActionElementOptions
}) => {
  const [directToEmail, setDirectToEmail] = useState<boolean>(false)
  const { actionData } = elementData

  const availableStips = actionData['created']
  const submittedStips = actionData['submitted']

  const highlightColor = proposalData.myeStyles.highlightColor

  useEffect(() => {
    if (availableStips && availableStips.length > 0) {
      availableStips.forEach((stip) => {
        const docs = stip.stipulation_acceptable_docs ? JSON.parse(stip.stipulation_acceptable_docs) : {}
        if (Object.keys(docs)?.length === 0 && !directToEmail) setDirectToEmail(true)
        else if (Object.keys(docs)?.length > 0 && directToEmail) setDirectToEmail(false)
      })
    }
  }, [availableStips, directToEmail])

  const showUploadDialog: ShowUploadDialogType = (additionalActionData) => {
    onAction({ elementData, proposalData, additionalActionData: additionalActionData })
  }

  if (!actionData || Object.keys(actionData).length < 1) return null
  if (directToEmail) return null
  else
    return (
      <div>
        {availableStips && (
          <div>
            <p>To progress with your financing please provide the following documentation</p>
            <div>
              {availableStips.map((stip) => {
                return (
                  <UploadStipulationAction
                    key={stip.name}
                    stipulation={stip}
                    linkColor={highlightColor}
                    showUploadDialog={showUploadDialog}
                  />
                )
              })}
            </div>
          </div>
        )}
        {submittedStips && (
          <div>
            <div>
              {submittedStips.map((stip) => {
                return (
                  <UploadStipulationAction
                    key={stip.name}
                    stipulation={stip}
                    linkColor={highlightColor}
                    showUploadDialog={showUploadDialog}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
}

export default StipulationUpload
