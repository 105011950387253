import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const Bag05OutlineSvg: FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M20.601 5.30004L1.40164 5.29976L1.40039 5.3001M20.601 5.30004L20.6004 18.6161C20.6004 19.8775 19.5584 20.9001 18.2731 20.9001H3.72766C2.44235 20.9001 1.40039 19.8775 1.40039 18.6161V5.3001M20.601 5.30004L16.7519 1.45157C16.5268 1.22653 16.2216 1.1001 15.9033 1.1001H6.09745C5.77919 1.1001 5.47396 1.22653 5.24892 1.45157L1.40039 5.3001M14.6004 8.9001C14.6004 10.8883 12.9886 12.5001 11.0004 12.5001C9.01217 12.5001 7.40039 10.8883 7.40039 8.9001"
        stroke={props.stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={props.fill}
      />
    </svg>
  )
}

export const Bag05OutlineIcon = createStandardIcon(Bag05OutlineSvg)
