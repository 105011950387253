import { styled } from 'opensolar-ui'
import { COLOR_PALETTE } from '../styles'

type Props = {
  url: string
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${COLOR_PALETTE.grey6}`,
  borderRadius: '4px',
  background: COLOR_PALETTE.white,
})

const Logo = styled('img')({
  height: '32px',
  width: '50px',
  objectFit: 'contain',
})

const DistributorLogo = ({ url }: Props) => {
  return (
    <Wrapper>
      <Logo src={url} alt="logo" />
    </Wrapper>
  )
}

export default DistributorLogo
