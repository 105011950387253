import { orgSelectors } from 'ducks/orgs'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ModuleGridType, ModuleLayout, PanelConfiguration } from 'types/global'
import { MeasurementUnits } from 'types/orgs'
import { formatNumber, trimDecimalPlaces } from '../common/helpers'
import MODULE_GRID_SETTER_GETTER_CONFIGS from './accessors/configs'

export type ModuleGridSettings = {
  rackingType: PanelConfiguration
  isDualTilt: boolean
  azimuthAuto: boolean
  slopeAuto: boolean
  isUsingTiltRacks: boolean
  elevationAuto: boolean
  azimuth: number
  ridgeDirection: number
  slope: number
  relativeTilt: number
  groundClearance: number
  moduleSpacing: [number, number]
  groupSpacing: [number, number]
  modulesPerCol: number
  modulesPerRow: number
  moduleOrientation: ModuleLayout
  trackingMode: unknown
  moduleLayoutOffset: boolean
  groundCoverageRatio: number
  panelPlacement: 'roof' | 'ground'
}

const useGetModuleGridGroupSettings = () => {
  const measurements = useSelector(orgSelectors.getMeasurementUnits) || MeasurementUnits.metric

  return useCallback(
    ({ moduleGrid }: { moduleGrid: ModuleGridType }): ModuleGridSettings => {
      const panelConfig = MODULE_GRID_SETTER_GETTER_CONFIGS['rackingType'].get(moduleGrid)
      const azimuth = MODULE_GRID_SETTER_GETTER_CONFIGS['azimuth'].get(moduleGrid)
      const azimuthAuto = MODULE_GRID_SETTER_GETTER_CONFIGS['azimuthAuto'].get(moduleGrid)
      const slope = MODULE_GRID_SETTER_GETTER_CONFIGS['slope'].get(moduleGrid)
      const slopeAuto = MODULE_GRID_SETTER_GETTER_CONFIGS['slopeAuto'].get(moduleGrid)
      const panelTilt = MODULE_GRID_SETTER_GETTER_CONFIGS['tilt'].get(moduleGrid)
      const moduleOrientation = MODULE_GRID_SETTER_GETTER_CONFIGS['orientation'].get(moduleGrid)
      const elevationAuto = MODULE_GRID_SETTER_GETTER_CONFIGS['elevationAuto'].get(moduleGrid)
      const groundClearance = MODULE_GRID_SETTER_GETTER_CONFIGS['groundClearance'].get(moduleGrid)
      const moduleSpacing = MODULE_GRID_SETTER_GETTER_CONFIGS['moduleSpacing'].get(moduleGrid)
      const groupSpacing = MODULE_GRID_SETTER_GETTER_CONFIGS['groupSpacing'].get(moduleGrid)
      const modulesPerCol = MODULE_GRID_SETTER_GETTER_CONFIGS['modulesPerCol'].get(moduleGrid)
      const modulesPerRow = MODULE_GRID_SETTER_GETTER_CONFIGS['modulesPerRow'].get(moduleGrid)
      const trackingMode = MODULE_GRID_SETTER_GETTER_CONFIGS['trackingMode'].get(moduleGrid)
      const panelPlacement = MODULE_GRID_SETTER_GETTER_CONFIGS['panelPlacement'].get(moduleGrid)
      const moduleLayoutOffset = MODULE_GRID_SETTER_GETTER_CONFIGS['offset'].get(moduleGrid)

      const isDualTilt = panelConfig === 'DUAL_TILT_RACK'
      const ridgeDirection = formatNumber(azimuth - 90 < 0 ? 360 + (azimuth - 90) : azimuth - 90) % 360
      const groundCoverageRatio = trimDecimalPlaces(moduleGrid.calculateGroundCoverageRatio(), 2)
      const relativeTilt = formatNumber(panelTilt - slope)
      const isUsingTiltRacks = moduleGrid.isUsingTiltRacks()

      return {
        rackingType: panelConfig,
        isDualTilt: isDualTilt,
        azimuth: azimuth,
        azimuthAuto: azimuthAuto,
        ridgeDirection: ridgeDirection,
        slope: slope,
        slopeAuto: slopeAuto,
        isUsingTiltRacks: isUsingTiltRacks,
        relativeTilt: relativeTilt,
        moduleOrientation: moduleOrientation,
        elevationAuto: elevationAuto,
        groundClearance: groundClearance,
        moduleSpacing,
        groupSpacing,
        modulesPerCol,
        modulesPerRow,
        trackingMode,
        moduleLayoutOffset,
        groundCoverageRatio,
        panelPlacement,
      }
    },
    [measurements]
  )
}

export default useGetModuleGridGroupSettings
