import { HelpOutline } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { Grid, styled, Tooltip, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AccountingTaxRateSelectionRow from './AccountingTaxRateSelectionRow'
import { AccountingTaxRateType, LedgerAccount, TaxRateSelection } from './types'
import { getAccountingTaxRates, getDefaultInvoiceTaxRates } from './utils'

type PropTypes = {
  onChange: (value: TaxRateSelection) => void
  taxRateSelection: TaxRateSelection
  ledgerAccounts: LedgerAccount[]
}

const GridContainer = styled(Grid)({
  marginTop: '16px',
})

const CenteredGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const GridRow = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

const HelpIcon = styled(HelpOutline)({
  fontSize: '20px',
  color: '#6B6B6B',
  marginLeft: '8px',
  cursor: 'pointer',
})

const AccountingTaxRateSelection: React.FC<PropTypes> = (props) => {
  const [defaultTaxRates, setDefaultTaxRates] = useState<number[]>([])
  const [availableTaxRates, setAvailableTaxRates] = useState<AccountingTaxRateType[]>([])
  const [taxRatesLoading, setTaxRatesLoading] = useState<boolean>(false)
  const translate = useTranslate()
  const orgCountryIso2 = useSelector(orgSelectors.getOrgIso2)
  const orgId = useSelector(orgSelectors.getOrg)?.id

  useEffect(() => {
    if (orgCountryIso2 && !defaultTaxRates?.length) {
      getDefaultInvoiceTaxRates(orgCountryIso2)
        .then((taxRates) => {
          setDefaultTaxRates(taxRates)
        })
        .catch((res) => {
          setDefaultTaxRates([])
        })
    }
  }, [orgCountryIso2, defaultTaxRates])

  useEffect(() => {
    if (orgId && !availableTaxRates?.length) {
      setTaxRatesLoading(true)
      getAccountingTaxRates(orgId)
        .then((res) => {
          setAvailableTaxRates(res)
        })
        .catch((err) => {
          setAvailableTaxRates([])
        })
        .finally(() => {
          setTaxRatesLoading(false)
        })
    }
  }, [orgId, availableTaxRates])

  return (
    <div>
      <Typography textVariant="h5">{translate('Settings by Tax Rate')}</Typography>
      <Typography>
        {translate(
          'Accounting systems often have multiple tax rate records with the same tax percentage. When we sync invoices to your accounting provider we will need to know which ledger accounts and tax rate records you prefer to use for each tax rate percentage.'
        )}
      </Typography>
      <GridContainer container spacing={3}>
        <CenteredGrid item xs={2}>
          <Typography textVariant="h5">{translate('Taxes')}</Typography>
        </CenteredGrid>
        <CenteredGrid item xs={10}>
          <Typography textVariant="h5">{translate('Product Selections')}</Typography>
        </CenteredGrid>
        {/* <CenteredGrid item xs={5}>
          <Typography textVariant="h5">{translate('Payment Selections')}</Typography>
        </CenteredGrid> */}
        <Grid item xs={2}>
          <Typography textVariant="caption2">{translate('Tax Rate')}</Typography>
        </Grid>
        <GridRow item xs={5}>
          <Typography textVariant="caption2">{translate('Ledger Account')}</Typography>
          <Tooltip title={translate('This is the ledger account that all invoice line items will be assigned to')}>
            <HelpIcon />
          </Tooltip>
        </GridRow>
        <GridRow item xs={5}>
          <Typography textVariant="caption2">{translate('Tax Rate Record')}</Typography>
          <Tooltip
            title={translate(
              'This is the tax rate record that will be assigned to all line items with the given tax rate in the first column. It should be the tax record that is assigned to the product ledger account you selected for this tax rate'
            )}
          >
            <HelpIcon />
          </Tooltip>
        </GridRow>
        {/* <GridRow item xs={3}>
          <Typography textVariant="caption2">{translate('Ledger Account')}</Typography>
          <Tooltip
            title={translate(
              'This is the ledger account that all payment records for paid invoices will be assigned to'
            )}
          >
            <HelpIcon />
          </Tooltip>
        </GridRow>
        <GridRow item xs={2}>
          <Typography textVariant="caption2">{translate('Tax Rate Record')}</Typography>
          <Tooltip
            title={translate(
              'This is the tax rate record that will be assigned to for all payment records for paid invoices. It should be the tax record that is assigned to the ledger account you selected for this tax rate'
            )}
          >
            <HelpIcon />
          </Tooltip>
        </GridRow> */}

        {defaultTaxRates?.map((taxRate) => (
          <AccountingTaxRateSelectionRow key={taxRate} {...props} taxRate={taxRate} taxRatesLoading={taxRatesLoading} />
        ))}
      </GridContainer>
    </div>
  )
}

export default AccountingTaxRateSelection
