import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core'
import inflection from 'inflection'
import PropTypes from 'prop-types'
import { usePermissions, useTranslate } from 'ra-core'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => {
  return {
    selected: {
      fontWeight: 'bold',
      color: 'black',
    },
  }
})

const BreadCrumbs = ({ hasList, resource, detail, options = {} }) => {
  const { loading, permissions } = usePermissions()
  const translate = useTranslate()
  const classes = useStyles()

  const listBreadCrumb = options.list?.breadCrumb || `ra.page.breadCrumb_list`
  const detailBreadCrumb = options[detail]?.breadCrumb || `ra.page.breadCrumb_` + detail

  var resourceName = translate('resources.' + resource + '.name', {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(resource)),
  })

  return (
    <div>
      {!loading && permissions && (
        <Breadcrumbs aria-label="breadcrumb">
          {permissions.includes('is_admin') && (
            <Link color="inherit" to={'/control'}>
              {translate('Control')}
            </Link>
          )}
          {hasList &&
            (detail === 'list' ? (
              <Typography className={classes.selected}>
                {translate(listBreadCrumb, { name: resourceName, detail })}
              </Typography>
            ) : (
              <Link color="inherit" to={`/${resource}`}>
                {translate(listBreadCrumb, { name: resourceName, detail })}
              </Link>
            ))}
          {detail !== 'list' && (
            <Typography className={classes.selected}>
              {translate(detailBreadCrumb, { name: resourceName, detail })}
            </Typography>
          )}
        </Breadcrumbs>
      )}
    </div>
  )
}

BreadCrumbs.propTypes = {
  detail: PropTypes.string,
}

export default BreadCrumbs
