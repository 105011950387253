import { FoundStyleValue, StyleNotice, StyleNoticesBrief, StyleWarningLevel, StyleWarningRules } from './types'

export function makeStyleNotices(
  styles: FoundStyleValue[] | undefined,
  rules: StyleWarningRules
): StyleNoticesBrief | undefined {
  if (!styles) return undefined

  let highestLevel: StyleWarningLevel = StyleWarningLevel.info
  let styleNotices: StyleNotice[] = []

  for (const style of styles) {
    for (const k in style.values) {
      const value = style.values[k]
      const key: keyof CSSStyleDeclaration = k as any
      const rule = rules[key]
      if (rule === undefined) continue
      if (typeof value !== 'string' || value === '') continue

      let level
      if (typeof rule.level === 'function') {
        level = rule.level(value)
        if (level === undefined) continue
      } else {
        level = rule.level
      }

      if (level > highestLevel) highestLevel = level

      // Filter out subkeys with the same value (e.g. border > borderLeft)
      let filterKey = false
      for (let k of styleNotices) {
        if ((key as string).startsWith(k.key as string) && k.value === value) {
          filterKey = true
          break
        }
      }
      if (filterKey) continue

      styleNotices.push({
        key,
        value,
        level,
      })
    }
  }

  if (!styleNotices.length) return undefined

  return { styles: styleNotices, highestLevel }
}
