import { FC } from 'react'

/**
 * * Icon Component for: arrow-left-sm
 */
type ArrowLeftSmIconProps = {
  size?: number
  color?: string
}
export const ArrowLeftSmIcon: FC<ArrowLeftSmIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M5.16667 10.375L1 6M1 6L5.16667 1.625M1 6H11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
