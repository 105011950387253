import { CircularProgress } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { PaymentExtraFields, PaymentRequestType, PaymentStaticCopy } from '../types'
import ApplePayButton from './ApplePayButton'
import GooglePayButton from './GooglePayButton'

type PaymentProps = {
  paymentRequestData: PaymentRequestType
  projectId: string
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  showGooglePay?: boolean
  showApplePay?: boolean | null
  doSubmitPayment: (args: PaymentExtraFields) => void
  isSubmitting: boolean
}

const ButtonStyle = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0',
})

const ButtonWrapper = styled('div')({
  width: '240px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SingleButtonWrapper = styled('div')({
  width: '240px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
})

const WalletButtons: React.FC<PaymentProps> = (props) => {
  const showApple = props.showApplePay
  const showGoogle = props.showGooglePay

  const WrapperComponent = !showApple || !showGoogle ? SingleButtonWrapper : ButtonWrapper

  return (
    <>
      {props.isSubmitting ? (
        <CircularProgress size={24} />
      ) : (
        <ButtonStyle data-testid="wallet-buttons">
          {showGoogle && (
            <WrapperComponent data-testid="google-button-wrapper">
              <GooglePayButton {...props} />
            </WrapperComponent>
          )}
          {showApple && (
            <WrapperComponent data-testid="apple-button-wrapper">
              <ApplePayButton {...props} />
            </WrapperComponent>
          )}
        </ButtonStyle>
      )}
    </>
  )
}

export default WalletButtons
