export const COPPER_RESISTIVITY = 1.724e-8 // Ω⋅m at 20°C https://www.engineeringtoolbox.com/resistivity-conductivity-d_418.html
export const COPPER_TEMP_COEFFICIENT = 0.00393 // per °C https://www.engineeringtoolbox.com/resistivity-conductivity-d_418.html
export const MAX_ALLOWABLE_VOLTAGE_DROP = 3 // 3% maximum allowed voltage drop
export const GOOD_VOLTAGE_DROP = 1 // 1% good voltage drop
export const DEFAULT_CABLE_TEMP = 40 // °C
export const BASELINE_COPPER_TEMP = 20 // °C
export const CABLE_STC_TEMP = 25 // °C
export const STANDARD_CABLE_SIZES = [
  0.35,
  0.5,
  1.0,
  1.5,
  2.0,
  2.5,
  3.0,
  4.0,
  6.0,
  8.5,
  10.0,
  16.0,
  25.0,
  35.0,
  50.0,
  70.0,
  95.0,
] // mm2
export const MAX_CABLE_SIZE = Math.max(...STANDARD_CABLE_SIZES)
