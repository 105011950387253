import { PartnerIdType } from 'pages/auth/sso/types'
import { AccountingIntegration } from 'pages/cashFlow/configuration/accounting/types'
import { CustomFormUsageType } from 'resources/customForms/types'
import { US_FINANCE_INTEGRATION_ENUM } from './paymentOptions'

export interface OrgBrief {
  id: number
  name: string
  allow_sms_mfa: boolean
  enable_exhibit: boolean
  linked_partner_orgs: LinkedPartnerOrgs
  bypass_user_terms: boolean
}

export type FinancePartnerStatusType = 'pending' | 'disabled' | 'active_staff_only' | 'active_public'

export type URLParameterConfigType = {
  key: string
  static_value_or_prefix?: string
  project_field?: string
}

export type ProOnlyFinanceCTAType = {
  cta_main_text: string
  include_for_all_commercial: boolean
  include_for_all_residential: boolean
  include_logo: boolean
  logo_url: string
  priority: number
  integration: string
  cta_dialog_config_json: {
    dialog_title: string
    content_html: string
    dismiss_button_label: string
    include_logo: boolean
    primary_button_label: string
    primary_button_redirect_config?: {
      base_url: string
      parameters: URLParameterConfigType[]
    }
  }
}

export type AvailableFinanceIntegrationType = {
  status: FinancePartnerStatusType
  display_name: string
  integration_name: string
  banner_content: {
    font_color?: string
    theme_color?: string
    theme_color_dark?: string
    promo_content_html?: string
    promo_content_title?: string
    existing_partner_redirect_url?: string | null
    existing_partner_redirect_url_non_admin?: string | null
    new_partner_redirect_url?: string | null
    new_partner_redirect_url_non_admin?: string | null
    intake_fields: IntakeFieldType[]
    skip_step_2?: boolean
    intake_form_description?: string
    connect_button_label?: string
    logo_height?: number
    import_dialog_title?: string
    import_dialog_content?: string
    page_2_content?: string
    priority?: number
    hide_from_payment_option_selection_dialog?: boolean
    skip_step_3?: boolean
  }
  logo_small_contents: string
  logo_large_contents: string
  activation_type: IntegrationActivationType
  support_residential: boolean
  support_commercial: boolean
  pro_only_ctas: ProOnlyFinanceCTAType[]
  requires_role_connection: boolean
}

export type IntegrationActivationType = 'simple' | 'api_credentials' | 'immediately_available' | 'oauth2'

export type IntakeFieldType = {
  field_name: string
  field_label: string
  field_type: 'text' | 'select' | 'boolean'
  filter_country?: string
  only_new_partners?: boolean
  only_existing_partners?: boolean
  grid_size?: 6 | 12
  choices?: {
    id: string
    name: string
  }[]
}

export type AvailableIntegrationsType = {
  finance: AvailableFinanceIntegrationType[]
}

export type IntegrationEnabledType = undefined | 'active' | 'shared'

export type BlueSnapOnboardingStatus =
  | undefined
  | 'created'
  | 'submitted'
  | 'pending'
  | 'denied'
  | 'approved'
  | 'conditionally approved'

export enum ExpoModes {
  Disabled,
  Basic,
  Premium,
}

export type ExpoStandBrief = {
  partnerName: string
  standSlug: string
}
export interface OrgType extends OrgBrief {
  url: string
  address: string | null
  zip: string | null
  state: string | null
  locality: string | null
  country: CountryType
  country_iso2: undefined | string
  custom_fields: CustomFieldType[]
  sales_phone_number: string
  about_content: string
  cashflow_is_active: boolean
  cashflow_onboarding_status: BlueSnapOnboardingStatus
  connected_orgs: ConnectedOrgBrief[]
  company_email: string | null
  company_website: string | null
  expo_mode: ExpoModes
  enable_exhibit: boolean
  enable_showcase: boolean
  enable_other_imagery: boolean
  enable_google_calendar: boolean
  enable_checkout: boolean
  accept_credit_card_stripe: boolean
  accept_offline: boolean
  active_accounting_integrations: string[]
  accounting_integrations: AccountingIntegration[]
  hide_loan_application_until_prequal: boolean
  quote_acceptance_heading: string | null
  quote_acceptance_content: string | null
  external_account_id: string | null
  api_key_google?: string
  api_key_pvsell?: string
  api_key_nearmap?: string
  api_key_bing?: string
  api_key_arcgis_osm?: string
  api_key_vexcel?: string
  invitation_email_custom_message: string | null
  invitation_email_custom_subject: string | null
  measurement_units: MeasurementUnits
  enable_centrix_credit_check: boolean
  enable_webhooks: boolean
  enable_bespoke_proposal: boolean
  enable_lead_distribution: boolean
  logo_public_url: URL | null
  tags: TagType[]
  enable_utility_api: boolean
  enable_nearmap: boolean
  enable_sunlight: IntegrationEnabledType
  enable_plenti: IntegrationEnabledType
  enable_energy_ease: IntegrationEnabledType
  enable_loanpal: IntegrationEnabledType
  enable_sungage: IntegrationEnabledType
  enable_dividend: IntegrationEnabledType
  enable_commonbond: IntegrationEnabledType
  enable_selina: IntegrationEnabledType
  enable_checkout_finance: IntegrationEnabledType
  enable_segen: boolean
  enable_city_plumbing: boolean
  enable_outlet: boolean
  enable_hdm: boolean
  enable_vtac: boolean
  enable_mosaic: IntegrationEnabledType
  enable_brighte: IntegrationEnabledType
  enable_eagleview: boolean
  ignore_loanpal_channels: boolean
  require_mfa: boolean
  docusign_config?: {
    docusign_account_connected: boolean
    docusign_account_id: string
    docusign_base_url: string
    enable_contracts_in_docusign: boolean
    docusign_contract_kba_enabled: boolean
    docusign_contract_sms_auth_enabled: boolean
    enable_california_protection_guide: boolean
  }
  solarapp_config?: { solarapp_account_connected: boolean }
  enable_contracts_in_docusign: boolean
  disable_apply_now_prompt: boolean
  cobranding_org?: {}
  enable_phoenix: IntegrationEnabledType
  custom_forms: CustomFormUsageType[]
  email_acceptance_files_enabled: boolean
  available_integrations: AvailableIntegrationsType
  zone_org?: any
  rounding_preference: null | RoundingPreferenceEnum
  linked_partner_orgs: LinkedPartnerOrgs
  enable_auto_design?: boolean
}

type LinkedPartnerOrgs = {
  [key in PartnerIdType]: {
    partner_company_uuid: string
    link_metadata: string
  }
}

export enum RoundingPreferenceEnum {
  ROUND_TO_NEAREST = 'nearest',
  ROUND_UP = 'ceil',
  ROUND_DOWN = 'floor',
}

export interface ConnectedOrgBrief {
  id: number
  org_id: number
  org_name: string
  org_url: string
  org_country_iso2: string
  permission_url: string
  permission_role_title: string
  is_active: boolean
}

export interface ConnectedOrg {
  id: number
  url: string // URL to connection
  org: string // URL from org
  org_to: string // URL to org
  org_name: string // name to org
  permission: string // URL to permission entity
  is_active: boolean
  is_other_active: boolean
  is_other_enabled: boolean

  // Only returned when `resolve_notify_users=1` argument passed
  notify_roles?: number[]
  notify_user_emails?: string[]
}

export interface CountryType {
  id: number
  name: string
  iso2: string
}

export enum MeasurementUnits {
  imperial = 'imperial',
  metric = 'metric',
}

type TagType = {
  id: number
  title: string
  allow_fetch: boolean
}

export type CustomFieldType = {
  field: string
  label: string
  field_type: CustomFieldVariants
  select_field_choices?: string
}

export enum CustomFieldVariants {
  text = 0,
  dropdown = 1,
  dateTime = 2,
  date = 3,
  multiSelect = 4,
}

export type IntegrationWithOrgNamesType = {
  required_org_names: string[]
  integration_name: US_FINANCE_INTEGRATION_ENUM
}

export type GetActionsResponseType = {
  data: {
    integrations: { [integration in US_FINANCE_INTEGRATION_ENUM]: IntegrationWithOrgNamesType }
    requires_external_refresh: boolean
    requires_internal_refresh: boolean
  }
}
