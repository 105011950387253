import { createContext } from 'react'

export const ErrorHandlingContext = createContext<ErrorHandlingProvider>({
  unexpected: () => {},
  data_provider: () => {},
  http: () => {},
})

export type ErrorHandlingProvider = {
  /**
   * Use this handler to capture unexpected logic errors.
   */
  unexpected: (err: Error, opts?: ErrorHandlerOpts) => void // Intentionally narrower error type
  /**
   * Use this handler to capture network/http errors to official OSAPI endpoints.
   */
  data_provider: (err: LooseError, opts?: ErrorHandlerOpts) => void
  /**
   * Use this handler to capture network/http errors to external/unofficial endpoints.
   */
  http: (err: LooseError, opts?: ErrorHandlerOpts) => void
}

export type ErrorType = keyof ErrorHandlingProvider

export type ErrorHandlerOverrides = {
  before?: { [key in ErrorType]?: (err: LooseError, opts?: ErrorHandlerOpts) => void }
  after?: { [key in ErrorType]?: (err: LooseError, opts?: ErrorHandlerOpts) => void }
  replace?: { [key in ErrorType]?: (err: LooseError, opts?: ErrorHandlerOpts) => void }
}

export type ErrorHandlerOpts = { msg?: string; notify?: boolean }

export type LooseError = string | Error | ErrorEvent
