import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const ImagePlaceholderIconSvg: FC<IconProps> = (props) => {
  const { fill, height, width } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.875 35.875L28.875 4.375L27.125 4.375L27.125 35.875L28.875 35.875Z" fill={fill} />
      <path
        d="M7.875 27.125L21.875 27.125L35.875 27.125L35.875 25.375L21.875 25.375L7.875 25.375L7.875 27.125Z"
        fill={fill}
      />
      <path d="M7.875 16.625L35.875 16.625L35.875 14.875L7.875 14.875L7.875 16.625Z" fill={fill} />
      <path d="M21.875 35.875L21.875 4.375L20.125 4.375L20.125 35.875L21.875 35.875Z" fill={fill} />
      <path d="M14.875 35.875L14.875 4.375L13.125 4.375L13.125 35.875L14.875 35.875Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.875 7.875C35.875 5.942 34.308 4.375 32.375 4.375L9.625 4.375C7.69195 4.375 6.125 5.942 6.125 7.875L6.125 34.125C6.125 36.0581 7.69195 37.625 9.625 37.625L32.375 37.625C34.308 37.625 35.875 36.0581 35.875 34.125L35.875 7.875ZM30.625 35.875C32.558 35.875 34.125 34.3081 34.125 32.375L34.125 9.625C34.125 7.692 32.558 6.125 30.625 6.125L11.375 6.125C9.44195 6.125 7.875 7.692 7.875 9.625L7.875 32.375C7.875 34.3081 9.44195 35.875 11.375 35.875L30.625 35.875Z"
        fill={fill}
      />
    </svg>
  )
}

export const ImagePlaceHolderIcon = createStandardIcon(ImagePlaceholderIconSvg)
