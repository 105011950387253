import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const getEnvelopeId = (
  transactionRequestData: ActionDataType,
  paymentOptionId: number,
  contractTemplateId: number | undefined
) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      paymentOptionId +
      `/contract_docusign_envelope_id/?document_template_id=${contractTemplateId}`,
  })
}

export const getEnvelopeURL = async (
  transactionRequestData: ActionDataType,
  paymentOptionId: number,
  docusignEnvelopeId: string | undefined,
  selectedDocusignCustomer: string | undefined,
  contractTemplateId: number | undefined
) => {
  let params = `?envelope_id=${docusignEnvelopeId}&document_template_id=${contractTemplateId}`
  if (selectedDocusignCustomer) {
    params += `&customer_index=${selectedDocusignCustomer}`
  }
  const response = await restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      paymentOptionId +
      `/contract_docusign_envelope_url/${params}`,
  })

  const { redirect_params } = response.data
  appStorage.setJSON('docusign_redirect', {
    params: redirect_params,
    url: window.location.href,
  })
  return response
}

export const getCompletedRecipients = (transactionRequestData: ActionDataType) => {
  return restClientInstance('CUSTOM_GET', 'custom', {
    url:
      'orgs/' +
      transactionRequestData?.org_id +
      '/projects/' +
      transactionRequestData?.project_id +
      '/systems/' +
      transactionRequestData?.system_uuid +
      '/' +
      transactionRequestData?.paymentOptionData?.id +
      '/contract_docusign/completed_recipients/',
  })
}
