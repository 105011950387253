import inflection from 'inflection'
import { useCallback } from 'react'

import { CRUD_GET_ONE, CRUD_UPDATE } from '../actions'
import { useGetOne, useUpdate } from '../dataProvider'
import { useTranslate } from '../i18n'
import { RedirectionSideEffect, useNotify, useRedirect, useRefresh } from '../sideEffect'
import { Identifier, Record } from '../types'
import { useCheckMinimumRequiredProps } from './checkMinimumRequiredProps'
import useVersion from './useVersion'

export interface EditProps {
  basePath: string
  hasCreate?: boolean
  hasEdit?: boolean
  hasShow?: boolean
  hasDelete?: any
  hasList?: boolean
  id: Identifier
  resource: string
  undoable?: boolean
  [key: string]: any
  filter?: { [key: string]: any }
}

export interface EditControllerProps {
  loading: boolean
  loaded: boolean
  saving: boolean
  defaultTitle: string
  save: (
    data: Record,
    redirect?: RedirectionSideEffect,
    callbacks?: {
      onSuccess: () => void
      onFailure: (error: string | { message?: string }) => void
    }
  ) => void
  resource: string
  basePath: string
  hasDelete?: any
  record?: Record
  redirect: RedirectionSideEffect
  version: number
  successMessage?: string
}

/**
 * Prepare data for the Edit view
 *
 * @param {Object} props The props passed to the Edit component.
 *
 * @return {Object} controllerProps Fetched data and callbacks for the Edit view
 *
 * @example
 *
 * import { useEditController } from 'react-admin';
 * import EditView from './EditView';
 *
 * const MyEdit = props => {
 *     const controllerProps = useEditController(props);
 *     return <EditView {...controllerProps} {...props} />;
 * }
 */
const useEditController = (props: EditProps): EditControllerProps => {
  useCheckMinimumRequiredProps('Edit', ['basePath', 'resource'], props)
  const { basePath, id, resource, successMessage, undoable = true, redirect: redirectOverride } = props
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const version = useVersion()
  const { data: record, loading, loaded } = useGetOne(resource, id, {
    version, // used to force reload
    action: CRUD_GET_ONE,
    onFailure: () => {
      notify('ra.notification.item_doesnt_exist', 'warning')
      const redirectTo = redirectOverride || DefaultRedirect
      redirect(redirectTo, basePath)
      refresh()
    },
    filter: props.filter,
  })

  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(resource)),
  })
  const defaultTitle = translate('ra.page.edit_title', {
    name: `${resourceName}`,
    id,
    record,
  })

  const [update, { loading: saving }] = useUpdate(
    resource,
    id,
    {}, // set by the caller
    record
  )

  const save = useCallback(
    (data: Partial<Record>, redirectTo = redirectOverride || DefaultRedirect, { onSuccess, onFailure } = {}) =>
      update(
        { payload: { data } },
        {
          action: CRUD_UPDATE,
          onSuccess: onSuccess
            ? onSuccess
            : () => {
                notify(successMessage || 'ra.notification.updated', 'info', {
                  messageArgs: { smart_count: 1 },
                  undoable,
                })
                redirect(redirectTo, basePath, data.id, data)
              },
          onFailure: onFailure
            ? onFailure
            : (error) => {
                if (undoable) {
                  refresh()
                }
              },
          undoable,
        }
      ),
    [update, undoable, notify, successMessage, redirect, basePath, refresh]
  )
  const hasDelete = typeof props.hasDelete === 'function' ? props.hasDelete(record) : Boolean(props.hasDelete)
  return {
    loading,
    loaded,
    saving,
    defaultTitle,
    hasDelete,
    save,
    resource,
    basePath,
    record,
    redirect: redirectOverride || DefaultRedirect,
    version,
  }
}

export default useEditController

const DefaultRedirect = 'list'
