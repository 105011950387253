import * as Sentry from '@sentry/react'
import { showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useShowCheckoutFinancingDialogCallback = (): CheckoutActionCallback => {
  const dispatch = useDispatch()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'CheckoutFinance') {
      Sentry.captureException(new Error('Invalid element data for checkout finance callback'))
      return
    }
    dispatch(
      showCheckoutDialog({
        type: 'CheckoutFinancingDialog',
        config: {
          orgId: elementData.actionData.org_id,
          projectId: elementData.actionData.project_id,
          paymentOptionId: elementData.actionData.payment_option_id,
          systemUuid: elementData.actionData.system_uuid,
          countryIso2: proposalData.selectedProject.country_iso2,
          amountDue: elementData.actionData.pricing?.system_price_including_tax || proposalData.selectedPaymentOption?.pricing?.system_price_including_tax || 0,
        },
      })
    )
  }, [])
}

export default useShowCheckoutFinancingDialogCallback
