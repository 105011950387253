import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled?: boolean
}

const ResourcesFields: FC<PropTypes> = ({ disabled }) => {
  const translate = useTranslate()
  const source = useBlockSource('resources')

  if (!source) return null
  return (
    <InputAccordion title={translate('Resources')} source={source}>
      <TextInput label={'Resources URL'} name={`${source}.url`} source={`${source}.url`} disabled={disabled} />
    </InputAccordion>
  )
}

export default ResourcesFields
