import { makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import ComponentTypeImg from 'elements/field/ComponentTypeImag'
import { isEmpty } from 'lodash'
import { Grid } from 'opensolar-ui'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { formatCurrency } from 'util/misc'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'

const COMPONENT_TYPE_NAME_MAPPING = {
  module: 'Panels',
  inverter: 'Inverters',
  battery: 'Batteries',
  other: 'Others',
}

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 475,
  },
  label: {
    fontSize: 12,
    color: COLOR_PALETTE.grey2,
  },
  label1: {
    fontSize: 12,
    color: COLOR_PALETTE.grey2,
    fontWeight: 475,
  },
  midDot: {
    fontSize: 18,
    fontWeight: 'bold',
    color: COLOR_PALETTE.grey2,
  },
  priceWrapper: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  price: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
    color: COLOR_PALETTE.grey2,
  },
  accordionStyle: {
    backgroundColor: COLOR_PALETTE.grey3,
    padding: 0,
  },
}))

const PartsList = ({ data }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <AccordionCard
      name={'parts-list'}
      defaultExpanded={true}
      elevation={0}
      className={classes.accordionStyle}
      title={`${translate('Parts List')} (${data?.totalItems})`}
      render={() => <PartsListContent data={data} />}
      lazyRender={true}
    />
  )
}

const PartsListContent = ({ data }) => {
  const partslistHashMap: { [key: string]: OrderLineItem[] } = {
    module: [],
    inverter: [],
    battery: [],
    other: [],
  }

  data?.items?.forEach((item: OrderLineItem) => {
    if (item.componentType) {
      partslistHashMap[item?.componentType].push(item)
    }
  })

  return (
    <Grid container style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 16, paddingBottom: 16 }} spacing={2}>
      {Object.keys(partslistHashMap).map((key) => {
        if (!isEmpty(partslistHashMap[key])) {
          return (
            <Grid item xs={12} key={key}>
              <div style={{ fontSize: 14, fontWeight: 550, paddingBottom: 8 }}>{COMPONENT_TYPE_NAME_MAPPING[key]}</div>
              {partslistHashMap[key].map((item: OrderLineItem) => (
                <PartsListComponent lineItem={item} key={item.code} />
              ))}
            </Grid>
          )
        }
        return null
      })}
    </Grid>
  )
}

const PartsListComponent = ({ lineItem }: { lineItem: OrderLineItem }) => {
  const lineItemObj = new OrderLineItem(lineItem)
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const { beforeDiscount, afterDiscount, discount } = lineItemObj.getBestDiscountOffer()
  const hasDiscount = beforeDiscount !== afterDiscount

  const imageUrl = useMemo(() => {
    switch (lineItemObj.componentType) {
      case 'module':
        return window.PUBLIC_URL + '/images/panels.svg'
      case 'inverter':
        return window.PUBLIC_URL + '/images/inverters.svg'
      case 'battery':
        return window.PUBLIC_URL + '/images/batteries.svg'
      case 'other':
        return window.PUBLIC_URL + '/images/other_components.svg'
      default:
        return 'componentImage'
    }
  }, [lineItemObj.componentType])

  return (
    <Grid container style={{ paddingBottom: 8, paddingTop: 8 }}>
      <Grid item xs={12} className={sectionStyles.row}>
        <Grid container>
          <Grid item xs={9} className={sectionStyles.row} style={{ alignItems: 'center' }}>
            <div className={`${sectionStyles.column} ${classes.imageWrapper}`}>
              {isEmpty(lineItemObj?.data?.image_url) && (
                <ComponentTypeImg componentType={lineItem.componentType} size="medium" />
              )}
              {!isEmpty(lineItemObj?.data?.image_url) && (
                <img src={lineItemObj?.data?.image_url} alt={imageUrl} style={{ width: 25, height: 35 }} />
              )}
            </div>

            <div className={sectionStyles.column}>
              <span className={classes.title}>{lineItem.data?.short_description}</span>
              <div className={sectionStyles.row} style={{ gap: '8px' }}>
                <span className={classes.label1}>{lineItem.data?.code}</span>
                <span className={classes.midDot}>&middot;</span>
                <span className={classes.label}>{`Qty: ${lineItem.quantity}`}</span>
                <span className={classes.midDot}>&middot;</span>
                <span className={classes.label}>{`Unit Price: ${currencySymbol}${formatCurrency(
                  lineItem.pricePerUnit
                )}`}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} className={`${sectionStyles.column} ${classes.priceWrapper}`}>
            <span
              className={classes.title}
              style={{ color: hasDiscount ? COLOR_PALETTE.green : COLOR_PALETTE.black }}
            >{`£${formatCurrency(afterDiscount)}`}</span>
            {hasDiscount && (
              <span className={`${sectionStyles.column} ${classes.price}`}>
                {`Was ${currencySymbol}${formatCurrency(beforeDiscount)}`}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(PartsList)
