import { styled } from 'opensolar-ui'
import React from 'react'
import useTranslateParse from 'util/useTranslateParse'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  marginBottom: 16,
})

const OwnerAndBankInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translateParse = useTranslateParse()

  return (
    <div data-testid="bluesnap-owner-and-bank-info-top">
      <Container>
        {translateParse(
          '<strong>Owner Info and Bank Info must be completed in a single session<strong> - please ensure you have all of the information available before starting.',
          {
            strong: (label: string) => <strong>{label}</strong>,
          }
        )}
      </Container>
    </div>
  )
}
export default OwnerAndBankInfoTop
