import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import ExpandLessIcon from '@material-ui/icons/ExpandLessOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import SendIcon from '@material-ui/icons/SendOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { sendInvitations as sendInvitationsAction } from 'ducks/invitation'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { permissionsSelectors } from 'ducks/permissions'
import GenericButton from 'elements/button/GenericButton'
import { useUxVersion } from 'hooks/useUxVersion'
import InfoIcon from 'icons/InfoIcon'
import StipCTAForPros from 'myenergy/selectionComponent/financeStipulations/StipCTAForPros'
import { Alert, Button, Dialog, Switch, TextField, Tooltip } from 'opensolar-ui'
import FinanceShareWarning from 'projectSections/sections/info/shareableLink/FinanceShareWarning'
import EditModal from 'projectSections/sections/manage3/common/EditModal'
import ContactsFormContent from 'projectSections/sections/manage3/details/elements/contacts/ContactsFormContent'
import { validateContactForm } from 'projectSections/sections/manage3/details/elements/contacts/ProjectContacts'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import DownloadBOMButton from 'resources/integrations/ironridge/DownloadBOMButton'
import LoanpalSendDocsProButton from 'resources/integrations/loanpal/LoanpalSendDocsProButton'
import MosaicShareCTA from 'resources/integrations/mosaic/MosaicShareCTA'
import SungageSendAppButton from 'resources/integrations/sungage/SungageSendAppButton'
import SunlightQuickCreateLauncher from 'resources/integrations/sunlight/SunlightQuickCreateLauncher'
import GreenLancerCTA from 'resources/projects/GreenLancer/GreenLancerCTA'
import restClient from 'restClient'
import { NORMAL_CONTACT } from 'types/contacts'
import { parseIntegrationJson, SystemsDataFromDesignDataJsonString } from 'util/misc'
import PaymentOptionWarning from '../PaymentOptionWarning'
import InvitationDialog from './InvitationDialog'

var QRCode = require('qrcode-svg')

const restClientInstance = restClient(window.API_ROOT + '/api')

function filterRealContacts(contacts) {
  const ret = []
  for (const contact of contacts) {
    if (contact.type === NORMAL_CONTACT) {
      ret.push(contact)
    }
  }
  return ret
}

const _InviteDialog = ({
  handleClose,
  projectId,
  sendInvitations,
  contacts,
  defaultMessage,
  defaultSubject,
  project,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useForm()
  const shareLink = form.getState().values?.share_link
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [urlInfo, setUrlInfo] = useState(false)
  const [url, setUrl] = useState('')
  const realContacts = filterRealContacts(contacts)
  const hasContacts = realContacts.length > 0
  const proposalPermission = useSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const [enableSharing, setEnableSharing] = useState({
    checkEnabled: false,
  })
  const orgId = useSelector(authSelectors.getOrgId)
  const disableEmailButton = !realContacts.every((contact) => contact.email && contact.email.trim() !== '')
  const [contactDialog, setContactDialog] = useState(false)
  const ui3Enabled = useUxVersion() === 3

  const validation = useCallback(() => {
    if (!hasContacts) {
      return 'Add customer contacts to enable send to customer.'
    }
    if (disableEmailButton) {
      return 'Please update the contact details with a valid email before sending the proposal via email.'
    }
    return null
  }, [hasContacts, disableEmailButton])
  const errorMessage = validation()
  const disableButton = Boolean(errorMessage)

  const projectValues = useFormState().values
  const [soldSystem, soldPaymentOption] = useGetSoldRecords(projectValues)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  const contactDisplay = useMemo(() => {
    return (
      contacts
        ?.filter(Boolean)
        .filter((contact) => contact.type === NORMAL_CONTACT)
        .map(
          (contact) =>
            contact.display ||
            (!contact.first_name && !contact.family_name
              ? contact.email
              : `${contact.first_name || ''} ${contact.family_name || ''}`)
        )
        .join(', ') || ''
    )
  }, [contacts])

  if (url !== '') {
    var svgQRCode = new QRCode({
      content: url,
      padding: 4,
      width: 100,
      height: 100,
      color: '#000000',
      background: 'none',
      join: true,
    }).svg()
  }

  const QRcode = () => {
    return { __html: svgQRCode }
  }

  useEffect(() => {
    if (shareLink !== '') {
      setUrl(shareLink)
      setEnableSharing({
        checkEnabled: true,
      })
    }
  }, [projectId])

  const handleOpen = () => {
    setIsOpen(true)
  }

  const onEnableOrDisableSharing = () => {
    if (enableSharing.checkEnabled) {
      if (!window.confirm(translate('Are you sure?\nThis will invalidate the existing shareable link permanently.'))) {
        return false
      } else {
        logAmplitudeEvent('link_sharing_disabled', {
          project_id: `${projectId}`,
          page: 'project/proposal ',
          has_cashflow: isProjectUsingCashflow,
        })
      }
      return restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/projects/' + projectId + '/share_link/',
        data: {
          enable: false,
        },
      }).then((res) => {
        if (res.data.url !== '') {
          setUrl(res.data.url)
        }
        if (res.data.url === '') {
          form.mutators.updateFieldSilently('share_link', res.data.url)
        }
      })
    } else {
      logAmplitudeEvent('link_sharing_enabled', {
        project_id: `${projectId}`,
        page: 'project/proposal',
        has_cashflow: isProjectUsingCashflow,
      })
      return restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + orgId + '/projects/' + projectId + '/share_link/',
        data: {
          enable: true,
        },
      }).then((res) => {
        if (res.data.url !== '') {
          setUrl(res.data.url)
          form.mutators.updateFieldSilently('share_link', res.data.url)
        }
      })
    }
  }

  const copyUrl = (url, notify, translate) => {
    navigator.clipboard.writeText(url).then(
      function () {
        notify('Link copied to clipboard', 'success')
      },
      function (err) {
        console.warn(err)
        notify('Failed to copy link to clipboard', 'warning')
      }
    )
  }

  const handleSwitchChange = (event) => {
    const promise = onEnableOrDisableSharing()
    if (promise) {
      setLoading(true)
      promise.finally(() => setLoading(false))
      setEnableSharing({ ...enableSharing, [event.target.name]: event.target.checked })
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        style={{ maxHeight: 'calc(100vh - 56px)', zIndex: 101 }}
        fullWidth
        width={680}
        disableEnforceFocus
      >
        <CloseIcon
          style={{ marginLeft: '95%', marginTop: '1%' }}
          onClick={() => {
            handleClose()
          }}
        />
        <Typography variant="h5" style={{ margin: 'auto', textAlign: 'center' }}>
          {translate('Send Proposal to %{contacts}', {
            contacts: contactDisplay,
          })}
          <Typography variant="subtitle1" style={{ margin: 'auto' }}>
            {translate('Choose from one of the options below:')}
          </Typography>
        </Typography>
        <PaymentOptionWarning />
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
          }}
        >
          {ui3Enabled && disableButton && (
            <Alert severity="error">
              <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 400 }}>
                <div>{translate('Missing Customer Email(s).')} </div>
                <Button variant="outlined" onClick={() => setContactDialog(true)}>
                  {translate('Add Customer Email')}
                </Button>
              </span>
            </Alert>
          )}

          <div style={{ width: '100%', backgroundColor: '#f5f5f5', marginTop: 20, marginBottom: 20 }}>
            <div style={{ width: '90%', margin: 'auto' }}>
              <Typography
                variant="subtitle1"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: '100%',
                }}
              >
                {translate('1. Send proposal link by email via OpenSolar platform')}
                <Tooltip title={disableButton ? translate(errorMessage) : ''}>
                  <span>
                    <Button
                      id="Customise_and_send_Proposal_btn"
                      style={{ height: 'unset', marginTop: 10, marginBottom: 10, width: '100%' }}
                      variant={ui3Enabled ? 'outlined' : 'contained'}
                      startIcon={<SendIcon />}
                      onClick={handleOpen}
                      disabled={disableButton}
                    >
                      <span>{translate('Customise and send Proposal')}</span>
                    </Button>
                  </span>
                </Tooltip>
              </Typography>
            </div>
          </div>
          {proposalPermission.allowView && proposalPermission.allowEdit && (
            <div style={{ width: '100%', backgroundColor: '#f5f5f5', marginBottom: 20 }}>
              <div style={{ width: '90%', margin: 'auto' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1">{translate('2. Enable Link Sharing')}</Typography>
                  <Switch checked={enableSharing.checkEnabled} onChange={handleSwitchChange} name="checkEnabled" />
                </div>
              </div>
            </div>
          )}
        </div>
        {enableSharing.checkEnabled && !loading && (
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
            }}
          >
            <div style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
              <div style={{ width: '90%', margin: 'auto' }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 10,
                    width: '100%',
                  }}
                >
                  {translate('Copy and share proposal link using the web address below:')}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TextField variant="outlined" size="small" fullWidth defaultValue={url} />
                    <Button
                      variant={ui3Enabled ? 'outlined' : 'contained'}
                      style={{ marginLeft: 10, minWidth: 135, marginTop: 10, marginBottom: 10 }}
                      startIcon={<LinkIcon />}
                      onClick={() => copyUrl(url, notify, translate)}
                    >
                      {translate('Copy link')}
                    </Button>
                  </div>
                  <FinanceShareWarning />
                </Typography>
              </div>
            </div>
            <div style={{ width: '100%', backgroundColor: '#f5f5f5', marginTop: 20 }}>
              <div style={{ width: '90%', margin: 'auto' }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: '100%',
                  }}
                >
                  {translate('Or scan QR code on your phone to access the proposal there, or to share via SMS')}
                  {url !== '' && <div dangerouslySetInnerHTML={QRcode()} />}
                </Typography>
              </div>
            </div>
            <div style={{ width: '100%', marginTop: 20, marginBottom: 30 }}>
              <Typography
                variant="h7"
                onClick={() => {
                  setUrlInfo(!urlInfo)
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <InfoIcon
                  style={{
                    color: 'rgba(255,160,0,0.95)',
                    width: 18,
                    height: 18,
                    verticalAlign: 'middle',
                  }}
                />
                {translate('Sharing URL Info')}
                {urlInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Typography>
              {urlInfo && (
                <p>
                  {translate(
                    'If your customer accesses the proposal via this link, you will receive notifications that your proposal has been accessed, but they will not indicate who has accessed the proposal (because this link is anonymous). If you would like to track who is accessing the proposal, please toggle to "email" above.'
                  )}
                </p>
              )}
            </div>
          </div>
        )}
      </Dialog>
      {isOpen && (
        <InvitationDialog
          handleClose={handleClose}
          projectId={projectId}
          sendInvitations={sendInvitations}
          contacts={contacts}
          defaultMessage={defaultMessage}
          defaultSubject={defaultSubject}
          project={project}
        />
      )}
      {contactDialog && (
        <div style={{ position: 'relative', zIndex: 102 }}>
          <EditModal
            open={true}
            initialValues={{
              ...project,
              contacts_data: contacts.filter((contact) => contact.type === NORMAL_CONTACT),
            }}
            headerText={translate('Contacts')}
            content={<ContactsFormContent alertSeverity={'error'} />}
            validate={validateContactForm}
            onClose={() => {
              setContactDialog(false)
            }}
            saveParentOnConfirm={true}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  defaultMessage: orgSelectors.getOrg(state)?.invitation_email_custom_message,
  defaultSubject: orgSelectors.getOrg(state)?.invitation_email_custom_subject,
})

export const InviteDialog = connect(mapStateToProps, {
  sendInvitations: sendInvitationsAction,
})(_InviteDialog)

const InviteButton = ({ projectId, active, contacts, project, orgData }) => {
  const getIsSold = () => {
    if (!project) return false
    else return !!project.system_sold
  }

  const [open, setOpen] = useState(false)
  const [isSold, setIsSold] = useState(getIsSold())
  const [showStipCTA, setShowStipCTA] = useState(false)
  const [mosaicAlreadyApplied, setMosaicAlreadyApplied] = useState(true)
  const [hasMosaicOption, setHasMosaicOption] = useState(false)
  const [showMosaicAgreement, setShowMosaicAgreement] = useState(false)
  const [showLoanpalAgreement, setShowLoanpalAgreement] = useState(false)
  const [loanpalActionData, setLoanpalActionData] = useState(undefined)
  const [showIronRidge, setShowIronridge] = useState(false)
  const [showSungageSendApp, setShowSungageSendApp] = useState(false)
  const [showSendSunlight, setShowSendSunlight] = useState(false)

  const designData = SystemsDataFromDesignDataJsonString(project.design)

  useEffect(() => {
    if (designData) {
      let foundMosaic = false
      let foundIronridge = false
      designData.forEach((system) => {
        if (orgData?.enable_mosaic) {
          system?.payment_options?.forEach((pmt) => {
            if (pmt.integration === 'mosaic') foundMosaic = true
          })
        }
        if (system.integration_json) {
          let integrationObj = parseIntegrationJson(system.integration_json)
          if (
            integrationObj &&
            integrationObj['ironridge'] &&
            integrationObj['ironridge']['project'] &&
            integrationObj['ironridge']['project']['id']
          ) {
            foundIronridge = true
          }
        }
      })
      // set mosaic state only if they haven't already applied
      if (!mosaicAlreadyApplied) {
        if (foundMosaic && !hasMosaicOption) setHasMosaicOption(true)
        else if (!foundMosaic && hasMosaicOption) setHasMosaicOption(false)
      }

      // set ironridge state
      if (!showIronRidge && foundIronridge) setShowIronridge(true)
      else if (showIronRidge && !foundIronridge) setShowIronridge(false)
    }
  }, [designData, orgData?.enable_mosaic, mosaicAlreadyApplied])

  useEffect(() => {
    if (
      project?.available_customer_actions &&
      project.available_customer_actions.length > 0 &&
      project.available_customer_actions[0] &&
      project.available_customer_actions[0].actions_available &&
      project.available_customer_actions[0].actions_available.length > 0
    ) {
      let hasStips =
        project.available_customer_actions[0]?.actions_available?.filter((act) => {
          const docs = act.stipulation_acceptable_docs ? JSON.parse(act.stipulation_acceptable_docs) : {}
          const hasDocs = docs && Object.keys(docs)?.length > 0
          return hasDocs && ['created'].includes(act.stipulation_review_status)
        })?.length > 0
      if (hasStips && !showStipCTA) setShowStipCTA(true)
      else if (!hasStips && showStipCTA) setShowStipCTA(false)

      let foundMosaicAgreement = false
      let hasSungageApp = false
      let hasSunlightApp = false
      project.available_customer_actions?.forEach((act) => {
        act.actions_available?.forEach((avail) => {
          if (avail.payment_method === 'sungage_application' && avail.status_code === 'available') hasSungageApp = true
          if (
            (avail.payment_method === 'sunlight_loan_application' ||
              avail.payment_method === 'sunlight_loan_prequal') &&
            avail.status_code === 'available'
          ) {
            hasSunlightApp = true
          }
          if (avail.document_type === 'mosaic_loan_agreement' && avail.status_title === 'available')
            foundMosaicAgreement = true
        })
      })

      let foundLoanpalAgreement = false
      project.available_customer_actions?.forEach((act) => {
        act.actions_available?.forEach((avail) => {
          if (avail.document_type === 'loanpal_loan_agreement' && avail.status_title === 'available')
            foundLoanpalAgreement = true
          setLoanpalActionData(avail)
        })
      })
      if (!foundMosaicAgreement && showMosaicAgreement && hasMosaicOption) setShowMosaicAgreement(false)
      else if (foundMosaicAgreement && !showMosaicAgreement && hasMosaicOption) setShowMosaicAgreement(true)

      if (!foundLoanpalAgreement && showLoanpalAgreement) setShowLoanpalAgreement(false)
      else if (foundLoanpalAgreement && !showLoanpalAgreement) setShowLoanpalAgreement(true)

      if (hasSungageApp !== showSungageSendApp) setShowSungageSendApp(hasSungageApp)
      if (hasSunlightApp !== showSendSunlight) setShowSendSunlight(hasSunlightApp)
    }
  }, [project?.available_customer_actions])

  useEffect(() => {
    if (
      orgData?.enable_mosaic &&
      project?.available_customer_actions &&
      project.available_customer_actions.length > 0
    ) {
      let appAvailable = false
      project.available_customer_actions?.forEach((act) => {
        let foundAnApp =
          act?.actions_available?.filter((avail) => {
            return avail.payment_method === 'mosaic_application' && avail.status_code === 'available'
          })?.length > 0
        if (foundAnApp && !appAvailable) appAvailable = true
      })
      if (appAvailable && mosaicAlreadyApplied) setMosaicAlreadyApplied(false)
      else if (!appAvailable && !mosaicAlreadyApplied && hasMosaicOption) setMosaicAlreadyApplied(true)
    }
  }, [project?.available_customer_actions, orgData?.enable_mosaic, hasMosaicOption])

  const translate = useTranslate()

  useEffect(() => {
    if (isSold !== getIsSold()) setIsSold(getIsSold())
  }, [project])

  const handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault()
    setOpen(true)
  }

  const systemsAndNestedPaymentOptions = designData?.map((systemData) => ({
    uuid: systemData.uuid,
    title: systemData.name ? systemData.name : systemData.moduleQuantity + ' Panel System',
    paymentOptionsData: systemData.payment_options
      .filter((po) => po.integration === 'sunlight' || po.integration === 'mosaic')
      .map((po) => ({
        id: po.id,
        title: po.title,
        integration: po.integration,
        externalReference: po.integration_external_reference,
        categoryId: po.category_id,
      })),
  }))

  if (isSold && project.country_iso2 === 'US') {
    return (
      <>
        {showStipCTA && <StipCTAForPros project={project} />}
        {!showStipCTA && showLoanpalAgreement && (
          <LoanpalSendDocsProButton project={project} loanpalAgreementData={loanpalActionData} />
        )}
        <GreenLancerCTA project={project} />
        {showMosaicAgreement && (
          <MosaicShareCTA
            project={project}
            designs={systemsAndNestedPaymentOptions}
            hasMosaicOption={true}
            mode="agreement"
          />
        )}
        {showIronRidge && <DownloadBOMButton designs={systemsAndNestedPaymentOptions} project={project} />}
      </>
    )
  } else {
    return projectId && active ? (
      <div>
        <GenericButton
          startIcon={<SendIcon style={{ color: '#FFFFFF' }} />}
          id="MyEnergyInviteButton"
          style={{ marginTop: 10, width: '100%' }}
          wrapperStyle={{ width: '100%' }}
          labelColor="#FFFFFF"
          backgroundColor="#42C310"
          backgroundColorOnHover="#369811"
          labelWrapperStyle={{
            padding: '0 0 0 6px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onClick={handleClick}
          label={translate('Send Proposal to Customer')}
        />
        {open && (
          <InviteDialog
            contacts={contacts}
            projectId={projectId}
            handleClose={() => setOpen(false)}
            project={project}
          />
        )}
        {orgData && orgData.enable_sunlight && showSendSunlight && (
          <SunlightQuickCreateLauncher project={project} designs={systemsAndNestedPaymentOptions} />
        )}
        {orgData && orgData.enable_mosaic && hasMosaicOption && (
          <MosaicShareCTA
            project={project}
            designs={systemsAndNestedPaymentOptions}
            mosaicAlreadyApplied={mosaicAlreadyApplied}
            hasMosaicOption={hasMosaicOption}
            mode="application"
          />
        )}
        {showSungageSendApp && <SungageSendAppButton project={project} />}
        {showIronRidge && <DownloadBOMButton designs={systemsAndNestedPaymentOptions} project={project} />}
      </div>
    ) : (
      <>
        <GenericButton
          startIcon={<SendIcon style={{ color: '#A2A2A2' }} />}
          id="MyEnergyInviteButton"
          style={{ marginTop: 10, width: '100%' }}
          wrapperStyle={{ width: '100%' }}
          labelWrapperStyle={{
            padding: '0 0 0 6px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          labelColor="#FFFFFF"
          backgroundColor="#cccccc"
          backgroundColorOnHover="#369811"
          disabled={true}
          label={translate('Send Proposal To Customer')}
        />
        {orgData && orgData.enable_sunlight && showSendSunlight && (
          <SunlightQuickCreateLauncher project={project} designs={systemsAndNestedPaymentOptions} />
        )}
        {showIronRidge && <DownloadBOMButton designs={systemsAndNestedPaymentOptions} project={project} />}
      </>
    )
  }
}

InviteButton.propTypes = {
  record: PropTypes.object,
}

export default InviteButton
