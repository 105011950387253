import { FC } from 'react'

/**
 * * Icon Component for: chevron-down
 */
type ChevronDownOutlineIconProps = {
  size?: number
  color?: string
}
export const ChevronDownOutlineIcon: FC<ChevronDownOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" fill="none" {...rest} {...iconAttributes}>
      <path d="M1 1L6.00081 5.58L11 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
