import { useMemo } from 'react'
import { useFormState } from 'react-final-form'

export const useBlockSource = (contentType: string): string | undefined => {
  const formValues = useFormState().values
  const elements = formValues.contents?.elements
  return useMemo(() => {
    const item = elements?.find((x) => x.contentType === contentType)
    if (item) return `contents.elements.${elements.indexOf(item)}.data`
    return undefined
  }, [contentType, elements])
}
