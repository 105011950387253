import * as Sentry from '@sentry/react'
import { myEnergySelectors } from 'ducks/myEnergy'
import { viewAsCustomerSelectors } from 'ducks/viewAsCustomer'
import useCheckoutActionCallback from 'myenergy/checkout/useCheckoutActionCallback'
import { OSSDK } from 'opensolar-sdk'
import type { SendProposalResponseType } from 'projectSections/sections/proposal/useSendProposalButton'
import { useEffect } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { LENDER_LOGO_PATHS } from 'resources/financeIntegrations/constants'
import { useSdkSingleton } from 'sdk/useSdkSingleton'
import type { ProposalDataType } from 'types/proposals'
import type { ProposalTemplateSettingsTypeV2 } from 'types/proposalTemplate'
import type { RootState } from 'types/state'
import type { AlteredDesignType } from './useMutateDesignComponentQuantity'

type ProposalDataTypeV2 = ProposalDataType & {
  proposalTemplateSettings: ProposalTemplateSettingsTypeV2
}

export type SdkProposalType = {
  proposalData: ProposalDataTypeV2
  fetchProjectData: () => void
  onPaymentMethodChange(id: number): void
  onSystemChange(uuid: string): void
  sendButtonOptions?: Omit<SendProposalResponseType, 'onSend'>
  // Actions only available when viewed as customer
  setIsViewAsCustomer?: (value: boolean) => void
  onSendProposal?: () => void
  onSaveTemplateSettings?: (newTemplateSettings: string) => Promise<any>
  onComponentQuantityChange?: (alteredDesign: AlteredDesignType) => void
}

const useBindProposal = ({
  sdk,
  proposalData,
  setIsViewAsCustomer,
  onPaymentMethodChange,
  sendButtonOptions,
  onSendProposal,
  onSystemChange,
  onSaveTemplateSettings,
  onComponentQuantityChange,
}: SdkProposalType & { sdk: OSSDK }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const onCheckoutElementAction = useCheckoutActionCallback()
  const isViewAsCustomer = useSelector(viewAsCustomerSelectors.getViewAsCustomer)
  const transactionState = useSelector((state: RootState) => state.transaction)
  const CheckoutElementsState = useSelector(myEnergySelectors.getCheckoutElements)

  useEffect(() => {
    sdk.proposal.data.value = proposalData
  }, [proposalData])

  useEffect(() => {
    sdk.proposal.checkoutElements.value = CheckoutElementsState
  }, [CheckoutElementsState])

  useEffect(() => {
    sdk.proposal.transaction.value = transactionState
  }, [transactionState])

  useEffect(() => {
    if (!proposalData.proposalTemplateSettings.proposal_v2_config?.template_settings) return

    try {
      sdk.proposal.templateSettings.value = JSON.parse(
        proposalData.proposalTemplateSettings.proposal_v2_config.template_settings
      )
    } catch (e) {
      sdk.proposal.templateSettings.value = {}
      Sentry.captureException(`Error: Failed to parse template_settings string: ${e}`)
      notify(translate('Failed to load proposal. Please try again later.'), 'error')
    }
  }, [proposalData]) // important to listen to proposalData, until we fix the sdk issue

  useEffect(() => {
    sdk.proposal.isViewAsCustomer.value = isViewAsCustomer
  }, [isViewAsCustomer])

  useEffect(() => {
    sdk.proposal.sendButtonOptions.value = sendButtonOptions || {}
  }, [JSON.stringify(sendButtonOptions)])

  useEffect(() => {
    const windowBaseUrl = (window.PUBLIC_URL || '').startsWith('http') ? window.PUBLIC_URL : window.origin
    sdk.proposal.lenderLogoPaths.value = Object.fromEntries(
      Object.entries(LENDER_LOGO_PATHS).map(([integrationKey, logoData]) => [
        integrationKey,
        `${windowBaseUrl}/images/${logoData.large}`,
      ])
    )
  }, [])

  // TODO: clear bindings
  sdk.proposal.data.own()
  sdk.proposal.checkoutElements.own()
  sdk.proposal.transaction.own()
  sdk.proposal.templateSettings.own()
  sdk.proposal.isViewAsCustomer.own()
  sdk.proposal.sendButtonOptions.own()
  sdk.proposal.lenderLogoPaths.own()
  sdk.proposal.setIsViewAsCustomer.own(async (value) => setIsViewAsCustomer?.(value))
  sdk.proposal.onCheckoutElementAction.own(onCheckoutElementAction)
  sdk.proposal.onSaveTemplateSettings.own(async (args) => onSaveTemplateSettings?.(args))
  sdk.proposal.onSendProposal.own(async () => onSendProposal?.())
  sdk.proposal.onSystemChange.own(async (uuid: string) => onSystemChange(uuid))
  sdk.proposal.onPaymentMethodChange.own(async (id: number) => onPaymentMethodChange(id))
  sdk.proposal.onComponentQuantityChange.own(async (alteredDesign: AlteredDesignType) =>
    onComponentQuantityChange?.(alteredDesign)
  )
}

// TODO: refactor, can we combine this with useBindProposal???
export const useSdkWithProposalV2 = ({
  proposalData,
  setIsViewAsCustomer,
  onPaymentMethodChange,
  onSystemChange,
  ...props
}: SdkProposalType) => {
  const sdk = useSdkSingleton()

  if (!sdk) {
    throw new Error('SDK not initialized, call useSdkInit before using this hook')
  }

  useBindProposal({
    sdk,
    proposalData,
    setIsViewAsCustomer,
    onPaymentMethodChange,
    onSystemChange,
    ...props,
  })
}

export default useBindProposal
