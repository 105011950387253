import { useMemo } from 'react'
import { AutocompleteInput } from 'react-admin'

export const CustomAutoCompleteInput = (props) => {
  const { choices, input, onChange, optionValue } = props
  const handleChange = (value) => {
    input.onChange(value)
    if (onChange) {
      const findItem = choices.find((item) => (optionValue ? item[optionValue] : item.url) === value)
      if (findItem) onChange(findItem)
    }
  }
  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input])
  return <AutocompleteInput {...props} choices={choices} input={inputProps} />
}
