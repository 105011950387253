import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import { useTranslate } from 'ra-core'
import { useContext } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'
import { FilesContext, FilesContextProps } from '../FilesContextWrapper'
import { EditFileFields } from '../formFields/EditFileFields'
import EmailDocumentationDialog from './EmailDocumentationDialog'
import GenerateDocumentDialog from './GenerateDocumentDialog'
import MergePdfsDialog from './MergePdfsDialog'
import MultipleFilesDialog from './MultipleFilesDialog'
import { PreviewDialog } from './PreviewDialog'
import { ShareUrlQrCodeDialog } from './ShareUrlQrCodeDialog'

export const CRUDFilesDialogs: React.FC = () => {
  const translate = useTranslate()
  const form = useForm()
  const {
    dialogSelected,
    recordSelected,
    onSuccess,
    onDismiss,
    projectId,
    projectUrl,
    orgId,
    recordsArraySelected,
    setRecordsArraySelected,
  } = useContext(FilesContext) as FilesContextProps

  const project_id = useSelector(projectInfoSelector.getProjectId)
  const isMultipleDialog = dialogSelected === 'upload_file' || dialogSelected === 'upload_site_photos'

  if (!dialogSelected) {
    return <></>
  }

  return (
    <div>
      <GenerateDocumentDialog
        onSuccess={onSuccess}
        onDismiss={onDismiss}
        projectId={projectId}
        orgId={orgId}
        projectForm={form}
      />
      <EmailDocumentationDialog isOpen={dialogSelected === 'send_documents'} />
      <MergePdfsDialog
        isOpen={dialogSelected === 'merge_pdfs'}
        onDismiss={onDismiss}
        recordsArraySelected={recordsArraySelected}
        setRecordsArraySelected={setRecordsArraySelected}
        onSuccess={onSuccess}
      />
      <CRUDRecordDialog
        isOpen={dialogSelected === 'edit_file'}
        updateFormValsFn={onSuccess}
        onDismiss={onDismiss}
        record={recordSelected}
        resource="private_files"
        basePath="/private_files"
        formContent={<EditFileFields />}
        notificationOnSuccess={true}
        customSuccessNotificationText={translate('Your File has been saved')}
        dialogTitle={translate('Edit Project File')}
        removeRecordFromForm={onSuccess}
        width="sm"
        hideDeleteBtn
      />
      <ShareUrlQrCodeDialog
        relativeUrl={`projects/${project_id}/private_files/`}
        isOpen={dialogSelected === 'share_upload_link'}
        onDismiss={onDismiss}
      />
      {recordSelected && (
        <PreviewDialog isOpen={dialogSelected === 'preview_file'} onDismiss={onDismiss} record={recordSelected} />
      )}
      {isMultipleDialog && (
        <MultipleFilesDialog
          isOpen={isMultipleDialog}
          onSuccess={onSuccess}
          onDismiss={onDismiss}
          dialogSelected={dialogSelected}
          projectId={projectId}
          orgId={orgId}
          projectUrl={projectUrl}
        />
      )}
    </div>
  )
}
