export type StyleNotice = { key: keyof CSSStyleDeclaration; value: string; level: StyleWarningLevel }
export type StyleNotices = StyleNoticesBrief & {
  id: string
  name: string
  order: number
}
export type StyleNoticesBrief = {
  styles: StyleNotice[]
  highestLevel: StyleWarningLevel
}

export type StyleWarningRules = {
  [key in keyof CSSStyleDeclaration]?: {
    level: StyleWarningLevel | ((value: string) => StyleWarningLevel | undefined)
  }
}

export enum StyleWarningLevel {
  info,
  warning,
  error,
}

export type PropsDefs = {
  [key: string]: {
    default?: string | number | boolean
    hidden?: boolean
  }
}

export type FoundStyleValue = {
  selectorFull?: string
  selectorMatched?: string
  values: Partial<CSSStyleDeclaration>
}
