import { Divider, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import classnames from 'classnames'
import React, { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { MpptType, StringType } from 'types/global'
import { celsiusToFahrenheit, getMeasurementsFromState, trimDecimalPlaces } from 'util/misc'
import MouseOverPopover from './MouseOverPopover'
import { calculateAddedTempIncrease, getMinMaxTemperature, getPanelSpecAtTemp, isValueWithinRange } from './Utils'

export const usePopoverStyles = makeStyles((theme: any) => ({
  wrapper: { fontSize: 12, color: 'rgb(126, 126, 126)' },
  icon: { width: 20, height: 20, cursor: 'help', color: theme.blue },
  divider: { margin: '10px 0', height: 2, backgroundColor: '#000000' },
  headingWrapper: { margin: '10px 0' },
  subHeadingWrapper: { margin: '10px 0' },
  heading: { color: '#000000', fontWeight: 400, fontSize: 14 },
  subHeading: { color: '#000000', fontWeight: 400, fontSize: 14 },
  itemRow: { display: 'flex', justifyContent: 'space-between', margin: '5px 0' },
  mpptItemRow: { display: 'flex', justifyContent: 'space-between', margin: '5px 0' },
  stringItemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
    width: '100%',
    '& span:first-child': {
      width: 50,
    },
  },
  figure: { fontWeight: 500, textAlign: 'right', width: 90 },
  validFigure: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  invalidFigure: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  highlightedFigure: { color: '#000000', fontWeight: 500, textAlign: 'right', width: 90, marginRight: 15 },
}))

type MpptPropsType = {
  mppt: MpptType
  index: number
}

type StringPropsType = {
  string: StringType
  index: number
  mpptVoltageRange: number[]
  inverterVoltageRange: number[]
  iscRange: number[]
  minTemp: number
  maxTemp: number
  maxPanelVoc: number
  minPanelVoc: number
  maxPanelVmp: number
  minPanelVmp: number
  maxPanelIsc: number
  minPanelIsc: number
}

type SpecsRangePropsType = {
  label: string
  unit: string
  range: number[]
  lowTempValue: number
  highTempValue: number
}

type ValidationiconPropsType = {
  isValid: boolean
}

const ValidationIcon = ({ isValid }: ValidationiconPropsType) => {
  return React.createElement(isValid ? CheckIcon : InfoOutlinedIcon, {
    style: { marginLeft: 5, width: 12, height: 12, color: isValid ? 'green' : 'red' },
  })
}

export const SpecsRangeWithValidation: FC<SpecsRangePropsType> = ({
  label,
  unit,
  range,
  lowTempValue,
  highTempValue,
}) => {
  const classes = usePopoverStyles()
  return (
    <div className={classes.stringItemRow}>
      <span>{label}</span>
      <span
        className={classnames(
          classes.figure,
          isValueWithinRange(range, lowTempValue) ? classes.validFigure : classes.invalidFigure
        )}
      >
        {trimDecimalPlaces(lowTempValue, 2)}
        {unit}
        <ValidationIcon isValid={isValueWithinRange(range, lowTempValue)} />
      </span>
      <span
        className={classnames(
          classes.figure,
          isValueWithinRange(range, highTempValue) ? classes.validFigure : classes.invalidFigure
        )}
      >
        {trimDecimalPlaces(highTempValue, 2)}
        {unit}
        <ValidationIcon isValid={isValueWithinRange(range, highTempValue)} />
      </span>
    </div>
  )
}

const getTotalPanel = (mppt: any) => {
  if (
    mppt.getSystem()?.inverters()?.length === 1 &&
    mppt.moduleQuantity() === 0 &&
    mppt.parent?.mppts()?.length === 1 &&
    !mppt.getSystem()?.dcOptimizer()
  ) {
    //use unstrung module quantity if there is only one string inverter (with or without optimisers) AND there is no stringing done,
    return mppt.getSystem()?.moduleQuantity()
  } else {
    return mppt.moduleQuantity()
  }
}

const StringDetail: FC<StringPropsType> = ({
  string,
  index,
  mpptVoltageRange,
  inverterVoltageRange,
  iscRange,
  minTemp,
  maxTemp,
  maxPanelVoc,
  minPanelVoc,
  maxPanelVmp,
  minPanelVmp,
  maxPanelIsc,
  minPanelIsc,
}) => {
  const translate = useTranslate()
  const classes = usePopoverStyles()
  const isFahrenheit = useSelector((state: any) => getMeasurementsFromState(state) === 'imperial')
  const moduleQuantity = string.moduleQuantity()
  const system = string.getSystem()
  const moduleData = system.moduleType()
  const systemHasModules = system.moduleGrids().length
  const maxPanelOperatingTemp = maxTemp + calculateAddedTempIncrease(moduleData.noct, systemHasModules ? system.moduleGrids()[0].getPanelConfiguration() : 'STANDARD')

  return (
    <div>
      <Divider className={classes.divider} />
      <div className={classes.subHeadingWrapper}>
        <span className={classes.subHeading}>
          {translate('String')} {index + 1}
        </span>{' '}
        {`(${string.moduleQuantity()})`} <span>{trimDecimalPlaces(string.getPanelsPower(), 2)}kW</span>
      </div>

      <div className={classes.stringItemRow}>
        <span></span>
        <span className={classes.highlightedFigure}>
          {isFahrenheit
            ? `${trimDecimalPlaces(celsiusToFahrenheit(minTemp), 1)}°F`
            : `${trimDecimalPlaces(minTemp, 1)}°C`}
        </span>
        <span className={classes.highlightedFigure}>
          {isFahrenheit
            ? `${trimDecimalPlaces(celsiusToFahrenheit(maxPanelOperatingTemp), 1)}°F`
            : `${trimDecimalPlaces(maxPanelOperatingTemp, 1)}°C`}
        </span>
      </div>
      <SpecsRangeWithValidation
        unit={'V'}
        label={'Voc'}
        range={inverterVoltageRange}
        lowTempValue={maxPanelVoc * moduleQuantity}
        highTempValue={minPanelVoc * moduleQuantity}
      />
      <SpecsRangeWithValidation
        unit={'V'}
        label={'Vmp'}
        range={mpptVoltageRange}
        lowTempValue={maxPanelVmp * moduleQuantity}
        highTempValue={minPanelVmp * moduleQuantity}
      />
      {iscRange[1] && (
        <SpecsRangeWithValidation
          unit={'A'}
          label={'Isc'}
          range={iscRange}
          lowTempValue={minPanelIsc}
          highTempValue={maxPanelIsc}
        />
      )}
    </div>
  )
}

const MpptDetailPopover: FC<MpptPropsType> = ({ index, mppt }) => {
  const classes = usePopoverStyles()
  const translate = useTranslate()
  const inverter = mppt.parent
  const isFahrenheit = useSelector((state: any) => getMeasurementsFromState(state) === 'imperial')

  const inverterVoltageRange = [inverter?.voltage_minimum, inverter?.voltage_max]
  const mpptVoltageRange = [inverter?.mppt_voltage_min || inverter?.voltage_minimum, inverter?.mppt_voltage_max]
  const iscMax = inverter?.current_isc_max
  const iscRange = [0, iscMax]
  const system = inverter.getSystem()

  const [minTemp, maxTemp] = getMinMaxTemperature(window.WorkspaceHelper?.project)
  const moduleData = mppt.getSystem().moduleType()

  // Calculate operating temperatures dynamically
  const systemHasModules = system.moduleGrids().length
  const maxPanelOperatingTemp = maxTemp + calculateAddedTempIncrease(moduleData.noct, systemHasModules ? system.moduleGrids()[0].getPanelConfiguration() : 'STANDARD')


  // Panel voltage calculations based on calculated operating temperatures
  const maxPanelVoc = getPanelSpecAtTemp(moduleData.voc, moduleData.temp_coefficient_voc, minTemp)
  const maxPanelVmp = getPanelSpecAtTemp(moduleData.max_power_voltage, moduleData.temp_coefficient_voc, minTemp)
  const maxPanelIsc = getPanelSpecAtTemp(moduleData.isc, moduleData.temp_coefficient_isc, maxPanelOperatingTemp)
  
  const minPanelVoc = getPanelSpecAtTemp(moduleData.voc, moduleData.temp_coefficient_voc, maxPanelOperatingTemp)
  const minPanelVmp = getPanelSpecAtTemp(
      moduleData.max_power_voltage,
      moduleData.temp_coefficient_voc,
      maxPanelOperatingTemp
  );
  const minPanelIsc = getPanelSpecAtTemp(moduleData.isc, moduleData.temp_coefficient_isc, minTemp)

 

  const mpptTotalOutput = mppt?.getPanelsPower()
  const totalPanel = getTotalPanel(mppt)

  return (
    <MouseOverPopover
      hasError={mppt.strings()?.some((string: any) => {
        const moduleQuantity = string.moduleQuantity()
        return (
          !isValueWithinRange(inverterVoltageRange, maxPanelVoc * moduleQuantity) ||
          !isValueWithinRange(inverterVoltageRange, minPanelVoc * moduleQuantity) ||
          !isValueWithinRange(mpptVoltageRange, maxPanelVmp * moduleQuantity) ||
          !isValueWithinRange(mpptVoltageRange, minPanelVmp * moduleQuantity) ||
          (iscMax ? iscMax < maxPanelIsc : false)
        )
      })}
    >
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <span className={classes.heading}>MPPT {index + 1}</span>{' '}
          <span>{trimDecimalPlaces(mpptTotalOutput, 2)}kW</span>
        </div>
        <div className={classes.mpptItemRow}>
          <span>{translate('Total Panels')}</span>
          <span className={classes.figure}>{totalPanel}</span>
        </div>
        <div className={classes.mpptItemRow}>
          <span>{translate('DC Input Voltage Range')}</span>
          <span className={classes.figure}>
            {inverterVoltageRange[0]} - {inverterVoltageRange[1]} V
          </span>
        </div>
        <div className={classes.mpptItemRow}>
          <span>{translate('MPPT Voltage Range')}</span>
          <span className={classes.figure}>
            {mpptVoltageRange[0]} - {mpptVoltageRange[1]} V
          </span>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.headingWrapper}>
          <span className={classes.subHeading}>{translate('Site Detail')}</span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('Maximum Location Temperature')}</span>
          <span className={classes.figure}>
            {isFahrenheit
              ? `${trimDecimalPlaces(celsiusToFahrenheit(maxTemp), 1)}°F`
              : `${trimDecimalPlaces(maxTemp, 1)}°C`}
          </span>
        </div>
        <div className={classes.itemRow}>
          <span>{translate('Minimum Location Temperature')}</span>
          <span className={classes.figure}>
            {isFahrenheit
              ? `${trimDecimalPlaces(celsiusToFahrenheit(minTemp), 1)}°F`
              : `${trimDecimalPlaces(minTemp, 1)}°C`}
          </span>
        </div>
        {iscMax && (
          <div className={classes.mpptItemRow}>
            <span>{translate('Max Short-Circuit Current')}</span>
            <span className={classes.figure}>{iscMax} A</span>
          </div>
        )}
        <div className={classes.itemRow}>
          <span>
              {translate('OpenSolar combines local solar irradiance and panel data to determine cell temperatures.')}{' '}
              <a 
                href="https://support.opensolar.com/hc/en-us/articles/12035668582543-Voc-and-Vmp-Calculations-in-Inverter-Tool-Tab" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{cursor: "pointer"}}
                >
                  {translate('Click here')}
              </a>{' '}
              {translate('to find out more.')}
          </span>
        </div>
        {mppt.strings().map((electricalString: any, electricalStringIndex: number) => (
          <StringDetail
            string={electricalString}
            index={electricalStringIndex}
            key={electricalString.uuid}
            inverterVoltageRange={inverterVoltageRange}
            mpptVoltageRange={mpptVoltageRange}
            iscRange={iscRange}
            minTemp={minTemp}
            maxTemp={maxTemp}
            maxPanelVoc={maxPanelVoc}
            minPanelVoc={minPanelVoc}
            maxPanelVmp={maxPanelVmp}
            minPanelVmp={minPanelVmp}
            maxPanelIsc={maxPanelIsc}
            minPanelIsc={minPanelIsc}
          />
        ))}
      </div>
    </MouseOverPopover>
  )
}

export default MpptDetailPopover
