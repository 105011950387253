import { Translate } from 'ra-core'
import { useMemo } from 'react'
import { lengthExact, lengthRange } from 'validations/length'

export const useIdentifierValidator = (country_iso2: string, translate: Translate, shouldValidate?: boolean) => {
  return useMemo(() => {
    if (!shouldValidate) return undefined
    if (country_iso2 === 'NZ') return lengthExact(11, translate)
    if (country_iso2 === 'AU') return lengthExact(11, translate)
    if (country_iso2 === 'GB') return lengthRange(6, 8, translate)
  }, [shouldValidate, country_iso2])
}
