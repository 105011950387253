import { Fade, makeStyles, MenuItem, Select } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { rolesSelectors } from 'ducks/auth_roles'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { imagerySelectors } from 'reducer/designer/detectImagery'
import { getMapTypesAtLocation } from 'sagas/designerSaga/detectImagery'
import { labelForImagery, parseQueryStringToDictionary } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import SectionContainer from '../SectionContainer'
import { useExploreLogic } from './useExploreLogic'

const useStyles = makeStyles((theme) => ({
  selectWrapper: {
    margin: '10px 0',
    position: 'fixed',
    bottom: 20,
    [theme.breakpoints.down('md')]: { bottom: 60 },
    [theme.breakpoints.down('xs')]: { bottom: 100 },
    left: 20,
    zIndex: 2000,
    display: 'flex',
  },
  menuItem: {
    '&.MuiMenuItem-root.Mui-selected': {
      color: '#1890ff',
      backgroundColor: 'none',
    },
  },
  input: {
    width: 210,
    margin: '0',
    height: 36,
    background: 'rgb(255,255,255)',
    '& fieldset': {
      border: 'none',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  promoWrapper: {
    height: 36,
  },
  promoContent: {
    paddingLeft: '6px',
    paddingRight: '16px',
    backgroundColor: 'white',
    marginLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
  },
  outlined: {},
}))

const ExplorePage: React.FC = () => {
  const form = useForm()
  const location = useLocation()
  const enableWallet = useFeatureFlag('os_wallet', 'on')
  const enablePreloadGoogleSolarApiImagery = !!useFeatureFlag('preload_gsa', 'on')
  const query = parseQueryStringToDictionary(location.search)

  if (query.lat && query.lon) {
    form.change('lon', parseFloat(query.lon))
    form.change('lat', parseFloat(query.lat))
  }

  const formValues = form.getState().values
  const projectCountryIso2 = formValues.country_iso2
  const projectState = formValues.state
  const projectAddress = formValues.address
  const classes = useStyles()
  const translate = useTranslate()
  const { selectedMapTypeOrFirst, setSelectedMapType, mapTypes } = useExploreLogic()
  const enablePremiumImagery = useSelector(rolesSelectors.getRoleHasPremiumImageryAccess)
  const [nearmapBannerMsg, setNearmapBannerMsg] = useState<string | false>(false) //false whenever this org has nearmap premium imagery enabled
  const [getMappingBannerMsg, setGetMappingBannerMsg] = useState<string | false>(false)
  const [getMappingPremiumBannerMsg, setGetMappingPremiumBannerMsg] = useState<string | false>(false)
  const [VexcelBannerMsg, setVexcelBannerMsg] = useState<string | false>(false)
  const blockedMapTypes = useSelector(imagerySelectors.getBlockedMapTypes)

  useEffect(() => {
    if (nearmapBannerMsg) logAmplitudeEvent('nearmap_explore_banner_shown', { message: nearmapBannerMsg })
  }, [nearmapBannerMsg])

  type PremiumProviderType = 'Nearmap' | 'GetMapping' | 'GetMappingPremium' | 'Vexcel'

  const generateBannerMsg = useCallback((has2D: boolean, has3D: boolean, provider: PremiumProviderType): string => {
    if (!has2D && !has3D) return ''
    let type_2d_3d

    if (has2D && has3D) type_2d_3d = translate(`2D and 3D`)
    if (has2D) type_2d_3d = translate(`2D`)
    if (has3D) type_2d_3d = translate(`3D`)

    if (provider === 'GetMapping' || provider === 'GetMappingPremium') {
      return translate('%{type_2d_3d} %{provider} Imagery is available for this location.', {
        type_2d_3d,
        provider,
      })
    }

    return translate('%{type_2d_3d} %{provider} Premium Imagery is available for this location.', {
      type_2d_3d,
      provider,
    })
  }, [])

  // I can't find a way to listen for map initialization so instead I'll just have it try, timeout, and try again until it tries 3 times
  const checkPremiumImagery = (
    premiumProvider: PremiumProviderType,
    bannerMsg: string | false,
    setBannerMsgFunc: (prev: string | false) => void,
    attemptNum = 0
  ) => {
    if (!projectCountryIso2) return
    if (attemptNum > 2) return

    try {
      const lonLatArray = window?.MapHelper?.activeMapInstance?.toMapData(true).center
      if (lonLatArray) {
        getMapTypesAtLocation(
          lonLatArray,
          projectCountryIso2,
          projectState,
          projectAddress,
          false,
          blockedMapTypes,
          undefined,
          enablePreloadGoogleSolarApiImagery
        )
          .then((locs) => {
            const releventMapType = locs[0].filter((mapType) => mapType.provider === premiumProvider)
            const has2D = releventMapType.some((mapType) => mapType.design_mode === '2D')
            const has3D = releventMapType.some((mapType) => mapType.design_mode === '3D')
            if (has2D || has3D) {
              const premiumImgResult = window.AccountHelper.getIsPremiumImageryAvailable(
                lonLatArray,
                projectCountryIso2,
                projectState
              )
              if (
                !bannerMsg &&
                (premiumImgResult?.isAvailable || premiumImgResult.blockedReason === 'insufficient_funds')
              ) {
                setBannerMsgFunc(generateBannerMsg(has2D, has3D, premiumProvider))
              } else if (bannerMsg) {
                setBannerMsgFunc(false)
              }
            } else {
              setTimeout(() => {
                checkPremiumImagery(premiumProvider, bannerMsg, setBannerMsgFunc, attemptNum + 1)
              }, 3000)
            }
          })
          .catch((err) => console.log('err', err))
      }
    } catch (e) {}
  }

  useEffect(() => {
    if (enablePremiumImagery) {
      setTimeout(
        () => checkPremiumImagery('GetMappingPremium', getMappingPremiumBannerMsg, setGetMappingPremiumBannerMsg, 0),
        2000
      )
      setTimeout(() => checkPremiumImagery('GetMapping', getMappingBannerMsg, setGetMappingBannerMsg, 0), 2000)
      setTimeout(() => checkPremiumImagery('Vexcel', VexcelBannerMsg, setVexcelBannerMsg, 0), 2000)
      setTimeout(() => checkPremiumImagery('Nearmap', nearmapBannerMsg, setNearmapBannerMsg, 0), 2000)
    }
  }, [enablePremiumImagery, projectCountryIso2])

  return (
    <div className={classes.selectWrapper}>
      <Select
        classes={{ outlined: classes.outlined }}
        variant="outlined"
        className={classes.input}
        onChange={(event) => {
          setSelectedMapType(event.target.value as string)
        }}
        value={selectedMapTypeOrFirst}
      >
        {mapTypes.map((mapType, index) => (
          <MenuItem key={index} value={mapType} className={classes.menuItem}>
            {labelForImagery(mapType)}
          </MenuItem>
        ))}
      </Select>
      {enableWallet && nearmapBannerMsg && (
        <Fade in={true} timeout={500}>
          <div className={classes.promoContent}>
            <img height="32" src={window.PUBLIC_URL + '/images/nearmap-full-logo.jpeg'} />
            <span>{translate(nearmapBannerMsg)}</span>
          </div>
        </Fade>
      )}
      {enableWallet && getMappingBannerMsg && (
        <Fade in={true} timeout={500}>
          <div className={classes.promoContent}>
            <img height="20" src={window.PUBLIC_URL + '/images/getmapping_logo.svg'} />
            <span>{translate(getMappingBannerMsg)}</span>
          </div>
        </Fade>
      )}
      {enableWallet && getMappingPremiumBannerMsg && (
        <Fade in={true} timeout={500}>
          <div className={classes.promoContent}>
            <img height="20" src={window.PUBLIC_URL + '/images/getmapping_logo.svg'} />
            <span>{translate(getMappingPremiumBannerMsg)}</span>
          </div>
        </Fade>
      )}
      {enableWallet && VexcelBannerMsg && (
        <Fade in={true} timeout={500}>
          <div className={classes.promoContent}>
            <img height="20" src={window.PUBLIC_URL + '/images/vexcel-logo.png'} />
            <span>{translate(VexcelBannerMsg)}</span>
          </div>
        </Fade>
      )}
    </div>
  )
}

export const Explore = () => {
  return (
    <SectionContainer section="explore" mode="explore">
      <ExplorePage />
    </SectionContainer>
  )
}
