import type { CheckOutDialogType } from 'myenergy/dialogs/checkout/types'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import type { CheckoutActionElementOptions } from 'opensolar-checkout'
import { CheckoutElementDataType } from 'opensolar-checkout'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { AvailableCustomerActionsType } from 'types/customerActions'
import { ProposalDataType } from 'types/proposals'
import { RootState } from 'types/state'
import { SystemDataType } from 'types/systems'

export const CLEAR_AVAILABLE_ACTIONS = 'CLEAR_AVAILABLE_ACTIONS'
export const GET_AVAILABLE_ACTIONS = 'GET_AVAILABLE_ACTIONS'
export const GET_AVAILABLE_ACTIONS_SUCCESS = 'GET_AVAILABLE_ACTIONS_SUCCESS'
export const GET_AVAILABLE_ACTIONS_FAIL = 'GET_AVAILABLE_ACTIONS_FAIL'
export const OPEN_CREDIT_DECISION_DIALOG = 'OPEN_CREDIT_DECISION_DIALOG'
export const CLOSE_CREDIT_DECISION_DIALOG = 'CLOSE_CREDIT_DECISION_DIALOG'
export const MARK_AS_SOLD = 'MARK_AS_SOLD'
export const SET_PROPOSAL_DATA_LOADING = 'SET_PROPOSAL_DATA_LOADING'
export const SET_PROPOSAL_DATA = 'SET_PROPOSAL_DATA'
export const CLEAR_PROPOSAL_DATA = 'CLEAR_PROPOSAL_DATA'
export const FORCE_OPEN_CREDIT_APP = 'FORCE_OPEN_CREDIT_APP'
export const MARK_CREDIT_APP_AS_OPENED = 'MARK_CREDIT_APP_AS_OPENED'
export const SHOW_FULL_SCREEN_LOADER = 'SHOW_FULL_SCREEN_LOADER'
export const HIDE_FULL_SCREEN_LOADER = 'HIDE_FULL_SCREEN_LOADER'
export const UPDATE_SHOW_TEMPLATE_LIBRARY_MODAL = 'UPDATE_SHOW_TEMPLATE_LIBRARY_MODAL'
export const SET_SEND_PROPOSAL_DIALOG_SHOWN = 'SET_SEND_PROPOSAL_DIALOG_SHOWN'
export const UPDATE_CHECKOUT_ELEMENTS = 'UPDATE_CHECKOUT_ELEMENTS'
export const SET_LOAN_DECISION_RENDERED = 'SET_LOAN_DECISION_RENDERED'
export const AUTO_LOGIN_LOAD_COUNT_INCREMENT = 'AUTO_LOGIN_LOAD_COUNT_INCREMENT'

export type AvailableActionsRequestData = {
  system_uuid?: string
  payment_option_id?: number
}

export type AvailableActionsResponseType = {
  data: {
    actions_available: ActionDataType[]
    system_uuid: string
  }[]
}

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

export const getAvailableActions = (orgId: number, projectId: number) => {
  return {
    type: GET_AVAILABLE_ACTIONS,
    payload: {
      orgId,
      projectId,
    },
  }
}

export const clearAvailableActions = () => {
  return {
    type: CLEAR_AVAILABLE_ACTIONS,
  }
}

export const doAvailableActionsRequest = (
  orgId: number,
  projectId: number,
  data?: AvailableActionsRequestData
): Promise<AvailableActionsResponseType> => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/projects/' + projectId + '/refresh_available_customer_actions/',
      data,
    })
      .then((response: AvailableActionsResponseType) => {
        resolve(response)
      })
      .catch((err: any) => {
        console.log('error getting available actions', err)
        reject({ data: [] })
      })
  })
}

export function* fetchAvailableActions(action: any) {
  try {
    const actions = yield call(doAvailableActionsRequest, action.payload.orgId, action.payload.projectId)
    yield put({ type: GET_AVAILABLE_ACTIONS_SUCCESS, availableCustomerActions: actions })
  } catch (e) {
    yield put({ type: GET_AVAILABLE_ACTIONS_FAIL, message: null })
  }
}

export type CreditDecisionDialogLoanType = 'loanpal' | 'mosaic' | 'sungage' | 'dividend' | 'generic'
export type CommonStipulationType = {
  description: string
}
export type DecisionDisplayPropsType = {
  decision:
    | 'APPROVED'
    | 'DECLINED'
    | 'PENDING'
    | 'CONDITIONAL'
    | 'APPROVED_COUNTER_OFFER'
    | 'Approved'
    | 'ApprovedWithStipulations'
    | 'Declined'
    | 'Pending'
  amountApproved?: number
  amountRequested?: number
  approvedContacts?: number[]
  appliedWithCoborrower?: boolean
  mosaicApplicantMessages?: Object
  stips?: CommonStipulationType[]
}

export const openCreditDecisionDialog = (
  loanType: CreditDecisionDialogLoanType,
  decisionDisplayProps: DecisionDisplayPropsType
) => {
  return {
    type: OPEN_CREDIT_DECISION_DIALOG,
    payload: {
      loanType,
      decisionDisplayProps,
    },
  }
}

export const forceOpenCreditApp = (systemId: undefined | number = undefined, pmtId: undefined | number = undefined) => {
  return {
    type: FORCE_OPEN_CREDIT_APP,
    payload: {
      systemId,
      pmtId,
    },
  }
}

export const setLoanDecisionRendered = () => {
  const now = new Date()
  return {
    type: SET_LOAN_DECISION_RENDERED,
    payload: {
      loanDecisionRenderedAt: now,
    },
  }
}

export const clearLoanDecisionRendered = () => {
  const now = new Date()
  return {
    type: SET_LOAN_DECISION_RENDERED,
    payload: {
      loanDecisionRenderedAt: undefined,
    },
  }
}

export const updateCheckoutElementsData = (
  checkoutElementsData: CheckoutElementDataType[],
  options: CheckoutActionElementOptions
) => {
  return {
    type: UPDATE_CHECKOUT_ELEMENTS,
    payload: {
      checkoutElementsData,
      options,
    },
  }
}

export const showCheckoutDialog = (checkoutDialog: CheckOutDialogType) => {
  return {
    type: UPDATE_CHECKOUT_ELEMENTS,
    payload: {
      checkoutDialog,
    },
  }
}

export const hideCheckoutDialog = () => {
  return {
    type: UPDATE_CHECKOUT_ELEMENTS,
    payload: {
      checkoutDialog: undefined,
    },
  }
}

export const markCreditAppAsOpened = () => {
  return {
    type: MARK_CREDIT_APP_AS_OPENED,
  }
}

export const showFullScreenLoader = () => {
  return {
    type: SHOW_FULL_SCREEN_LOADER,
  }
}

export const hideFullScreenLoader = () => {
  return {
    type: HIDE_FULL_SCREEN_LOADER,
  }
}

export const closeCreditDecisionDialog = () => {
  return {
    type: CLOSE_CREDIT_DECISION_DIALOG,
  }
}

export const markAsSold = (systemUuid: string, pmtId: number) => {
  return {
    type: MARK_AS_SOLD,
    payload: {
      systemUuid,
      pmtId,
    },
  }
}

export const resetSoldOptions = () => {
  return {
    type: MARK_AS_SOLD,
    payload: {
      systemUuid: undefined,
      pmtId: undefined,
    },
  }
}

export const setProposalDataLoading = (isLoading: boolean) => {
  return {
    type: SET_PROPOSAL_DATA_LOADING,
    payload: {
      loading: isLoading,
    },
  }
}

export const setProposalData = (val: ProposalDataType) => {
  return {
    type: SET_PROPOSAL_DATA,
    payload: {
      proposalData: val,
    },
  }
}

export const clearProposalData = () => {
  return {
    type: CLEAR_PROPOSAL_DATA,
  }
}

export const toggleSendProposalDialog = (isSendProposalDialogShown?: boolean) => ({
  type: SET_SEND_PROPOSAL_DIALOG_SHOWN,
  payload: { isSendProposalDialogShown },
})

export const updateShowTemplateLibraryModal = (config: { open: boolean; disableClose: boolean }) => {
  return {
    type: UPDATE_SHOW_TEMPLATE_LIBRARY_MODAL,
    payload: {
      showTemplateLibraryModal: config,
    },
  }
}

export type MyEnergyStateType = {
  loading: boolean
  error: boolean
  availableCustomerActions: ActionDataType[]
  checkoutElements: {
    checkoutDialog?: CheckOutDialogType
    checkoutElementsData: CheckoutElementDataType[]
    options?: CheckoutActionElementOptions
  }
  showCreditDecisionDialog: boolean
  loanType: CreditDecisionDialogLoanType | undefined
  decisionDisplayProps: DecisionDisplayPropsType | undefined
  forcedSoldSystemUuid: string | undefined
  forcedSoldPmtId: number | undefined
  forceOpenCreditApp: boolean
  forceOpenCreditAppSystemId: undefined | number
  forceOpenCreditAppPmtId: undefined | number
  showFullScreenLoader: boolean
  proposalData: ProposalDataType | undefined
  isSendProposalDialogShown: boolean
  showTemplateLibraryModal: {
    open: boolean
    disableClose: boolean
  }
  loanDecisionRenderedAt: Date | undefined
  autoLoginLoadCount: number
}

const defaultState = {
  loading: false,
  error: false,
  availableCustomerActions: [],
  checkoutElements: {
    checkoutDialog: undefined,
    checkoutElementsData: [],
    options: undefined,
  },
  showCreditDecisionDialog: false,
  loanType: undefined,
  decisionDisplayProps: undefined,
  forcedSoldSystemUuid: undefined,
  forcedSoldPmtId: undefined,
  forceOpenCreditApp: false,
  forceOpenCreditAppSystemId: undefined,
  forceOpenCreditAppPmtId: undefined,
  showFullScreenLoader: false,
  proposalData: undefined,
  isSendProposalDialogShown: false,
  showTemplateLibraryModal: {
    open: false,
    disableClose: false,
  },
  loanDecisionRenderedAt: undefined,
  autoLoginLoadCount: 1,
} as MyEnergyStateType

export default function reducer(state: MyEnergyStateType = defaultState, action: any) {
  switch (action.type) {
    case CLEAR_AVAILABLE_ACTIONS:
      return defaultState
    case GET_AVAILABLE_ACTIONS:
      return {
        ...state,
        loading: true,
      }
    case SET_PROPOSAL_DATA_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case SET_PROPOSAL_DATA:
      return {
        ...state,
        proposalData: action.payload.proposalData,
      }
    case UPDATE_CHECKOUT_ELEMENTS:
      return {
        ...state,
        checkoutElements: {
          ...state.checkoutElements,
          ...action.payload,
        },
      }
    case CLEAR_PROPOSAL_DATA:
      return {
        ...state,
        proposalData: undefined,
      }
    case GET_AVAILABLE_ACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_AVAILABLE_ACTIONS_SUCCESS:
      let existingActions = state.availableCustomerActions
      let newActions = action.availableCustomerActions?.data

      // Beware: This assumes the keys are in the same order
      let hasChanged = !!newActions && JSON.stringify(existingActions) !== JSON.stringify(newActions)
      if (hasChanged) {
        return {
          ...state,
          loading: false,
          error: true,
          availableCustomerActions: newActions,
        }
      } else {
        return {
          ...state,
          loading: false,
          error: true,
        }
      }
    case AUTO_LOGIN_LOAD_COUNT_INCREMENT:
      return {
        ...state,
        autoLoginLoadCount: state.autoLoginLoadCount + 1,
      }
    case OPEN_CREDIT_DECISION_DIALOG:
      return {
        ...state,
        decisionDisplayProps: action.payload.decisionDisplayProps,
        loanType: action.payload.loanType,
        showCreditDecisionDialog: true,
      }
    case CLOSE_CREDIT_DECISION_DIALOG:
      return {
        ...state,
        decisionDisplayProps: undefined,
        loanType: undefined,
        showCreditDecisionDialog: false,
      }
    case MARK_AS_SOLD:
      return {
        ...state,
        forcedSoldSystemUuid: action.payload.systemUuid,
        forcedSoldPmtId: action.payload.pmtId,
      }
    case FORCE_OPEN_CREDIT_APP:
      return {
        ...state,
        forceOpenCreditApp: true,
        forceOpenCreditAppSystemId: action?.payload?.systemId,
        forceOpenCreditAppPmtId: action?.payload?.pmtId,
      }
    case SET_LOAN_DECISION_RENDERED:
      return {
        ...state,
        loanDecisionRenderedAt: !!action?.payload?.loanDecisionRenderedAt,
      }
    case MARK_CREDIT_APP_AS_OPENED:
      return {
        ...state,
        forceOpenCreditApp: false,
      }
    case SHOW_FULL_SCREEN_LOADER:
      return {
        ...state,
        showFullScreenLoader: true,
      }
    case HIDE_FULL_SCREEN_LOADER:
      return {
        ...state,
        showFullScreenLoader: false,
      }
    case SET_SEND_PROPOSAL_DIALOG_SHOWN:
      return {
        ...state,
        isSendProposalDialogShown: action?.payload.isSendProposalDialogShown || !state.isSendProposalDialogShown,
      }
    case UPDATE_SHOW_TEMPLATE_LIBRARY_MODAL:
      return {
        ...state,
        showTemplateLibraryModal: action.payload.showTemplateLibraryModal,
      }
    default:
      return state
  }
}

export const myEnergySelectors = {
  getShouldForceOpenCreditApp: (state: RootState): boolean => !!state.myEnergy.forceOpenCreditApp,
  getShowFullScreenLoader: (state: RootState): boolean => !!state.myEnergy.showFullScreenLoader,
  getForceOpenAppSystemId: (state: RootState): number | undefined => state.myEnergy.forceOpenCreditAppSystemId,
  getForceOpenAppPmtId: (state: RootState): number | undefined => state.myEnergy.forceOpenCreditAppPmtId,
  getProposalProjectCountry: (state: RootState): string | null | undefined => {
    return state.myEnergy?.proposalData?.selectedProject?.country_iso2
  },
  getProposalAvailableActions: (state: RootState): AvailableCustomerActionsType | undefined => {
    return state.myEnergy?.proposalData?.selectedProject?.available_customer_actions
  },
  getProposalData: (state: RootState): undefined | ProposalDataType => {
    return state.myEnergy?.proposalData
  },
  getProposalSystems: (state: RootState): SystemDataType[] => {
    return state.myEnergy?.proposalData?.systems || []
  },
  getProposalProjectId: (state: RootState): number | undefined => {
    return state.myEnergy?.proposalData?.selectedProject?.id
  },
  getProposalOrgId: (state: RootState): number | undefined => {
    return state.myEnergy?.proposalData?.org?.id
  },
  getShowCreditDecisionDialog: (state: RootState): boolean => {
    return !!state.myEnergy?.showCreditDecisionDialog
  },
  getSystemUuidForcedMarkedAsSold: (state: RootState) => {
    return state.myEnergy?.forcedSoldSystemUuid
  },
  getPmtIdForcedMarkedAsSold: (state: RootState) => {
    return state.myEnergy?.forcedSoldPmtId
  },
  getIsSendProposalDialogShown: (state: RootState) => {
    return state.myEnergy?.isSendProposalDialogShown
  },
  getShowTemplateLibraryModal: (state: RootState) => {
    return state.myEnergy?.showTemplateLibraryModal
  },
  getIsLoanDecisionRendered: (state: RootState): Date | undefined => {
    return state.myEnergy?.loanDecisionRenderedAt
  },
  getCheckoutElements: (state: RootState) => {
    return state.myEnergy?.checkoutElements
  },
  getCheckoutDialog: (state: RootState) => {
    return state.myEnergy?.checkoutElements.checkoutDialog
  },
}

export function* myEnergySagas() {
  yield all([takeEvery(GET_AVAILABLE_ACTIONS, fetchAvailableActions)])
}
