import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { Error, ExpandMoreOutlined } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import { useInput } from 'ra-core'
import { FC, useMemo } from 'react'

const AccordionContent = styled(AccordionDetails, { name: 'AccordionContent' })({
  display: 'flex',
  flexDirection: 'column',
})

const AccordionStyled = styled(Accordion, { name: 'AccordionStyled' })({
  marginBottom: '20px !important',
  marginRight: 0,
})

const ErrorIcon = styled(Error, { name: 'ErrorIcon' })(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: 20,
  color: theme.palette.error.contrastText,
  marginLeft: 'auto',
}))

interface PropTypes {
  title: string
  defaultExpanded?: boolean
  source?: string
  assetSource?: string
}
const InputAccordion: FC<PropTypes> = ({ title, source, assetSource, defaultExpanded = false, ...props }) => {
  const {
    meta: { touched, error },
  } = useInput({ source: source || '' })
  const { meta } = useInput({ source: assetSource || '' })
  const hasError = useMemo(
    () => (touched && error && source !== undefined) || (meta.touched && meta.error && assetSource !== undefined),

    [source, assetSource, touched, error]
  )
  return (
    <AccordionStyled defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        {title} {hasError && <ErrorIcon />}
      </AccordionSummary>
      <AccordionContent>{props.children}</AccordionContent>
    </AccordionStyled>
  )
}

export default InputAccordion
