import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { LoadingDots, Typography, styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useRef, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { FieldPrefixFilterType } from '../types'
import { useGetPspOnboardingData } from '../utils'
import BankInfoSection from './BankInfoSection'
import BusinessTaxIdSection from './BusinessTaxIdSection'
import OwnerAndRepsSection from './OwnerAndRepsSection'

type PropTypes = {
  fields: any
  fieldPrefixFilter: FieldPrefixFilterType
  setFieldPrefixFilter: (boolean) => void
  setIsManualAddressEntryEnabled: (boolean) => void
  isManualAddressEntryEnabled: boolean
}

const StyledAccordion = styled(Accordion)({
  border: 'none !important',

  '& .MuiAccordionSummary-root': {
    minHeight: 'fit-content !important',
    padding: 0,
    margin: 0,
    borderBottom: '1px solid #E6E6E6',
  },

  '& .MuiAccordionSummary-content': {
    minHeight: 'fit-content !important',
    padding: 0,
    margin: '10px 0 15px',
  },

  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'fit-content !important',
    padding: 0,
    borderBottom: 'none',
  },

  '& .MuiAccordionDetails-root': {
    padding: 0,
    borderBottom: '1px solid #E6E6E6',
    paddingBottom: 10,
  },

  '& .MuiAccordion-root.Mui-expanded:first-child': {
    margin: '5px 0,',
  },

  '& .MuiAccordion-root.Mui-expanded:last-child': {
    border: 'none !important',
  },
})

const LoadingDotsWrapper = styled('div')({
  width: '100%',
  minHeight: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const OwnerAndBankInfoForm: React.FC<PropTypes> = (props) => {
  const { fields } = props
  const translate = useTranslate()
  const formVals = useFormState().values
  const notify = useNotify()

  const [expanded, setExpanded] = useState<string | false>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const orgCountry = useSelector(orgSelectors.getOrgIso2)
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const bluesnapOnboardingData = useGetPspOnboardingData('bluesnap')

  const savedTaxId = useRef(formVals?.businessInfo?.taxId).current
  const savedBankInfo = useRef(formVals?.bankingInfo).current

  const taxIdField = fields.filter((field) => {
    if (orgCountry === 'AU') return field.field_name === 'businessInfo.acnOrAbn'
    return field.field_name === 'businessInfo.taxId'
  })[0]
  const bankInfoFields = fields.filter((field) => field.section_name === 'Banking Information')
  const ownerFields = fields.filter(
    (field) =>
      field.section_name !== 'Banking Information' &&
      field.field_name !== 'businessInfo.taxId' &&
      field.field_name !== 'businessInfo.acnOrAbn'
  )

  useEffect(() => {
    const sectionName = bluesnapOnboardingData?.section_name

    if (Object.keys(formVals).length <= 3) {
      setExpanded('taxIdSection')
    } else {
      switch (sectionName) {
        case 'Business Information':
          setExpanded('taxIdSection')
          break
        case 'Banking Information':
          setExpanded('bankInfoSection')
          break
        case null:
        case undefined:
          setExpanded('taxIdSection')
          break
        default:
          setExpanded('contactsSection')
      }
    }
  }, [bluesnapOnboardingData])

  const toggleExpanded = (panel) => {
    setExpanded(expanded ? panel : false)
  }

  return (
    <div>
      {isLoading ? (
        <LoadingDotsWrapper>
          <LoadingDots />
        </LoadingDotsWrapper>
      ) : (
        <div data-testid="bluesnap-owner-and-bank-info-form">
          <StyledAccordion expanded={expanded === 'taxIdSection'} onChange={() => toggleExpanded('taxIdSection')}>
            <AccordionSummary aria-controls="taxIdSectiond-content" id="taxIdSectiond-header">
              <HeaderWrapper>
                <Typography textVariant="h5SemiBold"> 1. {translate('Tax ID')}</Typography>
                {expanded === 'taxIdSection' ? <ExpandLess /> : <ExpandMore />}
              </HeaderWrapper>
            </AccordionSummary>
            <AccordionDetails>
              <BusinessTaxIdSection taxIdField={taxIdField} />
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={expanded === 'contactsSection'} onChange={() => toggleExpanded('contactsSection')}>
            <AccordionSummary aria-controls="contactsSectiond-content" id="contactsSectiond-header">
              <HeaderWrapper>
                <Typography textVariant="h5SemiBold">
                  {'2. '}
                  {translate('Owner info')}
                </Typography>
                {expanded === 'contactsSection' ? <ExpandLess /> : <ExpandMore />}
              </HeaderWrapper>
            </AccordionSummary>
            <AccordionDetails>
              <OwnerAndRepsSection
                ownerFields={ownerFields}
                fieldPrefixFilter={props.fieldPrefixFilter}
                setFieldPrefixFilter={props.setFieldPrefixFilter}
                setIsManualAddressEntryEnabled={props.setIsManualAddressEntryEnabled}
                isManualAddressEntryEnabled={props.isManualAddressEntryEnabled}
              />
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion expanded={expanded === 'bankInfoSection'} onChange={() => toggleExpanded('bankInfoSection')}>
            <AccordionSummary aria-controls="bankInfoSectiond-content" id="bankInfoSectiond-header">
              <HeaderWrapper>
                <Typography textVariant="h5SemiBold">
                  {'3. '}
                  {translate('Bank info')}
                </Typography>
                {expanded === 'bankInfoSection' ? <ExpandLess /> : <ExpandMore />}
              </HeaderWrapper>
            </AccordionSummary>
            <AccordionDetails>
              <BankInfoSection
                bankInfoFields={bankInfoFields}
                setFieldPrefixFilter={props.setFieldPrefixFilter}
                fieldPrefixFilter={props.fieldPrefixFilter}
                setIsManualAddressEntryEnabled={props.setIsManualAddressEntryEnabled}
                isManualAddressEntryEnabled={props.isManualAddressEntryEnabled}
              />
            </AccordionDetails>
          </StyledAccordion>
        </div>
      )}
    </div>
  )
}
export default OwnerAndBankInfoForm
