import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const OtherComponentTypeSvg: FC<IconProps> = (props) => {
  const { color, fill, stroke } = props
  return (
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5423 3.5C16.8039 3.5 14.584 5.71992 14.584 8.45833C14.584 11.1967 16.8039 13.4167 19.5423 13.4167C22.2807 13.4167 24.5007 11.1967 24.5007 8.45833C24.5007 5.71992 22.2807 3.5 19.5423 3.5ZM23.334 8.45833C23.334 6.36425 21.6364 4.66667 19.5423 4.66667C17.4483 4.66667 15.7507 6.36425 15.7507 8.45833C15.7507 10.5524 17.4483 12.25 19.5423 12.25C21.6364 12.25 23.334 10.5524 23.334 8.45833Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 14.584C4.02234 14.584 3.5 15.1063 3.5 15.7507V23.334C3.5 23.9783 4.02234 24.5007 4.66667 24.5007H12.25C12.8944 24.5007 13.4167 23.9783 13.4167 23.334V15.7507C13.4167 15.1063 12.8944 14.584 12.25 14.584H4.66667ZM12.25 16.9173C12.25 16.273 11.7277 15.7507 11.0833 15.7507H5.83333C5.18901 15.7507 4.66667 16.273 4.66667 16.9173V22.1673C4.66667 22.8117 5.18901 23.334 5.83333 23.334H11.0833C11.7277 23.334 12.25 22.8117 12.25 22.1673V16.9173Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 3.5C4.02234 3.5 3.5 4.02234 3.5 4.66667V12.25C3.5 12.8944 4.02234 13.4167 4.66667 13.4167H12.25C12.8944 13.4167 13.4167 12.8944 13.4167 12.25V4.66667C13.4167 4.02234 12.8944 3.5 12.25 3.5H4.66667ZM12.25 5.83333C12.25 5.18901 11.7277 4.66667 11.0833 4.66667H5.83333C5.18901 4.66667 4.66667 5.18901 4.66667 5.83333V11.0833C4.66667 11.7277 5.18901 12.25 5.83333 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V5.83333Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7507 14.584C15.1063 14.584 14.584 15.1063 14.584 15.7507V23.334C14.584 23.9783 15.1063 24.5007 15.7507 24.5007H23.334C23.9783 24.5007 24.5007 23.9783 24.5007 23.334V15.7507C24.5007 15.1063 23.9783 14.584 23.334 14.584H15.7507ZM23.334 16.9173C23.334 16.273 22.8117 15.7507 22.1673 15.7507H16.9173C16.273 15.7507 15.7507 16.273 15.7507 16.9173V22.1673C15.7507 22.8117 16.273 23.334 16.9173 23.334H22.1673C22.8117 23.334 23.334 22.8117 23.334 22.1673V16.9173Z"
        fill={fill}
      />
    </svg>
  )
}

export const OtherComponentTypeIcon = createStandardIcon(OtherComponentTypeSvg)
