import { StepConnector, StepLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { StepIconProps } from '@mui/material/StepIcon'
import { useSendPvfActivationEmail } from 'hooks/usePremiumProducts'
import { StepperCheckIcon, styled } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import React, { useEffect, useState } from 'react'
import { useLocale } from 'react-admin'
import { useForm } from 'react-final-form'
import PremiumProductTermsDialog from 'resources/premiumProducts/PremiumProductTermsDialog'
import { ProjectType } from 'types/projects'
import { doNotTranslate, formatCurrencyWithSymbol } from 'util/misc'
import { colorMapping, colorMappingText, Scenario, scenarioDetails } from '../../../manage/scaffoldTile/constants'
import CreateQuote from '../../../manage/scaffoldTile/CreateQuote'
import OrderScaffold from '../../../manage/scaffoldTile/OrderScaffold'
import {
  BlackHeader,
  BoldDescription,
  ButtonWrapper,
  CalculateCostButton,
  CostDetailsWrapper,
  CustomWrapper,
  OrderInfoWrapper,
  RecalculateCostButton,
  StepContentWrapper,
  StepperWrapper,
  StepSection,
  StyledStep,
  TagWrapper,
  useStyles,
} from '../styles'

interface ScaffoldProps {
  calculateCost: () => void
  createQuote: () => void
  cancelOrder: () => void
  confirmOrder: () => void
  initializeCustomData: () => void
  isSubmitting: boolean
  project: ProjectType
}
const StyledConnector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#1751D0',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#1751D0',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#ECECED',
    borderRadius: 1,
  },
})(StepConnector)

const ColorlibStepIconRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{ ownerState: { completed?: boolean; active?: boolean } }>(({ ownerState }) => ({
  backgroundColor: '#ECECED',
  zIndex: 1,
  color: '#fff',
  width: 25,
  height: 25,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState?.active && {
    outline: '1px solid white',
    boxShadow: '0px 0px 0px 3px #618DF2, 0px 0px 0px 4px #FFF',
    backgroundColor: '#1751D0',
  }),
  ...(ownerState?.completed && {
    backgroundColor: '#1751D0',
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props
  const steps = [1, 2, 3]
  const icons: { [index: string]: React.ReactElement } = steps.reduce(
    (acc, step) => ({
      ...acc,
      [step]: <StepperCheckIcon />,
    }),
    {}
  )

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        icons[String(props.icon)]
      ) : (
        <span style={{ color: active ? 'white' : 'black' }}>{String(props.icon)}</span>
      )}
    </ColorlibStepIconRoot>
  )
}

const ScaffoldCard: React.FunctionComponent<ScaffoldProps> = ({
  calculateCost,
  createQuote,
  cancelOrder,
  confirmOrder,
  initializeCustomData,
  isSubmitting,
  project,
}) => {
  const classes = useStyles()
  const locale = useLocale()
  const form = useForm()
  const sendActivationEmail = useSendPvfActivationEmail()
  const customData = project?.custom_data?.scaffolding

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [isOrderScaffoldOpen, setIsOrderScaffoldOpen] = useState<boolean>(false)
  const [isCreateQuoteOpen, setIsCreateQuoteOpen] = useState<boolean>(false)
  const [enableDialogOpen, setEnableDialogOpen] = useState<boolean>(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [orderConfirmDialogOpen, setOrderConfirmDialogOpen] = useState<boolean>(false)
  const [steps, setSteps] = useState([
    {
      step: 1,
      label: 'Cost Calculation',
      description: 'Cost estimate',
    },
    {
      step: 2,
      label: 'Order Creation',
      description: 'Order Creation',
    },
    {
      step: 3,
      label: 'Order Submitted',
      description: 'Order Submitted',
    },
  ])

  useEffect(() => {
    if (customData?.scenario === Scenario.CREATE_QUOTE || customData?.scenario === Scenario.RECALCULATE_COST) {
      setCompleted({ 0: true, 1: false, 2: false })
      setActiveStep(1)
    } else if (
      customData?.scenario === Scenario.MAKE_PAYMENT ||
      customData?.scenario === Scenario.PENDING_REVIEW ||
      customData?.scenario === Scenario.PAYMENT_SETTLED ||
      customData?.scenario === Scenario.CANCELLED
    ) {
      setCompleted({ 0: true, 1: true, 2: true })
      setActiveStep(3)
    }
    setSteps([
      {
        step: 1,
        label: 'Cost Calculation',
        description: `Cost estimate`,
      },
      {
        step: 2,
        label:
          customData?.scenario !== Scenario.ESTIMATE_COST
            ? customData?.estimated_cost
              ? 'Order Creation'
              : 'Quote Creation'
            : 'Order Creation',
        description: 'Order Creation',
      },
      {
        step: 3,
        label:
          customData?.scenario !== Scenario.ESTIMATE_COST
            ? customData?.estimated_cost
              ? 'Order Submitted'
              : 'Quote Submitted'
            : 'Order Submitted',
        description: `Order Submitted`,
      },
    ])
  }, [customData?.scenario])

  const openOrderScaffold = () => setIsOrderScaffoldOpen(true)
  const openCreateQuote = () => setIsCreateQuoteOpen(true)

  const closeOrderScaffold = () => setIsOrderScaffoldOpen(false)
  const closeCreateQuote = () => setIsCreateQuoteOpen(false)
  const closeEnableDialog = () => setEnableDialogOpen(false)

  const onPvfEnable = () => {
    sendActivationEmail()
    form.change(`custom_data.scaffolding.form_data.scaffold_properties.is_pvf_enabled`, true)
    setEnableDialogOpen(false)
    openCreateQuote()
  }

  const handleRecalculateCost = () => {
    const updatedCustomData = { ...customData, scenario: Scenario.RECALCULATE_COST }
    form.mutators.updateField('custom_data.scaffolding', updatedCustomData)
    openOrderScaffold()
  }
  const handleQuoteCreation = async (e) => {
    if (!customData?.is_pvf_enabled) {
      setEnableDialogOpen(true)
      return
    }
    openCreateQuote()
  }
  const openCancelOrderDialog = () => {
    setCancelDialogOpen(true)
  }
  const openConfirmOrderDialog = () => {
    setOrderConfirmDialogOpen(true)
  }
  const costCalculationAfterCancel = () => {
    initializeCustomData()
    openOrderScaffold()
  }
  const handleCancelOrder = async () => {
    await cancelOrder()
    setCancelDialogOpen(false)
  }
  const handleConfirmOrder = async () => {
    await confirmOrder()
    setOrderConfirmDialogOpen(false)
  }
  const is48HoursPassed = (timestampString) => {
    const submissionTime = new Date(timestampString).getTime()
    const currentTime = new Date().getTime()
    const timeDifference = currentTime - submissionTime
    const hoursDifference = timeDifference / (1000 * 60 * 60) // Convert milliseconds to hours
    return hoursDifference >= 48
  }
  const getScenarioText = () => {
    const scenario = customData?.scenario
    if (scenario === Scenario.MAKE_PAYMENT) {
      if (customData?.estimated_cost > 0) {
        if (customData?.booked === 'order_confirmed') {
          return scenarioDetails[scenario].text
        } else {
          return scenarioDetails[Scenario.CONFIRM_ORDER].text
        }
      } else {
        return scenarioDetails[Scenario.PRICE_NOT_AVAILABLE].text
      }
    }
    return scenarioDetails[scenario].text
  }
  return (
    <div>
      {customData?.scenario === Scenario.ESTIMATE_COST ? (
        <CustomWrapper>
          <BlackHeader>{doNotTranslate('Order Scaffold')}</BlackHeader>
          <br />
          <div className={classes.description}>
            {doNotTranslate('Click "Calculate Cost" to calculate the cost for scaffolding for this project.')}
          </div>
          <CalculateCostButton
            variant="contained"
            onClick={customData.scenario === Scenario.CANCELLED ? costCalculationAfterCancel : openOrderScaffold}
            space="small"
          >
            {doNotTranslate('Calculate Cost')}
          </CalculateCostButton>
        </CustomWrapper>
      ) : (
        <>
          <CustomWrapper>
            <BlackHeader>{doNotTranslate('Scaffold')}</BlackHeader>
            <StepperWrapper
              orientation="horizontal"
              connector={<StyledConnector />}
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map((step, index) => (
                <StyledStep key={step.step} completed={completed[index]} active={activeStep === index}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
                  <StepContentWrapper>
                    <div>
                      <StepSection>
                        {step.label === 'Cost Calculation' &&
                          (customData?.scenario === Scenario.CREATE_QUOTE ||
                            customData?.scenario === Scenario.PENDING_REVIEW ||
                            customData?.scenario === Scenario.MAKE_PAYMENT ||
                            customData?.scenario === Scenario.RECALCULATE_COST ||
                            customData?.scenario === Scenario.PAYMENT_SETTLED) && (
                            <BoldDescription>
                              {doNotTranslate('Cost of Scaffolding:')}{' '}
                              {customData.estimated_cost > 0
                                ? formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)
                                : 'TBC'}
                            </BoldDescription>
                          )}

                        {(step.label === 'Order Submitted' || step.label === 'Quote Submitted') &&
                          scenarioDetails[customData?.scenario] && (
                            <TagWrapper
                              backgroundColor={scenarioDetails[customData?.scenario].backgroundColor}
                              color={scenarioDetails[customData?.scenario].color}
                            >
                              {getScenarioText()}
                            </TagWrapper>
                          )}
                        {(step.label === 'Order Submitted' || step.label === 'Quote Submitted') &&
                          (customData?.scenario === Scenario.PENDING_REVIEW ||
                            customData?.scenario === Scenario.MAKE_PAYMENT) && (
                            <OrderInfoWrapper>
                              {customData?.form_data?.project_installation_date && (
                                <div>
                                  Installation date:{' '}
                                  {new Date(customData?.form_data?.project_installation_date).toLocaleDateString(
                                    'en-GB'
                                  )}
                                </div>
                              )}
                              {customData.rag_status && (
                                <div>
                                  {doNotTranslate('Status:')}{' '}
                                  <span style={{ color: colorMapping[customData.rag_status.toLowerCase()] }}>
                                    {colorMappingText[customData.rag_status.toLowerCase()]}
                                  </span>
                                </div>
                              )}
                            </OrderInfoWrapper>
                          )}
                        {(step.label === 'Order Submitted' || step.label === 'Quote Submitted') &&
                          customData?.scenario === Scenario.CANCELLED && (
                            <CostDetailsWrapper>
                              {customData.estimated_cost > 0 && (
                                <>
                                  {doNotTranslate('Cost of Scaffolding:')}{' '}
                                  {formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)}
                                </>
                              )}
                            </CostDetailsWrapper>
                          )}
                      </StepSection>
                      <StepSection>
                        {(step.label === 'Order Submitted' || step.label === 'Quote Submitted') &&
                          (customData?.scenario === Scenario.PENDING_REVIEW ||
                            customData?.scenario === Scenario.MAKE_PAYMENT) && (
                            <ButtonWrapper
                              variant="contained"
                              color="error"
                              onClick={openCancelOrderDialog}
                              fullWidth={true}
                              disabled={
                                customData.booked === 'order_confirmed' &&
                                is48HoursPassed(customData.order_submission_timestamp)
                              }
                            >
                              {customData.estimated_cost > 0 ? 'Cancel Order' : 'Cancel Quote'}
                            </ButtonWrapper>
                          )}
                        {(step.label === 'Order Creation' || step.label === 'Quote Creation') &&
                          customData?.scenario === Scenario.MAKE_PAYMENT &&
                          customData.estimated_cost > 0 &&
                          customData.booked === 'quoting' && (
                            <CalculateCostButton
                              onClick={openConfirmOrderDialog}
                              fullWidth={true}
                              variant="contained"
                              color={'primary'}
                              space="small"
                            >
                              {'Confirm Order'}
                            </CalculateCostButton>
                          )}
                        {(step.label === 'Order Creation' || step.label === 'Quote Creation') &&
                          (customData?.scenario === Scenario.CREATE_QUOTE ||
                            customData?.scenario === Scenario.RECALCULATE_COST) && (
                            <CalculateCostButton
                              variant="contained"
                              onClick={(e) => handleQuoteCreation(e)}
                              color={'primary'}
                              fullWidth={true}
                              disabled={isSubmitting}
                              space="large"
                            >
                              {customData?.draft
                                ? 'Continue Quoting'
                                : customData?.estimated_cost
                                ? 'Order Scaffold'
                                : 'Get Quote'}
                            </CalculateCostButton>
                          )}

                        {step.label === 'Cost Calculation' && customData?.scenario === Scenario.CANCELLED && (
                          <CalculateCostButton
                            variant="contained"
                            onClick={costCalculationAfterCancel}
                            fullWidth={true}
                            disabled={isSubmitting}
                            space="medium"
                          >
                            {'Calculate New Cost'}
                          </CalculateCostButton>
                        )}
                        {step.label === 'Cost Calculation' &&
                          (customData?.scenario === Scenario.CREATE_QUOTE ||
                            customData?.scenario === Scenario.RECALCULATE_COST) && (
                            <RecalculateCostButton
                              variant="outlined"
                              onClick={handleRecalculateCost}
                              color={'default'}
                              fullWidth={true}
                              disabled={isSubmitting}
                            >
                              {'Recalculate Cost'}
                            </RecalculateCostButton>
                          )}
                      </StepSection>
                    </div>
                  </StepContentWrapper>
                </StyledStep>
              ))}
            </StepperWrapper>
          </CustomWrapper>
        </>
      )}
      <OrderScaffold
        isOpen={isOrderScaffoldOpen}
        onClose={closeOrderScaffold}
        calculateCost={calculateCost}
        openQuoteModal={openCreateQuote}
      />
      <PremiumProductTermsDialog
        open={enableDialogOpen}
        onClose={closeEnableDialog}
        productTitle={'PVF'}
        isEnableAndAccept
        onAccept={onPvfEnable}
      />
      <ConfirmModal
        open={cancelDialogOpen}
        setOpen={setCancelDialogOpen}
        handleSubmit={handleCancelOrder}
        title={'Are you sure you want to cancel your order?'}
        mainText={'Your order will no longer be processed if you proceed with the cancellation.'}
        submitBtnLabel={customData?.estimated_cost > 0 ? 'Yes, cancel order' : 'Yes, cancel quote'}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
      <ConfirmModal
        open={orderConfirmDialogOpen}
        setOpen={setOrderConfirmDialogOpen}
        handleSubmit={handleConfirmOrder}
        title={'Confirm Order'}
        mainText={
          !!customData?.estimated_cost
            ? 'Cost: ' + formatCurrencyWithSymbol(customData?.estimated_cost, '£', locale)
            : ''
        }
        subText={'Do you want to confirm your order?'}
        submitBtnLabel={'Yes, confirm order'}
        submitBtnProps={{ variant: 'contained', color: 'primary' }}
      />
      <CreateQuote isOpen={isCreateQuoteOpen} onClose={closeCreateQuote} createQuote={createQuote} />
    </div>
  )
}

export default ScaffoldCard
