import { Dialog, DialogContent, DialogTitle, IconButton, Typography as MuiTypography } from '@material-ui/core'
import { segenQuotePromptActions, segenQuotePromptSelectors } from 'ducks/segenQuotePrompt'
import { Button, CloseIcon, styled, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useSegenServiceV2 from 'services/segen/useSegenServiceV2'

const StyledDialog = styled(Dialog)({
  width: 'auto',
})

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledMuiTypography = styled(MuiTypography)({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24px',
})

const DialogActionContainer = styled('div')({
  textAlign: 'right',
  margin: '20px 24px',
})

const StyledDiscardButton = styled(Button)({
  margin: '0 8px',
})

const SegenQuotePrompt = ({ orderId }: { orderId: number }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const history = useHistory()
  const segenService = useSegenServiceV2()
  const [pathName, setPathName] = useState<string>('')
  const isDialogOpen = useSelector(segenQuotePromptSelectors.getSegenQuotePromptOpen)

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (!isDialogOpen) {
        dispatch(segenQuotePromptActions.setSegenQuotePrompt(true))
        setPathName(location.pathname)
        return false
      }
      return undefined
    })

    return () => {
      unblock()
    }
  }, [isDialogOpen, dispatch])

  const handleKeepQuote = () => {
    if (pathName) {
      dispatch(segenQuotePromptActions.setSegenQuotePrompt(false))
      history.push(pathName)
    }
  }

  const handleDiscardQuote = async () => {
    if (!orderId) return

    const response = await segenService.removeOrder(orderId)

    if (response.status === 200 && pathName) {
      dispatch(segenQuotePromptActions.setSegenQuotePrompt(false))
      history.push(pathName)
    }
  }

  return (
    <StyledDialog aria-labelledby="customized-dialog-title" open={isDialogOpen}>
      <StyledDialogTitle disableTypography>
        <StyledMuiTypography style={{ fontSize: '18px', fontWeight: 700, lineHeight: '24px' }}>
          {translate('Update your Segen price-locked quote?')}
        </StyledMuiTypography>
        <IconButton onClick={() => dispatch(segenQuotePromptActions.setSegenQuotePrompt(false))}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent dividers>
        <p>
          <Typography textVariant="body1">{translate('We have price-locked your Segen quote for 30 days.')}</Typography>
        </p>
        <p>
          <Typography textVariant="body1">
            {translate(
              'Do you want to keep this quote, or discard it? You can come back to this screen and create a new quote at any time.'
            )}
          </Typography>
        </p>
        <p>
          <Typography textVariant="body1">
            {translate(
              'Note, if you keep this quote and come back to this screen in the future, you may have duplicate quotes.'
            )}
          </Typography>
        </p>
      </DialogContent>
      <DialogActionContainer>
        <StyledDiscardButton variant="contained" color="default" onClick={handleDiscardQuote}>
          {translate("Discard, I'll fix this later")}
        </StyledDiscardButton>
        <Button variant="contained" color="primary" onClick={handleKeepQuote}>
          {translate('Yes, keep the quote as-is')}
        </Button>
      </DialogActionContainer>
    </StyledDialog>
  )
}

export default SegenQuotePrompt
