import { AdminProps } from 'ra-core'
import { FunctionComponent } from 'react'

import AdminContext from './AdminContext'
import AdminUI from './AdminUI'

/**
 * Main admin component, entry point to the application.
 *
 * Initializes the various contexts (auth, data, i18n, redux, router)
 * and defines the main routes.
 *
 * Expects a list of resources as children, or a function returning a list of
 * resources based on the permissions.
 *
 * @example
 *
 * // static list of resources
 *
 * import {
 *     Admin,
 *     Resource,
 *     ListGuesser,
 *     useDataProvider,
 * } from 'react-admin';
 *
 * const App = () => (
 *     <Admin dataProvider={myDataProvider}>
 *         <Resource name="posts" list={ListGuesser} />
 *     </Admin>
 * );
 *
 * // dynamic list of resources based on permissions
 *
 * import {
 *     Admin,
 *     Resource,
 *     ListGuesser,
 *     useDataProvider,
 * } from 'react-admin';
 *
 * const App = () => (
 *     <Admin dataProvider={myDataProvider}>
 *         {permissions => [
 *             <Resource name="posts" key="posts" list={ListGuesser} />,
 *         ]}
 *     </Admin>
 * );
 *
 * // If you have to build a dynamic list of resources using a side effect,
 * // you can't use <Admin>. But as it delegates to sub components,
 * // it's relatively straightforward to replace it:
 *
 * import React, { useEffect, useState } from 'react';
 * import {
 *     AdminContext,
 *     AdminUI,
 *     Resource,
 *     ListGuesser,
 *     useDataProvider,
 * } from 'react-admin';
 *
 * const App = () => (
 *     <AdminContext dataProvider={myDataProvider}>
 *         <Resources />
 *     </AdminContext>
 * );
 *
 * const Resources = () => {
 *     const [resources, setResources] = useState([]);
 *     const dataProvider = useDataProvider();
 *     useEffect(() => {
 *         dataProvider.introspect().then(r => setResources(r));
 *     }, []);
 *
 *     return (
 *         <AdminUI>
 *             {resources.map(resource => (
 *                 <Resource name={resource.name} key={resource.key} list={ListGuesser} />
 *             ))}
 *         </AdminUI>
 *     );
 * };
 */
const Admin: FunctionComponent<AdminProps> = ({
  appLayout,
  authProvider,
  catchAll,
  children,
  customReducers,
  customRoutes = [],
  customSagas,
  dashboard,
  dataProvider,
  history,
  i18nProvider,
  initialState,
  layout,
  loading,
  locale,
  loginPage,
  logoutButton,
  themePackages,
  persistentContent,
  menu, // deprecated, use a custom layout instead
  theme,
  title = 'React Admin',
  ui,
  errorHandlingOverrides,
}) => {
  if (appLayout && process.env.NODE_ENV !== 'production') {
    console.warn(
      'You are using deprecated prop "appLayout", it was replaced by "layout", see https://github.com/marmelab/react-admin/issues/2918'
    )
  }
  if (loginPage === true && process.env.NODE_ENV !== 'production') {
    console.warn(
      'You passed true to the loginPage prop. You must either pass false to disable it or a component class to customize it'
    )
  }
  if (locale && process.env.NODE_ENV !== 'production') {
    console.warn('You are using deprecated prop "locale". You must now pass the initial locale to your i18nProvider')
  }

  const UiComponentType = ui || AdminUI

  return (
    <AdminContext
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      history={history}
      customReducers={customReducers}
      customSagas={customSagas}
      initialState={initialState}
      errorHandlingOverrides={errorHandlingOverrides}
    >
      <UiComponentType
        layout={appLayout || layout}
        customRoutes={customRoutes}
        dashboard={dashboard}
        menu={menu}
        themePackages={themePackages}
        persistentContent={persistentContent}
        catchAll={catchAll}
        theme={theme}
        title={title}
        loading={loading}
        loginPage={loginPage}
        logout={authProvider ? logoutButton : undefined}
      >
        {children}
      </UiComponentType>
    </AdminContext>
  )
}

export default Admin
