export const addAsset = (field, fileArr, fieldName, itemIndex) => {
  if (field && field.constructor === Array && field.length) {
    const newUpload = field.filter((p) => p && p.rawFile && p.rawFile instanceof File)
    if (newUpload.length) fileArr.push({ ...newUpload[0], fieldName, itemIndex })
  }
  return fileArr
}

export const getFileUploads = (assets) => {
  let newFiles: any = []
  Object.keys(assets).forEach((fieldId) => {
    const field = assets?.[fieldId]
    if (field && fieldId === 'highlights') {
      field.forEach((highlight, i) => {
        newFiles = addAsset(highlight, newFiles, 'highlights', i)
      })
    } else {
      newFiles = addAsset(field, newFiles, fieldId, undefined)
    }
  })
  return newFiles
}

const multiUploadFields = ['highlights']
export const processFiles = (files, existingAssets) => {
  let assets = {}
  const singleUploads = files.filter((x) => x.itemIndex === undefined)
  singleUploads.forEach((file) => {
    const newObj = {
      src: file.src,
      title: file.title,
    }
    assets[file.fieldName] = newObj
  })

  const multipleUploads = files.filter((x) => x.itemIndex !== undefined)
  multiUploadFields.forEach((fieldKey) => {
    const filesByKey = multipleUploads.filter((x) => x.fieldName === fieldKey)
    if (filesByKey.length) {
      const maxLength = Math.max(...filesByKey.map((x) => x.itemIndex))
      let keyFiles: any[] = []
      for (let i = 0; i <= maxLength; i++) {
        if (existingAssets[fieldKey][i] && typeof existingAssets[fieldKey][i] === 'string') {
          keyFiles[i] = existingAssets[fieldKey][i]
        } else {
          const fileByIndex = filesByKey.find((x) => x.itemIndex === i)
          if (fileByIndex) {
            keyFiles[i] = {
              src: fileByIndex.src,
              title: fileByIndex.title,
            }
          } else {
            keyFiles[i] = null
          }
        }
      }
      if (keyFiles.length) assets[fieldKey] = keyFiles
    }
  })
  let standFiles = { assets }
  return standFiles
}

const uploadFields = {
  cover: 'cover_background',
  highlights: 'highlights',
}

export const formatSubmitValues = (values) => {
  if (!values.locale) values.locale = 'en'
  let elements = values.contents?.elements
  if (elements) {
    //clear data for disabled content blocks
    values.contents.elements = elements.map((x) => {
      if (!x.enabled && Object.keys(uploadFields).includes(x.contentType)) {
        const assetKey = uploadFields[x.contentType]
        if (!values.contents['assets']) values.contents['assets'] = {}
        values.contents['assets'][assetKey] = null
      }

      return { ...x, data: x.enabled ? x.data : undefined }
    })
  }
  return values
}

const youtubeRegex = /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/
const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
export const validateVideoUrl = (value: string) => {
  if (value && !youtubeRegex.test(value) && !vimeoRegex.test(value)) {
    return 'Invalid video link. Please input a valid Youtube or Vimeo link.'
  }
}

export const validateRequiredYoutubeUrl = (value: string) => {
  if (!value) return 'This field is required'
  if (value && !youtubeRegex.test(value)) {
    return 'Invalid video link. Please input a valid Youtube link.'
  }
}

export const validateExpoForm = (values) => {
  let errors = {}
  const elements = values?.contents?.elements
  if (elements) {
    let elementErrs = elements.map((element) => {
      if (element.contentType === 'stats' && element.data) {
        const invalidStats = element.data.filter((x) => !x.value || !x.description)
        if (invalidStats?.length) {
          let statErrs = element.data.map((stat) => {
            let objErr = {}
            if (!stat.value) objErr['value'] = 'This field is required'
            if (!stat.description) objErr['description'] = 'This field is required'
            return !stat.value || !stat.description ? objErr : undefined
          })
          return { data: statErrs }
        }
      }
      if (element.contentType === 'highlights' && element.data?.length) {
        let highlightErrs = element.data.map((highlight) => {
          let objErr = {}
          if (!highlight.title) objErr['title'] = 'This field is required'
          if (highlight.enableCountdown && !highlight.countdownDate) objErr['countdownDate'] = 'This field is required'
          return !highlight.title || (highlight.enableCountdown && !highlight.countdownDate) ? objErr : undefined
        })
        return { data: highlightErrs }
      }
      return undefined
    })
    if (elementErrs.filter((x) => x)) {
      errors['contents'] = {
        elements: elementErrs,
      }
    }
  }
  return errors
}
