import { useDataProvider } from 'react-admin'
import { useForm } from 'react-final-form'
import { generateUUID } from 'util/misc'
import { RecommendedBatterySetsRequestParams } from '../utility/types'

type PropsType = {
  projectId: number
  systemUuid: string
}

const getUnsavedData = (systemUuid: string) => {
  // Try to get unsaved project data and design data
  try {
    const restPostData = window.projectForm ? window.projectForm.mutators.getUnsavedChangeAffectSystemCalcs() : {}
    const sceneJSONFiltered = window.editor?.sceneSystemsOnlyAsJSON([systemUuid], true)

    // Inject system_current into each system, if found
    var system_current_matches = window.editor?.filterObjects(function (s) {
      return s.type === 'OsSystem' && s.userData.is_current === true
    })
    if (system_current_matches.length > 0) {
      for (var i = 0; i < sceneJSONFiltered['object']['children'].length; i++) {
        var s = sceneJSONFiltered['object']['children'][i]
        if (s.userData.is_current !== true) {
          s.userData.system_current = system_current_matches[0].userData
        }
      }
    }
    return {
      ...restPostData,
      design: sceneJSONFiltered && window.CompressionHelper.compress(JSON.stringify(sceneJSONFiltered)),
    }
  } catch (e) {
    console.error('useFetchRecommendedBatterySets: Failed to get unsaved data', e)
    return {}
  }
}

const useFetchRecommendedBatterySets = ({ projectId, systemUuid }: PropsType) => {
  const dataProvider = useDataProvider()
  const form = useForm()

  const fetchRecommendedBatterySets = async (settings: RecommendedBatterySetsRequestParams) => {
    // BDA encounters some issues when the system has some unsaved changes (see https://github.com/open-solar/opensolar-todo/issues/13202)
    // To resolve this, include unsaved system data and design data (if there is any)
    const unsavedDesignData = (form.mutators.getFormDirtyFields().length > 0 && getUnsavedData(systemUuid)) || {}

    const postData = {
      ...settings,
      ...unsavedDesignData,
      include_unsaved_project: true,
    }

    const response = await dataProvider.CUSTOM_POST('recommend_battery_sets', {
      url: `projects/${projectId}/systems/${systemUuid}/recommend_battery_sets/`,
      data: postData,
    })

    if (!response || !response.data) return []

    return response.data.map((batterySet) => ({
      ...batterySet,
      batteries: batterySet.batteries.map((battery) => ({ ...battery, type: 'battery' })),
      id: generateUUID(),
    }))
  }

  return fetchRecommendedBatterySets
}

export default useFetchRecommendedBatterySets
