import { getSdkSingleton_v1, initSdk_v1, isNestedWindow, OSSDK, SdkConfig } from 'opensolar-sdk'
import { useEffect, useState } from 'react'

/**
 * This allows the host application to access and configure the SDK.
 * It should only be used in one location in the host application.
 * Other locations should instead use useSdkSingletonReady.
 */

export const useSdkInit = (): { sdk: OSSDK | undefined; configResolved: boolean; shouldBind: boolean } => {
  const [sdk, setSdk] = useState<OSSDK | undefined>()

  useEffect(() => {
    let ret = getSdkSingleton_v1()
    if (ret) {
      setSdk(ret)
      return
    }
    initSdk_v1(getConfig()).then(setSdk)
  }, [])

  const [configResolved, setConfigResolved] = useState<boolean>(!!sdk?.resolvedConfig)
  const [shouldBind, setShouldBind] = useState<boolean>(false)

  // Watch configResolved
  useEffect(() => {
    if (!sdk) return
    let callback = () => setConfigResolved(!!sdk.resolvedConfig)
    sdk.onConfigResolved.add(callback)
    return () => sdk.onConfigResolved.remove(callback)
  }, [sdk])

  // Watch hasPeers
  useEffect(() => {
    if (!sdk) return

    const handler = () => {
      setShouldBind(sdk.peers.hasPeers.value)
    }
    handler() // update now

    sdk.peers.hasPeers.add(handler)

    return () => {
      sdk.peers.hasPeers.remove(handler)
      setShouldBind(false)
    }
  }, [sdk])

  return { sdk, configResolved, shouldBind }
}

const getConfig = (): SdkConfig => {
  let nested = isNestedWindow()

  const config: SdkConfig = {
    singleton: true,
    master: true,
    explicit_ready: true,
  }
  // When nested, allow the host app to govern these values
  if (!nested) {
    config.hostname_api = window.API_ROOT_ABSOLUTE
    config.hostname_spa = window.location.origin
  }
  return config
}
