import { FC } from 'react'
import ContentSlider from './common/ContentSlider'
import { CardWrapper, SectionWrapper } from './common/styles'

interface PropTypes {
  products: any[]
}

const Products: FC<PropTypes> = ({ products }) => {
  if (!products.length) return null
  return (
    <SectionWrapper>
      <ContentSlider
        cards={products}
        header={'Featured Products (placeholder)'}
        render={(product, i) => <CardWrapper>test</CardWrapper>}
      />
    </SectionWrapper>
  )
}

export default Products
