import { FC } from 'react'
import createStandardIcon from './createStandardIcon'
import type { IconProps } from './type'

const CheckContainedIconSvg: FC<IconProps> = (props) => {
  const { fill } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.400391 9.9999C0.400391 4.69797 4.69846 0.399902 10.0004 0.399902C15.3023 0.399902 19.6004 4.69797 19.6004 9.9999C19.6004 15.3018 15.3023 19.5999 10.0004 19.5999C4.69846 19.5999 0.400391 15.3018 0.400391 9.9999ZM15.0489 8.44843C15.5175 7.9798 15.5175 7.22 15.0489 6.75137C14.5803 6.28274 13.8205 6.28274 13.3519 6.75137L8.80039 11.3028L7.24892 9.75138C6.78029 9.28275 6.02049 9.28275 5.55186 9.75138C5.08323 10.22 5.08323 10.9798 5.55186 11.4484L7.95186 13.8484C8.42049 14.3171 9.18029 14.3171 9.64892 13.8484L15.0489 8.44843Z"
        fill={fill}
      />
    </svg>
  )
}

export const CheckContainedIcon = createStandardIcon(CheckContainedIconSvg)
