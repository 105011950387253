import { createContext, FC, useContext, useMemo } from 'react'
import { OsCardVersion } from '../card'
import { OsButtonVersion, OsCheckboxVersion, OsChipVersion, OsRadioVersion, TypographyVersion } from '../core'
import { OsAlertVersion } from '../core/Alert'
import { BoxVersion } from '../core/Box'
import { OsDatePickerVersion } from '../core/DatePicker'
import { OsDateTimePickerVersion } from '../core/DateTimePicker'
import { GridVersion } from '../core/Grid'
import { OsIconButtonVersion } from '../core/IconButton'
import { LinkVersion } from '../core/Link'
import { OsSelectVersion } from '../core/Select'
import { OsSnackbarVersion } from '../core/Snackbar'
import { OsSnackbarContentVersion } from '../core/SnackbarContent'
import { OsSwitchVersion } from '../core/Switch'
import { OsTooltipVersion } from '../core/Tooltip'
import { OsFieldTitleVersion, OsTextFieldVersion } from '../form'
import { OsDialogVersion } from '../popovers/Dialog'
import { OsTableVersion } from '../table/Table'

/**
 * Allows for specifying the version of the component to be used in this
 * part of the application hierarchy.
 *
 * Using `ComponentVersionsContext.Provider` won't inherit undefined component
 * versions from the parent context.
 * Do do this, instead use `ComponentVersionsInherit`.
 *
 * Usage:
 * ```
 *  <ComponentVersionsInherit versions={{button:3, chip:2, iconButton:1}}>
 *      <MySection {...props} />
 *  </ComponentVersionsInherit>
 * ```
 *
 * In OS main we have a set of configs for this purpose (spa/app/src/constants/uxVersions.ts).
 * Example:
 * ```
 *  <ComponentVersionsInherit versions={ComponentVersions_3_0}>
 *      <MySection {...props} />
 *  </ComponentVersionsInherit>
 * ```
 */

export const ComponentVersionsContext = createContext<ComponentVersions>({})

export const ComponentVersionsInherit: FC<{ versions: ComponentVersions }> = ({ children, versions }) => {
  const parentContext = useContext(ComponentVersionsContext)

  const resolvedVersions = useMemo(() => {
    return { ...parentContext, ...versions }
  }, [parentContext, versions])

  return <ComponentVersionsContext.Provider value={resolvedVersions}>{children}</ComponentVersionsContext.Provider>
}

// This is a version of the context which loads it's settings from localStorage, for debugging purposes
export const ComponentVersionsStoredKey = 'ui-component-versions'
export const ComponentVersionsStoredRoot: FC = ({ children }) => {
  let versions = parseStoredComponentVersions()
  return <ComponentVersionsContext.Provider value={versions}>{children}</ComponentVersionsContext.Provider>
}
export const parseStoredComponentVersions = () => {
  const versionsStr = localStorage.getItem(ComponentVersionsStoredKey)
  let versions
  if (versionsStr) {
    try {
      versions = JSON.parse(versionsStr)
    } catch (e) {
      console.warn('Failed to parse stored component versions from localStorage: ', ComponentVersionsStoredKey)
    }
  }
  if (!versions) versions = {}

  return versions
}

export const setStoredComponentVersions = (versions: ComponentVersions | undefined) => {
  if (!versions) localStorage.removeItem(ComponentVersionsStoredKey)
  else localStorage.setItem(ComponentVersionsStoredKey, JSON.stringify(versions))
}

export type ComponentVersions = {
  alert?: OsAlertVersion
  box?: BoxVersion
  button?: OsButtonVersion
  chip?: OsChipVersion
  grid?: GridVersion
  icon_button?: OsIconButtonVersion
  link?: LinkVersion
  switch?: OsSwitchVersion
  checkbox?: OsCheckboxVersion
  table?: OsTableVersion
  dialog?: OsDialogVersion
  radio?: OsRadioVersion
  select?: OsSelectVersion
  text_field?: OsTextFieldVersion
  typography?: TypographyVersion
  date_time_picker?: OsDateTimePickerVersion
  date_picker?: OsDatePickerVersion
  field_title?: OsFieldTitleVersion
  tooltip?: OsTooltipVersion
  card?: OsCardVersion
  snackbar?: OsSnackbarVersion
  snackbar_content?: OsSnackbarContentVersion
}
export type ComponentVersionsKey = keyof ComponentVersions
