import { orgSelectors } from 'ducks/orgs'
import { Box, CloseIcon, Dialog, DialogTitle, IconButton, styled } from 'opensolar-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getDefaultProposalSettings, getProposalV2Settings } from 'resources/proposalTemplates/ProposalV2FromContent'
import { ProposalTemplateTypeEnum } from 'resources/proposalTemplates/types'
import type { ProposalTemplateType } from 'types/proposalTemplate'
import { onChangeProposalTemplateSettings } from '../ProposalInputs.js'
import TemplateLibraryModalContent from './TemplateLibraryModalContent'
import { TemplateLibraryTypeEnum } from './useTemplateLibraryOptions'

const StyledDialog = styled(Dialog)({
  // override z-index to show on top of other dialogs
  zIndex: '2002 !important',
  '.MuiDialog-paper': {
    width: '600px',
    maxWidth: '600px',
  },
  '.MuiDialogTitle-root': {
    color: '#4C4B53',
    fontWeight: 600,
    svg: {
      stroke: 'none',
    },
  },
})

const StyledSection = styled('div')({
  width: 'calc(50% - 12px)',
  '> p': {
    fontWeight: 600,
    marginBottom: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export type PropTypes = {
  disableClose: boolean
  handleChange: () => void
  isSystemLimitExceeded: boolean
  setWaitingForAction: (value: boolean) => void
  open: boolean
  closeDialog: () => void
}

const useGetProposalTemplates = () => {
  const [proposalTemplates, setProposalTemplates] = useState<ProposalTemplateType[]>([])
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const dataProvider = useDataProvider()

  const loadProposalTemplates = useCallback(() => {
    setLoading(true)
    setLoaded(true)
    return (
      dataProvider
        //@ts-ignore
        .getList('proposal_templates', { filter: {} })
        .then((response) => {
          setProposalTemplates(response.data as ProposalTemplateType[])
        })
        .finally(() => {
          setLoading(false)
        })
    )
  }, [])

  const clearStoredProposalTemplates = useCallback(() => {
    setProposalTemplates([])
    setLoaded(false)
  }, [])

  return useMemo(
    () => ({
      loading,
      loaded,
      proposalTemplates,
      loadProposalTemplates,
      clearStoredProposalTemplates,
    }),
    [loading, loaded, proposalTemplates]
  )
}

/**
 * Use hook to control modal
 *
 * Sample:
 * const templateModal = useTemplateLibraryModalControl()
 * templateModal.setOpen()
 */
const TemplateLibraryModal: React.FC<PropTypes> = ({
  disableClose,
  handleChange,
  open,
  closeDialog,
  isSystemLimitExceeded,
  setWaitingForAction,
}) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const org = useSelector(orgSelectors.getOrg)
  const form = useForm()
  const formState = useFormState()
  const {
    loading,
    loaded,
    proposalTemplates,
    loadProposalTemplates,
    clearStoredProposalTemplates,
  } = useGetProposalTemplates()

  useEffect(() => {
    if (open && !loaded) {
      loadProposalTemplates()
    }
  }, [open, loaded])

  const onClose = () => {
    if (disableClose) return // do nothing
    closeDialog()
  }

  const registerAndUpdateField = (form, fieldName, value) => {
    const isFieldExist = form.getFieldState(fieldName)
    if (!isFieldExist) {
      form.registerField(fieldName, () => {}, {})
    }
    form.change(fieldName, value)
  }

  const handleSelect = async (
    updatedTemplate: TemplateLibraryTypeEnum,
    proposalTemplateData: ProposalTemplateType | undefined
  ) => {
    if (updatedTemplate === TemplateLibraryTypeEnum.ProposalV2 && proposalTemplateData === undefined) {
      // Create a default ProposalV2 template, if it doesn't exist yet
      setWaitingForAction(true)
      let newTemplateData: ProposalTemplateType | undefined
      try {
        const createResult = await dataProvider.CUSTOM_POST('proposal_templates_create', {
          url: 'proposal_templates/',
          data: {
            title: `${org!.name} Proposal`,
            type: ProposalTemplateTypeEnum.proposalV2,
            settings: JSON.stringify(getProposalV2Settings(getDefaultProposalSettings())),
          },
        })
        newTemplateData = createResult?.data
        clearStoredProposalTemplates() // clear cached proposal templates
      } catch (e) {
        console.error('Failed to create Default Proposal V2 template', e)
      }
      if (!newTemplateData?.url) {
        notify(translate('Failed to select proposal template. Please try again later.'), 'error')
        setWaitingForAction(false)
        return
      }
      proposalTemplateData = newTemplateData
      setWaitingForAction(false)
    }

    onChangeProposalTemplateSettings(form, proposalTemplateData)
    registerAndUpdateField(form, 'proposal_template', proposalTemplateData?.url || '')

    // TODO: The following logic should only be trigger when has_selected_template was false
    const oldCustomData = formState.values.custom_data || {}
    registerAndUpdateField(form, 'custom_data', {
      ...oldCustomData,
      proposal_config_v2: {
        ...(oldCustomData?.proposal_config_v2 || {}),
        has_selected_template: true,
      },
    })

    handleChange() // reflect form changes
    closeDialog()
  }

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>{translate('Template Library')}</span>
          {!disableClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <TemplateLibraryModalContent
        isSystemLimitExceeded={isSystemLimitExceeded}
        handleSelect={handleSelect}
        proposalTemplates={proposalTemplates}
        loading={loading}
      />
    </StyledDialog>
  )
}

export default TemplateLibraryModal
