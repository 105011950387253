import { TableBody as Mui4TableBody, TableBodyProps as Mui4TableBodyProps } from '@material-ui/core'
import { default as Mui5TableBody } from '@mui/material/TableBody'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'
import { OsTableVersion } from './Table'

export const TableBody: FC<OsTableBodyProps> = forwardRef((props, ref) => {
  return (
    <Inspector
      name="TableBody"
      propDefs={TableBodyProps}
      showExample={true}
      component={OsTableBody}
      props={{ ...props, ref }}
    />
  )
})

export type OsTableBodyProps = Mui4TableBodyProps

const OsTableBody: FC<OsTableBodyProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('table')
  switch (version) {
    case OsTableVersion.V2:
      return <Mui5TableBody ref={ref} {...props} />

    default:
      return <Mui4TableBody ref={ref} {...props} />
  }
})

const TableBodyProps: PropsDefs = {}
