import { Divider, Typography } from '@material-ui/core'
import { Alert as OSAlert, Grid, styled } from 'opensolar-ui'
import Alert from 'pages/ordering/checkoutv3/preOrder/components/Alert'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING } from 'pages/ordering/checkoutv3/preOrder/constants'
import { useTranslate } from 'ra-core'
import { useContext, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'
import IncomingListActionsField from 'resources/inventoryTransfer/fields/IncomingListActionsField'
import { DistributorPortalButton } from 'resources/inventoryTransfer/modals/OrderDistributor'
import { useDateFormatter } from 'util/date'
import { OrderContext, PREORDER } from '../..'
import OrderStatusStepper from '../../../manage3/hardware/OrderStatusStepper'
import { getSupplierName } from '../../util'

const FlexWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const InfoWrapper = styled('div')({
  width: '576px',
  maxWidth: '100%',
  height: '100%',
})

const StepWrapper = styled('div')<{ isSegen: boolean }>(({ theme, isSegen }) => ({
  width: isSegen ? '620px' : '474px',
  maxWidth: '100%',
  maxHeight: '100%',
}))

const DividerWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
})

const AlertWrapper = styled('div')({
  paddingBottom: 24,
})

const MainText = styled(Typography)({
  paddingBottom: 4,
})

const SubText = styled(Typography)({
  paddingBottom: 24,
})

const OrderInfo = ({ order }) => {
  const { setOrderId, updateHardwareComponent } = useContext(OrderContext)
  const project = useFormState().values
  const translate = useTranslate()
  const history = useHistory()
  const formatter = useDateFormatter({ variant: 'date' })
  const [status, setStatus] = useState(order.status)
  const orderNumber = order.order_number_display || order.order_number || order.order_id

  const handleStatus = (status) => {
    setStatus(status)
  }

  const getAlertText = (order) => {
    if (status === ORDER_STATUS.ORDERED && order.distributor === 'segen') {
      return 'Quote submitted to Segen. To convert your quote to an order, continue to the Segen portal.'
    }
    if (status === ORDER_STATUS.PROCESSING && (order.distributor !== 'outlet' || order.distributor !== 'segen')) {
      return 'Your order and payment are pending confirmation from your Account Handler. Once confirmed, you can mark the Processing step as complete.'
    }
    if (status === ORDER_STATUS.PROCESSING && order.distributor === 'segen') {
      return 'Order submitted to Segen. Please complete your order by creating a shipment on the Segen portal.'
    }
    if (status === ORDER_STATUS.PROCESSING && order.distributor === 'outlet') {
      return 'Next steps for your order: Solar Outlet will email you shortly to confirm your order and payment. Once confirmed, you’ll receive tracking or pickup details and an updated order number. Mark the Processing step as complete after confirmation. To change your order, contact Henry Mandorla at Solar Outlet (+61 449 734 823, henry@solarjuice.com.au), or your account manager.'
    }

    return undefined
  }

  const getUrl = () => {
    if (order.distributor === 'segen') {
      if (order.status === ORDER_STATUS.QUOTE_SUBMITTED) {
        return 'https://portal.segen.co.uk/Reseller/MyAccount/Quotes'
      }
      if (
        order.status === ORDER_STATUS.ORDERED ||
        order.status === ORDER_STATUS.PROCESSING ||
        order.status === ORDER_STATUS.DELIVERED
      ) {
        return 'https://portal.segen.co.uk/Reseller/OrderSummary/Summary'
      }
    }

    return ''
  }

  const handleResendOrder = () => {
    setOrderId(order.order_id)
    updateHardwareComponent(PREORDER)
  }

  return (
    <FlexWrapper>
      <Grid container>
        <Grid item xs={12} md>
          <InfoWrapper>
            <MainText variant="subtitle2" gutterBottom>
              {`Your Order ${order.order_id} (${getSupplierName(order)} ID ${orderNumber})`}
            </MainText>
            <SubText variant="body2">
              Ordered from {getSupplierName(order)} on {formatter(order.created_date)} to{' '}
              {project.address || project.org_name}
            </SubText>
            {getAlertText(order) && (
              <AlertWrapper>
                <OSAlert severity="info">
                  <Typography variant="body2" gutterBottom>
                    {getAlertText(order)}
                  </Typography>
                  {getUrl() !== '' && (
                    <DistributorPortalButton
                      distributor={order.distributor}
                      url={getUrl()}
                      label="Go to Segen Portal"
                      variant="contained"
                      textDecoration="none"
                    />
                  )}
                </OSAlert>
              </AlertWrapper>
            )}
            {order.status === ORDER_STATUS.DRAFT && (
              <Alert
                title={translate(
                  `Hmm, something went wrong with your order and we were not able to process your order with ${
                    DISTRBUTOR_KEY_TO_NAME_MAPPING[order.distributor]
                  }. Please resend your order.`
                )}
                severity="error"
                onSubmit={handleResendOrder}
                onSubmitLabel={translate('Resend Order')}
                fontWeight="normal"
              />
            )}
            <IncomingListActionsField
              transfer={order}
              transferType={'incoming'}
              allowReview={true}
              orderStatus={status}
              onManage={true}
            />
          </InfoWrapper>
        </Grid>
        <Grid item xs={12} md>
          <DividerWrapper>
            <Divider orientation="vertical" />
          </DividerWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StepWrapper isSegen={order.distributor === 'segen' ? true : false}>
            <OrderStatusStepper order={order} handleStatus={handleStatus} />
          </StepWrapper>
        </Grid>
      </Grid>
    </FlexWrapper>
  )
}

export default OrderInfo
