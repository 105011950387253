import { CheckoutFinancingCard as CheckoutFinancingCardUi } from 'opensolar-ui'
import type { Translate } from 'ra-core'
import type { CheckoutActionCallback, ProposalDataType } from '../../types'
import type { CheckoutFinanceType } from '../../types/CheckoutElementsDataTypes'

const CheckoutFinancingCard = ({
  onAction,
  proposalData,
  options,
  elementData,
  translate,
}: {
  onAction: CheckoutActionCallback
  elementData: CheckoutFinanceType
  translate: Translate
  proposalData: ProposalDataType
  options?: {
    isLoading?: boolean
  }
}) => {
  const handleAction = () => {
    onAction({ elementData, proposalData })
  }
  return <CheckoutFinancingCardUi onClick={handleAction} translate={translate} isProposal />
}

export default CheckoutFinancingCard
