import { OSSDK } from '../sdk'
import { SdkConfig, SdkInnerConfig } from '../types/config'
import { Logger } from '../util/logger'

export class OsView {
  readonly logger = new Logger('OSSDK.View')

  private element: HTMLIFrameElement | undefined
  private peerCleanup: (() => void) | undefined
  private readyPromise: Promise<void>
  private readyResolve: (() => void) | undefined
  private hostName: string

  constructor(private sdk: OSSDK, private config: SdkInnerConfig, private origConfig: SdkConfig, hostName?: string) {
    this.readyPromise = new Promise((resolve) => {
      this.readyResolve = resolve
    })
    this.hostName = hostName ?? this.config.hostname_spa
  }

  spawn(parent: HTMLElement, options?: { syncState?: boolean }) {
    // Some props we don't want to pass to the SPA
    const config = {
      ...this.origConfig,
      master: !this.config.master || this.sdk.peers.hasPeers.value ? undefined : false,
    }

    const iframeSrc = this.hostName + '?config=' + encodeURIComponent(JSON.stringify(config))

    // if this iFrame already exists we will not add it again.
    // this check should theoreretically not be required and it may cause problems if you really do
    // want to have the exact same project embeded more than once on the page, but for are trying to avoi
    // problems with multiple iframes being added to the page, possibly due to hot-reload or other reasons.
    const existingIframe = document.querySelector(`iframe[src="${iframeSrc}"]`) as HTMLIFrameElement

    let element

    if (existingIframe) {
      this.logger.warn('Skip adding iframe, use existing iframe because it already exists with exact same src.')
      element = existingIframe
    } else {
      element = document.createElement('iframe')
      element.src = iframeSrc
      element.width = '100%'
      element.height = '100%'
      element.style.setProperty('border', 'none')
      parent.appendChild(element)
    }
    const syncState = options?.syncState ?? false
    this.addElementToPeers({ element, syncState })
  }

  addElementToPeers({ element, syncState = false }: { element: HTMLIFrameElement; syncState: boolean }) {
    this.element = element
    this.peerCleanup = this.sdk.peers.addPeer(element.contentWindow!!, false, false, {
      onReadinessChange: (loaded: boolean, ready: boolean) => {
        if (loaded && ready) this.readyResolve?.()
      },
      syncState,
    })
  }

  ready(): Promise<void> {
    return this.readyPromise
  }

  onReadinessChange(loaded: boolean, ready: boolean) {}

  destroy() {
    this.peerCleanup?.()
    this.peerCleanup = undefined
  }

  setOverrides(overrides: ViewOverrides) {
    this.send({
      type: 'ViewSetViewInfo',
      overrides,
    })
  }

  send(msg: any) {
    if (!this.element) {
      throw new Error("can't send a message to an OsView until it has been spawned")
    }
    this.sdk.peers.send(this.element.contentWindow!!, msg)
  }
}

export type ViewOverrides = {
  [key: string]: ViewOverride
}
export type ViewOverride = {
  show?: boolean
}
