import { styled, TransactionActionButton } from 'opensolar-ui'
import { useMemo, useState } from 'react'
import { LoanApplicationProps } from '../loanApplication/LoanApplication'

const SequenceNumberWrapper = styled('div')(({ theme }) => {
  return {
    width: 44,
    height: 0,
    minWidth: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

const PlentiApplication = ({
  elementData,
  translate,
  proposalData,
  config,
  onAction,
  options,
}: LoanApplicationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { actionData } = elementData

  const openApplicationDialog = async () => {
    setIsLoading(true)
    await onAction({ elementData, proposalData })
    setIsLoading(false)
  }

  const buttonLabelText = useMemo(() => {
    if (actionData.status_title === 'InitiatedButIncomplete') {
      return translate('Continue with Plenti Finance')
    } else if (actionData.status_title === 'ApprovedPendingDocs') {
      return translate('Apply for Plenti Finance ✓')
    } else if (actionData.status_title === 'ApprovedNotTrackingSubstate') {
      return translate('Plenti Finance Approved ✓')
    } else {
      return translate('Apply for Plenti Finance')
    }
  }, [actionData])

  const extra_actions: React.ReactNode[] = []

  if (actionData.status_code === 'available') {
    extra_actions.push(
      <p key={extra_actions.length} className="small">
        {'Clicking "' +
          buttonLabelText +
          '" will take you to the Plenti\'s credit application. OpenSolar does not view or store any of the data you enter in this application.'}
      </p>
    )
  }

  if (actionData.status_description) {
    extra_actions.push(<p>{actionData.status_description}</p>)
  }

  return (
    <div>
      <TransactionActionButton
        isLoading={isLoading}
        label={
          <>
            <SequenceNumberWrapper>1</SequenceNumberWrapper>
            {buttonLabelText}
          </>
        }
        disabled={actionData.status_code !== 'available'}
        onClick={openApplicationDialog}
      />
      {extra_actions}
    </div>
  )
}

export default PlentiApplication
