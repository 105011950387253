import {
  makeStyles,
  Theme,
  Typography as Mui4Typography,
  TypographyProps as Mui4TypographyPropsType,
} from '@material-ui/core'
import { default as Mui5Typography } from '@mui/material/Typography'
import { CSSProperties, FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

// * MUI Typography API Spec  https://mui.com/material-ui/api/Typography/
export const Typography: FC<TypographyProps> = (props) => (
  <Inspector
    component={OsTypography}
    name="Typography"
    propDefs={TypographyPropsDefs}
    props={props}
    showExample={true}
  />
)

export enum TypographyVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

type TypographyVariant =
  | 'body1'
  | 'body1SemiBold'
  | 'body1Bold'
  | 'body2'
  | 'caption1'
  | 'caption2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h5SemiBold'
  | 'subtitle1'
  | 'subtitle1Bold'
  | 'subtitle2'
  | 'helper1'
  | 'titleSemiBold'

export interface TypographyProps extends Mui4TypographyPropsType {
  colorHex?: `#${string}`
  textVariant?: TypographyVariant
}

type StyleProps = {
  colorHex?: `#${string}`
  textVariant?: TypographyVariant
}

export const getTypographyStyles = ({ colorHex = '#212121', textVariant = 'body1' }: StyleProps): CSSProperties => {
  switch (textVariant) {
    // body
    case 'body1':
      return {
        color: colorHex,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      }
    case 'body1SemiBold':
      return {
        color: colorHex,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
      }
    case 'body1Bold':
      return {
        color: colorHex,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
      }
    case 'body2':
      return {
        color: colorHex,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
      }

    // caption
    case 'caption1':
      return {
        color: colorHex,
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
      }
    case 'caption2':
      return {
        color: colorHex,
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
      }

    // h
    case 'h3':
      return {
        color: colorHex,
        fontSize: '34px',
        fontWeight: 400,
        lineHeight: 'normal',
      }
    case 'h4':
      return {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: 'normal',
      }
    case 'h5':
      return {
        color: colorHex,
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
      }
    case 'h5SemiBold':
      return {
        color: colorHex,
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
      }

    // subtitles
    case 'subtitle1':
      return {
        color: colorHex,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
      }
    case 'subtitle1Bold':
      return {
        color: colorHex,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
      }
    case 'subtitle2':
      return {
        color: colorHex,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 'normal',
      }

    // helper
    case 'helper1':
      return {
        color: colorHex,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
      }

    case 'titleSemiBold':
      return {
        fontSize: '24px',
        fontWeight: 600,
        color: colorHex,
      }

    default:
      return {}
  }
}

const useMui4Styles = makeStyles<Theme, TypographyProps>(() => ({
  root: ({ colorHex, textVariant }) => ({
    ...getTypographyStyles({ colorHex, textVariant }),
  }),
}))

const OsTypography: FC<TypographyProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('typography')
  const styleProps: StyleProps = {
    colorHex: props.colorHex,
    textVariant: props.textVariant,
  }
  const v4Styles = useMui4Styles(styleProps)

  switch (version) {
    case TypographyVersion.V2:
      return (
        <Mui5Typography align={props.align} ref={ref} sx={{ ...getTypographyStyles(styleProps) }}>
          {props.children}
        </Mui5Typography>
      )

    default:
      return (
        <Mui4Typography align={props.align} classes={{ root: v4Styles.root }}>
          {props.children}
        </Mui4Typography>
      )
  }
})

const TypographyPropsDefs: PropsDefs = {
  textVariant: { default: 'body1' },
}
