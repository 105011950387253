import { permissionsSelectors } from 'ducks/permissions'
import _ from 'lodash'
import { formatNaturalGasValues } from 'projectSections/sections/energy/NaturalGas/utils'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getAllUsageFields, getUsageData } from 'resources/projects/UsageComponents'

// re-use proux1 getUsageData Func as much as possible.

const formatOtherEnergyTypes = (data) => {
  const FORMAT_FUNCTION_MAP = {
    // TODO: Add in other energy types formatters here
    natural_gas: formatNaturalGasValues,
  }

  return data.map((energyData) => {
    const formatFunc = _.get(FORMAT_FUNCTION_MAP, energyData?.energy_type)
    if (!formatFunc || !energyData) return energyData
    return formatFunc(energyData)
  })
}

export const useFormatUsageSubmitValues = () => {
  const permissions = useSelector(permissionsSelectors.getPermissionByKey('energy_usage_tariff'))

  return useCallback(
    (submitValues: any, formValues: any, dirtyFields: any[]) => {
      //it should be okay to differentiate create and edit
      //avoid passing another extra param just reuse formValues
      const creatingNewProject = !formValues.id
      const usageData = getUsageData(formValues)
      const otherEnergyTypes = usageData['other_energy_types']
      usageData['other_energy_types'] = formatOtherEnergyTypes(otherEnergyTypes)

      const formattedUsageData = JSON.stringify(usageData)
      const usageFields = getAllUsageFields()
      const dirtyUsageFields = _.intersection(usageFields, dirtyFields)

      if (creatingNewProject) {
        //set default usage
        if (permissions.allowCreate) submitValues['usage'] = formattedUsageData
      } else if (!formValues['usage'] && dirtyUsageFields.includes('usage_data_source')) {
        // this happens when usage_data_source is changed, see ElectricUsageContent:L235
        submitValues['usage'] = formattedUsageData
      } else if (dirtyUsageFields.length > 0) {
        submitValues['usage'] = formattedUsageData
      }

      return submitValues
    },
    [permissions.allowCreate, permissions.allowEdit]
  )
}
