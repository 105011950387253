import { Card as Mui4Card, CardProps as Mui4CardProps } from '@material-ui/core'
import { default as Mui5Card } from '@mui/material/Card'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

export const Card: FC<OsCardProps> = forwardRef((props, ref) => {
  return <Inspector name="Card" propDefs={CardProps} showExample={true} component={OsCard} props={{ ...props, ref }} />
})

export enum OsCardVersion {
  V1 = 1, // MUI4
  V2 = 2, // MUI5 + Stratis UI
}
export type OsCardProps = Mui4CardProps

const OsCard: FC<OsCardProps> = forwardRef((props, ref = null) => {
  const version = useComponentVersion('card')
  switch (version) {
    case OsCardVersion.V2:
      //@ts-ignore
      return <Mui5Card ref={ref} {...props} />

    default:
      return <Mui4Card ref={ref} {...props} />
  }
})

const CardProps: PropsDefs = {}
