import { Switch as Mui4Switch } from '@material-ui/core'
import { default as Mui5Switch, SwitchProps as Mui5SwitchPropsType } from '@mui/material/Switch'
import { FC, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { PropsDefs } from '../debug/types'
import { useComponentVersion } from '../hooks'

// * MUI Switch API Spec  https://mui.com/material-ui/api/switch/
export const Switch: FC<OsSwitchProps> = (props) => (
  <Inspector name="Switch" propDefs={SwitchPropsDefs} showExample={true} component={OsSwitch} props={props} />
)

export enum OsSwitchVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export type OsSwitchProps = Mui5SwitchPropsType

const OsSwitch: FC<OsSwitchProps> = forwardRef((props, ref) => {
  const version = useComponentVersion('switch')
  switch (version) {
    case OsSwitchVersion.V2:
      return <Mui5Switch ref={ref} {...props} />

    default:
      const { color, ...rest } = props
      const color4 =
        color === 'warning' || color === 'info' || color === 'success' || color === 'error' ? 'default' : color
      return <Mui4Switch ref={ref} {...rest} color={color4} />
  }
})

const SwitchPropsDefs: PropsDefs = {
  checkedIcon: { default: undefined },
  color: { default: 'primary' },
  disabled: { default: false },
  icon: { default: undefined },
  size: { default: 'medium' },
  value: { default: undefined },
}
