import { Delete } from '@material-ui/icons'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import InputAccordion from '../common/InputAccordion'
import MultipleCardField, { BtnWrapper } from '../common/MultipleCardField'
import { useBlockSource } from '../useBlockSource'

interface PropTypes {
  disabled?: boolean
}
const StatsFields: FC<PropTypes> = ({ disabled = false }) => {
  const translate = useTranslate()
  const source = useBlockSource('stats')
  if (!source) return null
  return (
    <InputAccordion title={translate('Why Us')} defaultExpanded={false} source={source}>
      <MultipleCardField
        source={source}
        addLimit={8}
        cardTitle={translate('Stats')}
        render={(item, { index, deleteCard }) => (
          <StatsInputs
            key={'stats-' + index}
            source={`${source}.${index}`}
            disabled={disabled}
            removeCard={() => {
              deleteCard(index)
            }}
            index={index}
          />
        )}
      />
    </InputAccordion>
  )
}

interface StatInputsProps {
  source: string
  disabled: boolean
  removeCard: () => void
  index: number
}
const StatsInputs: FC<StatInputsProps> = ({ source, disabled, removeCard, index }) => {
  const translate = useTranslate()
  return (
    <InputAccordion title={`${translate('Statistic')} ${index + 1}`} source={source}>
      <TextInput source={`${source}.value`} label="Value" disabled={disabled} />
      <TextInput source={`${source}.description`} label="Description" multiline disabled={disabled} />
      <BtnWrapper>
        <Button onClick={removeCard} startIcon={<Delete />} variant="contained" color="error" style={{ marginTop: 20 }}>
          {translate('Delete Stats')}
        </Button>
      </BtnWrapper>
    </InputAccordion>
  )
}

export default StatsFields
