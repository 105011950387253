import React from 'react'
import { useField } from 'react-final-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core'
import { useChoices } from 'ra-core'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      alignItems: 'flex-start',
    },
    label: {
      lineHeight: 1.5,
      marginTop: 10,
    },
  }),
  { name: 'RaRadioButtonGroupInputItem' }
)

const RadioButtonGroupInputItem = ({
  choice,
  optionText,
  optionValue,
  source,
  translateChoice,
  onChange,
  disabled,
}) => {
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })
  const classes = useStyles()
  const label = getChoiceText(choice)
  const value = getChoiceValue(choice)
  const {
    input: { type, ...inputProps },
  } = useField(source, {
    type: 'radio',
    value,
  })

  const nodeId = `${source}_${label}`

  return (
    <FormControlLabel
      label={label}
      htmlFor={nodeId}
      classes={classes}
      control={
        <Radio
          id={nodeId}
          color="primary"
          {...inputProps}
          disabled={disabled}
          onChange={(_, isActive) => isActive && onChange(value)}
        />
      }
    />
  )
}

export default RadioButtonGroupInputItem
