import { default as Mui5Button } from '@mui/material/Button'
import { styled } from '../../styles/styled'

const Wrapper = styled('div')(({ theme }) => {
  return {
    padding: '0px 10px',
  }
})

export const DepositCtaButton = ({
  label,
  disabled,
  onClick,
}: {
  label: string
  disabled: boolean
  onClick(): void
}) => {
  return (
    <Wrapper>
      <Mui5Button onClick={onClick} fullWidth variant="contained" color="secondary" disabled={disabled}>
        {label}
      </Mui5Button>
    </Wrapper>
  )
}
