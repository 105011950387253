import { FC } from 'react'

/**
 * * Icon Component for: more options icon
 */
type MoreOptionsIconProps = {
  size?: number
  color?: string
}
export const MoreOptionsIcon: FC<MoreOptionsIconProps> = ({ size = '1rem', color = 'currentColor', ...rest }) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} {...iconAttributes}>
      <g id="dot-vertical-filled">
        <g id="Icon">
          <path
            d="M10 6.5C8.89543 6.5 8 5.60457 8 4.5C8 3.39543 8.89543 2.5 10 2.5C11.1046 2.5 12 3.39543 12 4.5C12 5.60457 11.1046 6.5 10 6.5Z"
            fill={color}
          />
          <path
            d="M10 12.5C8.89543 12.5 8 11.6046 8 10.5C8 9.39543 8.89543 8.5 10 8.5C11.1046 8.5 12 9.39543 12 10.5C12 11.6046 11.1046 12.5 10 12.5Z"
            fill={color}
          />
          <path
            d="M10 18.5C8.89543 18.5 8 17.6046 8 16.5C8 15.3954 8.89543 14.5 10 14.5C11.1046 14.5 12 15.3954 12 16.5C12 17.6046 11.1046 18.5 10 18.5Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}
