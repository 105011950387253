import { Chip, styled } from 'opensolar-ui'
import { FC, useMemo } from 'react'
import { generateEmbedUrl, sanitizeHtml } from '../utils'
import { sectionBaseStyles } from './common/styles'

interface PropTypes {
  body: string
  tags: string[]
  videoUrl?: string
  media?: string
}

const InfoWrapper = styled('div', { name: 'InfoWrapper' })({
  ...sectionBaseStyles,
  display: 'flex',
  padding: '25px 20px',
  gap: 30,
})

const SectionWrapper = styled('div', { name: 'SectionWrapper' })({
  flex: 1,
  lineHeight: '24px',
})

const MediaWrapper = styled('div', { name: 'MediaWrapper' })({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    maxWidth: '50%',
  },
})

const SectionHeader = styled('h1', { name: 'SectionHeader' })({
  fontSize: 24,
  marginTop: 0,
  marginBottom: 20,
})

const TagHeader = styled('h4', { name: 'TagHeader' })({
  marginBottom: 10,
})

const TagChip = styled(Chip, { name: 'TagChip' })({
  marginRight: 5,
  borderRadius: 20,
})

const VideoPlayer = styled('iframe', { name: 'VideoPlayer' })({
  width: '100%',
  height: '100%',
})

const Description: FC<PropTypes> = ({ body, tags, videoUrl, media }) => {
  const videoLink = useMemo(() => generateEmbedUrl(videoUrl), [videoUrl])
  return (
    <InfoWrapper>
      <SectionWrapper>
        {body && (
          <>
            <SectionHeader>Our Story</SectionHeader>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }} />
          </>
        )}
        {!!tags?.length && (
          <>
            <TagHeader>Solutions offered</TagHeader>
            {tags.map((tag, i) => (
              <TagChip key={'tag-' + i} label={tag} variant="outlined" />
            ))}
          </>
        )}
      </SectionWrapper>

      {videoUrl ? (
        <SectionWrapper>
          {videoLink ? (
            <VideoPlayer
              src={videoLink}
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          ) : (
            'Error: Invalid Video Link'
          )}
        </SectionWrapper>
      ) : (
        <MediaWrapper>{media && <img src={media} />}</MediaWrapper>
      )}
    </InfoWrapper>
  )
}

export default Description
