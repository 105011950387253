import lodash from 'lodash'
import { FC, useEffect, useMemo } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

interface PropTypes {
  source: string
}

const ACTION_CHOICES = [
  { id: true, name: 'Is complete' },
  { id: false, name: 'Is incomplete' },
]

const ActionComplete: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const actionComplete = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])

  useEffect(() => {
    let newValue = {}
    if (actionComplete?.conditional !== undefined && actionComplete?.itemId) {
      newValue[`action_${actionComplete.conditional ? 'complete' : 'incomplete'}`] = actionComplete?.itemId
    }
    form.change(`${source}.value`, newValue)
  }, [actionComplete])
  return (
    <>
      <SelectInput
        choices={ACTION_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
        style={{ minWidth: '120px' }}
      />
      <ReferenceInput
        source={`${source}.fields.itemId`}
        reference="actions"
        perPage={1000}
        translateChoice={true}
        filter={{ is_workflow_action: 1 }}
        label=""
      >
        <SelectInput
          variant="outlined"
          source="title"
          optionText={(value: any) => {
            if (value == null) return ''
            let optionTitle = value.title
            if (!!value.workflows.length) {
              value.workflows.forEach((x, i) => {
                if (i === 0) optionTitle = optionTitle + ' ('
                optionTitle = optionTitle + `${x.stage} - ${x.workflow}${i === value.workflows.length - 1 ? ')' : ', '}`
              })
            }
            return optionTitle
          }}
          optionValue="id"
          margin={'none'}
        />
      </ReferenceInput>
    </>
  )
}

export default ActionComplete
