import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TARIFF_FIELD_COMPONENTS } from 'resources/utilityTariffs/tariffInputs/pricingTable/PricingRates'
import { formatSubmitValues } from 'resources/utilityTariffs/UtilityTariff'
import { urlToId } from 'util/misc'
import { parseTariffData } from '../parseTariffInitialValues'

//TODO: Standardize how tariffs are saved to avoid having to parse/check for data variable or not
export const useCurrentTariffData = (formValues: any) => {
  return useMemo(() => {
    if (formValues?.utility_tariff_current_custom) {
      return {
        ...formValues.utility_tariff_current_custom,
        tariffDataType: 'custom',
      }
    } else if (formValues?.utility_tariff_current_data) {
      //select tariff dialog returns full data with stringified data variable
      const parsedSelectedTariff = {
        ...formValues?.utility_tariff_current_data,
        data: parseTariffData(formValues?.utility_tariff_current_data.data),
      }
      return {
        ...parsedSelectedTariff,
        tariffDataType: 'current',
      }
    } else {
      //BE returns with data variable
      const tariffData = formValues.utility_tariff_current_guess || formValues.utility_tariff_or_guess
      return {
        ...tariffData,
        tariffDataType: 'guess',
      }
    }
  }, [formValues])
}

interface SpecialField {
  component: keyof typeof TARIFF_FIELD_COMPONENTS
  width: number
}

interface CountryTariffCriteria {
  hasSpecialDefaults: boolean
  specialFields: {
    [sourceType: string]: SpecialField[]
  }
}

const COUNTRY_TARIFF_CRITERIA: Record<string, CountryTariffCriteria> = {
  DE: {
    hasSpecialDefaults: true,
    specialFields: {
      export_credits: [{ component: 'CriteriaFields', width: 2 }],
    },
  },
  FR: {
    hasSpecialDefaults: true,
    specialFields: {
      export_credits: [{ component: 'CriteriaFields', width: 2 }],
    },
  },
}

export const useCountrySpecificTariffFields = (countryIso2: string | undefined): CountryTariffCriteria => {
  return useMemo(() => {
    if (!countryIso2) {
      return {
        hasSpecialDefaults: false,
        specialFields: {},
      }
    }

    return (
      COUNTRY_TARIFF_CRITERIA[countryIso2] || {
        hasSpecialDefaults: false,
        specialFields: {},
      }
    )
  }, [countryIso2])
}

export const useProposedTariffData = (formValues: any) => {
  const currentTariff = useCurrentTariffData(formValues)
  return useMemo(() => {
    if (formValues?.utility_tariff_proposed_custom) {
      return {
        ...formValues.utility_tariff_proposed_custom,
        tariffDataType: 'custom',
      }
    } else if (formValues?.utility_tariff_proposed_data) {
      //select tariff dialog returns full data with stringified data variable
      const parsedSelectedTariff = {
        ...formValues?.utility_tariff_proposed_data,
        data: parseTariffData(formValues?.utility_tariff_proposed_data.data),
      }
      return {
        ...parsedSelectedTariff,
        tariffDataType: 'proposed',
      }
    } else if (formValues?.utility_tariff_current_custom) {
      return {
        ...formValues.utility_tariff_current_custom,
        tariffDataType: 'current_custom',
      }
    } else if (formValues?.utility_tariff_current_data) {
      const currentData = formValues.utility_tariff_current_data
      if (currentData.transition_to_utility_tariff) {
        return {
          id: urlToId(currentData.transition_to_utility_tariff),
          code: currentData.transition_to_utility_tariff_code,
          current_transition: currentData.transition_to_utility_tariff,
          tariffDataType: 'current',
        }
      } else {
        const parsedSelectedTariff = {
          ...formValues?.utility_tariff_current_data,
          data: parseTariffData(formValues?.utility_tariff_current_data.data),
        }
        return {
          ...parsedSelectedTariff,
          tariffDataType: 'current',
        }
      }
    } else if (currentTariff.transition_to_utility_tariff_id) {
      return {
        id: currentTariff.transition_to_utility_tariff_id,
        current_transition: currentTariff.transition_to_utility_tariff_id,
        tariffDataType: 'guess',
      }
    } else {
      return {
        ...currentTariff,
        tariffDataType: 'guess',
      }
    }
  }, [formValues])
}

export const useFormatTariffValues = (values: any) => {
  const user = useSelector(authSelectors.getCurrentUser)
  return useMemo(() => formatSubmitValues(values, user?.email, false), [user, values])
}

export const useDefaultTariffCountry = (): { url: string; iso2: string } => {
  const formState = useFormState()
  const formValues = formState?.values
  const orgCountry = useSelector(orgSelectors.getOrg)?.country

  return useMemo(() => {
    return formValues?.country
      ? { url: formValues?.country, iso2: formValues?.country_iso2 }
      : { url: `${window.API_ROOT}/api/countries/${orgCountry?.id}/`, iso2: orgCountry?.iso2 }
  }, [formValues?.country, formValues?.country_iso2, orgCountry])
}
