import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import DualTiltIcon from 'icons/DualTiltIcon'
import FlushIcon from 'icons/FlushIcon'
import SingleTiltIcon from 'icons/SingleTiltIcon'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { PanelConfiguration } from 'types/global'
import { ActionType, EventType } from 'types/tracking'
import { useStudioStyles } from '../common/styles'

type RackingSelectorPropTypes = {
  onChange: (newValue: PanelConfiguration) => void
  value: PanelConfiguration | undefined
}

const RackingSelector = ({ value, onChange }: RackingSelectorPropTypes) => {
  const classes = useStudioStyles()
  const translate = useTranslate()

  const { trackEvent } = useTrackComponent({
    componentKey: 'racking_selector',
    eventName: 'User Selected Panel Group Racking',
  })

  const getOptCSSClassName = useCallback(
    (optValue: PanelConfiguration, selectionValue: PanelConfiguration | undefined) => {
      if (selectionValue === undefined) return classes.rackingSelectorOpt
      return optValue === selectionValue
        ? classes.rackingSelectorOpt + ' ' + classes.rackingSelectorOptActive
        : classes.rackingSelectorOpt
    },
    []
  )
  const handleOptionClick = useCallback(
    (selectedValue: PanelConfiguration, label: string) => {
      trackEvent(EventType.USER_INTERACTION, { type: ActionType.SELECT }, { selectedOption: selectedValue, label })
      onChange(selectedValue)
    },
    [trackEvent, onChange]
  )

  return (
    <div className={classes.rackingSelectorContainer}>
      <div className={getOptCSSClassName('STANDARD', value)} onClick={() => handleOptionClick('STANDARD', 'Flush')}>
        <FlushIcon highlight={value === 'STANDARD'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Flush')}</div>
      </div>
      <div
        className={getOptCSSClassName('TILT_RACK', value)}
        onClick={() => handleOptionClick('TILT_RACK', 'Single-tilt')}
      >
        <SingleTiltIcon highlight={value === 'TILT_RACK'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Single-tilt')}</div>
      </div>
      <div
        className={getOptCSSClassName('DUAL_TILT_RACK', value)}
        onClick={() => handleOptionClick('DUAL_TILT_RACK', 'Dual-tilt')}
      >
        <DualTiltIcon highlight={value === 'DUAL_TILT_RACK'} />
        <div className={classes.rackingSelectorOptLabel}>{translate('Dual-tilt')}</div>
      </div>
    </div>
  )
}

export default RackingSelector
