import { AppBar as MuiAppBar, Toolbar, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { toggleSidebar, useTranslate } from 'ra-core'
import { Children, cloneElement, createElement } from 'react'
import { useDispatch } from 'react-redux'

import DefaultUserMenu from './UserMenu'

const useStyles = makeStyles(
  (theme) => ({
    // toolbar: {
    //   paddingRight: 24,
    // },
    menuButton: {
      margin: 0,
      marginLeft: 5,
      marginRight: 5,
      padding: 10,
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
)

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *        </AppBar>
 *    );
 *};
 */
const AppBar = (props) => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'inherit',
    hasSideBar,
    isNav3Enabled,
    logo,
    logout,
    open,
    title,
    header,
    userMenu,
    ...rest
  } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const translate = useTranslate()
  return (
    <MuiAppBar
      style={isNav3Enabled
        ? { boxShadow: 'none' }
        : { boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px' }
      }
      position={'relative'}
      className={className}
      color={color}
      {...rest}
    >
      <Toolbar
        style={{
          minHeight: 44,
          backgroundColor: 'rgb(255,255,255)',
        }}
        disableGutters
        variant={isXSmall ? 'regular' : 'dense'}
        className={classes.toolbar}
      >
        {isXSmall && hasSideBar && (
          <Tooltip
            title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
              _: 'Open/Close menu',
            })}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        {Children.count(children) === 0
          ? // <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
          null
          : children}

        {header ? createElement(header) : cloneElement(userMenu, { logout })}
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
  isNav3Enabled: PropTypes.bool,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
}

AppBar.defaultProps = {
  userMenu: <DefaultUserMenu />,
  hasSideBar: true,
  isNav3Enabled: false,
}

export default AppBar
