import { Divider } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Grid, styled, Typography } from 'opensolar-ui'
import { getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useTranslate } from 'ra-core'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from 'util/misc'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING } from '../constants'
import { getShippingFeeCurrencySymbol } from '../helper'
import { COLOR_PALETTE } from '../styles'
import { CheckoutSourceType } from '../types'

interface Props {
  distributorData: any
  distributor: HardwareSupplierFilterKeyType
  callback: (value: number) => void
  checkoutSource: CheckoutSourceType
}

const MainContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const DividerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: '8px',
  width: '100%',
})

const OrderSummaryItem: FC<Props> = ({ distributorData, distributor, callback, checkoutSource }) => {
  const translate = useTranslate()
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const discount = distributorData['beforeDiscountTotal'] - distributorData['afterDiscountTotal']
  const shippingFreeCurrencySymbol = getShippingFeeCurrencySymbol(
    distributorData?.shippingInfo?.fee ? distributorData?.shippingInfo?.fee : '',
    currencySymbol
  )
  const supplierEnum = useMemo(() => {
    if (distributor) return getSupplierEnumByFilterKey(distributor)
  }, [distributor])
  const cartDiscountValue = useMemo(() => getCartDiscountValue(distributorData['afterDiscountTotal'], cartDiscount), [
    cartDiscount?.discountPercentage,
  ])

  useEffect(() => {
    getCartDiscount(
      distributorData.items,
      supplierEnum,
      (cartDiscount) => {
        setCartDiscount(cartDiscount)
      },
      'project'
    )
  }, [distributorData.items, distributor])

  useEffect(() => {
    if (cartDiscountValue > 0) {
      callback(cartDiscountValue)
    }
  }, [cartDiscountValue])

  return (
    <MainContainer item xs={12} md={12}>
      <Row>
        <Typography textVariant="subtitle1" colorHex={COLOR_PALETTE.black as `#${string}`}>
          {DISTRBUTOR_KEY_TO_NAME_MAPPING[distributor]}
        </Typography>
      </Row>
      <ContentContainer>
        <Typography textVariant="body2" colorHex={COLOR_PALETTE.black as `#${string}`}>{`${translate('Subtotal')} (${
          distributorData['totalItems']
        } items)`}</Typography>
        <Typography
          textVariant="body2"
          colorHex={COLOR_PALETTE.black as `#${string}`}
        >{`${currencySymbol}${formatCurrency(distributorData['beforeDiscountTotal'])}`}</Typography>
      </ContentContainer>
      {discount > 0 && (
        <ContentContainer>
          <Typography textVariant="body2" colorHex={COLOR_PALETTE.green as `#${string}`}>
            {translate('Discount')}
          </Typography>
          <Typography
            textVariant="body2"
            colorHex={COLOR_PALETTE.green as `#${string}`}
          >{`- ${currencySymbol}${formatCurrency(discount)}`}</Typography>
        </ContentContainer>
      )}
      {cartDiscount && cartDiscount.eligible && checkoutSource !== 'global' && (
        <ContentContainer>
          <Typography textVariant="body2" colorHex={COLOR_PALETTE.green as `#${string}`}>
            {translate(cartDiscount.discountName)}
          </Typography>
          <Typography
            textVariant="body2"
            colorHex={COLOR_PALETTE.green as `#${string}`}
          >{`- ${currencySymbol}${formatCurrency(cartDiscountValue)}`}</Typography>
        </ContentContainer>
      )}
      <ContentContainer>
        <Typography textVariant="body2" colorHex={COLOR_PALETTE.black as `#${string}`}>
          {translate('Shipping & Handling')}
        </Typography>
        <Typography
          textVariant="body2"
          colorHex={COLOR_PALETTE.black as `#${string}`}
        >{`${shippingFreeCurrencySymbol}${distributorData['shippingInfo']['fee']}`}</Typography>
      </ContentContainer>
      <DividerContainer>
        <Divider style={{ width: '25%' }} />
      </DividerContainer>
    </MainContainer>
  )
}

export default memo(OrderSummaryItem)
