import { FC } from 'react'

/**
 * * Icon Component for: copy-left
 */
type CopyLeftOutlineIconProps = {
  size?: number
  color?: string
}
export const CopyLeftOutlineIcon: FC<CopyLeftOutlineIconProps> = ({
  size = '1rem',
  color = 'currentColor',
  ...rest
}) => {
  const iconAttributes = {
    width: size,
    height: size,
    stroke: color,
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" fill="none" {...rest} {...iconAttributes}>
      <path
        d="M1 11.125L1 5C1 2.79086 2.79087 0.999998 5 1L11.125 1M7 19L13.75 19C14.9926 19 16 17.9926 16 16.75L16 7C16 5.75736 14.9926 4.75 13.75 4.75L7 4.75C5.75736 4.75 4.75 5.75736 4.75 7L4.75 16.75C4.75 17.9926 5.75736 19 7 19Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
