// Similar value to BE's EnergyTypeEnum: oslib.energy_model.types.EnergyTypeEnum
export enum EnergyTypeEnum {
  ELECTRICITY = 'electricity',
  NATURAL_GAS = 'natural_gas',
  PETROLEUM = 'petroleum',
}

export type OtherEnergyItemType = {
  energy_type: EnergyTypeEnum
  unit_value: number
  usage_data_source: string
  values: number
  values_kwh?: number
  price_per_kwh?: number
}
