import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Struct } from 'contexts/structs/types/types'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { addDevTool } from 'debug/ostools'
import { authSelectors } from 'ducks/auth'
import { Logger } from 'opensolar-sdk'
import { PromoDialog, usePromoDialogStructs } from 'persistentContent/promoDialog/usePromoDialogStructs'
import {
  ToastNotification,
  useToastNotificationStructs,
} from 'persistentContent/toastNotification/useToastNotificationStructs'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type StructType = 'promo-dialog' | 'toast-notification'
type AmpString = 'in_app_promo_shown' | 'toast_notification_shown'
interface StructData {
  structSource: any
  shortLabel: string
  fullLabel: string
  keyPrefix: string
  logger: any
  amplitude: {
    shown: AmpString
  }
}
const getData = (structType: StructType): StructData => {
  switch (structType) {
    case 'promo-dialog':
      return {
        structSource: usePromoDialogStructs,
        shortLabel: 'promos',
        fullLabel: 'promo dialog',
        keyPrefix: 'os-iap-',
        logger: new Logger('OS.PromoDialog'),
        amplitude: {
          shown: 'in_app_promo_shown',
        },
      }
    case 'toast-notification':
      return {
        structSource: useToastNotificationStructs,
        shortLabel: 'toasts',
        fullLabel: 'toast notification',
        keyPrefix: 'os-toast-',
        logger: new Logger('OS.ToastNotification'),
        amplitude: {
          shown: 'toast_notification_shown',
        },
      }
  }
}
const getStructKey = (struct, keyPrefix) => `${keyPrefix}${struct.key}`

export const useVisibleStructLogic = (structType: StructType) => {
  const [open, setOpen] = useState(false)
  const structData = useMemo(() => getData(structType), [structType])
  const useStructSource = useMemo(() => structData?.structSource, [structData])
  const { structs: allStructs } = useStructSource()
  const [currentKey, setCurrentKey] = useState<string>()
  const userId = useSelector(authSelectors.getCurrentUser)?.id || -1

  // Allows for hiding the promo from the console
  const [forceHide, setForceHide] = useState(false)
  addDevTool(
    `${structData.shortLabel}.forceHide`,
    () => {
      setForceHide(true)
    },
    { help: `Hides the ${structData.fullLabel}` }
  )
  addDevTool(
    `${structData.shortLabel}.clearForceHide`,
    () => {
      setForceHide(false)
    },
    { help: `Unhides the ${structData.fullLabel} (if there are still ${structData.shortLabel} to show)` }
  )

  // Incrementing forceCheck allows us to 'lock in' the promos that we've seen so that
  // they aren't immediately shown again when the user navigates closes the promo dialog
  const [forceCheck, setForceCheck] = useState(0)

  const { loaded: actionsLoaded, checkAction, recordAction } = useUserActionsContext()

  // both derived from currentKey
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [currentItem, setCurrentItem] = useState<Struct<any> | undefined>()

  const hasBeenDismissed = (struct: Struct<PromoDialog | ToastNotification>) => {
    const storageKey = getStructKey(struct, structData.keyPrefix)
    if (struct.data.show_again_rule !== 'always' && dismissedThisSession[storageKey]) {
      // Promo has already been shown this session
      structData.logger.debug(`\t${structData.fullLabel} Filtered, already shown this session: `, struct.key, {
        storageKey: storageKey,
      })
      return true
    }

    if (struct.data.show_again_rule === 'never') {
      if (!actionsLoaded) {
        // Don't show until actions are loaded
        structData.logger.debug(`\t${structData.fullLabel} Filtered, actions not loaded yet: `, struct.key)
        return true
      }
      if (checkAction(storageKey)) {
        // Promo has already been shown
        structData.logger.debug(`\t${structData.fullLabel} Filtered, already shown: `, struct.key, { storageKey })
        return true
      }
    }
    return false
  }

  const structsToDisplay = useMemo(() => {
    return allStructs.filter((struct) => !hasBeenDismissed(struct))
  }, [allStructs, actionsLoaded, forceCheck]) // intentionally not including checkAction, allows for outro to complete uninterupted

  const showPromo = (struct: Struct<PromoDialog | ToastNotification>) => {
    setCurrentKey(struct.key)
    const storageKey = getStructKey(struct, structData.keyPrefix)
    if (!hasShownThisSession(storageKey)) {
      trackShownThisSession(storageKey)
      logAmplitudeEvent(structData.amplitude.shown, { promo_id: struct.key })
    }
    setOpen(true)
  }

  useEffect(() => {
    const matchingPromo = structsToDisplay.find((struct) => struct.key === currentKey)
    setCurrentIndex(matchingPromo ? structsToDisplay.indexOf(matchingPromo) : 0)
    setCurrentItem(matchingPromo)
    if (!currentKey) setOpen(false)
  }, [currentKey, structsToDisplay])

  const trackDismissed = (promo: Struct<PromoDialog | ToastNotification> | undefined) => {
    if (!promo) return
    const key = getStructKey(promo, structData.keyPrefix)
    if (userId !== -1) recordAction(key)
    trackDismissedThisSession(key)
  }
  const trackAllDismissed = () => {
    structsToDisplay.forEach(trackDismissed)
  }

  // Checks the current promo against the filtered list of promos and
  // updates the current promo if necessary
  useEffect(() => {
    if (!structsToDisplay) return
    if (currentItem) {
      if (structType === 'promo-dialog') {
        // Check if new promos have been added which are higher priority
        for (const struct of structsToDisplay) {
          if (struct.key === currentItem.key) {
            // got to current promo without finding any, break
            break
          }
          if (!hasShownThisSession(getStructKey(struct, structData.keyPrefix))) {
            structData.logger.warn(`Promo (${struct.key}) was added at higher priority, switching to it`)
            showPromo(struct)
            return
          }
        }
      }

      const latestPromo = structsToDisplay.find((struct) => struct.key === currentItem.key)
      if (latestPromo) {
        // Promo is still available, all good!
        // Update the current promo to the latest version
        setCurrentItem(latestPromo)
        return
      } else {
        // Promo was removed, switch to the nearest available promo
        let gotoPromo: Struct<PromoDialog | ToastNotification> | undefined = structsToDisplay[currentIndex]
        if (!gotoPromo) gotoPromo = structsToDisplay[currentIndex - 1]
        if (!gotoPromo) gotoPromo = structsToDisplay[0]

        if (gotoPromo) {
          structData.logger.info(`Promo (${currentItem.key}) was removed, switching to nearest: `, gotoPromo.key)
          setCurrentKey(gotoPromo.key)
        } else {
          structData.logger.info(`Promo (${currentItem.key}) was removed, clearing`)
          setCurrentKey(undefined)
        }
      }
    }
    if (!currentItem) {
      if (structsToDisplay.length) {
        const struct = structsToDisplay[0]
        structData.logger.info('Selected Promo: ', struct.key)
        showPromo(struct)
      }
    } else {
      structData.logger.info('Retaining current Promo: ', currentItem.key)
    }
  }, [structsToDisplay, currentItem])

  return {
    open,
    setOpen,
    currentKey,
    setCurrentKey,
    forceHide,
    forceCheck,
    setForceCheck,
    currentIndex,
    currentItem,
    setCurrentItem,
    structsToDisplay,
    showPromo,
    trackAllDismissed,
    trackDismissed,
    trackingKey: currentItem ? getStructKey(currentItem, structData.keyPrefix) : undefined,
  }
}
//TODO: this should be cleared on logout
const dismissedThisSession: Record<string, true> = {}
const trackDismissedThisSession = (storageKey: string) => {
  dismissedThisSession[storageKey] = true
}

//TODO: this should be cleared on logout
const shownThisSession: Record<string, true> = {}
const trackShownThisSession = (storageKey: string) => {
  shownThisSession[storageKey] = true
}
const hasShownThisSession = (storageKey: string) => {
  return !!shownThisSession[storageKey]
}
