import { Reducer } from "redux";
import {
  CRUD_GET_MATCHING_SUCCESS,
  CrudGetMatchingSuccessAction,
  CRUD_GET_MATCHING_FAILURE,
  CrudGetMatchingFailureAction,
  CRUD_GET_MATCHING_LOADING,
  CrudGetMatchingLoadingAction,
} from "../../../actions/dataActions";
import { Identifier } from "../../../types";

const initialState = {
  resourcesMidRefresh: [],
};

export interface PossibleValuesState {
  [relatedTo: string]: { error?: string | object } | Identifier[];
  resourcesMidRefresh: string[];
}

type ActionTypes =
  | CrudGetMatchingSuccessAction
  | CrudGetMatchingFailureAction
  | CrudGetMatchingLoadingAction
  | { type: "OTHER_ACTION" };

const possibleValuesreducer: Reducer<PossibleValuesState> = (
  previousState = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case CRUD_GET_MATCHING_LOADING:
      let midRefresh = previousState.resourcesMidRefresh?.filter(
        (resource: string) => resource !== action.meta.resource
      );
      if (!midRefresh) midRefresh = [];
      midRefresh.push(action.meta.resource);
      return {
        ...previousState,
        resourcesMidRefresh: midRefresh,
      };
    case CRUD_GET_MATCHING_SUCCESS:
      return {
        ...previousState,
        [action.meta.relatedTo]: action.payload.data.map((record) => record.id),
        resourcesMidRefresh: previousState.resourcesMidRefresh.filter(
          (resource: string) => resource !== action.meta.resource
        ),
      };
    case CRUD_GET_MATCHING_FAILURE:
      return {
        ...previousState,
        [action.meta.relatedTo]: { error: action.error },
      };
    default:
      return previousState;
  }
};

export const getPossibleReferenceValues = (state, props) => {
  return state[props.referenceSource(props.resource, props.source)];
};

export const getPossibleReferences = (
  referenceState,
  possibleValues,
  selectedIds = []
) => {
  if (!possibleValues) {
    return null;
  }

  if (possibleValues.error) {
    return possibleValues;
  }
  possibleValues = Array.from(possibleValues);
  selectedIds.forEach(
    (id) =>
      possibleValues.some((value) => value === id) || possibleValues.unshift(id)
  );
  return possibleValues
    .map((id) => referenceState.data[id])
    .filter((r) => typeof r !== "undefined");
};

export default possibleValuesreducer;
