import { makeStyles } from '@material-ui/core/styles'
import { orgSelectors } from 'ducks/orgs'
import { usePvfIntegrationEnabled } from 'hooks/usePvfIntegrationEnabled'
import { PlugIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { useSolarAppAvailable } from 'util/solarApp'
import { useFeatureFlag } from 'util/split'
import { HalfWidthSection, iconStyles, SectionCardBottom, SectionCardTop } from '../styles'
import { getProjectCTAs } from './ctas/util'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    padding: 16,
    paddingLeft: 0,
  },
}))

const StyledPlugIcon = styled(PlugIcon, { name: 'StyledPlugIcon' })({
  ...iconStyles,
})
const IntegrationsSection: React.FC = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const enablePvf = usePvfIntegrationEnabled()
  const enableDownloadBOM = useFeatureFlag('hardware_manage_downloadBOM', 'on')
  const project = useFormState().values as ProjectType
  const enableSolarAPP = useSolarAppAvailable(project.country_iso2)
  const isIronRidgeLinked = useSelector((state: RootState) => orgSelectors.getIsPartnerOrgLinked(state, 'ironridge'))
  const ctaList = useMemo(() => {
    let featureFlagsEnabled: string[] = []
    if (enablePvf) featureFlagsEnabled.push('pvf_integration')
    if (enableDownloadBOM) featureFlagsEnabled.push('hardware_manage_downloadBOM')
    if (enableSolarAPP) featureFlagsEnabled.push('solar_app_plus')
    return getProjectCTAs(project, featureFlagsEnabled, isIronRidgeLinked)
  }, [project, enablePvf, enableDownloadBOM])

  if (ctaList.length === 0) return null

  return (
    <HalfWidthSection id={'Project-Integrations-Section'}>
      <SectionCardTop>
        <StyledPlugIcon size={18} />
        <p className={classes.header}>{translate('Integrations')}</p>
      </SectionCardTop>
      <SectionCardBottom>
        {ctaList.map((item, i) => {
          const Integration = item.component
          return <Integration key={'integration-' + i} project={project} {...item.props} />
        })}
      </SectionCardBottom>
    </HalfWidthSection>
  )
}

export default IntegrationsSection
