import type { Translate } from 'ra-core'

const LoadingMessage = ({ translate }: { translate: Translate }) => {
  return (
    <>
      <p>{translate('Preparing your Sunlight Financial prequalification application.')}</p>
      <p>{translate('(Process may take up to 30 seconds. Thank you for your patience.)')}</p>
    </>
  )
}

export default LoadingMessage
