import { default as Mui4Link, LinkProps as Mui4LinkPropsType } from '@material-ui/core/Link'
import { default as Mui5Link } from '@mui/material/Link'
import React, { FC, ForwardedRef, forwardRef } from 'react'
import { Inspector } from '../debug/Inspector'
import { useComponentVersion } from '../hooks'

// * MUI Link API Spec  https://mui.com/material-ui/api/Link/
export const Link: FC<LinkProps> = (props) => (
  <Inspector name="Link" showExample={false} component={OsLink} props={props} />
)

export enum LinkVersion {
  V1 = 1, // MUI4 + OS Theme
  V2 = 2, // MUI5 + Stratis
}

export interface LinkProps extends Mui4LinkPropsType {
  component?: React.ElementType
}

const OsLink: FC<LinkProps> = forwardRef((props, ref: ForwardedRef<HTMLAnchorElement>) => {
  const version = useComponentVersion('link')

  switch (version) {
    case LinkVersion.V2: {
      const { variant, ...restProps } = props
      return <Mui5Link component={props.component} ref={ref} {...restProps} />
    }

    default:
      return <Mui4Link {...props} />
  }
})
