import { ErrorOutline } from '@material-ui/icons'
import { MenuItem, Select, Tooltip } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { AccountingTaxRateType } from './types'

type PropTypes = {
  availableTaxRates: AccountingTaxRateType[]
  taxRate: number
  onChange: (newVal: string) => void
  value: string | undefined
  taxRatesLoading: boolean
}

const AccountingTaxRateSelect: React.FC<PropTypes> = (props) => {
  const matchingTaxRates = useMemo(() => {
    const taxRateToDecimal = props.taxRate * 100
    return props.availableTaxRates?.filter((taxRate) => {
      return taxRate.total_tax_rate === taxRateToDecimal || taxRate.effective_tax_rate === taxRateToDecimal
    })
  }, [props.availableTaxRates, props.taxRate])

  const isDisabled = useMemo(() => {
    return props.taxRatesLoading || !matchingTaxRates?.length
  }, [props.taxRatesLoading, matchingTaxRates])

  const onChange = (e) => {
    props.onChange(e.target.value)
  }

  return (
    <>
      <Select value={props.value} onChange={onChange} fullWidth displayEmpty disabled={isDisabled}>
        {matchingTaxRates?.map((tax) => (
          <MenuItem key={tax.id} value={tax.id}>
            {tax.name}
          </MenuItem>
        ))}
      </Select>
      {!matchingTaxRates?.length && !props.taxRatesLoading && (
        <Tooltip title="No matching tax rates found">
          <ErrorOutline />
        </Tooltip>
      )}
    </>
  )
}
export default AccountingTaxRateSelect
